.club-smiles {
    &__anywhere {
        &__chooseplan {
            width: 100%;
            display: inline-block;
            &__club {
                width: 100%;
                float: left;
                .logo {
                    float: left;
                    label {
                        color: $club;
                        font-size: 40px;
                        line-height: 50px;
                        font-family: $boldFontFamily;
                        letter-spacing: -1px;
                        width: 100%;
                        margin-bottom: 0;
                    }
                    small {
                        background-image: url(../images/svg/logos/smiles-purple.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 46px;
                        height: 21px;
                        position: relative;
                        display: inline-block;
                        margin-bottom: 20px;
                        margin-top: -2px;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 9px;
                            border-bottom: 3px solid $club;
                            top: -3px;
                            width: 30px;
                            right: 100%;
                            margin-right: 3px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            height: 9px;
                            border-bottom: 3px solid $club;
                            top: -4px;
                            width: 30px;
                            left: 100%;
                            margin-left: 3px;
                        }
                    }
                }
                span {
                    float: left;
                    color: $club;
                    text-transform: uppercase;
                    font-size: 16px;
                    margin: 20px 0 0 10px;
                    font-family: $boldFontFamily;
                }
            }
            &__plans {
                float: left;
                width: 100%;
                .success {
                    h2 {
                        text-align: center;
                        width: 100%;
                        font-size: 24px;
                    }
                    label {
                        font-size: 14px;
                        font-family: $boldFontFamily;
                        color: $default;
                    }
                    p {
                        width: calc(100% - 20px);
                        margin: 50px 10px;
                        font-family: $regularFontFamily;    
                        font-size: 14px;
                        text-align: center;
                    }
                    .logo {
                        float: left;
                        width: 100%;
                        background-image: url(../images/svg/icons/purple/ic-check.svg);
                        background-position: center 0;
                        background-repeat: no-repeat;
                        background-size: 30px;
                        padding-top: 30px;
                        label {
                            color: $club;
                            font-size: 50px;
                            line-height: 50px;
                            font-family: $boldFontFamily;
                            letter-spacing: -1px;
                            width: 100%;
                            margin-bottom: 0;
                            
                        }
                        small {
                            background-image: url(../images/svg/logos/smiles-purple.svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 50px;
                            height: 21px;
                            position: relative;
                            display: inline-block;
                            margin-bottom: 20px;
                            margin-top: -2px;
                            &::after {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -3px;
                                width: 30px;
                                right: 100%;
                                margin-right: 3px;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -4px;
                                width: 30px;
                                left: 100%;
                                margin-left: 3px;
                            }
                        }
                    }
                }
                .choose {
                    width: calc(100% - 20px);
                    margin: 0 10px 0 10px;
                    float: left;
                    .plan {
                        width: 100%;
                        height: 240px;
                        border: 1px solid $gray-300;
                        border-radius: 5px;
                        padding: 0 10px;
                        float: left;
                        label.club {
                            color: $club;
                            font-size: 50px;
                            line-height: 50px;
                            font-family: $boldFontFamily;
                            letter-spacing: -1px;
                            width: 100%;
                            margin-bottom: 0;
                        }
                        small {
                            background-image: url(../images/svg/logos/smiles-purple.svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 50px;
                            height: 21px;
                            position: relative;
                            display: inherit;
                            margin: auto;
                            margin-bottom: 20px;
                            margin-top: -2px;
                            &::after {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -3px;
                                width: 30px;
                                right: 100%;
                                margin-right: 3px;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -4px;
                                width: 30px;
                                left: 100%;
                                margin-left: 3px;
                            }
                        }
                        b {
                            color: $club;
                            display: block;
                            margin: 0 0 20px 0;
                            font-family: $boldFontFamily;
                        }
                        label.plan-choose {
                            font-size: 14px;
                            line-height: 16px;
                            margin-bottom: 20px;
                            font-family: $boldFontFamily;
                            b {
                                color: $club;
                            }
                        }
                        span {
                            font-size: 14px;
                            line-height: 16px;
                            display: block;
                            b {
                                color: $club;
                            }
                        }
                    }
                    .info {
                        float: left;
                        margin: 0 0 10px 0;
                        .user {
                            float: left;
                            clear: both;
                            h3 {
                                text-align: left;
                                margin: 10px 0 0 0;
                                font-size: 26px;
                            }
                            label {
                                width: 100%;
                                color: $default;
                                text-align: left;
                                margin-bottom: 0;
                                font-size: 18px;
                                font-family: $boldFontFamily;
                            }
                            small {
                                width: 100%;
                                display: block;
                                text-align: left;
                                margin-bottom: 78px;
                            }
                            span {
                                display: block;
                                float: left;
                                width: 100%;
                                text-align: left;
                                font-size: 14px;
                                font-family: $boldFontFamily;
                                
                            }
                        }
                    }
                    .payment-club {
                        float: left;
                        border-top: 1px solid $gray-300;
                        padding-top: 10px;
                        h3 {
                            text-align: left;
                            font-size: 26px;
                        }
                        &:not(:last-child) {
                          border-bottom: none;
                        }
                        .payment {
                          h4 {
                            color: $gray-700;
                            @include bms-font-size(5.3vw, 17px);
                          }
                          > div {
                            position: relative;
                            float: none;
                            margin-bottom: 30px;
                            &:not(:last-child) {
                              border-bottom: 2px solid $gray-300;
                              padding-bottom: 20px;
                              &:before {
                                content: " ";
                                position: absolute;
                                left: 45%;
                                bottom: -22px;
                                display: block;
                                width: 32px;
                                height: 32px;
                                background-image: url(#{$svg-path}/icons/gray/ic-arrow-lighter.svg);
                                background-size: 24px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                transform: rotate(90deg);
                              }
                            }
                    
                            .installments {
                              &__list {
                                margin: 0;
                                float: left;
                                width: 100%;
                                ul {
                                  li {
                                    display: none;
                                    p {
                                      margin: 0;
                                      padding: 0;
                                      font-size: 13px;
                                    }
                                  }
                                }
                                span {
                                  float: left;
                                  width: 100%;
                                  text-align: center;
                                  font-size: 13px;
                                }
                              }
                            }
                          }
                          .keyboard {
                            background-image: url(#{$svg-path}/logos/smiles-orange.svg);
                            background-size: 80px;
                            background-position: top right;
                            background-repeat: no-repeat;
                            h4 {
                              width: 100%;
                            }
                            input {
                              border: 1px solid $gray-800;
                              margin: 20px auto;
                              padding: 10px 5px;
                              border-radius: $border-radius;
                              width: 100%;
                              &::placeholder {
                                color: $gray-400;
                                @include bms-font-size(4vw, 12px);
                              }
                            }
                            .layout {
                              height: 160px;
                              padding: 10px 20px;
                              border: 2px solid $primary;
                              border-radius: $border-radius;
                              margin-bottom: 20px;
                              span {
                                display: block;
                                width: 240px;
                                margin: 0 auto;
                                font-weight: bold;
                              }
                              .numbers {
                                width: 240px;
                                margin: 10px auto;
                                > div {
                                  float: left;
                                  width: 45px;
                                  height: 45px;
                                  background-color: $gray-800;
                                  color: $white;
                                  margin-right: 3px;
                                  margin-bottom: 3px;
                                  border-radius: $border-radius;
                                  text-align: center;
                                  padding-top: 11px;
                                  cursor: pointer;
                                }
                              }
                            }
                            .buttons {
                              display: flex;
                              justify-content: space-between;
                              margin-bottom: 10px;
                              .btn {
                                width: 130px;
                                margin: 0;
                              }
                            }
                    
                            &.club-smiles {
                              background-image: none;
                              flex-direction: row;
                              .buttons {
                                margin-top: 20px;
                                .btn {
                                  width: 100%;
                                }
                              }
                              p {
                                width: 100%;
                                span {
                                  color: $default;
                                }
                                a {
                                  color: $club;
                                }
                              }
                            }
                          }
                          .text-security {
                            p {
                              margin: 0 0 5px 0;
                              @include bms-font-size(3vw, 10px);
                              @include bms-line-height(4vw, 12px);
                              strong {
                                font-family: $boldFontFamily;
                                @include bms-font-size(4.4vw, 14px);
                              }
                            }
                          }
                          .card-number {
                            position: relative;
                            .smls-credit-cards {
                              position: absolute;
                              width: 40px;
                              height: 40px;
                              top: 0px;
                              right: 8px;
                            }
                          }
                        }
                    }
                }
            }
            &__terms {
                width: calc(100% - 20px);
                margin: 0 10px;
                float: left;
                p {
                    float: left;
                    width: 100%;
                    font-size: 10px;
                    line-height: 22px;
                    text-align: left;
                    padding: 0;
                    b {
                        font-size: 14px;
                        font-family: $boldFontFamily;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .club-smiles {
        &__anywhere {
            &__chooseplan {
                width: 100%;
                display: inline-block;
                &__club {
                    width: 100%;
                    float: left;
                    .logo {
                        float: left;
                        label {
                            color: $club;
                            font-size: 50px;
                            line-height: 50px;
                            font-family: $boldFontFamily;
                            letter-spacing: -1px;
                            width: 100%;
                            margin-bottom: 0;
                        }
                        small {
                            background-image: url(../images/svg/logos/smiles-purple.svg);
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 50px;
                            height: 21px;
                            position: relative;
                            display: inline-block;
                            margin-bottom: 20px;
                            margin-top: -2px;
                            &::after {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -3px;
                                width: 30px;
                                right: 100%;
                                margin-right: 3px;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 3px solid $club;
                                top: -4px;
                                width: 30px;
                                left: 100%;
                                margin-left: 3px;
                            }
                        }
                    }
                    span {
                        float: left;
                        color: $club;
                        text-transform: uppercase;
                        font-size: 18px;
                        margin: 20px 0 0 20px;
                        font-family: $boldFontFamily;
                    }
                }
                &__plans {
                    float: left;
                    width: 100%;
                    h2 {
                        text-align: center;
                        width: 100%;
                        font-size: 24px;
                    }
                    .choose {
                        width: 80%;
                        margin: 0 10% 0 10%;
                        float: left;
                        .plan {
                            width: 200px;
                            height: 240px;
                            border: 1px solid $gray-300;
                            border-radius: 5px;
                            padding: 0 10px;
                            float: left;
                            label.club {
                                color: $club;
                                font-size: 50px;
                                line-height: 50px;
                                font-family: $boldFontFamily;
                                letter-spacing: -1px;
                                width: 100%;
                                margin-bottom: 0;
                            }
                            small {
                                background-image: url(../images/svg/logos/smiles-purple.svg);
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 50px;
                                height: 21px;
                                position: relative;
                                display: inline-block;
                                margin-bottom: 0px;
                                margin-top: -2px;
                                &::after {
                                    content: "";
                                    position: absolute;
                                    height: 9px;
                                    border-bottom: 3px solid $club;
                                    top: -3px;
                                    width: 30px;
                                    right: 100%;
                                    margin-right: 3px;
                                }
                                &::before {
                                    content: "";
                                    position: absolute;
                                    height: 9px;
                                    border-bottom: 3px solid $club;
                                    top: -4px;
                                    width: 30px;
                                    left: 100%;
                                    margin-left: 3px;
                                }
                            }
                            b {
                                display: block;
                                color: $club;
                                font-size: 14px;
                                font-family: $boldFontFamily;
                                margin-bottom: 20px;
                            }
                            label.plan-choose {
                                font-size: 14px;
                                line-height: 16px;
                                margin-bottom: 20px;
                                b {
                                    color: $club;
                                }
                            }
                            span {
                                font-size: 14px;
                                line-height: 16px;
                                display: block;
                                b {
                                    color: $club;
                                }
                            }
                        }
                        .info {
                            float: left;
                            margin: 20px 0 0 30px;
                            .user {
                                float: left;
                                clear: both;
                                h3 {
                                    text-align: left;
                                    margin-bottom: 0;
                                }
                                label {
                                    width: 100%;
                                    color: $default;
                                    text-align: left;
                                    margin-bottom: 0;
                                    font-size: 18px;
                                    font-family: $boldFontFamily;
                                }
                                small {
                                    width: 100%;
                                    display: block;
                                    text-align: left;
                                    margin-bottom: 68px;
                                }
                                span {
                                    display: block;
                                    float: left;
                                    width: 100%;
                                    text-align: left;
                                    font-size: 14px;
                                    font-family: $boldFontFamily;
                                    
                                }
                            }
                        }
                        .payment-club {
                            padding: 32px 32px 0 32px;
                            border-top: 0;
                            .payment {
                            display: flex;
                            justify-content: center;
                            margin-top: 20px;
                            > div {
                                width: 325px;
                                min-height: 310px;
                                padding: 0 30px;
                                &:first-child {
                                padding-left: 0;
                                }
                                &:last-child {
                                padding-right: 0;
                                }
                                &:not(:last-child) {
                                border-bottom: none;
                                border-right: 2px solid $gray-300;
                                &:before {
                                    top: 45%;
                                    right: -22px;
                                    bottom: unset;
                                    left: unset;
                                    transform: none;
                                }
                                }
                            }
                            .keyboard {
                                input {
                                width: 120px;
                                margin: 0 0 20px 0;
                                }

                                &.club-smiles {
                                .buttons {
                                    margin-top: 136px;
                                }
                                }
                            }
                            .card-number {
                                .smls-credit-cards {
                                top: 5px;
                                }
                            }
                            }
                        }
                    }
                    .success {
                        p {
                            width: 400px;
                            margin: 50px auto;
                            text-align: left;
                        }
                    }
                }
                &__terms {
                    width: 80%;
                    margin: 0 10%;
                }
            }
        }
    }
}