.filter-flights {
  padding: 10px 0 10px 0;
  display: block;
  background-color: white;

  @media screen and (max-width: 768px) {
    .filter-flights .col {
      display: flex !important;
    }
  }

  .resume-filters {
    .filter-boxes {
      @include bms-base-box;
      position: relative;
      width: auto;
      height: auto;
      padding: 9pt;
      margin: 16pt;
      font-family: $regularFontFamily;
      color: $gray-700;
      background-color: white;
      border-radius: 20px;
      cursor: pointer;
  
      &.club-box {
        border: 3px solid $club;
        display: flex;
        flex-direction: column;
        &.selected {
          color: white;
          background-color: $club;
        }
      }
  
      &.no-club-box {
        border: 3px solid $primary;
        &.selected {
          color: white;
          background-color: $primary;
        }
      }
  
      .flight-heading {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
    
        &.club-box {
          color: $club;
          &.selected {
            color: white;
          }
        }
    
        &.no-club-box {
          color: $primary;
          &.selected {
            color: white;
          }
        }
    
        img {
          height: 22px;
        }
      }
  
      .flight-info {
        display: flex;
        justify-content: space-between;
    
        p {
          margin: 0;
          font-weight: 700;
    
          &.miles-value {
            font-size: 17px;
          }
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      table-layout: fixed;
      border: 1px solid $gray-300;

      td, th {
        border: 1px solid $gray-300;
      }

      td {
        height: 50px;
        font-size: 14px;
        font-weight: 700;
        
        .box-airline {
          margin: 5px;
          .logo-airlines {
            height: 40px;
            width: 40px;
          }
        }

        &.clickable-fare {
          cursor: pointer;
          &.purple-color {
            color: $club !important;
          }
          &.primary-color {
            color: $primary;
          }
        }

        &.table-nav {
          cursor: pointer;
          padding: 10px !important;
          width: 30px;
          &.purple-nav {
            background-color: $club !important;
          }
          &.primary-nav {
            background-color: $gray-300 !important;
          }
        }
      }

      th {
        &.first-th {
          font-size: 18px;
          width: -33%;
          padding: 0px 20px;
        }

        &:first-of-type {
          width: 21%;
          font-size: 14px;
        }
      }
    }

    .club-selector {
      margin-bottom: 15px;
      background: $gray-200;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $club;
      font-weight: 700;
      
      div {
        margin: 0px !important;
        display: flex;
        margin-left: 20px !important;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 52px;
        height: 26px;
        margin: 5px 20px;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .slider {
            background-color: $club;
          }

          &:focus + .slider {
            box-shadow: 0 0 1px $club;
          }

          &:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $gray-700;
          -webkit-transition: .4s;
          transition: .4s;

          &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
          }

          &.round {
            border-radius: 34px;
            &:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .filter-options {
    background-color: $gray-700;
    border-radius: $border-radius;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;

    > span {
      @include bms-font-size(3vw, 12px);
      cursor: pointer;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 2px;

      &:before {
        content: '';
        background-image: url(#{$svg-path}/icons/white/ic-filter-solid.svg);
        background-size: 100%;
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        left: 18px;
        top: 14px;
      }
    }

    .filter-group {
      // display: none;
      .btn-group {
        position: unset;

        .btn-group {
          .btn-filter {
            @include bms-font-size(4vw, 14px);
            color: $gray-700;
            background-color: transparent;
            border: 0;
            border-bottom: 2px solid transparent;
            border-radius: 0px;
            padding: 10px 8px;
            height: 45px;
            transition: none;
          }

          .dropdown-toggle {
            &:after {
              content: '';
              float: right;
              background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
              background-size: 10px;
              background-repeat: no-repeat;
              background-position: top left;
              border: none;
              height: 12px;
              width: 12px;
              margin-top: 4px;
              margin-left: 5px;
            }
          }
        }

        .dropdown-menu {
          margin: 0;
          border: 0;
          color: $gray-700;
          background-color: $gray-300;
          border-top: 2px solid $gray-400;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          width: 100%;
          transform: translate3d(0px, 45px, 0px) !important;
          padding: .8rem;

          .flights-filter-adjust {
            .group-input {
              display: inline-grid;
              margin-right: 4px;
            }

            .arrival {
              padding-right: 50px;
            }

            .arrival,
            .departure {
              display: inline-block;

              strong {
                display: block;
                @include bms-font-size(4vw, 14px);
                margin-bottom: 10px;
              }
            }
          }

          .dropdown-divider {
            border-top-color: $gray-400;
            margin: 10px 0;
          }

          .btn-secondary {
            width: 150px;
          }
        }

        &.show {
          .btn-filter {
            border-bottom: 2px solid $primary;
          }

          .dropdown-toggle {
            &:after {
              background-image: url(#{$svg-path}/icons/gray/ic-arrow-up.svg);
            }
          }
        }
      }
    }
  }

  .dropdown {
    > a {
      @include bms-font-size(3vw, 14px);
      height: 45px;
      padding-left: 25px;
      border: 1px solid $gray-500;
      border-radius: $border-radius;
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      align-items: center;
      color: $gray-700;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }

      &:after {
        display: block;
        border: none;
        background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
        background-size: 100%;
        height: 12px;
        width: 12px;
        position: absolute;
        right: 20px;
      }
    }

    &.show {
      > a {
        border-bottom-color: $gray-400;

        &:after {
          background-image: url(#{$svg-path}/icons/gray/ic-arrow-up.svg);
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none;
      border-color: $gray-500;
      width: 100%;
      min-width: auto;

      li {
        &.dropdown-item {
          @include bms-font-size(3.5vw, 12px);
          color: $gray-700;
        }
      }

      .dropdown-item {
        @include bms-font-size(3vw, 14px);
        color: $gray-700;
        padding-left: 10px;
      }
    }

    &.sort-result {
      display: block;
      width: 98%;
      background-image: url(#{$svg-path}/icons/gray/ic-sort.svg);
      background-size: 16px;
      background-position: 5px center;
      background-repeat: no-repeat;

      .dropdown-menu {
        width: 100%;
        overflow-x: hidden;
        border: 1px solid $gray-500;
      }
    }
  }

  .filter-selected {
    display: block;
    width: 100%;
    margin-top: 20px;
    font-family: $boldFontFamily;
    text-align: left;

    .filter-item,
    .clear-filters {
      position: relative;
      float: left;
      clear: both;
      @include bms-font-size(4.3vw, 14px);
      margin: 0 10px 10px 0;
      white-space: nowrap;

      .filter-close {
        position: absolute;
        right: 5px;
        top: 20%;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(#{$svg-path}/icons/gray/ic-close-strong.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 10px;
        cursor: pointer;
      }
    }

    .filter-item {
      border: 1px solid $primary;
      border-radius: 20px;
      padding: 0.4rem 1.5rem 0.4rem 1rem;

      .filter-close {
        right: 5px;
      }
    }

    .clear-filters {
      font-weight: bolder;
      padding: 0.4rem 0 0.4rem 1.5rem;

      .filter-close {
        left: 5px;
      }
    }
  }

  .active-filters {
    margin-top: 20px;
  }
}

// Small devices
@media (max-width: 768px) {
  .filter-flights {
    padding-bottom: 40px;

    &.g3 {
      padding-bottom: 50px;

      .active-filters {
        margin-top: 35px;
      }
    }

    .resume-filters {
      table {
        td {
          .box-airline {
            .logo-airlines {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }

    .filter-options {
      justify-content: start;
      flex-direction: column;

      > span {
        margin-top: 14px;
      }

      .filter-group {
        position: relative;
        width: 100%;
        left: 0;
        top: 10px;

        .btn-group {
          .dropdown-menu {
            max-height: 60vmax;
            overflow-y: auto;

            .btn-secondary {
              max-width: 18vmax;
              margin-bottom: .8rem;
            }
          }

          .btn-group {
            .btn-filter {
              @include bms-font-size(3.2vw, 14px);
            }

            .dropdown-toggle {
              &:after {
                float: none;
                position: absolute;
                top: 30px;
                left: 50%;
                margin-left: -6px;
              }
            }
          }
        }
      }
    }

    .filter-selected {
      top: 40px;
      position: relative;

      .filter-item,
      .clear-filters {
        @include bms-font-size(3vw, 14px);
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .filter-flights {
    .filter-options {
      background-color: $gray-300;
      justify-content: flex-start;
      position: relative;

      > span {
        color: $gray-700;
        font-weight: 600;
        padding-left: 40px;
        padding-right: 10px;
        cursor: default;

        &:before {
          background-image: url(#{$svg-path}/icons/gray/ic-filter.svg);
          left: 15px;
        }
      }

      .filter-group {
        display: block;
      }
    }

    .dropdown {
      &.sort-result {
        width: 100%;
      }

      .dropdown-menu {
        .dropdown-item {
          padding-left: 25px;
        }
      }
    }

    .filter-selected {
      .filter-item,
      .clear-filters {
        clear: unset;
      }
    }
  }
}
