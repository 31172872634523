.my-account__change_confirmation {
  .confirm-change {
    @include bms-base-box;
    position: relative;
    text-align: left;
    width: 100%;

    h2 {
      text-align: left;
      font-size: 24px;
    }

    .change-option-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .change-option {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .original-flight {
        thead {
          background-color: $gray-500;
        }
      }

      .change-option-thead {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    @include media-large {
      h2 {
        font-size: 28px;
      }
    }

    @include media-large-maxw {
      .change-option {
        flex-direction: column;
      }
    }
  }
}
