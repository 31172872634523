.font-boldArticle {
  font-family: $boldFontFamily;
}

.padding_article {
  padding: 120px 10px 40px 10px;
}

.padding-sub-news {
  padding: 0 20px;
}

.container-article-reading {
  width: 100%;
  margin-left: 25px;

  .reading-timing {
    font-size: .88rem;
    color: $gray-900;

    .icon-timing {
      width: 15px;
      margin-bottom: 0px;
      margin-right: 5px;
    }
  }
}



.img {
  &__article {
    width: 100%;
    height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__facebook,
  &__instagram,
  &__twitter,
  &__linkedin,
  &__hyperlink {
    width: 25px;
    height: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(1) invert(1);
    opacity: 0.5;
  }

  &__facebook {
    background-image: url('../../../images/svg/socialNetwork/facebook.svg');
    margin: 0;
  }

  &__instagram {
    background-image: url('../../../images/svg/socialNetwork/instagram.svg');
    margin: 0;
  }

  &__twitter {
    background-image: url('../../../images/svg/socialNetwork/twitter.svg');
    margin: 0px 25px;
  }

  &__linkedin {
    background-image: url('../../../images/svg/socialNetwork/linkedin.svg');
    margin: 0;
  }

  &__hyperlink {
    background-image: url('../../../images/svg/icons/white/ic-hyperlink.svg');
    margin: 0px 25px;
  }
}

.article-btn {
  .btn-volver {
    background: none;
    border: none;
    text-decoration: underline;
    color: $primary;
    font-size: 16px;
    font-family: $boldFontFamily;
    line-height: 1;
  }

  .btn-volver:hover {
    opacity: 0.5;
  }
}

.notas-leidas-side-card {
  div {

    .card-image-settings {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 210px;
      width: 100%;
    }
  }

  .titleSize {

    &__h5 {
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
      margin-top: 10px;
    }

    &__h5:hover {
      cursor: pointer;
      opacity: .5;
      font-size: 20px;
    }
  }

  .card-text-container {
    padding: 25px 0px 35px 0px;

    .card-date {
      font-size: .9rem;
      font-family: $boldFontFamily;
      color: $gray-900;
    }

    .reading-timing {
      font-size: .83rem;
      color: $gray-900;

      .icon-timing {
        width: 15px;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    div {

      h3 {
        font-size: 19px;
      }

    }

    .btn-readNote {
      padding: 0;
      text-align: left;
      font-size: 12px;
      background: none;
      margin-top: 20px;
      border: none;
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      color: $primary !important;
      font-family: $boldFontFamily;
    }

    .btn-readNote:hover {
      opacity: .5;
    }

    .sub-head {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
    }

  }
}



.article-bottom-slider {
  .slick-slider {
    .slick-arrow {
      position: absolute;
      color: transparent;
      background-color: transparent;
      background-image: url("../../images/svg/icons/orange/ic-arrow-left-sm.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      z-index: 1;

      &.slick-prev {
        left: 41%;
        bottom: -7px;
      }

      &.slick-next {
        right: 42%;
        transform: rotate(180deg);
        bottom: -7px;
      }
    }

    .slick-dots {
      position: relative;
      margin-top: -50px;


      li {
        z-index: 2;
      }

    }
  }

}

.gray-titles{
  color: $gray-700 !important;
}

.share-notes-mobile {
  display: none;
}

.container-share-notes {
  .share-notes-desktop {
    display: block;
  }
}





@media only screen and (max-width: 575px) {
  .share-notes-mobile {
    display: block;
  }

  .container-share-notes {
    .share-notes-desktop {
      display: none;
    }
  }
  
  
  .article-bottom-filters {
    .etiquetas-filters {
      padding: 1px 25px;

      &__bottom {
        padding: 1px 0px;
        min-width: 47%;
        font-size: 13px;
      }
    }
  }

  .container-title-timing {
    flex-wrap: wrap;
  }

  .container-article-reading {
    margin-left: 0px;
  }

  .img {
    &__article {
      height: 300px;
    }
  }

  .padding_article {
    padding: 0 30px;
    margin-top: 50px;
  }

  .padding-sub-news {
    padding: 0 20px;
  }
}