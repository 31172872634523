.modal-calendar {
  display: flex !important;
  justify-content: center;
  align-items: center;
  .modal-dialog {
    align-items: center;
  }

  .modal-content {
    @media (max-width: 980px) {
      width: 980px;
    }

    @media (min-width: 1400px) {
      width: 980px;
    }
    width: 874px;
    padding: 1em;
    @media (max-width: 980px) {
      margin: 3em 0 0 0;
    }

    @media (min-width: 1400px) {
      margin: 3em 0 0 0;
    }
    margin: 3em 0 3em 0;
    align-self: center;
    border: 0;
    border-radius: 0;
    .modal-header {
      border-bottom: 0;
      padding: 0.6rem;
    }
  }
  .modal-title {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    .modal-calendar-title-header {
      display: flex;

      @media (min-width: 1400px) {
        height: 70px;
      }

      height: 34px;
      flex-direction: row;
    }

    .modal-calendar-title {
      flex: 1;
      @media (min-width: 1400px) {
        font-size: 30px;
      }
      font-size: 20px;
      color: #6d7278;
    }
  }
  .modal-title .btn-close-modal {
    text-align: right;
    @media (min-width: 1400px) {
      font-size: 40px;
    }
    font-size: 28px;
    margin-top: -8px;
    color: #6d7278;
    cursor: pointer;
  }

  .modal-content {
    @media (max-width: 980px) {
      width: 980px;
    }

    @media (min-width: 1400px) {
      width: 980px;
    }
    width: 874px;
    height: auto;
    padding: 0 1em 0 1em;
    @media (max-width: 980px) {
      margin: 3em 0 0 0;
    }

    @media (min-width: 1400px) {
      margin: 3em 0 0 0;
    }
    margin: 3em 0 3em 0;
    align-self: center;
    .modal-header {
      border-bottom: 0;
    }
  }

  .modal-calendar-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 0;
    padding: 0 1rem;
  }

  .calendar-header-destinations {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

    .destiny {
      width: 48%;
    }
    .destiny-only-going {
      width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .modal-calendar {
    .modal-lg {
      max-width: 1200px;
    }
  }
}

@media (max-width: 768px) {
  .modal-calendar {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
      border-radius: 0;
      width: 100%;
      padding: 0;
      margin: 2em 0 0 0;
      .modal-header {
        border-bottom: 0;
      }
      .modal-calendar-header {
        padding: 0;

        .modal-calendar-title-header {
          display: flex;
          flex-direction: row;
          height: 50px;
          padding: 0;
          align-items: center;
        }
      }
    }
    .modal-dialog {
      align-items: center;
      padding: 0px;
    }
    .modal-title {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      padding-left: 0.6em;
      padding-right: 0.6em;

      .modal-calendar-title {
        flex: 1;
        font-size: 22px;
        color: #6d7278;
      }
    }

    .modal-title .btn-close-modal {
      text-align: right;
      font-size: 22px;
      color: #6d7278;
      cursor: pointer;
    }
    .calendar-header-destinations {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      .destiny {
        width: 100%;
      }
    }
  }
}

@media (max-width: 375px) {
  .modal-calendar {
    .modal-content {
      margin-top: 20em;
    }
  }
}
