.member-number__tooltip {
    position: relative;
    left: 10px;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $primary;
    padding: 0.5rem;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    .member-number__arrow {
        position: absolute;
        right: 70px;
        top: 12px;
        width: 10px;
        height: 10px;
        background-color: $white;
        transform: rotate(45deg);
        border-bottom: 1px solid $primary;
        border-left: 1px solid $primary;
        z-index: 2;
        // @media only screen and (max-width: 991px) {
        //     right: 53px;
        // }
    }
    .member-number__tooltip-text {
        font-size: 14px;
        margin: 0px;
    }
}