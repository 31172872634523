.jarvis-continue-button,
.jarvis-modify-button {
  min-width: 210px;
  border-radius: 7px;
  border: none;
  color: white;
  height: auto !important;
  font-family: $semiBoldFontFamily;
  &__gray {
    background-color: $gray-600;
  }
  &__gray:hover {
    opacity: 0.5;
  }
  &__orange {
    background-color: $primary;
  }
  &__orange:hover {
    opacity: 0.5;
  }
  &__outline_orange {
    background-color: $white;
    border: 1px solid $primary;
    color: $primary;
  }
  &__outline_orange:hover {
    background-color: $primary;
    color: $white;
  }
}

.pricing-details-container {
  .pricing-details {
    .hr-strong-pricing {
      border-top: 3px solid $gray-400;
    }
    border-radius: 3px;
    border: 1px solid $gray-400;
    .item {
      font-size: 0.73em;
      font-family: $boldFontFamily;
    }
    .details-items {
      .item {
        font-size: 0.73em;
        font-family: $boldFontFamily;
      }
    }
    .total-price-container {
      flex-wrap: nowrap;
      .item {
        h6 {
          font-size: 1.1em;
        }
      }
    }
  }
}

.btn-container {
  justify-content: space-between;
}

.terms-text {
  font-size: 0.8em;
  font-family: $regularFontFamily;
  color: $gray-600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.terms-link {
  font-size: 0.8em;
  font-family: $regularFontFamily;
  color: $primary;
  margin-top: 10px;
  margin-bottom: 10px;
}

.conditions-container {
  margin: 30px 0px 45px 10px;
}

@media only screen and (max-width: 575px){
  .btn-container{
    justify-content: center;
    button{
      margin-bottom: 12px;
    }
  }
}