.table {
  &-borderless {
    thead {
      th {
        border-bottom: 1px solid $gray-300;
      }
    }
  }
  &__custom {
    tbody {
      font-size: 0.9rem;
    }
    thead {
      th {
        color: $default;
        vertical-align: top;
        border-top: 0;
      }
    }
  }
  &__collapse {
    &.table {
      background-color: $gray-200;
      margin-bottom: 0;
    }

    thead {
      th {
        color: $default;
      }
    }
  }
  &-summary {
    width: 100%;
    @include bms-font-size(3.75vw, 14px);
    thead {
      font-family: $boldFontFamily;
    }
    tr {
      height: 53px;
      border-bottom: 1px solid $gray-300;
      td {
        &:not(:first-child) {
          text-align: right;
          width: 60px;
          margin-left: 30px;
          small {
            font-family: $boldFontFamily;
            display: block;
            width: 100%;
          }
          strong {
            margin-left: 8px;
          }
        }
        strong {
          font-family: $boldFontFamily;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

// Large devices (Desktop, 768px and up)
@media (min-width: 768px) {
  .table {
    &__custom {
      margin-bottom: 20pt;
    }
    &-summary {
      tr {
        td {
          &:not(:first-child) {
            width: 200px;
          }
        }
      }
    }
  }
}
