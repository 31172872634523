.retrocredit {
  .box-airline {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 52px;
    border-radius: 3px;
    
    &:hover {
      border: 1px solid $primary !important;
    }

    @media (min-width: $site-width-mobile) {
      min-height: 95px;
    }

    &.large-box {
      max-height: unset;
      min-height: 90px;

      @media (min-width: $site-width-mobile) {
        min-height: 105px;
      }
    }

    span {
      width: 100%;
      height: 100%;

      @media (min-width: $site-width-mobile) {
        max-height: 60px;

        &.gol {
          max-height: 40px;
        }
        &.alitalia {
          max-height: 25px;
        }
        &.klm {
          max-height: 35px;
        }
        &.delta {
          max-height: 20px;
        }
        &.qatar {
          max-height: 30px;
        }
        &.aerolineas-argentinas {
          max-height: 42px;
        }
        &.etihad {
          max-height: 45px;
        }
        &.tap {
          max-height: 50px;
        }
        &.korean-air {
          max-height: 15px;
        }
        &.aero-mexico {
          max-height: 25px;
        }
        &.emirates {
          max-height: 50px;
        }
        &.avianca{
          max-height: 50px;
        }
        &.american-airlines{
          max-height: 50px;
        }
      }

      @media (max-width: $site-width-mobile) {
        &.gol {
          min-height: 25px;
        }
      }
    }
  }
}

.box-airline-container {
  display: inline-flex;
  align-items: center;
  span {
    font-size: 12px;
    width: min-content;
  }
}
