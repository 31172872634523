.my-account {
  &__sidebar {
    display: none;
  }

  &__header-info {
    @include bms-base-box;

    margin: auto;
    padding: 10px 20px;

    .user-information {
      display: block;
      font-family: $boldFontFamily;

      h3 {
        margin: 0;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
      }

      .user-id {
        font-family: $regularFontFamily;
        font-size: 12px;
        font-weight: 600;

        .smls-gray-icons {
          font-size: 8px;
        }
      }

      .miles {
        margin: 0 auto 10px auto;
        padding: 0;
        font-size: 26px;
        line-height: 26px;
      }

      .extract {
        font-size: 14px;
      }
    }

    .club-information {
      text-align: center;
      &__logo {
        width: 188px;
        float: none;
        margin: auto;
        label {
          font-family: $boldFontFamily;
          float: left;
          color: $club;
          font-size: 90px;
          letter-spacing: -2px;
          padding: 0;
          width: 100%;
          text-align: center;
          line-height: 60px;
          margin: 20px 0 0 0;
        }

        small {
          background-image: url(../images/svg/logos/smiles-purple.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 30px;
          float: left;
          text-align: center;
          background-position: center;
          margin-top: 5px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 3px solid $club;
            top: -1px;
            width: 25%;
            right: 70%;
            margin-left: 3px;
          }

          &::before {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 3px solid $club;
            top: -1px;
            width: 25%;
            left: 70%;
            margin-left: 3px;
          }
        }
      }
      &__info {
        float: left;
        padding: 20px 30px 20px 20px;
        border-right: none;
        width: 100%;

        &__plan {
          float: none;
          font-size: 18px;
          font-family: $regularFontFamily;
          background-color: $club;
          color: $white;
          padding: 10px 15px;
          clear: both;
          margin: 0 0 20px 15px;
        }
        &__status {
          border: 1px solid $club;
          border-radius: 21px;
          width: 140px;
          height: 38px;
          float: none;
          clear: both;
          font-size: 18px;
          padding: 5px 0 0 30px;
          text-align: center;
          color: $club;
          font-family: $boldFontFamily;
          &.active {
            background-image: url(../images/svg/icons/purple/ic-check.svg);
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 22px 10px;
          }
          &.suspended {
            background-image: url(../images/svg/icons/purple/ic-suspend.svg);
            background-repeat: no-repeat;
            background-size: 18px;
            background-position: 14px 11px;
            font-size: 14px;
            padding: 7px 0 0 30px;
          }
          &.blocked {
            background-image: url(../images/svg/icons/purple/ic-blocked.svg);
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 22px 10px;
          }
        }
      }
      a {
        color: $club;
        font-weight: 900;
      }
    }

    .status {
      max-width: 380px;
      .category {
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
          &:first-child {
            font-family: $boldFontFamily;
            color: $primary;
            margin: 0 30px 0 70px;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0px;
              width: 60px;
              height: 40px;
              background-size: 60px;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }
          &:last-child {
            color: $gray-500;
            font-size: 12px;
          }
        }
      }
      .qualification {
        height: 40px;
        color: $gray-700;
        b {
          display: inline-block;
          color: $primary;
          margin: 0 10px 0 25px;
        }
        span {
          position: relative;
          &:first-child {
            padding-right: 5px;
            border-right: 1px solid $gray-800;
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 1px;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url(#{$svg-path}/icons/orange/ic-database.svg);
              background-size: 20px;
              background-position: bottom center;
              background-repeat: no-repeat;
            }
          }
          &:last-child {
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url(#{$svg-path}/icons/orange/ic-flights-solid.svg);
              background-size: 16px;
              background-position: bottom center;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .message {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .diamante {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-diamond.svg);
            }
          }
        }
      }
    }
    .ouro {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-gold.svg);
            }
          }
        }
      }
    }
    .prata {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-silver.svg);
            }
          }
        }
      }
    }
    .smiles {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-smiles.svg);
            }
          }
        }
      }
    }
  }
}

.default-table {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $gray-200;
  padding: 2px;

  table {
    width: 100%;

    @include bms-font-size(3.5vw, 12px);
    @include bms-line-height(4vw, 16px);

    tr:nth-child(even) {
      background-color: $gray-200;
    }

    td,
    th {
      padding: 10px;
      vertical-align: middle;
    }

    thead {
      font-family: $boldFontFamily;
      text-align: left;
      color: $gray-700;
      background-color: $gray-200;
    }

    tbody {
      font-weight: normal;
      font-family: $ligthFontFamily;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .my-account {
    &__sidebar {
      display: block;
      float: left;

      @include bms-base-box;

      font-size: 14px;
      width: 266px;
      padding: 20px;

      ul {
        padding: 0;

        a {
          color: $gray-800;

          &.is-active {
            color: $default;
          }
        }

        > li {
          font-family: $boldFontFamily;
        }

        ul {
          padding-left: 12px;
          margin-bottom: 50px;

          li {
            margin-top: 15px;
            font-family: $regularFontFamily;
          }
        }
      }
    }

    &__header-info {
      .club-information {
        &__logo {
          float: left;
        }
        &__info {
          float: left;
          padding: 20px 30px 20px 20px;
          border-right: 1px solid $club;
          &__plan {
            float: left;
          }
        }
      }

      height: 162px;
      width: 100%;

      &.club-promo {
        margin-bottom: 28px !important;
      }
    }
  }
}
.my-account {
  &__sidebar {
    display: none;
  }

  &__header-info {
    @include bms-base-box;

    margin: auto;
    padding: 10px 20px;

    .user-information {
      display: block;
      font-family: $boldFontFamily;

      h3 {
        margin: 0;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 20px;
      }

      .user-id {
        font-family: $regularFontFamily;
        font-size: 12px;
        font-weight: 600;

        .smls-gray-icons {
          font-size: 8px;
        }
      }

      .miles {
        margin: 0 auto 10px auto;
        padding: 0;
        font-size: 26px;
        line-height: 26px;
      }

      .extract {
        font-size: 14px;
      }
    }

    .club-information {
      &__logo {
        width: 188px;
        float: none;
        margin: auto;
        label {
          font-family: $boldFontFamily;
          float: left;
          color: $club;
          font-size: 90px;
          letter-spacing: -2px;
          padding: 0;
          width: 100%;
          text-align: center;
          line-height: 60px;
          margin: 20px 0 0 0;
        }

        small {
          background-image: url(../images/svg/logos/smiles-purple.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 30px;
          float: left;
          text-align: center;
          background-position: center;
          margin-top: 5px;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 3px solid $club;
            top: -1px;
            width: 25%;
            right: 70%;
            margin-left: 3px;
          }

          &::before {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 3px solid $club;
            top: -1px;
            width: 25%;
            left: 70%;
            margin-left: 3px;
          }
        }
      }
      &__info {
        float: left;
        padding: 20px 20px 20px 20px;
        border-right: none;
        width: 100%;
        &__plan {
          float: none;
          font-size: 18px;
          font-family: $regularFontFamily;
          background-color: $club;
          color: $white;
          padding: 10px 15px;
          clear: both;
          display: block;
          width: 120px;
          margin: auto;
        }
        &__status {
          display: block;
          float: none;
          margin: 10px auto;
          border: 1px solid $club;
          border-radius: 21px;
          width: 140px;
          height: 38px;
          clear: both;
          font-size: 18px;
          padding: 5px 0 0 30px;
          text-align: center;
          color: $club;
          font-family: $boldFontFamily;
          &.active {
            background-image: url(../images/svg/icons/purple/ic-check.svg);
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 22px 10px;
          }
          &.suspended {
            background-image: url(../images/svg/icons/purple/ic-suspend.svg);
            background-repeat: no-repeat;
            background-size: 18px;
            background-position: 14px 11px;
            font-size: 14px;
            padding: 7px 0 0 30px;
          }
          &.blocked {
            background-image: url(../images/svg/icons/purple/ic-blocked.svg);
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: 22px 10px;
          }
        }
      }
    }

    .status {
      max-width: 380px;
      .category {
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
          &:first-child {
            font-family: $boldFontFamily;
            color: $primary;
            margin: 0 30px 0 70px;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0px;
              width: 60px;
              height: 40px;
              background-size: 60px;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }
          &:last-child {
            color: $gray-500;
            font-size: 12px;
          }
        }
      }
      .qualification {
        height: 40px;
        color: $gray-700;
        b {
          display: inline-block;
          color: $primary;
          margin: 0 10px 0 25px;
        }
        span {
          position: relative;
          &:first-child {
            padding-right: 5px;
            border-right: 1px solid $gray-800;
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 1px;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url(#{$svg-path}/icons/orange/ic-database.svg);
              background-size: 20px;
              background-position: bottom center;
              background-repeat: no-repeat;
            }
          }
          &:last-child {
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background-image: url(#{$svg-path}/icons/orange/ic-flights-solid.svg);
              background-size: 16px;
              background-position: bottom center;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .message {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .diamante {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-diamond.svg);
            }
          }
        }
      }
    }
    .ouro {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-gold.svg);
            }
          }
        }
      }
    }
    .prata {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-silver.svg);
            }
          }
        }
      }
    }
    .smiles {
      .category {
        span {
          &:first-child {
            &:before {
              background-image: url(#{$svg-path}/categories/ic-card-smiles.svg);
            }
          }
        }
      }
    }
  }
}

.default-table {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $gray-200;
  padding: 2px;

  table {
    width: 100%;

    @include bms-font-size(3.5vw, 12px);
    @include bms-line-height(4vw, 16px);

    tr:nth-child(even) {
      background-color: $gray-200;
    }

    td,
    th {
      padding: 10px;
      vertical-align: middle;
    }

    thead {
      font-family: $boldFontFamily;
      text-align: left;
      color: $gray-700;
      background-color: $gray-200;
    }

    tbody {
      font-weight: normal;
      font-family: $ligthFontFamily;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .my-account {
    &__sidebar {
      display: block;
      float: left;

      @include bms-base-box;

      font-size: 14px;
      width: 266px;
      padding: 20px;

      ul {
        padding: 0;

        a {
          color: $gray-800;

          &.is-active {
            color: $default;
          }
        }

        > li {
          font-family: $boldFontFamily;
        }

        ul {
          padding-left: 12px;
          margin-bottom: 50px;

          li {
            margin-top: 15px;
            font-family: $regularFontFamily;
          }
        }
      }
    }

    &__header-info {
      .club-information {
        &__logo {
          float: left;
        }
        &__info {
          display: flex;
          align-content: center;
          flex-direction: column;
          width: auto;
          float: left;
          padding: 0 30px 0 20px;
          height: 144px;
          border-right: 1px solid $club;
          &__plan {
            margin: auto auto 0 auto;
          }
          &__status {
            margin: 10px auto auto auto;
          }
        }
      }

      height: 162px;
      width: 100%;
    }
  }
}
