// Uses some styles from the global .helper-field
.retrocredit {
	.helper-field .helper-content {
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      position: absolute;
      left: 100%;
      top: 0;
      width: 225px;
      margin-left: 15px;

      &.small {
        width: 210px;
      }

      &::after, &::before {
        transform: rotate(-90deg);
        top: calc(25% - 6px);
      }

      &::after {
  			@include _base-arrow();
  			position: absolute;
        left: -10px;
  			border-color: transparent transparent $primary transparent;
  			border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
  			border-bottom-color: $primary;
  			z-index: 1;
  		}
  		&::before {
  			@include _base-arrow();
  			position: absolute;
        left: -8px;
  			border-color: transparent transparent $white transparent;
  			border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
  			border-bottom-color: $white;
  			z-index: 2;
  		}
    }
  }
}
