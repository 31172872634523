.forgotPasswordForm {
    .alertText {
        color: #5d5959;
        font-weight: bold;
    }
    .textTimer {
      padding-top: 35px;
    }
    .respTimer{
      color: #5d5959;
    }
}