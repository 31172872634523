.smiles {

    &__my-category {

        h4 {
            font-size: 18px;
            padding: 8px 0 16px;
            line-height: 20px;
        }

        .category-card {
            @include bms-base-box;

            .my-card {
                padding: 0 16px 16px;
                min-height: 280px;

                .content-card {
                    float: left;
                    width: 100%;
                    text-align: center;

                    span {
                        width: 100%;
                        height: 180px;
                        border: 0;
                    }

                    p {
                        margin-top: 8px;
                        padding: 4px 0;
                        font-size: 13px;
                        font-family: $boldFontFamily;

                        strong {
                            font-size: 16px;
                            line-height: 24px;
                        }

                        b {
                            font-size: 11px;
                            font-weight: bold;
                        }
                    }
                }

                .content-benefits {
                    display: inline-block;
                    width: 100%;

                    .benefits-title {
                        margin: 0 0 16px;

                        b, strong {
                            color: $default;
                            font-size: 14px;
                        }
                    }

                    .benefit-category-list {
                        ul {
                            margin: 0;
                            padding: 0 0 0 5px;
                            font-size: 12px;

                            li {
                                width: 100%;
                                list-style-type: none !important;
                                display: inline-block;
                                margin-bottom: 16px;
                                padding-bottom: 12px;
                            }
                        }
                    }
                }
            }

            .card-toogler{
                border-top: 1px solid $gray-300;
                padding: 12px 16px;
                position: relative;
                font-size: 13px;
                cursor: pointer;

                .smls-gray-icons {
                    position: absolute;
                    right: 20px;
                }
            }

            .toogle-body-benefits {
                ul {
                    padding: 0 0 0 20px;
                    font-size: 12px;
                }
            }
        }

        .category-ruler {
            @include bms-base-box;

            .smiles {
                &--highlight-text {
                    color: $gray-500;
                    font-size: 13px;
                }
            }

            .my-ruler {
                padding: 0 16px 16px;
                min-height: 290px;
                vertical-align: top;

                &__box {
                    display: block;
                    margin-bottom: 16px;
                }

                .ruler-info {
                    width: 100%;
                    display: block;
                    text-align: center;

                    div {
                        padding-top: 8px;
                        float: left;
                        width: 49%;
                        height: 90px;

                        p  {
                            span {
                                font-size: 16px;
                            }

                            p {
                                font-weight: bold;
                                font-size: 11px;
                                margin-bottom: 0px;
                            }

                            &:last-child {
                                font-size: 11px;
                            }
                        }
                    }
                }

                .ruler-status {
                    width: 100%;
                    display: inline-block;
                    font-size: 11px;

                    div {
                        width: 100%;

                        .categories {
                            margin: 0;
                            padding: 0;
                            display: table;
                            width: 100%;
                            position: relative;
                            height: 15px;

                            span {
                                display: table-cell;
                                vertical-align: middle;
                                text-transform: uppercase;

                                &:not(:last-child) {
                                    width: 25%;
                                    text-align: left;
                                }

                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }

                        .progress-bar {
                            border: 1px solid $gray-400;
                            border-radius: 12px;
                            width: 100%;
                            height: 12px;
                            overflow: hidden;
                        }

                        .quantity {
                            margin: 0;
                            padding: 0;
                            display: table;
                            width: 100%;
                            position: relative;
                            height: 15px;

                            span {
                                display: table-cell;
                                vertical-align: middle;
                                text-transform: uppercase;

                                &:not(:last-child) {
                                    width: 25%;
                                    text-align: left;
                                }

                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    .divider {
                        text-align: center;
                        height: 25px;
                        padding-top: 5px;

                        span {
                            display: inline-block;
                            position: relative;
                            text-align: center;

                            &::before {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 1px solid $gray-400;
                                top: 0;
                                width: 120px;
                                right: 100%;
                                margin-right: 15px;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                height: 9px;
                                border-bottom: 1px solid $gray-400;
                                top: 0;
                                width: 120px;
                                left: 100%;
                                margin-left: 15px;
                            }
                        }
                    }

                    .title {

                        p {
                            padding: 0 0 8px 0;
                            position: relative;
                            color: $gray-700;
                            margin: 0 0 8px 20px;
                            font-weight: 100;
                            font-size: 12px;

                            .smls-gray-icons {
                                position: absolute;
                                top: 0px;
                                left: -20px;
                            }
                        }
                    }
                }

                div:nth-child(3) {
                    font-size: 12px;
                }


            }

            .ruler-toogler {
                border-top: 1px solid $gray-300;
                padding: 12px 16px;
                position: relative;
                font-size: 13px;
                cursor: pointer;

                span {

                    b {
                        font-size: 13px;
                    }
                }

                .smls-gray-icons {
                    position: absolute;
                    right: 20px;

                    &.arrow-up {
                        &::before {
                            background-position: 0em -1.6em;
                            width: 1.3em;
                            height: 0.8em;
                            content: ' ';
                            vertical-align: middle;
                            display: inline-block;
                            background-image: url(sprite/img/smiles-icons.svg);
                            background-repeat: no-repeat;
                            background-size: 51.2em 356.5em;
                        }
                    }
                }
            }

            .toogle-body {
                padding: 8px 16px;

                p {
                    font-size: 12px;
                    margin: 0 0 10px;
                    font-weight: 100;
                    line-height: 18px;
                }

                b {
                    font-size: 12px;
                }
            }

            ul {
                padding: 0;
                margin: 4px 0 10px 25px;

                li {
                    list-style-type: disc;
                    font-size: 12px;
                    padding-bottom: 12px;
                    width: 100%;
                }
            }

            .flight-steps {
                position: relative;
                display: table;
                margin-top: 48px;
                margin-left: 16px;
                height: 40px;

                &:last-child {
                    margin-bottom: 16px;
                }

                div {
                    display: inline-block;
                }

                span {
                    position: absolute;
                    bottom: 25px;

                    &.leg-trip-one {
                        width: 90px;
                        height: 25px;
                        background-image: url("../images/svg/icons/gray/ic-leg-trip-one.svg");
                    }

                    &.leg-trip-two {
                        width: 172px;
                        height: 25px;
                        background-image: url("../images/svg/icons/gray/ic-leg-trip-two.svg");
                    }
                }

                .circle {
                    position: relative;
                    margin-right: 84px;

                    .info-text {
                        position: absolute;
                        left: -20px;
                        bottom: -22px;
                        font-size: 13px;
                    }

                    &.last {
                        margin-right: 28px;
                    }
                }

                .final-info {
                    font-size: 12px;
                    display: inline-block;
                }
            }
        }

        .category-history {
            @include bms-base-box;

            .my-history {
                padding: 0 16px 16px;

                p {
                    font-family: $regularFontFamily;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 100;
                }

                ul {
                    li {
                        font-family: $regularFontFamily;
                        list-style-type: disc;
                        font-size: 12px;
                        padding-bottom: 12px;
                        width: 100%;
                    }
                }
            }

            .history-toogler {
                border-top: 1px solid $gray-300;
                padding: 12px 16px;
                position: relative;
                font-size: 13px;
                cursor: pointer;

                .smls-gray-icons {
                    position: absolute;
                    right: 20px;

                    &.arrow-up {
                        &::before {
                            background-position: 0em -1.6em;
                            width: 1.3em;
                            height: 0.8em;
                            content: ' ';
                            vertical-align: middle;
                            display: inline-block;
                            background-image: url(sprite/img/smiles-icons.svg);
                            background-repeat: no-repeat;
                            background-size: 51.2em 356.5em;
                        }
                    }
                }
            }

            .history-table {
                padding: 8px 16px;

                table {
                    width: 100%;
                    font-size: 11px;

                    tr {
                        border: 0;

                        th {
                            padding: 8px;
                            border: 0px;
                            font-family: $boldFontFamily;
                        }

                        td {
                            padding: 8px;
                            border: 0px;
                            font-family: $ligthFontFamily;
                        }
                    }
                }
            }
        }

        .my-category {
            &__footer {
                font-family: $ligthFontFamily;
                font-size: 11px;
                margin: 0 8px 16px;
                color: $gray-700;
                line-height: 20px;

                h5 {
                    font-size: 12px;
                    color: $gray-700;
                }

                b {
                    font-family: $boldFontFamily;
                    font-size: 11px;
                }

                p {
                    font-family: $ligthFontFamily;
                    line-height: 20px;
                    font-size: 11px;

                    a {
                        text-decoration: underline;
                        color: $gray-700;
                    }
                }
            }
        }

        //Categoria Smiles
        &.smiles {
            .content-card {
                span {
                    background: url(../images/svg/categories/ic-card-smiles.svg) no-repeat center;
                    background-size: contain;
                }
                p {
                    strong {
                        color: $smiles;
                    }
                }
            }
            .card-toogler {
                display: none !important;
            }
            .content-benefits {
                .benefits-smiles {
                    display: block;
                }
                .benefits-silver {
                    display: none;
                }
                .benefits-gold {
                    display: none;
                }
                .benefits-diamond {
                    display: none;
                }
            }
            .ruler-status {
                .bar {
                       background: linear-gradient(to right, rgba(244,121,32,0.62) 0%, #ff5a00 100%);
                }
            }
        }

        //Categoria Prata
        &.silver {
            .content-card {
                span {
                    background: url(../images/svg/categories/ic-card-silver.svg) no-repeat center;
                    background-size: contain;
                }
                p {
                    strong {
                        color: $silver;
                    }
                }
            }
            .card-toogler {
                display: none !important;
            }
            .content-benefits {
                .benefits-smiles {
                    display: none;
                }
                .benefits-silver {
                    display: block;
                }
                .benefits-gold {
                    display: none;
                }
                .benefits-diamond {
                    display: none;
                }
            }

            .ruler-status {
                .bar {
                    background: linear-gradient(to right, #eee 0%, #989697 100%);
                }
            }
        }

        //Categoria Ouro
        &.gold {
            .content-card {
                span {
                    background: url(../images/svg/categories/ic-card-gold.svg) no-repeat center;
                    background-size: contain;
                }
                p {
                    strong {
                        color: $gold;
                    }
                }
            }
            .content-benefits {
                .benefits-smiles {
                    display: none;
                }
                .benefits-silver {
                    display: none;
                }
                .benefits-gold {
                    display: block;
                }
                .benefits-diamond {
                    display: none;
                }
            }
            .ruler-status {
                .bar {
                    background: linear-gradient(to right, #e5d8ae 0%, #a98c5d 100%);
                }
            }
        }

        //Categoria Diamante
        &.diamond {
            .content-card {
                span {
                    background: url(../images/svg/categories/ic-card-diamond.svg) no-repeat center;
                    background-size: contain;
                }
                p {
                    strong {
                        color: $diamond;
                    }
                }
            }
            .content-benefits {
                .benefits-smiles {
                    display: none;
                }
                .benefits-silver {
                    display: none;
                }
                .benefits-gold {
                    display: none;
                }
                .benefits-diamond {
                    display: block;
                }
            }
            .ruler-status {
                .bar {
                    background: linear-gradient(to right, #eaeaea 0%, #000 100%);
                }
            }
        }

        .visible-desktop {
            display: none !important;
        }

        .visible-phone {
            display: inherit !important;
        }
    }
}

@media (min-width: 768px){
    .smiles {
        &__my-category {
            .visible-desktop {
                display: table !important;
            }

            .visible-phone {
                display: none !important;
            }
            .category-card {
                .my-card {
                    .content-card {
                        width: 35%;
                        span {
                            width: 290px;
                            height: 144px;
                            margin: 0;
                        }
                    }
                    .content-benefits {
                        width: 65%;
                        .benefit-category-list {
                            ul {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .category-ruler {
                .my-ruler {
                    &__box {
                        display: flex;
                    }
                    .ruler-info {
                        width: 25%;
                        div {
                            height: 110px;
                            padding-top: 8px;
                            width: auto;
                            float: none;
                            p  {
                                font-weight: bold;
                                font-size: 16px;
                                margin-bottom: 0px;
                                padding-top: 8px;

                                span {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .ruler-status {
                        width: 50%;
                        .title {
                            p {
                                padding: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .ruler-toogler {
                    font-size: 15px;
                }

                .toogle-body {
                  p {
                        font-size: 13px;
                        line-height: 20px;
                    }

                    b {
                        font-size: 13px;
                    }

                }
                ul {
                    li {
                        font-size: 13px;
                    }
                }
            }
            .category-history {
                .my-history {
                    p {
                        font-size: 13px;
                        line-height: 20px;
                    }

                    ul {
                        li {
                            font-size: 13px;
                        }
                    }
                }

                .history-toogler {
                    font-size: 15px;
                }

                .history-table {
                    table {
                        font-size: 14px;
                    }
                }
            }
            .my-category {
                &__footer {
                    h5 {
                        font-size: 12px;
                    }

                    b {
                        font-size: 12px;
                    }

                    p {
                        line-height: 18px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.benefit-category-list ul {
    margin: 0;
}
.benefit-category-list ul li {
    list-style-type: none !important;
    display: inline-block;
    margin-bottom: 16px;
}
.benefit-category-list ul li div>div:first-child {
    vertical-align: top;
    width: 8%;
    text-align: center;
    display: inline-block;
}
.benefit-category-list ul li div>div:last-child {
    width: 88%;
    display: inline-block;
}
.benefit-category-list.half-column ul li div>div:first-child {
    width: 12%;
}
.benefit-category-list.half-column ul li div>div:last-child {
    width: 85%;
}
.benefit-category-list.half-column {
    columns: 2;
}
@media (max-width: 768px) {
    .benefit-category-list.half-column {
        columns: 1;
    }
}

.benefit-category-list ul {
    margin: 0;
}
.benefit-category-list ul li {
    list-style-type: none !important;
    display: inline-block;
    margin-bottom: 16px;
}
.benefit-category-list ul li div>div:first-child {
    vertical-align: top;
    width: 8%;
    text-align: center;
    display: inline-block;
}
.benefit-category-list ul li div>div:last-child {
    width: 88%;
    display: inline-block;
}
.benefit-category-list.half-column ul li div>div:first-child {
    width: 12%;
}
.benefit-category-list.half-column ul li div>div:last-child {
    width: 85%;
}
.benefit-category-list.half-column {
    columns: 2;
}
@media (max-width: 768px) {
    .benefit-category-list.half-column {
        columns: 1;
    }
}
