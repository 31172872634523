$background-colors-list: 'F7F7F7', '000000', '555555';
$charjv: "#";
@each $current-color in $background-colors-list {
    .bgd-#{$current-color} {
        background-color: unquote($charjv+$current-color);
    }
    .cld-#{$current-color} {
        color: unquote($charjv+$current-color);
    }
}

.faq .h-px-50 {
    height: 50px;
}

.faq .all-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.faq .cd {
    float: left;
    margin: 10px 0px;
}
.faq .card {
    height: 534px;
    margin: 10px;
    background-color: #FFFFFF;
    border: 1px solid #FF5A00;
    box-shadow: inset 6px 0 0 0 #FF5A00;

    div.dText {
        padding: 0 20px 0px 25px;
        height: 310px;
        overflow: auto;
    }

    p.text {
        margin-bottom: 27px;
        text-align: left;
        padding: 0 30px;

        a {
            color: #555555;
            font-family: NunitoRegular, sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            text-align: left;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .head-icon {
        margin: 28px 0 20px;
        text-align: center;

        h6 {
            padding-top: 8px;
        }

        img {
            max-width: 100%;
        }
    }

    .vermas {
        font-family: NunitoRegular, sans-serif;
        text-decoration: none;
    }
}

.faq .grid {
    min-height: 55px;
    overflow: hidden;
    background-color: #FFFFFF;
    border: 1px solid #FF5A00;
    box-shadow: inset 6px 0 0 0 #FF5A00;

    div.dText {
        padding: 20px 0;
        overflow-y: auto;
        overflow-x: hidden;
        float: left;

        h6 {
            margin: 0 0px;
            color: #555555;
            padding: 0 15px;
            float: left;
            text-align: left;
        }
    }
    div.dBtn {
        padding: 20px 0;
        float: left;

        img {
            padding: 0px 10px;
        }
    }
}

.faq .pointer {
    cursor: pointer;
}
