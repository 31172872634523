.historyData-booking-container{
    .historyData-map{
        span{
            font-size: 14px;
        }
        .ic-delete-container{
            .ic-delete{
                cursor: pointer;
                height: 30px;
                width: 30px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url("../../../images/svg/icons/gray/ic-delete.svg");
            }
        }
    }
}