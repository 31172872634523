$color-primary: #ff5a00;

.invite-friends-modal {
  margin-top: 3%;

  .modal-content {
    border-radius: 20px;

    .top-section {
      padding: 0 10px;
    }

    button.close {
      opacity: unset;
    }

    #invitationCodeClipboard {
      text-align: center;
      vertical-align: bottom;
    }
  }
}

.invite-friends {
  &.my-account {
    width: 85%;
  }
  .top-section {
    .h2 {
      margin-bottom: 20px;
    }

    .invitation-code-container {
      cursor: pointer;

      .copy-code,
      .invitation-code {
        height: 50px;
        padding: 15px 20px;

        &#invitationCodeClipboard {
          padding: 5px 10px;
          font-size: 0.9rem;
          vertical-align: bottom;

          @media (min-width: 670px) {
            font-size: 1.3rem;
          }
        }
      }

      button {
        @media (min-width: 670px) {
          vertical-align: baseline;
        }
      }

      .invitation-code {
        cursor: pointer;
        font-size: 1.4rem;
        border-top: 1px dashed #888;
        border-left: 1px dashed #888;
        border-bottom: 1px dashed #888;
        width: 55%;

        @media screen and (max-width: 480px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 320px) {
          width: 100%;
          border-right: 1px dashed #888;
        }
      }

      .copy-code {
        width: 35%;
        font-family: inherit;
        margin-top: -7px;

        .invitar-amigos-copy-responsive {
          @media (max-width: 944px) {
            display: none !important;
          }
        }

        small {
          font-size: 65%;
          vertical-align: top;
        }

        @media screen and (max-width: 480px) {
          vertical-align: bottom;
          margin-top: -10px;
          padding: 10px 0px;
        }

        @media screen and (max-width: 320px) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }

    .share-buttons {
      span {
        @media (max-width: 972px) {
          font-size: 1.1rem;
        }
      }
      #whatsapp-green-fill {
        circle {
          fill: #60fc7c;
        }
      }
      p.share-button {
        height: 50px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
          width: 145px;
          text-align: left;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &.vertical {
        @media (min-width: 970px) {
          display: flex;
          justify-content: center;
          align-items: center;

          > p.share-button {
            flex: 1;
            justify-content: center;
            padding: 0 2vw;
            display: flex;
            margin-bottom: 0px;
          }
        }
      }
    }

    .share-button-border {
      border-bottom: 1px dashed #cfcfcf;
    }

    .share-button-border-vertical {
      border-right: 1px solid #cfcfcf;
      @media (max-width: 969px) {
        border-right: 0;
        border-bottom: 1px solid #cfcfcf;
      }
    }
  }

  .bottom-section {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    &.beforeIcon {
      padding-top: 25px;
      background-color: #f5f5f5;

      &::before {
        content: "";
        position: relative;
        top: -6px;
        width: 20px;
        height: 20px;
        border-top: 20px solid #fff;
        border-right: 20px solid #f5f5f5;
        border-left: 20px solid #f5f5f5;
        background: #ffffff 0% 0% no-repeat padding-box;
      }
    }

    p.h6 {
      color: #7c7c7c;
    }
  }
  .club-text {
    color: $club;
  }
}

.invitation-link-copied-modal {
  margin-top: 5%;

  .modal-content {
    border-radius: 20px;

    button.close {
      opacity: unset;
    }

    p.h3 {
      padding: 50px;
    }
  }
}

.btn-invite-friends {
  @media screen and (max-width: 375px) {
    padding: 0.2rem 0.2rem;
    font-size: 75%;
  }
}

.bannerParent {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
}

.member-get-member-container {
  $default-section-margin: 40px;
  margin-top: 20px;

  &.logged {
    margin-top: 65px;
  }

  &.my-account {
    margin-top: 0 !important;

    .cloud-arrow-container {
      display: none;
    }

    .smiles-club-info {
      margin-top: 30px;

      p {
        &:nth-child(3) {
          width: 80%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    .smiles-club-details {
      width: 100%;
      padding: 0px 0px 20px;

      @media screen and (max-width: 480px) {
        padding: 0px 20px 20px;
      }

      div {
        div {
          div {
            &:first-child {
              padding: 30px 20px;

              img {
                max-width: 80%;
              }
            }

            &:nth-child(2) {
              margin-left: 30px;
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  $gray: #e8e1f0;
  $purple: #6d3c9d;
  $yellow: #fef101;
  $logo_color: white;
  $width: 1179px;

  .accession {
    background: $purple;
    width: 83%;

    @media(max-width: $width) {
      width: 100%;
    }

    margin: 100px auto;

    &:first-of-type {
      margin-top: 0;
    }

    @media(max-width: $width) {
      margin: 30px auto;
    }

    padding: 0 30px;
    padding-bottom: 30px;

    .info {
      display: flex;

      @media(max-width: $width) {
        flex-direction: column;
      }

      .info-first-block {
        flex: 1;

        background: $gray;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;

        padding-right: 15px;

        @media(max-width: $width) {
          padding-right: 0;
          padding-bottom: 15px;
          margin-bottom: 15px;
          background: #fff;
        }

        .club {
          label {
            font-size: 25px;
            font-weight: bold;

            margin-top: 15px;
            margin-bottom: 0;

            color: $purple;
            position: relative;
            &::after, &::before {
              content: '\00a0';
              position: absolute;
              border-bottom: 3px solid $purple;
              top: 8px;

              @media(max-width: $width) {
                border-color: white;
                top: 10px;
              }

              width: 31px;
            }

            &::after {
              right: -6px;
            }
            &::before {
              left: -6px;
            }

          }
        }

        .logo {
          width: 60px;
          filter: invert(30%) sepia(145%) saturate(6089%) hue-rotate(253deg) brightness(53%) contrast(106%);
        }

        .info-title {
          &.txtPurple {
            position: relative;
            color: $purple;
            margin-top: 20px;
            font-size: 54px;
            font-weight: bold;

            &::after {
              content: '+';
              position: absolute;
              right: -48px;
              top: -40px;
              color: $yellow;
              background: $purple;
              padding: 20px 15px;
              border-radius: 100%;

              @media(max-width: $width) {
                right: 125px;
                top: 53px;
              }

              @media(max-width: 1024px) {
                right: 580px;
                top: 58px;
              }

              @media(max-width: 768px) {
                right: 320px;
                top: 58px;
              }

              @media(max-width: 812px) {
                right: 342px;
                top: 58px;
              }

              @media(max-width: 736px) {
                right: 305px;
              }

              @media(max-width: 667px) {
                right: 268px;
              }

              @media(max-width: 640px) {
                right: 230px;
              }

              @media(max-width: 568px) {
                right: 222px;
              }

              @media(max-width: 414px) {
                right: 145px;
              }

              @media(max-width: 375px) {
                right: 125px;
              }

              @media(max-width: 360px) {
                right: 120px;
              }

              @media(max-width: 320px) {
                right: 100px;
              }

            }
          }

          &.txtMillas {
            text-transform: uppercase;

            letter-spacing: 1px;
            margin-top: 10px;
          }
        }
      }

      .info-third-block {
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .info-third-block-months {
          display: flex;

          .info-third-month {
            margin-bottom: 0;
            padding: 0 30px;

            @media(max-width: $width) {
              padding: 0 15px;
            }

            &:first-of-type {
              padding-left: 0px;
            }

            font-size: 24px;
            @media(max-width: $width) {
              font-size: 16px;
            }

            border-right: 2px dashed yellow;
            &:last-of-type {
              border-right: none;
              padding-right: 0;
            }

            text-transform: uppercase;

            color: white;
            span {
              display: block;

              &:first-of-type {
                color: $yellow;

                font-size: 40px;
                @media(max-width: $width) {
                  font-size: 34px;
                }
                font-weight: bold;

                letter-spacing: 0;
                margin-top: 20px;
                margin-bottom: 13px;
              }

              &.txtBonus {
                margin-left: 7px;
                letter-spacing: 9px;
                font-size: 20px;

                @media(max-width: $width) {
                  letter-spacing: 6px;
                  font-size: 14px;
                }

                font-weight: normal;
                text-transform: lowercase;
              }
            }
          }
        }
      }

      .info-fourth-block {
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        background: $gray;
        border-top-right-radius: 20px;
        @media (max-width: $width) {
          background: #fff;
          border-top-right-radius: 0px;
        }

        margin-top: 25px;
        a {
          text-decoration: none;
          padding: 15px;

          @media(max-width: $width) {
            display: grid;
            grid-template-columns: 1fr 41%;
            grid-template-rows: 1fr 30px;
          }

          span {
            display: block;
            color: $purple;

            &.txtSubscribe {
              font-size: 26px;
              line-height: 25px;
              margin: 0 30px;

              @media(max-width: $width) {
                margin: 0;
              }

              font-weight: bold;

              @media(max-width: $width) {
                grid-row-start: 1;
                grid-row-end: 3;
                display: flex;
                align-items: center;
              }
            }

            &.txtRegular {
              margin-top: 7px;

              line-height: 38px;
              font-size: 34px;

              @media(max-width: $width) {
                font-size: 22px;
              }

              @media(max-width: 360px) {
                font-size: 21px;
              }

              font-weight: bold;
            }

            &.txtMonth {
              font-size: 22px;
              text-transform: uppercase;
              letter-spacing: 2px;

              @media(max-width: 360px) {
                font-size: 20px;
              }

              @media(max-width: 320px) {
                font-size: 17px;
              }
            }
          }
        }
      }
    }

    &.grayish {
      background: $gray;
      @media(max-width: $width) {
        background: white;
      }

      .info-first-block {
        background: $purple;

        .club label {
          color: white;

          &::before, &::after {
            border-color: white;
          }
        }

        .logo {
          filter: none;
        }

        p.info-title {
          color: white !important;
          &::after {
            color: $purple !important;
            background: $gray !important;
            @media(max-width: $width) {
              background: white !important;
            }
          }
        }
      }

      .info-third-block > .info-third-block-months > p.info-third-month {
        border-color: $purple;
        color: #555555;

        span {
          color: $purple !important;
        }

        .txtBonus {
          color: #555555 !important;
        }
      }

      .info-fourth-block {
        background: $purple;
        a > span {
          color: white;
        }
      }
    }
  }

  .smiles-club-info {
    margin-bottom: 40px;

    p {
      &:first-child {
        color: $club;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 1.3;

        @media screen and (max-width: 320px) {
          font-size: 1.5rem;
        }
      }

      &:nth-child(2) {
        color: #7c7c7c;
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 23px;

        @media screen and (max-width: 480px) {
          font-size: 1.25rem;
        }
      }

      &:nth-child(3) {
        color: $club;
        font-size: 1.2rem;
        width: 52%;
        margin: auto;
        line-height: 1.3;

        @media screen and (max-width: 992px) {
          width: 80%;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          font-size: 1.0rem;
        }
      }
    }
  }

  .smiles-club-details {
    background-color: #f5f5f5;
    padding: 0px 20px 20px;
    margin: auto;
    width: 80%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    div {
      div {
        div {
          display: inline-block;

          @media screen and (max-width: 992px) {
            display: block;
          }

          &:first-child {
            position: relative;
            background-color: $club;
            padding: 30px 60px;
            border-bottom-right-radius: 35px;
            border-bottom-left-radius: 35px;

            &::after {
              content: '+';
              position: absolute;
              bottom: -34px;
              bottom: 38%;
              right: -28px;
              font-weight: bold;
              color: $club;
              background: #f5f5f5;
              border-radius: 50%;
              width: 60px;
              font-size: 2.5rem;

              @media screen and (max-width: 992px) {
                bottom: -34px;
                left: 42%;
                right: inherit;
              }
            }
          }

          &:nth-child(2) {
            margin-left: 40px;
            margin-right: 40px;

            @media screen and (max-width: 992px) {
              margin: 50px 0px 20px;
            }

            img {
              max-width: 100%;
            }
          }

          &:nth-child(3) {
            vertical-align: bottom;
            background-color: $club;
            padding: 15px 20px;
            border-top-right-radius: 35px;

            @media screen and (max-width: 992px) {
              border-top-right-radius: 0px;
              padding: 20px;
            }

            img {
              max-width: 100%;

              &.hide-desktop {
                @media screen and (min-width: 992px) {
                  display: none;
                }
              }

              &.hide-mobile {
                @media screen and (max-width: 992px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .smiles-club-benefits {
    h2 {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  .bannerParent {
    position: relative;
    z-index: 0;

    .go-to-terms {
      position: absolute;
      bottom: 40px;
      left: 30px;

      font-size: 1.1rem;

      color: white;
      text-decoration: underline;

      cursor: pointer;

      @media(max-width: 767px) {
        display: none;
      }

    }
  }

  .club-title {
    margin-top: 100px;
  }

  .smiles-club-info {
    padding: 0px 20px;
  }

  .club-title {
    color: $club;

    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
      margin-bottom: 30px;
    }
  }

  .club-sub-title {
    color: #bdbdbd;
    margin-bottom: $default-section-margin;
  }

  .club-text {
    color: $club !important;
  }
  .border-club {
    border-color: $club !important;
  }

  .promotion-info {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    background: #ccc4c47a 0% 0% no-repeat padding-box;

    @media screen and (max-width: 767px) {
      padding: 0px 10%;
      margin-left: 15px;
      margin-right: 15px;

      h2.club-title {
        font-size: 1.8rem;
      }

      h3.club-sub-title {
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 320px) {
      padding: 0px 5%;
    }

    > div {
      margin: auto;
    }

    .club-title,
    .club-sub-title {
      margin: auto;
    }

    .club-title {
      line-height: 1.5;
      font-size: 2.3rem;
    }

    .club-sub-title {
      color: $club;
      font-size: 1.9rem;
    }

    &.mixed {
      @media screen and (min-width: 992px) {
        .club-title {
          width: 69%;
        }

        &.my-account {
          .club-title {
            width: 87%;
          }
        }
      }

      @media screen and (min-width: 768px) {
        padding: 0 20px;
      }

      @media screen and (max-width: 767px) {
        .club-title {
          width: 100%;
          font-size: 1.7rem;
        }

        .club-sub-title {
          font-size: 1.1rem;
        }
      }
    }
  }

  .btn-subscribe-club {
    width: 269px;
    height: 50px;
    background: $club 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    font-size: 1.3rem;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      width: 300px;
    }
  }

  .link-terms-and-conditions {
    padding-inline-end: 16px;
    padding-bottom: 5px;
  }

  .btn-terms-and-conditions {
    border-radius: 5px;

    &::after {
      content: "";
      width: 10px;
      height: 10px;
      border-bottom: 2px solid #ff5a00;
      border-right: 2px solid #ff5a00;
      display: inline-block;
      margin-left: 10px;
      margin-right: 5px;
      transform: rotate(-43deg);
      margin-top: -5px;
    }

    &:hover {
      &::after {
        border-color: #fff;
      }
    }

    @media screen and (max-width: 768px) {
      width: 290px;
      height: 50px;

      .terms-button-text {
        display: flex;
      }
    }
  }

  .invite-friends-info {
    overflow: hidden;
    transition: opacity 0.3s linear;
    text-align: center;
    background: transparent 0% 0% no-repeat padding-box;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: space-between !important;
    width: 85% !important;
    margin: 0 auto;
    background-color: #F3E8FF;
    border: 1px solid $club;

    &.no-club {
      background-color: #FFF4F0;
      border: 1px solid $color-primary;

      p {
        text-align: left;
        font-weight: 700;
        color: $color-primary;
      }
    }

    img {
      margin-bottom: -2px;
    }

    .club-text {
      text-align: left;
      font-weight: 700;
    }

    @media screen and (max-width: 767px) {
      padding: 0px;
      flex-direction: column;
      align-items: center;

      img {
        min-width: 100%;
        height: 150px;
        object-fit: cover;
      }

      p {
        text-align: center;
        font-size: 1.2rem;
        max-width: 80%;
        margin: 0 auto !important;
        min-width: 100%;
        height: 150px;
        object-fit: cover;
        padding: 40px 20px;
      }
    }

    .subtitle-mobile {
      @media (max-width: 816px) {
        width: 100%;
      }
      @media screen and (max-width: 420px) {
        font-size: 1.15rem;
      }
    }

    h1 {
      font-size: 2.4rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 420px) {
        font-size: 1.7rem;
      }
    }

    #cloudOne,
    #cloudTwo,
    #cloudThree,
    #cloudFour {
      position: absolute;

      span {
        background-color: #cacaca;
      }

      @media (max-width: 991px) {
        &#cloudThree,
        &#cloudFour {
          display: none;
        }

        &#cloudOne {
          top: 120px;
          left: 54px;
          @media (max-width: 325px) {
            top: 95px;
          }
          @media (max-width: 415px) {
            top: 155px;
          }
          @media (max-width: 375px) {
            top: 86px;
          }
          &.my-account {
            top: 110px;
            @media (max-width: 415px) {
              top: 160px;
            }
            @media (max-width: 375px) {
              top: 42%;
            }
          }
          .cloud-center {
            width: 19px;
            height: 19px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -23px;
          }

          .cloud-right {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 37px;
          }
        }

        &#cloudTwo {
          top: 18px;
          left: 380px;
          &.my-account {
            left: 350px;
          }
          .cloud-center {
            width: 25px;
            height: 25px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -19px;
          }

          .cloud-right {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 37px;
          }
        }
      }

      @media (max-width: 768px) {
        &#cloudOne {
          top: 50%;
        }

        &#cloudTwo {
          left: 793px;
        }
      }

      @media (max-width: 480px) {
        &#cloudTwo {
          left: 440px;
        }
      }

      @media (max-width: 380px) {
        &#cloudTwo {
          left: 400px;
        }
      }

      @media (max-width: 360px) {
        &#cloudTwo {
          left: 383px;
        }
      }

      @media (max-width: 320px) {
        &#cloudOne {
          top: 41%;
        }

        &#cloudTwo {
          left: 343px;
        }
      }

      @media screen and (min-width: 481px) and (max-width: 767px) {
        &#cloudTwo {
          left: 665px;
        }
      }

      @media (min-width: 992px) {
        &#cloudOne {
          top: 150px;
          left: 110px;
          &.my-account {
            top: 90%;
            left: 85px;
          }
          .cloud-center {
            width: 25px;
            height: 25px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -19px;
          }

          .cloud-right {
            width: 12px;
            height: 12px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 37px;
          }
        }

        &#cloudTwo {
          top: 167px;
          left: 92px;
          &.my-account {
            top: 97%;
            left: 75px;
          }
          .cloud-center {
            width: 10px;
            height: 10px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 5px;
            height: 5px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -32px;
          }

          .cloud-right {
            width: 5px;
            height: 5px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 38px;
          }
        }

        &#cloudThree {
          top: 45%;
          left: 1200px;
          &.my-account {
            left: 800px;
          }
          .cloud-center {
            width: 42px;
            height: 42px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 20px;
            height: 20px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -2px;
          }

          .cloud-right {
            width: 20px;
            height: 20px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 37px;
          }
        }

        &#cloudFour {
          top: 90px;
          left: 1160px;
          &.my-account {
            top: 40%;
            left: 760px;
          }

          .cloud-center {
            width: 10px;
            height: 10px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -40px;
          }

          .cloud-left {
            width: 5px;
            height: 5px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            right: -32px;
          }

          .cloud-right {
            width: 5px;
            height: 5px;
            clip-path: circle(50% at 50% 20%);
            -webkit-clip-path: circle(50% at 50% 20%);
            left: 38px;
          }
        }
      }
    }

    p {
      margin: 0 0 0 25px;
      color: #7c7c7c;
      width: 830px;
      font-size: 1.5rem;
      line-height: 1.2;
      left: 0;
      right: 0;

      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
      }
    }
  }

  .share-with-friends {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    &.my-account {
      background: transparent;
    }
  }

  .image-banner {
    width: 100%;
  }

  .cloud-arrow-container {
    > div {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;

      .cloud-arrow-center {
        &.my-account {
          display: none;
        }
        background-color: #f5f5f5;
        width: 80px;
        height: 80px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        position: absolute;
        left: -40px;

        &::before {
          content: "";
          border-bottom: 3px solid $club;
          border-right: 3px solid $club;
          width: 20px;
          height: 20px;
          position: relative;
          transform: rotate(45deg);
          display: block;
          left: 0;
          right: 0;
          margin: auto;
          top: 10px;
        }
      }

      .cloud-arrow-left {
        background-color: #f5f5f5;
        width: 40px;
        height: 40px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        display: inline-block;
        position: absolute;
        right: 28px;
      }

      .cloud-arrow-right {
        background-color: #f5f5f5;
        width: 40px;
        height: 40px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        display: inline-block;
        position: absolute;
        left: 25px;
      }
    }
  }

  .cloud-container {
    transform: rotate(180deg);
    margin-bottom: 100px;
    z-index: 0;

    > div {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;

      .cloud-center {
        background-color: #f5f5f5;
        width: 80px;
        height: 80px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        position: absolute;
        right: -40px;
      }

      .cloud-left {
        background-color: #f5f5f5;
        width: 40px;
        height: 40px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        display: inline-block;
        position: absolute;
        right: 35px;
      }

      .cloud-right {
        background-color: #f5f5f5;
        width: 40px;
        height: 40px;
        clip-path: circle(50% at 50% 20%);
        -webkit-clip-path: circle(50% at 50% 20%);
        display: inline-block;
        position: absolute;
        left: 35px;
      }
    }
  }

  .mgm-current-reward {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    width: 384px;
    height: 133px;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0px 20px;

    > p {
      font-size: 1.1rem;
    }

    &.my-account {
      right: 5%;

      &.with-miles {
        height: 198px;

        @media screen and (max-width: 767px) {
          height: 170px;
          left: 0;
          right: 0;

          > div {
            padding: 15px 0px;
          }
        }

        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          left: inherit;
          right: 10px;
        }

        @media only screen and (min-width: 320px) and (max-width: 360px) {
          height: 180px;
        }
      }

      @media screen and (max-width: 767px) {
        right: 0;
        left: 0;
      }

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        right: 2%;
      }
    }

    &.with-miles {
      height: 198px;

      @media only screen and (max-width: 767px) {
        height: 180px;
      }

      @media screen and (max-width: 320px) {
        height: 175px;
      }
    }

    > p {
      margin-left: -15px;
      margin-bottom: 0;
    }

    .border-bottom-dashed {
      border-bottom: 1px dashed #9d9d9d;
    }

    > div {
      padding: 25px 0px;
      @media (min-width: 320px) and (max-width: 360px) {
        padding-top: 15px;
      }
    }

    .reward-amount,
    .reward-label-container {
      width: 50%;
      line-height: 14px;
    }

    .mgm-reward-info {
      display: flex;
      align-items: center;
    }

    .reward-amount {
      margin: 0px;
      vertical-align: bottom;
    }

    span.reward-label {
      width: 79px;
      text-align: left;
      color: #7c7c7c;
      display: inline-block;
      vertical-align: middle;
    }

    @media screen and (max-width: 767px) {
      top: unset;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 297px;
      border-bottom: 5px solid $color-primary;
    }
  }

  .mgm-registration {
    &__heading {
      padding: 0px 24%;
      margin-top: 40px;

      h1 {
        color: $orange;
        font-size: 30px;
      }

      .registration-message {
        p {
          font-size: 20px;
          line-height: 120%;
        }
      }
    }
  }

  .mgm-promo-advantage {
    margin-top: 0px;
    padding: 30px;
    position: relative;

    h2 {
      color: $orange;
      font-size: 30px;
      padding: 30px 0px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      border: 3px solid $gray-500;
      margin-bottom: 0px;
    }

    .steps-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 60px 15px 50px 15px;
      background: $gray-300;
      border-left: 3px solid $gray-500;
      border-bottom: 3px solid $gray-500;
      border-right: 3px solid $gray-500;

      .arrow-separator {
        @include d-flex;
        padding: 10px;
        border-radius: 50%;
        background-color: $color-primary;
        margin: 0px 15px;
        height: max-content;

        img {
          width: 15px;
          height: 15px;
        }
      }

      .step-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .step {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          .step-number {
            border: 3px solid $orange;
            border-radius: 50%;
            width: 37px;
            height: 37px;
            padding: 20px;
            font-size: 1.35rem;
            font-weight: 700;
            color: $color-primary;
            margin-bottom: 15px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -1em;
          }

          .step-info {
            border: 3px solid $orange;
            border-radius: 15px;
            padding: 25px 10px 10px 10px;
            min-height: 160px;
            @include d-flex;
            background-color: $white;

            h5 {
              font-size: 14px;
            }

            &__image {
              height: 60px;
              width: 60px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              img {
                max-width: 60px;
                max-height: 80%;
              }
            }
          }
        }
      }

      @media screen and(max-width: 768px) {
        flex-direction: column;

        article {
          margin-bottom: 30px;
          margin-top: 50px;

          &:first-of-type {
            margin-top: 0px;
          }

          &:last-of-type {
            margin-bottom: 0px;
          }
        }

        .arrow-separator {
          transform: rotate(90deg);
        }
      }
    }

    &.steps-club {
      background-color: $club;

      h2 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.8rem;

        @media screen and (max-width: 768px) {
          margin-bottom: 50px;
        }
      }

      .step-info {
        font-size: 100%;

        &.step-3,
        &.step-4 {
          font-size: 100%;

          &.promotion-MIXED {
            font-size: 95%;
          }
        }
      }

      .step-number {
        background-color: $club;
      }

      .mgm-promo-advantage-cloud-container#cloud {
        > div {
          img {
            display: initial;
          }

          span {
            background-color: $club;
          }
        }
      }

      .btn-invite-friends {
        color: $club;
      }
    }

    .step-3 {
      .plus-1,
      .plus-2 {
        position: relative;
        font-weight: bold;
      }

      .plus-1 {
        left: 8px;
        bottom: 10px;
      }

      .plus-2 {
        bottom: 25px;
        left: 25px;
      }

      @media screen and (max-width: 768px) {
        .plus-1,
        .plus-2 {
          position: absolute;
        }

        &.promotion-MIXED, &.promotion-CLUB {
          .plus-1 {
            left: 10%;
            bottom: 70%;
          }
        }

        .plus-1 {
          left: 48%;
          bottom: 90%;
        }

        .plus-2 {
          bottom: 98%;
          left: 55%;
        }
      }

      @media screen and (max-width: 480px) {
        .plus-2 {
          left: 60%;
        }
      }
    }

    .step-4 {
      .ic-refresh {
        position: relative;
        bottom: 20px;
        right: 10px;
      }
    }

    .step-info {
      width: 160px;

      &.step-3,
      &.step-4 {
        font-size: 1.1rem;
        width: 200px;
      }
    }

    .create-account {
      text-decoration: underline;
    }

    .btn-invite-friends {
      width: 269px;
      height: 50px;
      border-radius: 10px;
      border: none;
      font-size: 1.3rem;
    }

    @media screen and (max-width: 992px) {
      padding: 30px 10px;

      $step-height: 130px;

      .step {
        width: 40%;
        margin-left: 5px;

        > div {
          height: $step-height;

          > div:nth-child(2) {
            display: inline-block;

            img {
              width: 80%;
            }
          }

          .step-number {
            margin-right: 10px;
            float: left;
          }
        }

        .step-3 {
          > div:nth-child(2) img {
            width: 70%;
          }
        }
      }

      .steps-info {
        text-align: left;

        > div {
          height: $step-height;

          .step-2 {
            font-size: 1.1rem;
          }
        }
      }

      h2 {
        font-size: 1.75rem;
      }

      h3 {
        font-size: 1.25rem;
        margin-bottom: $default-section-margin;
      }
    }

    @media screen and (min-width: 569px) and (max-width: 992px) {
      .steps {
        margin-left: 5.5%;
      }
    }

    @media screen and (min-width: 480px) and (max-width: 568px) {
      .steps {
        margin-left: 4%;
      }
    }

    @media screen and (min-width: 380px) and (max-width: 479px) {
      .steps {
        margin-left: 10px;
      }
    }

    @media screen and (max-width: 320px) {
      h2 {
        font-size: 1.55rem;
      }

      h3 {
        font-size: 1.05rem;
      }

      .steps.row {
        width: 45%;
        margin-left: 0;
      }

      .step-info {
        &.step-3,
        &.step-4 {
          width: inherit;
        }
      }
    }
  }

  .switch-club-plan .sub-header {
    h5 {
      font-size: 2rem;
      font-weight: 900;
    }

    p {
      font-size: 1.5rem;
    }
  }
}

#modalImage {
  margin-top: 50px;
}

div.container-page-modal {
  padding-bottom: 10px;
}

div.container-page-invitar-amigos {
  background: white;
  box-shadow: 1px 1px 9px 3px rgba(0, 0, 0, 0.05);
  padding: 30px 13px;
  margin: 20px 5vw;

  .copy-code {
    width: 25% !important;
  }

  .invitation-code {
    width: 75% !important;
  }

  .font-size-invitar-amigos {
    font-size: 24px;
  }

  .text-helper-invitar-amigos {
    display: flex;
    color: #aaa;
    font-size: 14.5px;
    font-weight: bold;
    margin: 5px 0;
  }

  .title {
    display: block;
    margin-bottom: 30px;
    @media (max-width: 575px) {
      &.nomargin {
        margin-bottom: 0px;
      }
    }
    color: $color-primary;
    text-align: left;
    font-size: 1em;
    font-weight: bold;

    &.club {
      color: $club;
    }
  }

  .share-buttons {
    @media (max-width: 970px) {
      text-align: left;
    }
  }
}

.not-club-member {
  @media(min-width: 1030px) {
    padding: 0px 35%;
  }
  .modal-content {
    border-radius: 20px;
    .content {
      font-size: 1.4rem;
      color: $club;
      text-align: center;
      padding: 30px 50px;

      p {
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: bold;
      }

      button {
        padding: 0px 50px;
        margin-bottom: 10px;
      }
    }
  }
}

.send-email-modal {
  p.modalText {
    color: #aaa;
    font-size: 24px;
    margin: 50px;
    text-align: center;
  }
}

.container-invitar-email {
  .invite-alert {
    @media (max-width: 575px) {
      margin: 0;
      margin-top: 20px;
    }
  }
  .title {
    display: flex;
    color: $color-primary;
    font-weight: bold;
    font-size: 20px;

    @media (max-width: 450px) {
      font-size: 14px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    text-align: right;

    .add-friend {
      margin-bottom: 15px;
      @media (min-width: 451px) {
        margin-right: 6%;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        color: $color-primary;

        &.disabled {
          color: #555;
          cursor: not-allowed;

          img {
            filter: invert(26%) sepia(98%) saturate(1%) hue-rotate(314deg)
              brightness(98%) contrast(89%);
          }
        }

        font-weight: bold;
        @media (max-width: 767px) {
          justify-content: center;
        }
      }
    }
    .send-invite {
      @media screen and (max-width: 767px) {
        text-align: center;
      }

      button {
        background: #ff5a00 0% 0% no-repeat padding-box !important;
        border-color: #ff5a00;
        height: auto !important;
        padding: 13px 35px;

        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
  }

  .form {
    margin-bottom: 10px;
    @media (max-width: 575px) {
      margin-bottom: 0px;
      &.bordered {
        border-top: 1px dashed black;
      }
      padding-top: 40px;
    }
  }

  .inputList {
    padding-bottom: 20px;

    &:first-child {
      @media (max-width: 575px) {
        padding-top: 20px;
        padding-bottom: 0px;
      }
    }

    @media (max-width: 575px) {
      &:not(:first-child) {
        padding-top: 30px;
        border-top: 1px dashed black;
      }
    }
  }

  .input {
    position: relative;
    display: inline-block;

    &.name {
      width: 100%;
      margin-bottom: 9px;
      @media (min-width: 575px) {
        margin: 0;
        width: 42%;
        padding-right: 4px;
      }
    }

    &.email {
      width: 100%;
      @media (min-width: 575px) {
        width: 52%;
        padding-left: 4px;
      }
    }

    &.exclude {
      &.invisible {
        opacity: 0;
      }

      cursor: pointer;
      width: 6%;
      color: #333;

      @media (max-width: 575px) {
        width: 100%;
        margin: 10px 0;
        padding: 10px 0;
        font-size: 1.1rem;
        background: #eee;

        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      &.removeButton {
        background: white;
      }

      span {
        background: none;
        display: none;
        @media (max-width: 575px) {
          display: inline-block;
        }
      }

      img {
        width: max-content;
        cursor: pointer;
        @media (max-width: 575px) {
          display: inline-block;
        }
      }
    }

    span#floatingLabel {
      pointer-events: none;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 22px;

      padding: 0px 5px;
      background: white;

      transition: all 0.1s linear;

      &.disabled {
        background: transparent;
        color: transparent;
        cursor: not-allowed;
      }
    }

    &.active span#floatingLabel {
      top: 2px;
      font-size: 0.9rem;
    }

    input {
      background: none;
      outline: none;

      width: 100%;
      padding: 10px 15px;
      border-radius: 3px;
      border: 2px solid #ccc;

      &.disabled {
        background: rgba(141, 141, 141, 0.075);
        cursor: not-allowed;
        &:hover {
          cursor: not-allowed;
        }
      }

      &.error {
        border: 2px solid red;
      }
    }
  }
}

.screen-club {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .clouds {
    .cloud-item {
      position: absolute;
      &.rotate {
        transform: scaleX(-1);
      }
      &#secondCloud, &#fourthCloud {
        @media(max-width: 812px) {
          display: none;
        }
      }
      &#firstCloud {
        left: 140px;
        top: 60px;
        width: 40px;
        @media(max-width: 812px) {
          left: -14px;
          top: 70px;
          width: 35px;
        }
      }
      &#secondCloud {
        left: 180px;
        top: 82px;
        width: 23px;
      }
      &#thirdCloud {
        right: 160px;
        top: 92px;
        width: 70px;
        @media(max-width: 812px) {
          right: -17px;
          top: 20px;
          width: 40px;
        }
      }
      &#fourthCloud {
        right: 120px;
        top: 130px;
        width: 35px;
      }
    }
  }

  .infos {
    margin-top: 30px;
    width: 65%;
    @media(max-width: 414px) {
      width: 90%;
    }
    @include d-flex;

    .club-title {
      color: rgb(102, 51, 153);
      font-size: 2rem;
      margin-bottom: 1em;
      margin-top: 5px;
      @media(max-width: 414px) {
        font-size: 1.9rem;
      }
    }

    .club-subtitle,
    .club-about {
      color: rgb(124, 124, 124);
    }

    .club-subtitle {
      padding: 0 30px;

      p {
        font-weight: 600;
        font-size: 1.4rem;
        letter-spacing: 0.6px;
        line-height: 120%;

        @media(max-width: 414px) {
          font-size: 1.1rem;
          line-height: 25px;
        }

        @media(min-width: 568px) and (max-width: 812px) {
          line-height: 30px !important;
        }
      }
    }

    .club-about {
      font-size: 1.23rem;
      line-height: 120%;
      margin: 0px;
      letter-spacing: 0.5px;

      @media(max-width: 414px) {
        font-size: 1.1rem;
      }
    }

    .see-more-container {
      min-width: 100%;
      margin-top: 40px;

      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .dash-line {
          width: 100%;
          border: 1px dashed $club;
          position: absolute;
          top: 50%;
          z-index: -1;
        }

        .see-more {
          color: $white;
          font-weight: bold;
          font-size: 1.5rem;
          display: flex;
          align-items: center;
          padding: 5px 40px;
          border-radius: 6px;
          background-color: $club;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            display: inline-block;
            height: 100%;
            width: 20px;
            background-color: $white;
            left: -20px;
          }

          &::after {
            content: "";
            position: absolute;
            display: inline-block;
            height: 100%;
            width: 20px;
            background-color: $white;
            right: -20px;
          }
        }
      }

      .see-more-image {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        &::before {
          content: "";
          position: absolute;
          height: 50px;
          width: 50px;
          background: $club;
          border-radius: 50%;
          top: -27px;
          z-index: -1;
        }

        .down-to-plan {
          margin-bottom: 18px;
          height: 15px;
        }
      }

      // .see-more, .down-to-plan {
      //   @media(max-width: 768px) {
      //     display: none;
      //   }
      // }
    }

    @media (max-width: 620px) {
      margin-top: 25px;
      width: 80%;

      .club-title {
        font-size: 1.6rem;
        margin: 0 60px;
      }

      .club-subtitle {
        margin-top: 15px;
        font-size: 1.1rem;
        line-height: 25px;
      }

      .club-about {
        font-size: 1rem;
        line-height: 25px;
      }
    }

    @media (max-width: 414px) {
      .club-title {
        margin: 0 70px;
      }
    }

    @media (max-width: 320px) {
      .club-title {
        margin: 0 40px;
      }
    }
  }
}

div.section-bottom {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.recaptcha {
  margin-bottom: 20px;
  text-align: left;
  color: #b10000;

  #recaptchaResize {
    @media(max-width: 767px) {
      transform: scale(0.8);
      display: flex;
      justify-content: center;
    }

    @media(max-width: 320px) {
      transform: scale(0.75);
    }
  }

  @media (max-width: 516px) {
    text-align: center;
  }

  iframe > div.rc-anchor-normal {
    width: 200px !important;
  }
}

.mgm-club-public {
  div {
    position: relative;

    .clouds-left {
      position: absolute;
      left: -50px;
      z-index: -1;

      @media screen and (min-width: 992px) {
        top: 45%;
        left: 2%;
      }

      @media screen and (max-width: 320px) {
        top: 16%;
      }
    }

    .clouds-right {
      position: absolute;
      right: 2%;
      top: 3%;

      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .club-title {
      width: 90%;
      margin: 50px auto 15px;

      @media screen and (max-width: 480px) {
        width: 70%;
      }

      @media screen and (max-width: 380px) {
        width: 80%;
      }
    }

    .club-subtitle {
      width: 90%;
      margin: 0px auto 50px;


      @media screen and (min-width: 768px) {
        width: 77%;

        &.club-message {
          width: 65%;
        }
      }

      @media screen and (min-width: 992px) {
        width: 52%;

        &.club-message {
          width: 39%;
        }
      }

      @media screen and (max-width: 480px) {
        width: 67%;
      }

      @media screen and (max-width: 380px) {
        width: 85%;
      }

      p {
        font-size: 1rem;
        line-height: 1.4rem;

        @media screen and (min-width: 768px) {
          font-size: 1.3rem;
          line-height: 1.5rem;
        }

        @media screen and (min-width: 992px) {
          line-height: 1.8rem;
        }
      }
    }
  }
}
