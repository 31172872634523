
.my-account__cancel {
  .cancel-flight {
    @include bms-base-box;
    position: relative;
    text-align: left;
    width: 100%;

    h2 {
      text-align: left;
      font-size: 24px;
    }

    .panel-heading {
      margin: 20px 0;
      background-color: $gray-500;
      display: flex;
      padding: 2px 9px 0px 9px;
      border-bottom: 1px solid $gray-500;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      width: 100%;

      @include media-large {
        padding: 9px 18px 6px 18px;
      }

      .flight-locator {
        width: 65px;
        text-align: left;
        max-width: 120px;
        float: left;

        &__number {
          color: $white;
          font-size: 17px;
        }

        &__label {
          color: $gray-900;
          font-size: 13px;
          font-family: $regularFontFamily;
          font-weight: bold;
        }
      }
    }

    .panel {
      width: 100%;
      border: 1px solid $gray-700;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 20px;
      background-color: $white;


      &.checked {
        border-color: $default;
      }

      .panel-body {
        padding: 0 4px;

        .flight-list__item {
          position: relative;

          .input-checkbox-wrapper {
            padding-top: 10px;

            input {
              @include media-large {
                left: -50px;
                top: 25%;
                position: absolute;
              }
            }
          }

          .flight-details {
            color: $gray-500;
            font-family: $regularFontFamily;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .date-container {
              display: flex;
              flex-direction: column;

              @include media-large {
                flex-direction: row;
                min-width: 25%;
                justify-content: space-between;
              }
            }

            &__date {
              position: relative;
              text-align: left;
              display: block;
              flex-flow: column wrap;

              .date {
                &__week-day {
                  text-transform: uppercase;
                  color: $gray-700;
                  font-size: 10px;
                  line-height: 10px;
                  margin-right: 10px;
                }

                &__day {
                  font-size: 12px;
                  line-height: 12px;
                  color: $black;
                  margin-right: 10px;
                }

                &__month {
                  text-transform: uppercase;
                  font-size: 12px;
                  line-height: 12px;
                  color: $black;
                }
              }
            }

            &__company {
              padding: 3px 10px;
              height: 50px;
              border: 1px solid $gray-500;
              border-radius: 5px;

              .logo-airlines {
                width: 48px;
                height: 35px !important;
                padding: 0;
                margin: 0;
                background-size: contain !important;
              }
            }

            &__travel {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 60%;

              .mobile-travel__stops-and-duration {
                width: 100%;
                flex-flow: row wrap;
                height: 20px;
                display: inherit;

                &.travel {
                  &__info {
                    color: $gray-700;
                    font-size: 12px;
                    font-weight: bold;
                    align-items: center;
                    justify-content: center;
                  }
                }

                .travel {

                  &__stops {
                    margin-right: -5px;
                    min-width: 57px;
                    text-align: right;
                    text-transform: uppercase;
                    font-family: $boldFontFamily;
                  }

                  &__duration {
                    margin-left: 40px;
                    text-align: left;
                    font-family: $boldFontFamily;
                  }
                }
              }

              .travel__details-wrapper {
                display: flex;
                justify-content: center;
                width: 100%;

                .travel {

                  &__airport {
                    color: $black;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 100;
                  }

                  &__location {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 100;
                    color: $gray-800;
                  }

                  &__date-info {
                    font-size: 12px;
                    color: $gray-600;
                  }

                  &__origin,
                  &__arrival {
                    display: flex;
                    flex-flow: column wrap;

                    @include media-large {
                      max-width: 35%;
                    }
                  }
                  &__origin {
                    align-items: flex-end;
                    margin-right: 0px;
                    text-align: right;
                    margin-left: 15px;
                  }
                  &__arrival {
                    text-align: left;
                    margin-left: 0px;
                  }

                  &__stops {
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 14px;
                    color: $gray-700;
                    font-weight: bold;
                  }

                  &__duration {
                    font-size: 12px;
                    line-height: 14px;
                    color: $gray-700;
                    font-weight: bold;
                  }

                  &__info {
                    display: inherit;
                    flex-direction: column;
                    padding: 0 2vw;
                    text-align: center;
                    margin: 10px 0 0 0;

                    .seta {
                      width: 20px;
                      margin: 25px 5px 0 5px;

                      &::after {
                        content: "";
                        width: 100%;
                        height: 14px;
                        display: block;
                        background-image: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
                        background-position: center right;
                        background-repeat: no-repeat;
                        margin-bottom: 1px;
                      }
                    }
                  }
                }

              }
            }

            &__about {
              color: $gray-700;
              font-size: 12px;
              text-align: left;
              margin: 15px 0 0 0;
              padding: 0;

              li {
                line-height: 20px;

                .smls-gray-icons {

                  &::before {
                    width: 28px !important;
                  }
                }

              }
            }

            @include media-large {
              &__date{
                display: flex;
                text-align: center;
                height: 60px;

                .date {
                  &__week-day {
                    margin-right: 0
                  }

                  &__day {
                    font-size: 28px;
                    line-height: 28px;
                    margin-right: 0;
                  }

                  &__month {
                    font-size: 14px;
                    line-height: 14px;
                    margin-right: 0;
                  }
                }
              }

              &__company {

                .logo-airlines {
                  width: 65px;
                  height: 45px;
                }
              }

              &__travel {
                width: unset;

                .mobile-travel__stops-and-duration {
                  display: none;
                }

                .travel__details-wrapper {

                  .travel {

                    &__info {
                      .seta {
                        margin: 0 5px;
                        width: 80px;
                      }
                    }

                    &__origin {
                      margin-right: 12px;
                      width: 40%;
                      margin-left: 0;
                    }

                    &__arrival {
                      margin-left: 12px;
                      width: 40%;
                    }
                  }
                }
              }

              &__about {
                margin: 5px 0 0 0;
                max-width: 55%;
              }
            }
          }
        }
      }
    }

    .message-box {
      margin-top: 20px;
      text-align: center;

      @include media-large {
        margin-top: 80px;
      }

      p {
        margin-bottom: 20px;
        font-size: 14px;
        color: $black;
      }

      .featured {
        color: $default;
        font-size: 20px;
      }
    }

    .hidden-desktop {
      display: block;

      @include media-large{
        display: none;
      }
    }
    .hidden-phone {
      display: none;

      @include media-large{
        display: block;
      }
    }

    .btn-sm-full {
      width: 100%;
      margin-bottom: 10px;

      @include media-large {
        width: unset;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .btn-inverse {
      background-color: $black;
      color: $white;
      border-radius: 5px;
      font-size: 14px;
      padding: 7px 25px;
      height: 35px;

      &:hover {
        background-color: $gray-800;
      }
    }

    @include media-large {
      h2 {
        font-size: 28px;
      }
    }
  }
}
