.container {
  &.one-column {
    padding-top: 80px;
    padding-bottom: 35px;
    background-color: $gray-200;
    text-align: center;
    min-height: 500px;
    &.white {
      background-color: $white;
    }
  }
  &.two-columns {
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: $gray-200;
    > .row {
      > .col-lg-4 {
        padding: 0;
      }
      > .col-lg-8 {
        padding: 0;
        flex-basis: 100%;
        max-width: 100%;
      }
      > .col-lg-12 {
        padding: 0;
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .container {
    &.two-columns {
      padding-left: 64px;
      padding-top: 35px;
      padding-bottom: 35px;
      > .row {
        > .col-lg-4 {
          flex-basis: 295px;
        }
        > .col-lg-8 {
          flex-basis: 855px;
          max-width: 855px;
          &.dashboard {
            flex-basis: 883px;
            max-width: 883px;
          }
        }
        > .col-lg-12 {
          flex-basis: 1150px;
          max-width: 1150px;
          padding: 26px 0;
        }
      }
    }
  }
}
