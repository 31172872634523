.box-pre-register {
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #f5f5f5;
    width: 100%;
}
.pre-register {
    @include bms-base-box;
    position: relative;
    text-align: left;
    padding: 15pt 9pt;
    &.box {
        h2 {
            margin-bottom: 28px;
            border-bottom: 1px solid $default;
            padding-bottom: 8px;
            font-size: 24px;
            text-align: left;
        }
        .box-header {
            margin-bottom: 10px;
            p {
                font-size: 16px;
                margin: 0;
                font-weight: 600;
            }
        }
        .frm-default {
            margin-bottom: 10px;
            .row {
                margin: 0 0 14px 0;
                span, div {
                    font-size: 14px;
                }
                select {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &__register-alert {
        width: 561px;
        margin: 20px auto;
        background-color: #663399;
        color:white;
        padding: 10px;
        font-weight: bold;
    }
}




// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .pre-register {
        padding: 40px 50px;
        height: auto;
        width: 561px;
        /* Box com o ID e o Captcha */
        &.box {
            h2 {
                flex-direction: column ;
                flex-wrap: wrap;
                justify-content: center;
                .ic-profile-solid {
                    font-size: 30px;
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            .frm-default {
                .row {
                    select {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

