.airport-change-location-icon__container {
    display: flex;
    z-index: 2;
    .airport-change-location-paragraph {
      margin-left: 5px;  
      margin-right: 5px;
      margin-bottom: 0px;
      color: $primary;
      font-weight: 500;
      font-size: 20px;
      padding-top: 3px;
    }
}

.airport-change-location-message {
  max-width: 320px;
  width: 100%;
  padding: 7px;
  border: solid 1px $primary;
  background-color: $white;
  border-radius: 5px;
  position: absolute;
  bottom: 150px;
  z-index: 2;
  .close-button {
    border: none;
    background-color: $white;
    color: $primary;
    position: absolute;
    right: 5px;
    top: 0px;
    cursor: pointer;
    font-weight: bold;
  }
  

  .title {
   color: $danger;
   font-weight: 800;
   font-size: 13px;
   margin-bottom: 0px;
  }
  .paragraph {
    color: $gray-900;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0px;
    line-height: normal !important;
  }
}

.airport-change-location-arrow {
  width: 10px;
  height: 10px;
  border-top: none;
  border-bottom: solid 1px $primary;
  border-left: none;
  border-right: solid 1px $primary;
  transform: rotate(42deg);
  position: relative;
  top: 13px;
  left: 150px;
  background-color: $white;
  z-index: 2;
}

.airport-background-gray {
  background-color: $gray-200;
}

.airport-background-white {
  background-color: $white;
}

@media only screen and (max-width: 991px) { 
  
  .airport-change-location-message {
    left: 190px;
    bottom: revert;
  }
  .airport-change-location-arrow {
    transform: rotate(137deg);
    position: absolute;
    bottom: revert;
    left: -6px;
    z-index: 3;
    margin-top: 2.5px;
  }

}

@media only screen and (max-width: 768px) {
  .airport-change-location-message {
    left: 185px;
    bottom: revert;
  }
  .airport-change-location-arrow {
    transform: rotate(137deg);
    position: absolute;
    bottom: revert;
    left: -6px;
    z-index: 3;
    margin-top: 2.5px;
  }
}

@media only screen and (max-width: 425px) { 
  .airport-change-location-message {
    width: 200px;
    left: 180px;
    bottom: revert;
  }
  .airport-change-location-arrow {
    transform: rotate(137deg);
    position: absolute;
    bottom: revert;
    left: -6px;
    z-index: 3;
    margin-top: 2.5px;
  }
}

@media only screen and (max-width: 375px) { 
  .airport-change-location-message {
    width: 170px;
    left: 160px;
    bottom: revert;
  }
  .airport-change-location-arrow {
    transform: rotate(137deg);
    position: absolute;
    bottom: revert;
    left: -6px;
    z-index: 3;
    margin-top: 2.5px;
  }
}

@media only screen and (max-width: 320px) { 
  .airport-change-location-message {
    width: 130px;
    left: 153px;
    top: 350px;
    bottom: revert;
  }
  .airport-change-location-arrow {
    transform: rotate(137deg);
    position: absolute;
    bottom: revert;
    left: -6px;
    z-index: 3;
    margin-top: 2.5px;
  }
}
