.resume-passenger-container {
    .passenger-description{
        font-size: .7em;
    }
    .jarvis-passenger-type{
        h6{
            color: $gray-600;
        }
    }
    .passenger-quantity{
        font-size: .7em;
        text-align: right;
    }
    
    .pricing-jarvis-v2{
        .price{
            font-size: .75em;
            font-family: $boldFontFamily;
            text-align: right;
            margin-right: 20px !important;
            &__standard{
                color: $primary;
            }
            &__club{
                color: $club;
            }
            &__diamante{
                color: $diamond;
            }
    
        }
    }

}

.service-detail-table-container{
    div{
        div{
            .hr-border-primary{
                border-color: $primary !important;
            }
        }
    }
}

.resume-details-total-price-bottom{
    .total-price-container {
        flex-wrap: nowrap;
        .item{
            h6{
                color: $gray-600;
                font-size: .9em;
            }
        }
    }
}

@media only screen and (max-width: 575px){
    .resume-passenger-container {
        .passenger-quantity{
            font-size: .7em;
            text-align: center;
        }
    }
/*     .counter-passenger-container {
        justify-content: center;
        text-align: center;
        .pricing-jarvis-v2{
            margin-top: 10px;
            .price{
                font-size: 1.1em;
                text-align: center;
                margin-right: 0px !important;
            }
        }
    } */
/*     .resume-passenger-container {
        padding: 50px 0px !important;
    } */
}
