/*Resultado emision Gol vuelos de millas inicio*/
.title {
    padding: 0 0 0 0;
    display: flex;
    h4 {
        font-size: 28px;
        font-family: $regularFontFamily;
        float: left;
        font-weight: bold;
        @media only screen and (max-width: 768px) {  h4 { border-top: none; } }
    }
    .print-container {
        display: flex;
        justify-content: end;
        .search-flights-caption {
            font-size: 16px;
            margin: 0 0 20px 10px;
            &:before {
                content: '';
                background-image: url(#{$svg-path}/icons/gray/ic-print.svg);
                height: 25px;
                width: 25px;
                background-size: 100%;
                margin-right: 5px;
                position: relative;
            }
        }
    }
}
    
.emission-gol-success__container {
            width: 100%;
            padding: 20px 10px;
            margin: 0px;
            .resume-emision-gol {
                padding: 0px;
                border: 1px solid $gray-500;
                width: 100% !important;
                display: flex;
                @media only screen and (max-width: 991px) {
                    flex-direction: column;
                }
                .resume-emision-gol__left {
                        height: 230px;
                        background-color: $primary;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        .resume-emision-gol__icon-check {
                            width: 55px;
                            height: 55px;
                            background-size: 55px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                        }
                        h4 {
                            margin: 20px 0px 20px 10px;
                            color: $white;
                            text-align: center;
                        }
                        @media only screen and (max-width: 768px) { 
                            h4 {
                                margin: 20px 0px 20px 10px;
                                color: $white;
                                text-align: center;
                                font-size: 24px;
                            }
                        }
                }
                .resume-emision-gol__right {
                    padding: 0px;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    hr {
                        margin: 0px 20px;
                    }
                    .resume-emision-gol__inner-top {
                        height: 105px;
                        background-color: $white;
                        padding: 25px 15px;
                        width: 290px;
                        display: flex;
                        justify-content: center;
                        .resume-list-top {
                            margin: 0px !important;
                            padding: 0px !important;
                            display: flex;
                            justify-content: center;
                            li {
                                width: 100%;
                                margin-top: 0px !important;
                                margin-bottom: 0px;
                                display: flex;
                                .resume-title { 
                                    font-weight: 900;
                                    color: $primary;
                                    display: inline-block;
                                    font-size: 18px;
                                }
                                span {
                                    font-size: 16px;
                                    color: $gray-700;
                                }
                                span:first-child {
                                    margin-right: 7.5px;
                                }
                                span:last-child {
                                    margin-left: 7.5px;  
                                }
                            }
                        }
                    }
                    .resume-emision-gol__inner-bottom {
                        background-color: $white;
                        padding: 10px 10px 34px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        max-width: 290px;
                        width: 100%;
                        .resume-emision-gol__icon-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            max-width: 280px;
                            .resume-emision-gol__icon-airplane {
                                margin-bottom: 5px;
                                width: 111px;
                                height: 40px;
                                background-size: 40px;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url(#{$svg-path}/icons/orange/ic-airplane-take-off.svg);
                            }
                            .resume-emision-gol__icon-baggage {
                                margin-bottom: 5px;
                                width: 111px;
                                height: 40px;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url(#{$svg-path}/icons/orange/ic-baggage.svg);
                                background-size: 40px;
                            }
                        }
                        .resume-emision-gol__button-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            max-width: 280px;
                            a {
                                .btn {
                                margin-top: 0px;
                                padding: 5px 10px;
                                font-size: 12px;
                                height: 30px;
                                }
                            }
                        }
                    }
                }
            }
            .resume-emision-gol__banner-bottom {
                margin-top: 1.5rem;
            }
            .child-alone{
                padding: 20px;
                border: 1px solid $gray-500;
                background-color: $gray-200;
                border-top: 0px;
                width: 100% !important;
                display: flex;
                text-align: left;
                p {
                    font-size: 13px;
                    margin: 0;
                }
                @media only screen and (max-width: 768px) { 
                    flex-direction: column;
                }
            }
}
/*Resultado emision Gol vuelos de millas fin*/