.timeline-list {
  border-left: 2px solid $gray-300;
  padding-inline-start: initial;

  .locator {
    h4,
    h5 {
      display: inline-block;
      margin-left: 5pt;
    }
    h5 {
      font-size: 8pt;
      color: $gray-500;
    }
    h4 {
      color: $gray-700;
      font-size: 10pt;
    }
  }

  &-item {
    &:not(.year) {
      border-bottom: 1px solid $gray-300;
      padding: 12pt 0 12pt 8pt;
      .button-history {
        background-color: $primary;
        border: $primary;
        padding: 0px 35px;
        height: 25px;
        margin-top: 0.5rem;
        font-size: 14px;
      }
    }

    &.year {
      padding-left: 8pt;
      h5 {
        font-size: 10pt;
      }
    }

    &:last-child {
      border: 0;
    }

    &__line {
      align-items: center;
      position: relative;

      & + .timeline-list-item__line {
        padding-top: 12pt;
      }

      &:before {
        content: "";
        width: 9pt;
        height: 9pt;
        background-color: $gray-500;
        border-radius: 22px;
        position: absolute;
        top: 50%;
        margin-top: -4.5pt;
        left: -13.5pt;
      }

      .date {
        h3 {
          color: $gray-700;
          margin-bottom: 0;
          font-size: 14pt;
          font-weight: normal;
        }
        h5 {
          font-size: 10pt;
          font-weight: normal;
        }
      }

      .logo {
        width: 50pt;
        height: 14pt;
      }

      .departure {
        padding-right: 10pt;
      }

      .arrival {
        padding-left: 10pt;
      }

      .departure,
      .arrival {
        h3 {
          color: $gray-700;
          margin-bottom: 0;
          font-size: 16pt;
        }
        h5 {
          font-size: 8pt;
          font-weight: normal;
        }
      }
    }
  }
}

// Large devices (Desktops, 768px and up)
@media (min-width: 768px) {
  .timeline-list {
    .locator {
      h4 {
        font-size: 14pt;
      }
      h5 {
        font-size: 10pt;
      }
      h4,
      h5 {
        display: block;
        margin-bottom: 0;
      }
    }

    &-item {
      &__line {
        .logo {
          width: 80pt;
          height: 20pt;
        }
        .departure {
          padding-right: 18pt;
        }

        .arrival {
          padding-left: 18pt;
        }
        .departure,
        .arrival {
          h3 {
            font-size: 20pt;
          }
          h5 {
            font-size: 10pt;
          }
        }
      }
    }
  }
}
