/*Progreso compra millas inicio*/
.container {
    width: 100% !important;

    .purchase-miles-progress__container {
        padding: 1.5rem;

        @media only screen and (max-width: 768px) {
            padding: 3rem 1.5rem 1.5rem 1.5rem;
        }

        .title {
            padding: 0 0 0 0;

            h4 {
                font-size: 28px;
                font-family: $regularFontFamily;
                float: left;
                font-weight: bold;
            }
        }

        .order-in-progress__container {
            padding: 0px;
            border: 1px solid $gray-500;
            display: flex;
            flex-direction: row;

            .order-in-progress__left {
                background-color: $primary;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                height: 195px;

                .inprogress {
                    background: url(#{$svg-path}/icons/white/ic-gear.svg) no-repeat center;
                    width: 100%;
                    height: 60px;
                    margin-bottom: 10px;
                    display: block;
                    background-size: 60px;
                }

                h2 {
                    color: $white;
                    text-align: center;
                    margin-bottom: 0px;
                }
            }

            .order-in-progress__right {
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 15px 25px;
                height: 195px;
                text-align: center;

                p {
                    margin-bottom: 10px;
                }

                a {
                    border: 0px solid;
                    background-color: $primary;
                    margin-top: 0px;
                    float: right;
                    padding: 10px 24px;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .order-in-progress__container {
                flex-direction: column;
            }
        }

        .ticket {
            display: block;
            height: 300px;
            background-color: $gray-300;
        }
    }
}

.new-checkout {
    text-align: left;
    border: 2px solid $gray-300;
    margin-top: 65px;
    margin-bottom: 20px;
    margin-right: 1.5rem;

    @media only screen and (max-width: 991px) {
        margin-top: 0px;
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
    }

    .box {
        padding: 2rem;
    }
}

@media only screen and (min-width: 769px) {
    .checkout {
        &.miles-purchase,
        &.miles-transfer,
        &.miles-extension,
        &.miles-reactivation {
            .box-info-purple {
                position: absolute;
                background-color: $club;
                color: $white;
                max-height: 70px;
                font-size: 10px;
                text-align: left;
                width: 100%;
                max-width: 610px;
                padding: 1rem 1rem 1rem 0px;
                margin-top: 10px !important;

                &:before {
                    content: "";
                    margin-right: -31px !important;
                    margin-top: 5px !important;
                }

                .btn-suscribite {
                    border: 1px solid $white;
                    padding: 5px !important;
                    height: 29px;
                    font-family: NunitoLight, sans-serif;
                    font-size: 14px;
                    margin-top: -2px;
                }

                .close {
                    font-size: 15px !important;
                    margin-top: -16px !important;
                    margin-right: -10px !important;
                }

            }
        }
        tbody {
            tr {
                td {
                    .tdDivBonusMsg {
                        max-width: 150px;
                        width: 100% !important;
                    }
                }
            }
        }  
        tfoot {
            .table-footer-border {
                border-top: 1px solid $gray-300;
                border-bottom: none;
            }
        }

    }

}

/*Progreso compra millas Fin*/
//-----------------------------------------------------------------------------------------------------------------------
/*Resultado compra millas inicio*/
.purchase-miles-success__container {
    padding: 20px 10px 20px 10px;
    margin: 0px;

    @media only screen and (max-width: 768px) {
        padding: 3rem 10px 20px 10px;
    }

    .title {
        padding: 0 0 0 0;

        h4 {
            font-size: 28px;
            font-family: $regularFontFamily;
            float: left;
            font-weight: bold;

            @media only screen and (max-width: 768px) {
                border-top: 0px solid $primary;
            }
        }
    }

    h2 {
        margin-bottom: 20px;
    }

    .resume-wrapper {
        padding: 0px;
        border: 1px solid $gray-500;
        width: 100% !important;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
        }

        .resume-wrapper__left {
            .resume-wrapper__left--inner-top {
                background-color: $primary;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 52px 20px;

                @media screen and (min-width: 992px) and (max-width: 1220px) {
                    padding: 44px 20px;
                }

                .resume-wrapper__icon-check {
                    width: 55px;
                    height: 55px;
                    background-size: 55px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                }

                h4 {
                    margin: 20px 0px 20px 10px;
                    color: $white;
                    text-align: center;
                }
            }

            .resume-wrapper__left--inner-bottom {
                background-color: $white;
                padding: 20px;
                display: flex;
                justify-content: center;

                .resume-wrapper__button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    max-width: 280px;

                    a {
                        .btn {
                            margin-top: 0px;
                            float: right;
                            padding: 10px 20px;
                            width: 120px;
                        }
                    }
                }
            }
        }

        .resume-wrapper__right {
            padding: 0px;
            background-color: $white;

            .resume-wrapper__inner-top {
                height: 200px;
                background-color: $white;
                padding-top: 25px;
                padding-bottom: 10px;

                .resume-list-top {
                    li {
                        width: 100%;
                        margin-top: 5px;
                        margin-bottom: 5px;

                        .resume-title {
                            font-weight: 500;
                            color: $gray-900;
                            display: inline-block;
                        }

                        .bonus-club {
                            color: $club;
                            font-weight: 900;
                        }

                        .diamond {
                            color: $diamond;
                            font-weight: 900;
                        }

                        .bonus {
                            color: $default;
                            font-weight: 900;
                        }

                        span {
                            color: $gray-700;
                            @media only screen and (max-width: 768px) {
                                font-size: 12px !important;
                            }
                        }

                        span:first-child {
                            text-align: right;
                            width: 50%;
                        }

                        span:last-child {
                            width: 50%;
                            margin-left: 15px;
                        }
                    }
                }

                @media only screen and (max-width: 991px) {
                    .resume-list-top {
                        padding: 0px 0px 0px 20px;

                        li {
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .resume-wrapper__inner-bottom {

                background-color: $gray-200;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px;

                @media only screen and (max-width: 500px) {
                    flex-direction: column;
                }

                .resume-list-bottom {
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    width: 50%;

                    @media only screen and (max-width: 991px) {
                        padding: 0px 0px 0px 5px;
                        width: 100%;
                    }

                    li {
                        width: 100%;
                        margin-top: 0px;

                        .resume-title {
                            font-weight: 900;
                            color: $gray-900;
                            display: inline-block;
                        }

                        span {
                            color: $gray-700;
                            font-size: 11.5px;
                        }

                        .resume-icon-user {
                            margin-right: 10px;
                            display: inline-block;
                            width: 25px;
                            height: 22px;
                            background-size: 25px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                        }

                        .resume-icon-tdc {
                            margin-right: 10px;
                            display: inline-block;
                            width: 25px;
                            height: 17px;
                            background-size: 25px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/gray/ic-credit-card.svg);
                        }
                    }
                }
            }
        }
    }

    .resume__banner-bottom {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .resume__banner-right {
        margin-left: 1.5rem;
    }

    @media only screen and (max-width: 991px) {
        .resume__banner-right {
            margin-left: 0rem;
        }
    }
}

/*Resultado compra millas fin*/


/*Compras de millas sin exito*/
.miles-purchase-cancel{
    margin: 50px 150px;
}
.subtitle{
    font-size: 14px;
    font-weight: 800;
    margin-top: 5px;
    color: $primary;
}
.cancel-purchase-button{
    display: flex;
    justify-content: end;
    margin: 10px 1px 0px 10px;
    button{
        padding: 0 40px;
        background: #FF5A00;
        border: 1px solid #FF5A00; 
    }
    button:hover {
        background: $white;
        color: $default;
        border: 1px solid $default;
      }
}
.error-right__container{
    font-weight: bold;
}
.logo-whatsaap-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 282px;
    span {
      font-size: 14px;
      font-weight: 800;
      display: block;
      margin-bottom: 10px;
      text-align: center;
    }
    .wp-flex {
      display: block;
      width: 50px;
      height: 50px;
      background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
    }
  }

  @media only screen and (max-width: 769px){
    .miles-purchase-cancel{
        margin: 50px;
    }
  }