
.checkout {

    .title {
        padding: 50px 0 0 0;
    }

    .seats {
        width: 100%;
        margin: auto;
        margin-top: 20px;
        border: 2px solid #E5E5E5;

        .seats-details {
            padding: 10px;
            overflow-x: auto;

            .smiles-table {
                width: 100%;
                text-align: center;

                thead {
                    tr{
                        th{
                            border-bottom: 1px solid $gray-300;;
                            padding: 16px 5px;
                            color: $default;
                            font-size: 20px;
                            font-family: $boldFontFamily;
                            text-align: left;
                        }
                    }
                }

                tbody {
                    tr{
                        border-bottom: 1px solid $gray-300;

                        td{
                            padding: 16px 5px;
                            font-size: 14px;
                            font-family: $regularFontFamily;

                            strong {
                                font-family: $boldFontFamily;   
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        } 
                    }
                }
            }
        }
        .box {
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
        }
    }

    @media (min-width: 768px) {
        width: 1060px;
    }
}