.checkout-sucess, .checkout-sucess-congenere, .checkout-success-cancel, .change-confirmation-success {
    padding: 8px;

    .title {
        padding: 80px 0 0 0;

        &.emission-title {
          padding-top: 0px !important;
          .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          a.search-flights-caption {
            right: 0px !important;
            top: 0px !important;
            position: relative;
            margin: 0 0 0 0;
          }
        }

        h4 {
            font-size: 28px;
            font-family: $regularFontFamily;
            float: left;
            font-weight: bold;
        }

        a {
            display: none;
        }
    }

    .order {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .order-details {
            padding: 15px 8px;
            border: 2px solid $default;
            border-radius: 4px;
            margin-top: 30px;
            display: flex !important;
            align-items: center;
            flex-flow: row wrap;

            .check {
                background: url(#{$svg-path}/icons/gray/ic-check.svg) no-repeat center;
                width: 100%;
                height: 108px;
                display: block;
                background-size: 60px;
            }

            .inprogress {
                background: url(#{$svg-path}/icons/gray/ic-gear.svg) no-repeat center;
                width: 100%;
                height: 108px;
                display: block;
                background-size: 60px;
            }

            .alert {
                background: url(#{$svg-path}/icons/orange/ic-alert.svg) no-repeat center;
                width: 100%;
                height: 108px;
                display: block;
                background-size: 60px;
            }

            div:last-child {
                text-align: center;
                width: 100%;
                border-left: none;

                h4 {
                    font-size: 16px;
                    margin-bottom: 15px;
                }

                h3 {
                    font-size: 20px;
                    margin-bottom: 15px;
                }

                p {
                    line-height: 20px;
                    font-family: $ligthFontFamily;
                }

                b {
                    font-family: $boldFontFamily;
                }

                a, button {
                    line-height: 20px;
                    height: 40px;
                    width: 100%;
                    font-family: $boldFontFamily;
                    background-color: $gray-700;
                    border-color: $gray-700;
                    font-size: 12px;
                }

                &.in-progress {
                    padding: 0 10 0 10px;

                    p {
                        text-align: left;
                        font-size: 13px;
                    }
                }

                &.canceled {
                    padding: 0 10 0 10px;

                    h2 {
                        font-size: 18px;
                    }

                    p {
                        text-align: center;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .order-easy-travel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid $easy-travel;
        border-radius: 5px 5px 0 0;
        width: 100%;
        margin: auto;

        .order-header {
            float: left;
            width: 100%;
            background-color: $easy-travel;
            background-image: url(#{$svg-path}/icons/white/ic-easy-travel.svg);
            background-repeat: no-repeat;
            background-position: 20px 8px;
            background-size: 64px;
            min-height: 81px;

            p {
                color: $white;
                font-size: 20px;
                float: left;
                margin: 15px 0 0 100px;
                line-height: 26px;
            }

            div {

                p {
                    color: $white;
                    font-size: 20px;
                    float: left;
                    margin: 15px 0 0 100px;
                    line-height: 26px;

                    &:nth-child(2) {
                        width: 100%;
                        font-size: 13px;
                        color: $gray-300;
                        line-height: 19px;
                        padding: 20px;
                        text-align: center;
                        margin: auto;
                        font-family: $regularFontFamily;
                    }
                }

                &:nth-child(2) {
                    float: left;
                    width: 120px;
                    height: 80px;
                    background: $white;
                    margin: 20px;
                    border-radius: 5px;
                    text-align: center;
                    padding: 6px;

                    label {
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 17px;
                        color: $gray-700;
                        margin-bottom: 3px;

                        &:nth-child(2) {
                            color: $easy-travel;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }

                    .tooltip-info {
                        background-color: $white;
                        border: 2px $default solid;
                        padding: 5px;
                        float: left;
                        color: $gray-700;
                        position: relative;
                        width: 90px;
                        height: 100px;
                        font-size: 12px;
                        display: block;
                        border-radius: 3px;
                        margin-top: -90px;
                        margin-left: 150px;
                        z-index: 1;

                        span {
                            font-family: $regularFontFamily;
                            color: $easy-travel !important;
                        }

                        b {
                            font-family: $boldFontFamily;
                            color: $club;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 20px;
                            left: 55px;
                            margin-left: -75px;
                            border-width: 10px;
                            border-style: solid;
                            background-color: transparent;
                            border-color: transparent $default transparent transparent;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 23px;
                            left: 51px;
                            margin-left: -65px;
                            border-width: 7px;
                            border-style: solid;
                            background-color: transparent;
                            border-color: transparent $white transparent transparent;
                        }
                    }
                }
            }
        }

        .order-details {
            display: flex !important;
            align-items: center;
            flex-direction: column;

            .inprogress {
                width: 100px;
                height: 100px;
                display: block;
                border: none;
                float: left;
                background: url(#{$svg-path}/icons/gray/ic-gear.svg) no-repeat center 20px;
            }

            .content {
                float: left;
                width: 100%;
                height: auto;
                padding: 0px 15px 10px 15px;
                text-align: center;

                p {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: center;
                    padding-top: 10px;

                    button {
                        background-color: $gray-700;
                        height: 31px;
                        border: 0;
                        border-radius: 4px;
                        font-size: 12px;
                        font-family: $regularFontFamily;
                        float: none;
                        margin-right: 0;
                        width: 100%;
                        margin-top: 15px;
                    }

                    label {
                        color: $default;
                    }
                }

                h2 {
                    color: $easy-travel;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
            }

            .footer {
                width: 100%;
                padding: 0 15px 15px 15px;

                p {
                    width: 100%;
                    font-size: 14px;
                    line-height: 16px;
                    color: $gray-600;
                    text-align: center;
                    margin: 0 0 5px 0;
                }

                button {
                    background-color: $gray-700;
                    height: 31px;
                    border: 0;
                    border-radius: 4px;
                    font-size: 12px;
                    font-family: $regularFontFamily;
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: 768px) {

        .title {
            padding: 80px 0 0 0;

            h4 {
                font-size: 28px;
                font-family: $regularFontFamily;
                float: left;
                font-weight: bold;
            }

            a {
                font-size: 18px;
                float: right;
                color: $gray-600;
                cursor: pointer;
                display: inline-block
            }

            .search-flights-caption {
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0 0 20px 10px;
                &:before {
                    content: '';
                    background-image: url(#{$svg-path}/icons/gray/ic-print.svg);
                    height: 25px;
                    width: 25px;
                    background-size: 100%;
                    margin-right: 5px;
                    position: relative;
                }
            }
        }

        .order {

            &.col {
                .col-lg-4 {
                    flex: 0 0 37% !important;
                    max-width: 37% !important;
                }
            }
            .order-details {
                .check {
                    width: 80px;
                }

                .inprogress {
                    width: 68px;
                }

                .alert {
                    width: 80px;
                }

                div:last-child {
                    width: 81%;
                    border-left: 1px solid #ececec;

                    a, button {
                        width: auto;
                        height: 30px;
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    &.in-progress {
                        padding: 0 0 0 5px;
                        width: 85%;

                        p {
                            text-align: center;
                            font-size: 13px;
                        }

                        a, button {
                            margin-right: 0px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        .order-easy-travel {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 760px;
            margin: auto;

            .order-header {
                background-image: url(#{$svg-path}/icons/white/ic-easy-travel.svg);
                background-position: 40px center;
                background-size: 81px;
                height: 143px;

                p {
                    font-size: 24px;
                    float: left;
                    margin: 60px 160px;
                }

                div {
                    float: left;
                    margin: 35px 0 35px 160px;
                    max-width: 450px;

                    p {
                        margin: 0;

                        &:nth-child(2) {
                            max-width: 350px;
                            font-size: 14px;
                            color: $gray-300;
                            line-height: 19px;
                            margin-top: 8px;
                            text-align: left;
                            padding: 0;
                        }
                    }

                    &:nth-child(2) {
                        float: left;
                        width: 120px;
                        height: 80px;
                        background: $white;
                        margin: 35px 0px;
                        border-radius: 5px;
                        text-align: center;
                        padding: 6px;

                        label {
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 17px;
                            color: $gray-700;
                            margin-bottom: 3px;

                            &:nth-child(2) {
                                color: $easy-travel;
                                font-size: 14px;
                                line-height: 17px;
                            }
                        }

                        .tooltip-info {
                            margin-top: -80px;
                            margin-left: 160px;
                            z-index: 1;
                        }
                    }
                }
            }

            .order-details {
                flex-direction: row;

                .inprogress{
                    width: 120px;
                    height: 110px;
                    display: block;
                    background-size: 70px;
                    border-right: 2px solid $gray-400;
                    float: left;
                }


                .content {
                    min-width: 600px;
                    float: left;
                    height: 159px;
                    padding: 29px 0 0 24px !important;

                    p {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;

                        button, a {
                            float: right;
                            margin-right: 30px;
                            width: 122px;
                            margin-top: 0;
                            font-size: 14px;
                            line-height: 14px;
                            padding: 8px;
                            height: auto;
                        }

                        label {
                            color: $default;
                        }
                    }

                    h2 {
                        font-size: 26px;
                        font-weight: 700;
                        line-height: 36px;
                        margin-left: 0;
                        margin-top: 0;
                        text-align: left;
                    }
                }

                .footer {
                    width: 140px;
                    padding-right: 40px;

                    p {
                        width: 120px;
                        text-align: left;
                        margin: 38px 0 5px 0;
                    }

                    button {
                        width: 110px;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .checkout-success-cancel {
        padding-bottom: 60px;
    }
    .change-confirmation-success {
      .order {
        .order-details {
          flex-flow: row nowrap;
        }
      }
    }
}


// novo sucesso g3 - passageiro menor desacompanhado
.checkout-sucess-g3 {
    .summary{
       @media screen and (min-width: 768px) {
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }

    .head-tabs {
        h2 {
            font-size: 24px !important;
            margin-left: 3px;
        }
    }
      &.emission-title {
          padding-top: 0px !important;
          .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          a.search-flights-caption {
            right: 0px !important;
            top: 0px !important;
            position: relative;
            margin: 0 0 0 0;
          }
        }

    a.search-flights-caption {
        position: absolute;
        right: 0;
        margin-right: -117px;
        margin-top: 52px;
        cursor: pointer;
    }

    .options-flight {
        margin-top: -3px;
    }

    .order.col-lg-12 {
        justify-content: center;
        margin-left: -4px;

        .box-code {
            width: 495px;
            height: 94px;
            padding: 0;
            margin: 0;
            border-style: solid;
            align-items: center;
            justify-content: flex-start;

            h3 {
                font-size: 18px;
                margin-left: 33px;
            }

            .locator-code {
                border: 0;
                padding: 0;
                width: auto;
                height: auto;
                margin-left: 85px !important;
            }
        }
    }

    .child-alone {
        max-width: 545px;
        text-align: left;
        margin-left: 4px;
        margin-top: 15px;
    }

    .reminder-baggage {
        margin-top: 80px;
    }

    @media (max-width: 768px) {
        width: 100% !important;

        .order.col-lg-12 {
            .box-code {
                border-style: dotted;
                width: 90%;
                height: auto;
                padding-bottom: 15px;
                margin: 0 auto;

                h3 {
                    max-width: 140px;
                    margin: 0 auto;
                    margin-top: 30px;
                    margin-bottom: 10px;
                }

                .locator-code {
                    margin: 0 auto !important;
                    padding: 20px 0;
                    border: 1px dotted #ECECEC;
                    max-width: 215px;
                }
            }
        }

        .child-alone {
            max-width: 90%;
            margin: 0 auto;
            margin-top: 35px;

            p {
                font-size: 16px;
                text-align: center;
                word-wrap: break-word;
            }
        }

        .reminder-baggage {
            display: none;
        }

        .flight-allinfo {
            margin-top: 60px;
        }

    }

}
