.my-account__cencosud-points-success-container {
    .cencosud-points-success-info {
        p {
            .cencosud-points-span {
                margin: 5px 0px 0px 0px;
                display: inline-block;
            }
        }
        .cencosud-points-success-buttons {
            @media (max-width: 375px) {
                grid-gap: 5px;
                margin: 10px 0px;
                .col {
                    a {
                        .btn {
                            font-size: 12px;
                        }
                    }
                } 
            }
        }
    }
}
@media (max-width: 425px) {
    .checkout {
        .checkout-header {
            h2 {
                font-size: 25px;
            }
            .go-back-checkout {
                padding-right: 0px;
            }
        }
    }
}