.smls-purple-icons {
    font-size: 10px;
}

.smls-purple-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-purple-icons.svg");
    background-repeat: no-repeat;
    background-size: 9em 95em;
}

.no-svg .smls-purple-icons:before {
    background-image: url("../sprites/smls-purple-icons.png");
}



  .smls-purple-icons.ic-airplane:before {
      background-position: 0em 0em;
      width: 9em;
      height: 6.4em;
  }




  .smls-purple-icons.ic-airplane-card:before {
      background-position: 0em -6.4em;
      width: 4em;
      height: 3em;
  }




  .smls-purple-icons.ic-airplane-solid:before {
      background-position: 0em -9.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-arrow-back:before {
      background-position: 0em -11em;
      width: 3.2em;
      height: 1.7em;
  }




  .smls-purple-icons.ic-arrow-left:before {
      background-position: 0em -12.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-arrow-right:before {
      background-position: 0em -14.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-bank:before {
      background-position: 0em -15.9em;
      width: 8em;
      height: 7.5em;
  }




  .smls-purple-icons.ic-bell:before {
      background-position: 0em -23.4em;
      width: 4em;
      height: 4em;
  }




  .smls-purple-icons.ic-blocked:before {
      background-position: 0em -27.4em;
      width: 1.6em;
      height: 1.2em;
  }




  .smls-purple-icons.ic-calendar-30-days:before {
      background-position: 0em -28.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-card-currency:before {
      background-position: 0em -30.2em;
      width: 6em;
      height: 5em;
  }




  .smls-purple-icons.ic-check:before {
      background-position: 0em -35.2em;
      width: 1.6em;
      height: 1.2em;
  }




  .smls-purple-icons.ic-cloud:before {
      background-position: 0em -36.4em;
      width: 8.7em;
      height: 3em;
  }




  .smls-purple-icons.ic-copy:before {
      background-position: 0em -39.4em;
      width: 1.6395em;
      height: 2.0475000000000003em;
  }




  .smls-purple-icons.ic-databases:before {
      background-position: 0em -41.5em;
      width: 4.8em;
      height: 3.4em;
  }




  .smls-purple-icons.ic-databases-x10:before {
      background-position: 0em -44.9em;
      width: 4.8em;
      height: 3.4em;
  }




  .smls-purple-icons.ic-down-arrow:before {
      background-position: 0em -48.3em;
      width: 2.2142em;
      height: 3.149em;
  }




  .smls-purple-icons.ic-miles-stack:before {
      background-position: 0em -51.5em;
      width: 5.5em;
      height: 5.1em;
  }




  .smls-purple-icons.ic-miles-stack-10:before {
      background-position: 0em -56.6em;
      width: 5.8em;
      height: 5.9em;
  }




  .smls-purple-icons.ic-notebook:before {
      background-position: 0em -62.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-plus:before {
      background-position: 0em -64.1em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-purple-icons.ic-price:before {
      background-position: 0em -66.5em;
      width: 4.6em;
      height: 4.6em;
  }




  .smls-purple-icons.ic-smiles-connector:before {
      background-position: 0em -71.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-purple-icons.ic-suspend:before {
      background-position: 0em -72.7em;
      width: 1.6em;
      height: 1.2em;
  }




  .smls-purple-icons.ic-ticket-money:before {
      background-position: 0em -73.9em;
      width: 8em;
      height: 8em;
  }




  .smls-purple-icons.ic-two-clouds:before {
      background-position: 0em -81.9em;
      width: 3.0100000000000002em;
      height: 1.6em;
  }




  .smls-purple-icons.singleGear:before {
      background-position: 0em -83.5em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-purple-icons.siren:before {
      background-position: 0em -85.9em;
      width: 3.9em;
      height: 3.9em;
  }




  .smls-purple-icons.success:before {
      background-position: 0em -89.8em;
      width: 5.2em;
      height: 5.2em;
  }





