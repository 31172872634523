.payment {
    margin: 10px 0;
    text-align: left;

    .info {
        border: 1px solid #ececec;
        padding: 8px;
        margin: 5px 0;

        h5 {
            font-size: 13px;
            color: $gray-700;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                font-size: 12px;
                line-height: 22px;

                .smls-gray-icons {
                    float: left;
                    margin: 0px 8px 0 0;
                    width: 18px;
                }

                .brand-light {
                    color: $default;
                }
            }
        }
    }
}

@media (min-width: 768px) {

    .payment {
        margin: 10px 0;

        .info {
            padding: 0;
            border: none;
            margin: 0;
        }
    }
}
