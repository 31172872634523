.service-jarvis__tooltip {
    position: absolute;
    left: 100px;
    background-color: $gray-300;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
    z-index: 5;
    top: 39px;
    color: $black;
    min-width: 350px;
    div{
        img{
            width: 10px;
            height: 10px;
        }
        .service-jarvis__tooltip-text {
            font-family: $semiBoldFontFamily !important;
            color: $gray-800;
            font-size: 12px !important;
            margin: 0px;
            line-height: 1;
        }
    }
}

@media only screen and (max-width: 575px){
    .service-jarvis__tooltip {
        left: -50px;
        top: 51px;
        min-width: 240px;
    }
}