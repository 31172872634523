.footer {
    .smiles-footer-description{
        margin-left: 30px;
    }

    &__address {
        padding: 10px 0;
        margin: 30px auto 0 auto;
        background-color: $gray-800;
        color: $white;

        p {
            margin: 5px auto 0 auto;
            span{
                @include bms-font-size (3.5vw, 13px);
                @include bms-line-height(5vw, 14px);
                &.bold {
                    font-family: $boldFontFamily
                }
            }
        }

        .seguinos {
            @include bms-font-size (4vw, 12px);
            font-family: $boldFontFamily;
        }

        @media screen and (max-width: 768px) {
            .footer-grid__mb {
                display: grid;
                grid-template-areas: "a b" "c c";
                width: 90%;
                margin: 0 auto;
                gap: 15px;

                .footer-logo {
                    grid-area: a;
                }

                .footer-social {
                    grid-area: b;
                    min-width: 100%;
                }

                .footer-legals {
                    grid-area: c;
                    max-width: 100%;
                }

                .smiles-icons__logo a.smiles {
                    width: unset;
                }

                .smiles-footer-description {
                    width: 100%;
                    margin-left: 0px;
                }

                .seguinos {
                    display: none;
                }
            }
        }
    }
}

.smiles-icons_{
    &_logo{
        a {
            display: block;
            height: 40px;
            margin-right: 20px;
            text-align: center;
            font-size: .3em;

            &.smiles {
                margin-top: 15px;
                background: url('../../images/svg/logos/smiles-white.svg') no-repeat;
                background-size: 100px;
                width: 100px;
            }
        }
    }

    &_bottom-bar, &_footer-social{
        .social-list {
            li{
                display: inline-block;
            }

            p {
                text-align: center;
            }

            a {
                display: block;
                height: 30px;
                margin-right: 20px;
                text-align: center;
                font-size: .3em;
                &.linkedin {
                    background-image: url('/images/svg/socialNetwork/linkedin.svg')
                }
                &.facebook {
                    background-image: url('/images/svg/socialNetwork/facebook.svg')
                }
                &.instagram {
                    background-image: url('/images/svg/socialNetwork/instagram.svg')
                }
                &.twitter {
                    background-image: url('/images/svg/socialNetwork/twitter.svg')
                }
                background-position: center !important;
                background-repeat: no-repeat;
            }
        }
    }
}

.smiles-contact {
    display: flex;
    @media (min-width: 768px) {
      width: auto !important;
    }

    .ic-phone {
        background: url(#{$image-path}/footer/phone.png) no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
        background-size: 20px;
        background-position: center !important;
    }

    .wapp-icon {
        background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 3px;
        display: inline-block;
        background-size: 100%;
        background-position: center !important;
    }
}

.smiles-footer-col {
    display: flex;
    flex-wrap: wrap;

    .smiles-footer-image {
        margin: 0 50px;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
}

.smiles-footer-image {
    width: 70px;
    height: 70px;
    background-position: center !important;
    background-size: contain !important;
    &.e-commerce {
        background: url(#{$image-path}/footer/e-commerce.png) no-repeat;
    }
    &.data-fiscal {
        background: url(#{$image-path}/footer/data-fiscal.png) no-repeat;
    }
    &.ministerio-turismo {
        background: url(#{$image-path}/footer/ministerio-turismo.png) no-repeat;
    }
    &.pdp {
        background: url(#{$image-path}/footer/pdp.png) no-repeat;
    }
    &.essencial-accessibility {
        background: url(#{$image-path}/footer/accessibility.png) no-repeat;
        background-size: 100px;
    }
    &.defensa-caba {
        background: url(#{$image-path}/footer/defensa-caba.png) no-repeat;
        background-size: 100px;
        width: 200px;
    }
    &.defensa-nacion {
        background: url(#{$image-path}/footer/defensa-nacion.png) no-repeat;
        background-size: 100px;
        width: 170px;
    }

}

.rodape-parceiros {
    border-top: 1px solid $gray-300;

    .no-border-top {
        border-top: 0px solid $gray-300;
    }

    .itens-menu-parceiros {
        a {
          @include bms-font-size (4vw, 14.5px);

          font-family: $semiBoldFontFamily;
          color: $gray-700;
          cursor: pointer;
          transition: color .3s;
          font-size: 14.5px;

          &:hover{
            color: $primary;
            text-decoration: none;
          }

          padding-right: 0.55rem !important;
          padding-left: 0.55rem !important;
        }
    }

    .contato-menu-parceiros {
        div {
            p {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .parceiros-programa {
        border-bottom: 1px solid $gray-300;

        p {
            text-align: left;
            font-size: 14px;
            font-weight: normal;
        }

        .img-programa {
            div {
                width: 100%;
                height: 50px;
            }
        }

        .gol {
            max-height: 60px;
        }

        .compania-asociada {

            &::after,
            &::before {
                content: "";
                width: 1px;
                height: 70%;
                position: absolute;
                background: $gray-300;
                top: 65%;
                margin-top: -10%;
            }

            &::before {
                left: 0px;
            }

            &::after {
                right: 0px;
            }

            p {
                text-align: center;
                font-family: $boldFontFamily;
                @include bms-font-size (3.5vw, 16px);
                color: $default;
            }

            .partners-grid {
                display: grid;
                grid-template-columns: repeat(8,1fr);

                .partner-logo {
                    padding: 15px 10px; 

                    &::before {
                        content: "";
                        width: 1px;
                        height: 70%;
                        background: $gray-300;
                        position: absolute;
                        right: 0px;
                    }

                    &::after {
                        content: "";
                        width: 80%;
                        height: 1px;
                        position: absolute;
                        background: $gray-300;
                        bottom: 0px;
                    }

                    &:nth-child(8n) {
                        &::before {
                            content: none;
                        }
                    }

                    .parceiros-card {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 25px;
                        @media(min-width: $site-width-mobile) {
                            &.royal-air-maroc {
                                min-height: 45px;
                            }
                            &.south-african-airways {
                                min-height: 35px;
                            }
                            &.emirates {
                                min-height: 35px;
                            }
                            &.airfrance {
                                min-height: 30px;
                                max-width: 95px;
                            }
                            &.klm {
                                min-height: 30px;
                            }
                            &.qatar {
                                min-height: 30px;
                                max-width: 95px;
                            }
                            &.aerolineas-argentinas {
                                min-height: 30px;
                            }
                            &.etihad {
                                min-height: 35px;
                            }
                            &.tap {
                                min-height: 45px;
                            }
                            &.alitalia {
                                min-height: 35px;
                                max-width: 95px;
                            }
                            &.copa-airlines {
                                min-height: 30px;
                            }
                            &.korean-air {
                                min-height: 30px;
                            }
                            &.air-canada {
                                min-height: 30px;
                            }
                            &.aero-mexico {
                                min-height: 30px;
                            }
                            &.south-african-airways {
                                min-height: 30px;
                            }
                            &.ethiopian {
                                min-height: 35px;
                            }
                            &.delta {
                                min-height: 35px;
                                max-width: 120px;
                            }
                        }
        
                        @media(max-width: $site-width-mobile) {
                            &.royal-air-maroc {
                                min-height: 55px;
                            }
                            &.south-african-airways {
                                min-height: 45px;
                            }
                            &.emirates {
                                min-height: 45px;
                            }
                            &.airfrance {
                                min-height: 35px;
                                max-width: 95px;
                            }
                            &.klm {
                                min-height: 35px;
                            }
                            &.qatar {
                                min-height: 35px;
                                max-width: 95px;
                            }
                            &.aerolineas-argentinas {
                                min-height: 35px;
                            }
                            &.etihad {
                                min-height: 45px;
                            }
                            &.tap {
                                min-height: 35px;
                            }
                            &.alitalia {
                                min-height: 35px;
                                max-width: 95px;
                            }
                            &.copa-airlines {
                                min-height: 35px;
                            }
                            &.korean-air {
                                min-height: 35px;
                            }
                            &.air-canada {
                                min-height: 35px;
                            }
                            &.aero-mexico {
                                min-height: 35px;
                            }
                            &.south-african-airways {
                                min-height: 45px;
        
                            }
                            &.ethiopian {
                                min-height: 45px;
                            }
                            &.delta {
                                min-height: 45px;
                                max-width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        border-top: 2px solid #ECECEC !important;
        padding-top: 20px;

        .compania-asociada {
            p {
                width: 100%;
                border-top: 2px solid $gray-300;
                padding-top: 20px;
            }
        }

        .parceiros-programa {
            border-bottom: 0px;
        }
    }
}

// Small devices (mobile and tablet, up to 768px)
@media (max-width: 768px) {
    .smiles-icon {
        text-align: center;

        &__logo{
            a {
                margin: 10px auto;
            }
        }

        &__bottom-bar {
            text-align: center;
            .social-list {
                a {
                    margin: 0px !important;
                }
            }
        }
    }
}

.smiles-orange {
    font-family: $boldFontFamily;
    color: $orange
}

hr {
    height: 5px;
}

@media (min-width: $site-width-mobile) {
    .flex-grow-sm-2 {
        flex-grow: 2;
    }
}
@media (max-width: $site-width-mobile) {
    .flex-grow-xs-1 {
        flex-grow: 1;
    }
}

.hr-fix {
   min-height: 36px;
}

.smiles-anchor {
    color: $gray-700;
    font-family: $semiBoldFontFamily;
    line-height: 1.6;
}
