
.benefit-category-list {
  ul {
    margin: 0;
    li {
      list-style-type: none !important;
      display: inline-block;
      margin-bottom: 16px;
      div>div:first-child {
        vertical-align: top;
        width: 8%;
        text-align: center;
        display: inline-block;
      }
      div>div:last-child {
        width: 88%;
        display: inline-block;
      }
    }
  }
  &.half-column {
    columns: 2;
    ul {
      li {
        div>div:first-child {
          width: 12%;
        }
        div>div:last-child {
          width: 85%;
        }
      }
    }
  }
  .smls-gray-icons,
  .smls-gold-icons,
  .smls-black-icons {
    &.ic-box-checked {
      font-size: 12px;
    }
    &.ic-star-solid,
    &.ic-renewal-cycle {
      font-size: 14px;
    }
    &.ic-suitcase-solid,
    &.ic-ticket-solid,
    &.ic-anticipation {
      font-size: 15px;
    }
    &.ic-seat-solid {
      font-size: 17px;
    }
  }
  .smls-black-icons {
    &.ic-expiration {
      font-size: 17px;
    }
  }
  .smls-orange-icons,
  .smls-gray-icons,
  .smls-gold-icons {
    &.ic-expiration {
      font-size: 13px;
    }
  }

}


@media (max-width: 768px) {
  .benefit-category-list {
    &.half-column {
      columns: 1;
    }
    ul {
      margin: 0;
      li {
        list-style-type: none !important;
        display: inline-block;
        margin-bottom: 16px;
        div>div:first-child {
          vertical-align: top;
          width: 12%;
          text-align: center;
          display: inline-block;
        }
        div>div:last-child {
          width: 86%;
          display: inline-block;
        }
      }
    }
    .hidden-phone {
      display: none !important;
    }
  }
}
