.smls-orange-icons {
    font-size: 10px;
}

.smls-orange-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-orange-icons.svg");
    background-repeat: no-repeat;
    background-size: 23.3852em 162.7em;
}

.no-svg .smls-orange-icons:before {
    background-image: url("../sprites/smls-orange-icons.png");
}



  .smls-orange-icons.ic-airplane:before {
      background-position: 0em 0em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-airplane-solid:before {
      background-position: 0em -1.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-airplane-take-off:before {
      background-position: 0em -3.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-alert:before {
      background-position: 0em -4.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-alert-adhesion:before {
      background-position: 0em -6.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-all-classes:before {
      background-position: 0em -8em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-orange-icons.ic-arrow-down:before {
      background-position: 0em -10.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-left:before {
      background-position: 0em -12.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-left-long:before {
      background-position: 0em -13.7em;
      width: 1.69em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-left-right:before {
      background-position: 0em -15.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-left-sm:before {
      background-position: 0em -16.9em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-right:before {
      background-position: 0em -18.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-right-large:before {
      background-position: 0em -20.1em;
      width: 2em;
      height: 2em;
  }




  .smls-orange-icons.ic-arrow-right-long:before {
      background-position: 0em -22.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-right-sm:before {
      background-position: 0em -23.7em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-arrow-up:before {
      background-position: 0em -25.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-baby:before {
      background-position: 0em -26.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-back-forth-arrows:before {
      background-position: 0em -28.5em;
      width: 1.6em;
      height: 0.643em;
  }




  .smls-orange-icons.ic-baggage:before {
      background-position: 0em -29.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-baggage-hand:before {
      background-position: 0em -30.8em;
      width: 8.8em;
      height: 9.3em;
  }




  .smls-orange-icons.ic-baggage-transport:before {
      background-position: 0em -40.1em;
      width: 9.4em;
      height: 9.3em;
  }




  .smls-orange-icons.ic-building:before {
      background-position: 0em -49.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-calendar:before {
      background-position: 0em -51em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-cancel-atention:before {
      background-position: 0em -52.6em;
      width: 2.414em;
      height: 2.529em;
  }




  .smls-orange-icons.ic-cancel-mobile:before {
      background-position: 0em -55.2em;
      width: 2.357em;
      height: 2.786em;
  }




  .smls-orange-icons.ic-cancel-whatsapp:before {
      background-position: 0em -58em;
      width: 2.5em;
      height: 2.541em;
  }




  .smls-orange-icons.ic-card-error:before {
      background-position: 0em -60.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-card-error--alert:before {
      background-position: 0em -62.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-cellphone:before {
      background-position: 0em -63.8em;
      width: 6.7299999999999995em;
      height: 7.4em;
  }




  .smls-orange-icons.ic-check-solid:before {
      background-position: 0em -71.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-circle-check:before {
      background-position: 0em -72.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-circle-error:before {
      background-position: 0em -74.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-circle-plus:before {
      background-position: 0em -76em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-clock:before {
      background-position: 0em -77.6em;
      width: 6.6em;
      height: 6.8em;
  }




  .smls-orange-icons.ic-coin-stack:before {
      background-position: 0em -84.4em;
      width: 3.4em;
      height: 3.0210999999999997em;
  }




  .smls-orange-icons.ic-copy:before {
      background-position: 0em -87.5em;
      width: 1.6395em;
      height: 2.0475000000000003em;
  }




  .smls-orange-icons.ic-countdown:before {
      background-position: 0em -89.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-database:before {
      background-position: 0em -91.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-date-selector:before {
      background-position: 0em -92.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-delete:before {
      background-position: 0em -94.4em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-departure-solid:before {
      background-position: 0em -96.8em;
      width: 2.9em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-economic-class:before {
      background-position: 0em -98.4em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-orange-icons.ic-edit:before {
      background-position: 0em -100.9em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-exclamation-circle:before {
      background-position: 0em -103.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-executive-class:before {
      background-position: 0em -104.9em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-orange-icons.ic-expiration:before {
      background-position: 0em -107.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-family:before {
      background-position: 0em -109em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-flights:before {
      background-position: 0em -110.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-flights-solid:before {
      background-position: 0em -112.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-form-check:before {
      background-position: 0em -113.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-friends:before {
      background-position: 0em -115.4em;
      width: 3.4em;
      height: 2.6411000000000002em;
  }




  .smls-orange-icons.ic-globe:before {
      background-position: 0em -118.1em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-multi-profile-solid:before {
      background-position: 0em -120.5em;
      width: 2.104em;
      height: 2.1390000000000002em;
  }




  .smls-orange-icons.ic-pencil:before {
      background-position: 0em -122.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-pet:before {
      background-position: 0em -124.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-plus:before {
      background-position: 0em -125.9em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-profile-solid:before {
      background-position: 0em -128.3em;
      width: 2em;
      height: 2em;
  }




  .smls-orange-icons.ic-profile-with-circle:before {
      background-position: 0em -130.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-question-orange:before {
      background-position: 0em -131.9em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-search:before {
      background-position: 0em -134.3em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-orange-icons.ic-share:before {
      background-position: 0em -136.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-share-down:before {
      background-position: 0em -138.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-smiles_diamante-v2:before {
      background-position: 0em -139.9em;
      width: 13.8178em;
      height: 2.2333em;
  }




  .smls-orange-icons.ic-stopwatch:before {
      background-position: 0em -142.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-swap:before {
      background-position: 0em -143.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-ticket:before {
      background-position: 0em -145.4em;
      width: 3em;
      height: 2.1em;
  }




  .smls-orange-icons.ic-title-seats:before {
      background-position: 0em -147.5em;
      width: 10.8em;
      height: 7.1em;
  }




  .smls-orange-icons.ic-travel:before {
      background-position: 0em -154.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.ic-warning:before {
      background-position: 0em -156.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-orange-icons.logo-viaje-facil:before {
      background-position: 0em -157.8em;
      width: 23.3852em;
      height: 4.8136em;
  }





