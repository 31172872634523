html,
body {
  font-family: $regularFontFamily;
  color: $gray-700;
  padding-top: 30px;
  padding-right: 0px !important;
  margin: 0;

  button,
  input,
  input:focus {
    box-sizing: border-box;
    outline: none !important;
    box-shadow: none !important;
  }

  &.modal-open {
    overflow: visible !important;
  }

  iframe[src*="SMILESAR_LOGIN_REACT"], a#_ec_rgb_link {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
  font-family: $boldFontFamily;
}

h2 {
  font-size: 1.1rem;
}

ul {
  list-style: none;
}

p {
  @include bms-font-size(13px, 15px);
  @include bms-line-height(18px, 20px);
}

// text-color
.g-700 {
  * {
    color: $gray-700;
  }
}

button {
  cursor: pointer;
}

//Strucuture
.container {
  padding: 0;
  width: 100% !important;
  max-width: 100% !important;
}
.row {
  padding: 0;
  margin: 0;
}
.content-overflow-x {
  overflow-x: hidden;
}
.horizontal-scrool {
  float: left;
  width: 100%;
}

//Form Controls
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//Buttons
.btn {
  @include bms-font-size(14px, 16px);
  @include bms-line-height(18px, 18px);
  font-family: $boldFontFamily;
  height: 40px;
}

// Links
.lnk-primary {
  color: $primary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

//Alerts
.alert {
  &.alert-danger {
    @include bms-font-size(12px, 12px);
    @include bms-line-height(16px, 16px);
    display: flex;
    align-items: center;
    font-family: $boldFontFamily;
    padding: 10px;
    color: $white;
    background-color: $danger;
    border: 1px solid $danger;
    text-align: left;
    min-height: 60px;

    > span {
      width: 35px;
      height: 35px;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  &.alert-success {
    @include bms-font-size(12px, 12px);
    @include bms-line-height(16px, 16px);
    display: flex;
    align-items: center;
    font-family: $boldFontFamily;
    padding: 10px;
    color: $white;
    background-color: $green;
    border: 1px solid $green;
    text-align: left;
    min-height: 60px;

    > span {
      width: 35px;
      height: 35px;
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

// Small devices (mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .horizontal-scrool {
    overflow-x: auto;
    width: 830px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  body {
    padding-top: 80px;
  }
  .container {
    width: $site-width-desktop;
    max-width: $site-width-desktop;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    #root {
      overflow-x: hidden;
    }
  }
}
