.steps {
  &__box {
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    header {
      height: 42px;
      font-family: $boldFontFamily;
      border-bottom: 1px solid $primary;
      margin-bottom: 30px;
      text-align: left;
      h2 {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 18px;
        span {
          &:first-child {
            text-align: center;
            line-height: 42px;
            width: 42px;
            height: 42px;
            margin-right: 5px;
            border-radius: $border-radius;
            background-color: $primary;
            color: $white;
            font-size: 22px;
          }
        }
        strong {
          max-width: 70%;
        }
      }
    }
    &.completed,
    &.warning {
      h2 {
        background-repeat: no-repeat;
        background-position: 98% center;
        background-size: 21px;
      }
      input {
        color: $primary;
        &:focus {
          color: $gray-700;
        }
      }
    }
    &.warning {
      h2 {
        background-image: url(#{$svg-path}/icons/orange/ic-warning.svg);
      }
    }
    &.completed {
      h2 {
        background-image: url(#{$svg-path}/icons/orange/ic-check-solid.svg);
      }
    }

    &.disabled {
      header {
        border-bottom-color: $gray-500;
        h2 {
          color: $gray-500;
          span {
            background-color: $gray-500;
          }
        }
      }
      .content {
        display: none;
      }
    }

    .alert-message {
      color: $primary;
    }
  }
}
