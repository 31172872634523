.search-flight {
    .details {
        display: none;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .search-flight {
        .details {
            display: block;
        }
    }
}