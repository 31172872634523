#cookies-container {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 99;
  &.modalCookie-open {
    display: flex;
  }
  &.modalCookie-close {
    display: none;
  }
  .overlay {
    position: absolute;
    width: 100vw;
    height: 400vh;
    z-index: 97;
  }
  .modal-cookie {
    padding: 2rem 7rem;
    position: relative;
    z-index: 98;
    background: $white;
    box-shadow: 0px 1px 8px $black;
    gap: 1rem;
    width: 100%;
    height: auto;
    transition: transform 0.8s ease-in-out;
    &.modalCookie-open {
      transform: translateY(0);
    }
    &.modalCookie-close {
      transform: translateY(200px);
    }
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 2rem 3rem 5.5rem 3rem;
    }
    .modal-cookie__text {
      
      @media (max-width: 768px) {
        width: 100%;
      }
      h5,
      p {
        color: #464646;
        a {
          color: $primary;
          text-decoration: underline;
        }
      }
      p {
        width: 100%;
      }
    }
    .modal-cookie__buttons {
      
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 992px) {
        flex-direction: row;
      }
      button {
        &:hover {
          opacity: 0.8;
        }
        align-items: center;
        background-color: $primary;
        border-radius: 4px;
        border: 1px solid $primary;
        font-weight: bold;
        padding: 0.4rem 1.327rem;
        color: #fff;
        display: flex;
        font-size: 13px;
        justify-content: center;
        border: none;
      }
    }
  }
}
