.frm-default {
  label,
  input,
  select {
    @include bms-font-size(11px, 15px);
    @include bms-line-height(18px, 20px);
  }

  input {
    padding: 0 0 0 10px;
    &.error {
      border-color: $danger;
    }
    &.input-error {
      border-color: $orangeBorder;
      position: relative;
      & + p {
        position: absolute;
        color: $orangeBorder;
        font-size: 8pt;
        left: 0;
        bottom: -25pt;
      }
      &[type="checkbox"] {
        ~ span, ~ div {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -3px;
            display: block;
            width: 23px;
            height: 20px;
            border: 1px solid red;
            padding: 10px 2px 10px 10px;
          }
        }
      }
    }
  }

  input:not([type="checkbox"]):not([type="radio"]),
  select {
    height: 40px;
  }

  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    -webkit-border-radius: 0px;
    border-radius: 2px;
    background: #ffffff;
    background-image: url(#{$svg-path}/icons/gray/ic-dropdown-padding.svg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 25px;
    cursor: pointer;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-image: none !important;
    }
  }

  label {
    font-weight: bold;
  }

  hr {
    height: 1px;
    margin: $space-form-elements;
    padding: 0;
  }

  &.retrocredit {
    .align-error-message .form-group {
      height: 40px;
    }

    .show-tooltip-icon {
      &.row {
        align-items: center;
      }
      .request-miles-tooltip {
        margin: $space-form-elements;
      }
    }
  }

  .form-group {
    margin: $space-form-elements;

    &.long-error {
      margin-bottom: 0;
      input+p{
        margin-bottom: 5px;
        position: relative;
        bottom: unset;
        line-height: 1rem;
      }
    }

    &-custom {
      margin: $space-form-elements;
      .form-group {
        margin-bottom: 0;
      }
      small {
        font-size: 14px;
        color: $danger;
        margin-top: 10px;
        display: block;
      }
    }

    > span {
      color: $danger;
      @include bms-font-size(10px, 13px);
    }
  }

  .form-group.row,
  .row {
    padding: 0;
    margin: 0;
    width: 100%;
    [class*="col"] {
      padding: 0;
    }
    [class*="col-3"],
    [class*="col-4"],
    [class*="col-5"],
    [class*="col-6"],
    [class*="col-7"],
    [class*="col-8"],
    [class*="col-9"] {
      padding: 0;
      margin: 0;
      &:not(:last-child) {
        padding-right: $space-after-field;
      }
    }
  }

  .terms {
    margin-bottom: 20px !important;
    .form-check {
      margin-bottom: 10px;
    }
  }

  .is-required-content {
    &:after {
      content: "*";
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .frm-default {
    input:not([type="checkbox"]):not([type="radio"]),
    select, .modify-dni {
      height: 50px;
    }

    &.retrocredit {
      .align-error-message .form-group {
        height: 50px;
      }
    }

    .row {
      [class*="col"] {
        &:not(:last-child) {
          padding-right: $space-after-field;
        }
      }
    }
  }
}

.frm-pre-register {
  margin-bottom: 40px !important;

  h3 {
    font-size: 20px;
  }

  .subtitle {
    margin-bottom: 20px;
    margin-top: 8px;
  }

  span {
    display: block;
    font-size: 15px;

  }

  h2, p {
    display: none;
  }
}

// Input animate
.form-group {
  &.animate {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    position: relative;
    @mixin small-label() {
      top: $animate-small-label-top;
      left: $animate-small-label-left;
      @media (min-width: 768px) {
        top: $animate-small-label-top-desk;
        left: $animate-small-label-left-desk;
      }
      @include bms-font-size(9px, 10px);

      @media (max-width: 380px ){
        @include bms-font-size(5px, 6px);
      }
      opacity: 1;
      color: $gray-700;
    }
    @mixin disabled-and-readonly() {
      border-color: $gray-300;
      box-shadow: none;
    }
    @mixin disabled() {
      @include disabled-and-readonly;
    }
    @mixin read-only() {
      @include disabled-and-readonly;
      &:focus {
        border-color: $primary;
      }
    }
    &.has-content {
      input {
        ~ label {
          @include small-label;
        }
      }
    }
    input {
      &:focus ~ label,
      &:not(:focus):valid ~ label {
        @include small-label;
      }
      &:disabled {
        @include disabled;
      }
      // The disable attribute is used as a work around for read-only on IE
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:disabled {
          background-color: $primary;
          ~ label {
            @include small-label;
          }
        }
      }
      // FF
      &:-moz-read-only {
        @include read-only;

        ~ label {
          @include small-label;
        }
      }
      &:read-only {
        @include read-only;
        ~ label {
          @include small-label;
        }
      }
      &:focus:valid ~ label {
        color: $primary;
      }
      &:focus:invalid ~ label {
        color: $primary;
      }
      &:not(:focus) {
        padding: $animate-padding;
      }
      @media (min-width: 768px) {
        &:not(:focus) {
          padding: $animate-padding-desk;
        }
      }
      &:focus {
        padding: $animate-padding;
        @media (min-width: 768px) {
          padding: $animate-padding-desk;
        }
        &:invalid:focus {
          border-color: $primary;
          ~ label {
            color: $primary;
          }
        }
      }
    }
    label {
      position: absolute;
      pointer-events: none;
      top: $animate-label-top;
      left: $animate-label-left;
      @media (min-width: 768px) {
        top: $animate-label-top-desk;
        left: $animate-label-left-desk;
      }
      -webkit-transition: 0.2s ease all;
      transition: 0.2s ease all;
      color: $gray-700;
    }
    label.smls-invalid-field {
      @include small-label;
      top: 28px !important;
    }
  }

  .btn {
    &.disabled {
      cursor: no-drop;
    }
  }
}

// Radio and CheckBox
.form-check-label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    color: $gray-600;
  }

  input {
    display: none;
    ~ span, ~ div {
      &:before {
        content: " ";
        position: relative;
        float: left;
        margin-top: 1px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        background-image: url(#{$svg-path}/icons/ui/ic-input-radio.svg);
        background-repeat: no-repeat;
        background-position: 0 bottom;
      }
      &.large {
        &:before {
          margin-top: -3px;
          @media (min-width: 768px) {
            margin-top: 0;
          }
          width: 24px;
          height: 24px;
          background-size: 48px;
        }
      }
    }

    &:checked {
      ~ span, ~ div {
        &:before {
          background-position: -16px bottom;
        }
        &.large {
          &:before {
            background-position: -24px bottom;
          }
        }
      }
    }

    &[type="checkbox"] {
      ~ span, ~ div {
        &:before {
          position: absolute;
          left: 0;
          top: 2px;
          background-image: url(#{$svg-path}/icons/ui/ic-input-checkbox.svg);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

// flight radio and checkBox
.form-check-label-emission {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.disabled {
    color: $gray-600;
  }

  input {
    display: none;
    ~ span, ~ div {
      &:before {
        content: " ";
        position: relative;
        float: left;
        margin-top: 1px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        background-image: url(#{$svg-path}/icons/ui/ic-input-radio.svg);
        background-repeat: no-repeat;
        background-position: 0 bottom;
      }
      &.large {
        &:before {
          margin-top: -3px;
          @media (min-width: 768px) {
            margin-top: 0;
          }
          width: 24px;
          height: 24px;
          background-size: 48px;
        }
      }
    }

    &:checked {
      ~ span, ~ div {
        &:before {
          background-position: -16px bottom;
        }
        &.large {
          &:before {
            background-position: -24px bottom;
          }
        }
      }
    }

    &[type="checkbox"] {
      ~ span, ~ div {
        &:before {
          left: 0;
          top: 2px;
          background-image: url(#{$svg-path}/icons/ui/ic-input-checkbox.svg);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

// Buttons
.btn {
  &.btn-inverse {
    color: $primary;
    background-color: $white;
  }
  &.btn-inverse-club {
    color: $club;
    background-color: $white;
  }

  &.btn-collapse {
    height: 16px;
    width: 16px;
    background-image: url(#{$svg-path}/icons/orange/ic-arrow-up.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px;
    background-color: transparent;
    &.collapsed {
      background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
    }
  }
}

//PlaceHolder Input date
.placeholderclass::before {
  width: 100%;
  content: attr(placeholder);
}

.placeholderclass:hover::before {
  width: 0%;
  content: "";
}
