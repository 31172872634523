.modal {
  &__custom {
    .modal-header {
      border-bottom: 0;

      &__content-reverse {
        flex-direction: column-reverse;
      }

      .modal-title {
        width: 100%;
        text-align: center;
      }
    }
    .modal-body {
      max-height: 70vh;
      overflow-y: auto;

      p {
        &.title {
          margin-bottom: 2pt;
        }
        &.info {
          font-weight: 200;
        }
      }

      .tab-pane {
        &.primary {
          overflow: auto;
          max-height: 70vh;
        }
      }

      .nav {
        &__custom {
          margin-bottom: 20pt;

          > li {
            a {
              color: $gray-700;
              font-size: 10pt;

              &.active {
                color: $default;
                border-bottom: 2px solid $default;
              }
            }
          }
        }

        &-tabs {
          margin-bottom: 20pt;

          a {
            font-size: 9pt;
          }
        }

        &-pills {
          li {
            overflow: hidden;

            &:first-child {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }

            &:last-child {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
    .modal-footer {
      border-top: 0px;
      justify-content: center;
      button {
        min-width: 150px;
      }
    }
    &.loading-modal {
      div {
        display: flex;
        justify-content: center;
        img {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  &__dashed {
    .modal-content {
      border: 2px dashed $default;
    }
    .modal-header {
      position: absolute;
      width: 100%;
      top: -56px;

      .modal-title {
        color: $white;
        background-color: $default;
        border-radius: 10px;
        padding: 4px 20px;
        width: auto;
        margin: 0 auto;
      }

      .close {
        color: white;
        opacity: 1;
        top: 10px;
        position: relative;
      }
    }

    .modal-body {
      padding-top: 2rem;
    }
  }
  &__custom-checkout {
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      border-top: none;
      justify-content: center;
      flex-direction: row;
      button {
        min-width: 100px;
        margin: 0 5px;
      }
    }
  }
  &.wait-search-result {
    .modal-dialog-centered {
      .modal-content {
        margin: auto;
        width: 90%;
        .modal-header {
          display: none;
        }
        .modal-body {
          padding: 30px 0 0 0;
          text-align: center;
          p {
            font-size: 14px;
            font-style: italic;
            font-family: $boldFontFamily;
          }
          .easy-travel {
            margin: 20px;
            background-color: #ffe5cf;
            border: 1px solid #fd7909;
            border-radius: 10px;
            padding: 90px 36px 44px 36px;
            font-size: 15px;
            background-image: url(../images/svg/icons/orange/logo-viaje-facil.svg);
            background-repeat: no-repeat;
            background-position: center 30px;
            strong {
              font-family: $boldFontFamily;
              display: inline-block;
            }
          }
        }
        .modal-footer {
          position: relative;
          .loading-airlines {
            height: 45px;
          }
          .aninated-text {
            font-family: $regularFontFamily;
            font-size: 14px;
            position: absolute;
            width: 100%;
            text-align: left;
            padding-left: 40%;
            font-style: italic;
            bottom: -40px;
            left: 0;
            color: $gray-400;
            span {
              &:after {
                overflow: hidden;
                display: inline-block;
                vertical-align: bottom;
                -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
                animation: ellipsis steps(4, end) 1500ms infinite;
                content: "\2026";
                width: 0px;
              }
              @keyframes ellipsis {
                to {
                  width: 1.25em;
                }
              }

              @-webkit-keyframes ellipsis {
                to {
                  width: 1.25em;
                }
              }
            }
          }
        }
      }
    }
  }
  &.time-expired {
    .modal-dialog-centered {
      .modal-content {
        margin: auto;
        width: 90%;
        * {
          display: block;
          border: none;
          text-align: center;
          margin: auto;
        }
        .modal-header {
          padding-top: 40px;
          h5 {
            @include bms-font-size(6vw, 24px);
          }
          .ic-stopwatch {
            font-size: 32px;
          }
        }
        .modal-body {
          p {
            font-family: $boldFontFamily;
            @include bms-font-size(3.5vw, 14px);
          }
        }
        .modal-footer {
          .btn {
            width: 100px;
            white-space: normal;
            height: auto;
            @include bms-font-size(3.5vw, 14px);
          }
        }
      }
    }
  }
  &.vouchers {
    .modal-header {
      border-bottom: 0;
      background-color: $default;
      flex-direction: row;

      .close {
        color: $white;
        margin: 0;
        padding: 0px 5px;
        font-size: 2rem;
        line-height: 2rem;
      }

      .modal-title {
        width: 100%;
        text-align: left;
        color: $white;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
    .modal-body {
      overflow-y: scroll;
      max-height: calc(100vh - 230px);
    }
  }
  .centered-button {
    .btn-primary {
      width: 100%;
    }
  }
  &__color-head {
    text-align: center;
    p {
      margin: 20px auto 0 auto;
    }
    &.md {
      .modal-dialog {
        width: 90%;
        margin: 25% auto;
      }
    }
    .modal-header {
      position: relative;
      height: 60px;
      background-color: $primary;
      h5 {
        color: $white;
        text-align: center;
        width: 100%;
      }
      .close {
        font-size: 25px;
        position: absolute;
        top: 30px;
        right: 20px;
        color: $white;
      }
    }
    .position-relative {
      margin-top: 20px;
    }
    .footer-buttons {
      margin-top: 30px;
    }
  }
}

.loading-modal .modal-content {
  background-color: $gray-100;

  .loading-airplane {
    background-size: contain;
  }
}

.loading-airlines-footer {
  justify-content: center;
}

@media (min-width: 992px) {
  .modal {
    &.wait-search-result {
      .modal-dialog-centered {
        .modal-content {
          width: 420px;
        }
      }
    }
    &.time-expired {
      .modal-dialog-centered {
        .modal-content {
          width: 560px;
        }
      }
    }
    &__color-head {
      &.md {
        .modal-dialog {
          margin: 20% auto;
          width: 567px;
        }
        form {
          margin: auto;
          width: 80%;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .modal {
    &__custom {
      .modal-xl {
        max-width: 1140px;
      }
    }
  }
}

@media (min-width: 992px) {
  .modal {
    &__custom {
      .modal-lg,
      .modal-xl {
        max-width: 800px;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal {
    &__custom-checkout {
      .modal-body {
        text-align: center;
      }

      .modal-footer {
        border-top: none;
        justify-content: center;
        flex-direction: column;
        button {
          margin: 5px 0;
        }
      }
    }
  }
}
