.virtual-keyboard {
  width: 288px;
  height: 371px;
  margin: auto;
  background-image: url(#{$svg-path}/logos/smiles-orange.svg);
  background-size: 80px;
  background-position: right 20px ;
  background-repeat: no-repeat;
  h4 {
    color: $gray-700;
    @include bms-font-size(5.3vw, 17px);
    padding: 20px 0px;
  }
  input {
    width: 120px;
    margin: 0 0 20px 0;
    border: 1px solid $gray-800;
    padding: 10px 5px;
    border-radius: $border-radius;
    &::placeholder {
      color: $gray-400;
      @include bms-font-size(4vw, 12px);
    }
  }
  .layout {
    height: 160px;
    padding: 10px 20px;
    border: 2px solid $primary;
    border-radius: $border-radius;
    margin-bottom: 20px;
    span {
      display: block;
      width: 240px;
      margin: 0 auto;
      font-weight: bold;
    }
    .numbers {
      width: 240px;
      margin: 10px auto;
      > div {
        float: left;
        width: 45px;
        height: 45px;
        background-color: $gray-800;
        color: $white;
        margin-right: 3px;
        margin-bottom: 3px;
        border-radius: $border-radius;
        text-align: center;
        padding-top: 11px;
        cursor: pointer;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .btn {
      width: 130px;
      margin: 0;
    }
  }

  &.club-smiles {
    background-image: none;
    flex-direction: row;
    .buttons {
      margin-top: 20px;
      .btn {
        width: 100%;
      }
    }
    p {
      width: 100%;
      span {
        color: $default;
      }
      a {
        color: $club;
      }
    }
  }
}
