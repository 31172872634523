.cancel-taxes {
    width: 100%;

    table {
        width: 100%;

        tr {
            border-bottom: 1px solid $gray-300;

            &:last-child {
                border-bottom: none;
            }

            th {
                font-size: 14px;
                text-align: right;
                color: $gray-700;
                padding: 16px 0;
                
                &:first-child {
                    color: $default;
                    font-size: 17px;
                    font-family: $boldFontFamily;
                    text-align: left;
                    width: 70%;
                }
            }
    
            td {
                padding: 16px 0;
                font-size: 14px;
                text-align: right;
                color: $gray-700;
    
                &:first-child {
                    font-size: 14px;
                    font-family: $regularFontFamily;
                    text-align: left;
                    width: 70%;
                }
            }
        }
    }
}

.confirm-cancel {
    border-top: 2px solid $gray-300;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    
    .btn {
        padding: 15px 12px;
        border-radius: 5px;
        height: 50px;
    }

    &.easy-travel {
        border-top: 0;

        .btn {
            font-size: 14px;
            font-family: $regularFontFamily;

            &:first-child {
                float: left;
            }

            &:last-child {
                float: right;
            }
        }
    }
}


.anchor {
    position: relative;

    .tooltip-taxes{
        position: relative;
        left: inherit;
        top: -10px;
        width: 98%;
        margin: auto;
        box-sizing: border-box;
        background-color: $white;
        border: 2px solid $black;
        border-radius: 4px;
        font-family: Arial,Helvetica,sans-serif;
        padding: 25px;
        z-index: 1;
        
        p {
            text-align: center;

            &:first-child {
                font-weight: 900;
                color: $black;

                span {
                    color: $default;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: -28px;
            left: 50%;
            margin-left: -15px;
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent $white transparent;
            z-index: 1;
        }

        &::after {
            content: "";
            position: absolute;
            top: -30px;
            left: 50%;
            margin-left: -15px;
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent $black transparent;
        }
    }
}

@media (min-width: 768px) { 
    .anchor {
        
        .tooltip-taxes{
            position: absolute;
            left: 330px;
            top: -140px;
            width: 310px;

            &::before {
                content: "";
                position: absolute;
                top: 40%;
                left: -13px;
                margin-left: -15px;
                border-width: 15px;
                border-style: solid;
                border-color: transparent $white transparent transparent;
                z-index: 1;
            }

            &::after {
                content: "";
                position: absolute;
                top: 40%;
                left: -15px;
                margin-left: -15px;
                border-width: 15px;
                border-style: solid;
                border-color: transparent $black transparent transparent;
            }
        }
    }
}

