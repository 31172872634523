.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  a {
    text-decoration: none;
  }
  &__brand {
    background-color: $primary;
    color: $white;
    overflow: hidden;
    border-bottom: 1px solid $white;

    .container {
      width: 90% !important;
      margin: 0 auto;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 30%;
        height: 80px;

        a {
          display: block;
          width: 100%;
          height: 100%;
          background-image: url(#{$svg-path}/logos/smiles-white.svg);
          background-position: center center;
          background-size: 100px;
          background-repeat: no-repeat;
          overflow: hidden;
          text-indent: -1000px;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: left;

        .toggle-button {
          font-family: $boldFontFamily;
          z-index: 1;

          > div {
            display: flex;
            align-items: center;

            span {
              margin-right: 5px;
            }
          }

          .ic-menu-bars {
            font-size: 16px;
          }

          .ic-close-plain {
            font-size: 0.8px;
          }
        }

        .box-signin {
          .btn-link {
            float: left;
            color: $white;
          }

          .btn-outline-light {
            float: right;
            border: 2px solid $white;

            &:hover {
              --hover-color: $primary;
              background: var(--hover-color);
              color: $white;
            }
          }
        }

        .box-user-signout {
          flex-basis: 100%;

          &.d-lg-flex {
            display: none;
          }
        }

        /* Mobile menu styles*/
        .box-menu-mobile {
          position: fixed;
          width: max-content;
          border-radius: 0;
          border: 0;
          top: 0;
          left: 0;
          padding: 0;
          height: calc(100% - 70px);
          background: transparent;
          display: none;
          margin: -5px 0 0 0;
          z-index: 0;

          &.disable {
            display: none;
          }

          .opacity {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            background: $black;
            opacity: 0.4;
          }

          .content {
            position: relative;
            overflow-y: auto;
            height: 100%;
            background: $default;
            width: 100vw;
            z-index: 20;
            top: 85px;
            display: inline-block;
            font-size: 14px;

            ul {
              list-style: none;
              margin-left: 0;
              padding: 0;
              overflow: scroll;
              width: 100%;

              li {
                color: $default;
                line-height: 14px;
                margin-bottom: 0;
                font-weight: bold;
                font-size: 14px;
                font-family: $boldFontFamily;

                a {
                  color: $white;
                  font-weight: 100;
                  line-height: 14px;
                  text-decoration: none;
                  padding: 10px 20px;
                  margin-top: 15px;
                  display: block;
                  float: left;
                  width: 100%;

                  &.menu-dropdown {
                    background-image: url(#{$svg-path}/icons/white/ic-arrow-right-sm.svg);
                    background-repeat: no-repeat;
                    background-position: 240px 8px !important;
                  }
                  &.menu-dropdown-open {
                    background-image: url(#{$svg-path}/icons/white/ic-arrow-down.svg);
                    background-repeat: no-repeat;
                    background-position: 240px 8px !important;
                  }
                }

                label {
                  background: $default;
                  color: $white;
                  font-size: 16px;
                  margin: 0;
                  line-height: 20px;
                  padding: 10px 20px;
                  float: left;
                  display: block;
                  width: 100%;

                  span.close-menu-dropdown {
                    background-color: #c63;
                    height: 100%;
                    float: right;
                    color: $white;
                    padding: 10px 15px;
                    margin: -10px -20px;
                  }
                }

                &:nth-child(2) {
                  label {
                    background: $gray-400;
                    color: $gray-800;
                    background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg);
                    background-repeat: no-repeat;
                    background-position: 20px 12px;
                    padding-left: 40px;
                  }
                }

                ul.hide {
                  display: none;
                }
              }
            }

            ul.menu-smiles-dropdown {
              li {
                &:first-of-type {
                  a {
                    &::after {
                      content: "";
                      position: absolute;
                      top: 21px;
                      width: 20px;
                      height: 20px;
                      background: url(#{$svg-path}/icons/white/ic-search.svg);
                      margin-left: 25px;
                      background-position: center center;
                      background-repeat: no-repeat;
                      background-size: contain;
                      -webkit-transform: scaleX(-1);
                      transform: scaleX(-1);
                    }
                  }
                }
              }
            }

            /* --- Club Smiles Items styles ---*/
            .menu-smiles-dropdown {
              .club-smiles {
                a {
                  background-color: $club;
                }
              }
              li{
                .know-clubSmiles{
                  background-color: #fff;
                  color: $default;
                }
              }
            }
            //

            .collapse {
              &.show {
                li {
                  &:first-of-type {
                    a {
                      &::after {
                        content: none;
                      }
                    }
                  }

                  &:last-of-type a {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                  }

                  a {
                    background: lighten($default, 10%) !important;
                    border: 1px solid darken($default, 10%);
                    margin: 0px !important;
                    max-width: 56%;
                    margin-left: 20px !important;
                  }
                }

                /* --- Club Smiles dropdown links styles --- */
                .club-smiles {
                  a {
                    background: lighten($club, 10%) !important;
                    border: 1px solid darken($club, 10%);
                  }
                }
                //
              }
            }

            a.logout {
              color: $white;
              padding: 20px 0 20px 10px;
              display: block;
              cursor: pointer;
              text-align: left;
              border-top: 1px solid $white;
              float: left;
              clear: both;
              width: 100%;
            }

            .dropdown-menu-footer {
              float: left;
              width: 100%;
              padding: 20px;
              border-top: 1px solid $white;

              label {
                float: left;
                clear: both;
                color: $white;
              }

              a {
                float: left;
                clear: both;
                color: $white;
                text-decoration: none;
                padding: 5px 0 0 25px;

                &.ar {
                  background-image: url(#{$svg-path}/misc/ic-flag-ar.svg);
                  background-repeat: no-repeat;
                  background-position: 0 8px;
                }

                &.br {
                  background-image: url(#{$svg-path}/misc/ic-flag-br.svg);
                  background-repeat: no-repeat;
                  background-position: 0 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  &__nav {
    display: none;

    .container {
      .flag-menu {
        margin-top: 15px;

        span {
          &.ar {
            background-image: url(#{$svg-path}/misc/ic-flag-ar.svg);
            background-repeat: no-repeat;
            background-position: 0 8px;
            padding: 10px;
            margin-right: 5px;
          }

          &.br {
            background-image: url(#{$svg-path}/misc/ic-flag-br.svg);
            background-repeat: no-repeat;
            background-position: 0 8px;
            padding: 10px;
          }
        }
      }
      .club {
        color: $club !important;
      }
      .know-clubSmiles {
        color: $default !important;
      }
    }
  }
}

// Small devices
@media (max-width: 768px) {
  .header {
    &__brand {
      .container {
        .actions {
          .box-user-signout {
            top: 80px;
            position: absolute;
            min-width: 100vw;
            left: 50%;
            .box-logged {
              left: -50%;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .header {
    &__brand {
      .container {
        justify-content: space-between;
        .logo {
          width: 120px;
        }
        .box-signin {
          padding-right: 10px;
          width: 240px;
        }
      }
    }
    &__nav {
      .dropdown-menu {
        li {
          &:hover {
            .sub-menu {
              visibility: visible;
            }
          }
        }
      }
      .dropdown {
        .dropdown-toggle {
          &:after {
            float: right;
            background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: 10px;
            margin-top: 5px;
            width: 14px;
            height: 14px;
            border: none;
          }
        }
        &:hover {
          .dropdown-menu {
            display: block;
            @include bms-triangle-tooltip(up, $gray-500, 8, 7px, 1);
          }
          .dropdown-toggle {
            color: $primary !important;
            &:after {
              background-image: url(#{$svg-path}/icons/orange/ic-arrow-up.svg);
            }
          }
        }
        .dropdown-menu {
          @include bms-triangle-tooltip(up, $gray-500);
          ul {
            display: block;
            width: auto;
            white-space: nowrap;
          }
        }

        &.show {
          .dropdown-toggle {
            color: $primary !important;
            &:after {
              background-image: url(#{$svg-path}/icons/orange/ic-arrow-up.svg);
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .header {
    &__brand {
      .container {
        flex-wrap: nowrap;
        .box-search {
          width: 900px;
          padding-left: 60px;
          input {
            border: 1px solid $white;
            border-radius: $input-border-radius;
            background-color: $primary;
            color: $white;
            width: 500px;
            padding: 10px 20px;
            background-image: url(#{$image-path}/svg/icons/white/ic-search.svg);
            background-position: 96% center;
            background-size: 20px;
            background-repeat: no-repeat;
            &::placeholder {
              color: $white;
            }
          }
        }

        .box-signin {
          display: flex;
          align-items: center;
          //justify-content: flex-end;
          padding-right: 10px;
          width: 52%;
        }
        .box-user-signout {
          flex-basis: auto;
          display: flex;
          align-items: center;

          &.d-lg-flex {
            display: flex;
          }
        }
      }
    }
    &__nav {
      display: block;
      background-color: $white;
      .navbar {
        text-align: center;
        ul {
          margin: auto;
          font-family: $regularFontFamily;
          font-weight: 400;

          .nav-item {
            cursor: pointer;
            min-height: 50px;
            display: flex;
            align-items: center;
          }
          .nav-link {
            color: $gray-700;
            @include bms-font-size(3vw, 16px);
            @include bms-line-height(4vw, 18px);
            padding-right: 30px;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    &__brand {
      .container {
        .box-signin {
          width: 36%;
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .header {
    &__brand {
      .container {
        .box-signin {
          width: auto;
        }
      }
    }
  }
}
