.club-benefit {
  text-align: center;

  h5 {
    color: $club;

    &::before, &::after {
        content: "";
        width: 200px;
        display: inline-block;
        position: relative;
        border: 1px solid;
        border-style: dashed;
        top: -3px;
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }

    @media screen and (max-width: 768px) {
          &::before, &::after {
          content: "";
          width: 15%;
        }
    }
  }

  .benefit-container {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 120px;
  }
  .caption {
    display: block;
    color: $club;
    font-weight: bold;
    font-size: small;
  }

  #benefit-carousel {
    display: inline-block;

    .smls-purple-icons {
      font-size: 25px;
    }

    .carousel-control-prev {
      position: absolute;
      left: -10%;
      top: -12%;
    }

    .carousel-control-next {
      position: absolute;
      right: -10%;
      top: -12%;
    }
  }
}
