.new-card-principal{
  display: flex;
  align-items: center;
  border: 1px solid $gray-400;
  border-radius: 30px;
  margin: 10px;
  div{
      .card-image-settings{
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 29px 0px 0px 29px;
          height: 400px;
          width: 600px;
      }
  }

  .titleSize{
      &__h3:hover{
        cursor: pointer;
        opacity: .5;
      }
  }
  .card-text-container{
      width: 100%;
      padding: 15px 40px;
      .card-date{
          font-size: 1rem;
          font-family: $boldFontFamily;
          color: $gray-900;
          
      }
      .reading-timing{
        font-size: .88rem;
        color: $gray-900;
        .icon-timing{
          width: 15px;
          margin-bottom: 0px;
          margin-right: 5px;
        }
      }
      div{
          .btn-readNote{
              padding: 0;
              text-align: left;
              font-size: 12px;
              text-transform: uppercase;
              background:none;
              border:none;
              border-bottom:1px solid $primary;
              color: $primary !important;
              font-family: $boldFontFamily;

          }
          .btn-readNote:hover{
            opacity: .5;
          }
      }
      .sub-head {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
      }
  }
}

.new-card{
  margin: 10px;
  border: 1px solid $gray-400;
  border-radius: 30px;
  max-height: 640px;
  min-height: 558px;
  div{

      .card-image-settings{
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 29px 29px 0px 0px;
          height: 280px;
          width: 100%;
      }
  }

  .titleSize{
      
      &__h5{
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
          margin-top: 10px;
      }
      &__h5:hover{
        cursor: pointer;
        opacity: .5;
        font-size: 20px;
      }
  }
  .card-text-container{
      padding: 25px 30px 50px 30px;
      .card-date{
        font-size: .9rem;
        font-family: $boldFontFamily;
        color: $gray-900;
      }
      .reading-timing{
        font-size: .83rem;
        color: $gray-900;
        .icon-timing{
          width: 15px;
          margin-bottom: 5px;
          margin-right: 5px;
        }
      }
      div{
          .btn-readNote{
              padding: 0;
              text-align: left;
              font-size: 12px;
              background:none;
              border:none;
              text-transform: uppercase;
              border-bottom:1px solid $primary;
              color: $primary !important;
              font-family: $boldFontFamily;
          }
          .btn-readNote:hover{
            opacity: .5;
          }
      }
      .sub-head {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
      }

  }
}

.slick-slider {
  position: relative;

  .slick-dots {
    display: flex !important;
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    justify-content: center;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0 0.20rem;
    }

    button {
      display: block;
      width: 12px;
      height: 12px;
      padding: 0;
      border: none;
      border-radius: 100%;
      background-color: #C1C1C1;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #FF5A00;
    }
  }
}

/* Botones del Slider dentro de News */
.smiles-slider-notas{
  .slick-slider {
    .slick-arrow {
      position: absolute;
      color: transparent;
      background-color: transparent;
      background-image: url("../../images/svg/icons/orange/ic-arrow-left-sm.svg");
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
      z-index: 1;

      &.slick-prev {
        left: 41%;
        bottom: -7px;
      }

      &.slick-next {
        right: 42%;
        transform: rotate(180deg);
        bottom: -7px;
      }
    }

    .slick-dots {
      position: relative;
      margin-top: 20px;


      li {
        z-index: 2;
      }

    }
  }

}

.notes-title{
  color: $gray-700;
  .bracket-news-filters-title{
    transform: rotate(270deg);
    position: relative;
    top: 22px;
    margin-left: 19px;
    margin-right: 6px;
    font-size: 36px;
    color: $gray-700;
  }
  h1{
    color: $primary
  }

}

.bracket-news-filters{
  transform: rotate(270deg);
  position: relative;
  top: 15px;
  margin-left: 5px;
  margin-right: 6px;
  font-size: 13px;
  color: $white;
  &__filterBottom{
    color: $gray-700 !important;
  }
  &__filterBottomClub{
    color: $club !important;
  }
}

.news-filters{
  border-radius: 10px;
  border: none;
  padding:1px 20px;
  color: white;
  height: 40px !important;
  font-family: $boldFontFamily;
  &__bottom{
    padding:1px 13px !important;
    min-width: 159px;
  }
  &__gray {
    background-color: $gray-600;
  }
  &__gray:hover {
    opacity: 0.5;
  }
  &__orange {
    background-color: $primary;
  }
  &__orange:hover {
    opacity: 0.5;
  }
  &__club {
    background-color: $club;
  }
  &__club:hover {
    opacity: 0.5;
  }
  &__outline_gray {
    font-size: 14px;
    background-color: $white;
    border: 1px solid $gray-700;
    color: $gray-700;
  }
  &__outline_gray:hover {
    opacity:.5;
  }
  &__outline_club {
    font-size: 14px;
    background-color: $white;
    border: 1px solid $club;
    color: $club;
  }
  &__outline_club:hover {
    opacity: .5;
  }
}

  

  @media only screen and (max-width: 575px){
    .news-filters{
      padding:1px 25px;
      &__bottom{
        padding:1px 0px;
        min-width: 47%;
        font-size: 13px;
      }
    }
    .notas-margin{
      margin-top: 30px;
    }
    .new-card-principal{
      display: block;
      div{
          .card-image-settings{
              border-radius: 29px 29px 0px 0px;
              height: 280px;
              width: 100%;
          }
      }
      .card-text-container{
        width: 100%;
        padding: 20px 30px 50px 30px;
      }
    }
    .new-card{
      margin-bottom: 30px;
    }

    .smiles-slider-notas{
      .slick-slider {
        .slick-arrow {
          &.slick-prev {
            left: 32%;
          }
  
          &.slick-next {
            right: 36%;
          }
        }
      }
    }
  }