.flight-details {
  .flight {
    width: 100%;
    max-width: 100%;
    margin: 0px auto 0 auto;

    text-align: left;
    font-family: "NunitoLight", Arial, sans-serif;
    clear: both;

    .theme-inverse {
      padding: 15px;
      background-color: #ececec;
      text-transform: uppercase;
      color: #000;
      display: none;
    }

    .flight-segment,
    .flight-segment__legs {
      font-size: calc(9.9px + 0.2vw);
    }

    .flight-segment {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      padding: 15px 1.7vw;
      margin-bottom: 15px;

      &__details-toggler {
        background-color: #7c7c7c;
        width: 23px;
        height: 30px;
        border-radius: 4px;
        display: block;

        &::after {
          content: "";
          width: 0;
          height: 0;
          top: 16px;
          left: 4px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid #fff;
          position: relative;
        }
      }

      &__date {
        display: inherit;
        flex-direction: column;
        text-align: center;
        margin: 0 0 0 10px;
      }

      &__company {
        display: block;
        width: 75px;
        height: 30px;

        .logo-airlines {
          width: 75px;
          height: 25px;
          padding: 0 10px;
          margin: 0 0 0 10px;
          background-size: contain !important;
        }
      }

      &__stretch {
        display: inherit;
        margin: 0;
        width: 46%;

        .airport-departure {
          width: 85px;
          text-align: right;
          flex-direction: column;

          span {
            font-size: calc(9.9px + 0.2vw);
          }
        }

        .airport-destiny {
          width: 85px;
          text-align: left;
          flex-direction: column;

          span {
            font-size: calc(9.9px + 0.2vw);
          }
        }

        b {
          font-size: calc(10px + 1vw);
          margin-bottom: 7px;
          width: 100%;
          display: block;
          color: black;
        }

        .duration {
          display: inherit;
          flex-direction: column;
          padding: 0 0.9vw;
          text-align: center;
          margin: 10px 0 0 0;

          .seta {
            width: 30px;
            margin: 0 5px;

            &::after {
              content: "";
              width: 100%;
              height: 14px;
              display: block;
              background-image: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
              background-position: center right;
              background-repeat: no-repeat;
              margin-bottom: 1px;
            }
          }
        }
      }

      &__details {
        margin: 0;
        width: 100%;
        padding: 10px 30px;

        div {
          width: 50%;
          float: left;

          &:last-child {
            text-align: right;
          }
        }
      }

      &__legs {
        width: 100%;
        margin: 30px 0 0 0;
        flex-direction: column;
        padding: 0 1.7vw;

        .leg {
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: center;
          display: flex;

          .smls-gray-icons {
            font-size: 15px;
          }

          .flying-company {
            text-align: center;
            width: 54px;

            .logo-airlines {
              width: 54px;
              height: 25px;
              background-size: contain !important;
              background-repeat: no-repeat;
              margin: auto;
            }

            p {
              height: 40px;
              line-height: 18px;
              margin: 10px 0;
              font-size: calc(9.9px + 0.2vw);
              span {
                &:last-child {
                    white-space: nowrap;
                }
              }
            }
          }

          .airport-departure {
            text-align: right;
            width: 88px;
            line-height: 20px;

            b {
              font-size: calc(10px + 1vw);
              margin-bottom: 7px;
              display: block;
              line-height: 20px;
              color: black;
            }
          }

          .airport-destiny {
            text-align: left;
            width: 88px;
            line-height: 20px;

            b {
              font-size: calc(10px + 1vw);
              margin-bottom: 7px;
              display: block;
              line-height: 20px;
              color: black;
            }
          }

          .passengers {
            margin: 10px 0;

            span {
              float: left;
              clear: left;
              text-transform: capitalize;
            }
          }

          .arrow {
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 15px solid #ececec;
          }
        }

        .connection {
          margin: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 14px;

          &:after {
            content: "";
            background-color: $gray-300;
            width: 100%;
            height: 1px;
            margin-left: 15px;
          }
        }
      }
    }
  }

  .visible-phone {
    display: inline;
  }

  .visible-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .flight-details {
    .visible-phone {
      display: none;
    }

    .visible-desktop {
      display: inline-block;
    }

    .flight {
      border: 2px solid #ececec;
      border-radius: 0px;
      .flight-segment {
        justify-content: space-between;

        &__date {
          margin: inherit;
        }

        &__company {
          width: auto;
          height: auto;

          .logo-airlines {
            width: 75px;
            height: 50px;
            padding: 0;
            margin: 0;
          }
        }

        &__stretch {
          width: auto;
          margin: inherit;

          .airport-departure,
          .airport-destiny {
            span {
              font-size: 14px;
            }
          }

          .duration {
            .seta {
              margin: 10px 0;
              width: auto;
            }
          }
        }

        &__details {
          margin: 0 10px;
          width: auto;
          padding: 0;

          div {
            width: auto;
            float: none;
          }
        }

        &__legs {
          margin: 30px 0;

          .leg {
            .flying-company {
              text-align: center;
              width: auto;

              .logo-airlines {
                width: 75px;
                height: 50px;
                margin: auto;
              }

              p {
                font-size: 12px;
              }
            }

            .airport-departure {
              width: 85px;
            }

            .airport-destiny {
              width: 85px;
            }
          }
        }
      }
    }
  }
}
