.two-cards-error-modal {
    max-width: 370px;
    margin: 0 auto;
    padding: 66px 0 50px;

    .error-card {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 22px;

        &__img {
            position: relative;
            @include square(73px);

            padding: 0;
            margin: 0 13px;

            &.error {
                $icon: "#{$svg-path}/icons/orange/ic-card-error.svg";
                @include icon-pos(before, $icon);
            }

            &.alert {
                $icon: "#{$svg-path}/icons/orange/ic-card-error--alert.svg";
                @include icon-pos(before, $icon);
            }

        }

    }

    .message {
        font-family: $regularFontFamily;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .card-error-img {
        display: inline-block;
        @include size(172px, 74px);
        position: relative;
    }

    .card-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .selected-card {
        @include square(68px);
        border: 2px solid #ffa97d;
        padding: 2px;
        display: inline-block;

        margin: 6px 8px;

        img {
            object-fit: contain;
            @include square(100%);
        }
    }

    .actions {
        display: flex;
        justify-content: center;

        button {
            margin: 0 7px;
            min-width: 126px;
        }
    }

}