.payment-seats {
    width: 896px;
    max-width: 99%;
    margin: 20px auto 0 auto;
    
    h4 {
        font-size: 20px;
        color: $gray-600;
        width: 100%;
        text-align: left;
    } 

    table {
        margin-top: 0px;
        width: 100%;

        tr:first-child {
            border-bottom: 1px solid $gray-400;
        }

        th, td {
            text-align: left;
            color: $gray-600;
            padding: 16px 0;
        }

        th:first-child {
            width: 70%;
            font-size: 20px;
            font-family: $boldFontFamily;
        }

        td:first-child {
            font-size: 14px;
            font-family: $boldFontFamily;
        }

        th {
            font-size: 12px;
            font-family: $regularFontFamily;
        }

        td {
            font-size: 14px;
        }
        
        th:last-child, td:last-child {
            text-align: right;
        }
    }

    @media (min-width: 768px) { 
        margin: 50px auto 0 auto;

        table {
            margin-top: 50px;
        }
    }
}