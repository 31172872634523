.ticket-issue {
  flex-direction: column-reverse;
  margin: 0 10px;
  h2 {
    text-align: left;
    margin-right: 10px;
    @include bms-font-size(6vw, 24px);
  }
  h5 {
    @include bms-font-size(5vw, 22px);
  }
  &.row {
    > .col-lg-8 {
      width: 100%;
      padding: 8pt;
    }
  }

  .jarvis-box-main{
    padding: 32px 35px;
    border: 1px solid $gray-400;
  }

  .jarvis-PassengerForm-container{
    margin-bottom: 2px;
    .collapse{
      border: 1px solid $primary;
    }
    .jarvis-box-header {
      background-color: $default;
      color: $white;
      padding: 10px 25px;
      margin-top: 0px;
      margin-bottom: 0px;
      h5 {
        color: $white;
        margin-bottom: 0px;
      }
  
      .arrow {
        text-align: right;
  
        .arrow-up {
          font-size: 22px;
          &::before {
            content: "▲";
          }
        }
  
        .arrow-down {
          font-size: 22px;
          &::before {
            content: "▼";
          }
        }
      }
    }
  }
  .jarvis-box-passenger-form {
    padding: 20px;
    div{
      div{
        div{
          label{
            font-family: $regularFontFamily;
            font-weight: 300;
            font-size: 13px;
            white-space: nowrap;
          }
          .nationality-select{
            .react-select__control{
              .react-select__value-container{
                .react-select__input-container{
                  margin: 0px;
                  padding: 0px;
                }
              }
            }
          }
        }
      }
    }
  }


  .box-main {
    padding: 8pt;
    text-align: left;
    border: 1px solid $gray-300;
    margin-bottom: 30px;

  

    .subtitle {
      font-style: italic;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }


  .box-header {
    background-color: $default;
    color: $white;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    h5 {
      color: $white;
      margin-bottom: 0px;
    }

    .arrow {
      text-align: right;

      .arrow-up {
        font-size: 22px;
        &::before {
          content: "▲";
        }
      }

      .arrow-down {
        font-size: 22px;
        &::before {
          content: "▼";
        }
      }
    }
  }
  .box-passenger-form {
    padding: 40px;
  }

  .box-favorite-passenger {
    &.single-passenger {
      padding: 20px 40px 30px 40px;
      border: 1px solid $gray-300;
    }
    text-align: left;
    margin-bottom: 30px;
    label {
      color: $default;
      font-size: 14px;
      font-family: $boldFontFamily;
    }
    ul {
      margin: 10px 0 0 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      white-space: nowrap;
      li {
        font-family: $boldFontFamily;
        margin: 5px;
        display: block;
        border: 1px solid $default;
        border-radius: 4px;
        color: $default;
        font-size: 14px;
        padding: 3px 20px;
        cursor: pointer;

        &.selected-favorite-passenger {
          background-color: $default;
          color: $white;
        }
      }
    }
  }
  @media only screen and (max-width: 425px) {
    .box-passenger-form {
      padding: 40px 5px;//Primer cambio
    }
    .box-favorite-passenger {
      ul {
        li { 
          padding: 3px 6px;//Segundo Cambio
        }
      }
    }
  }
  .breadcrumb {
    margin: 0 auto 30px auto;
  }
  .clean-fields {
    text-align: right;
    button {
      appearance: none;
      border: none;
      position: relative;
      display: block;
      font-family: $boldFontFamily;
      margin: 0 0 0 auto;
      outline: none;
      &:first-child {
        @include bms-font-size(4vw, 16px);
      }
      &:last-child {
        @include bms-font-size(3.5vw, 14px);
        color: $primary;
      }
    }
  }
  .frm-default {
    .form-check-label {
      input {
        ~ span {
          margin-right: 30px;
        }
      }
    }
    .genderInputRadio {
      input {
        display: initial;
        ~ span, ~ div {
          &:before {
            height: 18px;
          }
        }
      }
      &.focus {
        border: thin ridge;
      }
    }
  }
  .especial-services {
    margin-top: 20px;
    h5 {
      cursor: pointer;
    }
    .list-items {
      p {
        @include bms-font-size(3vw, 16px);
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 15px;
          &.featured {
            color: $primary;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .form-footer {
    margin: 20px auto;
    text-align: right;
  }
  .ticket-content {
    margin: 0 0 0 20px;
    text-align: left;
    > div {
      padding: 10px 30px 10px 0;
    }
    h4 {
      @include bms-font-size(5vw, 20px);
      margin-bottom: 10px;
    }
    p {
      @include bms-font-size(3.5vw, 14px);
    }
    .smls-orange-icons {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  &__club-box {
    width: 100%;
    height: auto;
    &__logo {
      width: 100%;
      height: 90px;
      label {
        font-family: $boldFontFamily;
        color: $club;
        font-size: 50px;
        letter-spacing: -2px;
        width: 100%;
        line-height: 50px;
        margin: 10px 0 0 0;
      }
      span {
        background-image: url(../images/svg/logos/smiles-purple.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 22px;
        position: relative;
        display: inline-block;
        margin-bottom: 20px;

        &::after {
          content: "";
          position: absolute;
          height: 9px;
          border-bottom: 3px solid #639;
          top: -3px;
          width: 30px;
          right: 100%;
          margin-right: 3px;
        }

        &::before {
          content: "";
          position: absolute;
          height: 9px;
          border-bottom: 3px solid #639;
          top: -3px;
          width: 30px;
          left: 100%;
          margin-left: 3px;
        }
      }
    }
    &__header {
      padding: 0;
      text-align: center;
      font-family: $boldFontFamily;
      label {
        color: $club;
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
    &__benefits {
      ul {
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        list-style: none;
      }
      li {
        margin: 10px auto;
        padding: 0 20px;
        color: $club;
        text-align: center;
        font-size: 14px;
        font-family: $boldFontFamily;
        padding-top: 50px;
        background-repeat: no-repeat;
        background-position: center 0;

        &.validate {
          background-image: url(../images/svg/icons/purple/ic-databases-x10.svg);
          background-size: 60px;
        }
        &.money {
          background-image: url(../images/svg/icons/purple/ic-ticket-money.svg);
          background-size: 40px;
        }
        &.accesso {
          background-image: url(../images/svg/icons/purple/ic-bell.svg);
          background-size: 42px;
        }
        &.bonus {
          background-image: url(../images/svg/icons/purple/ic-card-currency.svg);
          background-size: 50px;
        }
        &.reservas {
          background-image: url(../images/svg/icons/purple/ic-airplane-card.svg);
          background-size: 50px;
        }
      }
    }
    &__bonus {
      width: 100%;
      height: auto;
      border: 1px solid $default;
      padding: 10px 10px;
      display: inline-block;
      margin: auto;
      p {
        font-size: 14px;
        line-height: 24px;
        b {
          color: $club;
        }
      }
    }
  }
  &__club-term {
    width: 100%;
    div {
      width: 100%;
      position: relative;
      margin: 20px auto;
      clear: both;
      float: left;
      label {
        float: left;
        padding-left: 20px;
        span {
          font-size: 16px;
          a {
            text-decoration: none;

            b {
              color: $club;
            }
          }
        }
      }
    }
    button {
      float: none;
      margin: 10px auto;
      border: none;
      border-radius: 3px;
      height: 40px;
      color: $white;
      font-size: 14px;
      &.no-accept {
        background-color: $gray-800;
        width: 216px;
      }
      &.accept {
        background-color: $club;
        width: 100%;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ticket-issue {
    flex-direction: row;
    .box-main {
      padding: 8pt;
    }
    .box-favorite-passenger {
      text-align: left;
      margin-bottom: 30px;
      label {
        color: $default;
      }
      ul {
        margin: 10px 0 0 0;
        padding: 0;
        display: block;
        padding-bottom: 10pt;
        li {
          margin: 0 15px 0 0;
          display: inline-block;
          border: 1px solid $default;
          border-radius: 4px;
          color: $default;
          font-size: 14px;
          padding: 3px 20px;
          cursor: pointer;
        }
      }
    }
    &.row {
      > .col-lg-8 {
        padding: 0;
      }
    }
    .helper-field {
      position: relative;
      .helper-content {
        width: 266px;
        height: auto;
        position: absolute;
        right: -280px;
        top: 0;
        padding: 10px 10px 1px;
        p {
          line-height: 16px;
        }
        &::after {
          left: -8px;
          top: 20px;
          border-color: transparent transparent transparent $primary;
          border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height
            ($tooltip-arrow-width / 2) 0;
          border-right-color: $primary;
        }
        &::before {
          left: -5px;
          top: 20px;
          border-color: transparent transparent transparent $white;
          border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height
            ($tooltip-arrow-width / 2) 0;
          border-right-color: $white;
        }
      }
    }

    .breadcrumb {
      width: 364px;
      margin: auto auto 38px auto;
    }

    &__club-box {
      height: 520px !important;
      border: 1px solid $club;
      &__logo {
      }
      &__header {
        padding: 0 130px;
      }
      &__benefits {
        ul {
          padding: 10px;
          display: flex;
          align-items: center;
          flex-direction: row;
          list-style: none;
          li {
            margin: 0 10px;
          }
        }
      }
      &__bonus {
        width: 712px;
        height: 132px;
        padding: 15px 55px;
        p {
          font-size: 16px;
        }
      }
    }
    &__club-term {
      div {
        margin: 20px;
      }
      button {
        float: left;
        margin-right: 20px;
        border: none;
        border-radius: 3px;
        height: 40px;
        color: $white;
        font-size: 14px;
        &.no-accept {
          background-color: $gray-800;
          width: 216px;
        }
        &.accept {
          background-color: $club;
          width: 380px;

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .ticket-issue {
    .jarvis-box-main{
      padding: 0px;
      border: none;
    }
    .fix-mobile {
      .animate {
        &.form-group {
          label {
            font-size: 9px;
          }
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .genderInputRadio {
    input {
      display: initial;
      margin-top: 2px;
      width:5px;
      ~ span, ~ div {
        &:before {
          height: 39px;
          margin-top:10;
        }
      }
    }
    &.focus {
      border: thin ridge;
    }
  }
}


@media (max-width: 440px) {
  .responsive-label {
    label {
      font-size: 6.5px!important;
    }
  }
}
