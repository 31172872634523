.checkout {
  .flex-payment {
    align-items: center;
  }

  .buttons.centered {
    width: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    flex-direction: column;

    .confirm-recaptcha-container {
      .confirm-payment {
        .recaptcha-payment {
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
        }
      }
    }

    .btn {
      margin-top: 46%;
      margin-bottom: 18px;

      &.btn-payment {
        margin-top: 0;
      }
    }

    .haciendo-clicl-en-con {
      .highlight {
        color: $primary;
      }
      .highlight.club {
        color: $primary;
      }
      a.club {
        color: $club;
      }
    }
  }
}

.hidden-mobile {
  display: block;
}

.total-hidden-desktop {
  display: none;
}

.modal-content div.text-centered {
  text-align: center;
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .checkout {
    .buttons.centered {
      .confirm-recaptcha-container {
        .confirm-payment {
          .recaptcha-payment {
            display: block;
          }
        }
      }
      .btn {
        margin-top: 0;
      }
    }

    .no-botton-border {
      border-bottom: none;
    }

    .tr-height-75 {
      height: 75px;
    }
  }

  .text-left {
    text-align: left;
  }

  .hidden-mobile {
    display: none;
  }

  .total-hidden-desktop {
    display: block;
  }

  .modal.vouchers .modal-content .vouchers-body .voucher-item {
    padding: 20px 0;

    .wrapper-1 {
      padding-right: 0;
    }
  }
}
