.travel-box-info {
  display: flex;
  @include bms-font-size (2vw, 14px);
  @include bms-line-height (2vw, 16px);
  .origin {
    width: 100%;
    text-align: right;
  }
  .arrival {
    width: 100%;
    text-align: left;
  }
  .info {
    display: flex;
    align-items: center;
    width: 130px;
    margin: 0 15px;
    @include bms-font-size (1vw, 9px);
    @include bms-line-height (1vw, 9px);
    > div {
      width: 100%;
      span {
        display: block;
        width: 100%;
        height: 12px;
        padding: 0;
        text-align: center;
        font-weight: 900;
        //border: 1px solid red;
        &.stops {
          height: 24px;
          &:after {
            content: "";
            width: 100%;
            height: 14px;
            display: block;
            background-image: url(#{$svg-path}/icons/gray/ic-arrow-flight-details.svg);
            background-position: center right;
            background-repeat: no-repeat;
            margin-bottom: 1px;
          }

        }
      }
    }
  }

  .airport {
    font-family: $boldFontFamily;
  }
  .date-info {
    @include bms-font-size (1vw, 12px);
  }


}