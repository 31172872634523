.order-summary-mobile {
    @media only screen and (max-width: 950px) { 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .silder__container {
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        .slider-range {
            .slider-range--disabled-color[type='range']::-webkit-slider-runnable-track {
                background-color: $gray-500;
            }
        }
        .slider-tooltip {
            display: block;
            border: solid 2px $primary;
            padding: 5px;
            border-radius: 5px;
            background-color: $white;
            position: absolute;
            width: 290px;
            right: -310px;
            z-index: 3;
            bottom: -10px;
            font-size: 15px;
            font-weight: bold;
            color: $gray-700;
            @media only screen and (max-width: 950px) {
                display: none;
            }
            .slider-tooltip-arrow {
                border-left: solid 2px $primary;
                border-right: solid 0px $primary;
                border-top: solid 2px $primary;
                border-bottom: solid 0px $primary;
                background-color: $white;
                transform: rotate(-45deg);
                width: 15px;
                height: 15px;
                position: absolute;
                margin-top: 30px;
                left: -9px;
            }
        }
    }
    .slider-tooltip-mobile {
        display: none;
        border: solid 2px $primary;
        padding: 5px;
        border-radius: 5px;
        background-color: $white;
        width: 290px;
        z-index: 3;
        font-size: 15px;
        font-weight: bold;
        color: $gray-700;
        position: relative;
        @media only screen and (max-width: 950px) {
            display: block;
        }
        .slider-tooltip-arrow-mobile {
            border-left: solid 2px $primary;
            border-right: solid 0px $primary;
            border-top: solid 2px $primary;
            border-bottom: solid 0px $primary;
            background-color: $white;
            width: 15px;
            height: 15px;
            position: absolute;
            transform: rotate(45deg);
            margin-top: -14px;
            left: 135px;
        }
    }
} 


