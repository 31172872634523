@import "./bugs/bugs.scss";
@import "./milesPurchase/milesPurchase.scss";
@import "./error/milesError.scss";
@import "./milesTransfer/milesTransfer.scss";
@import "./milesGift/milesGift.scss";
@import "./milesExtension/milesExtension.scss";
@import "./emission/emissionInProgress.scss";
@import "./emission/easyTravelInProgress.scss";
@import "./emission/emissionGolSuccess.scss";
@import "./emission/easyTravelSuccess.scss";
@import "./emission/resumeMemberData.scss";
@import "./emission/emissionCongenereSuccess.scss";
@import "./milesTicketCard/milesTicketCard.scss";
@import "./registerMultiple/registerMultilpe.scss";
@import "./emission/easyTravelError.scss";
@import "./checkoutMultiple/checkoutMultiple.scss";
@import "./checkoutMultiple/inProgressMultiple.scss";
@import "./checkoutMultiple/successMultiple.scss";
@import "./InProgressTicketAdhesion/InProgressTicketAdhesion.scss";
@import "./myData/myData.scss";
@import "./milesReactivation/milesReactivation.scss";
@import "./tooltips/tooltips.scss";
@import "./myFlights/buttonPaymentDetails.scss";
@import "./orderSummary/orderSummary.scss";
@import "./checkoutSimple/InProgress.scss";
@import "./checkoutSimple/canceled.scss";
@import "./checkoutSimple/OrderSuccess.scss";
@import "./home/home-entities.scss";
@import "./luggage/luggageInProgress.scss";
@import "./luggage/luggageSuccess.scss";
@import "./NewArticles/NewsCard.scss";
@import "./Article/Article.scss";
@import "./jarvisStyles/tooltipsJarvis.scss";
@import "./jarvisStyles/Pricing.scss";
@import "./jarvisStyles/Service.scss";
@import "./jarvisStyles/Passengers.scss";
@import "./timepicker/timePickerInput.scss";
@import "./jarvisStyles/Checkout.scss";
@import "./jarvisStyles/Success.scss";
@import "./jarvisStyles/BookingResume.scss";
@import "./jarvisStyles-V2/ServicesDescription.scss";
@import "./jarvisStyles-V2/ServicesDescriptionItem.scss";
@import "./jarvisStyles-V2/AirportSelect.scss";
@import "./jarvisStyles-V2/Services.scss";
@import "./jarvisStyles-V2/ServicePrice.scss";
@import "./jarvisStyles-V2/ServiceTooltip.scss";
@import "./jarvisStyles-V2/ServiceDetails.scss";
@import "./jarvisStyles-V2/Counter-jarvis.scss";
@import "./jarvisStyles-V2/PricingDetails.scss";
@import "./jarvisStyles-V2/WarningMessage.scss";
@import "./jarvisStyles-V2/ResumeDetails.scss";
@import "./jarvisStyles-V2/ResumeDetailsTable.scss";
@import "./jarvisStyles-V2/ResumeDetailsCards.scss";
@import "./jarvisStyles-V2/JarvisFlightV2.scss";
@import "./jarvisStyles-V2/ModifyExperiencie.scss";
@import "./SuccessReactive/successReactive.scss";
@import "./NewCommonClubPayment/NewCommonClubPayment_reactive.scss";
@import "./modals/smlsLoadingNewModal.scss";
@import "./modals/smlsPurpleLoadingModal.scss";
@import "./CookiesModal/CookiesModal.scss";
@import "./CookiesModal/CookiesConfigModal.scss";
@import "./checkoutSimple/CheckoutDniInput.scss";
@import "./MpCardform/MpCardform.scss";