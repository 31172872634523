.flight-card {
  width: 100% !important;

  // override
  table thead {
    background-color: transparent;
  }

  & > tbody > tr > td {
    padding: 20px;
  }

  .airport-code {
    @media (min-width: $site-width-mobile) {
      text-transform: uppercase;
      font-family: $boldFontFamily;
      font-size: 30px;
    }
  }

  .direct {
    text-transform: uppercase;
    font-family: $boldFontFamily;
    font-size: 13px;
  }

  .date {
    padding: 5px;
    font-size: 10px;

    @media (min-width: $site-width-mobile) {
      font-size: 1rem;
    }
  }

  .logo {
    .logo-airlines {
      width: 60px;
      height: 60px;

      @media (min-width: $site-width-mobile) {
        width: 80px;
        height: 80px;
      }
    }

  }

  .flight-indicator {
    .logo-airlines {
      width: 60px;
      height: 60px;

      @media (min-width: $site-width-mobile) {
        width: 80px;
        height: 55px;
      }
    }

  }

  .segment {
    .arrow > div::before {
      transform: scale(0.35, 0.6);
      transform-origin: left;
      margin-left: 3px;
      margin-bottom: 17px;
    }
  }

  // override
  .logo.my-flights__logo-wrapper {
    border: none;
  }

  strong {
    font-family: $boldFontFamily;
  }

  .baggage-table {
    th {
      color: $primary;
      font-family: $boldFontFamily;
    }
  }
}
