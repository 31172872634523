.purchase-flight {
    display: flex;
    .steps{
        padding-top: 2vw;
        .breadcrumb{
            margin-top: 0;
        }
    }
    .search-flights-caption {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 20px 10px;
        &:before {
            content: '';
            background-image: url(#{$svg-path}/icons/gray/ic-print.svg);
            height: 25px;
            width: 25px;
            background-size: 100%;
            margin-right: 5px;
            position: relative;
        }
    }
    .options-flight {
        padding: 2vw 3vw;
        .head-tabs {
            h2{
                @include bms-font-size(24px, 30px);
                font-weight: 400 !important;
                margin-bottom: 0;
                text-align: left;
            }
        }
        .box-code{
            width: 94%;
            height: auto;
            margin: 0 auto;
            border: 1px dotted $primary;
            padding: 20px;
            margin-bottom: 40px;
            h3{
                font-weight: 500;
                margin-bottom: 0;
            }
            .locator-code{
                width: calc(100% - 50px);
                height: auto;
                margin: 0 auto;
                border: 1px dotted $gray-300;
                padding: 15px;
                h6{
                    color: $gray-600;
                    font-weight: 600;
                }
                h5{
                    color: $black;
                    font-weight: 700;
                }
                & > *{
                    margin-bottom: 0px;
                }
            }
        }
        .reminder-baggage{
            h6{
                @include bms-font-size(18px,23px);
            }
            p{
                width: 260px;
                margin: 0 auto 20px;
                line-height: 17px;
            }
        }
        .flight-allinfo{
            display: flex;
            flex-direction: column;
            .flight-summary {
                padding: 20px 10px;
                background: $gray-200;
                height: auto;
                border-radius: $border-radius;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-start;
                .flight-info{
                    flex-direction: column;
                    width: 90px;
                    .flight-info-row{
                        display: flex;
                        align-self: flex-start;
                        .ic-flight{
                                content: '';
                                background-repeat: no-repeat;
                                background-size: 100%;
                                height: 22px;
                                width: 22px;
                                display: flex;
                                background-image: url(#{$svg-path}/icons/orange/ic-airplane.svg);
                        }
                        .flight-way {
                            font-size: 18px;
                            font-weight: 800;
                            color: $primary;
                            position: relative;
                            margin-left: 4px;
                            text-align: left;
                        }
                    }
                    .flight-date{
                        width: auto;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        & > p{
                            margin-bottom: 0;
                            color: $gray-600;
                            font-family: ArialMT, sans-serif;;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                            width: 80px;
                            text-align: left;
                        }
                        span{
                            display: none;
                        }
                    }
                }
                .flight-cities{
                    display: flex;
                    align-self: center;
                    .flight-city{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        &:last-child{
                            align-items: flex-start;
                            .city {
                                text-align: left;
                            }
                        }
                        .airport-code{
                            font-family: Arial, sans-serif;;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 16px;
                        }
                        .city{
                            font-weight: 300;
                            font-size: 13px;
                            min-width: 80px;
                            text-align: right;
                        }
                    }

                    .direction{
                        display: flex;
                        height: 25px !important;
                        align-items: center;
                        align-self: center;
                        width: 52px;
                        margin: 0 12.5px;
                        .ic-arrow{
                            position: relative;
                            width: 52px;
                            height: 12.25px;
                            &:before {
                                content: '';
                                background-repeat: no-repeat;
                                background-size: 300%;
                                background-position: center right;
                                width: 52px;
                                height: 12.25px;
                                display: block;
                                position: absolute;
                                background-image: url(#{$svg-path}/icons/gray/ic-arrow-flight-details.svg);
                            }
                        }
                    }

                }
                & + .flight-summary{
                    margin-top: 2px;
                }
             }
            .box-baggage{
                width: 94%;
                height: auto;
                margin: 0 auto;
                border: 1px solid $gray-300;
                padding: 20px;
                margin-top: 20px;
                .ic-baggage {
                    margin: 0px auto 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-luggage-silver.svg);
                        width: 70px;
                        height: 60px;
                        background-size: 100%;
                        position: relative;
                    }
                }
            }

        }
        .passenger-info{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 17px;
                .passenger-title{
                    text-align: left;
                    font-weight: 800;
                    font-family: $boldFontFamily;
                    margin: 20px 0px;
                }
                .infos {
                margin: 0px 0px 10px;
                .ic-name{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-passenger.svg);
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        margin-right: 5px;
                        margin-top: 0px;
                    }

                }
                .ic-address{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-order-address.svg);
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        margin-right: 5px;
                        margin-top: 0px;
                    }
                }
                .ic-phone{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-phone.svg);
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        margin-right: 5px;
                        margin-top: 0px;
                    }
                }
                .ic-mail{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-email-resend.svg);
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        margin-right: 5px;
                        margin-top: 0px;
                    }
                }
                .ic-miles{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;
                        &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-order-paymentform.svg);
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: 100%;
                        margin-right: 5px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }
                }
                .payment-date{
                    display: flex;
                align-items: center;
                        margin-bottom: 10px;

                }
            }
        }
    }
    &.buttons {
      display: block;
      padding: 9pt;
      .btn {
        width: 100%;
      }
    }

  &.shopping-banner {
    max-width: 100%;
    margin: 0 auto 20px auto;
  }
  }
  @media (min-width: 992px){
    .purchase-flight {
      flex-direction: row-reverse;
      max-width: 990px;
      margin: 0 auto;
      .steps{
          margin-right: -50px;
          margin-left: 50px;
      }
      .search-flights-caption {
          justify-content: flex-end;
          margin: 40px 60px 0px 0;
      }
      .options-flight {
          padding: 10px 15px;
          .box-code{
              display: flex;
              max-width: 550px;
              justify-content: space-around;
              h3{
                  font-weight: 500;
                  align-self: center;
              }
              .locator-code{
                  max-width: 230px;
                  height: auto;
                  margin: 0 !important;
                  border: 1px dotted $gray-400;
                  padding: 15px;
                  h6{
                      color: $gray-600;
                      font-weight: 600;
                  }
                  h5{
                      color: $black;
                      font-weight: 700;
                  }
                  & > *{
                      margin-bottom: 0px;
                  }
              }
          }
          .reminder-baggage{
              p{
                  width: auto !important;
              }
          }
          .flight-allinfo{
              display: flex;
              flex-direction: row;
              align-self: center;
              margin: 0 auto;
              justify-content: center;
              .flight-summary{
                  max-width: 330px;
                  margin-right: 2px;
                  align-content: center;
                  & + .flight-summary{
                      margin-top: 0px !important;
                  }
                  .flight-info{
                      margin: auto;
                      .ic-flight{
                          margin-left: -52px;
                      }
                      .flight-date{
                          flex-direction: row !important;
                          margin-right: 5px;
                          margin-left: -20px;
                          justify-content: center;
                          span{
                              display: flex !important;
                              margin: 0 2px;
                              line-height: 14px;
                          }
                      }
                  }
                  .flight-cities{
                      margin: 0 auto;
                  }
              }
              .box-baggage{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  max-width: 270px;
                  margin: 0 0 0 20px !important;
                  height: 220px;
                  padding: 50px 20px;
                  .ic-baggage{
                      margin-bottom: 20px;
                  }
              }
          }
          .passenger-info{
              margin-left: 40px;
              .passenger-title{
                  margin: 50px 0 20px;
              }
          }
      }
      &.buttons {
        display: flex;
        align-items: center;
        width: 60%;
        height: 100px;
        margin-bottom: 20px;
        .btn {
          width: auto;
        }
      }
      &.shopping-banner {
        max-width: 60%;
        padding-bottom: 20px;
        border-bottom: 2px solid $gray-300;
      }
    }
  }


.shopping-image {
  width: 100%;
  height: auto;
}
