.checkout {
  &.miles-reactivation {
    .box-info-purple {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;

      .close {
        margin-top: -9px !important;
      }
    }
  }
}

.my-account {
  &__reactivation-miles {
    .btn {
      width: 100%;
    }

    .reactivation-miles__content {
      .left {
        width: 33.33%;

        h5 {
          color: $gray-800;
          font-size: 18px;
        }

        p {
          font-size: 14px;
          color: $gray-600;
          font-weight: 700;
        }

        span {
          &.input-border {
            input {
              padding: 5px 10px;
              font-size: 35px;
              border-radius: 4px;
              max-width: 90%;
              border: 1px solid $gray-800;
              font-weight: 700;
              color: $gray-700;
            }

            p {
              margin-top: 10px;
              font-size: 18px;
              margin-bottom: 0px;
            }
          }
        }
      }

      .bonus-col {
        color: $primary;
        padding: 0px 30px;

        img.border-icon {
          position: absolute !important;
          left: -23px;
          height: 45px;
          padding: 5px;
          background: white;
          top: 45%;
        }

        p {
          line-height: 100%;
          font-weight: 600;
          font-size: 35px;
          padding-top: 16%;
          margin-bottom: 10px;
        }

        span {
          font-weight: 700;
          font-size: 18px;
        }

        &.club {
          color: $club;
          h5 {
            color: $club;
            font-size: 18px;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20px;

        h5 {
          margin-bottom: 10%;
          font-size: 25px;
          color: $gray-800;
        }

        img.border-icon {
          position: absolute;
          left: -14px;
          top: 47%;
          background: #fff;
          padding: 10px 5px;
          height: 38px;
        }

        .total-miles {
          text-align: center;

          span {
            font-size: 35px;
            font-weight: 700;
          }

          p {
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .my-account {
    &__reactivation-miles {
      .btn {
        margin-left: 10px;
      }

      .reactivation-miles__content {
        text-align: center;

        .col {
          flex-basis: auto !important;
        }

        .border-right {
          border-right: 0px !important;
        }

        img.border-icon {
          display: none !important;
        }

        .left {
          margin-bottom: 20px;
          border-bottom: 1px solid $gray-600;

          input {
            text-align: center;
          }
        }

        .bonus-col {
          padding-bottom: 20px !important;
          margin-bottom: 20px;
          border-bottom: 1px solid $gray-600;

          p {
            padding-top: 5%;
          }
        }

        .right {
          padding-left: 0;

          h5 {
            margin-bottom: 0;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
