.checkout {
  &.miles-purchase, &.miles-transfer, &.miles-extension, &.miles-reactivation {
    .box-info-purple {
      position: absolute;
      background-color: $club;
      color: white;
      max-height: 58px;
      font-size: 10px;
      text-align: left;
      width: 650px;

      .btn-suscribite {
        border: 1px solid $white;
        padding: 8px;
        height: 32px;
        font-family: NunitoLight, sans-serif;
        font-size: 14px;
        margin-top: -2px;
      }

      .close {
        font-size: 15px;
        margin-top: -16px;
        margin-right: 4px;
      }

      p {
        display: inline-block;
      }

      & > div {
        float: left;
      }

      &:before {
        content: "";
        vertical-align: middle;
        width: 0px;
        height: 0px;
        border-left: 8px solid $club;
        border-right: 8px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: 0px;
        margin-right: -16px;
        margin-top: 3px;
        float: right;
      }
    }

    .box-purple {
      text-align: left;
      background: $club;
      padding: 0;
      height: 40px;
      display: flex;
      align-items: center;

      p {
        text-align: center;
        width: 100%;
        font-size: 12px;
        color: white;
        line-height: 14px;
        margin-left: 7px;
        margin-bottom: 0px;
        float: right;
      }
    }

    .box-purple::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 0px;
      height: 0px;
      border-left: 8px solid transparent;
      border-right: 8px solid $club;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      left: 0;
      margin-left: -16px;
      margin-top: 10px;
      position: relative;
      top: -4px;
    }

    .incClubSmiles {
      p {
        margin: 0px;
      }

      .pOrange {
        color: orange;
        font-family: NunitoBold, sans-serif;
      }

      .cancel:hover,
      .cancel {
        color: red;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.my-account {
  &__extend-miles, &__reactivation-miles {
    @include bms-base-box;
    position: relative;
    margin: auto;
    text-align: left;
    padding: 15pt 25pt;
    background-color: white !important;
    border: none !important;
    box-shadow: none;

    .btn {
      margin-top: 20px;
      float: right;
      padding: 10px 40px;
    }

    .banner-purple {
      margin-top: 10px;
      order: 3;
      padding: 20px;
      background-color: #9a3766;

      p {
        margin: 0px;
        color: $white;
        font-weight: 700;
        font-size: 17px;
      }

      span {
        font-size: 22px;
        display: inline-block;
        margin-top: 8px;
      }
    }

    .extend-miles__content, .reactivation-miles__content {
      padding: 30px 0px 15px;
    }
  }

  &__purchase-miles, &__extend-miles, &__reactivation-miles {
    .resume-wrapper {
      border: 1px solid $primary;
      margin: auto;
      width: 830px;
      display: flex;
      justify-content: space-between;
      padding: 14px;

      h4 {
        margin: 35px 25px 0 10px;
      }

      .resume {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 480px;

        .valid {
          font-size: 12px;
          width: 170px;
          margin-top: 17px;
        }
      }

      .table {
        justify-content: inherit;
        border: 1px solid $gray-300;
        display: flex;
        padding: 5px 10px;
        position: relative;
        margin-bottom: 0;

        &::before {
          content: "";
          position: absolute;
          border-bottom: 1px solid $gray-300;
          bottom: 28px;
          width: 457px;
          margin: auto;
        }

        .miles {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: left;

          .value {
            font-size: 1.3em;
            color: $primary;
          }
        }

        .diamond {
          display: flex;
          flex-direction: column;
          text-align: left;
          color: black;

          .valid {
            width: 195px;
            color: $gray-700;
          }

          .value {
            font-size: 1.3em;
          }
        }

        .club {
          display: flex;
          flex-direction: column;
          text-align: left;
          color: $club;

          .valid {
            width: 195px;
            color: $gray-700;
          }

          .value {
            font-size: 1.3em;
          }
        }

        .bonus {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: left;
          color: $default;

          .valid {
            width: 195px;
            color: $gray-700;
          }

          .value {
            font-size: 1.3em;
            color: $gray-700;
          }
        }

        .total {
          flex: 1;
          text-align: right;

          .value {
            color: black;
          }

          .valid {
            display: none;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
      }

      .btn {
        position: relative !important;
        right: 0;
        bottom: 0;
        width: 130px;
        padding: 10px;
      }
    }
  }

  &__purchase-miles, &__transfer-miles {
    @include bms-base-box;
    position: relative;
    margin: auto;
    text-align: left;
    border: none !important;
    box-shadow: none;

    .wrapper {
      width: 100%;
      min-height: 220px;
    }

    h5 {
      color: $gray-700;
      margin-bottom: 0px;
    }

    .rule {
      position: relative;
      display: flex;
      right: 0px;
      top: 0;
      flex-wrap: wrap;
      justify-content: flex-end;
      float: right;
      margin-bottom: 20px;

      &.points {
        justify-content: space-between;

        h4 {
          width: 60%;
          div {
            color: $gray-900;
            display: inline;
          }
        }

        h5 {
          div {
            color: $gray-900;
            display: inline;
          }
        }

        img {
          width: 40px;
          height: 40px;
          margin-bottom: 20px;
        }

        .final-block {
          p {
            font-family: $boldFontFamily;
            font-size: 1.5rem;
          }
        }

        .dni-not-registered {
          margin-top: 39px;
          width: 100%;

          h4 {
            color: $gray-700;
            div {
              color: $gray-900;
              display: inline;
            }
          }

          .buttons {
            display: flex;
            margin-top: 39px;
            justify-content: space-evenly;
          }
        }

        .miles-amount {
          border: 1px solid $default;

          &::before {
            border-left: 40px solid $default;
          }

          .input-border {
            font-family: $boldFontFamily;
            color: $default;

            #miles {
              color: $default;
            }
          }
        }

        .diamond, .club, .bonus {
          text-align: center;
          padding-left: 40px;
          padding-top: 40px;
        }

        .value-total {
          border: 1px solid $gray-400;
          border-left: transparent;

          &::before {
            border-left: 40px solid $gray-400;
          }

          .total_highlit {
            color: $gray-700;
          }
        }
      }

      .flex-wrapper {
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
      }

      .miles-amount, .points {
        height: 150px;
        border: 1px solid $gray-400;
        position: relative;
        padding-right: 20px;
        border-right: none;
        flex: 1;

        &::before {
          content: "";
          position: absolute;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          margin-left: 1px;
          top: -1px;
          z-index: 8;
        }

        &::after{
          content: "";
          position: absolute;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          top: -1px;
          z-index: 9;
        }

        .input-border {
          margin-top: 8px;
          border: 1px solid $gray-400;
          display: block;
          overflow: hidden;
          background-color: $white;
          width: 200px;
          padding: 10px;

          #miles {
            border: none;
            color: $gray-700;
            padding-right: 4px;
            display: inline;
            text-align: right;
          }
        }
      }

      .diamond, .club, .bonus, .points {
        flex: 1;
        color: $white;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0px;
        }
      }

      .diamond {
        background-color: $diamond;
      }

      .club {
        background-color: $club;
      }

      .bonus {
        background-color: $default;
      }

      .points {
        background-color: $gray-200;
      }

      .value-total {
        width: 25%;
        position: relative;
        border-left: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 14px;
        font-family: $boldFontFamily;

        &::before {
          content: "";
          position: absolute;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          margin-right: 1px;
          top: -1px;
          left: 1px;
        }

        &::after {
          content: "";
          position: absolute;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          top: -1px;
          left: 0px;
        }
      }
    }

    .btn {
      margin-top: 20px;
      float: right;
      padding: 10px 40px;
    }

    .banner-purple {
      float: left;
      background-color: $club;
      order: 3;

      a, p {
        color: $white;
        font-size: 14px;
        padding: 0px 11px;
        margin: 13px;
      }

      .btn-compra {
        border: 1px solid white;
        padding: 10px;
        cursor: pointer;
        color: white;
        border-radius: 4px;
        background-color: transparent;
        margin: 10px;
      }

      .btn-close {
        right: 5px;
        top: 5px;
        position: absolute;
        margin: 0 4px 0 0;
        padding: initial;
        color: white;
        cursor: pointer;
      }
    }
  }

  &__terms_conditions {
    display: block;
    margin: auto;
    margin-top: 1.5rem;
    padding-left: 1.25rem;
    position: relative
  }
}

.success-miles {
  height: 420px;
  width: 100%;
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;

  &.header-miles-purchase {
    background-image: url(#{$image-path}/milespurchase/01_comprar_millas_desktop.jpg);
  }

  &.header-miles-extension {
    background-image: url(#{$image-path}/milesextension/01_extender_millas_desktop.jpg);
  }

  &.header-miles-reactivation {
    background-image: url(#{$image-path}/milesreactivation/01_reactivar_millas_desktop.jpg);
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .checkout {
    &.miles-purchase, &.miles-transfer, &.miles-extension, &.miles-reactivation {
      .box-info-purple {
        position: relative;
        height: 140px;
        margin-left: 0 !important;
        text-align: center;
        margin-top: 15px !important;
        margin-bottom: 10px !important;
        max-height: 140px;
        width: 100%;

        &::before {
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid $club;
          position: absolute;
          top: 0;
          left: 50%;
          margin: -20px 0 0 0;
        }

        .hidden-row {
          display: none;
        }

        .club-style {
          display: table-row;
        }

        &.miles-purchase, &.miles-extension, &.miles-reactivation {
          height: unset;
          min-height: 140px;
          max-height: 200px;

          & > div {
            float: none;
          }

          .btn-suscribite {
            margin-top: 10px;
          }
        }
      }

      .box-purple {
        position: absolute;
        max-width: 280px;
        margin-top: -16px;
        left: 18px;
        height: auto;
        padding: 10px;

        p {
          margin: 0;
        }

        &::before {
          border-right: 8px solid transparent;
          border-bottom: 10px solid $club;
          left: 46px;
          top: -29px;
          position: absolute;
        }
      }

      .incClubSmilesMoney {
        vertical-align: top;
        padding-top: 10px;
      }

      .incClubSmiles {
        margin-top: 10px;

        a.cancel {
          padding-top: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .my-account {
    &__extend-miles, &__reactivation-miles {
      padding: 0 9pt 9pt 9pt;

      h4 {
        font-size: 4vw;
        padding: 0;
        margin: 0 0 0 5px;
        font-weight: 700;
        line-height: 25px;
        display: inline-block;
        width: auto;
        border-top: 2px solid $smiles;
      }

      .btn {
        margin-top: 10px;
        order: 3;
        margin-right: 10px;
      }

      .banner-purple {
        position: relative;

        .col-md-8 {
          p {
            margin-top: 30px;
            text-align: center;
          }
        }

        .col-md-4 {
          .col-md-11 {
            text-align: center;
          }
        }
      }
    }

    &__purchase-miles, &__extend-miles, &__reactivation-miles {
      .resume-wrapper {
        border: none;
        margin: auto;
        width: 100%;
        flex-direction: column;
        font-size: 18px;

        .resume {
          border: 1px solid $gray-300;
          width: auto;
        }

        .miles, .diamond {
          margin: 10px 0;
        }

        h4 {
          border-top: none;
          margin: 0 auto 20px;
          font-size: 1.4em;
        }

        .table {
          flex-direction: column;
          border: none;
          text-align: left;

          &::before {
            display: none
          }

          .valid {
            margin-bottom: 6pt;
            margin-top: 0;
          }

          .total {
            display: flex;
            flex-direction: column;
            text-align: left;

            .valid {
              display: inline-block;
            }
          }
        }

        .buttons {
          flex-direction: column;
          margin: 20px 0;
          align-items: center;

          .btn {
            margin: 10px auto;
            min-width: 80%;
          }
        }
      }
    }

    &__purchase-miles, &__transfer-miles {
      .wrapper {
        flex-direction: column;
        display: flex;
      }

      h4 {
        font-size: 4vw;
        padding: 0;
        margin: 0 0 0 5px;
        font-weight: 700;
        line-height: 25px;
        display: inline-block;
        width: auto;
        border-top: 2px solid $default;
      }

      .banner-purple {
        position: relative;

        .col-md-1 {
          .btn-close {
            position: absolute;
            right: 3px;
            cursor: pointer;
          }
        }

        .col-md-4 {
          .col-md-11 {
            text-align: center;
          }
        }
      }

      .rule {
        order: 2;
        flex-direction: column;
        padding: 0;
        width: 100%;

        .flex-wrapper {
          flex-direction: column;
        }

        .miles-amount {
          border: none ;
          height: auto;
          margin: 0;
          text-align: center !important;

          &::before{
            display: none;
          }

          &::after{
            display: none;
          }
        }
      }
    }
  }

  .success-miles {
    margin-top: 60px;
    background-color: transparent;
    background-size: contain;
    background-position:  0 0;

    &.header-miles-purchase {
      background-image: url(#{$image-path}/milespurchase/01_comprar_millas_mobile.jpg);
    }

    &.header-miles-extension {
      background-image: url(#{$image-path}/milesextension/01_extender_millas_mobile.jpg);
    }

    &.header-miles-reactivation {
      background-image: url(#{$image-path}/milesreactivation/01_reactivar_millas_mobile.jpg);
    }
  }
}
