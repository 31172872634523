.resgister-succes-container {
    @include d-flex;

    .image-banner {
        margin-bottom: 40px;
    }

    .register-success-info {
        @include d-flex($align-items: flex-start);
        max-width: 50%;
        margin-bottom: 6em;
        padding: 30px 60px;
        border: 2px solid #FF5A00;
        border-radius: 15px;
        text-align: left;
        position: relative;

        h2 {
            font-size: 36px;
            font-weight: 300 !important;
            color: #FF5A00;
            margin-bottom: 30px;
            text-align: left;
        }

        p {
            font-weight: 500;
        }

        .redirect-timer {
            @include d-flex;
            position: absolute;
            bottom: -3em;
            left: 25%;
            width: 50% !important;

            .time {
                margin-bottom: 5px;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 3px solid #FF5A00;
                background: white;
                box-shadow: 0 0 0 8px #ffffff;
                z-index: 1000;
                font-weight: 700;
                color: #FF5A00;
            }

            .redirect-message {
                color: #FF5A00;
                font-size: 12px;
                text-align: center;
            }
        }

        @media screen and(max-width: 768px) {
            max-width: 90%;
            padding: 10px 15px;
            align-items: center;
            margin-bottom: 3em;

            h2 {
                font-size: 25px;
                margin-bottom: 15px;
            }

            p {
                text-align: center;
            }

            .redirect-timer {
                display: none;
            }
        }
    }
}