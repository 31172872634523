.breadcrumb {
  margin-top: 50px;
  ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
      li {
          font-size: 12px;
          line-height: 12px;
          color: $gray-500;
          width: 25%;
          position: relative;
          text-align: center;
          &.active {
              color: $primary;
              &:before {
                  background: $primary;
              }
              &:after {
                  background: $primary;
              }
          }
          &:before {
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              background: $gray-500;
              border-radius: 50%;
              margin: 0 auto;
              position: relative;
              z-index: 1;
              margin-bottom: 10px;
          }
          &:after {
              content: '';  
              display: block;
              width: 100%;
              height: 2px;
              background: $gray-500;
              margin: 0 auto;
              position: absolute;
              right: 50%;
              top: 3px;
          }
          &:first-child {
              &:after {
                  content: '';
                  right: 0;
                  background: transparent;
              }
          }
      }
  }
}