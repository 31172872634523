.smls-gold-icons {
    font-size: 10px;
}

.smls-gold-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-gold-icons.svg");
    background-repeat: no-repeat;
    background-size: 3.2600000000000002em 17.6em;
}

.no-svg .smls-gold-icons:before {
    background-image: url("../sprites/smls-gold-icons.png");
}



  .smls-gold-icons.ic-anticipation:before {
      background-position: 0em 0em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-box-checked:before {
      background-position: 0em -1.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-departure-plus-solid:before {
      background-position: 0em -3.2em;
      width: 2.98em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-departure-solid:before {
      background-position: 0em -4.8em;
      width: 2.9em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-expiration:before {
      background-position: 0em -6.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-luggage-gold:before {
      background-position: 0em -8em;
      width: 2.8em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-renewal-cycle:before {
      background-position: 0em -9.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-seat-solid:before {
      background-position: 0em -11.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-star-solid:before {
      background-position: 0em -12.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-suitcase-solid:before {
      background-position: 0em -14.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gold-icons.ic-two-luggage-gold:before {
      background-position: 0em -16em;
      width: 3.2600000000000002em;
      height: 1.6em;
  }





