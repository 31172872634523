.button-detail-payment__container {
    display: flex;
    justify-content: start;
    // @media only screen and (max-width: 600px) {
    //     justify-content: center;
    // }
    .button-detail-payment {
        display: flex;
        width: 100%;
        flex-direction: column;
        max-width: 193px;
        text-align: center;
        @media only screen and (max-width: 991px) {
            // margin-right: 2.5rem;
            margin-left: 2.5rem;
        }
        @media only screen and (max-width: 500px) {
            margin-left: 1.5rem;
        }
        @media only screen and (max-width: 375px) {
            margin-left: 1rem;
        }
        // @media only screen and (max-width: 768px) {
        //     margin-right: 3.5rem;
        // }
        // @media only screen and (max-width: 600px) {
        //     margin-right: revert;
        // }
    }
}