.my-account {
  &__my-flights {
    @include bms-base-box;
    position: relative;
    margin: auto;
    text-align: left;
    padding: 15pt 25pt;

    & > div {
      margin-bottom: 30pt;
    }

    h5 {
      color: $gray-700;
    }

    hr {
      margin-bottom: 30pt;
    }

    .empty-state {
      margin: 40pt auto;
    }

    &__table {
      border: 1px solid $gray-300;
      thead {
        background-color: $default;

        &.easy-travel {
          background-color: $easy-travel;

          &::before {
            content: "Viaje Fácil";
            width: 100px;
            height: 32px;
            background-color: $easy-travel-dark;
            position: absolute;
            top: 13px;
            left: -5px;
            border-radius: 3px;
            color: $white;
            padding: 5px;
            text-align: center;
            font-size: 14px;
            padding-right: 10px;
            font-family: $ligthFontFamily;
          }

          &::after {
            content: "";
            position: absolute;
            top: 11px;
            left: 70px;
            margin-left: -5px;
            border-width: 18px;
            border-style: solid;
            border-color: transparent $easy-travel transparent transparent;
          }

          tr {
            th {
              div {
                width: 329px;
                height: 32px;
                position: relative;
                background-color: $easy-travel-dark;
                background-image: url(#{$svg-path}/icons/white/ic-calendar-check.svg);
                background-position: 10px center;
                background-repeat: no-repeat;
                border: 1px $white solid;
                color: $white;
                font-size: 12px;
                text-align: center;
                margin: auto;
                padding-top: 6px;
                font-family: $ligthFontFamily;
              }
            }
          }
        }

        th {
          border-top: 0;
        }

        h5,
        h3 {
          margin-bottom: 0;
        }

        h5 {
          color: $black;
          font-size: 8pt;
        }
        h3 {
          color: $white;
          font-size: 12pt;
        }
      }
    }

    .my-flights {
      &__date {
        &-weekday {
          font-size: 9pt;
        }
        &-day,
        &-month {
          font-size: 10pt;
          font-weight: bold;
        }
      }

      &__options {
        span {
          &[class^="text"] {
            font-size: 8pt;
          }
        }

        a,
        span[class^="text"] {
          cursor: pointer;
          text-decoration: none;
          color: $gray-700;
        }

        &.easy-travel {
          div {
            &:first-child {
              label {
                float: left;
                font-family: $regularFontFamily;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                text-align: left;
                clear: both;

                &:first-child {
                  color: $gray-700;
                }
                &:nth-child(2) {
                  color: $easy-travel;
                }
              }

              button {
                float: left;
                width: 100%;
                background-color: $easy-travel;
                border: none;
                border-color: transparent;
                margin-bottom: 10px;
                font-size: 14px;
                padding: 5px 0 !important;
                height: 32px;

                &.disable {
                  background-color: $gray-500;
                }
              }

              div {
                background-color: $white;
                border: 2px $default solid;
                padding: 5px;
                float: left;
                color: $gray-700;
                position: relative;
                width: 120px;
                height: 60px;
                font-size: 11px;
                display: block;
                border-radius: 3px;
                margin: 3px 0 3px 35px;

                b {
                  font-family: $regularFontFamily;
                  color: $easy-travel !important;
                }

                span {
                  font-family: $regularFontFamily;
                  color: $club !important;
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: -20px;
                  left: 55px;
                  margin-left: -5px;
                  border-width: 10px;
                  border-style: solid;
                  background-color: transparent;
                  border-color: transparent transparent $default transparent;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: -14px;
                  left: 58px;
                  margin-left: -5px;
                  border-width: 7px;
                  border-style: solid;
                  background-color: transparent;
                  border-color: transparent transparent $white transparent;
                }
              }

              small {
                float: left;
                font-size: 10px;
                max-width: 120px;
                margin: 0 0 0 43px;
              }
            }

            &:last-child {
              a {
                font-family: $boldFontFamily;
                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      &__inner-table {
        thead {
          background-color: initial;
        }
        tbody {
          .passenger-info {
            font-size: 10pt;
          }
          span {
            & + span {
              margin-left: 5pt;
            }
          }
        }
      }

      &__logo {
        width: 45pt;
        height: 20pt;

        &-wrapper {
          padding: 12pt 0;
          border: 1px solid $gray-300;
        }
      }

      &__transfer {
        &-range {
          font-size: 8pt;
        }
        &-city {
          font-size: 7pt;
        }
        &-date {
          font-size: 6pt;
        }
      }

      &__cabin {
        &-wrapper {
          & + .my-flights__cabin-wrapper {
            margin-top: 5pt;
          }
        }
        span {
          font-size: 8pt;
          margin-right: 5pt;
        }
      }

      &__details {
        &-wrapper {
          span {
            margin-left: 4pt;
            font-size: 9pt;
          }
        }
      }

      &__sidebar {
        .btn {
          line-height: inherit;
        }
      }

      &__timeline {
        margin-top: 15pt;

        span {
          &[class*="icons"] {
            background-color: $white;
            height: fit-content;
            position: relative;
            z-index: 1;
          }
        }

        .checkin-details {
          &-range {
            &-text {
              font-size: 8pt;
              border-radius: 4px;
              padding: 2pt;
              border: 1px solid $gray-300;
              text-transform: uppercase;
              margin-top: 2pt;
            }
          }
          &-info {
            flex-wrap: nowrap !important;
            &-text {
              font-size: 9pt;
            }
          }
        }
      }
    }

    .flight-navigation {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      gap: 10px;

      button {
        background-color: $gray-300;
        color: $gray-700;
        border: none;

        &.active-section {
          color: $default !important;
        }

        @supports (-webkit-touch-callout: none) and (not (translate: none)) {
          &:not(:last-child) { /* Safari below 14.1 doesn't support flex gap */
            margin-right: 5%;
            margin-bottom: 5%;
          }
        }

      }
    }
  }
}

.payment {
  &__table {
    &-title {
      font-size: 10pt;
    }

    &-details {
      border: 2px solid $gray-300;
      padding: 5pt 10pt;
      margin-bottom: 20pt;

      span[class^="smls"] {
        & + p {
          display: inline-block;
          margin-left: 10pt;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .my-account {
    &__my-flights {
      padding: 15pt 10pt;

      h5 {
        &.section-title {
          font-size: 12pt;
        }
      }

      hr {
        margin-bottom: 20pt;
      }

      .empty-state {
        margin: 20pt 0;
      }

      .my-flights {
        &__details {
          margin-top: 10pt;
        }
        &__timeline {
          > div {
            &:before {
              content: "";
              width: 1px;
              border-left: 1px dashed $gray-600;
              position: relative;
              left: 8px;
            }

            &:last-child {
              &:before {
                border: none;
              }
            }
          }

          .checkin-details {
            &-info {
              flex-wrap: wrap !important;
              padding-left: 10px;
              margin-bottom: 20pt;
            }
          }
        }
        &__options {
          &.easy-travel {
            div {
              &:first-child {
                text-align: center;

                label {
                  float: none;
                  clear: none;
                  display: inline-block;
                }

                button {
                  float: none;
                  width: 156px;
                  margin-bottom: 10px;
                }

                div {
                  float: none;
                  display: inline-block;
                  margin: 3px 10px;
                }

                small {
                  float: none;
                  display: inline-block;
                  font-size: 10px;
                  max-width: 100%;
                  margin: auto;
                }
              }
              &:last-child {
                width: 100%;
                display: block !important;
                max-width: 100%;
                float: left;
                min-width: 100%;
                text-align: center;

                span {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      &__table {
        thead {
          &.easy-travel {
            &::before {
              content: "Viaje Fácil";
              width: 100px;
              height: 32px;
              background-color: $easy-travel-dark;
              position: absolute;
              top: -14px;
              left: -5px;
              border-radius: 3px;
              color: $white;
              padding: 5px;
              text-align: center;
              font-size: 14px;
              padding-right: 10px;
              font-family: $ligthFontFamily;
            }

            &::after {
              content: "";
              position: absolute;
              top: 1px;
              left: 70px;
              margin-left: 13px;
              border-width: 0 18px 18px 0;
              border-style: solid;
              border-color: transparent $easy-travel transparent transparent;
            }

            tr {
              &::after {
                content: "";
                position: absolute;
                top: -17px;
                left: 70px;
                margin-left: 13px;
                border-width: 18px 18px 0 0;
                border-style: solid;
                border-color: transparent $white transparent transparent;
              }

              th {
                div {
                  width: 100%;
                  margin: 20px 0 0 0;
                  background-size: 20px;
                  padding: 9px 0 0 20px;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.no-data {
    margin-top: 20pt;
  }
}

.modal-detalle-de-pago {
  &.cancel-warning {
    .modal-content {
      .modal-footer {
        display: inherit;
        border-top: 0;
      }
    }
  }
  .modal-content {
    padding: 0 0 20px 0;
    border-radius: 0 !important;
    .modal-header {
      border: none;
    }
    .modal-footer {
      display: none;
    }
    .modal-body {
      padding: 0 20px;
    }
  }
}

.payment {
  &__table-title {
    padding: 0 10px;
  }
  &__table-details {
    margin: 0 10px 20px 10px;
    border: 1px solid $gray-500;
    color: $gray-900;
    &.buyer-data {
      padding: 0;
      padding-left: 10px;
      .col-sm-12 {
        padding-top: 12px;
        padding-left: 13px;
        @include min-width (1024px) {
          &:nth-child(1) {
            border-right: 1px solid $gray-500;
            border-bottom: 1px solid $gray-500;
          }
          &:nth-child(2) {
            border-bottom: 1px solid $gray-500;
          }
          &:nth-child(3) {
            border-right: 1px solid $gray-500;
          }
        }
      }
    }
    &.company-data {
      padding: 0;
      padding-left: 10px;
      .col-sm-12 {
        padding-top: 12px;
        padding-left: 13px;
        @include min-width (1024px) {
          &:nth-child(1) {
            border-right: 1px solid $gray-500;
          }
        }
      }
    }
    &__column {
      table {
        tbody {
          tr {
            border-bottom: 2px solid $gray-300;
            &:last-child {
              border-bottom: none;
            }
            td {
              small {
                padding: 0;
                float: left;
              }
            }
          }
        }
      }
      .ic-order-creditcard,
      .ic-order-creditcardtype,
      .ic-order-creditcarduser,
      .ic-order-paymentform {
        font-size: 20px;
      }
    }

  }
}

// Large devices (Desktops)
@media (min-width: 768px) {
  .my-account {
    &__my-flights {
      .my-flights {
        &__timeline {
          position: relative;

          &:before {
            content: "";
            width: 85%;
            border-top: 1px dashed $gray-600;
            height: 1px;
            position: absolute;
            left: 32pt;
            top: 6pt;
          }
        }

        &__logo {
          margin-top: 5pt;
          width: 25pt;
        }
      }
    }
  }

  .payment {
    &__table {
      &-details {
        p {
          font-size: 13px;
        }
        .baggage-info {
          p {
            white-space: nowrap;
            font-size: 12px;
          }
        }
        &__column {
          & + .payment__table-details__column {
            border-left: 2px solid $gray-300;
          }
          table {
            tbody {
              tr {
                td {
                  small {
                    padding: 0 0 0 20px;
                    float: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-detalle-de-pago {
    .modal-content {
      padding: 0 20px 20px 20px;
    }
  }
}

.my-flights {
  &__payment {
    &-title {
      color: $gray-800;
    }
  }
}

.ttipCancelVuelo {
  .popover {
    border-color: $gray-800 !important;
    box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.75);
    .popover-inner {
      max-width: 180px;
    }
    .popover-header {
      color: $default;
    }
    .popover-body {
      font-size: 12px;
    }
    .arrow {
      &::before {
        border-top-color: $gray-800 !important;
        border-bottom-color: $gray-800 !important;
      }
    }
  }
}
