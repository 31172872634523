.content-page {
  .smls-white-icons.ic-smiles-connector {
    font-size: 16px !important;
    margin-top: -7px !important;
  }

  .smls-white-icons.ic-airplane,
  .smls-orange-icons.ic-airplane-solid,
  .smls-white-icons.ic-luggage-solid {
    font-size: 20px !important;
    margin-top: 0px !important;

    @media (max-width: 576px) {
      font-size: 13px !important;
      margin: 0 10px 0 10px !important;
    }
  }

  .text-bold {
    font-family: $boldFontFamily !important;
  }

  .img-bg-mobile {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .img-bg-desktop {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.content-page .company-page {
  @media (max-width: 767px) {
    padding-top: 20px;
  }

  @media (max-width: 576px) {
     #atomoKV ul li .atomoKVItem .atomoKVFooter > span {
         margin: 0 10px -10px 10px;
     }
  }

  .c-header__termos,
  .c-subtitle__termos {
    color: #FF5A00;
  }
}
