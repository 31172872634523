// BMS => Box Model Smiles

@mixin media-large() {
  @media (min-width: 768px) {
      @content;
  }
}

@mixin media-large-maxw() {
  @media (max-width: 768px) {
      @content;
  }
}
@mixin media-max-w($width: 768px) {
  @media (max-width: $width) {
    @content;
  }
}
@mixin max-width($width) {
  @media (max-width: $width) {
    @content;
  }
}
@mixin min-width($width) {
  @media (min-width: $width) {
    @content;
  }
}
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin bms-font-size($mobile, $desktop, $hasMiddleSize: false) {
  font-size: $mobile;

  @if ($hasMiddleSize) {
    $value: strip-unit($mobile);

    @media (min-width: 576px) {
      font-size: ($value + 1) + vw;
    }
  }

  @media (min-width: 768px) {
    font-size: $desktop;
  }
}

@mixin bms-line-height($mobile, $desktop) {
  line-height: $mobile;
  @media (min-width: 768px) {
    line-height: $desktop;
  }
}

@mixin bms-base-box($has-border: true) {
  box-sizing: border-box;
  background-color: $white;
  @if ($has-border == true) {
    box-shadow: 0 1px 3px 0 $gray-400;
  } @else {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  margin: auto auto 25px auto;
  @media (max-width: 991px) {
    width: 90vw;
    margin: auto auto 15px auto;
  }
}


@mixin bms-triangle-tooltip($direction: up, $color: #000, $base-size: 8, $positon: 48%, $border-size: 1) {

  @if ($direction == up) {
    &:before {
      position: absolute;
      content: "";
      border-top: 0;
      border-left: ($base-size + px) solid transparent;
      border-bottom: (($base-size - 1) + px) solid $color;
      border-right: ($base-size + px) solid transparent;
      left: ($positon);
      top: ($base-size * -1) + px;
    }
    &:after {
      position: absolute;
      content: "";
      border-top: 0;
      border-left: ($base-size + px) solid transparent;
      border-bottom: (($base-size - 1) + px) solid $white;
      border-right: ($base-size + px) solid transparent;
      left: ($positon);
      top: (($base-size - $border-size) * -1) + px;
    }
  }

  @if ($direction == right) {
    &:before {
      position: absolute;
      content: "";
      border-top:  ($base-size + px) solid transparent;
      border-left: (($base-size - 1) + px) solid $color;
      border-bottom: ($base-size + px) solid transparent;
      border-right: 0;
      right: ($base-size * -1) + px;
      top: $positon;
    }
    &:after {
      position: absolute;
      content: "";
      border-top: ($base-size + px) solid transparent;
      border-left: (($base-size - 1) + px) solid $white ;
      border-bottom: ($base-size + px) solid transparent;
      border-right: 0;
      right: (($base-size - $border-size) * -1) + px ;
      top: $positon;
    }
  }

  @if ($direction == bottom) {
    &:before {
      position: absolute;
      content: "";
      border-top: (($base-size - 1) + px) solid $color;
      border-left: ($base-size + px) solid transparent;
      border-bottom: 0;
      border-right: ($base-size + px) solid transparent;
      bottom: ($base-size * -1) + px;
      left: $positon;
    }
    &:after {
      position: absolute;
      content: "";
      border-top: (($base-size - 1) + px) solid $white;
      border-left: ($base-size + px) solid transparent;
      border-bottom: 0;
      border-right: ($base-size + px) solid transparent;
      bottom: (($base-size - $border-size) * -1) + px ;
      left: $positon;
    }
  }

}

@mixin _base-arrow {
  position: absolute;
  content: " ";
  border-style: solid;
}

@mixin navigation-active {
  background-color: $gray-200;
}

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin cloud-club($width,$height,$left,$top) {
  content: '';
  background-image: url(../images/svg/icons/purple/ic-cloud.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: $width;
  height: $height;
  left: $left;
  top: $top;
}


@mixin base-logo ($color: $white) {

  @if ($color == $white) {
    background-image: url(../images/svg/logos/smiles-white.svg);
  }
  @if ($color != $white) {
    background-image: url(../images/svg/logos/smiles-purple.svg);
  }
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 30px;
  text-align: center;
  background-position: center;
  margin-top: 0px;
  position: relative;
  display: block;

  &::after {
      content: "";
      position: absolute;
      height: 9px;
      border-bottom: 2px solid $color;
      top: 3px;
      width: 16%;
      right: 70%;
      margin-left: 1px;
  }
  &::before {
      content: "";
      position: absolute;
      height: 9px;
      border-bottom: 2px solid $color;
      top: 3px;
      width: 16%;
      left: 70%;
      margin-left: 3px;
  }

}

@mixin size($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
  @content;
}

@mixin square($size: 100%) {
  @include size($size, $size);
}

@mixin pos-absolute($x: left, $v-val: 0, $y: top, $y-val: 0) {
  position: absolute;
  #{$x}: $v-val;
  #{$y}: $y-val;
}

@mixin icon-pos(
  $pos: "before",
  $icon-path: "",
  $size-x: 100%,
  $size-y: 100%,
  $pos-x: left,
  $val-x: 0,
  $pos-y: top,
  $val-y: 0
) {
  &::#{$pos} {
    content: "";

    @include pos-absolute($pos-x, $val-x, $pos-y, $val-y);
    @include size($size-x, $size-y);

    background: url("#{$icon-path}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @content;
  }
}

@function icon ($icon) {
  @return "#{$svg-path}/icons/#{$icon}";
}

@mixin d-flex($direction: column, $align-items: center, $justify: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify;
}
