.my-account {
  &__login {
    &-box {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 90%;
      padding: 20pt 5pt 5pt 5pt;
      @include bms-base-box;
      margin: auto !important;
      h2 {
        text-align: center;
        color: $gray-800;
        margin-bottom: 20px;
        font-size: 14px;
        &:before {
          content: " ";
          display: block;
          width: 100%;
          height: 60px;
          background-image: url(#{$svg-path}/icons/orange/ic-profile-solid.svg);
          background-size: 50px;
          background-position: center 0;
          background-repeat: no-repeat;
        }
      }
      h3 {
        text-align: center;
        color: $gray-800;
        margin-bottom: auto;
        font-size: 14px;
        line-height: 20px;
      }
      form {
        .form-group {
          input {
            border-radius: 4px;
            height: 40px;
          }
        }
        button {
          margin: 20px auto;
          width: 100%;
          border-radius: 4px;
        }
      }
      a {
        text-align: center;
        display: block;
        color: $gray-800;
        font-size: 12px;
        font-family: $boldFontFamily;
        margin: 0 auto 10px;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      .alert-message {
        color: $red;
        text-align: center;
      }
    }
  }
}

.login-box-modal {
  .modal-dialog {
    max-width: 400px;
    text-align: center;
    .modal-content {
      background-color: $gray-100;
      margin: 50px auto;
      border: none;
      border-radius: $border-radius;
      min-height: 330px;
      width: 90%;
      .modal-header {
        > h5 {
          font-size: 18px;
          color: $gray-800;
        }
        border: none;
        button {
          position: absolute;
          outline: none;
          background-color: transparent;
          opacity: 1;
          top: 12px;
          right: 12px;
          span {
            display: block;
            width: 32px;
            height: 32px;
            overflow: hidden;
            text-indent: -1000px;
            &:before {
              position: absolute;
              top: 15px;
              right: 10px;
              content: " ";
              width: 32px;
              height: 32px;
              background-image: url(#{$svg-path}/icons/gray/ic-close-circle.svg);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 32px;
            }
          }
        }
      }
      .modal-body {
        padding-top: 0;
        .row {
          margin-top: 10px;
          font-family: $boldFontFamily;
          @include bms-font-size(10px, 12px);
          @include bms-line-height(14px, 16px);
          &:last-child {
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            text-align: left;
          }
        }

        a {
          color: $gray-800;

          &:hover {
            color: $primary;
            text-decoration: none;
          }
        }

        .frm-default {
          .form-group {
            margin: 0 0 25px 0;

            span {
              text-align: left;
              float: left;
              font-size: 12px;

              &.disabled {
                display: none;
              }

              &.enabled {
                display: block;
                padding: 3px 0 3px 0;
              }
            }
          }
        }

        .recaptcha {
          height: 70px;
          width: 100%;
          overflow: hidden;
          margin-bottom: 20px;
          > div {
            > div {
              > div {
                display: flex;
                justify-content: center;
                > div {
                  margin: 0 auto !important;
                  transform: scale(0.78);
                  -webkit-transform: scale(0.78);
                }
              }
            }
          }
        }
      }
      .modal-footer {
        border: none;
        background-color: $primary;
        color: $white;
        .col-7 {
          text-align: left;
          p {
            font-family: $boldFontFamily;
            margin: 0 5px 0 0;
            padding: 0;
            @include bms-font-size(10px, 13px);
            @include bms-line-height(14px, 17px);
          }
        }
      }
    }
  }
}

.login-first-step {
  .modal-dialog {
    width: 224px;
    height: 222px;
    top: 60px;
    float: right;
    .modal-content {
      border: 1px solid $primary;
      border-radius: $border-radius;
      .modal-header {
        display: none;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .my-account {
    &__login {
      &-box {
        padding: 50px;
        text-align: left;
        width: 561px;
        min-height: auto;
        height: auto;
        h2 {
          margin-bottom: 30px;
          font-size: 16px;
          &:before {
            height: 70px;
            background-position: center 0;
          }
        }
        form {
          padding: 0 70px;
          .form-group {
            input {
              height: 50px;
            }
          }
        }
        a {
          font-size: 14px;
        }
      }
    }
  }
  .login-box-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          > h5 {
            width: 100%;
            text-align: center;
            margin-top: 50px;
            &:before {
              position: absolute;
              top: -20px;
              left: 40%;
              content: " ";
              width: 72px;
              height: 72px;
              background-image: url(#{$svg-path}/icons/orange/ic-profile-with-circle.svg);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 72px;
            }
          }
          button {
            top: 0;
            right: -50px;
            span {
              &:before {
                width: 42px;
                height: 42px;
                background-size: 42px;
              }
            }
          }
        }
        .modal-body {
          .frm-default {
            margin: auto;
            width: 304px;

            .form-group {
              margin: 0 0 14px 0;

              span {
                display: none;
                &.enabled {
                  display: none;
                }
              }
            }
          }
          .recaptcha {
            height: 80px;
            > div {
              > div {
                > div {
                  > div {
                    transform: scale(1);
                    -webkit-transform: scale(1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
