/*Flujo error inicio*/
.easy-travel-error-main {
    padding: 20px 10px;
    .title {
        padding: 0 0 0 0;
        h4 {
            font-size: 28px;
            font-family: $regularFontFamily;
            float: left;
            font-weight: bold;
        }
    }
    .error-message__container {
        padding: 0px;
        border: 1px solid $gray-500;
        display: flex;
        flex-direction: row;
        .error-message__left {
            background-color: $easy-travel;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            height: 261px;
            .viaje-facil-logo {
                width: 55px;
                height: 55px;
                position: absolute;
                top: 12px;
                left: 12px;
                background: url(#{$svg-path}/icons/white/ic-easy-travel.svg) no-repeat;
                background-size: 55px !important;
            }
            .error-icon {
                background: url(#{$svg-path}/icons/white/ic-alert-error.svg) no-repeat center;
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
                display: block;
                background-size: 60px;
            }
            h2 {
                color: $white;
                text-align: center;
                margin-bottom: 0px;
                font-size: 1.3rem;
            }
        }
        .error__right {
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 25px;
            text-align: center;
            height: 260px;
            .error-right__container {
                width: 100%;
                text-align: left;
                .error-list {
                    padding-left: 0px;
                    hr {
                        margin: 0px;
                        margin-bottom: 5px;
                    }
                    li {
                        span {
                            font-size: 14px;
                            font-weight: 800;
                        }
                        .error-icon-check {
                            display: inline-block;
                            background: url(#{$svg-path}/icons/gray/ic-check-box-error.svg) no-repeat center;
                            width: 20px;
                            height: 17px;
                            background-size: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .error-right__footer {
                width: 100%;
                a { 
                    border: 0px solid;
                    background-color: $easy-travel;
                    margin-top: 0px;
                    float: right;
                    padding: 6px 15px;
                    height: 30px;
                    border-radius: 5px;
                    font-size: 14px;
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .error-message__container {
            flex-direction: column;
        }    
    }
    .resume-flight {
        display: block;
        height: 300px;
        background-color: $gray-300;
    }
    .gray-square {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 261px;
        background-color: $gray-300;
        span {
            font-size: 14px;
            font-weight: 800;
            display: block;
            margin-bottom: 10px;
            text-align: center;
        }
        .wp-flex {
            display: block;
            width:50px;
            height:50px;
            background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
            background-size: 60px;
        }
    }
}
/*Flujo error Fin*/