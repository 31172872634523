.CheckoutDniInput-container {
    width: 100%;
    .FormGroupinput-dni {
      max-width: 300px;
      position: relative;
      width: 100%;
      z-index: 1;
      @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
      .input-dni {
        width: 260px !important;
        min-width: 260px;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 100% !important;
          min-width: 0px;
          height: 40px !important;
        }
      }
      .alert-div {
        align-items: center;
        background-position: 90% !important;
        background-repeat: no-repeat !important;
        background-size: 28px 20px !important;
        border-radius: 6px;
        display: flex;
        height: 50px;
        justify-content: flex-end;
        padding: 0 5px;
        position: relative;
        right: 7px;
        top: 0;
        width: 80px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          height: 40px !important;
          right: 20px;
        }
      }
      &.error-alert {
        .input-dni {
          border: 1px solid #e95555;
        }
        .alert-div {
          background-image: url("../../../images/svg/icons/red/ic-error-adhesion.svg") !important;
          background: #ffebeb;
          border: 1px solid #ffb7b7;
        }
      }
      &.warning-alert {
        .input-dni {
          border: 1px solid #fd8026;
        }
        .alert-div {
          background-image: url("../../../images/svg/icons/orange/ic-alert-circle.svg") !important;
          background: #ffebce;
          border: 1px solid #ffc18e;
        }
      }
      &.success-alert {
        .input-dni {
          border: 1px solid #0a9947;
        }
        .alert-div {
          background-image: url("../../../images/svg/icons/green/ic-success-adhesion.svg") !important;
          background: #d3fbd7;
          border: 1px solid #91ce9c;
        }
      }
    }
    .errors{
      text-align: left;
      &.error-alert{
        .error__Tittle, .error-text{
          color: #e95555 !important;
        }
      }
      &.warning-alert{
        .error__Tittle, .error-text{
          color: #fd8026 !important;
        }
      }
      &.success-alert{
        .error__Tittle, .error-text{
          color: #0a9947 !important;
        }
      }
      .error__Tittle{
        font-size: 14px;
        margin-bottom: 5px !important;
      }
      .error-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        @media screen and (max-width: 991px) {
          margin: 0;
        }
      }
    }
  }
