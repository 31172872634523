/* COUNTER */

.smiles-offer-counter-inner {
    align-items: center;
    background-color: #FF5A00;
    border-radius: 20px 7px 7px 20px;
    display: flex;
    height: 100px;
    left: 35px;
    max-width: 315px;
    min-width: 300px;
    padding-right: 10px;
    position: absolute;
    top: 185px;
    width: auto;

    &:before {
        background-color: inherit;
        border-radius: 20px;
        content: " ";
        height: 30px;
        position: absolute;
        right: 0;
        top: -5px;
        width: 95%;
        z-index: 1;

        -moz-transform: rotate(-2deg);
        -webkit-transform: rotate(-2deg);
        -o-transform: rotate(-2deg);
        -ms-transform: rotate(-2deg);
        transform: rotate(-2deg);
    }

    &:after {
        background-color: inherit;
        border-radius: 20px;
        bottom: -5px;
        content: " ";
        height: 30px;
        position: absolute;
        right: 0;
        width: 95%;
        z-index: 1;

        -moz-transform: rotate(2deg);
        -webkit-transform: rotate(2deg);
        -o-transform: rotate(2deg);
        -ms-transform: rotate(2deg);
        transform: rotate(2deg);
    }

    &.club-smiles {
        background-color: #663398;
    }

    .icon-timer {
        margin: 0 12px 0 -8px;
        position: relative;
        z-index: 2;

        img {
            display: block;
        }

        .timer-mobile {
            display: none;
        }
    }

    .counter-timer {
        position: relative;
        z-index: 2;

        p {
            color: #FFF;
            display: inline-block;
            font-family: 'NunitoBold', sans-serif;
            font-size: 39px;
            line-height: 0.55em;
            margin: 15px 4px 0 4px;
            vertical-align: top;

            span {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 1299px) {
    .smiles-offer-counter-inner {
        height: 90px;
        left: 2.5vw;
        max-width: 240px;
        min-width: auto;
        top: 14vw;

        .counter-timer {
            p {
                font-size: 23px;
                line-height: 0.7em;
                margin: 8px 4px 0 4px;
            }
        }
    }
}

@media (max-width: 1023px) {
    .smiles-offer-counter-inner {
        border-radius: 10px 7px 7px 10px;
        height: 65px;
        max-width: 185px;

        &:before {
            border-radius: 10px;
            top: -6px;
            width: 100%;
        }

        &:after {
            border-radius: 10px;
            bottom: -6px;
            width: 100%;
        }

        .icon-timer {
            margin: 0 5px 0 -7px;

            img {
                width: 60px;
            }
        }

        .counter-timer {
            p {
                font-size: 15px;
                line-height: 1em;
                margin: 4px 0 0 3px;

                span {
                    font-size: 11px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .smiles-offer-counter-inner {
        border-radius: 0;
        height: 85px;
        max-width: 100%;
        position: static;
        justify-content: center;
        width: 100%;
        padding-right: 0;

        &:before {
            display: none;
        }

        &:after {
            display: none;
        }

        .icon-timer {
            margin: 0 20px 0 0;

            .timer-desktop {
                display: none;
            }

            .timer-mobile {
                display: block;
            }
        }

        .counter-timer {
            p {
                color: #FFF;
                display: inline-block;
                font-family: 'NunitoBold', sans-serif;
                font-size: 39px;
                line-height: 0.55em;
                margin: 15px 4px 0 4px;
                vertical-align: top;

                span {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: 359px) {
    .smiles-offer-counter-inner {
        .counter-timer {
            p {
                color: #FFF;
                display: inline-block;
                font-family: 'NunitoBold', sans-serif;
                font-size: 30px;
                line-height: 0.55em;
                margin: 15px 4px 0 4px;
                vertical-align: top;
            }
        }
    } 
}