.jarvis-flight-details-container {
  margin-top: 40px;
  min-height: 715px;
  .flight-details-wrapper {
    padding: 50px 70px;
    .flight-details {
      .row-btns{
        justify-content: space-between;
      }
      .my-account__extract {
        box-shadow: none;
        border: 1px solid $gray-400;
        font-size: 0.8em;
        form {
          div {
            div {
              div {
                input {
                  height: auto !important;
                }
                .input-text {
                  font-size: 1em;
                  padding: 9px;
                  line-height: 1;
                }
                .react-select__control {
                  height: 10px !important;
                  .react-select__value-container {
                    font-size: 1em;
                    margin-bottom: 12px;
                  }
                  .react-select__indicators {
                    .react-select__indicator-separator {
                      margin-bottom: 20px !important;
                    }
                    .react-select__dropdown-indicator {
                      margin-bottom: 13px !important;
                    }
                  }
                }
                .date-picker {
                  .SingleDatePicker {
                    div {
                      .SingleDatePickerInput {
                        height: 40px !important;
                        .DateInput {
                          .DateInput_input {
                            font-size: 1em;
                            padding: 7px 11px 9px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .jarvis-flight-details-container {
    .flight-details-wrapper {
      padding: 50px 0px !important;
      .flight-details {
        .row-btns{
          justify-content: center;
        }
        .my-account__extract {
          form {
            div {
              div {
                div {
                  input {
                    font-size: 1em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .jarvis-flight-details-container {
    .flight-details-wrapper {
      padding: 20px 120px !important;
    }
  }
}

@media only screen and (min-width: 1550px) {
  .jarvis-flight-details-container {
    min-height: 540px;
    .flight-details-wrapper {
      padding: 20px 340px !important;
    }
  }
}
