@mixin member-icon() {
  padding-left: 30px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: left 0;
  background-image: url(#{$svg-path}/icons/ocean/ic-profile-solid.svg);
}

.family-account {
  width: 97vw;
  margin: 35px auto 15px auto;
  h2 {
    color: $family-account;
    margin-bottom: 17px;
  }
  h3 {
    color: $family-account;
  }
  span {
    &.txt-capitalize {
      text-transform: capitalize;
    }
  }
  .btn-family-account {
    padding: 10px 30px 10px 30px;
  }
  .password {
    text-align: left;
    margin-top: 10px;
    padding-bottom: 20px;
    .checkout {
      width: 100%;
      .box {
        padding: 0;
        margin: 0;
        border: none;
      }
    }
  }
  .disable-all {
    opacity: 0.5;
    * {
      pointer-events: none;
    }
  }
  &__header {
    position: relative;
    width: 100%;
    span {
      position: absolute;
      top: 0;
      right: 0;
      color: $gray-600;
      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        top: 3px;
        left: -20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg);
      }
    }
  }
  .start-account {
    @include bms-base-box(false);
    text-align: center;
    &.checkout-club {
      padding-bottom: 30px;
    }
    .steps {
      @include bms-base-box;
      &__header {
        background-color: $family-account;
        padding: 10pt 18pt 15pt 18pt;
        box-shadow: 0 1px 5px 0 $gray-400;
        text-align: left;
        background-image: url(#{$svg-path}/misc/ic-cloud-with-airplane.svg);
        background-size: 150px;
        background-repeat: no-repeat;
        background-position: center 20px;
        p {
          @include bms-font-size(5vw, 18px);
          @include bms-line-height(8vw, 24px);
          color: $white;
          margin: 80px auto auto auto;
        }
      }
      &__details {
        .detail {
          padding: 10pt 10pt 10pt 10pt;
          h3 {
            @include bms-font-size(6vw, 20px);
            &::before {
              content: "";
              display: block;
              width: 100%;
              height: 70px;
              background-size: 80px;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.step-1 {
              &::before {
                background-image: url(#{$svg-path}/icons/ocean/ic-people.svg);
              }
            }
            &.step-2 {
              &::before {
                background-image: url(#{$svg-path}/icons/ocean/ic-people-arrows.svg);
              }
            }
            &.step-3 {
              &::before {
                background-image: url(#{$svg-path}/icons/ocean/ic-airplane-fa.svg);
              }
              &.dependent {
                &::before {
                  background-size: 60px;
                  background-image: url(#{$svg-path}/icons/ocean/ic-ticket-solid-v2.svg);
                }
              }
            }
          }
          p {
            @include bms-font-size(5.7vw, 18px);
            @include bms-line-height(8vw, 24px);
            font-weight: lighter;
          }
          .separator {
            position: relative;
            .step-number {
              display: flex;
              margin: auto;
              background-color: $family-account;
              color: $white;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              opacity: 1;
              > div {
                margin: auto;
                width: 46px;
                height: 46px;
                border: 3px solid $white;
                border-radius: 50%;
                font-family: $boldFontFamily ;
                font-size: 24px;
                padding-top: 2px;
              }
              &::before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 40%;
                height: 1px;
                margin-top: 25px;
                border-top: 3px dotted lighten($family-account, 10%);
              }
              &::before {
                margin-left: -40%;
              }
              &::after {
                margin-left: 50px;
              }
            }
          }
        }
      }
    }
    .requirement {
      color: $family-account;
      padding-bottom: 35px;
      border-bottom: 1px solid $gray-500;
      h3 {
        margin-top: 40px;
        font-family: $boldFontFamily;
        text-transform: uppercase;
        @include bms-font-size(5vw, 18px);
      }
      p {
        margin-top: 30px;
        padding: 0 20pt 0 20pt;
        @include bms-font-size(5vw, 16px);
        @include bms-line-height(8vw, 22px);
      }
      .btn-family-account {
        margin-top: 30px;
      }
      &.dependent {
        border-bottom: none;
        padding-bottom: 0;
        &.buttons {
          padding-bottom: 20px;
        }
        h3 {
          margin: 20px auto 10px auto;
          text-transform: none;
        }
        p {
          margin: 0 auto 30px auto;
          color: $gray-700;
        }
        .expiration {
          margin: 0;
          padding: 0;
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 70px;
            background-size: 50px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(#{$svg-path}/icons/ocean/ic-clouds-fa.svg);
          }
        }
      }
      .btn-outline-primary {
        width: 120px;
        margin-right: 10px;
        color: $family-account;
        border-color: $family-account;
        &:hover {
          background-color: $family-account;
          color: $white;
        }
        &.active {
          background-color: $family-account;
          color: $white;
        }
      }
    }
    .questions {
      padding-bottom: 30px;
      h3 {
        @include bms-font-size(7vw, 26px);
        @include bms-line-height(10vw, 30px);
        margin-top: 10px;
        color: $gray-600;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 70px;
          background-size: 50px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(#{$svg-path}/icons/ocean/ic-clouds-fa.svg);
        }
      }
      p {
        @include bms-font-size(4.8vw, 18px);
        @include bms-line-height(6vw, 26px);
        font-family: $boldFontFamily;
        a {
          color: $family-account;
          text-decoration: underline;
        }
      }

    }
  }
  .account-name {
    @include bms-base-box(true);
    padding: 10pt 5pt 20pt 5pt;
    h3 {
      @include bms-font-size(5vw, 18px);
      text-align: left;
      margin-bottom: 15px;
    }
  }
  .success {
    @include bms-base-box;
    text-align: center;
    padding: 30pt 20pt 20pt 20pt;
    h3, p {
      @include bms-font-size(5.6vw, 18px);
      @include bms-line-height(9vw, 24px);
    }
    p, .btn-family-account {
      margin-top: 20px;
    }
    h3 {
      span {
        display: block;
      }
    }
    &.dependent {
      h3 {
        @include bms-font-size(5.6vw, 24px);
        @include bms-line-height(9vw, 32px);
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 60px;
          background-size: 50px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(#{$svg-path}/icons/ocean/ic-circle-check.svg);
          margin-bottom: 10px;
        }
      }
      p {
        margin-top: 0;
      }
    }

  }
  .dashboard {
    .box {
      @include bms-base-box;
      padding: 20pt 20pt 20pt 20pt;
      h3 {
        @include bms-font-size(5vw, 18px);
      }
      a {
        color: $gray-800;
        text-decoration: underline;
      }
      p {
        @include bms-font-size(4.4vw, 14px);
        @include bms-line-height(6.5vw, 18px);
        margin-top: 20px;
      }
      .title {
        position: relative;
        display: block;
        min-height: 80px;
        background-size: 80px;
        background-repeat: no-repeat;
        background-position: 10px top;
        background-image: url(#{$svg-path}/icons/ocean/ic-people.svg);
        h3 {
          padding-top: 50px;
          border-bottom: 1px dashed $family-account;
        }
        .edit {
          position: absolute;
          top: 0;
          right: 0;
          a {
            text-decoration: none;
          }
        }
      }
      .fa-member {
        @include bms-font-size(4vw, 14px);
        @include bms-line-height(6vw, 22px);
        position: relative;
        color: $family-account;
        min-height: 30px;
        padding: 0 0 10px 0;
        border-bottom: 1px dashed $family-account;
        &.invite {
          > span {
            @include member-icon;
            display: inline-block;
            cursor: pointer;
            height: 26px;
            padding-top: 2px;
            padding-left: 40px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &.active {
          span {
            color: $gray-700;
          }
          .name {
            @include member-icon;
            text-transform: capitalize;
            display: block;
            padding: 3px 100px 0 30px;
            min-height: 25px;
          }
          .status {
            display: block;
            width: 100%;
            margin: 10px 0 0 30px;
            span {
              font-family: $regularFontFamily;
              color: $family-account;
            }
          }
          .pending {
            @include bms-font-size(3.7vw, 14px);
            position: absolute;
            top: 3px ;
            right: 0;
            padding-right: 20px;
            .smls-gray-icons {
              position: absolute;
              right: 0;
              cursor: pointer;
            }
          }
          .action {
            position: absolute;
            top: 0;
            right: 0;
            .smls-gray-icons {
              cursor: pointer;
              font-size: 9px;
              margin-left: 5px;
            }
          }
        }
        &.blocked {
          span {
            color: $gray-500;
            display: block;
            width: 100%;
            padding-left: 30px;
            &:first-child {
              margin-bottom: 5px;
              background-size: 23px;
              background-repeat: no-repeat;
              background-position: left top;
              background-image: url(#{$svg-path}/icons/gray/ic-blocked.svg);
            }
          }
        }
      }
      .action {
        display: flex;
        cursor: pointer;
        position: relative;
        &.edit {
          .ic-edit {
            top: 0;
            position: absolute;
            font-size: 8px;
            left: -25px;
          }
        }
        &.extract {
          padding-left: 25px;
          margin-bottom: 20px;
          .ic-file {
            position: absolute;
            top: 2px;
            left: 0;
          }
        }
        &.delete {
          padding-left: 25px;
          .ic-delete {
            position: absolute;
            font-size: 9px;
            left: -2px;
          }
        }
      }
      &.dependent {
        @include bms-base-box(false);
        background-color: transparent;
        padding: 0;
        .details {
          @include bms-base-box();
          width: 100%;
          background-color: $white;
          padding: 20pt;
          margin-bottom: 10px;
        }
        .name,
        .highlight {
          display: block;
          width: 100%;
          font-family: $boldFontFamily;
          color: $family-account;
        }
        .description {
          display: block;;
          @include bms-font-size(3.5vw, 12px);
          @include bms-line-height(4.5vw, 14px);
        }
        .name {
          @include member-icon;
          text-transform: capitalize;
        }
        .highlight {
          text-transform: uppercase;
          margin-top: 15px;
        }
      }
    }
    .box-extract {
      @include bms-font-size(4vw, 12px);
      @include bms-line-height(6vw, 14px);
      padding: 0 5px 0 5px;
      a {
        color: $primary;
      }
    }
  }
  .no-club {
    @include bms-base-box;
    text-align: center;
    padding: 10pt 5pt 20pt 5pt;
    h3 {
      @include bms-font-size(5.7vw, 18px);
      @include bms-line-height(9vw, 24px);
      padding: 0 20pt 0 20pt;
      color: $club;
      span {
        @include bms-font-size(7.6vw, 24px);
        @include bms-line-height(9vw, 36px);
        display: block;
      }
    }
    .info {
      display: flex;
      padding: 70px 0 35px 0;
      .calendar,
      .notebook,
      .club {
        position: relative;
        padding: 0 7pt 0 7pt ;
        @include bms-font-size(4.5vw, 16px);
        @include bms-line-height(7vw, 22px);
      }
      .calendar,
      .notebook {
        &::before {
          content: "";
          position: absolute;
          top: -70px;
          left: 0;
          display: block;
          width: 100%;
          height: 70px;
          background-size: 50px;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      .calendar {
        &::before {
          background-image: url(#{$svg-path}/icons/purple/ic-calendar-30-days.svg);
        }
      }
      .notebook {
        border-left: 1px dashed $gray-500;
        &::before {
          background-image: url(#{$svg-path}/icons/purple/ic-notebook.svg);
        }
      }
      .club {
        margin-top: -52px;
      }
    }
    &.dependent {
      .info {
        .club,
        .notebook {
          padding: 0px 5px 0 30px;
          position: relative;
        }
        .notebook {
          border-left: none;
        }
        .club {
          &::after {
            content: " ";
            position: absolute;
            display: block;
            height: 200px;
            width: 1px;
            right: 0;
            top: -10px;
            border-left: 1px solid $gray-400;
          }
        }
      }

      p {
        position: relative;
        text-align: left;
        color: $club;
        .steps-club {
          position: absolute;
          display: block;
          width: 20px;
          height: 20px;
          left: -22px;
          text-align: center;
          top: 0;
          background-color: $club;
          color: white;
          border-radius: 50%;
          line-height: 21px;
        }
      }
    }
  }
  .delete-account {
    @include bms-base-box(true);
    padding: 10pt 5pt 20pt 5pt;
    text-align: center;
    h3 {
      @include bms-font-size(5.7vw, 24px);
      @include bms-line-height(8vw, 32px);
      color: $primary;
    }
    p {
      @include bms-font-size(4.5vw, 18px);
      @include bms-line-height(6.5vw, 24px);
    }
    .box-alert {
      padding: 10pt;
      margin-bottom: 30px;
      .btn {
        margin-top: 10px;
        padding-left: 20pt;
        padding-right: 20pt;
        &.disabled {
          background-color: $gray-600;
          border-color: $gray-600;
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
  .logo-club {
    font-size: 30px;
    margin: auto auto 25px auto;
    padding: 0;
    width: 90px;
    height: 28px;
    position: relative;
    font-family: $boldFontFamily;
    letter-spacing: -1px;
    font-weight: bolder;
    color: $club;
    border-bottom: 2px solid $club;
    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 30px;
      margin: -8px auto auto auto;
      background-size: 40px;
      background-repeat: no-repeat;
      background-position: center top;
      background-image: url(#{$svg-path}/logos/smiles-purple.svg);
      background-color: $white;
    }
  }
  &__modal {
    &.only-close {
      > .modal-body {
        padding: 0 20px 40px 20px;
      }
      .modal-header {
        border-bottom: none;
      }
      .modal-footer {
        display: none;
      }
    }
    &.medium {
      .modal-dialog {
        top: 30pt;
        text-align: center;
      }
      .modal-header {
        height: 10px;
        .close {
          position: absolute ;
          top: 20px;
          right: 20px;
        }
        border-bottom: none;
      }
    }
    &.small {
      .modal-dialog {
        top: 25%;
      }
    }
    &.terms {
      .modal-title {
        @include bms-font-size(4vw, 16px);
      }
      .modal-content {
        .modal-footer {
          justify-content: center;
          .btn-primary {
            padding-left: 40pt;
            padding-right: 40pt;
          }
        }
      }
    }
    &.alert {
      * {
        text-align: center;
      }
      h5 {
        width: 100%;
      }
      .modal-header {
        border: none;
      }
      .modal-body {
        @include bms-font-size(4.5vw, 16px);
        @include bms-line-height(7vw, 22px);
      }
      .modal-footer {
        border: none;
        display: block;
      }
    }
    &.modal__color-head {
      .footer-buttons {
        .btn {
          padding-left: 60px;
          padding-right: 60px;
        }
      }
    }
    .countdown {
      padding: 40pt 15pt 0 15pt;
      background-size: 40px;
      background-repeat: no-repeat;
      background-position: center top;
      background-image: url(#{$svg-path}/icons/orange/ic-countdown.svg);
      h2 {
        @include bms-font-size(6vw, 24px);
        @include bms-line-height(9vw, 32px);
      }
      p {
        @include bms-font-size(4.5vw, 18px);
        @include bms-line-height(7vw, 24px);
      }
    }
    .edit-account {
      h2 {
        padding: 0 10pt 20pt 10pt;
        border-bottom: 1px solid $default;
        margin-bottom: 20pt;
      }
    }
    .success {
      padding: 50pt 0 0 0;
      background-size: 50px;
      background-repeat: no-repeat;
      background-position: center 10px;
      background-image: url(#{$svg-path}/icons/orange/ic-circle-check.svg);
      h2 {
        @include bms-font-size(5.6vw, 24px);
        @include bms-line-height(9vw, 32px);
      }
      p {
        @include bms-font-size(5.6vw, 18px);
        @include bms-line-height(9vw, 24px);
      }
      .txt-capitalize {
          text-transform: capitalize;
      }
      .action-buttons {
        .btn {
          padding-left: 60px;
          padding-right: 60px;
        }
      }
    }
    .close {
      color: $default;
      opacity: 1;
      font-size: 40px;
      padding: 0 10px 0 0;
    }
    .vertical-buttons {
      display: flex;
      flex-direction: column;
      button {
        margin-bottom: 10px;
      }
    }
    .footer-buttons {
      margin-top: 20px;
      padding-bottom: 30px;
      .btn {
        padding-left: 60px;
        padding-right: 60px;
      }
    }
    .txt-alert {
      @include bms-font-size(6vw, 24px);
      @include bms-line-height(8vw, 26px);
      margin-bottom: 0;
      padding: 0 10pt 0 10pt;
      font-family: $boldFontFamily;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        background-size: 50px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(#{$svg-path}/icons/orange/ic-exclamation-circle.svg);
      }
    }
    .txt-success {
      @include bms-font-size(6vw, 20px);
      @include bms-line-height(8vw, 27px);
      margin-bottom: 0;
      padding: 0 10pt 0 10pt;
      color: $primary;
      text-align: center;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        background-size: 50px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(#{$svg-path}/icons/orange/ic-circle-check.svg);
      }
    }
    .box-error {
      width: 80%;
      text-align: center;
      margin: auto ;
      h2 {
        @include bms-font-size(6vw, 24px);
        @include bms-line-height(8vw, 32px);
        padding: 0 10pt 0 10pt;
        color: $primary;
        text-align: center;
        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 50px;
          margin-bottom: 10px;
          background-size: 50px;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(#{$svg-path}/icons/orange/ic-circle-error.svg);
        }
      }
      p {
        font-family: $semiBoldFontFamily;
        font-weight: 900;
      }
    }
    .reject-invitation {
      margin-top: 20px;
      h2 {
        @include bms-font-size(7vw, 24px);
        @include bms-line-height(9vw, 32px);
      }
      .action-buttons {
        margin-top: 40px;
        .btn {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
    .text-danger {
      @include bms-font-size(4vw, 14px);
      @include bms-line-height(6vw, 18px);
      margin-top: 10px;
    }
    .password {
      text-align: left;
      @media screen and (device-aspect-ratio: 40/71) {
        transform: scale(0.95);
        margin-left: -10px;
      }
    }
  }
  .my-account__extract {
    h2, h3 {
      color: $primary;
    }
  }
}


@media (min-width: 768px) {
  .family-account {
    width: 100%;
    margin: 0 auto 25px auto;
    .start-account {
      @include bms-base-box;
      .steps {
        @include bms-base-box(false);
        &__header {
          background-position: 20px center;
          padding: 20px 50px 15px 50px;
          p {
            margin: auto auto auto 160px ;
          }
        }
        &__details {
          margin-top: 38px;
          display: flex;
          justify-content: space-around;
          .detail {
            padding: 0;
            flex-basis: 220px;
            .separator {
              .step-number {
                &::before,
                &::after {
                  display: none;
                }
              }
            }
          }
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 65%;
            height: 1px;
            margin-left: 0%;
            margin-top: 25px;
            border-top: 3px dotted lighten($family-account, 10%);
            z-index: 0;
          }
        }
      }
      .requirement {
        margin: auto;
        width: 90%;
        p,
        .btn-family-account  {
          margin-top: 20px;
        }
        .btn-outline-primary {
          width: auto;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
    .account-name {
      > .box {
        width: 370px;
        margin: 64px auto auto auto;
      }
    }
    .success {
      @include bms-base-box;
      text-align: center;
      padding: 30pt 40pt 20pt 40pt;
      h3 {
        span {
          display: inline;
        }
      }
    }
    .dashboard {
      .col-lg-4 {
        padding-right: 20px;
      }
      .box {
        min-height: 360px;
        padding: 30px 20px 20px 30px;
        .fa-member {
          background-position: left center;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
          &.active {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-image: none;
            padding-left: 0;
            .name {
              display: inline-block;
              width: 222px;
              padding: 0 10px 0 40px;
              margin: auto;
            }
            .status {
              display: inline-block;
              width: 330px;
              margin: -4px auto 0 auto;
            }
            .action {
              display: inline-block;
              text-align: right;
              width: 80px;
              margin: -8px 0 0 0;
              position: relative;
            }
            .pending {
              display: inline-block;
              position: relative;
              width: 410px;
              top: -4px ;
            }
          }
          &.blocked {
            display: flex;
            span {
              display: inline-block;
              &:first-child {
                flex-basis: 280px;
              }
              &:last-child {
                padding: 0;
              }
            }
          }
        }
        &.dependent {
          @include bms-base-box(true);
          padding: 20px;
          min-height: 135px;
          .details {
            @include bms-base-box(false);
            border: 1px solid $gray-400;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            > div {
              margin: auto 5px auto auto;
            }
            .name {
              min-width: 200px;
              width: 200px;
            }
          }
          .highlight {
            margin-top: 0;
          }
        }
      }
    }
    .no-club {
      .info {
        padding: 90px 0 45px 0;
        .calendar,
        .notebook,
        .club  {
          padding: 0 70px 0 70px ;
        }
      }
      &.dependent {
        .info {
          .club,
          .notebook {
            padding: 0 5px 0 60px;
          }
          .club {
            .logo-club {
              margin-left: 30%;
            }
            &::after {
              height: 150px;
            }
          }
          .notebook {
            &::before {
              background-size: 65px;
            }
          }
        }
        p {
          font-size: 16px;
          .steps-club {
            left: -35px;
            font-size: 18px;
            width: 30px;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .delete-account {
      h3 {
        padding: 0 30pt 0 30pt;
      }
      .box-alert {
        padding: 30px 70px 0px 70px;
        margin-bottom: 50px;
        .btn {
          padding-left: 80pt;
          padding-right: 80pt;
        }
      }
    }
    &__modal {
      .countdown {
        padding: 40px 40px 0 40px;
      }
      .edit-account {
        padding: 25px 45px 20px 45px;
        form {
          width: 80%;
          margin: auto;
        }
      }
      .success {
        // padding: 70px 80px 20px 80px;
      }
      .modal-dialog {
        max-width: 70%;
      }
      &.medium {
        .modal-dialog {
          width: 590px;
        }
      }
      &.small {
        .modal-dialog {
          width: 424px;
        }
      }
      .vertical-buttons {
        margin: 20px auto auto auto;
      }
      .txt-alert {
        padding: 0 20pt 0 20pt;
      }
      .reject-invitation {
        .action-buttons {
          .btn {
            display: inline-block;
            width: auto;
            margin-left: 20px;
          }
        }
      }
    }
    .my-account__extract {
      .my-account__extract__action__large {
        position: relative;
        .btn-block {
          width: auto;
          position: absolute;
          left: -20px;
        }
      }
    }

  }
}

