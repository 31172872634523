.tooltip-exit{

    .tooltip-inner {
        background-color: $default;
        border: 0px solid;
        border-radius: 3px;
        min-width: 220px;
        text-align: left;
        color: white;
    }

    .arrow::after, .bs-tooltip-bottom .arrow::after {
        width: 0;
        height: 0;
        border-left-color: $default;
        border-right-color: $default;
    }
}

.tooltip-confort{

    .tooltip-inner {
        background-color: #90d8d8;
        border: 0px solid;
        border-radius: 3px;
        min-width: 220px;
        text-align: left;
        color: white;
    }

    .arrow::after, .bs-tooltip-bottom .arrow::after {
        width: 0;
        height: 0;
        border-left-color:  #90d8d8;
        border-right-color:  #90d8d8;
    }

    .arrow::before, .bs-tooltip-bottom .arrow::before {
        width: 0;
        height: 0;
        border-left-color:  #90d8d8;
        border-right-color:  #90d8d8;
    }
}