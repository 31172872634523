.container.one-column.white.container-reactive {
  background-color: $gray-200 !important;
  .text-club {
    color: $club;
  }
  .text-gray {
    color: $gray-800;
    font-size: 13px;
    font-family: $boldFontFamily;
  }
  .club-smiles {
    &__reactive {
      padding: 35px;
      margin: 50px;
      background-color: $white;
      box-shadow: 0px 0px 5px $gray-400;

      div {
        h2 {
          padding: 0 40px;
          font-size: 24px;
          color: $club;
          font-family: $boldFontFamily;
        }

        p.info-reactive {
          font-size: 16px;
          // font-family: $boldFontFamily;
          margin-bottom: 30px;
          margin-top: 20px;
          line-height: 20px;
        }

        span {
          color: $gray-600;
          font-family: $boldFontFamily;
          font-weight: 300;
          &:before {
            background-image: url(#{$svg-path}/icons/ui/ic-input-radio-purple.png);
          }
        }

        .annual {
          width: 80%;
          margin: auto;
          margin-top: 10px;
          display: flex;
          align-content: flex-end;
          flex-direction: row-reverse;

          button {
            background-color: $club;
          }
        }

        table {
          font-family: $boldFontFamily;
          width: 100%;
          border: 1px solid $gray-300;
          margin: auto;
          font-size: 14px;
          padding: 10px;
          display: block;

          tbody {
            display: table;
            width: 100%;

            tr {
              border-bottom: 1px solid $gray-300;

              &:last-child {
                border-bottom: none;
              }

              td {
                width: 24%;
                text-align: right;
                padding: 15px 0;

                &:first-child {
                  text-align: left;
                  width: 75%;
                }
              }
            }
          }
        }

        .method-payment {
          font-size: 24px;
          font-family: $boldFontFamily;
          margin: 20px auto;
          color: $club;
        }

        .payment-type {
          display: flex;
          justify-content: space-evenly;
          padding: 30px;
          border: 1px solid $gray-300;
          margin-top: 30px;
        }

        .payment {
          width: 100%;
          border: 1px solid $gray-300;
          display: flex;
          justify-content: center;
          align-items: center;

          &.debt-info {
            gap: 30px;
          }

          &__debt {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 0 auto;

            button {
              width: 170px;
              margin-bottom: 15px;
            }

            span {
              width: 70%;
              text-align: center;
            }
          }

          div.tarjeta {
            width: calc(100% - 20px);
            margin: 0 10px 20px 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid $gray-300;

            h3 {
              font-size: 16px;
              color: $gray-700;
              font-family: $boldFontFamily;
              padding: 20px 0 0 0;
              text-align: center;
            }

            i.icon-credit-card {
              background-image: url(../images/svg/icons/gray/ic-creditcard-default.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              width: 212px;
              height: 212px;
              display: block;
              margin: auto;
            }

            span {
              display: inline-block;
              margin: auto;
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-family: $boldFontFamily;
              margin-top: -20px;
            }

            label {
              display: inline-block;
              margin: auto;
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-family: $ligthFontFamily;
            }

            button {
              display: block;
              margin: 10px auto 0 auto;
              border-radius: 3px;
              background: $gray-700;
              color: $white;
              border: none;
              font-size: 12px;
              padding: 7px 50px;
              height: inherit;
              line-height: inherit;
            }
          }

          div.new-tarjeta {
            width: 100%;

            h3 {
              font-size: 16px;
              color: $gray-700;
              font-family: $boldFontFamily;
              padding: 20px 0;
              text-align: center;
            }

            &.debt {
              position: relative;
              border-right: none;

              &::before {
                content: none;
              }

              &::after {
                content: "";
                width: 0px;
                height: 0px;
                border-right: 2px solid $gray-300;
                border-top: 2px solid $gray-300;
                position: absolute;
                transform: rotate(45deg);
                right: -11px;
                top: 50%;
                background: transparent;
              }
            }

            .frm-default {
              width: calc(100% - 20px);
              margin: auto;

              .credit-card {
                .row {
                  label.form-check-label {
                    position: relative;
                    margin-top: 20px;
                    font-family: $boldFontFamily;

                    span {
                      padding-left: 20px;
                    }
                  }

                  div.text-security {
                    p {
                      margin-bottom: 10px;
                      font-size: 10px;
                      line-height: 12px;

                      strong {
                        font-family: $boldFontFamily;
                        font-size: 13px;
                      }
                    }
                  }

                  .card-number {
                    position: relative;

                    .smls-credit-cards {
                      position: absolute;
                      width: 40px;
                      height: 40px;
                      top: 6px;
                      right: 8px;
                    }
                  }
                }
              }
            }

            button {
              display: block;
              margin: 20px auto;
              border-radius: 3px;
              background: $club;
              color: $white;
              border: none;
              font-size: 12px;
              padding: 7px 50px;
              height: inherit;
              line-height: inherit;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      &__reactive {
        div {
          h2 {
            padding: 0;
          }

          p.info-reactive {
            line-height: 20px;
            margin-bottom: 70px;
          }

          table {
            padding: 28px;
            font-size: 14px;

            tbody {
              display: table;
              width: 100%;
            }
          }

          .method-payment {
            margin: 50px auto;
          }

          div.payment {
            border: 1px solid $gray-300;
            height: 570px;
            margin: auto;
            position: relative;

            div.tarjeta {
              width: 50%;
              float: left;
              margin: 0;
              border-bottom: none;

              h3 {
                padding: 10px 0;
              }

              button {
                margin: 50px auto 0 auto;
              }

              &::before {
                content: "";
                border-right: 1px solid #ececec;
                width: 1px;
                height: 45%;
                position: absolute;
                left: 50%;
              }

              &::after {
                content: "";
                border-right: 1px solid #ececec;
                width: 1px;
                height: 45%;
                position: absolute;
                left: 50%;
                top: 55%;
              }
            }

            div.new-tarjeta.debt {
              width: 100%;
            }

            div.new-tarjeta {
              width: 50%;
              float: left;

              h3 {
                padding: 10px 0;
                margin-top: -4rem;
                margin-bottom: 2rem;
              }

              .frm-default {
                width: 356px;
              }

              button {
                margin: 50px auto 0 auto;
              }

              &::before {
                content: "";
                width: 1px;
                height: 45%;
                position: absolute;
                left: 50%;
                top: 48%;
                margin-left: -5px;
                color: $gray-500;
              }
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      &__reactive {
        margin: 5% 15px;

        div.payment {
          flex-direction: column;
        }
      }
    }
  }

  .payment-container.payment-options {
    margin-top: 0px;
    border-radius: 3px;
    border: 1px solid #ccc;

    .payment-options-container {
      padding: 10px 0px 30px 0px;

      .label-payment-option {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .input-option-payment {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked {
            ~ {
              .checkmark {
                background-color: $white;

                &:after {
                  display: block;
                }
              }
            }
          }
        }

        &:hover {
          .input-option-payment {
            ~ {
              .checkmark {
                background-color: $gray-300;
              }
            }
          }
        }

        .checkmark {
          &:after {
            left: 8px;
            top: 3px;
            width: 5px;
            height: 12px;
            border: solid $gray-900;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .checkmark {
        position: absolute;
        top: 22px;
        left: -40px;
        height: 22px;
        width: 22px;
        border: 1px solid $gray-500;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
  }
}
