.home {
    .featured {
        position: relative;
        width: 623px;
        max-width: 623px;
        height: 350px;
        overflow: hidden;
        &:not(:last-child) {
            margin-right: 32px;
            margin-bottom: 32px;
        }
        &.white {
            .content {
                display: table;
                width: 100%;
                height: 350px;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    max-height: 300px;
                    height: 300px;
                }
                > div {
                    display: table-row;
                    width: 100%;
                    &:first-child {
                        height: 10%;
                    }
                    &:last-child {
                        height: 90%;
                    } 
                    > div {
                        display: table-cell;
                        &:last-child {
                            padding-left: 20px;
                        } 
                    }
                }
                * {
                    color: $white;
                }
                p {
                    @include bms-font-size(12px,18px);
                    @include bms-line-height(16px,26px);
                    font-family: $ligthFontFamily; 
                } 
                .line {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-left: 1px solid $white;
                    border-bottom: 1px solid $white;
                    border-bottom-left-radius : 20px  
                }
                .smls-white-icons {
                    font-size: 20px;
                }
            }
        }
        .img-background {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
        }
        .content {
            position: absolute;
            z-index: 1;
            text-align: left;
            padding: 40px;
        }
    } 
}