.checkout-sucess-congenere, .checkout-success-cancel {
  .flight {
    width: 100%;
    max-width: 100%;
    margin: 50px auto 0 auto;
    border: 2px solid $gray-300;
    border-radius: 0px;
  }

  .travel-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    &.easy-travel {
      border: 2px solid $gray-300;
    }
    
    h4 {
      color: $gray-800;
      text-transform: uppercase;
      background-color: $gray-300;
      margin: 0;
      font-size: 14px;
      padding: 20px 10px;
      width: 100%;
      text-align: left;
    }

    .flight-details {
      margin-top: -50px;
      width: 100%;
      
      .flight {
        max-width: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .checkout-sucess-congenere, .checkout-success-cancel {

    .visible-phone {
      display: none;
    }

    .visible-desktop {
      display: inline-block;
    }

    .flight {
      max-width: 99%;
    }
  }
}
