.smls-white-icons {
    font-size: 10px;
}

.smls-white-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-white-icons.svg");
    background-repeat: no-repeat;
    background-size: 95.95em 506.3em;
}

.no-svg .smls-white-icons:before {
    background-image: url("../sprites/smls-white-icons.png");
}



  .smls-white-icons.ic-add:before {
      background-position: 0em 0em;
      width: 44.8em;
      height: 44.8em;
  }




  .smls-white-icons.ic-airplane:before {
      background-position: 0em -44.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-alert-error:before {
      background-position: 0em -46.4em;
      width: 5em;
      height: 5em;
  }




  .smls-white-icons.ic-arrow-back:before {
      background-position: 0em -51.4em;
      width: 3.177em;
      height: 1.7492em;
  }




  .smls-white-icons.ic-arrow-down:before {
      background-position: 0em -53.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-arrow-left-right:before {
      background-position: 0em -54.8em;
      width: 2em;
      height: 2em;
  }




  .smls-white-icons.ic-arrow-left-sm:before {
      background-position: 0em -56.8em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-white-icons.ic-arrow-right:before {
      background-position: 0em -58.4em;
      width: 16.2em;
      height: 2.897em;
  }




  .smls-white-icons.ic-arrow-right-large:before {
      background-position: 0em -61.3em;
      width: 2em;
      height: 2em;
  }




  .smls-white-icons.ic-arrow-right-sm:before {
      background-position: 0em -63.3em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-white-icons.ic-arrow-up:before {
      background-position: 0em -64.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-back-forth-arrows:before {
      background-position: 0em -66.5em;
      width: 1.6em;
      height: 0.643em;
  }




  .smls-white-icons.ic-badge-diamond:before {
      background-position: 0em -67.2em;
      width: 2.3em;
      height: 2.3em;
  }




  .smls-white-icons.ic-calendar-check:before {
      background-position: 0em -69.5em;
      width: 2em;
      height: 2em;
  }




  .smls-white-icons.ic-card:before {
      background-position: 0em -71.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-check:before {
      background-position: 0em -73.1em;
      width: 5.01em;
      height: 3.5em;
  }




  .smls-white-icons.ic-check-mark:before {
      background-position: 0em -76.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-check-success:before {
      background-position: 0em -78.2em;
      width: 5em;
      height: 5em;
  }




  .smls-white-icons.ic-close:before {
      background-position: 0em -83.2em;
      width: 51.2em;
      height: 51.2em;
  }




  .smls-white-icons.ic-close-plain:before {
      background-position: 0em -134.4em;
      width: 32.9em;
      height: 32.9em;
  }




  .smls-white-icons.ic-coins-one-stack:before {
      background-position: 0em -167.3em;
      width: 2.1243em;
      height: 2.8884em;
  }




  .smls-white-icons.ic-coins-two-stacks:before {
      background-position: 0em -170.2em;
      width: 4.7em;
      height: 4.0834em;
  }




  .smls-white-icons.ic-departure:before {
      background-position: 0em -174.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-diamond:before {
      background-position: 0em -175.9em;
      width: 51.2em;
      height: 51.2em;
  }




  .smls-white-icons.ic-diamond-client:before {
      background-position: 0em -227.1em;
      width: 3.4em;
      height: 2.5em;
  }




  .smls-white-icons.ic-discount:before {
      background-position: 0em -229.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-easy-travel:before {
      background-position: 0em -231.2em;
      width: 15em;
      height: 15em;
  }




  .smls-white-icons.ic-equal:before {
      background-position: 0em -246.2em;
      width: 95.95em;
      height: 95.95em;
  }




  .smls-white-icons.ic-exclamation-mark:before {
      background-position: 0em -342.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-filelist:before {
      background-position: 0em -343.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-filter-solid:before {
      background-position: 0em -345.4em;
      width: 51.2em;
      height: 51.2em;
  }




  .smls-white-icons.ic-flight-back:before {
      background-position: 0em -396.6em;
      width: 3.2em;
      height: 2.3em;
  }




  .smls-white-icons.ic-flight-going:before {
      background-position: 0em -398.9em;
      width: 3.2em;
      height: 2.2969999999999997em;
  }




  .smls-white-icons.ic-gear:before {
      background-position: 0em -401.2em;
      width: 7.5em;
      height: 7.5em;
  }




  .smls-white-icons.ic-globe:before {
      background-position: 0em -408.7em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-white-icons.ic-gold-client:before {
      background-position: 0em -411.1em;
      width: 3.2em;
      height: 3.2em;
  }




  .smls-white-icons.ic-hyperlink:before {
      background-position: 0em -414.3em;
      width: 3.087em;
      height: 3.049em;
  }




  .smls-white-icons.ic-login:before {
      background-position: 0em -417.4em;
      width: 5.6em;
      height: 4.6204em;
  }




  .smls-white-icons.ic-logout:before {
      background-position: 0em -422.1em;
      width: 3em;
      height: 3em;
  }




  .smls-white-icons.ic-luggage-diamond:before {
      background-position: 0em -425.1em;
      width: 3.811em;
      height: 1.3599999999999999em;
  }




  .smls-white-icons.ic-luggage-solid:before {
      background-position: 0em -426.5em;
      width: 2.07em;
      height: 1.6em;
  }




  .smls-white-icons.ic-menu-bars:before {
      background-position: 0em -428.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-plane-smile:before {
      background-position: 0em -429.7em;
      width: 4.96em;
      height: 2.259em;
  }




  .smls-white-icons.ic-refresh:before {
      background-position: 0em -432em;
      width: 2.0042em;
      height: 1.5161em;
  }




  .smls-white-icons.ic-right-arrow-paging:before {
      background-position: 0em -433.6em;
      width: 0.77em;
      height: 1.33em;
  }




  .smls-white-icons.ic-search:before {
      background-position: 0em -435em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-smiles-connector:before {
      background-position: 0em -436.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.ic-star-outline-smile:before {
      background-position: 0em -438.2em;
      width: 4.96em;
      height: 2.859em;
  }




  .smls-white-icons.ic-star-solid-smile:before {
      background-position: 0em -441.1em;
      width: 4.96em;
      height: 2.859em;
  }




  .smls-white-icons.ic-stars-bordered:before {
      background-position: 0em -444em;
      width: 7.6em;
      height: 2.6762em;
  }




  .smls-white-icons.ic-ticket:before {
      background-position: 0em -446.7em;
      width: 3em;
      height: 2.1em;
  }




  .smls-white-icons.ic-timer:before {
      background-position: 0em -448.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-white-icons.icono-mgm:before {
      background-position: 0em -450.4em;
      width: 5.6em;
      height: 4.6886em;
  }




  .smls-white-icons.not-allowed:before {
      background-position: 0em -455.1em;
      width: 51.2em;
      height: 51.2em;
  }





