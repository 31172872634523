.my-cards {
  @include bms-font-size(3vw, 14px);
  @include bms-line-height(4vw, 18px);
  @include bms-base-box;
  position: relative;
  width: 90vw;
  height: auto;
  min-height: 100px;
  padding: 0 9pt 9pt 9pt;
  margin: 0 auto 16pt auto !important;
  font-family: $regularFontFamily;
  color: $gray-700;

  &.spacer {
    background-color: transparent;
    box-shadow: none;
  }

  p {
    font-family: $ligthFontFamily;
    margin-left: 5px;
    margin-top: 10px;
    @include bms-font-size(4vw, 14px);
    @include bms-line-height(6vw, 16px);
    font-weight: 300;
  }

  &.change-plan-in-progress {

    height: auto;
    padding: 0;

    .processing-banner {
      background-color: $club;
      margin: 0;
      padding: 20px;
      h5 {
        color: $white;
      }
      p {
        color: $white;
        margin: 0;
      }
    }
  }

  &.club {
    .row {
      height: 60px;

      h3 {
        border-top-color: $club !important;
        color: $club !important;
        font-size: 30px !important;
        font-family: $boldFontFamily;
        letter-spacing: -1px;
        float: left;
        padding-top: 5px !important;
      }
      small {
        background-image: url(../images/svg/logos/smiles-purple.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 36px;
        height: 40px;
        position: relative;
        display: inline-block;
        margin-top: 20px;
        margin-left: -44px;

        &::after {
          content: "";
          position: absolute;
          height: 1px;
          border-bottom: 1px solid $club;
          top: 16px;
          width: 15px;
          right: 100%;
          margin-right: 3px;
          box-shadow: 0px 0px 2px 0px rgba(102, 51, 153, 1);
        }

        &::before {
          content: "";
          position: absolute;
          height: 1px;
          border-bottom: 1px solid $club;
          top: 16px;
          width: 15px;
          left: 100%;
          margin-left: 3px;
          box-shadow: 0px 0px 2px 0px rgba(102, 51, 153, 1);
        }
      }
      a {
        text-decoration: underline;
        float: right;
        font-size: 14px;
        color: $club;
        margin: 5px 0;
      }
    }
  }

  &.ocean {
    .row {
      &:first-child {
        h3 {
          color: $family-account;
          border-top-color: $family-account;
        }
      }
    }
  }

  button {
    &.btn {
      position: absolute;
      right: 10px;
      bottom: 10px;
      padding: 0 10px;
      @include bms-font-size(3vw, 12px);
      height: 32px;
      min-width: 108px;
      max-width: none;
      width: auto;
      font-weight: 100;
      font-family: $regularFontFamily;
      &.btn-primary {
        background-color: $primary;
      }
    }
  }
  .row {
    display: block;
    &:first-child {
      h3 {
        @include bms-font-size(4vw, 14px);
        padding: 0;
        margin: 0 0 0 5px;
        font-weight: 700;
        line-height: 25px;
        display: inline-block;
        width: auto;
        border-top: 2px solid $primary;
      }
    }
  }
  .row-footer {
    position: relative;
    margin-top: 5px;
    height: 40px;
    width: 100%;
    .btn {
      right: 0;
      bottom: 0;
    }
  }
}

.my-account {
  small {
    display: block;
    margin-left: 5px;
    @include bms-font-size(3vw, 10px);
    @include bms-line-height(4vw, 14px);
    font-weight: 900 !important;
  }
  &__information {
    button {
      &.btn-primary {
        position: relative;
        margin: 5px 0;
        bottom: auto;
        right: auto;
      }
    }
    strong {
      display: block;
      margin-left: 5px;
      @include bms-font-size(8vw, 24px);
      @include bms-line-height(9vw, 28px);
      color: $primary;
      font-weight: 700;
    }
    span {
      display: block;
      margin: 0 5px 3px 5px;
      @include bms-font-size(4vw, 14px);
      @include bms-line-height(6vw, 18px);
      color: $gray-700;
    }
    ul {
      position: relative;
      width: 100%;
      text-align: right;
      margin: -35px auto 3px auto;
      padding: 0;
      li {
        @include bms-font-size(3vw, 12px);
        @include bms-line-height(6vw, 18px);
        a {
          color: $gray-700;
          text-decoration: underline;
        }
      }
    }
    .box-number {
      small {
        font-weight: 100 !important;
      }
      span {
        font-family: $boldFontFamily;
        @include bms-font-size(4vw, 16px);
      }
    }
    .btn-primary {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    > .btn-primary {
      margin-bottom: 0;
    }
  }
  &__category {
    &-card,
    &-status {
      float: none;
      width: 100%;
      height: auto;
    }
    &-card {
      text-align: center;
      border: 1px solid $gray-200;
      padding-bottom: 10px;
      a {
        display: none;
      }
      strong {
        &::before {
          content: " ";
          display: block;
          margin-top: 40px;
          margin-bottom: 5px;
          width: 100%;
          height: 50px;
          background-size: 80px;
          background-position: center center;
          background-repeat: no-repeat;
        }
      }
    }

    &.diamante {
      .my-account__category-card {
        strong {
          color: $diamond;
          &::before {
            background-image: url(#{$svg-path}/categories/ic-card-diamond.svg);
          }
        }
      }

      .my-account__category-status {
        .bar {
          background: rgb(234, 234, 234); /* Old browsers */
          background: -moz-linear-gradient(
            left,
            rgba(234, 234, 234, 1) 0%,
            rgba(0, 0, 0, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(234, 234, 234, 1) 0%,
            rgba(0, 0, 0, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(234, 234, 234, 1) 0%,
            rgba(0, 0, 0, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
        }
      }
    }

    &.ouro {
      .my-account__category-card {
        strong {
          color: $gold;
          &::before {
            background-image: url(#{$svg-path}/categories/ic-card-gold.svg);
          }
        }
      }
      .my-account__category-status {
        .bar {
          background: rgb(229, 216, 174); /* Old browsers */
          background: -moz-linear-gradient(
            left,
            rgba(229, 216, 174, 1) 0%,
            rgba(169, 140, 93, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(229, 216, 174, 1) 0%,
            rgba(169, 140, 93, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(229, 216, 174, 1) 0%,
            rgba(169, 140, 93, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5d8ae', endColorstr='#a98c5d',GradientType=1 ); /* IE6-9 */
        }
      }
    }

    &.prata {
      .my-account__category-card {
        strong {
          color: $silver;

          &::before {
            background-image: url(#{$svg-path}/categories/ic-card-silver.svg);
          }
        }
      }
      .my-account__category-status {
        .bar {
          background: rgb(238, 238, 238); /* Old browsers */
          background: -moz-linear-gradient(
            left,
            rgba(238, 238, 238, 1) 0%,
            rgba(152, 150, 151, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(238, 238, 238, 1) 0%,
            rgba(152, 150, 151, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(238, 238, 238, 1) 0%,
            rgba(152, 150, 151, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#989697',GradientType=1 ); /* IE6-9 */
        }
      }
    }

    &.smiles {
      .my-account__category-card {
        strong {
          color: $smiles;
          &::before {
            background-image: url(#{$svg-path}/categories/ic-card-smiles.svg);
          }
        }
      }
      .my-account__category-status {
        .bar {
          background: -moz-linear-gradient(
            left,
            rgba(244, 121, 32, 0.62) 0%,
            rgba(255, 90, 0, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(244, 121, 32, 0.62) 0%,
            rgba(255, 90, 0, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(244, 121, 32, 0.62) 0%,
            rgba(255, 90, 0, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9eFF5A00', endColorstr='#ff5a00',GradientType=1 ); /* IE6-9 */
        }
      }
    }

    &-status {
      margin-top: 25px;
      > div {
        width: 100%;
      }

      .title {
        p {
          position: relative;
          font-family: $regularFontFamily;
          color: $gray-700;
          margin: 0 0 8px 20px;
          @include bms-font-size(3.5vw, 12px);
          @include bms-line-height(4.5vw, 14px);
          font-weight: 900;
          span.smls-gray-icons {
            position: absolute;
            top: -2px;
            left: -20px;
          }
        }
      }
      .divider {
        text-align: center;
        height: 25px;
        padding-top: 5px;
        span {
          display: inline-block;
          position: relative;
          &:before,
          &:after {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 1px solid $gray-500;
            top: 0;
            width: 120px;
          }
          &:before {
            right: 100%;
            margin-right: 15px;
          }
          &:after {
            left: 100%;
            margin-left: 15px;
          }
        }
      }

      .categories,
      .quantity {
        margin: 0;
        padding: 0;
        display: table;
        width: 100%;
        position: relative;
        height: 15px;
        span {
          display: table-cell;
          vertical-align: middle;
          text-transform: uppercase;
          font-family: $boldFontFamily;
          @include bms-font-size(3vw, 10px);
          @include bms-line-height(6vw, 10px);
          &:not(:last-child) {
            width: 25%;
            text-align: left;
          }
          &:last-child {
            text-align: right;
            //position: absolute;
            //right: 0;
          }
        }
      }
    }
  }
  &__club-smiles {
    div {
      float: none;
      height: 180px;

      &.plan {
        font-family: $boldFontFamily;
        width: 100%;
        border-bottom: 1px solid $club;
        border-left: none;
        font-size: 14px;
        color: $club;
        padding: 20px 5px 0 5px;
        * {
          float: left;
          clear: both;
        }
        div {
          width: 100%;
          height: 75px;
          font-size: 25px;
          text-align: center;
          color: $white;
          background-color: $club;
          padding: 28px 0;
        }
        small {
          color: $gray-600;
          margin: 10px 0;
          font-size: 12px;
          font-family: $regularFontFamily;
          margin-top: 20px;
        }
        a {
          text-decoration: underline;
          color: $club;
          clear: none;
          margin: 20px 0 0 10px;
        }
      }
      &.status {
        font-family: $boldFontFamily;
        width: 305px;
        font-size: 14px;
        color: $club;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0 0 50px;
        * {
          float: left;
          clear: both;
        }
        label {
          margin-bottom: 14px;
        }
        span {
          font-size: 20px;
          text-transform: uppercase;
          position: relative;
          &.active {
            padding-left: 30px;
            &::before {
              content: "";
              background-image: url(#{$svg-path}/icons/purple/ic-check.svg);
              background-repeat: no-repeat;
              background-position: 0 0;
              position: absolute;
              background-size: 20px;
              width: 20px;
              height: 20px;
              left: 0;
              top: -3px;
            }
          }
          &.suspended {
            color: #e74646;
            position: relative;
            padding-left: 30px;
            display: flex;
            &::before {
              content: "!";
              border: 1px solid #e74646;
              width: 24px;
              height: 24px;
              border-radius: 20px;
              position: absolute;
              left: 0;
              top: -5px;
              text-align: center;
              padding: 3px;
            }
            a {
              margin-top: 0px;
              padding-left: 5px;
            }
          }
        }
        small {
          color: $gray-600;
          font-size: 12px;
          margin: 0;
          margin-top: 28px;
          font-family: $regularFontFamily;
        }
        a {
          font-size: 14px;
          align-self: flex-end;
          margin-top: 18px;
          text-transform: initial;
          text-decoration: underline;
        }

        button {
          position: inherit;
          background-color: $club;
          border-color: $club;
          font-weight: 700;
        }
      }
      &.actions {
        display: flex;
        justify-content: space-around;
      }
    }
  }
  &__miles-to-expire {
    p {
      margin-bottom: 40px;
    }

    .box-message {
      display: table;
      width: 100%;
      height: 150px;
      text-align: center;
      border: 1px solid $gray-200;
      border-top: none;
      p {
        display: table-cell;
        vertical-align: middle;
        margin: 20px auto;
        font-family: $ligthFontFamily;
        @include bms-font-size(4vw, 14px);
      }
    }

    .default-table {
      .ic-warning {
        @include bms-font-size(2vw, 8px);
        &:before {
          margin: -5px 5px 0 -5px;
        }
      }
      tr {
        th,
        td {
          &:last-child {
            padding: 0;
            width: 30%;
          }
        }
        .empty {
          min-height: 36px;
          display: block;
        }
      }
    }
  }
  &__request-miles {
    .airlines {
      margin-top: 5px;
      position: relative;
      height: 100px;
      strong {
        display: none;
      }
      .logo-featured {
        width: 100%;
        height: 82px;
        float: none;
        padding-top: 15px;
        text-align: center;
        .gol {
          display: block;
          height: 40px;
          width: 100px;
          margin: 0 auto;
        }
      }
      .carousel {
        width: 100%;
        height: 45px !important;
        border-radius: 3px;
        border: 1px solid $gray-500;
        float: right;
        margin-bottom: 15px;
        padding: 0 !important;
        .carousel-inner {
          .carousel-item {
            padding-left: 35px;
            .group-items {
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 90%;
              height: 45px;
              span {
                margin: auto;
                flex-basis: auto;
                text-align: center;
              }
            }
            .logo-airlines {
              display: block;
              height: 20px;
              width: 80px !important;
              margin: 0 3px;
            }
          }
        }
        .carousel-control-prev {
          left: 0;
          > span {
            position: absolute;
            left: 5px;
          }
        }
        .carousel-control-next {
          right: 0;
          > span {
            position: absolute;
            right: 5px;
          }
        }
      }
    }
  }
  &__favorite-passengers {
    .row-footer {
      label {
        font-size: 14px;
        margin: 0px 0 0 5px;
        line-height: 18px;
        font-family: $boldFontFamily;
      }
    }
  }
  &__flights {
    padding-bottom: 40px;
    .smls-gray-icons.ic-clouds {
      display: block;
      width: 100%;
      height: 100px;
      text-align: center;
      @include bms-font-size(18vw, 54px);
    }
    strong {
      @include bms-font-size(3.5vw, 12px);
      @include bms-line-height(4.5vw, 14px);
      color: $gray-700;
    }
    .default-table {
      tr {
        th,
        td {
          overflow: hidden !important;
          &:first-child {
            padding: 8px 0 8px 8px !important;
            width: 75%;
            text-align: left;
          }
          &:last-child {
            padding: 8px 8px 8px 0 !important;
            width: 25%;
            text-align: right;
          }
        }
      }
      tbody {
        tr {
          height: 60px !important;
        }
      }
    }
    .box-leg {
      position: relative;
      width: 50%;
      float: left;
      small {
        margin-left: 0;
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ic-od-arrow {
        margin-left: 5px;
        position: absolute;
        right: 10px;
        top: -2px;
        @include bms-font-size(6vw, 20px);
      }
    }
    .box-date {
      height: 100%;
      small {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }
  &__miles-action-frame {
    padding-bottom: 10px;
    background-color: $gray-200;
    flex: 1;
    margin: 0 0 0 10px;
    &:first-child {
      margin: 0 10px 0 0;
    }


    h4 {
      font-size: 14px;
      margin: 9px auto;
      text-align: center
    }


    .miles-purchase {
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      height: 192px;
    }

    a {
      display: block;
      width: 100%;
      position: relative;
      text-align: center;
    }

    button.btn {
      position: relative !important;
      right: unset;
      bottom: unset;
      margin: auto auto 20px;
      border: none;
    }


    p {
      margin: 10px 10% 10px;
      text-align: center;
    }

    .limit {
      background-color: white;
      margin: 0 10% 0;
      padding: 10px 20px;
      line-height: 2.8em;
      vertical-align: middle;
      text-align: center;

      span {
        font-size: 1.7em;
        line-height: 1.7em;
        padding-left: 10px;
        vertical-align: bottom;
      }
    }
  } // miles-purchase
  &__family-account {
    img {
      width: 100%;
    }
    .box {
      margin-top: 30px;
      font-size: 14px;
      padding: 11px;
      text-align: center;
      background-color: $gray-200;
      border-radius: 4px;
      span {
        font-family: $boldFontFamily;
        color: $easy-travel;
      }
    }
  } // family-account
}

.my-account-purple {
  .bg-p {
    background-image: url(https://cosmic-s3.imgix.net/1f0eebb0-767a-11e9-a75a-c36e3f7dfaf1-accession-club-smiles-promo.jpg);
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    float: left;
    min-height: 120px;
    background-size: 100%;
    background-position: 0;

    .opacity-purple {
      background: linear-gradient(to bottom, rgba(2, 1, 1, 0), #663399);
      min-height: 120px;
    }
  }

  .row:first-child h3 {
    border-top: 2px solid #639;
    color: #639;
  }

  .purple {
    background-color: #639;
  }
  p {
    margin: 5px auto;
    font-size: 12px;
    float: none;
  }
}

.progress-bar {
  border: 1px solid $gray-400;
  border-radius: 12px;
  width: 100%;
  height: 12px;
  overflow: hidden;

  .progress {
    width: 100%;
    margin: 0;

    .bar {
      border: 1px solid $white;
      border-radius: 12px;
      width: 98%;
      height: 10px;
      background-image: none;
    }
  }
}

@media (max-width: 767px) {

  .my-account {

    &__club-smiles {
      div {

        &.status {
          width: auto;
          padding: 20px 10px 10px;
        }

      }
    }

  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .my-account-purple {
    p {
      float: left;
    }
  }
  .my-cards {
    float: left;
    width: 266px;
    height: 240px;
    margin-right: 28px !important;
    margin-bottom: 28px !important;
    overflow: hidden;

    &.two-cols {
      width: 560px;
    }
    .row-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      .btn {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
    .my-account__voucher {
      .row-footer {
        position: relative;
      }
    }
  }
  .my-account {
    &__information {
      ul {
        text-align: left;
        margin: 5px;
        li {
          margin-bottom: 5px;
        }
      }
    }
    &__category {
      position: relative;

      &-card,
      &-status {
        float: left;
      }

      &-card {
        width: 152px;
        height: 198px;
        a {
          display: block;
          margin-top: 15px;
          text-decoration: underline;
          color: $gray-700;
          @include bms-font-size(3vw, 10px);
          @include bms-line-height(4vw, 14px);
        }
      }

      &-status {
        margin-top: 0;
        margin-left: 30px;
        width: 330px;
        height: 115px;
      }
    }

    &__club-smiles {

      div {
        float: left;
        height: 150px;
        &.plan {
          width: 230px;
          border-right: 1px solid $club;
          border-bottom: none;
          padding: 0 5px;
          div {
            width: 150px;
          }
          a {
            clear: both;
            margin: 0px 0 0 0;
          }
          small {
            margin-top: 10px;
          }
        }

        &.status {
          padding: 10px;
        }

      }
    }
    &__miles-action-frame {
      padding-bottom: 0px;
    }

    &__request-miles {
      .airlines {
        margin-top: 5px;
        position: relative;
        height: 100px;

        .logo-featured {
          position: relative;
          width: 120px;
          height: 82px;
          float: left;
          .gol {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          }
        }

        .carousel {
          width: 393px;
          height: 82px !important;
          float: right;
          .carousel-inner {
            .carousel-item {
              padding-left: 35px;
              .group-items {
                width: 320px;
                height: 80px;
              }
              .logo-airlines {
                width: 100px !important;
                height: 30px;
                &.smaller {
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
    &__favorite-passengers {
      .row-footer {
        label {
          margin: -10px 0 0 15px;
          font-family: $regularFontFamily;
        }
      }
    }
    &__flights {
      .box-leg {
        small {
          width: 80px;
        }
      }
      .default-table {
        tr {
          th,
          td {
            &:first-child {
              padding: 8px 0 8px 5px !important;
            }
            &:last-child {
              padding: 8px 5px 8px 0 !important;
            }
          }
        }
      }
    }
  }
}

.profileChange-adjuntarDocumento {
  text-decoration: underline;
  cursor: pointer;
}

.profileChange-fileName {
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
}

.user-suspended {

  .modal-title {
    font-size: 27px;
    line-height: 32px
  }

  .modal-suspended {
    font-family: $boldFontFamily;
    font-size: 24px;
    text-align: center;
    line-height: 29px;
  }

  .modal-content {
    width: 571px;
    height: 240px;
    margin-top: 50%;
  }

  .close {
    font-size: 2rem !important;
  }

  @media (max-width: 767px) {

    .modal {
      margin-top: 75px;
    }

    .modal-content {
      width: 95%;
      height: 224px;
      margin: 5px auto 0;
    }

    .modal-suspended {
      font-family: $boldFontFamily;
      font-size: 18px;
      text-align: center;
      line-height: 22px;
    }

  }

}

.flight-voucher-buttons-row.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  padding-top: 15px;

  .btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $white;;
    background-color: $primary;
    border: 1px solid $primary;
  }
  @media (max-width: 768px) {
    display: block;
    width: 100%;

    .btn {
      display: block !important;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
