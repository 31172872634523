.smiles {
    &__my-club {
        &__cancel {
            margin-top: 30px;
            &__info {
                background-color: $white;
                @include bms-base-box;
                position: relative;
                padding: 20px 5px;
                h3 {
                    font-size: 22px;
                    color: $club;
                    text-align: center;
                }
                p {
                    text-align: center;
                    font-size: 14px;
                    max-width: 590px;
                    margin: auto;
                    padding: 10px;
                }
                &__list {
                    width: 100%;
                    margin: 0;
                    p {
                        text-align: center;
                        padding: 10px 20px;
                    }
                    ul {
                        padding: 0;
                        li {
                            position: relative;
                            list-style: none;
                            font-size: 14px;
                            font-family: $boldFontFamily;
                            line-height: 16px;
                            padding-left: 25px;
                            color: $club;
                            font-weight: 500;
                            margin: 10px 0;
                            &::before {
                                // content: 'X';
                                content: url("../../images/svg/cancel/arrow-cancel-club-smiles.svg");
                                width: 14px;
                                height: 14px;
                                color: $club;
                                font-size: 24px;
                                position: absolute;
                                left: 0;
                                font-family: $boldFontFamily;
                                top: 0;
                                line-height: 15px;
                            }
                        }
                    }
                    &__warning{
                        background-color: #F9F9F9;
                        border: 1px solid #7C7C7C;
                        margin: 20px 0;
                        padding: 20px;
                    }
                    &__buttons{
                        gap: 20px;
                        a{
                            flex: 1;
                        }
                        .btn-club{                            
                            background-color: $club;
                            border-radius: 2px;
                            border: none;
                            color: #fff;
                            font-size: 14px;
                            height: 50px;
                            line-height: 1;
                            padding: 5px 20px;
                            width: 100%;
                            &:hover{
                                opacity: 0.8;
                            }
                        }
                        @media only screen and (max-width: 768px){
                            a{
                                width: 50%;
                            }
                        }
                        @media only screen and (max-width: 475px){
                            a{
                                width: 100%;
                            }
                        }

                    }
                }
            }
            &__how-to-cancel {
                width: 100%;
                float: none;
                margin-right: 28px;
                padding: 10px 20px;
                .cancel_comunicate{
                    display: flex;
                    align-items: center;
                    p{
                        font-weight: 600;
                        font-family: $semiBoldFontFamily;
                        font-size: .9em;
                        margin: 20px 0 0 0;
                    }
                    .whatsapp_icon_cancel_comunicate{
                        width:50px;
                        height:50px;
                        margin-left: 30px;
                        background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
                        display: inline-block;
                        background-size: 100%;
                        background-position: center !important;
                    }
                }
                h3 {
                    font-size: 21px;
                    color: $club;
                    margin: 0;
                    text-align: left;
                    font-family: $boldFontFamily;
                }
                .cancel_more_info{
                    font-family: $boldFontFamily;
                    text-align: left;
                    font-size: 12px;
                    margin: 80px 0 0 0;

                    a {
                        color: $default;
                        text-decoration: underline;
                    }
                }
            }

            .call-us-container-outside{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-right: 120px;
                margin-top: 20px;
                .call-us-whatsapp_icon{
                    width:25px;
                    height:25px;
                    background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
                    display: inline-block;
                    background-size: 100%;
                    background-position: center !important;
                    margin: 5px;
                }
                .call-us-steps_comunicate{
                    font-family: $boldFontFamily;
                    text-align: left;
                    font-size: 12px;
                    // color: $primary;
                }
                .call-us-steps_comunicate a{
                    color: #8f8f8f !important;
                }
            }
            
            .call-us-container{
                display: none;
                justify-content: flex-end;
                align-items: center;
                margin-right: 120px;
                margin-top: 20px;
               
                .call-us-whatsapp_icon{
                    width:25px;
                    height:25px;
                    background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
                    display: inline-block;
                    background-size: 100%;
                    background-position: center !important;
                }
                .call-us-steps_comunicate{
                    font-family: $boldFontFamily;
                    text-align: left;
                    font-size: 12px;
                    color: $primary;
                }
            }
            &__phone {
                background-color: transparent !important;
                margin: 0 5px !important;
                @include bms-base-box;
                position: relative;
                padding: 0px;
                height: auto;
                overflow: hidden;
                width: 266px;
                box-shadow: none;
                
                .step-container{
                    .whatsapp_icon{
                        width:25px;
                        height:25px;
                        background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
                        display: inline-block;
                        background-size: 100%;
                        background-position: center !important;
                    }
                    .icon_step{
                        &__first, &__second, &__third{
                            padding: 0;
                            width:30px;
                            height:30px;
                            background-position: center !important;

                        }
                        &__first{
                            margin: 20px 0 0 0;
                            background: url(#{$svg-path}/icons/orange/ic-cancel-mobile.svg) no-repeat;
                        }
                        &__second{
                            margin: 40px -6px 0 0;
                            background: url(#{$svg-path}/icons/orange/ic-cancel-whatsapp.svg) no-repeat;
                        }
                        &__third{
                            margin: 40px 0 0 0;
                            background: url(#{$svg-path}/icons/orange/ic-cancel-atention.svg) no-repeat;
                        }

                    }
                    .text-inside-step{  
                        &__first, &__second, &__third{
                            font-size: 12px;
                            margin-bottom: 0 !important;
                            margin-left: 15px;
                            font-weight: 600;
                            line-height: 1.2;
                            strong {
                                color: $gray-900;
                                font-family: $boldFontFamily;
                            }
                        }
                        &__first{
                            margin-top: 22px !important;
                            
                        }
                        &__second{
                            margin-top: 34px !important;
                            
                        }
                        &__third{
                            margin-top: 33px !important;
                        }
                    }
                    .steps_comunicate{
                        font-family: $boldFontFamily;
                        text-align: left;
                        font-size: 12px;
                        color: $primary;
                    }
                }
                h3 {
                    float: none;
                    text-align: center;
                    font-size: 18px;
                    width: 142px;
                    margin: 10px auto;
                    color: $gray-700;
                    b {
                        color: $default;
                    }
                }
                label {
                    width: 100%;
                    margin: 20px 0 0 0;
                    text-align: center;
                    font-size: 18px;
                }
                span {
                    width: 100%;
                    margin: 5px auto;
                    text-align: center;
                    font-size: 22px;
                    font-family: $boldFontFamily;
                    display: block;
                }
                small {
                    width: 100%;
                    margin: 0 0 0 0;
                    text-align: center;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
    @media (min-width: 768px) {
       
        &__my-club {
            &__cancel {
                &__info {
                    padding: 30px;
                    p {
                        padding: 0;
                    }
                    &__list {
                        padding: 40px 20px;
                        p {
                            text-align: left;
                            padding: 0;
                        }
                    }
                }
                &__how-to-cancel {
                    width: 560px;
                    float: left;
                    margin-right: 28px;
                    padding: 0;
                }
                &__phone {
                    margin: 0 !important;
                    h3 {
                        float: right;
                        margin: 10px 20px;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 575px){
    .smiles {
        &__my-club {
            &__cancel {
                .call-us-container-outside{
                    display: none;
                }
                .call-us-container{
                    display: flex;
                }
                &__phone{
                    width: 100%;
                    padding-left: 90px;
                }
            }
        }
    }
}