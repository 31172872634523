.checkout {
  text-align: left;
  padding: 25px 5pt;

  h2 {
    @include bms-font-size(8.85vw, 32px);
  }

  h3 {
    @include bms-font-size(5.35vw, 17px);
  }

  .travel-details, .voucher-container {
    margin: 30px auto;
    width: 108%;
    margin-left: -4%;
  }

  .travel-details {
    &.easy-travel {
      border: 2px solid $gray-300;
      width: 100%;
      margin: auto;
    }

    h4 {
      color: $gray-800;
      text-transform: uppercase;
      background-color: $gray-300;
      margin: 0;
      @include bms-font-size(4vw, 14px);
      padding: 20px 10px;
    }
  }

  .total-purchase {
    .highlights {
      @include bms-font-size(4.4vw, 20px);
    }

    .bonus {
      color: $default;
    }

    .easy-travel {
      strong {
        color: $easy-travel;
      }

      span {
        float: right;
        display: none;
      }
    }

    .club-smiles {
      td {
        position: relative;
        padding-bottom: 70px !important;

        strong {
          color: $club;
          font-size: 16px;
        }

        label {
          color: $club;
          font-size: 30px;
          font-family: $boldFontFamily;
          letter-spacing: -1px;
          float: left;
          clear: both;
          margin: 5px 20px 0 20px;
          line-height: 30px;
        }

        small {
          background-image: url(../images/svg/logos/smiles-purple.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 40px;
          height: 18px;
          position: relative;
          margin-bottom: 5px;
          float: left;
          clear: both;
          margin-left: 27px;

          &::after {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 2px solid $club;
            top: -2px;
            width: 20px;
            right: 100%;
            margin-right: 3px;
          }

          &::before {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 2px solid $club;
            top: -2px;
            width: 20px;
            left: 100%;
            margin-left: 3px;
          }
        }
      }
    }

    .miles-purchase {
      &__club {
        min-width: 230px;
        text-align: left;

        b {
          color: $club;
        }
      }
    }
  }

  .box {
    $box-border-color: $gray-400;
    $horizontal-padding: 32px;

    padding: 10pt 9pt 9pt 9pt;
    border: 2px solid $gray-300;

    &:not(:last-child) {
      border-bottom: none;
    }

    &.regularization-payment {
      padding: 0;

      .col-title, .text-security {
        color: #636363;
      }

      .payment-method {
        select {
          height: calc(3rem + 2px);
          border-color: #9e9e9e;
          border-radius: 3px;
        }
      }

      .col-title {
        margin-bottom: 22px;
      }

      .payment {
        .mt-6 {
          @media screen and (max-width: 991px) {
            margin-top: 0 !important;
          }
        }

        .new-tarjeta {
          padding: 0 32px;

          @media screen and (max-width: 768px) {
            padding: 0 32px 32px;
          }

          .frm-default {
            .credit-card {
              .row {
                input, select {
                  border-color: #9e9e9e;
                  border-radius: 3px;
                }

                label {
                  color: #777777;
                  font-weight: 400;
                }
              }

              .row:last-child {
                display: none;
              }
            }
          }
        }

        > div:last-child {
          padding: 0 32px;
        }
      }

      div.title, div.subtitle {
        font-size: 24px;
        padding: 12px $horizontal-padding;
      }

      div.title {
        border-bottom: 1px solid $box-border-color;
      }

      div.subtitle {
        font-weight: 900;
        font-size: 26px;
      }
    }

    &.reactive-summary {
      border: 1.4px solid $box-border-color;
      padding: 0;

      table {
        tr td {
          padding: 12px $horizontal-padding;

        }

        thead {
          tr {
            border-bottom: 1px solid $box-border-color;

            th {
              padding: 21px $horizontal-padding;
              font-size: 24px;

              &:last-child {
                text-transform: uppercase;
                color: $gray-700;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid $box-border-color;
          }
        }

        tfoot {
          tr {
            border-bottom: none;
          }
        }

        tbody, tfoot {
          td {
            font-size: 1.1rem;
          }
        }

      }
    }

    .payment {
      h4 {
        color: $gray-700;
        @include bms-font-size(5.3vw, 17px);
      }

      > div {
        position: relative;
        float: none;
        margin-bottom: 30px;

        &:not(:last-child) {
          border-bottom: 2px solid $gray-300;
          padding-bottom: 20px;

          &:before {
            content: " ";
            position: absolute;
            left: 45%;
            bottom: -22px;
            display: block;
            width: 32px;
            height: 32px;
            background-image: url(#{$svg-path}/icons/gray/ic-arrow-lighter.svg);
            background-size: 24px;
            background-position: center center;
            background-repeat: no-repeat;
            transform: rotate(90deg);
          }
        }

        .installments {
          &__list {
            margin: 0;
            float: left;
            width: 100%;

            ul {
              li {
                display: none;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 13px;
                }
              }
            }

            span {
              float: left;
              width: 100%;
              text-align: center;
              font-size: 13px;
            }
          }
        }
      }

      .keyboard {
        background-image: url(#{$svg-path}/logos/smiles-orange.svg);
        background-size: 80px;
        background-position: top right;
        background-repeat: no-repeat;

        h4 {
          width: 100%;
        }

        input {
          border: 1px solid $gray-800;
          margin: 20px auto;
          padding: 10px 5px;
          border-radius: $border-radius;
          width: 100%;

          &::placeholder {
            color: $gray-400;
            @include bms-font-size(4vw, 12px);
          }
        }

        .layout {
          height: 160px;
          padding: 10px 20px;
          border: 2px solid $primary;
          border-radius: $border-radius;
          margin-bottom: 20px;

          span {
            display: block;
            width: 240px;
            margin: 0 auto;
            font-weight: bold;
          }

          .numbers {
            width: 240px;
            margin: 10px auto;

            > div {
              float: left;
              width: 45px;
              height: 45px;
              background-color: $gray-800;
              color: $white;
              margin-right: 3px;
              margin-bottom: 3px;
              border-radius: $border-radius;
              text-align: center;
              padding-top: 11px;
              cursor: pointer;
            }
          }
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .btn {
            width: 136px;
            margin: 0;
          }
        }

        .box-terms {
          display: block;
          margin-top: 20px;

          .highlight {
            color: $primary;
          }

          a {
            color: $club;
          }
        }

        &.club-smiles {
          background-image: none;
          flex-direction: row;

          .buttons {
            margin-top: 20px;

            .btn {
              width: 100%;
            }
          }

          p {
            width: 100%;

            span {
              color: $default;
            }

            a {
              color: $club;
            }
          }
        }
      }

      .text-security {
        p {
          margin: 0 0 5px 0;
          @include bms-font-size(3vw, 10px);
          @include bms-line-height(4vw, 12px);

          strong {
            font-family: $boldFontFamily;
            @include bms-font-size(4.4vw, 14px);
          }
        }
      }

      .card-number {
        position: relative;

        .smls-credit-cards {
          position: absolute;
          width: 40px;
          height: 40px;
          top: 0px;
          right: 8px;
        }
      }

      .pass-message {
        display: block;
        text-align: left;

        > div {
          width: auto;
          padding: 0;
          flex-basis: auto;
          margin: auto;
        }

        b {
          color: $primary;
        }

        .pass-box {
          display: flex;
          align-items: center;
          @include bms-font-size(5vw, 22px);
          @include bms-line-height(7vw, 26px);
        }

        .ic-share-down {
          font-size: 20px;
        }

        .ic-share {
          display: none;
        }
      }
    }

    .cuit {
      @include min-width(768px) {
        transform: translateY(-92%);
      }
    ;
      @include max-width(767px) {
        margin-left: 0;
      }
    ;
    }

    .cuit-two-cards {
      @include min-width(768px) {
        transform: translateY(-23%);
      }
    ;
      @include max-width(767px) {
        margin-left: 0;
      }
    ;
    }

    .cencosud-points {
      color: $gray-700;

      .miles {
        color: $default;
      }

      .points {
        color: $gray-900;
      }
    }
  }

  .club-smiles-tooltip {
    display: block;
    background-color: $club;
    border: 2px solid $club;
    width: 280px;
    height: auto;
    color: $white;
    font-family: $regularFontFamily;
    font-size: 14px;
    position: absolute;
    padding: 10px;
    left: 0;
    margin-top: 62px;
    background-repeat: no-repeat;
    z-index: 3;

    @media (min-width: 768px) {
      font-size: 16px;
      padding: 20px;
      left: 30%;
      margin-top: -22px;
    }

    /* center inside a table row */
    &.table-row-center {
      width: 250px;
      left: unset;
      right: -50px;
      margin-top: 0;
      text-align: left;
      padding: 10px;
      top: 100%;

      &::before {
        top: -18px;
      }

      @media (min-width: 768px) {
        top: 60%;
        right: 80%;

        transform: translateY(-50%);

        &::before {
          top: 40%;
        }
      }
    }

    &::before {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 8px;
      border-bottom-color: $club;
      z-index: 3;
      top: -29%;

      @media (min-width: 768px) {
        top: 40%;
        border-width: 8px;
        border-bottom-color: transparent;
      }
    }

    &:not(.arrow-right)::before {
      left: 14%;

      @media (min-width: 768px) {
        border-right-color: $club;
        left: -6%;
      }
    }

    &.arrow-right::before {
      right: 23%;

      @media (min-width: 768px) {
        border-left-color: $club;
        left: 100%;
      }
    }
  }

  .diamond-tooltip {
    display: none;
    background-image: url(#{$svg-path}/icons/orange/ic-smiles_diamante-v2.svg);
    background-color: $diamond;
    background-position-x: 96px;
    background-position-y: 9px;
    border: 2px solid $diamond;
    width: 260px;
    height: auto;
    color: $white;
    font-family: $ligthFontFamily;
    font-size: 12px;
    position: absolute;
    padding: 6px;
    right: 39%;
    margin-top: -52px;
    background-repeat: no-repeat;

    span {
      color: $default;
      font-size: 14px;
      font-family: $boldFontFamily;
    }

    div {
      padding-top: 10px;
    }

    &::before {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      z-index: 3;
      left: 100%;
      top: 40%;
      border-width: 8px;
      border-left-color: $diamond;
    }
  }

  .checkout-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .go-back-checkout {
      padding-right: 20px;
    }
  }
}

// Large devices (Desktop, 768px and up)
@media (min-width: 768px) {
  .checkout {
    margin: 0 auto;
    width: 1056px;

    .travel-details {
      &.easy-travel {
        border: none;
      }
    }

    .total-purchase {
      .club-smiles {
        td {
          padding-bottom: 1px !important;
        }
      }
    }

    .diamond-tooltip {
      display: block;
    }

    .total-purchase {
      .easy-travel {
        span {
          display: block;
        }
      }
      .table-summary {
        .miles-purchase td:first-child {
          width: 170px;
        }
        tr td:not(:first-child) {
          text-align: center;
          strong {
            margin-left: 0px;
          }
        }
      }
    }

    .box {
      padding: 32px;
      &.small-h-padding {
        padding: 36px 8px;
      }
      .payment {
        display: flex;
        justify-content: center;
        > div {
          width: 325px;
          min-height: 310px;
          padding: 0 30px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:not(:last-child) {
            &:not(.regularization) {
              border-bottom: none;
              border-right: 2px solid $gray-300;
              &:before {
                top: 45%;
                right: -22px;
                bottom: unset;
                left: unset;
                transform: none;
              }
            }
          }
        }
        .keyboard {
          input {
            width: 120px;
            margin: 0 0 20px 0;
          }
          &.club-smiles {
            .buttons {
              margin-top: 136px;
            }
          }
        }
        .card-number {
          .smls-credit-cards {
            top: 5px;
          }
        }
        .pass-message {
          display: flex;
          text-align: center;
          .pass-box {
            display: block;
          }
          .ic-share-down {
            display: none;
          }
          .ic-share {
            display: block;
            font-size: 20px;
          }
        }
      }
    }
    .travel-details, .voucher-container {
      width: 100%;
      margin-left: auto;
    }
  }
}
