.SmlsPurpleLoadingModal {
    padding: 25px;
    .SmlsPurpleLoadingModal_container {
        .gear-icon {
            background-image: url(#{$svg-path}/icons/purple/singleGear.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            margin: 15px;
        }
        .hold-message {
            padding: 10px;
            background-color: $club;
            width: 150px;
            height: 50px;
            color: #ffffff;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            color: $club;
            margin-bottom: 4px;
        }
    }
}
