.retrocredit {
  &__main {
    @include bms-base-box;
  }
  .retrocredit__main {
    width: auto;
  }

  .col-vertical-alignment {
    display: grid;
    align-items: center;
  }

  /* fontes */
  .header-2 {
    @include bms-font-size(4.3vw, 20px);
    font-weight: 700;
  }
  .header-3 {
    @include bms-font-size(4.3vw, 18px);
    font-weight: 700;
  }
  .header-4 {
    @include bms-font-size(4.0vw, 16px);
    font-weight: 700;
  }
  .header-5 {
    @include bms-font-size(4.0vw, 16px);
    font-weight: 700;
  }
  .text-medium {
    @include bms-font-size(3.8vw, 14px);
    font-weight: 700;
  }
  .text-small {
    @include bms-font-size(3.4vw, 10px);
  }

  select#company {
    @include bms-font-size(3.8vw, 12px);
    font-weight: 600;
  }

  h2, h3, p {
    line-height: 1.3;
  }
  p {
    @include bms-font-size(3.8vw, 12px);
    font-weight: 600;
  }

  [class*=btn-] {
    padding: {
      top: 11px;
      bottom: 11px;
    };
    min-width: 185px;
  }
  .btn-primary {
    @include bms-font-size(3.6vw, 12px);
  }
  .btn-outline {
    @include bms-font-size(3.8vw, 14px);
    background-color: unset;
    color: $secondary;

    &:before {
      font-size: small;
    }
  }

  .icon-paragraph {
    display: flex;

    .smls-orange-icons {
      @include bms-font-size(4.6vw, 20px);
    }

    @media (min-width: $site-width-mobile) {
      justify-content: center;
      flex-wrap: wrap;
      align-content: end;

      > p {
        flex-basis: 100%;
      }
    }
  }

  .form-group.has-icon {
    input {
      padding-right: 20px;
    }

    span {
      position: absolute;
      font-size: 11px;
      top: 16px;
      right: 10px;
    }
  }

  .flight-ticket-header img {
    max-height: 50px;
  }

  .flight-ticket-data {
    @include bms-font-size(1.8vw, 8px);

    color: $black;
    font-weight: bold;
  }

  .code-highlight {
    @include bms-font-size(3.8vw, 14px);

    padding: map-get($spacers, 1) map-get($spacers, 2);
    box-shadow: 0 1px 3px 0 $gray-400;
    font-weight: bold;
  }

  .orange-border-full {
    border: $orange 2px solid
  }

  .icon-medium {
    @include bms-font-size(4.6vw, 28px);
  }

  .icon-big {
    @include bms-font-size(4.6vw, 44px);
  }
}
