.suggestions-input {
  position: absolute;
  background: $white;
  border: 2px solid $primary;
  border-radius: $border-radius;
  padding: 10px;
  z-index: 2;
  width: 100%;
  font-family: $boldFontFamily;

  &::after {
    @include _base-arrow();
    position: absolute;
    top: -8px;
    left: 8%;
    border-color: transparent transparent $primary transparent;
    border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
    border-bottom-color: $primary;
    z-index: 1;
  }
  &::before {
    @include _base-arrow();
    position: absolute;
    top: -5px;
    left: 8%;
    border-color: transparent transparent $white transparent;
    border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
    border-bottom-color: $white;
    z-index: 2;
  }

  ul {
    max-height: 300px;
    padding: 0;
    margin-bottom: 0;
    overflow: {
      y: auto;
      x: hidden;
    };
  }

  li {
    border-bottom: 2px solid $gray-400;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    &:last-child {
      border: none
    }

    a {
      text-align: left;
      padding: 5px 3px;
      color: $gray-600;
      font-size: 13px;
      display: block;
    }
  }
}
