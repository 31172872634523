.member-number__container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}

.member-number-text {
    display: block;
    margin: 0 5px 3px 5px;
    font-size: 4vw;
    color: $gray-700;
}

.button-icon {
    background-color: $white;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    top: -2px;
    .icon-copy {
        width: 20px;
        height: 20px;
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(#{$svg-path}/icons/orange/ic-copy.svg);
    }
}

.button-icon:hover {
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .button-icon {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        position: revert;
        top: revert;
        .icon-copy {
            width: 25px;
            height: 25px;
            background-size: 18px;
        }
    }
}

@media only screen and (max-width: 500px) { 
    .button-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        position: relative;
        top: -2px;
        .icon-copy {
            width: 20px;
            height: 20px;
            background-size: 12px;
        }
    }
}

.header-info-member-number__container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    .user-id {
        padding: 10px 0px;
    }  
    .button-icon {
        padding-left: 0.3rem;
    }
    .member-number__tooltip {
        .member-number__arrow {
            right:72px;
        }
    }
}

