.retrocredit {
  .warning {
    display: flex;

    .space {
      width: 180px;
      margin: 25px;
      @media (max-width: $site-width-mobile) {
        width: 0px;
        margin: 0px;
      }
    }

    .warning-partner {
      display: flex;
      padding: 0px;
      border-radius: 3px;

      -webkit-box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      -moz-box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      margin: 15px 0px;

      .ico {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $default;
        width: 70px;
        height: 70px;
      }

      .ico span {
        width: 40px;
        height: 40px;
        background: {
          image: url('/images/retrocredit/troca-info-cia.png');
          repeat: no-repeat;
          position: center 0%;
          size: 40px;
        };
      }

      .text {
        padding: 10px 15px;
        .bold-date {
          font-weight: 800 !important;
        }

        p:first-child {
          font-size: 13px !important;
          line-height: 20px;
          font-weight: 800 !important;
        }

        p {
          font-size: 12px !important;
          font-weight: normal !important;
          color: $black !important;
          margin: 0px;
          padding: 0px;
          line-height: 15px;
        }
      }
    }
  }

  .step-display {
    display: flex;
    width: 100%;
    padding: 0;

    li {
      flex: 1;
      text-align: center;
      position: relative;
      padding: 40px 6px 16px 6px;
      font-size: 3vw;
      line-height: 1.2;
      color: $gray-500;

      &.open {
        color: $default;

        &:before,&:after {
          background-color: $default;
        }
      }

      &.checked {
        background: {
          image: url('/images/svg/icons/orange/ic-check-solid.svg');
          repeat: no-repeat;
          position: center 0%;
          size: 15px;
        };
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        background-color: $gray-300;
        border-radius: 100%;
        top: 20px;
        width: 5px;
        height: 5px;
        left: calc(50% - 3px);
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        background-color: $gray-300;
        top: 23px;
        width: 96%;
        height: 1px;
        left: -45%;
      }

      &:first-child::after {
        display: none;
      }

      @media (min-width: $site-width-mobile) {
        font-size: 13px;

        &:before {
          top: 20px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
