.smls-credit-cards {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  &.default { background-image: url(#{$image-path}/svg/icons/gray/ic-creditcard-default.svg); }
  &.amex { background-image: url(#{$image-path}/credit-cards/smls-amex.png);}
  &.diners { background-image: url(#{$image-path}/credit-cards/smls-diners.png);}
  &.discover { background-image: url(#{$image-path}/credit-cards/smls-discover.png);}
  &.elo { background-image: url(#{$image-path}/credit-cards/smls-elo.png);}
  &.flag-card-smiles { background-image: url(#{$image-path}/credit-cards/smls-flag-card-smiles.png);}
  &.hipercard { background-image: url(#{$image-path}/credit-cards/smls-hipercard.png);}
  &.mastercard { background-image: url(#{$image-path}/credit-cards/smls-mastercard.png);}
  &.visa { background-image: url(#{$image-path}/credit-cards/smls-visa.png);}
  &.naranja { background-image: url(#{$image-path}/credit-cards/smls-naranja.png);}
  &.cabal { background-image: url(#{$image-path}/credit-cards/smls-cabal.png);}
  &.argencard { background-image: url(#{$image-path}/credit-cards/smls-argencard.png);}
  &.visa_debit { background-image: url(#{$image-path}/credit-cards/smls-visa-debit.png);}
  &.maestro { background-image: url(#{$image-path}/credit-cards/smls-maestro-debit.png);}
  &.cabal_debit { background-image: url(#{$image-path}/credit-cards/smls-cabal-debit.png);}
  &.mastercard_debit { background-image: url(#{$image-path}/credit-cards/smls-mastercard-debit.png);}
}
