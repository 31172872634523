.my-account {
  &__cencosud-points-in-progress {
    @include d-flex;
    text-align: center;

    p {
      font-size: 20px;
      align-self: center;
    }

    .cencosud-miles {
      color: #FF5A00;
    }
  }

  &__cencosud-points-success-container {
    @include d-flex;

    .image-banner {
      margin-bottom: 40px;
    }

    .cencosud-points-success-info {
        @include d-flex($align-items: flex-start);
        max-width: 50%;
        margin-bottom: 6em;
        padding: 30px 60px;
        text-align: left;
        position: relative;

        p {
          font-size: 20px;
          align-self: center;
        }

        .cencosud-miles {
          color: #FF5A00;
        }

        @media screen and(max-width: 768px) {
          max-width: 90%;
          padding: 10px 15px;
          align-items: center;
          margin-bottom: 3em;

          p {
              text-align: center;
          }
        }
    }

    .cencosud-points-success-buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      margin: 10px auto;
      text-align: center;

      .jumbo-btn {
        padding: 10px;
      }
    }
  }
}
