
.services-experience-jarvis-container{
    margin-top: 20px;
    .services-experience-jarvis-data {
        border-left: 2px solid #FF5A00;
        padding: 12px 85px 1px 85px;
        background: #f5f5f5;
        border-radius: 3px
    }
}

@media only screen and (max-width: 575px){
    .services-experience-jarvis-container{
        .services-experience-jarvis-data {
            padding: 12px 25px 1px 85px;
        }
    }
}