@keyframes wind {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  35%,
  50%,
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(30%);
  }
}

.empty-state {
  &.animation {
    overflow: hidden;
    padding: 30pt 2pt;
    border: 1px solid $gray-300;

    span {
      display: table;
      margin: 0 auto 32px auto;
      position: relative;
      left: -54px;

      &:before {
        content: "";
        width: 17.8em;
        height: 8.4em;
        vertical-align: middle;
        display: inline-block;
        background-image: url(#{$image-path}/svg/misc/ic-clouds-gray.svg);
        background-repeat: no-repeat;
        animation: wind 20s linear infinite;
      }
    }

    h6 {
      color: $gray-700;
    }
  }
}
