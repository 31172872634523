.club-smiles {
    &__reactive-no-logged {
        .dni {
            margin: 0 10px;
            width: calc(100% - 20px);
            padding: 20px 30px 40px 30px;
            border-bottom: 1px solid $gray-500;
            label {
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-family: $boldFontFamily;
            }
            input {
                width: 100%;
                padding: 5px;
                border-radius: 3px;
                border: 1px solid $gray-400;
                margin-bottom: 10px;
            }
            button {
                width: 100%;
                font-size: 16px;
                border: none;
                background-color: $club;
                border-radius: 3px;
                color: $white;
                padding: 5px 30px;
                font-family: $ligthFontFamily;
            }
        }
        .info {
            width: 100%;
            padding: 40px 20px;
            label {
                color: $club;
                font-size: 20px;
                line-height: 20px;
                font-family: $boldFontFamily;
            }
            p{
                width: 100%;
                font-family: $boldFontFamily;
                line-height: 20px;
                margin: 20px auto;
            }
            small {
                color: $default;
                font-size: 16px;
                font-family: $boldFontFamily;
            }
        }
    }
    @media (min-width: 768px) {
        &__reactive-no-logged {
            .dni {
                margin: 0;
                padding: 20px 0 80px 0;
                label {
                    width: auto;
                    text-align: left;
                }
                input {
                    width: 350px;
                    margin-bottom: 0;
                }
                button {
                    width: auto;
                }
            }
            .info {
                width: 100%;
                padding-top: 80px;
                label {
                    font-size: 24px;
                }
                p{
                    width: 700px;
                }
            }
        }
    }
}

.modal-reactive-no-logged {
    .modal-content {
        .modal-header {
            border-bottom: 0;
            .modal-title {
                width: 100%;
                text-align: center;
                span {
                    text-align: center;
                }
            }
        }
        .modal-body {
            text-align: center;
        }
        .modal-footer {
            border-top: 0;
            button {
                display: none;
            }
        }
    }
}
