.helper-field {
	.helper-content.show{
		display: block;
	}
	position: relative;
	&:focus-within {
		.helper-content{
			display: block;
		}
	}
	&:not(:focus-within){
		.helper-content{
			display: none;
		}
		.helper-content.show{
			display: block;
		}
	}
	.helper-content {
		p {
			@include bms-font-size(3vw, 12px);
		}
		position: relative;
		border: 2px solid $primary;
		border-radius: $border-radius;
		padding: 10px;
		margin-bottom: 15px;
		background-color: $white;
		&::after {
			@include _base-arrow();
			position: absolute;
			top: -8px;
			left: 48%;
			border-color: transparent transparent $primary transparent;
			border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
			border-bottom-color: $primary;
			z-index: 1;

		}
		&::before {
			@include _base-arrow();
			position: absolute;
			top: -6px;
			left: 48%;
			border-color: transparent transparent $white transparent;
			border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height $tooltip-arrow-height;
			border-bottom-color: $white;
			z-index: 2;
		}
	}
	.helper-content{
		display: none;
	}
}
