.voucher-container {
  .voucher {
    width: 90%;
    max-width: 100%;
    margin: 0px auto 0 auto;
    text-align: left;
    font-family: "NunitoLight", Arial, sans-serif;
    clear: both;
    border: 2px solid $gray-300;
    border-radius: 0px;

    .voucher-segment {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;
      padding: 15px 1.7vw;

      h4 {
        font-size: 17px;
        color: $primary;
      }

      h6 {
        font-size: 14px;
        color: $gray-700;
      }

      &__details-toggler {
        background-color: $gray-700;
        width: 23px;
        height: 30px;
        border-radius: 4px;
        display: block;

        &::after {
          content: "";
          width: 0;
          height: 0;
          top: 16px;
          left: 4px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid $white;
          position: relative;
        }
      }

      &__legs {
        width: 100%;
        margin: 30px 0 0 0;
        flex-direction: column;

        .voucher-error {
          font-size: 12px;
          color: $orangeBorder;
        }

        .input-border {
          border: 1px solid $gray-400;
          display: block;
          overflow: hidden;
          background-color: $white;
          padding: 10px;

          &.invalid-voucher {
            border: 1px solid $orangeBorder;
          }
    
          #voucher-number {
              border: none;
              color: $gray-900;
              padding-left: 25px;
              display: inline;
              text-align: left;
              font-weight: bold;
              background: transparent;

              &:disabled {
                color: $gray-700;
              }
          }
        }

        .voucher-input {
          align-items: center;
        }

        .voucher-message {
          margin-top: 8px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .voucher-container {
    .voucher {
      width: 100%;

      .voucher-segment {
        justify-content: space-between;

        &__legs {
          margin: 30px 0;
        }
      }
    }
  }
}