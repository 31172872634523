/*Resultado compra millas inicio*/
.reactivation-miles-success__container {
    padding: 20px 10px 20px 10px;
    margin: 0px;

    @media only screen and (max-width: 768px) {
        padding: 3rem 10px 20px 10px;
    }

    .title {
        padding: 0 0 0 0;

        h4 {
            font-size: 28px;
            font-family: $regularFontFamily;
            float: left;
            font-weight: bold;

            @media only screen and (max-width: 768px) {
                border-top: 0px solid $primary;
            }
        }
    }

    h2 {
        margin-bottom: 20px;
    }

    .resume-wrapper {
        padding: 0px;
        border: 1px solid $gray-500;
        width: 100% !important;
        display: flex;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
        }

        .resume-wrapper__left {
            .resume-wrapper__left--inner-top {
                background-color: $primary;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 32px 20px;
                height: 215px;

                .resume-wrapper__icon-check {
                    width: 55px;
                    height: 55px;
                    background-size: 55px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                }

                h4 {
                    margin: 20px 0px 20px 10px;
                    color: $white;
                    text-align: center;
                }
            }

            .resume-wrapper__left--inner-bottom {
                background-color: $white;
                padding: 30px 20px;
                display: flex;
                justify-content: center;

                .resume-wrapper__button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    max-width: 280px;

                    a {
                        .btn {
                            margin-top: 0px;
                            float: right;
                            padding: 10px 20px;
                            width: 120px;
                        }
                    }
                }
            }
        }

        .resume-wrapper__right {
            padding: 0px;
            background-color: $white;

            .resume-wrapper__inner-top {
                background-color: $white;
                padding: 1rem;
                height: 220px;
                @media only screen and (max-width: 991px) {
                    height: 100%;
                }
                .resume-list-top {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    max-width: 390px;
                    height: 100%;
                    @media only screen and (max-width: 991px) {
                        max-width: 420px;
                    }
                    li {
                        width: 100%;
                        margin-top: 5px;
                        margin-bottom: 5px;

                        .resume-title {
                            font-weight: 500;
                            color: $gray-900;
                            display: inline-block;
                        }

                        .bonus-club {
                            color: $club;
                            font-weight: 900;
                        }

                        .diamond {
                            color: $diamond;
                            font-weight: 900;
                        }

                        .bonus {
                            color: $default;
                            font-weight: 900;
                        }

                        span {
                            color: $gray-700;
                            @media only screen and (max-width: 768px) {
                                font-size: 12px !important;
                            }
                        }

                        span:first-child {
                            width: 60%;
                        }

                        span:last-child {
                            width: 40%;
                        }
                    }
                }

                @media only screen and (max-width: 991px) {
                    .resume-list-top {
                        padding: 0px 0px 0px 0px;

                        li {
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .resume-wrapper__inner-bottom {

                background-color: $gray-200;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px;

                @media only screen and (max-width: 500px) {
                    flex-direction: column;
                }

                .resume-list-bottom {
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    width: 50%;

                    @media only screen and (max-width: 991px) {
                        padding: 0px 0px 0px 5px;
                        width: 100%;
                    }

                    li {
                        width: 100%;
                        margin-top: 0px;

                        .resume-title {
                            font-weight: 900;
                            color: $gray-900;
                            display: inline-block;
                        }

                        span {
                            color: $gray-700;
                            font-size: 11.5px;
                        }

                        .resume-icon-user {
                            margin-right: 10px;
                            display: inline-block;
                            width: 25px;
                            height: 22px;
                            background-size: 25px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                        }

                        .resume-icon-tdc {
                            margin-right: 10px;
                            display: inline-block;
                            width: 25px;
                            height: 17px;
                            background-size: 25px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/gray/ic-credit-card.svg);
                        }
                    }
                }
            }
        }
    }

    .resume__banner-bottom {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .resume__banner-right {
        margin-left: 1.5rem;
    }

    @media only screen and (max-width: 991px) {
        .resume__banner-right {
            margin-left: 0rem;
        }
    }
}

/*Resultado compra millas fin*/