.checkout-sucess {

    .flight-seats {
        width: 896px;
        max-width: 100%;
        margin: 50px auto 0 auto;
        border: 2px solid $gray-300;
        border-radius: 3px;
        text-align: left;
        font-family: $regularFontFamily;
        clear: both;
        
        .flight-segment {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            padding: 15px 1.7vw;
            font-size: calc(9.9px + .2vw);
            margin-bottom: 15px;

            &__date {
                display: inherit;
                flex-direction: column;
                text-align: center;
                color: $black;
                margin: 0 0 0 10px;
                line-height: 20px;
            }

            &__company {
                font-size: calc(9.9px + .2vw);

                .logo-airlines {
                    width: 62px;
                    height: 26px;
                    padding: 0;
                    margin: 0;
                    background-size: contain !important;
                    display: block;
                }
            }

            &__stretch {
                display: inherit;
                margin: 0 10px 0 0;
                width: 46%;
                line-height: 20px;

                .airport-departure {
                    width: 85px;
                    text-align: right;
                    flex-direction: column;

                    span {
                        font-size: calc(9.9px + .2vw);
                    }
                }

                .airport-destiny {
                    width: 85px;
                    text-align: left;
                    flex-direction: column;

                    span {
                        font-size: calc(9.9px + .2vw);
                    }
                }

                b {
                    font-size: calc(10px + 1vw);
                    margin-bottom: 0px;
                    width: 100%;
                    display: block;
                    color: $black;
                }

                .duration {
                    display: inherit;
                    flex-direction: column;
                    padding: 0 .9vw;
                    text-align: center;
                    margin: 10px 0 0 0;

                    .seta {
                        width: 20px;
                        margin: 0;
                        padding: 10px 0;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 14px;
                            display: block;
                            background-image: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
                            background-position: center right;
                            background-repeat: no-repeat;
                            margin-bottom: 1px;
                        }
                    }
                }
            }

            &__details {
                display: flex;
                width: 100%;
                margin-top: 10px;

                div {
                    width: 50%;
                    text-align: center;
                }
            }

            &__legs-details {
                width: 100%;
                margin: 30px 0;
                display: flex;
                flex-direction: column;
                text-align: center;

                hr {
                    width: 85%;
                    margin: auto;
                    border-top: 1px dashed $black;
                    position: relative;
                    top: 12px;
                    z-index: -1;
                }

                .stretch {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-grow: 1;

                    .leg__airport {
                        display: block;
                    }

                    .leg__company {
                        text-align: center;

                        .logo-airlines {
                            width: 100%;
                            height: 26px;
                            padding: 0;
                            margin: 10px 0 5px 0;
                            background-size: contain !important;
                            display: block;
                        }
                    }

                    .leg__company, .leg__airport {

                        .smls-gray-icons {
                            padding: 0 8px 8px 8px;
                            width: 20px;
                            align-self: center;
                            background-color: $white;
                        } 
                    }
                }
            }

            &__passengers-details {
                width: 100%;
                overflow-x: auto;

                .smiles-table {
                    width: 100%;
                    tr {
                        height: 50px;

                        th {
                            color: $default;
                            font-family: $boldFontFamily;
                            font-size: 14px;
                            text-align: right;
                        }

                        th:first-child {
                            text-align: left;
                        }

                        td {
                            text-align: right;
                        }

                        td:first-child {
                            text-align: left;
                        }
                    }

                    tr:first-child {
                        border-bottom: 1px solid $gray-300;
                    }
                }
            }
        }
    }

    .hidden-phone {
        display: none;
    }

    @media (min-width: 768px) {
        .hidden-phone {
            display: block;
        }

        .flight-seats {

            .flight-segment {

                &__date {
                    margin: 0;
                }

                &__stretch {
                    margin: 0;
                    width: auto;
                
                    .duration { 

                        .seta {
                            width: 60px;
                            margin: 0 5px;
                            padding: 10px 0;
                        }
                    }
                }

                &__details {
                    margin: 0;
                    display: block;
                    width: auto;
    
                    div {
                        width: auto;
                        text-align: left;
                    }
                }

                &__legs-details {
                    
                    hr {
                        width: 90%;
                    }
                }

                &__passengers-details {
                    
                    .smiles-table {
                    
                        tr {
                            
                            th {
                                text-align: left;
                            }
                            td {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}
