.navigation {
  .page-item {
    .page-link {
      border-radius: 6px;
      border: none;
    }
  }
  .page-item:not(.arrow-item) {
    &.active {
      .page-link {
        @include navigation-active;
      }
    }
    .page-link,
    a {
      background-color: transparent;
      color: $gray-700;
    }
    .page-link {
      &:hover {
        @include navigation-active;
      }
    }
    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
    }
  }
  .arrow-item {
    .page-link {
      color: $gray-700;
      font-size: 2.5rem;
      line-height: 1.2rem;

      &:hover {
        background-color: $gray-500;
      }
    }
  }
}
