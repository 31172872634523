.jarvis-warning-message {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    align-items: center;

    &__gray {
        background-color: $gray-200;
        border: 1px solid $gray-600;
    }

    &__orange {
        border: 1px solid $primary,
    }

    div{
        .img-container{
            margin: 0px 20px;
            img{
                width: 20px;
            }
        }
        .message-size{
            font-size: .8em;
        }
    }

}

@media only screen and (max-width: 575px){
    .jarvis-warning-message {
        div{
            .img-container{
                margin: 0px 10px 0px 0px;
            }
        }
    
    }
}