.modal-reactive-club {
  p {
    color: $gray-700;
    font-size: 18pt;
    line-height: 25pt;
    margin-bottom: 25pt;
  }

  button.btn-primary {
    font-size: 9pt;
    max-width: 50%;
    margin: 0 auto;
  }
}