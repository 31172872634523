.container {

    .resume-table {

        border: 2px solid $gray-300;
        padding: 2rem;

        h3 {
            font-size: 17px;
            text-align: left;
        }

        table {
            width: 100%;
            tbody {
                .localMoneySubtotal {
                    text-align: center;
                }
            }
            tfoot {
                // border-top: 2px solid $gray-300;
                height: 50px;
                .total-miles-title {
                    text-align: center;
                    height: 30px;
                    font-size: 12.5px;
                }
                .total-money-title {
                    text-align: center;
                    height: 30px;
                    font-size: 12.5px;
                }
                .total-miles {
                    text-align: center;
                    height: 25px;
                }
                .total-money {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    height: 25px;
                }
            }
        }

        .resume-table-header {
            height: 50px;
            font-weight: bold;
            border-bottom: 2px solid $gray-300;

            .subtitle {
                font-size: 1.2rem;
            }
            .miles-quantity {
                font-size: 1rem;
                text-align: center;
                width: 20%;
                @media only screen and (max-width: 655px) {
                    width: 25%;
                }
            }
            .money-symbol {
                text-align: center;
                font-size: 1rem;
                border-bottom: 2px solid $gray-300;
                width: 20%;
                @media only screen and (max-width: 655px) {
                    width: 30%;
                }
            }
        }
        .table-row__container {
            border-bottom: 2px solid $gray-300;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            .col-first__container {
                padding-top: 0.6rem;
                padding-bottom: 00.6rem;
                .col-first {
                    align-items: center;
                    .logo-club__container {
                        width: 55px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .description {
                        padding-left: 0.6rem;
                        padding-right: 00.6rem;
                        @media only screen and (max-width: 804px) {
                            padding-left: 0rem !important;
                        }
                        .bonus-text {
                            color: $primary;
                        }
                    }
                }
            }   
        }
    
        .plan {
            color: $club;
            font-family: "NunitoBold", "Arial Bold", sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
        }

        .logo-wrapper {
            position: relative;


            .logo-smiles {
                z-index: 2;
                position: relative;
                display: block;
                width: 35px;
                height: 17px;
                background-size: 30px;
                background-image: url(../images/svg/logos/smiles-purple.svg);
                background-position: center center;
                background-repeat: no-repeat;
                overflow: hidden;
                text-indent: -1000px;
                margin: 0 auto;
                background-color: $white;
            }

            .logo-trace {
                border-bottom: 2px solid $club;
                width: 55px;
                height: 0;
                position: absolute;
                top: 7px;
                z-index: 1;
                right: -11px;
            }
        }
    }

   
    @media only screen and (max-width: 500px) {
        .resume-table {
            padding: 2rem 1rem;
            tbody {
                .table-row__container {
                    .col-first__container {
                        .logo-club__container {
                            .plan {
                                font-size: 24px !important;
                            }
                        }
                        .description {
                            padding-left: 0rem !important;
                        }
                    }
                }
            }
            span, td {
                font-size: 12px !important;
            }
        }
    }
    .mouse_enter_noclub {
        color: $club; 
    }
}