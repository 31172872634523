.footer-calendar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#note-diamond {
  color: $diamond;
}

#note-club-smiles {
  color: $club;
}

#note-low-fare {
  color: $default;
}

.notes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  strong {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    margin: 0 12px 0 4px;
    font-size: 14px;
    span.icon-not-available {
      content: "";
      margin: 0.5em 0 0.5em 0;
      background-image: url(#{$svg-path}/icons/gray/ic-block.svg);
      display: block;
      background-size: 100%;
      height: 16px;
      width: 16px;
      background-repeat: no-repeat;
      margin-right: 0.4em;
    }
    .calendar-notes-icon {
      margin-right: 0.4em;
    }
    .icon-date-selected {
      height: 13px;
      width: 13px;
      background: none;
      border: 2px solid $default;
    }
  }
}
@media (min-width: 768px) {
  .notes-container {
    justify-content: flex-start;
    width: 100%;
    strong {
      width: auto;
      margin-right: 1em;
    }
  }
}
@media (max-width: 767px) {
  .footer-calendar-button {
    align-items: center;
  }
}

#line-modal-footer {
  border: 0.5px solid #d8d6d7;
  opacity: 1;
  width: 100%;
  margin: 12px 0 12px 0;
}
.list-remark {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  margin: 0;
  padding: 0;
}
.footer-calendar-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 980px) {
    height: 90px;
  }

  @media (min-width: 1400px) {
    height: 90px;
  }
  height: 40px;

  button,
  .btn-primary {
    height: 30px;
    padding: 0px 30px 0px 30px;
  }
  @media (min-width: 768px) {
    @media (max-width: 980px) {
      height: 60px;
    }

    @media (min-width: 1400px) {
      height: 60px;
    }
    height: 40px;

    button,
    .btn-primary {
      height: 30px;
      width: 115px;
      padding: 0px;
      text-align: center;
      align-content: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 3px;
    }
  }
  .container-text-tooltip {
    width: 55%;
  }
}

.smls-pricing-calendar,
.smls-pricing-calendar-only-going {
  header {
    h6 {
      display: flex;
      font-size: 0.8rem;
      section {
        display: inline-block;
        width: 50%;

        &.segment-name {
          span:nth-child(2) {
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid #ddd;
          }
        }

        &.month-info {
          display: flex;
          justify-content: flex-end;
          .calendar-pagination {
            margin-left: 10px;
            display: flex;
            justify-content: flex-end;

            span {
              background-repeat: no-repeat;
              display: block;
              width: 16px;
              height: 16px;
              content: "";
            }
            .icon-arrow-left-orange {
              background-image: url(#{$svg-path}/icons/orange/ic-arrow-left.svg);
            }
            .icon-arrow-left-gray {
              background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg);
            }
            .icon-arrow-right-orange {
              background-image: url(#{$svg-path}/icons/orange/ic-arrow-right.svg);
            }
            .icon-arrow-right-gray {
              background-image: url(#{$svg-path}/icons/gray/ic-arrow-right.svg);
            }
          }
        }
      }
    }
  }

  .calendar-body {
    thead {
      th {
        width: 14.28%;
        line-height: 1;
        padding: 0.4em 0 0.4em 0;
        border-bottom-width: 1px;
        &.highlight {
          background-color: #ddd;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0px;
          border-color: #e9e9e9;
          div.detail-day {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            @media (max-width: 980px) {
              padding: 8px;
              font-size: 10px;
            }

            @media (min-width: 1400px) {
              padding: 8px;
              font-size: 10px;
            }
            padding: 4px;
            font-size: 8px;

            div {
              &.default-loading {
                min-height: 11px;
                min-width: 11px;
                background-size: 11px;
                margin: 0.5em 0 0.5em 0;
              }
            }
            span.icon-not-available {
              content: "";
              margin: 0.5em 0 0.5em 0;
              background-image: url(#{$svg-path}/icons/gray/ic-block.svg);
              display: block;
              background-size: 100%;
              height: 14px;
              width: 14px;
              background-repeat: no-repeat;
            }
            @media (max-width: 768px) {
              span.icon-not-available {
                content: "";
                margin: 0.3em 0 0.6em 0;
                background-image: url(#{$svg-path}/icons/gray/ic-block.svg);
                display: block;
                background-size: 100%;
                height: 14px;
                width: 14px;
                background-repeat: no-repeat;
              }
            }
            span.icon-search {
              content: "";
              margin: 0.5em 0 0.5em 0;
              background-image: url(#{$svg-path}/icons/gray/ic-search.svg);
              display: block;
              background-size: 100%;
              height: 14px;
              width: 14px;
              background-repeat: no-repeat;
            }
            &.clubmember {
              color: $white;
              background-color: $purple;
            }
            &.smiles {
              color: $orange;
            }
          }

          &:not(.disabled) {
            cursor: pointer;
          }

          &.selected-date {
            border: 2px solid $primary;
          }

          &.disabled {
            opacity: 0.5;
          }
          .line-tooltip {
            height: 0px;
            width: 100%;
            position: relative;
          }
          & > .line-tooltip > .tooltip-day,
          & > .line-tooltip > .tooltip-day-withmiles {
            position: absolute;
            width: 100%;
            background: $white;
            color: $gray-800;
            border: 1px solid $primary;
            padding: 0.1em;
            .tooltip-inner-day {
              font-size: 11px;
            }
            .tooltip-inner-day-tramo {
              font-size: 8px;
            }
            small {
              font-size: 11px;
            }
          }

          .tooltip-day:after,
          .tooltip-day:before,
          .tooltip-day-withmiles:after,
          .tooltip-day-withmiles:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          .tooltip-day:after,
          .tooltip-day-withmiles:after {
            border-top-color: $white;
            margin-left: -2.8px;
            transform: translateY(-15%);
            border-width: 0.2em 0.12em;
          }
          .tooltip-day:before,
          .tooltip-day-withmiles:before {
            border-color: rgba(255, 90, 0, 0);
            border-top-color: $primary;
            border-width: 0.31em 0.12em;
            margin-left: -2.5px;
          }
          .tooltip-day::before,
          .tooltip-day-withmiles::before {
            border-width: 0.31em 0.12em;
            margin-left: -2.5px;
          }

          @media (min-width: 768px) {
            .line-tooltip {
              display: flex;
              justify-content: center;
              .tooltip-day {
                width: 100%;
              }
              .tooltip-day-withmiles {
                width: 100%;
              }
            }
            & > .line-tooltip > .tooltip-day,
            & > .line-tooltip > .tooltip-day-withmiles {
              transform: translateY(-277%);
            }
          }
          @media (max-width: 576px) {
            div.detail-day {
              padding: 4px;
            }
            .line-tooltip {
              display: flex;
              justify-content: center;
              .tooltip-day {
                width: 100%;
              }
              .tooltip-day-withmiles {
                width: 100%;
              }
            }
            & > .line-tooltip > .tooltip-day {
              transform: translateY(-270%);
            }
            & > .line-tooltip > .tooltip-day-withmiles {
              transform: translateY(-270%);
            }
          }
        }
      }
    }
  }
}

.modal-calendar-body {
  display: flex;
  justify-content: space-between;
  padding: 2px 16px 0 16px;

  @media (max-width: 980px) {
    min-height: 418px;
  }

  @media (min-width: 1400px) {
    min-height: 418px;
  }

  .smls-pricing-calendar,
  .smls-pricing-calendar-only-going {
    display: flex;
    flex-direction: column;
    width: 48%;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &:nth-child(2) {
      float: right;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .table-bordered th,
    table td {
      @media (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  .smls-pricing-calendar-only-going {
    width: 100%;
  }
}

.tooltip-footer-finish {
  .tooltip {
    .tooltip-inner {
      max-width: 140px;
      display: flex;
      justify-content: center;
      padding: 4px 0px 2px 0px;
      border-radius: 0px;
      border: 1.3px solid #ff5a00;
      .container-text-tooltip {
        width: 76%;
        small {
          font-size: 11px;
        }
      }
    }
    .arrow {
      &::before,
      &::after {
        border-width: 0.29rem 0.4rem 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .tooltip-footer-finish {
    .tooltip {
      .tooltip-inner {
        display: flex;
        justify-content: center;
        padding: 2px 0px 2px 0px;
        border-radius: 0px;
        max-width: 120px;
        border: 1.3px solid #ff5a00;
        line-height: 1.2em;
        .container-text-tooltip {
          width: 70%;
          small {
            font-size: 11px;
          }
        }
      }
    }
  }
}
