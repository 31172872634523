/*Progreso emision Congenere vuelos de millas inicio*/
.easy-travel-progress__container {
    padding: 20px 10px;
    .title {
        padding: 0 0 0 0;
        h4 {
            font-size: 28px;
            font-family: $regularFontFamily;
            float: left;
            font-weight: bold;
        }
    }
    .order-in-progress__container {
        padding: 0px;
        border: 1px solid $gray-500;
        display: flex;
        flex-direction: row;
        .order-in-progress__left {
            background-color: $easy-travel;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            height: 195px;
            .viaje-facil-logo {
                width: 55px;
                height: 55px;
                background: url(#{$svg-path}/icons/white/ic-easy-travel.svg) no-repeat;
                background-size: 55px !important;
                position: absolute;
                top: 12px;
                left: 12px;
            }
            .inprogress {
                background: url(#{$svg-path}/icons/white/ic-gear.svg) no-repeat center;
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
                display: block;
                background-size: 60px;
            }
            h2 {
                color: $white;
                text-align: center;
                margin-bottom: 0px;
            }
        }
        .order-in-progress__right {
            background-color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 25px;
            height: 195px;
            text-align: center;
            p {
                margin-bottom: 15px;
            }
            a { 
                border: 0px solid;
                margin-top: 0px;
                padding: 10px 24px;
            }
            .button-easy-travel {
                background-color: $easy-travel !important;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .order-in-progress__container {
            flex-direction: column;
        }    
    }
}
/*Progreso emision Congenere vuelos de millas Fin*/