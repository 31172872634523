.club-smiles { 
    &__success-accession {
        .banner-promo {
            background-image: url(../images/svg/backgrounds/success-accession-club.jpg);
            background-size: 100%;
            height: 460px;
            width: 100%;
        }

        .info-accession {
            border: 1px solid $club;
            width: 100%;
            height: auto;
            display: inline-block;
            margin-top: 106px;

            .club {
                background-color: $club;
                width: 100%;
                height: 98px;
                margin: 0;
                float: left;
                
                label {
                    font-size: 50px;
                    color: $white;
                    margin-top: 100px;
                    font-family: $boldFontFamily;
                }
                
                span {
                    background-image: url(../images/svg/logos/smiles-white.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 78px;
                    height: 28px;
                    position: relative;
                    display: inline-block;
                    margin-bottom: 20px;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 3px solid $white;
                        top: -5px;
                        width: 30px;
                        right: 100%;
                        margin-right: 3px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 3px solid $white;
                        top: -5px;
                        width: 30px;
                        left: 100%;
                        margin-left: 3px;
                    }
                }
            }

            .info {
                float: left;
                padding: 30px 0 0 0;
                width: auto;
                &__account {
                    float: left;
                    width: 100%;
                    text-align: center;
                    padding: 0;
                    
                    label {
                        font-size: 14px;
                        color: $club;
                        margin-bottom: 25px;
                        margin-top: 10px;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 25px;

                        span {
                            text-transform: uppercase;
                        }
                    }
                }

                &__resumen {
                    float: left;
                    width: 400px;
                    padding: 10px;
                    text-align: center;
                    
                    label {
                        font-size: 14px;
                        color: $club;
                        margin-bottom: 20px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    button {
                        float: none;
                        background-color: $club;
                        color: $white;
                        width: 188px;
                        height: 53px;
                        font-size: 20px;
                        border: none;
                    }
                }
            }

            &.emission {
                padding: 10px;
                border: none;
                width: 100%;
                margin-top: 50px;

                .club {
                    width: 100%;
                    label {
                        width: 100%;
                        margin: 10px auto auto auto;
                        font-size: 32px;
                        text-align: center;
                    }
                }

                .info {
                    &__resumen {
                        width: 100%;
                        border-top: 1px solid $club;
                        div {
                            font-family: $boldFontFamily;
                            width: 100%;
                            label {
                                max-width: 100%;
                                width: 100%;
                                float: left;
                                color: $default;
                                margin: 0;
                                &:last-child {
                                    color: $club;
                                    margin-bottom: 20px;
                                    padding-bottom: 20px;
                                    border-bottom: 1px solid $club;
                                }
                                &:first-child{
                                    color: $club;
                                }
                            }
                            span {
                                width: 100%;
                                float: left;
                                text-align: center;
                                padding-top: 0px;
                                color: $club;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) { 
    .club-smiles { 
        &__success-accession {
            .info-accession {
                background-color: $gray-100;
                border: 1px solid $club;
                width: 972px;
                &.emission {
                    border: 2px solid $gray-600;
                    width: 972px;
                    height: 330px;
                    padding: 0;
                    .club {
                        width: 225px;
                        height: 328px;
                        label {
                            font-size: 40px;
                            text-align: left;
                            margin: 100px 15px 0 15px;
                            width: auto;
                        }
                        span {
                            width: 98px;
                            height: 38px;
                            &::after {
                                width: 45px;
                            }
        
                            &::before {
                                width: 45px;
                            }
                        }
                    }
                    .info {
                        float: left;
                        padding: 30px 0 0 0;
                        width: auto;
                        &__account {
                            float: left;
                            width: 270px;
                            text-align: left;
                            border-right: 1px solid $gray-700;
                            padding: 0 0 0 30px;

                        }
                        &__resumen {
                            width: 460px;
                            padding: 10px 10px 0 30px;
                            text-align: left;
                            border-top: 0;
                            div {
                                font-family: $boldFontFamily;
                                width: 100%;
                                label {
                                    max-width: 80px;
                                    margin: 0 0 20px 0;
                                    float: left;
                                    color: $default;
                                    &:last-child {
                                        color: $club;
                                        padding-bottom: 0;
                                        border-bottom: 0;
                                    }
                                    &:first-child{
                                        color: $club;
                                    }
                                }
                                span {
                                    width: 80px;
                                    float: left;
                                    text-align: center;
                                    padding-top: 10px;
                                    color: $club;
                                }
                            }
                            button {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

