.auth0{
  &__container{
    position: fixed;
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7C7C7C85;
    top: 0;
    left: 0;
    right: 0; 
    bottom: 0;
    outline: 0;
    transition: opacity 0.15s linear;
    box-sizing: border-box;
  }

  &__content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F6F6;
    max-width: 400px;
    height: 200px;
    border-radius: 6px;
  }

  &__loading{
    width: 200px;
  }

  &__error{
    display: flex;
    flex-direction: column;

    &--title{
      text-align: center;
      padding-top: 0;
      color: red;
    }

    &--message{
      padding: 20px ;
      font-size: 20px;
      text-align: center;
    }
  }

  &__close{
    position: absolute;
    border: 2px solid gray;
    color: gray;
    border-radius: 50%;
    padding: 3px 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    top: 20px;
    right: 20px;

    &:hover{
      border-color: red;
      color: red;
    }
  }
}