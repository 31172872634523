.checkout {

    &__installment {
        h2 {
            display: inline-block;
            font-size: 6vw;
        }
        a.conditions {
            margin-left: 20px;
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
        }
        h4 {
            color: $gray-600;
            font-size: 16px;
            display: block;
            margin: 30px 0 5px 0;
            text-align: center;
            font-family: $regularFontFamily;
        }
        h5 {
            font-size: 16px;
        }
        &__type-payment {
            width: 100%;
            border: 1px solid $gray-400;
            border-radius: 4px;
            @media (max-width: 768px){
                padding: 0px !important;
            }
            
            &__how-pay{
                padding: 30px;     
                @media (max-width: 768px){
                    padding: 20px 16px;
                }           
                h3 {
                    color: $gray-600;
                    font-size: 24px;
                    display: block;
                    margin-bottom: 30px;
                    font-family: $ligthFontFamily;
                }

                ul {
                    display: block;
                    padding: 0;
                    width: 100%;
                    height: 30px;
                    margin: 0;
                    li {
                        float: left;
                        margin-right: 20px;

                        .form-check-label {
                            span {
                                &::before {
                                    height: 20px;
                                    width: 20px;
                                    background-size: cover;
                                }
                            }

                            input {
                                &:checked {
                                    ~ span {
                                    &:before {
                                        background-position: -20px bottom;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

            }

            &__mercadoPago_time-text{
                align-items: center;
                background-color: #eeeeee;
                border-top: 1px solid $gray-400;
                display: flex;
                font-family: $regularFontFamily;
                font-size: 14px;
                justify-content: center;
                margin-top: 10px;
                padding: 30px;    
                p{
                    color: #555555;
                    font-family: Nunito;
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0;
                }
            }
        }

        &__type-flags {
            width: 100%;
            border: 1px solid $gray-400;
            padding: 0 0 5px 0;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
            .flag {
                width: 80px;
                display: block;
                margin: 5px auto auto auto;
                &.exclusive {
                    .logo {
                        border-color: $purple;
                        height: 49px;
                        background-size: contain;
                        @media screen and(max-width: 768px) {
                            .club-label {
                                font-size: 12px;
                            }
                        }
                    }
                    .club-label {
                        width: 100%;
                        text-align: center;
                        background: $club;
                        color: $white;
                        font-size: 13px;
                        padding: 5px;
                        display: flex;
                        justify-content: center;
                    }
                }
                &.selected {
                    .logo {
                        border-color: $default;
                    }
                    &.exclusive {
                        .logo {
                            border-color: $gray-500;
                        }
                        .club-label {
                            background-color: $gray-500;
                        }

                        .installment {
                            border-color: $gray-500;
                            color: $gray-500;
                        }
                    }
                }
                .logo {
                    width: 80px;
                    height: 80px;
                    border: 1px solid $gray-400;
                    margin-bottom: 5px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;

                    &.bancopatagonia {
                        background-image: url(../images/logo-banks/ic-bancopatagonia.png);
                    }
                    &.bbva {
                        background-image: url(../images/logo-banks/ic-bbva.png);
                    }
                    &.american-express {
                        background-image: url(../images/logo-banks/ic-amex.png);
                    }
                    &.cabal {
                        background-image: url(../images/logo-banks/ic-cabal.png);
                    }
                    &.diners {
                        background-image: url(../images/logo-banks/ic-diners.png);
                    }
                    &.galicia {
                        background-image: url(../images/logo-banks/ic-galicia.png);
                    }
                    &.hsbc {
                        background-image: url(../images/logo-banks/ic-hsbc.png);
                    }
                    &.icbc {
                        background-image: url(../images/logo-banks/ic-icbc.png);
                    }
                    &.mastercard {
                        background-image: url(../images/logo-banks/ic-mastercard.png);
                    }
                    &.naranja {
                        background-image: url(../images/logo-banks/ic-naranja.png);
                        background-size: 50px;
                    }
                    &.santander {
                        background-image: url(../images/logo-banks/ic-santander.png);
                    }
                    &.visa {
                        background-image: url(../images/logo-banks/ic-visa.png);
                    }
                }
                .installment {
                    width: 80px;
                    height: 40px;
                    border: 1px dashed $default;
                    font-size: 10px;
                    color: $default;
                    text-align: center;
                    border-radius: 5px;
                    padding: 5px 2px;
                    &.interest {
                        border-color: $gray-700;
                        color: $gray-500;
                    }
                    &.exclusive {
                        border-color: $purple;
                        color: $purple;
                    }
                }
            }
        }

        &__numbers {
            width: 100%;
            border: 1px solid $gray-400;
            border-radius: 5px;
            padding: 5px;
            margin-top: 30px;
            .installment {
                border-bottom: 1px solid $gray-300;
                background-position-x: calc(100% - 5px);
                background-position-y: 10px;
                background-repeat: no-repeat;
                &:last-child {
                    border-bottom: 0;
                }

                &.open {
                    background-image: url(../images/svg/icons/orange/ic-arrow-up.svg);
                    .itens {
                        display: block;
                    }
                }
                .exclusive-club {
                    color: $white !important;
                    padding: 0px 15px;
                    background-color: $club;
                    @media screen and(max-width: 768px) {
                        max-width: 120px;
                    }
                }
                &.closed {
                    background-image: url(../images/svg/icons/orange/ic-arrow-down.svg);
                    .itens {
                        display: none;
                    }
                }
                .info {
                    width: 100%;
                    padding: 10px 0;
                    padding-right: 9%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    div {
                        display: inline-block;
                        line-height: 20px;
                        border-left: 1px solid $gray-300;
                        padding-left: 10px;
                        padding-right: 10px;
                        &:first-child {
                            width: 80px;
                            label, span, small {
                                font-size: 14px;
                            }
                            border-left: none;
                            padding-left: 0;
                        }
                        label, span, small {
                            font-size: 14px;
                        }
                        span {
                            text-transform: capitalize;
                        }
                    }
                    &.exclusive {
                      label {
                        color: $purple
                      }
                    }
                    label {
                        color: $default;
                        margin: 0;
                        cursor: pointer;
                    }

                    span {
                        color: $black;
                    }
                    small {
                        color: $gray-600;
                        i {
                            margin-top: -10px;
                            display: inline-block;
                            line-height: 9px;
                            vertical-align: middle;
                            font-size: 10px;
                            font-style: normal;
                        }
                    }
                }
                .itens {
                    background-color: $gray-200;
                    width: 100%;
                    &.selected {
                        .parcel {
                            display: flex;
                            align-items: center;
                        }
                        .flag {
                            .logo {
                                border: 1px solid $default;
                            }
                        }
                    }
                    .flag {
                        cursor: pointer;
                        padding: 8px 5px;
                        display: flex;
                        align-items: center;
                        .logo {
                            width: 60px;
                            height: 60px;
                            min-width: 60px;
                            background: $white;
                            border: 1px solid $white;
                            border-radius: 5px;
                            margin-right: 50px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 90%;
                            &.bancopatagonia {
                                background-image: url(../images/logo-banks/ic-bancopatagonia.png);
                            }
                            &.bbva {
                                background-image: url(../images/logo-banks/ic-bbva.png);
                            }
                            &.american-express {
                                background-image: url(../images/logo-banks/ic-amex.png);
                            }
                            &.cabal {
                                background-image: url(../images/logo-banks/ic-cabal.png);
                            }
                            &.diners {
                                background-image: url(../images/logo-banks/ic-diners.png);
                            }
                            &.galicia {
                                background-image: url(../images/logo-banks/ic-galicia.png);
                            }
                            &.hsbc {
                                background-image: url(../images/logo-banks/ic-hsbc.png);
                            }
                            &.icbc {
                                background-image: url(../images/logo-banks/ic-icbc.png);
                            }
                            &.mastercard {
                                background-image: url(../images/logo-banks/ic-mastercard.png);
                            }
                            &.naranja {
                                background-image: url(../images/logo-banks/ic-naranja.png);
                                background-size: 40px;
                            }
                            &.santander {
                                background-image: url(../images/logo-banks/ic-santander.png);
                            }
                            &.visa {
                                background-image: url(../images/logo-banks/ic-visa.png);
                            }
                        }
                        div {
                            font-size: 14px;
                            label {
                                color: $black;
                                margin: 0;
                                cursor: pointer;
                            }
                            span {
                              color: $default;
                            }
                            small {
                                font-size: 14px;
                            }
                            &.two-lines {
                                line-height: 20px;
                                width: 100%;
                                margin-left: -30px;
                                div {
                                    float: right;
                                    width: 100%;
                                    text-align: right;
                                }
                            }
                        }
                        &.exclusive {
                          div span {
                            color: $purple;
                          }
                        }
                    }
                    .parcel {
                        background: $white;
                        padding: 20px 5px;
                        display: flex;
                        align-items: center;
                        &.reverse {
                            background-color: $gray-200;
                        }
                        div {
                            display: inline-block;
                            width: calc(100% - 40px);
                            &:first-child {
                                position: relative;
                                width: 30px;
                                height: 30px;
                                .form-check-label {
                                    position: absolute;
                                    span {
                                        &::before {
                                            height: 20px;
                                            width: 20px;
                                            background-size: cover;
                                        }
                                    }

                                    input {
                                        &:checked {
                                            ~ span {
                                            &:before {
                                                background-position: -20px bottom;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                        label {
                            color: $gray-800;
                            font-family: $regularFontFamily;
                            display: block;
                            line-height: 20px;
                        }
                        span {
                            color: $gray-400;
                            font-family: $boldFontFamily;
                            font-size: 20px;
                            display: block;
                            line-height: 36px;
                        }

                    }
                }
            }
        }

        &__resumen {
            width: 100%;
            padding: 0 10px;
            border: 1px solid $gray-300;
            display: block;
            position: relative;


            .card-number {
                font-family: $boldFontFamily;
                font-size: 24px;
                color: $gray-700;
                padding-top: 10px;
                padding-left: 7px;
                margin-bottom: -10px;
                @include media-large {
                    padding-top: 0px;
                    margin-bottom: 0;
                    @include pos-absolute(left, 18px, top, 14px);
                }
            }

            .banks {
                width: 100%;
                height: 160px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                div {
                    width: 100px;
                    height: 100px;
                    border: 1px solid $default;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 90%;

                    &.bancopatagonia {
                        background-image: url(../images/logo-banks/ic-bancopatagonia.png);
                    }
                    &.bbva {
                        background-image: url(../images/logo-banks/ic-bbva.png);
                    }
                    &.america-express {
                        background-image: url(../images/logo-banks/ic-amex.png);
                    }
                    &.cabal {
                        background-image: url(../images/logo-banks/ic-cabal.png);
                    }
                    &.diners {
                        background-image: url(../images/logo-banks/ic-diners.png);
                    }
                    &.galicia {
                        background-image: url(../images/logo-banks/ic-galicia.png);
                    }
                    &.hsbc {
                        background-image: url(../images/logo-banks/ic-hsbc.png);
                    }
                    &.icbc {
                        background-image: url(../images/logo-banks/ic-icbc.png);
                    }
                    &.mastercard {
                        background-image: url(../images/logo-banks/ic-mastercard.png);
                        background-size: 60px;
                    }
                    &.naranja {
                        background-image: url(../images/logo-banks/ic-naranja.png);
                        background-size: 50px;
                    }
                    &.santander {
                        background-image: url(../images/logo-banks/ic-santander.png);
                    }
                    &.visa {
                        background-image: url(../images/logo-banks/ic-visa.png);
                    }
                }
            }

            .installment {
                display: inline-block;
                width: 100%;
                height: auto;
                border-bottom: 1px solid $gray-300;
                border-top: 1px solid $gray-300;
                border-right: none;
                border-left: none;
                padding: 10px 20px;
                label {
                    color: $gray-600;
                    margin-bottom: 0;
                    font-family: $boldFontFamily;
                }
                span {
                    display: block;
                    font-size: 30px;
                    color: $gray-500;
                    font-family: $boldFontFamily;

                    small {
                        font-size: 20px !important;
                    }
                }
                small {
                    font-size: 16px;
                    color: $gray-500;
                    font-family: $boldFontFamily;
                }
                b {
                    display: block;
                    color: $gray-500;
                    font-family: $boldFontFamily;
                    text-align: center;
                }
                small {
                    display: inline-block;
                    line-height: 24px;
                    font-family: $regularFontFamily;
                    width: 100%;
                    b {
                        font-size: 28px;
                        text-align: left;
                        position: relative;
                        i {
                            font-style: normal;
                            position: absolute;
                            font-size: 14px;
                            top: 0;
                            width: 50px;
                            margin: 0 0 0 10px;
                            line-height: 12px;
                            font-family: $regularFontFamily;
                        }
                        strong {
                            font-style: normal;
                            position: absolute;
                            font-size: 14px;
                            top: 0;
                            margin: 10px 0 0 10px;
                            line-height: 12px;
                            font-family: $regularFontFamily;
                            width: 50px;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .total {
                display: inline-block;
                width: 100%;
                height: auto;
                padding: 10px 20px 60px 10px;
                position: relative;
                label {
                    color: $gray-600;
                    margin-bottom: 0;
                    font-family: $boldFontFamily;
                }
                a {
                    float: none;
                    color: $default;
                    font-family: $ligthFontFamily;
                    font-size: 14px;
                    position: absolute;
                    bottom: 0;
                    width: 180px;
                    display: block;
                    text-align: center;
                    left: 50%;
                    margin-bottom: 30px;
                    margin-left: -90px;
                    background-image: url(../images/svg/icons/orange/ic-pencil.svg);
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    padding: 0 0 0 20px;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                        color: $default;
                    }
                }
                span {
                    display: block;
                    font-size: 20px;
                    color: $gray-600;
                    font-family: $boldFontFamily;
                    &:last-child {
                        font-size: 18px;
                        color: $gray-500;
                    }
                }
            }

        }
    }

    @media (min-width: 768px) {
        &__installment {
            h2 {
                font-size: 32px;
            }
            h4 {
                font-size: 24px;
                text-align: left;
                font-family: $ligthFontFamily;
            }

            &__type-flags {
                padding: 30px 50px;
                margin-top: 20px;
                .flag {
                    width: 100px;
                    margin: 7px 0 7px 9px;
                    .logo {
                        width: 100px;
                        height: 100px;
                        margin-bottom: 10px;
                    }
                    .installment {
                        width: 100px;
                        padding: 5px 10px;
                        background-size: 100%;
                        &.amex {
                            background-size: 60px;
                        }
                        &.cabal {
                            background-size: 60px;
                        }
                        &.diners {
                            background-size: 60px;
                        }
                        &.mastercard {
                            background-size: 60px;
                        }
                        &.naranja {
                            background-size: 50px;
                        }
                        &.visa {
                            background-size: 60px;
                        }
                    }
                    &.exclusive .logo {
                        width: 100px;
                        height: 69px;
                        margin-bottom: 10px;
                    }
                }
            }
            &__numbers {
                padding: 20px;
                margin-top: 30px;
                .installment {
                    .info {
                        width: 100%;
                        padding: 10px 0;
                        line-height: 30px;
                        div {
                            display: inline-block;
                            border-left: 1px solid $gray-300;
                            &:first-child {
                                width: 150px;
                                label, span {
                                    font-size: 20px;
                                }
                                border: none;
                                margin-right: 20px;
                            }
                            label, span {
                                font-size: 16px;
                            }
                        }
                    }
                    .itens {
                        .flag {
                            padding: 8px 10px 8px 90px;
                            .logo {
                                margin-right: 23px;
                            }
                            label, span, small {
                                font-size: 16px;
                            }
                            small {
                                padding-left: 30px;
                            }
                        }
                        .parcel {
                            padding: 20px 110px;
                            div {
                                &:first-child {
                                    width: 60px;
                                }
                            }
                            span {
                                font-size: 36px;
                            }
                        }
                        div {
                            &.two-lines {
                                margin: 0 !important;
                                div {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
            &__resumen {
                padding: 60px 10px;
                display: flex;
                .banks {
                    width: 245px;
                    height: 160px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                }
                .installment {
                    width: 250px;
                    border-left: 1px solid $gray-300;
                    border-right: 1px solid $gray-300;
                    border-top: none;
                    border-bottom: none;
                    padding: 0 30px;
                    height: 160px;
                    span:first-of-type {
                        margin-top: 25px;
                    }
                    label {
                        display: block;
                    }
                    span {
                        position: relative;
                        small {
                            position: absolute;
                            top: 14px;
                            right: 0;
                            width: 40px;
                            line-height: 20px;
                            font-size: 18px !important;
                        }
                    }
                    b {
                        display: block;
                        font-family: $boldFontFamily;
                        text-align: center;
                    }
                    small {
                        display: inline-block;
                        line-height: 24px;
                        font-family: $regularFontFamily;
                        font-size: 14px;
                        b {
                            font-size: 28px;
                            text-align: left;
                            position: relative;
                            i {
                                font-style: normal;
                                position: absolute;
                                font-size: 14px;
                                top: 0;
                                width: 50px;
                                margin: -3px 0 0 10px;
                                line-height: 12px;
                                font-family: $regularFontFamily;
                            }
                            strong {
                                position: relative;
                                margin: 0;
                            }
                        }
                    }
                }
                .total {
                    width: 480px;
                    padding: 0 30px;
                    height: 160px;
                    label {
                        margin-bottom: 25px;
                    }
                    a {
                        position: relative;
                        width: auto;
                        display: block;
                        text-align: left;
                        margin-bottom: 0;
                        margin-left: 0;
                        left: 40px;
                        &.disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                            opacity: .7;
                        }
                    }
                    span {
                        font-size: 30px;
                        color: $gray-500;
                        &:last-child {
                            font-size: 34px;
                            color: $gray-500;
                        }
                    }
                }
            }
        }
    }
}

.checkout.emission {
  .checkout__installment {
    &__resumen {
      .installment {
        span {
          font-size: 23px;
        }
      }

      .total {
        a {
          background-position: 0 10px;
          padding: 10px 0 0 20px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .checkout__installment {
      &__resumen {
        .installment {
          span {
            small {
              top: 8px;
              width: 16px;
            }
          }
        }
        .total {
          span {
            font-size: 23px;
          }
        }
      }

      .keyboard-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.modal-installment-conditions {
    text-align: center;
    max-width: 700px;

    &::before {
        vertical-align: middle;
        content: " ";
        height: 100%;
        display: inline-block;
    }

    &.modal-dialog {
        vertical-align: middle;
        text-align: left;
    }

    .modal-content {
        border: none;
        .modal-header {
            z-index: 1;
            h5 {
                font-size: 16px;
            }
            button {
                padding: 16px 0;
            }
        }
        .modal-body {
            border: 2px dashed $default;
            border-radius: 5px;
            height: 100%;
            max-height: 100%;
            p {
                font-size: 14px;
                font-family: $ligthFontFamily;
                line-height: 18px;
                strong {
                    font-family: $boldFontFamily;
                    color: $default;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .modal-installment-conditions {
        .modal-content {
            .modal-header {
                h5 {
                    font-size: 1.25rem;
                }
                button {
                    padding: 16px;
                }
            }
            .modal-body {
                p{
                    font-size: 22px ;
                    line-height: 30px;
                }
            }
        }
    }
}
