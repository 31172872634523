/*Resultado transferencia de millas inicio*/

    .milles-transfer-success__container {
        padding: 20px 10px 20px 10px;
        margin: 0px;
        @media only screen and (max-width: 768px) {
            padding: 3rem 10px 20px 10px;
        }
        .container {
            width: 1279px !important;
            max-width: 100% !important;
            .resume-wrapper {
                padding: 0px;
                border: 1px solid $gray-500;
                width: 100% !important;
                display: flex;
                @media only screen and (max-width: 991px) {
                    flex-direction: column;
                }
                .resume-wrapper__left {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    .resume-wrapper__left--inner-top {
                        background-color: $primary;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 34px 20px;
                        @media screen and (min-width: 991px) and (max-width: 1060px) {
                            padding: 79px 20px;
                        }
                        @media screen and (min-width: 1061px) and (max-width: 1071px) {
                            padding: 72px 20px;
                        }
                        @media screen and (min-width: 1072px) and (max-width: 1076px) {
                            padding: 60px 20px;
                        
                        }
                        @media screen and (min-width: 1077px) and (max-width: 1085px) {
                            padding: 46px 20px;
                        }
                        .resume-wrapper__icon-check {
                            width: 55px;
                            height: 55px;
                            background-size: 55px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                        }
                        h4 {
                            margin: 20px 0px 20px 10px;
                            color: $white;
                            text-align: center;
                        }
                    }
                    .resume-wrapper__left--inner-bottom {
                        background-color: $white;
                        padding: 20px;
                        display: flex;
                        justify-content: center;
                        @media screen and (min-width: 1086px) and (max-width: 1148px) {
                            padding: 32px 20px;
                        }
                        .resume-wrapper__button-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            max-width: 280px;
                            a {
                                .btn {
                                margin-top: 0px;
                                float: right;
                                padding: 10px 20px;
                                width: 120px;
                                }
                            }
                        }
                    }
                }
                .resume-wrapper__right {
                    padding: 0px;
                    background-color: $white;
                    .resume-wrapper__inner-top {
                        background-color: $white;
                        padding: 10px;
                        height: 202px;
                        @media screen and (min-width: 991px) and (max-width: 1060px) {
                            padding: 10px 10px;
                            height: 322px;
                        }
                        @media screen and (min-width: 1061px) and (max-width: 1071px) {
                            padding: 10px 10px;
                            height: 308px;
                        }
                        @media screen and (min-width: 1072px) and (max-width: 1076px) {
                            padding: 10px 10px;
                            height: 284px;
                        }
                        @media screen and (min-width: 1077px) and (max-width: 1085px) {
                            padding: 46px 10px;
                            height: 256px;
                        }
                        @media screen and (min-width: 1086px) and (max-width: 1148px) {
                            padding: 46px 10px;
                            height: 256px;
                        }
                        .resume-list-top {
                            margin: 0px !important;
                            padding: 0px !important;
                            li {
                                width: 100%;
                                margin-top: 0px;
                                margin-bottom: 0px;
                                .resume-title { 
                                    font-weight: 500;
                                    color: $gray-900;
                                    display: inline-block;
                                }
                                .no-club {
                                    color: $primary;
                                    font-weight: 900;
                                }
                                .diamond {
                                    color: $diamond;
                                    font-weight: 900;
                                }
                                .club {
                                    color: $club;
                                    font-weight: 900;
                                }
                                span {
                                    font-size: 12px !important;
                                    color: $gray-700;
                                }
                                span:first-child {
                                    text-align: right;
                                    width: 30%;
                                }
                                span:last-child {
                                    width: 70%;
                                    margin-left: 15px;
                                }
                                @media only screen and (max-width: 1178px) {
                                    span {
                                        font-size: 12px !important;
                                    }
                                }
                            }
                            hr {
                                margin: 5px 0px !important;
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            .resume-list-top {
                                padding: 0px 0px 0px 20px;
                                li {
                                    margin-top: 0px;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                    .resume-wrapper__inner-bottom {
                        background-color: $gray-200;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 10px;
                        @media only screen and (max-width: 500px) {
                            flex-direction: column;
                        }
                        .resume-list-bottom {
                            padding: 0px 0px 0px 0px;
                            margin: 0px;
                            width: 50%;
                            @media only screen and (max-width: 991px) {
                                padding: 0px 0px 0px 5px;
                                width: 100%;
                            }
                            li {
                                width: 100%;
                                margin-top: 0px;
                                .resume-title {   
                                    font-weight: 500;
                                    color: $gray-900;
                                    display: inline-block;
                                }
                                span {
                                    color: $gray-700;
                                    font-size: 11.5px;
                                }
                                .resume-icon-user {
                                    margin-right: 10px;
                                    display: inline-block;
                                    width: 25px;
                                    height: 22px;
                                    background-size: 25px;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                                }
                                .resume-icon-tdc {
                                    margin-right: 10px;
                                    display: inline-block;
                                    width: 25px;
                                    height: 17px;
                                    background-size: 25px;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    background-image: url(#{$svg-path}/icons/gray/ic-credit-card.svg);
                                }
                            }
                        }
                    }
                }
            }
            .resume__banner-bottom {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
            .resume__banner-right {
                margin-left: 1.5rem;
            }
            @media only screen and (max-width: 991px) {
                .resume__banner-right {
                    margin-left: 0px;
                }    
            }
        }
    }

/*Resultado transferencia de millas fin*/