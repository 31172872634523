.date-picker {
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;

    .DayPicker_transitionContainer {
      border: 2px solid $primary;
    }
  }

  .SingleDatePickerInput_calendarIcon {
    float: right;
  }

  .DateRangePicker {
    top: 12px;
  }

  &.endDate {
    position: absolute;
    left: 93px;
  }
  --before-top: 2px;
}

.SingleDatePickerInput .SingleDatePickerInput_1 {
  position: relative;
}
.SingleDatePicker_picker {
  margin-left: -1.8rem;
  top: calc(100% + 17px) !important;
}

.CalendarMonth_caption {
  text-transform: uppercase;
  font-weight: bold;
  color: $gray-700;
  line-height: .9;

  strong {
    @include bms-font-size(3.4vw, 14px);

    width: min-content;
    display: inline-block;
    color: $secondary;
  }

  .month-name {
    font-family: $boldFontFamily;
    @include bms-font-size(3.8vw, 14px);
  }

  .year {
    font-family: $ligthFontFamily;
    @include bms-font-size(3.4vw, 12px);
  }
}

.DateInput_fang {
  top: calc(100% + 10px) !important;
}

.DateInput_fangStroke {
  stroke: $primary;
  stroke-width: 2px;
}

.DateInput_input,
.DateInput_input__focused {
  border-bottom: 0;
}

.DateInput {
  margin-left: 1px;
}

.DayPicker__withBorder {
  box-shadow: unset;
}

.DateInput, .DateInput_input {
  background: unset;
}

.DayPicker_weekHeader {
  padding: 10px 13px 0 !important;
}

.DayPicker_weekHeader_li {
  text-transform: uppercase;
  // @HACK - hide the weekDay dot with ugly negative margin
  // due to @BUG on react-dates
  overflow: hidden;
  position: relative;
  height: 20px;

  small {
    color: $secondary;
    font-weight: bolder;
    position: absolute;
    right: -2px;
  }
}

.DateRangePicker_picker {
  border: 2px solid $primary;
  left: -27px !important;
  top: 5px;

  &:before {
    content: "";
    width: 15px;
    height: 16px;
    display: inline-block;
    position: absolute;
    transform: rotate(45deg);
    border-top: 2px solid $primary;
    border-left: 2px solid $primary;
    top: -8px;
    left: 47px;
    background: white;
  }
}

.DayPickerNavigation_button {
  border: 0;
}

@mixin CalendarDay-white-border {
  border: 0;
  border-top: 5px solid white;
  border-bottom: 5px solid white;
}

.CalendarDay__highlighted_calendar {
  font-weight: bold;
  color: $primary;
  background-color: unset;
}

.CalendarDay__default {
  border: 1px solid transparent;
}

.CalendarDay__selected {
  background: unset;
  color: white;
  font-weight: bold;
  position: relative;
  z-index: 1;

  &:active {
    border: 0;
    background-color: unset;
    color: $primary;

    &:before {
      opacity: 0;
    }
  }

  &:hover {
    border: 0;
    background-color: unset;
  }

  &:before {
    content: '';
    background: $primary;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity ease-in .2s;
  }

  &.CalendarDay__selected_start {
    &:before {
      height: 33px;
      top: 2px;
      left: 1px;
      border-radius: 50% 0 0 50%;
    }
  }

  &.CalendarDay__selected_end {
    &:before {
      height: 33px;
      top: var(--before-top);
      left: -1px;
      border-radius: 0 50% 50% 0;
    }
  }
}

.CalendarDay__selected_span {
  @include CalendarDay-white-border();
  background: rgba(255, 90, 0, 0.4);
  color: #000;

  &:active, &:hover {
    @include CalendarDay-white-border();
    background: rgb(255, 90, 0);
    color: #000;
  }
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  @include CalendarDay-white-border();
  background: rgba(255, 90, 0, 0.4);
  color: #000;
}

.customArrow {
  transform: scale(2.3);
  margin-bottom: 0;
  padding-top: 10px;
}

.searchDay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
