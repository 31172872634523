.btn {
  &.btn-club {
      color: $white;
      background-color: $club;
      &:hover {
          background-color: lighten($club, 5%);
      }
  }
  &.btn-family-account {
    color: $white;
    background-color: $family-account;
    border-color: $family-account;
  }
}

.wp-float {
  position:fixed;
  width:50px;
  height:50px;
  bottom:20px;
  right:20px;
  background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 9000;

  @media screen and (max-width: 768px) {
    left: 20px;
    right: unset;
  }
}
