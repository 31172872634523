.smls-gray-icons {
    font-size: 10px;
}

.smls-gray-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-gray-icons.svg");
    background-repeat: no-repeat;
    background-size: 95.95em 277.5em;
}

.no-svg .smls-gray-icons:before {
    background-image: url("../sprites/smls-gray-icons.png");
}



  .smls-gray-icons.ic-adult:before {
      background-position: 0em 0em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-airplane:before {
      background-position: 0em -1.6em;
      width: 2em;
      height: 1.8em;
  }




  .smls-gray-icons.ic-airplane-solid:before {
      background-position: 0em -3.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-airplane-two:before {
      background-position: 0em -5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-alert-info:before {
      background-position: 0em -6.6em;
      width: 2em;
      height: 2em;
  }




  .smls-gray-icons.ic-all-classes:before {
      background-position: 0em -8.6em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-gray-icons.ic-anticipation:before {
      background-position: 0em -11.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-arrow-down:before {
      background-position: 0em -12.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-arrow-flight-details:before {
      background-position: 0em -14.3em;
      width: 9.2em;
      height: 1.2em;
  }




  .smls-gray-icons.ic-arrow-left:before {
      background-position: 0em -15.5em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-arrow-lighter:before {
      background-position: 0em -17.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-arrow-right:before {
      background-position: 0em -18.7em;
      width: 1.05em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-arrow-up:before {
      background-position: 0em -20.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-baby:before {
      background-position: 0em -21.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-block:before {
      background-position: 0em -23.5em;
      width: 1.7167000000000001em;
      height: 1.7167000000000001em;
  }




  .smls-gray-icons.ic-blocked:before {
      background-position: 0em -25.3em;
      width: 1.6em;
      height: 1.2em;
  }




  .smls-gray-icons.ic-cabine:before {
      background-position: 0em -26.5em;
      width: 1.5em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-calendar-check:before {
      background-position: 0em -28.1em;
      width: 2em;
      height: 2em;
  }




  .smls-gray-icons.ic-card:before {
      background-position: 0em -30.1em;
      width: 6em;
      height: 3.6em;
  }




  .smls-gray-icons.ic-check:before {
      background-position: 0em -33.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-check-box-error:before {
      background-position: 0em -35.3em;
      width: 5em;
      height: 5em;
  }




  .smls-gray-icons.ic-child:before {
      background-position: 0em -40.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-close:before {
      background-position: 0em -41.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-close-circle:before {
      background-position: 0em -43.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-close-strong:before {
      background-position: 0em -45.1em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-cloud:before {
      background-position: 0em -46.7em;
      width: 7.5102em;
      height: 4.4455em;
  }




  .smls-gray-icons.ic-cloud_white:before {
      background-position: 0em -51.2em;
      width: 4.8565em;
      height: 2.9971em;
  }




  .smls-gray-icons.ic-clouds:before {
      background-position: 0em -54.2em;
      width: 3.4em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-coin:before {
      background-position: 0em -55.8em;
      width: 10em;
      height: 10em;
  }




  .smls-gray-icons.ic-credit-card:before {
      background-position: 0em -65.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-creditcard-default:before {
      background-position: 0em -67.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-delete:before {
      background-position: 0em -69em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-gray-icons.ic-departure:before {
      background-position: 0em -71.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-departure-solid:before {
      background-position: 0em -73em;
      width: 2.9em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-dropdown:before {
      background-position: 0em -74.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-dropdown-padding:before {
      background-position: 0em -76.2em;
      width: 3.2em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-economic-class:before {
      background-position: 0em -77.8em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-gray-icons.ic-edit:before {
      background-position: 0em -80.3em;
      width: 2.4em;
      height: 2.4em;
  }




  .smls-gray-icons.ic-email:before {
      background-position: 0em -82.7em;
      width: 10em;
      height: 10em;
  }




  .smls-gray-icons.ic-email-resend:before {
      background-position: 0em -92.7em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-equal:before {
      background-position: 0em -94.3em;
      width: 95.95em;
      height: 95.95em;
  }




  .smls-gray-icons.ic-executive-class:before {
      background-position: 0em -190.3em;
      width: 2.5em;
      height: 2.5em;
  }




  .smls-gray-icons.ic-expiration:before {
      background-position: 0em -192.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-file:before {
      background-position: 0em -194.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-file-list:before {
      background-position: 0em -196em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-filter:before {
      background-position: 0em -197.6em;
      width: 1.497em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-gear:before {
      background-position: 0em -199.2em;
      width: 7.5em;
      height: 8.1em;
  }




  .smls-gray-icons.ic-house:before {
      background-position: 0em -207.3em;
      width: 10em;
      height: 10em;
  }




  .smls-gray-icons.ic-leg-trip-one:before {
      background-position: 0em -217.3em;
      width: 9em;
      height: 2.5em;
  }




  .smls-gray-icons.ic-leg-trip-two:before {
      background-position: 0em -219.8em;
      width: 17.2em;
      height: 2.5em;
  }




  .smls-gray-icons.ic-luggage-silver:before {
      background-position: 0em -222.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-miles-stack:before {
      background-position: 0em -223.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-od-arrow:before {
      background-position: 0em -225.5em;
      width: 1.6em;
      height: 0.41em;
  }




  .smls-gray-icons.ic-od-arrow-big:before {
      background-position: 0em -226em;
      width: 0.4747em;
      height: 0.35750000000000004em;
  }




  .smls-gray-icons.ic-order-address:before {
      background-position: 0em -226.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-order-creditcard:before {
      background-position: 0em -228em;
      width: 1.6em;
      height: 1.1em;
  }




  .smls-gray-icons.ic-order-creditcardtype:before {
      background-position: 0em -229.1em;
      width: 1.6em;
      height: 1.1em;
  }




  .smls-gray-icons.ic-order-creditcarduser:before {
      background-position: 0em -230.2em;
      width: 1.6em;
      height: 1.1em;
  }




  .smls-gray-icons.ic-order-paymentform:before {
      background-position: 0em -231.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-passenger:before {
      background-position: 0em -232.9em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-passengers:before {
      background-position: 0em -234.5em;
      width: 2.0989999999999998em;
      height: 1.8219999999999998em;
  }




  .smls-gray-icons.ic-payment-details:before {
      background-position: 0em -236.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-pencil:before {
      background-position: 0em -238em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-phone:before {
      background-position: 0em -239.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-phone-checkout:before {
      background-position: 0em -241.2em;
      width: 10em;
      height: 10em;
  }




  .smls-gray-icons.ic-pin:before {
      background-position: 0em -251.2em;
      width: 1.5em;
      height: 2em;
  }




  .smls-gray-icons.ic-print:before {
      background-position: 0em -253.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-question:before {
      background-position: 0em -254.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-renewal-cycle:before {
      background-position: 0em -256.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-search:before {
      background-position: 0em -258em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-seats:before {
      background-position: 0em -259.6em;
      width: 13em;
      height: 8em;
  }




  .smls-gray-icons.ic-simple-calendar:before {
      background-position: 0em -267.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-sort:before {
      background-position: 0em -269.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-suitcase-solid:before {
      background-position: 0em -270.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-trash-can:before {
      background-position: 0em -272.4em;
      width: 1.8em;
      height: 1.9em;
  }




  .smls-gray-icons.ic-user-credit-card:before {
      background-position: 0em -274.3em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-gray-icons.ic-warning:before {
      background-position: 0em -275.9em;
      width: 1.6em;
      height: 1.6em;
  }





