.jarvis-service-details-container {
    margin-top: 40px;
    .service-details-wrapper{
        padding: 50px 70px;
        .service-details{
            .passengers-container{
                border-radius: 3px;
                border: 1px solid rgb(204, 204, 204);
                .hr-border-primary{
                    border-color: $primary !important;
                }
            }
    
        }
    }
}

@media only screen and (max-width: 575px){
    .jarvis-service-details-container {
        .service-details-wrapper{
            padding: 50px 0px !important;
            .service-details{
                .passengers-container{
                    padding: 25px;
                }
        
            }
        }
    }
}


@media only screen and (min-width: 1400px) {
    .jarvis-service-details-container {
      .service-details-wrapper {
        padding: 20px 120px !important;
      }
    }
  }
  
  @media only screen and (min-width: 1550px) {
    .jarvis-service-details-container {
      .service-details-wrapper {
        padding: 20px 340px !important;
      }
    }
  }
  