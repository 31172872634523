.search-flight {
  .order-summary {
    padding: 2vw 3vw;
    .line-separator {
      width: 100%;
      height: 2px;
      background: $gray-300;
      display: block;
      position: relative;
      margin: 5px 0 5px 0;
    }
    .order-summary-header {
      border-top: 2px solid $gray-300;
      h5 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
    .products-header {
      span {
        @include bms-font-size(4vw, 14px);
        font-weight: bold;
        color: $gray-600;
      }
    }
    .product-line {
      span {
        @include bms-font-size(3vw, 14px);
        font-weight: bold;
        color: $gray-700;
      }
    }
    .product-line-light {
      span {
        @include bms-font-size(3vw, 14px);
        font-weight: bold;
        color: $gray-500;
      }
    }
    .product-line-interes {
      span {
        @include bms-font-size(4vw, 14px);
        color: $primary-light-200;
      }
    }
    .product-totals {
      span {
        @include bms-font-size(4vw, 15px);
        color: $gray-500;
        &.total-destac-person {
          color: $primary;
        }
      }
      .total-values {
        span {
          color: $gray-700;
          font-weight: bold;
        }
      }
    }
    .conditions-header {
      @include bms-font-size(4vw, 14px);
      background-color: $gray-300;
      text-transform: uppercase;
    }
    .conditions-line {
      @include bms-font-size(4vw, 14px);
      color: $gray-700;
      .strong {
        span {
          font-weight: bold;
        }
      }
      .info-fare-conditions {
        color: $primary-light-200;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-style: normal;
          border: 1px solid $primary-light-200;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
    .easy-travel-warning{
      margin-top: 50px
    }
    .accept-rules-terms {
      padding-left: 1.25rem;
      text-align: left;
      @include bms-font-size(4.6vw, 12px);
      margin: 10px 0 10px 0;
      .form-check-label {
        // position: relative;
        margin: 0 0 10px 0;
      }
    }
    .slider {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
      &-header,
      &-values {
        flex-basis: 100%;
        min-width: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        font-family: $boldFontFamily;
        > span {
          margin: 0 0;
          cursor: pointer;
        }
      }

      &-header {
        @include bms-font-size(4.5vw, 18px);
        margin: 0 auto;
        -webkit-order: 1;
        order: 1;
      }
      &-values {
        @include bms-font-size(4vw, 18px);
        margin: 10px auto;
        -webkit-order: 2;
        order: 2;
        > span {
          padding: 10px;
          color: $gray-500;
          border: 1px solid $gray-500;
          border-radius: 5px;
          &.selected {
            display: inline-block;
            color: $white;
            background-color: $primary;
            border-color: $primary;
          }
        }

      }
      &-range {
        flex-basis: 100%;
        min-width: 100%;
        margin: 0 auto;
        -webkit-order: 3;
        order: 3;
      }
    }

  }
}

// medium devices (tablet, 768px and up)
@media (min-width: 768px) {
  .search-flight {
    .order-summary {
      border: 2px solid $gray-300;
      line-height: 1.5;
      &.taxes {
        border-bottom: none;
      }
      .order-summary-header {
        border-top: none;
        margin: 20px 0 20px 0;
      }
      .accept-rules-terms {
        margin-top: 50px;
        text-align: center;
        position: relative;
        font-size: 14px;
      }
      .continue-btn {
        text-align: center;
        .disabled {
          cursor: no-drop;
        }
      }
      .btn {
        width: 160px;
        margin: 0 auto;
      }
      .form-check-label {
        span {
          &:before {
            position: relative;
          }
        }
      }
      .slider {
        display: block;
        width: 400px;
        &-header {
          width: 320px;
          min-width: 320px;
        }
        &-range {
          width: 280px;
          min-width: 280px;
        }
        &-values {
          margin: 5px auto;
          > span {
            border: none;
            &.selected {
              color: $gray-700;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
