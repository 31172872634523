.club-smiles {
    &__reactive-success {
        width: 100%;
        border: none;
        padding: 20px 10px;

        h2 {
            font-size: 20px;
            text-align: left;
            color: $default;
            width: 100%;
            background-repeat: no-repeat;
            position: relative;
            background-size: 14px;
            padding-left: 34px;
        }

        h3 {
            font-size: 24px;
            text-align: center;
            color: $club;
            width: 100%;
        }

        table {
            width: 100%;
            border: 1px solid $gray-300;
            margin: auto;
            font-size: 14px;
            padding: 10px;
            display: block;
            thead {
                width: 100%;
                display: table;
                tr {
                    border-bottom: 1px solid $gray-300;
                    th {
                        width: 24%;
                        text-align: right;
                        padding: 15px 0;
                        font-family: $boldFontFamily;
                        &:first-child {
                            text-align: left;
                            width: 75%;
                        }
                    }
                }
            }
            tbody {
                width: 100%;
                display: table;
                tr {
                    border-bottom: 1px solid $gray-300;
                    &:last-child {
                        border-bottom: none;
                    }
                    td {
                        width: 24%;
                        text-align: right;
                        padding: 15px 0;
                        &:first-child {
                            text-align: left;
                            width: 75%;
                        }
                    }
                    &:last-child {
                        font-family: $boldFontFamily;
                    }
                }
            }
        }
        button {
            display: block;
            margin: 20px auto 0 auto;
            border-radius: 3px;
            background: $default;
            color: $white;
            border: none;
            font-size: 12px;
            padding: 7px 50px;
        }
    }
    @media (min-width: 768px) {
        &__reactive-success {
            border: 1px solid $gray-300;
            padding: 20px 40px;
            table {
                font-size: 16px;
                padding: 28px;
                width: 1016px;
            }
            button {
                display: block;
                margin: 50px auto 0 auto;
            }
        }
    }
}
