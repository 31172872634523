.logo-airlines {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.turkish, &._tk { background-image: url(#{$svg-path}/airlines/turkish.svg); }
  &.gol, &._g3 { background-image: url(#{$svg-path}/airlines/gol.svg); }
  &.airfrance, &._af { background-image: url(#{$svg-path}/airlines/airfrance.svg);}
  &.aerolineas-argentinas, &._ar, &._au { background-image: url(#{$svg-path}/airlines/aerolineas-argentinas.svg);}
  &.air-canada, &._ac { background-image: url(#{$svg-path}/airlines/air-canada.svg);}
  &.aero-mexico, &._am { background-image: url(#{$svg-path}/airlines/aero-mexico.svg);}
  &.alitalia, &._az { background-image: url(#{$svg-path}/airlines/logoita2.png);}
  &.sky, &._h8 { background-image: url(#{$svg-path}/airlines/skyperu-h2.png);}
  &.american-airlines, &._aa { background-image: url(#{$svg-path}/airlines/americanairlines.svg); }
  &.copa-airlines, &._cm { background-image: url(#{$svg-path}/airlines/copa-airlines.svg);}
  &.delta, &._dl { background-image: url(#{$svg-path}/airlines/delta.svg);}
  &.emirates, &._ek { background-image: url(#{$svg-path}/airlines/emirates.svg);}
  &.ethiopian, &._et { background-image: url(#{$svg-path}/airlines/ethiopian.svg);}
  &.etihad, &._ey { background-image: url(#{$svg-path}/airlines/etihad.svg);}
  &.klm, &._kl { background-image: url(#{$svg-path}/airlines/klm.svg);}
  &.korean-air, &._ke { background-image: url(#{$svg-path}/airlines/korean-air.svg);}
  &.passaredo, &._passaredo, &._2z { background-image: url(#{$svg-path}/airlines/passaredo.svg);}
  &.qatar, &._qr { background-image: url(#{$svg-path}/airlines/qatar.svg);}
  &.royal-air-maroc, &._at { background-image: url(#{$svg-path}/airlines/royal-air-maroc.svg);}
  &.south-african,  &.south-african-airways, &._sa { background-image: url(#{$svg-path}/airlines/south-african-airways.svg);}
  &.tap, &._tp { background-image: url(#{$svg-path}/airlines/tap.svg);}
  &.aev1, &._ux { background-image: url(#{$svg-path}/airlines/aev1.svg);}
  &.taag, &._dt { background-image: url(#{$svg-path}/airlines/taag.svg);}
  &.avianca, &._av, &._ta, &._lr, &._gu, &._t0, &._2k { background-image: url(#{$svg-path}/airlines/avianca.svg);}

  &.americanairlines, &._aa { background-image: url(#{$svg-path}/airlines/americanairlines.svg);}

  &._4o, &.interjet { background-image: url(#{$svg-path}/airlines/hahn-air/4o_interjet.svg);}
  // &._9w, &.jet-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/9w_jet-airways.svg);}
  &._a3, &.aegean-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/a3_aegean-airlines.svg);}
  &._ai, &.air-india {  background-image: url(#{$svg-path}/airlines/hahn-air/ai_air-india.svg);}
  &._as, &.alaska-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/as_alaska-airlines.svg);}
  &._bt, &.air-baltic-corporation.svg {  background-image: url(#{$svg-path}/airlines/hahn-air/bt_air-baltic-corporation.svg);}
  // &._cz, &.china-southern-airlines.svg {  background-image: url(#{$svg-path}/airlines/hahn-air/cz_china-southern-airlines.svg);}
  &._ei, &.aer-lingus {  background-image: url(#{$svg-path}/airlines/hahn-air/ei_aer-lingus.svg);}
  // &._eq, &.tame {  background-image: url(#{$svg-path}/airlines/hahn-air/eq_tame.svg);}
  &._ga, &.garuda-indonesia {  background-image: url(#{$svg-path}/airlines/hahn-air/ga_garuda-indonesia.svg);}
  &._gq, &.sky-express-greece {  background-image: url(#{$svg-path}/airlines/hahn-air/gq_sky-express-greece.svg);}
  &._h1, &.h1_h1 {  background-image: url(#{$image-path}/airlines/hahn-air.jpeg);}
  &._ha, &.hawaiian-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/ha_hawaiian-airlines.svg);}
  &._ho, &.juneyao-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/ho_juneyao-airlines.svg);}
  &._jq, &.jetstar-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/jq_jetstar-airways.svg);}
  &._kq, &.kenya-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/kq_kenya-airways.svg);}
  &._me, &.middle-east-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/me_middle-east-airlines.svg);}
  &._ms, &.egyptair {  background-image: url(#{$svg-path}/airlines/hahn-air/ms_egyptair.svg);}
  &._mu, &.china-eastern-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/mu_china-eastern-airlines.svg);}
  &._ou, &.croatia-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/ou_croatia-airlines.svg);}
  &._pg, &.bangkok-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/pg_bangkok-airways.svg);}
  &._py, &.surinam-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/py_surinam-airways.svg);}
  &._sg, &.spicejet {  background-image: url(#{$svg-path}/airlines/hahn-air/sg_spicejet.svg);}
  // &._sn, &.brussels-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/sn_brussels-airlines.svg);}
  &._up, &.bahamasair {  background-image: url(#{$svg-path}/airlines/hahn-air/up_bahamasair.svg);}
  &._v7, &.volotea {  background-image: url(#{$svg-path}/airlines/hahn-air/v7_volotea.svg);}
  &._va, &.virgin-australia {  background-image: url(#{$svg-path}/airlines/hahn-air/va_virgin-australia.svg);}
  // &._vy, &.vueling-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/vy_vueling-airlines.svg);}
  &._wm, &.winair {  background-image: url(#{$svg-path}/airlines/hahn-air/wm_winair.svg);}
  &._zp, &.paranair {  background-image: url(#{$svg-path}/airlines/hahn-air/zp_paranair.svg);}
  &._ob, &.boa {  background-image: url(#{$svg-path}/airlines/hahn-air/ob_boa.svg);}
  &._tr, &.scoot-tigerair {  background-image: url(#{$svg-path}/airlines/hahn-air/tr_scoot.svg);}
  &._mn, &.kulula {  background-image: url(#{$svg-path}/airlines/hahn-air/mn_kulula.svg);}
  &._cx, &.cathay-pacific {  background-image: url(#{$svg-path}/airlines/hahn-air/cx_cathay-pacific.svg);}
  &._tg, &.thai-airways {  background-image: url(#{$svg-path}/airlines/hahn-air/tg_thai-airways.svg);}
  &._s7, &.siberia-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/s7_s7-airlines.svg);}
  &._fa, &.fly-safair {  background-image: url(#{$svg-path}/airlines/hahn-air/fa_fly-safair.svg);}

  &._z7, &._z8, &.amaszonas {  background-image: url(#{$svg-path}/airlines/amaszonas.svg);}
  &._ba, &.british {  background-image: url(#{$svg-path}/airlines/british.svg);}
  &._ib, &.iberia {  background-image: url(#{$svg-path}/airlines/iberia.svg);}

  &._jl, &.japan-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/jl_japan-airlines.svg);}
  &._nh, &.ana {  background-image: url(#{$svg-path}/airlines/hahn-air/nh_all-nippon-airways.svg);}
  &._ok, &.czech-airlines {  background-image: url(#{$svg-path}/airlines/hahn-air/ok_czech-airlines.svg);}
  &._ps, &.ukraine {  background-image: url(#{$svg-path}/airlines/hahn-air/ps_ukraine-international-airlines.svg);}

  &._tr, &.scoot {  background-image: url(#{$image-path}/airlines/scoot.png);}
  &._h2, &.sky {  background-image: url(#{$image-path}/airlines/sky.png);}
  &._vh, &.viva-colombia, &._vv, &.viva-peru {  background-image: url(#{$image-path}/airlines/viva-colombia.png);}
}
