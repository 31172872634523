.my-card {
  @include bms-base-box;
  padding: 25px;

  .title {
    font-size: 20px;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
    border: none;

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: flex-start;
    }

    > div {
      position: relative;
      margin: 0 5px;

      img {
        width: 327px;
      }
    }

    .card-info {
      position: absolute;
      left: 30px;
      bottom: 15px;
      font-family: $font-family-monospace;
      color: #fff;

      p {
        margin: 0;
        line-height: 100%;
      }

      .registration {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;

        span {
          width: 35%;
        }

        .label {
          width: 15%;
          margin-right: 5px;
          font-size: 10px;
          font-weight: bold;
          line-height: 1.2em;
        }
      }
    }
  }

  .print {
    display: flex;
    justify-content: flex-end;

    span {
      display: flex;
      align-items: center;
      margin: 0 0 20px 0px;

      &:before {
        content: '';
        background-image: url(#{$svg-path}/icons/gray/ic-print.svg);
        height: 28px;
        width: 28px;
        background-size: 100%;
        margin-right: 5px;
        position: relative;
      }
    }
  }
}



@media print {
  header,
  footer,
  .my-account__header-info,
  .my-account__sidebar {
    display: none;
  }

  .my-card {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;

    .title,
    .description,
    .print {
      display: none;
    }

    .card {
      flex-direction: row;
      align-items: flex-start;
      margin: 0;
      border: none;

      .card-info {
        font-family: $font-family-monospace;
        color: #fff;

        p {color: #fff;}
      }
    }
  }
}
