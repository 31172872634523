@media (max-width: 767px) { 
    .visible-phone {
        display: block;
    }

    .visible-desktop {
        display: none;
    }
}

@media (min-width: 768px) { 
    .visible-phone {
        display: none;
    }
    
    .visible-desktop {
        display: block;
    }    
}