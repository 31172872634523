.retrocredit {

  .border-left {
    border-left: 1px solid $primary !important;
  }

  .prepended:before {
    margin: {
      left: -1.7rem;
      right: 5px;
      bottom: 10px;
    };
    font-size: large;
    background-color: white;
  }
  .prepended-bigger {
    font-size: x-large;
  }
  .ic-reverse:before {
    transform: scale(-1, 1);
  }

  .ic-point {
    position: relative;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: {
      left: -1.8em;
      right: 10px;
    };

    &:before, &:after {
      content: ' ';
      vertical-align: middle;
      border-radius: 50%;
    }

    &:before {
      display: inline-block;
      border: 1px solid $primary;
      width: 1em;
      height: 1em;
      background-color: white;
    }
  }

  .ic-point-marked {
    position: relative;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: {
      left: -1.8em;
      right: 10px;
    };

    &:before, &:after {
      content: ' ';
      vertical-align: middle;
      border-radius: 50%;
    }

    &:before {
      display: inline-block;
      border: 1px solid $primary;
      width: 1em;
      height: 1em;
      background-color: $primary;
    }

    &:after {
      position: absolute;
      background-color: white;
      width: .4em;
      height: .4em;
    }
  }

  .range-control {
    position: relative;

    .control {
      position: absolute;
      right:0;
      height: 50%;
      width: 20px;
      cursor: pointer;
      display: none;
      background: {
        size: 50% 50%;
        repeat: no-repeat;
        position: center left;
      };

      @media (min-width: $site-width-mobile) {
        display: block;
      }

      &.increase {
        background-image: url('/images/svg/icons/gray/ic-arrow-up.svg');
        top:0;
      }

      &.decrease {
        background-image: url('/images/svg/icons/gray/ic-arrow-down.svg');
        bottom:0;
      }
    }
  }

  .airport-row .helper-field > .row {
    .form-group  {
      padding: 0px;
    }
    @media (min-width: map-get($container-max-widths, lg)) {
      .form-group {
        margin-bottom: 5px;
      }

      & > div:first-child {
        padding-right: 20px;

        &:after {
          content: " ";
          color: $primary;
          position: absolute;
          background: url('/images/svg/icons/orange/ic-arrow-right-long.svg') no-repeat;
          background-size: contain;
          width: 23px;
          height: 25px;
          left: calc(100% - 12px);
          top: calc(50% - 12px);
        }
      }

      & > div:nth-child(2) {
        padding-left: 20px;
      }
    }
  }

  @media (max-width: $site-width-mobile) {
    .ticket-input {
      > div {
        width: auto !important;
        padding-right: 10px !important;
      }
    }
  }
}
