@import "summary";
@import "cancel-taxes";
@import "boxViajeFacil";
@import "club-smiles";
@import "voucher-container";
@import "goback";

//Success Congenere
@import "success/boxFlight";
@import "success/boxInfoStatus";
@import "success/boxMoreOptions";
@import "success/boxPayment";
@import "success/boxPaymentSeats";
@import "success/boxSeats";

//Success G3
@import "success/completed-g3";
@import "seats";

//Tooltip
@import "tooltip-checkout";

//New Installment
@import "new-installments";

//New Installment
@import "two-credit-cards-form";

//Card Resume responsive rules
@import "card-resume";

//Error modal
@import './two-cards-error-modal';
