.jarvis-serviceSelected-container{
    margin-top: 50px;
    .services-container{
        margin-top: 20px;
        display: flex;
        border-radius: 3px;
        border: 1px solid #ccc;
        .left-img{
            background-color:$primary;
            width: 142px;
            padding: 30px;
            img{
                width: 150px;
            }
        }
        .service-content{
            padding:50px 40px 30px 70px;
            span{
                font-size: .9em;
            }
            div{
                div{
                    div{
                        div{
                            .jarvis-label-container{
                                .jarvis-label {
                                    display: block;
                                    position: relative;
                                    margin-bottom: 12px;
                                    cursor: pointer;
                                    font-size: 16px;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;
                                    input {
                                        position: absolute;
                                        opacity: 0;
                                        cursor: pointer;
                                        &:checked {
                                            ~ {
                                                .checkmark {
                                                    background-color: $gray-800;
                                                    &:after {
                                                        display: block;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &:hover {
                                        input {
                                            ~ {
                                                .checkmark {
                                                    background-color: $gray-500;
                                                }
                                            }
                                        }
                                    }
                                    .checkmark {
                                        &:after {
                                            left: 10px;
                                            top: 4px;
                                            width: 5px;
                                            height: 15px;
                                            border: solid white;
                                            border-width: 0 3px 3px 0;
                                            -webkit-transform: rotate(45deg);
                                            -ms-transform: rotate(45deg);
                                            transform: rotate(45deg);
                                        }
                                    }
      
                                }
                                div{
                                    flex-wrap: nowrap;
                                    .tooltip-data{
                                        cursor: pointer;
                                        background-color:$gray-800,;
                                        color: $white;
                                        padding: 2px 9px;
                                        margin-bottom:8px;
                                        border-radius:15px;
                                        line-height: 1.1;
                                        font-family: $boldFontFamily;
                                        font-size: 1em;
                                    }
                                }
                                .description{
                                    font-size: .73em;
                                    font-family: $semiBoldFontFamily;
                                    line-height: 1.4;
                                }
                                .checkmark {
                                    position: absolute;
                                    top: 22px;
                                    left: -40px;
                                    height: 25px;
                                    width: 25px;
                                    border: 1px solid $gray-500;
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
        }
        }
    }
}

@media only screen and (max-width: 575px){
    .jarvis-serviceSelected-container{
        margin-top: 50px;
        .services-container{
            margin-top: 20px;
            display: flex;
            border-radius: 3px;
            border: 1px solid #ccc;
            .left-img{
                background-color:$primary;
                width: 56px;
                padding: 10px;
                img{
                    width: 150px;
                }
            }
            .service-content{
                padding:40px 15px 40px 55px;
            }
        }
    }

}