.nav-option-partner {
  padding-bottom: 4vw;
  .nav-tabs {
      border-bottom: 2px solid $primary;
      .nav-item {
          margin-bottom: -2px;
          margin-right: 5px;
      }
      .nav-link {
          position: relative;
          color: $gray-700;
          font-size: 14px;
          font-weight: 700;
          border: 2px solid $gray-500;
          border-bottom-color: $primary;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          &:first-child {
              width: 100%;
              text-align: left;
              padding-left: 0;
              border-color: transparent;
              &:hover {
                  border-color: transparent;
              }
          }
          &.active {
              border-color: $primary $primary #fff;
          }
          &.logo {
              display: block;
              width: 100px;
              height: 100%;
              background-position: center center;
              background-size: 40px;
              background-repeat: no-repeat;
              overflow: hidden;
              text-indent: -1000px;
              &.gool {
                  background-image: url(#{$svg-path}/airlines/gol.svg);
              }
          }
          &.congenere {
            --background-img: url(#{$svg-path}/misc/tooltip-congenere.svg);
            &:before {
              content: " ";
              position: absolute;
              top: -55px;
              left: 5px;
              width: 210px;
              height: 70px;
              background-image: var(--background-img);
              background-position: left top;
              background-size: 100%;
              background-repeat: no-repeat;
            }
          }
      }
  }
}

// medium devices (tablet, 768px and up)
@media (min-width: 768px) {
  .nav-option-partner {
      padding-bottom: 1vw;
      .nav-tabs {
          .nav-link {
              &:first-child {
                  width: auto;
              }
              &.congenere {
                &:before {
                  top: -75px;
                  left: 30px;
                  width: 263px;
                  height: 80px;
                }
              }
          }
      }
  }
}
