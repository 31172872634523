.home {
  // slide img
  .content-image-div {
      margin: 0 10px 20px 10px;
      display: flex;
      justify-content: center;

      .content-image {
          max-width: 100%;
          max-height: 60px;
      }

      @media (min-width: $site-width-mobile) {
        margin: 0 0 20px 0;
        display: flex;
        justify-content: flex-end;
      }
  }

  [class*="band-"] {
    min-height: 300px;
  }

  .promo-band {
    min-height: 330px;
  }

  // =========================================================
  .header-custom {
      @include bms-font-size(2rem, 36px);
      font-family: $ligthFontFamily;
      font-weight: 300;
  }
  .header-custom-bold {
      @include bms-font-size(2rem, 36px);
      font-family: $boldFontFamily;
      font-weight: 700;
  }
  .header-1 {
    @include bms-font-size(4.5vw, 32px);
  }
  .header-2 {
    @include bms-font-size(4.3vw, 24px);
    font-weight: 700;
  }
  .header-3 {
    @include bms-font-size(4.3vw, 18px);
    font-weight: 700;
  }
  .header-4 {
    @include bms-font-size(4.0vw, 16px);
    font-weight: 700;
  }
  .header-5 {
    @include bms-font-size(4.0vw, 16px);
    font-weight: 700;
  }
  .text-medium {
    @include bms-font-size(3.8vw, 14px);
    font-weight: 700;
  }
  .text-small {
    @include bms-font-size(3.4vw, 10px);
  }

  .text-white {
    color: $white;
  }

  .font-bold {
    font-family: $boldFontFamily;
  }
  .font-light {
    font-family: $ligthFontFamily;
  }

  p, h2 {
    strong {
      font-family: $boldFontFamily;
    }
  }

  .bank-mobile {
      max-width: 50%;
  }

  .btn.btn-primary:not(.normal) {
    min-width: 170px;
    max-width: 100%;
  }

  .form-container {
    @media screen and (min-width: $site-width-mobile) {
      position: absolute;
      z-index: 3;
      margin-top: 12px;
    }
  }

  // =========================================================
  .featured-carousel .content {
    position: relative;
    text-align: center;
    background-size: cover;
    min-height: 360px;

    @media (min-width: $site-width-mobile) {
      text-align: right;
      min-height: 420px;
    }
  }

  .search-box {
      width: 100%;
      height: 232px;
      background-color: $white;

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 680px;
        position: absolute;
        z-index: 1;
        margin-top: 20px;
        margin-left: 20px;
        border-radius: 5px;
      }
  }

  .promo-banners {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;

    a {
      width: calc(50% - 10px);

      div {
        border-radius: 8px;
      }
    }

    .band-promo {
      margin: 20px 0px;

      .participating-banks {
        text-align: center;
        img {
          max-width: 150px;
          margin: 0 auto;
        }

        @media (min-width: $site-width-mobile) {
          width: 240px;
          height: 160px;
          border-radius: 10px;
          background: rgba(245, 245, 245, 0.7);
          padding-top: 1px;

          p {
            text-align: center;
            color: $gray-800;
            font-weight: bold;
          }
          img {
            width: 100px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  // =========================================================
  .headline-companies {
      border-bottom: 1px solid $gray-300;
      margin-bottom: 10px;

      p {
          margin-top: 30px;
          font-size: 17px;
          color: $gray-500;
      }
  }
  .parceiros {

      .row{
          display: flex;
          justify-content: center;
          align-items: center;

          .parceiros-card
          {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              div {
                  width: 100%;
                  height: 50px;
              }
          }
          .gol {
              min-height: 55px;
          }
      }
  }

  // =========================================================
  // same height slides
  .slick-track {
    display: flex;
    background: linear-gradient($gray-600, $gray-400);

    .slick-slide {
      height: inherit;

      & > div, .content {
        height:100%;
      }
    }
  }

  .slick-slider {
    position: relative;

    .slick-dots {
      display: flex !important;
      position: absolute;
      bottom: 0px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      justify-content: center;
      padding: 0;
      list-style-type: none;
      li {
        margin: 0 0.20rem;
      }

      button {
        display: block;
        width: 12px;
        height: 12px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: #C1C1C1;
        text-indent: -9999px;
      }

      li.slick-active button {
        background-color: #FF5A00;
      }
    }
  }

  //=======================================================//
  /* Search flight slider controls styles */

  .search-flight__banner,
  .offer-slider {
    .slick-slider {
      .slick-arrow {
        position: absolute;
        color: transparent;
        background-color: transparent;
        background-image: url("../../images/svg/icons/orange/ic-arrow-left-sm.svg");
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
        z-index: 1;

        &.slick-prev {
          left: 42%;
          bottom: 9px;
        }

        &.slick-next {
          right: 43%;
          transform: rotate(180deg);
          bottom: 9px;
        }
      }

      .slick-dots {
        position: relative;
        margin-top: 20px;

        li {
          z-index: 2;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .search-flight__banner {
      .slick-arrow {
        width: 20px;
        padding: 0px;

        &.slick-next {
          right: 10px !important;
        }

        &.slick-prev {
          left: 10px !important;
        }
      }
    }
  };

  .offer-slider {
    padding: 20px;
    border: 2px solid $default;
    border-radius: 16px;
    margin-top: 5px;

    .slick-slider {
      .slick-list {
        .slick-track {
          background: $white;

          .slick-slide {
            .offer-card {
              max-width: unset;
              height: 400px;

              .content {
                border-radius: 16px;
              }
            }
          }
        }
      }

      .slick-dots {
        button {
          width: 30px;
          border-radius: 8px;
        }
      }

      .slick-arrow {
        width: 15px !important;
        padding: 0px !important;

        &.slick-prev {
          left: 0px;
        }
        &.slick-next {
          right: 0px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0px;
      border: 0px;

      .slick-arrow {
        &.slick-next {
          right: 15px !important;
        }
        &.slick-prev {
          left: 15px !important;
        }
      }

      .offer-card {
        height: unset !important;

        img {
          border-radius: 16px;
        }
      }
    }
  }
  //

  //=======================================================//
  /* Promo slider mobile styles */
  @media screen and (max-width: 768px) {
    .promo-slider {
      position: relative;

      &.slick-slider {
        .slick-list {
          .slick-track {
            background: $white !important;
          }
        }

        .slick-dots {
          position: relative;
        }

        .slick-arrow {
          position: absolute;
          color: transparent;
          background-color: transparent;
          background-image: url("../../images/svg/icons/orange/ic-arrow-left-sm.svg");
          background-repeat: no-repeat;
          background-position: center center;
          border: none;
          z-index: 1;

          &.slick-prev {
            left: 15px;
            bottom: -5px;
          }

          &.slick-next {
            right: 15px;
            transform: rotate(180deg);
            bottom: -5px;
          }
        }


        button {
          width: 30px;
          border-radius: 8px;
        }

      }
    }
  }

  //=======================================================//
  /* Parceiros slider mobile styles */

  @media screen and (max-width: 768px) {
    .compania-asociada {
      padding: 0px !important;
      margin: 20px 0px;

      .slick-slider {
        &.parceiros-slider-mb {
          padding-bottom: 20px;

          .slick-arrow {
            position: absolute;
            z-index: 1;
            border: none;
            font-size: 0;
            background-color: $default !important;
            background-size: 7px !important;
            padding: 10px;
            bottom: 62px;

            &.slick-prev {
              background: url('../../images/svg/icons/white/ic-arrow-left-sm.svg') no-repeat center center;
              left: 10px;
              top: 0;
            }

            &.slick-next {
              background: url("../../images/svg/icons/white/ic-arrow-right-sm.svg") no-repeat center center;
              right: 10px;
              top: 0;
            }
          }

          .slick-list {
            width: 80%;
            margin: 0 auto;

            .slick-track {
              background: $white !important;
            }
          }
        }

        .parceiros-slider-mb__content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100%;

            a {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .col-6 {
            &:nth-child(4n) {
              &::before {
                content: "";
                position: absolute;
                height: 30px;
                width: 1px;
                background: $gray-300;
                left: -4px;
              }
            }

            &:nth-child(3n),
            &:first-of-type {
              &::before {
                content: "";
                position: absolute;
                height: 30px;
                width: 1px;
                background: $gray-300;
                right: -12px;
              }
            }
          }
        }
      }
    }
  }

  // =========================================================
  .some-ms-fix-content {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-height: 300px;
      height: 300px;
    }
  }
}
