.modal-go-back {
  .button-group {
    display: flex;
    justify-content: space-around;
  }

  .content {
    padding-bottom: 35px;

    span {
      font-size: 20px;
    }
  }
}
