
.my-account__extract{
    div{
        .desc-vip-jarvis{
            font-family: $semiBoldFontFamily;
        }
    }
}

.jarvis-services-img-container{
    margin-top: -30px;
    position: relative;
    width: 70px;
    height: 45px;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    top: 40px;
    left: -87px;
    img{
        width: 40px;
        height: 40px;
    }
}
.jarvis-services-terms{
    font-size: .6em;
}

.jarvis-service-description{
    .item-description{
        font-family: $semiBoldFontFamily;
    }
}

