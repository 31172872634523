#invite-cards {
    display: inline-block;
    background-color: #F5F5F5;
    padding: 3rem;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

#invite-carousel .my-account__miles-action-frame .miles-purchase,
#invite-carousel-mobile .my-account__miles-action-frame .miles-purchase,
#invite-box-group .my-account__miles-action-frame .miles-purchase {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: 350px;
    padding: 2rem 1rem;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    width: 250px;
    justify-content: space-between;
}

#invite-carousel .smls-orange-icons, #invite-carousel-mobile .smls-orange-icons {
    font-size: 26px;
}

#invite-cards {
    margin-top: 5rem;
}

#invite-carousel .my-account__miles-action-frame,
#invite-carousel-mobile .my-account__miles-action-frame,
#invite-box-group .my-account__miles-action-frame {
    background-color: transparent;
    flex: none;
    margin: 0 15px 0 10px;
    display: flex;
}

#invite-carousel  .my-account .miles-actions,
#invite-carousel-mobile  .my-account .miles-actions,
#invite-box-group .miles-actions {
    flex-direction: row;
    display: flex;
    margin: 10px;
    justify-content: center !important;
}

@media (max-width:768px) {
    #invite-box-group .miles-actions {
        flex-direction: row;
        display: flex;
        margin: 10px;
        justify-content: flex-start !important;
    }
    #invite-box-group.my-cards {
        overflow-x: scroll !important;
    }
    #invite-cards h3 {
        font-size: 1.5rem;
    }
    .carousel-control-prev {
        left: -40px;
    }
    .carousel-control-next {
        right: -40px !important;
    }
    #invite-carousel .smls-orange-icons, #invite-carousel-mobile .smls-orange-icons {
        font-size: 16px;
    }
}

#invite-carousel .my-account__miles-action-frame p,
#invite-carousel-mobile .my-account__miles-action-frame p, {
    margin: 15px 0px;
    text-align: center;
    font-size: 15px;
    line-height: 150%;
}

#invite-carousel .my-cards,
#invite-carousel-mobile .my-cards,
#invite-box-group.my-cards {
    width: 100% !important;
    height: max-content;
    margin: 0;
    overflow: hidden;
    box-shadow: none !important;
    margin-top: 30px !important;
    margin-bottom: 0px !important;
    background: transparent;
}

img.card-header__logo {
    width: 71px;
}

.card-bottom button {
    margin-bottom: 5px !important;
    font-size: 15px !important;
}

.card-bottom a {
    text-transform: uppercase;
    font-size: 9px;
}

.card-bottom .smls-green-icons {
    font-size: 30px;
}

.d-mobile {
  @media (min-width: $site-width-mobile) {
    display: none;
  }
}

.d-desktop {
  @media (max-width: $site-width-mobile) {
    display: none;
  }
}
