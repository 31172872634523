.smiles {
    &__my-club {
        //background-color: $white;
        //@include bms-base-box;
        position: relative;
        padding: 10px;

        &__logo {
            width: 100%;
            text-align: center;
            float: left;
            label {
                color: $club;
                font-size: 100px;
                line-height: 100px;
                font-family: $boldFontFamily;
                letter-spacing: -5px;
                width: 100%;
                margin-bottom: 0;
            }
            small {
                background-image: url(../images/svg/logos/smiles-purple.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 88px;
                height: 40px;
                position: relative;
                display: inline-block;
                margin-bottom: 20px;

                &::after {
                    content: "";
                    position: absolute;
                    height: 9px;
                    border-bottom: 5px solid $club;
                    top: 4px;
                    width: 55px;
                    right: 100%;
                    margin-right: 3px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    height: 9px;
                    border-bottom: 5px solid $club;
                    top: 4px;
                    width: 55px;
                    left: 100%;
                    margin-left: 3px;
                }
            }
        }

        &__plan {
            width: 100%;
            text-align: center;
            float: left;
            margin-bottom: 60px;
            label {
                color: $white;
                background-color: $club;
                padding: 10px;
                display: inline-block;
                font-size: 24px;
                font-family: $boldFontFamily;
            }
        }

        &__info {
            width: 100%;
            float: left;
            margin: 20px 0 0 0;
            label {
                color: $club;
                font-size: 15px;
                font-family: $boldFontFamily;
            }
            p {
                font-size: 14px;
                font-family: $ligthFontFamily;
                line-height: 18px;
                margin-bottom: 28px;
            }
            a {
                font-size: 14px;
                font-family: $ligthFontFamily;
                color: $gray-600 !important;
                line-height: 18px;
                background-image: url(../images/svg/icons/red/ic-file.svg);
                background-repeat: no-repeat;
                background-size: 18px;
                padding-left: 22px;
            }
        }

        &__info-plan {
            float: right;
            margin-right: 0;
            background-color: $white;
            @include bms-base-box;
            position: relative;
            padding: 15px;
            width: 100% !important;

            &::before {
                content: '';
                width: 80px;
                height: 2px;
                background-color: $club;
                position: absolute;
                top: 0;
                left: 15px;
            }

            &__updated {
                width: 100%;
                font-size: 15px;
                padding: 10px 15px;
                color: $club-updated-dark;
                border: 1px solid $club-updated-dark;
                background-color: $club-updated;
                border-radius: 3px;
                margin-bottom: 10px;
            }
            table {
                width: 100%;
                margin-top: -15px;
                tr:nth-child(odd){
                    color: $club;
                    font-family: $boldFontFamily;
                    font-size: 15px;
                    td {
                        white-space: nowrap;
                        padding-top: 15px;
                        a {
                            float: right;
                            background-image: url(#{$svg-path}/icons/gray/ic-pencil.svg);
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            padding: 0 0 0 20px;
                            color: $gray-700;
                            font-family: $ligthFontFamily;
                            font-size: 14px;
                            cursor: pointer;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &:nth-child(2){
                            text-align: right;
                        }
                    }
                }
                tr:nth-child(even) {
                    font-family: $ligthFontFamily;
                    font-size: 12px;
                    border-bottom: 1px solid $gray-500;
                    td {
                        padding: 10px 0;
                        &:last-child {
                            text-align: right;
                        }
                        a {
                            text-decoration: underline;
                            cursor: pointer;
                            padding-left: 5px;
                        }
                    }
                }

                tr:last-child {
                    border: none !important;
                }
            }
        }

        &__card-credit {
            float: right;
            margin-left: 30px;
            @include bms-base-box;
            position: relative;
            padding: 15px;
            width: 100% !important;

            &::before {
                content: '';
                width: 80px;
                height: 2px;
                background-color: $club;
                position: absolute;
                top: 0;
                left: 15px;
            }

            div.payment {
                width: 100%;
                margin: auto;
                position: relative;

                div.tarjeta {
                    width: 100%;
                    float: left;
                    h3 {
                        font-size: 15px;
                        color: $club;
                        font-family: $boldFontFamily;
                        padding: 0 0 10px 0;
                        text-align: left;
                    }
                    .frm-default {
                        margin: auto;
                        .credit-card {
                            .row {
                                label.form-check-label {
                                    position: relative;
                                    margin-top: 20px;
                                    font-family: $boldFontFamily;
                                    span {
                                        padding-left: 20px;
                                    }
                                }
                                div.text-security {
                                    p {
                                        margin-bottom: 10px;
                                        font-size: 10px;
                                        line-height: 12px;
                                        strong {
                                            font-family: $boldFontFamily;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                .col-4 {
                                    &:nth-child(3) {
                                        padding-right: 0 !important;
                                    }
                                }
                                input.completed {
                                    border-color: $club-updated-dark;
                                    color: $club-updated-dark;
                                }
                            }
                            .next-payment {
                                label {
                                    color: $club;
                                    font-size: 15px;
                                    font-family: $boldFontFamily;
                                    margin-top: 15px;
                                }
                                p {
                                    font-family: $ligthFontFamily;
                                    font-size: 14px;
                                    float: right;
                                    margin-top: 15px;
                                }
                                button {
                                    display: block;
                                    margin: 15px auto;
                                    float: none;
                                    border-radius: 3px;
                                    background: $default;
                                    color: $white;
                                    border: none;
                                    font-size: 16px;
                                    padding: 7px 50px;
                                    width: 100%;
                                }
                            }
                            .cancel-club {
                                text-align: end;
                                div.link {
                                    padding-right: 0;
                                    a {
                                        display: inline-block;
                                        color: $club;
                                        font-family: $boldFontFamily;
                                        label {
                                            font-size: 40px;
                                            line-height: 20px;
                                        }
                                        small {
                                            float: left;
                                            clear: both;
                                            line-height: 12px;
                                            font-size: 12px;
                                        }
                                        span {
                                            float: left;
                                            clear: both;
                                            line-height: 18px;
                                            font-size: 16px;
                                        }

                                    }
                                }
                                p {
                                    font-family: $ligthFontFamily;
                                    font-size: 14px;
                                    display: block;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                    &__info-history {
                        width: 100%;
                        label {
                            color: $club;
                            font-family: $boldFontFamily;
                            font-size: 15px;
                            float: left;
                            clear: both;
                            margin: 40px 0 0 0;
                        }
                        p {
                            font-family: $ligthFontFamily;
                            font-size: 14px;
                            float: left;
                            clear: both;
                        }
                        table {
                            width: 100%;
                            font-size: 14px;
                            thead {
                                display: table-header-group;
                                tr {
                                    border-bottom: 1px solid $gray-500;
                                    th {
                                        padding: 10px 8px;
                                        color: $club;
                                        font-family: $boldFontFamily;
                                        &:last-child {
                                            text-align: right;
                                            padding: 10px 0 10px 8px;
                                        }
                                        &:first-child {
                                            padding: 10px 8px 10px 0;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    border-bottom: 1px solid $gray-500;
                                    td {
                                        display: table-cell;
                                        padding: 10px 8px;
                                        font-family: $ligthFontFamily;
                                        &:last-child {
                                            text-align: right;
                                            padding: 10px 0 10px 8px;
                                        }
                                        &:first-child {
                                            padding: 10px 8px 10px 0;
                                        }
                                    }
                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &__club-achievement{
            .text-card_tittle{
                color: $club;
                font-size: 20px;
            }
            .card-body{
                padding: 20px 80px !important;
            }
            @media (max-width: 991px){
                .card-body{
                    padding: 20px 60px !important;
                }
            }
            @media (max-width: 768px){
                .card-body{
                    padding: 20px !important;
                }
            }
            .achievement-row{
                background-color: $club;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                &  p{
                    font-size:  18px !important;
                }
            }
            .achievement-row-2{
                border: 1px solid $club;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                &  p{
                    color:  $club;
                }
            }
        }
        &__info-history {
            background-color: $white;
            float: left;
            position: relative;
            @include bms-base-box;
            padding: 15px;
            width: 100% !important;
            margin-top: 20px !important;

            &::before {
                content: '';
                width: 80px;
                height: 2px;
                background-color: $club;
                position: absolute;
                top: 0;
                left: 15px;
            }

            .form-group {
                label {
                    color: $gray-700 !important;
                    margin: 0 0 5px 0 !important;
                    font-family: $ligthFontFamily !important;
                }
                select.form-control {
                    color: $club !important;
                    font-size: 14px;
                    background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
                    background-repeat: no-repeat;
                    background-position: 95%;
                    padding: 5px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }
            label {
                color: $club;
                font-family: $boldFontFamily;
                font-size: 15px;
                float: left;
                clear: both;
                margin: 0 0 0 0;
            }
            p {
                font-family: $ligthFontFamily;
                font-size: 14px;
                float: left;
                clear: both;
            }
            table {
                width: 100%;
                font-size: 14px;
                thead {
                    display: none;
                    tr {
                        border-bottom: 1px solid $gray-500;
                        th {
                            padding: 10px 8px;
                            color: $club;
                            font-family: $boldFontFamily;
                            &:last-child {
                                text-align: right;
                                padding: 10px 0 10px 8px;
                            }
                            &:first-child {
                                padding: 10px 8px 10px 0;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        border-bottom: none;
                        display: none;
                        td {
                            display: block;
                            padding: 5px 0 0 0;
                            font-family: $ligthFontFamily;

                            &:first-child {
                                display: none;
                            }
                            b {
                                display: block;
                                color: $club;
                                font-family: $boldFontFamily;
                            }
                            span {
                              color: $club
                            }
                        }
                        &.historyActive {
                            display: table-row;
                        }
                        &.historyActive {
                          display: table-row;
                        }
                        &.historyHide {
                          display: none !important;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        &__my-club {
            padding: 0px;

            &__logo {
                label {
                    font-size: 120px;
                }
                small {
                    width: 98px;
                    &::after {
                        width: 65px;
                    }

                    &::before {
                        width: 65px;
                    }
                }
            }

            &__info-plan {
                padding: 30px;
                width: 510px !important;
                margin-left: 30px;
                &::before {
                    left: 30px;
                }
                table {
                    tr:nth-child(even) {
                        td {
                            font-size: 14px;
                            padding: 0;
                            text-align: left;
                            &:last-child {
                                text-align: left;
                            }
                            &:nth-child(2) {
                                text-align: left;
                            }
                        }
                    }
                    tr:nth-child(odd) {
                        text-align: left;
                        td {
                            &:last-child {
                                text-align: left;
                            }
                            &:nth-child(2){
                                text-align: left;
                            }
                        }
                    }
                }
            }
            &__info {
                width: 250px;
                margin: 0;
                float: left;
            }

            &__banner{
                width: 100%;
                margin: 0;
                float: left;
                padding: 0 15%;
            }

            &__card-credit {
                float: right;
                padding: 30px;
                width: 560px !important;
                margin-left: 30px;
                &::before {
                    left: 30px;
                }
                div.payment {
                    div.tarjeta {
                        .frm-default {
                            .credit-card {
                               .next-payment {
                                   label {
                                        color: $club;
                                        font-size: 15px;
                                        font-family: $boldFontFamily;
                                        margin-top: 15px;
                                    }
                                    p {
                                        font-family: $ligthFontFamily;
                                        font-size: 14px;
                                        float: none;
                                        margin-top: 0;
                                    }
                                    button {
                                        display: block;
                                        margin: 15px 0;
                                        float: right;
                                        border-radius: 3px;
                                        background: $default;
                                        color: $white;
                                        border: none;
                                        font-size: 16px;
                                        padding: 7px 50px;
                                        width: auto;
                                    }
                                }
                                .cancel-club {
                                    text-align: right;
                                    div.link {
                                        padding-right: 45px;
                                    }
                                }
                                .row {
                                    .col-4 {
                                        &:nth-child(3) {
                                            padding-right: 10px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__info-history {
                padding: 0 50px 50px 50px;
                width: 100%;
                &::before {
                    left: 50px;
                }
                .form-group {
                    display: none;
                }
                label {
                    color: $club;
                    font-family: $boldFontFamily;
                    font-size: 15px;
                    float: left;
                    clear: both;
                    margin: 20px 0 0 0;
                }
                p {
                    font-family: $ligthFontFamily;
                    font-size: 14px;
                    float: left;
                    clear: both;
                }
                table {
                    width: 100%;
                    font-size: 14px;
                    thead {
                        display: table-header-group;
                        tr {
                            border-bottom: 1px solid $gray-500;
                            th {
                                padding: 10px 8px;
                                color: $club;
                                font-family: $boldFontFamily;
                                &:last-child {
                                    text-align: right;
                                    padding: 10px 0 10px 8px;
                                }
                                &:first-child {
                                    padding: 10px 8px 10px 0;
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            display: table-row !important;
                            border-bottom: 1px solid $gray-500;
                            &.historyHide {
                              display: table-row !important;
                            }
                            td {
                                display: table-cell;
                                padding: 10px 8px;
                                font-family: $ligthFontFamily;
                                &:last-child {
                                    text-align: right;
                                    padding: 10px 0 10px 8px;
                                }
                                &:first-child {
                                    padding: 10px 8px 10px 0;
                                    display: table-cell;
                                }
                                b {
                                    display: none;
                                }
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
