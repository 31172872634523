.time-picker__clock {
    display: flex;
    align-items: center;
    padding: 5px;
    height: 50px;
    margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
    text-align: center;
    white-space: nowrap;
}
.time-picker {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
}

.time-picker__input {
    padding: 0 0 0 10px;
    height: 50px;
    border: 1px 1px 1px 0px solid grey;
}

.time-picker__selector {
    display: block;
    width: 200px;
    height: 100px;
    background-color: white;
    border: 1px solid #FF5A00;
    position: absolute;
    top: 55px;
    left: 5px;
    z-index: 10000;
}
