.my-account {
  &__invitar-amigos {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    text-align: left;
    overflow: hidden;

    .copy-code {
      margin-top: -10px;
    }
    .share-with-friends {
      margin-top: 0px !important;
    }
    .container-page-invitar-amigos {
      margin: 20px 10px;
    }
    .invite-friends.my-account {
      width: 100%;
    }
  }
}
