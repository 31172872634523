// Smiles Typography
// ----------------

//Fontes
@font-face {
  font-family: 'NunitoRegular';
	src: url(../fonts/nunito/Nunito-Regular.ttf) format('truetype');
}
@font-face{
	font-family: 'NunitoBold';
  src: url(../fonts/nunito/Nunito-Bold.ttf) format('truetype');
}
@font-face{
	font-family: 'NunitoLight';
	src: url(../fonts/nunito/Nunito-Light.ttf) format('truetype');
}

@font-face{
	font-family: 'NunitoSemiBold';
	src: url(../fonts/nunito/Nunito-SemiBold.ttf) format('truetype');
}

