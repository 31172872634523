.retrocredit {
  $radius: 3px;

  .nav-link {
    cursor: pointer;
  }

  .nav.nav-primary {
    > a {
      color: $primary;
      font-weight: bold;
      border: 1px solid $secondary;
      padding-top: 20px;
      padding-bottom: 20px;
      flex: 1;
      text-align: center;

      &:not(:first-child){
        border-left: unset;
      }
      &:first-child {
        border-radius: $radius 0 0 $radius;
      }
      &:last-child {
        border-radius: 0 $radius $radius 0;
        border-left: 0px;
      }
      &.active {
        background-color: $primary;
        color: $white;
      }


    }
  }
}
