.pricing-type-title {
  position: relative;
  width: 200px;
  margin: 20px 0px;

  &__club {
    color: $club;
    left: 64%;
    .text-diamond {
      color: $black;
    }
  }
  &__standard {
    color: $primary;
    left: 67%;
  }
  &__standard-total {
    color: $primary;
    left: 64%;
  }
  &__diamond {
    color: $black;
    left: 64%;
  }
}

.pricing-select-title {
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: $black;
  margin-bottom: 35px;
}

.items-counter {
  margin: 10px 0;
  &__club {
    color: $club;
  }
  &__standard {
    color: $primary;
  }
  &__diamond {
    color: $black;
  }
}
.estimateCost,
.continue-button {
  min-width: 200px;
}

@media only screen and (max-width: 575px) {
  .pricing-select-title {
    margin: 15px;
  }

  .pricing-type-title {
    position: initial;
    text-align: center;
    width: 100%;
  }
  .items-counter {
    margin: 30px 0px;
  }
  .text-no-cost {
    font-size: 14px;
    line-height: 1;
  }
}
