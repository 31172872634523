.my-account {
  &__extend-miles {
    .extend-miles__content {
      .left {
        span {
          &.input-border {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            font-size: 18px;

            input {
              border: 1px solid $gray-800;
              padding: 10px 15px;
              font-size: 25px;
              font-weight: 700;
              color: $gray-800;
              width: 90%;
              margin-top: 10px;
            }
          }
        }

        h3 {
          font-size: 18px;
          color: $gray-800;
        }

        p {
          font-weight: 700;
        }
      }

      .middle {
        padding: 0px 20px;
        position: relative;

        img.border-icon {
          position: absolute;
          top: 42%;
          padding: 5px 0px;
          background: $white;
          left: -18px;
          height: 45px;
        }
      }

      .right.col {
        display: flex;
        text-align: center;
        justify-content: space-between;
        flex-direction: column;

        img.border-icon {
          position: absolute;
          top: 45%;
          padding: 5px 0px;
          background: $white;
          left: -10px;
          height: 28px;
        }

        .total-extension {
          height: 100% !important;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 20px;

          &__content {
            font-weight: 700;

            span {
              font-size: 35px;
            }

            p {
              &.total-extension__text {
                font-size: 20px;
              }
            }
          }

          button {
            margin: 0px !important;
            line-height: 10px;

            &:hover {
              opacity: .65;
            }
          }
        }
      }

      .extension-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px !important;

        &:last-of-type {
          margin-bottom: 0px !important;
        }

        &__field {
          padding: 10px;
          width: 100%;
          display: flex;
          justify-content: center;
          border: 1px solid $gray-600;
          color: $gray-600;
          align-items: center;

          .form-group, p {
            margin: 0;
          }

          p {
            margin-left: 15px;
          }
        }

        &__title {
          padding: 5px;
          background-color: $gray-600;
          color: $white;
          width: 100%;
          text-align: center;
          font-weight: 700;
          font-size: 14px;
        }
      }

      button.extend-btn {
        float: right;
        clear: both;
        padding: 10px 40px;
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }

    .resume-wrapper {
      h4 {
        width: 250px;
      }
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .my-account {
    &__extend-miles {
      .banner-purple {
        p {
          text-align: center !important;
        }
      }

      .extend-miles__content {
        .row {
          display: flex;
          flex-direction: column;
        }

        .col {
          flex-basis: auto !important;
        }

        .border-right {
          border-right: 0px !important;
        }

        img.border-icon {
          display: none !important;
        }

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin-bottom: 30px;
          border-bottom: 1px solid;
          padding: 0px 20px 30px 20px !important;

          span {
            &.input-border {
              display: flex;
              flex-direction: row !important;
              align-items: baseline;

              input {
                margin-right: 10px !important;
              }
            }
          }

          h3 {
            text-align: center !important;
          }

          p {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 0px;
          }
        }

        .middle {
          padding: 0px 20px 30px 20px;
          margin-bottom: 30px;
          border-bottom: 0.2px solid $black;
        }

        .right.col {
          .total-extension {
            display: flex;
            flex-direction: column !important;
            justify-content: center !important;
            align-items: baseline;
            align-items: center;
            padding-left: 0;

            h3 {
              margin-right: 10px;
              margin-bottom: 0px;
            }

            &__content {
              height: 100% !important;
              display: flex;
              flex-direction: row;
              justify-content: center;
              padding-left: 0px;
              align-items: center;
              margin: 10px 0px !important;

              span {
                margin-right: 3px;
              }

              p {
                margin-right: 9px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      .resume-wrapper {
        .table {
          .miles {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
