.cookies-config-modal {
  .modal-content{
    min-height: 80vh;
  }
  .centered-modal {
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    font-family: $regularFontFamily;
    .config-modal-form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .chekbox-cookies-config-modal {
        flex: 4;
        .card-header{
          background-color: $white !important;
        }
        .card-body{
          background-color: $gray-300 !important;
          color: $gray-900;
          min-height: 27vh;
        }
        .panel-default{
          .panel-heading{
            button{
              color: $gray-800;
              text-decoration: none;
            }
            button:after {
              content: "";
              position: relative;
              top: 1px;
              font-family: $boldFontFamily;
              font-size: 26px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              float: left;
              transition: transform .15s linear;
              -webkit-transition: -webkit-transform .15s linear;
              right: 16px;
              color: $gray-800;
            }
          } 
          .panel-heading {
            button[aria-expanded="true"]:after {
              content: "\2212";
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
   
          }
          .panel-heading {
            button[aria-expanded="false"]:after{
              content: "\002b";
              -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          } 
        }
        .cookies-label {
          display: block;
          position: relative;
          cursor: pointer;
          font-size: 16px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked {
              ~ {
                .checkmark {
                  background-color: $primary;
                  &:after {
                    display: block;
                  }
                }
              }
            }
          }
          &:hover {
            input {
              ~ {
                .checkmark {
                  opacity: 0.8;
                }
              }
            }
          }
          .checkmark {
            &:after {
              left: 10px;
              top: 4px;
              width: 5px;
              height: 15px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          span{
            font-family: $boldFontFamily;
            font-size: 14px;
            color: $primary;
            text-align: center;
          }
        }
        .checkmark {
          position: absolute;
          top: 0;
          right: -15px;
          height: 25px;
          width: 25px;
          border: 1px solid $gray-500;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
      }
    }
  }
}

.modal-dialog.cookies-config-modal{
  transform: translate(0, 5vh) !important;
}

.modal-header__content-reverse.cookies{
  border-bottom: none !important;
  h5{
    margin-top: 17px;
    color: $gray-800;
  }
  .close:not(:disabled):not(.disabled):hover{
    color: $gray-300 !important;
  }
  .modalCloseBtn{
    border: 2px solid $white;
    border-radius: 51%;
    padding: 3px 8px;
    margin: -17px -69px;
    color: $white;
    opacity: 1;
    :hover{
      color: $gray-300 !important;
    }
    @media (max-width: 768px) {
      border: 2px solid #444;
      border-radius: 51%;
      margin: 0;
      color: #444;
      :hover{
        color: $gray-600 !important;
      }
    }
  }
}
.modal-footer{
  .saveBtn{
    width: 270px;
    border-radius: 6px;
  }
}