.miles-ticket-card {
    .miles-col {
        display: inline-block;
        width: 50%;
    }
    .miles-ticket-card__title {
        display: inline-block;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 1.75rem;
        color:  $primary;
        font-family: "NunitoBold","Arial Bold",sans-serif;
        font-weight: 500;
        line-height: 1.2;
    }
    .miles-ticket-card__head {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid $gray-300;
        .miles-ticket-card__head--left {
            text-align: left;
            font-size: 14px;
        }
        .miles-ticket-card__head--right {
            text-align: right;
            font-size: 14px;
        }
    }
    .miles-ticket-card__body {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid $gray-300;
        .miles-ticket-card__body--left {
            text-align: left;
            font-size: 14px;
        }
        .miles-ticket-card__body--right {
            text-align: right;
            font-size: 14px;
        }
    }
    .miles-ticket-card__bonus {
        display: flex;
        justify-content: center;
        padding: 1rem 0 1rem 0;
        text-align: center;
        .color-smiles {
            color: $primary;
        }
        .tdDivBonusMsg {
            .color-smiles {
                color: $primary;
            }
            .color-club {
                color: $club;
            }
            .color-diamond {
                color: $diamond
            }
        }
    }
    .miles-ticket-card__foot {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0 0 0;
        border-top: 1px solid $gray-300;
        .miles-ticket-card__foot--left {
            text-align: left;
            font-size: 14px;
        }
        .miles-ticket-card__foot--right {
            text-align: right;
            font-size: 14px;
        }
    }
}
tbody {
    tr{
        .incClubSmilesMoney {
            text-align: right !important;
        }
    }
}
