.my-account {
  &__register {
    #registerHeader {
      margin-top: 15px;
    }
    @include bms-base-box;
    position: relative;
    margin: auto;
    text-align: left;
    padding: 15pt 9pt;

    .indication-box {
      margin-bottom: 50px;
      > span {
        font-size: 0.8rem;
      }

      input {
        height: 50px;
        font-size: x-large;
        color: #ff5a00;
      }
    }

    /* Box com o ID e o Captcha */
    &.box {
      min-height: 400px;
      padding: 30pt 20pt 30pt 20pt;
      h2 {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        .ic-profile-solid {
          font-size: 15px;
          margin-right: 15px;
        }
      }
      .box-header {
        margin-bottom: 10px;
        p {
          font-size: 16px;
          margin: 0;
          font-weight: 600;
        }
      }

      .recaptcha {
        height: 80px;
        width: 104.5%;
        overflow: hidden;
        margin-bottom: 20px;
        > div {
          > div {
            > div {
              display: flex;
              justify-content: center;
              > div {
                margin: 0 auto !important;
                transform: scale(0.82);
                -webkit-transform: scale(0.82);
              }
            }
          }
        }
      }

      .frm-default {
        margin-bottom: 10px;
      }
      .box-footer {
        @include bms-font-size(13px, 13px);
        @include bms-line-height(16px, 16px);
        a {
          color: $gray-800;
          text-decoration: underline;
        }
      }
    }
    &.edit {
      padding-bottom: 10px;
      .completed {
        input {
          color: $gray-700;
        }
        label{
          font-size: 10px;

          @media screen and (max-width: 380px){
            top: 3px;
            left: 10px;
            font-size: 9px;
            opacity: 1;
            color: #7C7C7C;          }
        }

      }
      .completed, .warning {
        h2 {
          background-position: 90% center;
          .btn-collapse {
            position: absolute;
            right: -3px;
            top: 12px;
          }
        }
      }
      > h2 {
        color: $gray-700;
        margin-bottom: 20px;
      }
      .footer {
        margin-top: 30px;
      }
      .check-email {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        .form-check-label {
          margin-top: -18px;
          flex-basis: 30px;
          text-align: left;
        }
        .form-group {
          flex-basis: 100%;
        }
      }
      .terms {
        margin-bottom: 0 !important;
      }
    }
  }

  &__register-alert {
    width: 561px;
    margin: 20px auto;
    background-color: #663399;
    color: white;
    padding: 10px;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .my-account {
    &__register {
      padding: 40px 50px;
      height: auto;
      width: 561px;

      /* Box com o ID e o Captcha */
      &.box {
        padding: 60px 140px;
        h2 {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          .ic-profile-solid {
            font-size: 30px;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
        .recaptcha {
          margin-bottom: 10px;
          > div {
            > div {
              > div {
                > div {
                  transform: scale(0.9);
                  -webkit-transform: scale(0.9);
                }
              }
            }
          }
        }
      }
      &.edit {
        margin: 0 auto 20px 0;
        .completed, .warning {
          h2 {
            background-position: 98% center;
            .btn-collapse {
              right: -30px;
              top: 12px;
            }
          }
        }
      }
    }
  }
}
