.mouse_pointer {
    cursor: pointer
}

.mouse_enter { 
    color: $club;
    cursor: pointer
}

.mouse_leave {
    color: $club
}

.mouse_enter_noclub { 
    color: $default;
    cursor: pointer
}

.mouse_leave_noclub {
    color: $default 
}

.club-smiles {
    &__know-club {
        .banner-promo {
            background-image: url(../images/svg/backgrounds/accession-club-smiles-promo.png);
            background-size: 200%;
            background-position-y: 55%;
            height: 200px;
            width: 100%;

            .alpha {
                background: linear-gradient(to bottom, rgba(102,51,153,0.27), #663399);
                width: 100%;
                height: 200px;
                padding: 20px 10px;

                .line{
                    border-left: 2px solid #ffffff;
                    border-bottom: 2px solid #ffffff;
                    border-bottom-left-radius: 20px;
                    flex: 1;
                    height: 55%;
                    margin: 75px 0 0 0px;
                    width: 93%;
                    position: relative;

                    &::after {
                        content: '';
                        background-image: url(../images/svg/icons/white/ic-airplane.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        right: -9%;
                        top: 89%;
                    }

                    h3, h4 {
                        color: #ffffff;
                        width: 100%;
                        float: right;
                        text-align: left;
                        clear: both;
                        margin: -10px 0 15px 0;
                        padding: 0 0 5px 10px;
                    }

                    h3 {
                        font-size: 14px;
                    }

                    h4 {
                        font-size: 14px;
                        font-family: 'NunitoLight', Arial, sans-serif;
                    }
                }

                .logo-club {
                    width: 100px;
                    float: left;
                    margin-top: -20px;

                    .club {
                        font-family: 'NunitoBold', "Arial Bold", sans-serif;
                        float: left;
                        color: #ffffff;
                        font-size: 50px;
                        letter-spacing: -2px;
                        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
                        padding: 0;
                        height: 55px;
                        width: 100%;
                    }

                    .smiles {
                        background-image: url(../images/svg/logos/smiles-white.svg);
                        background-size: contain;
                        width: 45px;
                        height: 18px;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            height: 9px;
                            border-bottom: 2px solid #ffffff;
                            top: -5px;
                            width: 25px;
                            right: 100%;
                            margin-right: 3px;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            height: 9px;
                            border-bottom: 2px solid #ffffff;
                            top: -5px;
                            width: 25px;
                            left: 100%;
                            margin-left: 3px;
                        }
                    }
                }

            }
        }

        .advantage-club {
            background-color: #ffffff;
            border: 2px dashed #663399;
            border-radius: 30px;
            width: calc(100% - 20px);
            height: auto;
            display: inline-block;
            margin: 40px 10px;
            position: relative;

            &::before {
                content: '¿Qué es Club Smiles?';
                background-color: #663399;
                border-radius: 24px;
                width: 240px;
                height: 40px;
                position: absolute;
                top: -20px;
                left: 50%;
                margin-left: -120px;
                font-size: 20px;
                color: #ffffff;
                padding-top: 5px;
            }

            p {
                display: inline-block;
                width: 100%;
                font-size: 14px;
                font-family: 'NunitoBold', "Arial Bold", sans-serif;
                text-align: center;
                margin: 0;
                line-height: 24px;
                color: #6c757d;
                padding: 40px 20px;
            }

            .itens {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding: 0 20px;

                div {
                    font-size: 16px;
                    color: #663399;
                    text-align: center;
                    display: block;
                    height: auto;
                    margin: 0 0 20px 0;
    
                    &.receive {
                        background-image: url(../images/svg/icons/purple/ic-databases.svg);
                        background-size: 60px;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 40px;
                        width: 100%;
                    }
    
                    &.discounts {
                        background-image: url(../images/svg/icons/purple/ic-ticket-money.svg);
                        background-size: 40px;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 40px;
                        width: 100%;
                    }
    
                    &.promotions {
                        background-image: url(../images/svg/icons/purple/ic-bank.svg);
                        background-size: 50px;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 50px;
                        width: 100%;
                    }
    
                    &.validity {
                        background-image: url(../images/svg/icons/purple/ic-databases-x10.svg);
                        background-size: 70px;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 50px;
                        width: 100%;
                    }
    
                    &.bonus {
                        background-image: url(../images/svg/icons/purple/ic-card-currency.svg);
                        background-size: 50px;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 50px;
                        width: 100%;
                    }
                }
            }
            
        }

        .more-advantages {
            width: 100%;
            height: auto;
            display: inline-block;
            position: relative;
            padding-top: 30px;
            margin-bottom: 40px;

            &::before {
                content: 'Además, tenés:';
                background-color: #663399;
                border-radius: 24px;
                width: 240px;
                height: 40px;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -120px;
                font-size: 20px;
                color: #fff;
                padding-top: 5px;
            }

            div {
                color: #663399;
                display: inline-block;
                width: 100%;
                margin: 0;
                position: relative;

                label {
                    font-size: 120px;
                    font-family: 'NunitoBold', "Arial Bold", sans-serif;
                    margin: 0;
                    padding: 0;
                    height: 160px;
                }

                p {
                    font-size: 14px;
                    text-align: center;
                    padding: 0 41px;
                    font-family: 'NunitoBold', "Arial Bold", sans-serif;
                    line-height: 25px;
                }

                &:first-child {
                    p {
                        padding: 0 10px;
                    }
                }
            }
        }

        .your-miles {
            background-color: #663399;
            width: 100%;
            height: auto;
            display: inline-block;
            text-align: center;

            label {
                background-color: #ffffff;
                border-radius: 24px;
                width: 260px;
                height: 40px;
                color: #663399;
                font-size: 20px;
                display: inline-block;
                margin: 20px;
                padding-top: 5px;
            }

            small {
                font-size: 14px;
                color: #ffffff;
                display: inline-block;
                margin-bottom: 26px;
                padding: 0 20px;
            }

            p {
                width: 100%;
                text-align: center;
                font-size: 16px;
                color: #ffffff;
                display: inline-block;
                line-height: 25px;
                padding: 0 15px;
            }

            .tiers {
                width: 100%;
                display: inline-block;
                
                div {
                    background-color: #ffffff;
                    border-radius: 100%;
                    width: 130px;
                    height: 130px;
                    display: inline-block;
                    margin: 20px 10px;
                    background-size: 95px 64px;;
                    background-position: center;
                    background-repeat: no-repeat;

                    &.smiles {
                        background-image: url(../images/svg/categories/ic-card-smiles.svg);
                    }

                    &.silver {
                        background-image: url(../images/svg/categories/ic-card-silver.svg);
                    }

                    &.gold {
                        background-image: url(../images/svg/categories/ic-card-gold.svg);
                    }

                    &.diamond {
                        background-image: url(../images/svg/categories/ic-card-diamond.svg);
                    }
                }
            }
        }

        .accession {
            background-color: #F6F6F6;
            border: 1px solid #663399;
            width: 100%;
            height: auto;
            display: inline-block;
            margin-top: 0;

            .club {
                background-color: #663399;
                width: 100%;
                height: auto;
                float: left;
                margin: 0;
                
                label {
                    font-size: 50px;
                    color: #ffffff;
                    margin-top: 15px;
                }
                
                span {
                    background-image: url(../images/svg/logos/smiles-white.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 98px;
                    height: 38px;
                    position: relative;
                    display: inline-block;
                    margin-bottom: 20px;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 5px solid #fff;
                        top: -5px;
                        width: 65px;
                        right: 100%;
                        margin-right: 3px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 5px solid #fff;
                        top: -5px;
                        width: 65px;
                        left: 100%;
                        margin-left: 3px;
                    }
                }
            }

            .info {
                float: left;
                width: 100%;
                padding: 26px 26px;
                
                p {
                    font-size: 13px;
                    text-align: left;
                    line-height: 25px;
                    font-family: 'NunitoBold', "Arial Bold", sans-serif;

                    b {
                        color: #663399;
                    }

                    &:first-child {
                        padding-bottom: 10px;
                        border-bottom: 1px dashed #663399;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }
                }

                label {
                    font-size: 14px;
                    color: #663399;
                    text-align: center;
                    float: left;
                    font-family: 'NunitoBold', "Arial Bold", sans-serif;
                    padding: 0 40px;
                }

                button {
                    width: 100%;
                    height: auto;
                    padding: 10px 0;
                    background-color: #663399;
                    border: none;
                    float: right;
                    color: #ffffff;
                    font-size: 16px;
                    margin: 10px 0 0 0;
                }
            }
        }
    }

    @media (min-width: 768px) { 
        &__know-club {
            .banner-promo {
                background-size: 100%;
                background-position-y: 50%;
                width: 1280px;
                height: 350px;

                .alpha {
                    height: 350px;
                    padding: 30px;

                    .line{
                        height: 65%;
                        margin: 95px 0 0 0px;
                        width: 95%;

                        &::after {
                            right: -3%;
                            top: 94%;
                        }

                        h3, h4 {
                            width: 569px;
                            text-align: left;
                            margin: 0 0 20px 0;
                            padding: 0;
                        }
    
                        h3 {
                            font-size: 29px;
                        }
    
                        h4 {
                            font-size: 26px;
                        }
                    }

                    .logo-club {
                        width: 120px;
                        
                        .club {
                            font-size: 60px;
                            height: 70px;
                            width: 100%;
                        }
    
                        .smiles {
                            width: 53px;
                            height: 22px;
                        
                            &::after {
                                width: 35px;
                            }
    
                            &::before {
                                width: 35px;
                            }
                        }
                    }
                }

            }

            .more-advantages {
                background: url(../images/svg/backgrounds/waves-club-accession.svg) no-repeat;
                background-size: 1200px;
                background-repeat: no-repeat;
                background-position-y: -117px;
                background-position-x: -163px;
                width: 1069px;
                height: 538px;
                display: inline-block;
                position: relative;
                padding-top: 180px;
                margin-bottom: 120px;
    
                &::before {
                    content: 'Además, tenés:';
                    background-color: #663399;
                    border-radius: 24px;
                    width: 286px;
                    height: 48px;
                    position: absolute;
                    top: 14%;
                    left: 50%;
                    margin-left: -143px;
                    font-size: 24px;
                    color: #fff;
                    padding-top: 5px;
                }
    
                &::after {
                    content: '';
                    background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    width: 90px;
                    height: 36px;
                    left: 65px;
                    top: 145px;
                }
    
                div {
                    color: #663399;
                    display: inline-block;
                    width: 294px;
                    margin: 0 20px;
                    position: relative;
    
                    label {
                        font-size: 120px;
                        font-family: 'NunitoBold', "Arial Bold", sans-serif;
                        margin: 0;
                        padding: 0;
                        height: 160px;
                    }
    
                    p {
                        font-size: 18px;
                        text-align: center;
                        padding: 0 41px;
                        font-family: 'NunitoBold', "Arial Bold", sans-serif;
                        line-height: 25px;
                    }
    
                    &:first-child {
                        p {
                            padding: 0 27px;
                        }
    
                        &::before {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 90px;
                            height: 36px;
                            left: 2px;
                            top: 345px;
                        }
    
                        &::after {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 62px;
                            height: 36px;
                            left: 200px;
                            top: 310px;
                        }
                    }
    
                    &:nth-child(2) {
                        &::before {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 62px;
                            height: 36px;
                            left: -100px;
                            top: 370px;
                        }
    
                        &::after {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 62px;
                            height: 36px;
                            left: 430px;
                            top: -110px;
                        }
                    }
    
                    &:last-child {
                        &::before {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-cloud.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 62px;
                            height: 36px;
                            left: 260px;
                            top: -50px;
                        }
    
                        &::after {
                            content: '';
                            background-image: url(../images/svg/icons/purple/ic-airplane.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            position: absolute;
                            width: 53px;
                            height: 51px;
                            left: 224px;
                            top: -97px;
                        }
                    }
                }
            }

            .advantage-club {
                width: 1069px;
                height: 305px;
                margin: 80px 0 0 0;
                border-radius: 53px;

                &::before {
                    width: 286px;
                    height: 48px;
                    top: -9%;
                    left: 50%;
                    margin-left: -143px;
                    font-size: 24px;
                }

                p {
                    margin: 45px 250px 35px 250px;
                    width: 500px;
                    padding: 0;
                    font-size: 16px;
                }

                .itens {
                    flex-direction: row;

                    div {
                        font-size: 16px;
                        height: 140px;
                        margin: 0 20px;
                        
                        &.receive {
                            background-size: 80px;
                            padding-top: 60px;
                            width: 131px;
                        }
        
                        &.discounts {
                            background-size: 50px;
                            padding-top: 60px;
                            width: 150px;
                        }
        
                        &.promotions {
                            background-size: 60px;
                            padding-top: 60px;
                            width: 160px;
                        }
        
                        &.validity {
                            background-size: 80px;
                            padding-top: 60px;
                            width: 131px;
                        }
        
                        &.bonus {
                            background-size: 60px;
                            padding-top: 60px;
                            width: 131px;
                        }
                    }
                }
            }

            .your-miles {
                width: 1280px;
                height: 463px;

                label {
                    width: 373px;
                    height: 48px;
                    font-size: 24px;
                    margin: 44px 450px 26px 450px;
                    padding-top: 6px;
                }

                p {
                    width: 924px;
                    font-size: 26px;
                    line-height: 30px;
                    padding: 0;
                }

                small {
                    font-size: 16px;
                    padding: 0;
                }

                .tiers {
                    width: 940px;
                    
                    div {
                        width: 157px;
                        height: 157px;
                        margin: 20px 25px;
                        background-size: 110px 74px;;
                    }
                }
            }

            .accession {
                width: 1028px;
                height: 393px;
                margin-top: 80px;

                .club {
                    width: 319px;
                    height: 393px;
                    margin: 0;
                    
                    label {
                        margin-top: 135px;
                    }
                }

                .info {
                    width: 707px;
                    padding: 26px 26px;

                    p {
                        font-size: 16px;

                        &:nth-child(2) {
                            text-align: left;
                        }
                    }

                    label {
                        font-size: 20px;
                        text-align: left;
                        padding: 0;
                    }

                    button {
                        width: 225px;
                        font-size: 20px;
                        height: 73px;
                        padding: 0;
                    }
                }
            }
        }
    }
}