.my-account {
    &__miles-action-frame {
        .miles-action-frame {
            a {
                text-align: center;
            }

            button.btn {
                position: relative !important;
                right: 0;
                margin: 10px auto 2px ;
                width: 60%;
                border:none;
            }

            &:first-child {
              margin-right:20px;
            }
        }
    }

    &__purchase-miles {
        padding: 15pt 25pt;
        background-color: white !important;

        .miles-limit {
            position: relative;
            display: inline-block;
            margin-left: 5px
        }

        .ic-arrow-down {
            &:before {
                margin-top: -10px;
                transform: rotate(180deg);
                content: ' ';
                vertical-align: middle;
                display: inline-block;
                background-image: url(icons/sprites/smls-orange-icons.svg);
                background-repeat: no-repeat;
                background-size: 23.3852em 66.4em;
                background-position: 0em -10.2em;
                width: 1.6em;
                height: 1.6em;
            }
        }

        .line {
            text-decoration: line-through;
        }

        .club-smiles-banner {
            background-color: $purple;
            color: $white;
            padding: 5px;
            margin: 0px 0 20px;
            height: 100px;
            width: 100%;
            float: right;

            p {
                width: 50%;
                float: left;
                padding: 16px 0 0 20px;
                display: inline-block;

            }

            button {
                background: transparent;
                border: 1px solid $white;
                color: $white;
                border-radius: 10px;
                padding: 4px 10px;
                display: inline;

                &.subscribe {
                    float: right;
                    margin-top: 26px;
                    margin-right: 20px;
                }

                &.close {
                    border: none;

                }
            }
        }

        h5 {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }

        .vouchers-wrapper {
            width: 75%;
            float: right;
            border: 1px solid $default;
            padding: 16px;
            align-self: flex-start;
            margin-bottom: 20px;
            margin-top: -30px;
            transition: max-height 1s ease-out;

            &.closed {
                max-height: 100px;
                transform-origin: length;
            }

            .vouchers-list {
                margin-top: 20px;
            }

            .voucher-toggle {
                font-size: 1.2rem;

                .vouchers-amount {
                    background-color: $default;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: $white;
                    line-height: 30px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: bold;
                    margin-right: 5px;
                }
            }

            .highlight {
                color: $default;
                margin-left: 10px;
            }

            font-size: 14px;

            .alert {
                border: 1px solid $default;
                padding: .75rem .75rem;
                margin-bottom: 30px;

                .ic-warning {
                    font-size: .8rem;
                    line-height: 2rem;
                    height: 1.5rem;
                    margin-right: 0.5rem;
                }

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                }

            }

            .wrapper-1 {
                width: 40%;
                padding-right: 20px;
            }

            .wrapper-2 {
                width: 60%;
                margin-top: 30px;
                position: relative;
            }

            .voucher-title {
                margin: 0px 0 10px;
                width: 100%;
            }

            .orange-text {
                color: $default;
            }

            .container {
                padding: 0 10px 15px;
            }

            .voucher-item {
                padding: 20px 10px 20px;
                margin-top: 20px;
                margin-bottom: 10px;
                border-top: 1px solid $gray-400;
                position: relative;

                &.disabled {
                    opacity: .5;
                }

                .voucher-title {
                    margin: 0px 0 10px;
                    width: 100%;

                    .applied {
                        background-color: $mp-voucher-applied;
                        color: $white;
                        padding: 5px 8px;
                        border-radius: 5px;
                        margin-left: 20px;
                        margin-top: -5px;
                        .close {
                            padding: 0;
                            margin: 0;
                            background-color: $white;
                            color: $mp-voucher-applied;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            font-size: 16px;
                            margin-left: 10px;

                        }
                    }
                }
            }

            .btn-voucher-acept {
                color: $white;
                background-color: $gray-700;
                width: 100px;
                position: absolute;
                bottom: 0;
                right: 7px;
                padding: 10px 10px;
            }

            .expiration-date {
                position: absolute;
                top: 57px;
                left: 40%;
                padding-right: 20px;
            }

            .offer {
                margin-top: 10px;
                height: 1.5em;
            }

            .available {
                margin-top: 46px;
            }

            .label {
                font-weight: bold;
            }

            .number {
                .value{
                    margin: 12px 0;
                }
            }

            .number, .limit {
                .value {
                    display: block;
                }
            }

            .show-more {
                color: $default;

                font-size: 1rem;
                text-align: center;
                width: 100%;

                border-top: 1px solid $gray-400;
                padding: 10px 0 0;
                margin-top: 10px;
                button {
                    color: $default;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    float: none;
                }
            }
        }

        .rule {
            width: 75%;
            float: right;

	        &.vouchers-rule {
                .miles-total {
                    flex: unset;
                    position: relative;
                    border-top: 1px solid $gray-400;
                    border-bottom: 1px solid $gray-400;
                    line-height: 1em;
                    padding-top: 40px;
                    padding-left: 35px;
                    font-size: 1em;

                    .value {
                        font-weight: bold;
                        margin: 10px auto 17px;
                        text-align: center;
                        display: block;
                        color: unset;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        border-top: 75px solid transparent;
                        border-bottom: 75px solid transparent;
                        border-left: 30px solid $gray-400;
                        margin-left: 1px;
                        top: -1px;
                        right: -30px;
                        z-index: 8;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        border-top: 75px solid transparent;
                        border-bottom: 75px solid transparent;
                        border-left: 30px solid white;
                        top: -1px;
                        right: -29px;
                        z-index: 9;
                    }
                }

                .value-total {
                    flex: 0.5;
                    padding: 40px 20px 0 40px;
                    .total {
                        color: $default;
                    }
                }

                .miles-amount {
                    padding: 40px 10px;
                    text-align: left;
                    border-bottom: 1px solid $gray-400;
                }
            }

            button.buy-btn {
                display: none
            }

            .miles-amount {
                padding: 20px 10px;

                &::before {
                    border-left: 30px solid $gray-400;
                    right: -30px;
                }

                &::after {
                    border-left: 30px solid $white;
                    right: -29px;
                }

                .input-border {
                    #miles {
                        width: 50%;
                    }
                }
            }

            .diamond, .club, .bonus {
                border:1px solid $gray-400;
                border-right: none;
                padding-top: 17px;
        
                .value {
                    display: block;
                }
        
                &::after {
                    border-top: 74px solid $white;
                    border-bottom: 74px solid $white;
                    right: 0px;
                }
            }

            .diamond {
                padding-left: 30px;
                padding-right: 30px;
                line-height: 1em;
        
                .value {
                    font-weight: bold;
                    margin: 10px auto 17px;
                    text-align: center;
                }
        
                &::after {
                    border-left: 30px solid $diamond;
                }
            }

            .club {
                padding-left: 26px;
                padding-right: 34px;
                font-size: 12px;
                text-align: left;
                font-family: $ligthFontFamily;
                line-height: 14px;
        
                label {
                    max-width: 50px;
                    float: left;
                    margin-left: 10px;
                }
        
                .value {
                    margin: 1px auto 14px;
                    text-align: right;
                    line-height: 22px;
                    float: left;
                    width: 100%;
                    font-size: 14px;
                    padding-right: 10px;
        
                    small {
                        float: left;
                        clear: both;
                        width: 100%;
                        font-size: 12px;
                    }
                }
        
                &::after {
                    border-left: 30px solid $club;
                }
            }

            .bonus {
                padding-left: 45px;
                padding-right: 34px;
                font-size: 12px;
                text-align: left;
                font-family: $ligthFontFamily;
                line-height: 14px;
        
                label {
                    max-width: 50px;
                    float: left;
                    margin-left: 10px;
                }
        
                .value {
                    margin: 1px auto 14px;
                    text-align: right;
                    line-height: 22px;
                    float: left;
                    width: 100%;
                    font-size: 14px;
                    padding-right: 10px;
        
                    small {
                        float: left;
                        clear: both;
                        width: 100%;
                        font-size: 12px;
                    }
                }
        
                &::after {
                    border-left: 30px solid $default;
                }
            }

            .miles-total {
                flex: 1;
                position: relative;
                border-top: 1px solid $gray-400;
                border-bottom: 1px solid $gray-400;

                font-size: 12px;
                line-height: 14px;
                padding-top: 17px;
                padding-left: 7px;

                label {
                    max-width: 50px;
                    small {
                        white-space: nowrap;
                        font-size: 12px;
                    }
                }

                .value {
                    margin: 1px auto 14px;
                    text-align: right;
                    display: block;
                    line-height: 22px;
                    float: left;
                    width: 100%;
                    font-size: 14px;
                    padding-right: 25px;
                    color: $default;
                    font-family: $boldFontFamily;

                    small {
                        float: left;
                        clear: both;
                        width: 100%;
                        font-size: 12px;
                        font-family: $boldFontFamily;
                    }
                }

                &::before{
                    content: "";
                    position: absolute;
                    border-top: 75px solid transparent;
                    border-bottom: 75px solid transparent;
                    border-left: 30px solid $gray-400;
                    margin-left: 1px;
                    top: 0;
                    right: -30px;
                    z-index: 8;
                }

                &::after{
                    content: "";
                    position: absolute;
                    border-top: 75px solid transparent;
                    border-bottom: 75px solid transparent;
                    border-left: 30px solid white;
                    top: 0px;
                    right: -29px;
                    z-index: 9;
                }
            }

            .value-total {
                padding: 35px 20px 0 40px;
                border: 1px solid $primary;
                margin-left: 7px;
                border-left: transparent;

                &::before {
                    border-left: 30px solid $primary;
                }

                &::after {
                    border-left: 30px solid $white;
                }
            }
        }

        .banner-purple {
            margin-top: 10px;
        }

        .banner-purple.close {
            display: none;
        }

        .limit-bar {
            position: absolute;
            bottom: 12px;
            left: 0;

            .progress-level {
                width: 300px;
                height: 17px;
                border: 1px solid $gray-500;
                border-radius: 9px;
                overflow: hidden;

                .level {
                    background-color: $gray-500;
                    overflow: hidden;
                    height: 100%;
                    border-radius: 0px 8px 8px 0px;
                }
            }

            p {
                margin-bottom: 2px;
                display: inline-block;
            }
        }

        button.buy-btn {
            float: right;
            clear: both;
            padding: 10px 40px;
            margin-bottom: 10px;
            margin-top: 0px;
        }
    }

    .miles-actions {
        flex-direction: row;
        display: flex;
        margin: 9px 0;
    }
}

.pPurple {
    color: $club;
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
    .my-account {
        &__purchase-miles {
            h5 {
              font-size: 13px;
            }

            button.buy-btn {
                display: none;
            }

            .ic-arrow-up {
                margin-left: 5px;
                margin-bottom: 5px;

                &:before {
                    margin-top: -8px
                }
            }

            .ic-arrow-down {
                margin-left: 5px;
            }

            .smls-orange-icons {
                &:before {
                    -webkit-transition: 0.3s ease-in-out;
                    -moz-transition: 0.3s ease-in-out;
                    -o-transition: 0.3s ease-in-out;
                    transition: 0.3s ease-in-out;
                }
            }

            .voucher-toggle {
                text-align: center;

                .vouchers-amount {
                    position: absolute;
                    left: calc( 50% - 15px);
                    top: -15px;
                }

                .highlight {
                    display: block;
                }
            }

            .banner-purple {
                .col-md-1 {
                  .btn-close {
                      top: -140px;
                  }
                }
        
                .col-md-8 {
                    p {
                        margin-top: 30px;
                        text-align: center;
                    }
                }  
            }

            .rule {
                position: relative;
                background-color: $gray-100;
                margin-top: 20px;
                width: 100%;

                button.buy-btn {
                    display: block;
                    margin: 10px 40px 20px;
                }

                .miles-amount {
                    padding: 20px 35px 20px !important;
                    border-bottom: none !important;

                    h5 {
                      font-weight: normal;
                    }

                    .input-border {
                        width: auto;
                        text-align: right;
                        padding: 5px;

                        #miles {
                            width: 100px;
                        }
                    }
                }

                .diamond {
                    color: black;
                    background-color: transparent;
                    border: none;
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 17px;
                    display: flex;
                    flex-direction: row;
                    margin-left: 10px;

                    .value {
                        font-weight: bold;
                        text-align: center;
                        display:inline-flex;
                        order: 2;
                        margin: auto;
                        margin-right: 10px;
                    }

                    .level {
                        order: 1;

                        &::before {
                            content: "\00a0 ";
                        }
                    }

                    &::after{
                        display: none
                    }
                }

                .club {
                    flex: 1;
                    color: white;
                    background-color: $club;
                    position: relative;
                    border:1px solid $gray-400;
                    border-right: none;
                    padding-left: 26px;
                    padding-right: 34px;
                    padding-top: 17px;
                    font-size: 12px;
                    text-align: left;
                    font-family: $ligthFontFamily;
                    line-height: 14px;

                    label {
                        max-width: 50px;
                        float: left;
                        margin-left: 10px;
                    }

                    .value {
                        margin: 1px auto 14px;
                        text-align: right;
                        display: block;
                        line-height: 22px;
                        float: left;
                        width: 100%;
                        font-size: 14px;
                        padding-right: 10px;

                        small {
                            float: left;
                            clear: both;
                            width: 100%;
                            font-size: 12px;
                        }
                    }

                    &::after{
                        content: "";
                        position: absolute;
                        border-top: 56px solid white;
                        border-bottom: 57px solid white;
                        border-left: 30px solid $club;
                        top: 0px;
                        right: 0px;
                    }
                }

                .bonus {
                    background-color: initial;
                    border: none;
                    color: $black;
                    font-size: 14px;
                    font-weight: bold;
                    padding-top: 0;

                    .miles-bonus-text {
                        float: left;
                    }

                    .value {
                        margin: 0;
                        float: right;
                        width: auto;
                        line-height: inherit;

                        .miles-text {
                        display: none;
                        }
                    }

                    &::after {
                        content: none;
                    }
                }

                .miles-total {
                    display: flex;
                    flex-direction: row;
                    border: none !important;
                    height: auto;
                    font-size: 14px !important;
                    padding: 15px 44px 0 45px !important;
                    text-align: right;
                    width: auto;

                    &::before{
                        display: none;
                    }

                    &::after{
                        display: none;
                    }
                    span {
                        flex: 1;
                        font-weight: bold;

                        &.label {
                            text-align: left;
                        }

                        &.value {
                            text-align: right !important;
                            margin: 0 !important;
                            padding: 0;
                            float: right;
                            line-height: inherit;
                            font-weight: normal !important;
                        }
                    }

                    .orange {
                      display: none;
                    }
                }

                .value-total {
                    display: flex;
                    flex-direction: row;
                    border: none;
                    border-top: 1px solid $gray-400;
                    height: auto;
                    padding: 10px 45px 0 45px !important;
                    text-align: center;
                    margin: 20px 0 10px;
                    width: auto;

                    &::before{
                        display: none;
                    }

                    &::after{
                        display: none;
                    }

                    span {
                        flex: 1;
                        font-weight: bold;

                        &.label {
                            text-align: left;
                            font-weight: normal;
                        }

                        &.total {
                            text-align: right;
                            color: inherit !important;
                            font-weight: normal;
                        }
                    }
                }
            }

            .limit-bar {
                order: unset;
                margin: 40px 10px 20px;
                position: relative;
                display: block;

                .progress-level {
                    width: auto;
                }
            }

            .vouchers-wrapper {
                width: calc(100% - 10px);
                position: relative;
                margin-top: 20px;
                margin-right: 5px;

                .vouchers-list {
                    .wrapper-1 {
                        width: 100%;
                        padding-right: 0;
                    }

                    .wrapper-2 {
                        width: 100%;
                        margin-top: 30px;
                    }

                    .voucher-item {
                        padding: 10px 0px 0px 0px;

                        .available {
                            margin-top: 25px;
                        }

                        .expiration-date {
                            position: relative;
                            top: 20px;
                            left: 0;
                            width: 100%;
                        }

                        .value {
                            float: right;
                        }

                        .btn-voucher-acept {
                            width: 80%;
                            position: relative;
                            margin: 30px auto 0px;
                        }

                        .number {
                            margin-bottom: 10px;
                            .value {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .btn {
                margin-top: 10px;
                order: 3;
                margin-right: 10px;
            }
        }

        .miles-actions {
            flex-direction: column;

            .my-account__miles-action-frame {
                margin: 5px;
            }
        }
    }
}
