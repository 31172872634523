.modal.vouchers {
  &.hide {
    display: none;
    opacity: 0;
    position: absolute;
    top: -500;
  }

  &.show {
    display: block;
    position: fixed;
    opacity: 1;
  }

  .modal-content {
    margin-top: 26%;

    .vouchers-body {
      font-size: 14px;

      .alert {
        border: 1px solid $default;
        padding: .75rem .75rem;
        margin-bottom: 30px;

        .ic-warning {
            font-size: .8rem;
            line-height: 2rem;
            height: 1.5rem;
            margin-right: 0.5rem;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }
      }

      .voucher-item {
        padding: 20px 10px 20px;
        margin-top: 20px;
        margin-bottom: 10px;
        border-top: 1px solid $gray-400;
        position: relative;

        .orange-text {
          color: $default;
        }

        .voucher-title {
          margin: 0px 0 10px;
          width: 100%;
        }

        .wrapper-1 {
          width: 45%;
          padding-right: 20px;

          .offer {
            margin-top: 10px;
            height: 1.5em;
          }

          .available {
            margin-top: 46px;
          }
        }
  
        .wrapper-2 {
          width: 55%;
          margin-top: 30px;
        }

        .expiration-date {
          position: absolute;
          top: 57px;
          left: 45%;
        }

        .flight-voucher-conditions {
          width: 100%;
          margin: 15px 0 0 0;

          .title {
            color: $primary;
          }

          .item-voucher-condition {
            margin-top: 10px;
          }
        }

        .label {
          font-weight: bold;
        }

        .number {
          .value {
            margin-bottom: 24px;
          }
        }

        .number, .limit {
          .value {
            display: block;
          }
        }

        .btn-voucher-acept {
          color: $white;
          background-color: $gray-700;
          width: 100px;
          position: absolute;
          bottom: 0;
          right: 7px;
          font-family: "NunitoRegular","Arial",sans-serif;
        }
      }
    }
  }
}

.modal-wrapper {
  .hide {
      display: none;
      opacity: 0;
  }
}

@media (max-width: 600px) {
  .modal.vouchers .modal-content .vouchers-body .voucher-item {
    .wrapper-1 {
      width: 100%;

      .available {
        margin-top: 23px;
      }
    }

    .wrapper-2 {
      width: 100%;
      margin-top: 10px;
    }

    .expiration-date {
      position: initial;
      width: 100%;
      top: 171px;
      left: 10px;
      margin-top: 10px;
    }

    a {
      width: 100%;
      text-align: center;
    }

    .number {
      margin-bottom: 10px;
      
      .value {
        margin: 0;
      }
    }

    .value {
      float: right;
    }

    .btn-voucher-acept {
      width: 70%;
      position: relative;
      margin: 30px auto 0px;
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .modal.vouchers .modal-content .vouchers-body .voucher-item {
    padding: 20px 0;

    .wrapper-1 {
      padding-right: 0;
    }
  }
}