.modify-modal {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .cross-icon {
    align-self: flex-end;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .icon-warning {
        align-self: center;
        margin-bottom: 20px;
      }

      .text {
        color: $gray-600;
        text-align: center;
        margin-top: 10px;
        font-weight: bold;
      }
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 575px){
  .btn-container{
    justify-content: center !important;
    button{
      margin-bottom: 12px;
    }
  }
}