.home {
    .smiles-offer-counter {
        align-items: center;
        background-color: #FF5A00;
        border-radius: 50px 0 0 50px;
        display: flex;
        height: 80px;
        justify-content: space-between;
        margin: 20px 0 20px;
        padding: 0 47px 0 107px;
        position: relative;
        width: 100%;

        .icon-timer {
            background-repeat: no-repeat;
            background-position: center center;
            background-color: inherit;
            border-radius: 50%;
            display: block;
            height: 95px;
            left: -1px;
            position: absolute;
            top: -7px;
            width: 95px;
            background-size: cover;
        }

        .counter-timer {
            p {
                color: #FFF;
                display: inline-block;
                font-family: 'NunitoBold', sans-serif;
                font-size: 32px;
                line-height: 0.55em;
                margin: 12px 4px 0 5px;

                span {
                    font-size: 12px;
                }
            }
        }

        .counter-text {
            max-width: 700px;
            margin-right: 100px ;

            p {
                color: #FFF;
                font-family: 'NunitoBold', sans-serif;
                font-size: 26px;
                line-height: 1.1em;
                margin: 0;

                span.other-color {
                    color: #FFF200;
                }
            }
        }

        .counter-button {
            a {
                align-items: center;
                background-color: #FFF200;
                border-radius: 4px;
                color: #FF5A00;
                display: flex;
                font-family: 'NunitoBold', sans-serif;
                font-size: 20px;
                height: 40px;
                justify-content: center;
                line-height: 1em;
                max-width: 250px;
                min-width: 215px;
                padding: 2px 10px 0;
                text-align: center;
                text-decoration: none !important;
            }
        }

        &.club-smiles {
            background-color: $primary;

            .counter-button {
                a {
                    color: #663398;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .home {
        .smiles-offer-counter {
            padding: 0 15px 0 80px;

            .counter-timer {
                p {
                    font-size: 24px;
                    margin: 12px 2px 0 3px;
                }
            }

            .counter-text {
                p {
                    font-size: 16px;
                    margin-right: 0px;
                }
            }

            .counter-button {
                a {
                    font-size: 17px;
                    height: 35px;
                    min-width: 130px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .home {
        .smiles-offer-counter {
            border-radius: 0;
            flex-direction: column;
            height: auto;
            margin: 0 0 30px;
            padding: 15px 15px 20px 15px;

            .icon-timer {
                background-color: transparent;
                height: 56px;
                left: 50%;
                margin-left: -169px;
                top: auto;
                width: 56px;
                bottom: 77px;
            }

            .counter-timer {
                margin: 13px 0 20px 71px;
                order: 2;

                p {
                    font-size: 35px;
                    line-height: 0.4em;
                }
            }

            .counter-text {
                background-color: inherit;
                border-bottom: 1px solid #FFF;
                display: block;
                margin-bottom: 20px;
                order: 1;
                padding-bottom: -10px;
                padding-top: 4px;
                position: relative;
                width: 100%;
                margin-right: 0px;
                

                p {
                    font-size: 18px;
                    text-align: center;

                    .first-text {
                        display: block;
                        font-size: 22px;
                        margin-bottom: 8px;
                        text-transform: uppercase;
                    }
                }

                &:after {
                    background-color: inherit;
                    border-bottom: 1px solid #FFF;
                    border-left: 1px solid #FFF;
                    bottom: -8px;
                    content: " ";
                    height: 14px;
                    left: 50%;
                    margin-left: -7px;
                    position: absolute;
                    width: 14px;

                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                }
            }

            .counter-button {
                display: block;
                order: 3;
                width: 100%;

                a {
                    font-size: 20px;
                    height: 40px;
                    max-width: 100%;
                    min-width: 100%;
                }
            }
        }
    }
}

@media (max-width: 359px) {
    .home {
        .smiles-offer-counter {
            .counter-timer {
                p {
                    font-size: 40px;
                }
            }
        }
    }
}
