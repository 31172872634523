.club-text-primary {
  color: $default;
}

.club-text-diamond {
  color: $club;
}

.back-link {
  justify-content: end;
  .edit-reserve {
    color: $primary !important;
    text-decoration: underline !important;
  }
  .edit-reserve:hover {
    cursor: pointer;
  }
}

.passanger-resume.row {
  margin-top: 1rem;
  .col {
    padding: 1rem;
  }
}

.jarvis-checkout-section {
  .col {
    // padding: 2rem;
    .confirm-payment-recaptcha {
      padding-top: 1rem;
      div > div > div {
        width: 100% !important;
      }
    }
    .confirm-payment {
      text-align: center;
    }
    .confirm-payment-button {
      .btn {
        margin: 20px 20px;
        font-size: 20px;
        padding: 16px 20px;
        height: 100%;
      }
    }
  }

  .keyboard {
    background-size: 80px;
    background-position: top right;
    background-repeat: no-repeat;

    h4 {
      width: 100%;
      font-size: 1.2rem;
      color: $secondary;
    }

    input {
      border: 1px solid $gray-800;
      margin: 20px auto;
      padding: 10px 5px;
      border-radius: $border-radius;

      &::placeholder {
        color: $gray-400;
        @include bms-font-size(4vw, 12px);
      }
    }

    .layout {
      height: 160px;
      padding: 10px 20px;
      border: 2px solid $primary;
      border-radius: $border-radius;
      margin-bottom: 20px;

      span {
        display: block;
        width: 240px;
        margin: 0 auto;
        font-weight: bold;
      }

      .numbers {
        width: 240px;
        margin: 10px auto;

        > div {
          float: left;
          width: 45px;
          height: 45px;
          background-color: $gray-800;
          color: $white;
          margin-right: 3px;
          margin-bottom: 3px;
          border-radius: $border-radius;
          text-align: center;
          padding-top: 11px;
          cursor: pointer;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .btn {
        width: 136px;
        margin: 0;
      }
    }

    .box-terms {
      display: block;
      margin-top: 20px;

      .highlight {
        color: $primary;
      }

      a {
        color: $club;
      }
    }

    &.club-smiles {
      background-image: none;
      flex-direction: row;

      .buttons {
        margin-top: 20px;

        .btn {
          width: 100%;
        }
      }

      p {
        width: 100%;

        span {
          color: $default;
        }

        a {
          color: $club;
        }
      }
    }
  }
}

.resume-info__left {
  background-color: $default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 280px;

  h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    font-size: 1.3rem;
  }

  .resume-alert-icon {
    background: url(#{$svg-path}/icons/white/ic-alert-error.svg) no-repeat center;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: block;
    background-size: 60px;
  }

  .resume-gear-icon {
    background: url(../images/svg/icons/white/ic-gear.svg) no-repeat center;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: block;
    background-size: 60px;
  }

  .resume-check-icon {
    background: url(../images/svg/icons/white/ic-check-success.svg) no-repeat center;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: block;
    background-size: 60px;
  }
}

.resume-info__right {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 280px;
  .icon-check {
    display: inline-block;
    background: url(#{$svg-path}/icons/gray/ic-check-box-error.svg) no-repeat center;
    width: 20px;
    height: 17px;
    background-size: 20px;
    margin-right: 10px;
  }
}

.info-dark-span {
  font-size: 14px;
  font-weight: 800;
  margin-top: 5px;
}

.info-dark-span-no-margin {
  font-size: 14px;
  font-weight: 800;
}

.info-span {
  font-size: 14px;
  margin-bottom: 5px;
}

.center {
  text-align: center !important;
}

.resume-gray-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 282px;
  background-color: $gray-300;
  span {
    font-size: 14px;
    font-weight: 800;
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
  .wp-flex {
    display: block;
    width: 70px;
    height: 70px;
    background: url(#{$image-path}/footer/whatsapp-icon.svg) no-repeat;
    background-size: 60px;
  }
}

.jarvis-right__footer {
  display: flex;
  justify-content: end;
  margin: 10px 1px 0px 10px;
  button {
    padding: 0 40px;
    background: $default;
    border: 1px solid $default;
  }
  button:hover {
    background: $white;
    color: $default;
    border: 1px solid $default;
  }
}

.resume-lateral {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  height: 280px;
  border: 1px solid $gray-300;

  hr {
    width: 100%;
  }

  span {
    font-size: 14px;
  }
}
