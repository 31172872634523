.switch-club-plan {
    .club-information {
        display: flex;

        &__info {
            padding: 20px 60px 20px 50px;
            float: right;
            flex: 1;

            span {
                display: block;
                font-size: 14px;
                margin-top: 2px;
                clear: both;

                &.label {
                    color: $club;
                    margin-bottom: 5px;
                }
            }

            &__plan {
                margin: 0 0 5px 0;
                padding: 20px 10px;
                font-size: 1.5em;
                display: block;
                width: auto;
            }
        }

        &__logo {
            padding: 0 3%;
            width: 50%;

            label {
                font-size: 6em;
                margin: 0;
            }
        }

        .club-information__info {
            border: none;
        }
    }

    &.confirmation {
        .club-information__logo {
            width: unset;
        }
    }

    .header-photo {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .my-account__header-info {
        border: 1px solid $club;
        box-shadow: none;
        height: auto;
    }

    .club-information__state {
        padding: 20px 0px 20px 50px;
        border-left: 1px solid $club;
        height: 100%;

        span.label {
            font-size: 14px;
            color: $club;
        }

        .btn {
            background-color: $club;
            margin-top: 10px;
        }

        .club-information__info__status{
            margin: 4px 0;
            border: none;
            padding: 0;
            background-position: 2px 5px;
            width: 200px;
            text-align: left;

            &.active {
                padding-left: 30px;
            }

            &.suspended {
                color: $red;
                background-image: none;
                font-size: 1.2em;

                &::before {
                    content: "!";
                    text-align: center;
                    margin-right: 5px;
                    width: 30px;
                    height: 30px;
                    border: 2px solid $red;
                    display: inline-block;
                    border-radius: 50%;
                }
            }
        }
    }

    .processing-banner {
        text-align: left;
        background-color: $club;
        margin: 20px 0;
        padding: 20px;

        h5 {
            color: $white;
        }

        p {
            color: $white;
            margin: 0;
        }
    }

    .sub-header {
        text-align: center;
        padding: 20px 0;
        background: $white;
        border-radius: 20px;
        margin: 10px 0px;

        h5 {
            color: $club;
            font-size: 30px;
        }
    }

    .switch-rules {
        margin: 30px 0 0;

        h5 {
            text-transform: uppercase;
            color: $club;
            margin: 20px 0;
        }
    }

    .plan-miles-info {
        display: flex;

        .plan-club-header {
            background-color: $club;
            color: $white;
            padding: 15px 10px ;

            &__logo {
                width: 80%;
                float: none;
                margin: 10px auto;
                display: block;

                label {
                    font-family: $boldFontFamily;
                    color: $white;
                    font-size: 1.7rem;
                    letter-spacing: 0px;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    line-height: 1.7rem;
                    margin: 0;
                }

                small {
                    @include base-logo();
                }
            }
        }

        .plan-resume {
            display: flex;
            flex-direction: column;
            width: 90%;

            div {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                padding: 0;
                color: $club;
                margin: 7px auto;
                width: 100%;

                span {
                    font-size: 1rem;
                    flex: 1;
                    text-align: right;
                }

                &.recived-miles {
                    color: $default;
                }
            }

            button.continue-btn {
                background-color: $club;
                color: $white;
                width: 40%;
                height: 40px;
                border: none;
                border-radius: 4px;
                font-weight: bold;
                margin-bottom: 30px;
            }
        }
    }

    .plan-data {
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding: 30px;
        background: $white;
        border-radius: 20px;

        .plan-data-item {
            margin-right: 20px;

            &:last-of-type {
                margin-right: 0px;
            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    margin: 0 0 1rem;
                    padding-left: 23px;
                    position: relative;

                    span.ic-check {
                        position: absolute;
                        left: 0px;
                        top: 3px;
                    }

                    font-family: $boldFontFamily;
                    font-size: 13px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    strong {
                        color: $club;
                    }
                }
            }
        }

        @media (max-width: 768px) { 
            flex-direction: column;
            align-items: flex-start;
            width: 90%;
            align-self: center;

            .plan-data-item {
                ul li {
                    margin-right: 0px;
                    margin-bottom: 20px !important;

                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-flow: column;

        .my-cards {
            width: 94vw;
        }

        .my-account__header-info {
            padding-top: 0;
            border: none;
            width: 94vw;
            box-shadow: 0 1px 3px 0 $gray-400;
        }

        .club-information {
            padding-top: 80px;
            border: none;

            &__logo {
                border-top: 2px solid $club;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 90px;
                padding: 0;

                label {
                    font-size: 3em;
                    margin-top: -5px;
                    color: $club;
                }

                small {
                    background-size: 37%;
                    background-position-x: center;
                    margin-top: -13px;

                    &::before {
                        width: 27%;
                        margin-left: 0px;
                        border-bottom: 2px solid $club;
                        top:2px;
                        border-radius: 1px
                    }

                    &::after {
                        width: 27%;
                        margin-right: 0px;
                        border-bottom: 2px solid $club    ;
                        top:2px;
                        border-radius: 1px
                    }
                }
            }

            &__info {
                padding: 20px 0;

                span.label {
                    font-size: 1.1em;
                }

                &__plan {
                    width: 100%;
                    font-size: 2em;
                    text-align: center;
                    padding: 28px 0;
                    font-weight: bold;
                    margin: 10px 0;
                }
            }

            &__state {
                padding: 20px 0;
                border-top: 2px solid $club;
                border-left: none;

                .club-information__info__status {
                    margin:30px 0 20px;
                }

                button.btn {
                    width: 60%;
                    display: block;
                    clear: both;
                    margin: 30px auto 0;
                }
            }
        }

        .sub-header {
            width: 90%;
            align-self: center;
        }

        .switch-rules {
            order: 7;
            margin: 0 10px;

            .col-6, .col-auto {
                padding-top: 5px;
            }
        }

        .processing-banner {
            order: 6;
            margin: 10px 10px;
        }
    }
}

.club-switch-popup {
    font-family: "NunitoBold","Arial Bold",sans-serif;
    text-align: center;

    &.show {
        opacity: 1;
        display: block;
        background-color: rgba(0, 0, 0, 0.4);

        .modal-dialog {
            margin: 20% auto;
        }

        .modal-content {
            border: none;
            border-radius: 0;
            padding: 20px;
        }

        .message {
            text-align: center;
            margin: 0px 60px 20px;

            p {
                margin-bottom: 3px;
            }
        }
    }

    &__logo {
        width: 140px;
        height: 90px;
        margin: 20px auto 10px;
        display: block;

        label {
            font-family: $boldFontFamily;
            color: $club;
            font-size: 3.7rem;
            letter-spacing: -2px;
            padding: 0;
            width: 100%;
            text-align: center;
            line-height: 3.1rem;
            margin: 0;
        }

        small {
            @include base-logo($color: $club);
            height: 22px;

            &::before {
                top: 0;
                left: 70%;
                width: 25%;

            }

            &::after {
                top: 0;
                right: 70%;
                width: 25%;
            }
        }
    }

    button.acept {
        background-color: $club;
        color: $white;
        width: 40%;
        height: 40px;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        margin-bottom: 30px;

    }

    &.hide {
        opacity: 0;
        display: none;
    }
}

@media (max-width: 768px) {
    .club-switch-popup {

        .modal-dialog {
            width: 90%;

            button.acept {
                width: 70%;
            }
        }
    }
}
