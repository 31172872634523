.booking-details {
  display: none;
}

@media (min-width: 992px) {
  .booking-details {
    display: block;
    background-color: $white;
    width: 364px;
    margin: auto;
    overflow-y: auto;

    &.has-booking {
      height: calc(100vh - 40vh);
    }

    &.fixed {
      position: fixed;
      margin: 0 30px;
      z-index: 1;
      top: 150px;
    }

    &.fixed-emission {
      position: fixed;
      margin: 0 30px;
      top: 20px;
      z-index: 1;
    }

    .box {
      padding: 0 10px 10px 10px;
      border: 2px solid $gray-300;
      background-color: $white;
      &:not(:last-child) {
        border-bottom: none;
      }
    }
    .tb-booking {
      width: 100%;
      @include bms-font-size(2vw, 14px);
      thead {
        tr {
          height: 30px;
          border-bottom: 2px solid $gray-500;
        }
      }
      tbody {
        tr {
          height: 35px;
          border-bottom: 1px solid $gray-500;
          td,
          th {
            padding: 5px;
            &:first-child {
              text-align: left;
            }

            .ic-two-luggage-gold {
              font-size: 12pt;
              &:before {
                content: "";
                vertical-align: middle;
                display: inline-block;
                background-image: url(#{$svg-path}/icons/gold/ic-two-luggage-gold.svg);
                background-repeat: no-repeat;
                width: 2em;
                height: 1.2em;
              }
            }
          }
        }
      }
      span {
        display: block;
        position: relative;
        width: 100%;
        &.heavy {
          font-family: $boldFontFamily;
        }
        &.plus-sign {
          padding-top: 21px;
          padding-left: 10px;
          text-align: center;
        }
        b {
          position: absolute;
          margin-left: 1px;
        }
      }
      &.total {
        td,
        th {
          padding: 5px;
          &:first-child {
            text-align: left;
          }
        }
        td,
        th {
          &:not(:first-child){
            text-align: center;
            min-width: 80px;
          }
        }
        tbody {
          tr {
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    .link-rules {
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      .ic-file-list {
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    .wait-select {
      text-align: center;
      height: 110px;
      overflow-y: hidden;
      background-image: url(#{$svg-path}/icons/gray/ic-dropdown.svg);
      background-position: 98% 15px;
      background-repeat: no-repeat;
      background-size: 12px;
      p {
        font-size: 13px;
        margin: 15px auto;
        padding: 0;
        strong {
          font-family: $boldFontFamily;
        }
        &:before {
          position: relative;
          display: block;
          content: " ";
          width: 100%;
          height: 40px;
          background-image: url(#{$svg-path}/icons/gray/ic-airplane-two.svg);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 35px;
        }
      }
      &.return {
        p {
          &:before {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }
  }
  .travel-header {
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;

    @include bms-font-size(2vw, 12px);

    > div {
      flex-basis: 100%;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }

    .stretch {
      position: relative;
      text-indent: 20px;

      &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 16px;
        height: 16px;
        background-image: url(#{$svg-path}/icons/gray/ic-airplane-two.svg);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 16px;
      }

      &.return {
        &:before {
          transform: scaleX(-1);
        }
      }
    }
    .logo-airlines {
      width: 100px;
      height: 25px;
    }
  }
}
