.emissions {
  &__pnr {
    padding: 10px;

    h3 {
      font-size: 22px;
    }
    
    &-subtitle {
      color: $gray-700;
    }

    &-box {
      border: 2px solid $gray-300;

      & + .emissions__pnr-box {
        &:last-child {
          border-top: 0;
        }
      }

      p {
        font-size: 8pt;
        margin-bottom: 0;

        &.detail {
          font-size: 8pt;
          line-height: 9pt;
        }
      }

      .align-items-baseline {
        p {
          font-size: 7pt;
        }
        span {
          float: left;
          margin-right: 3px;
        }
      }

      span {
        font-size: 8pt;
        &.ic-arrow-flight-details {
          font-size: 5pt;
        }

        &.back {
          &:before {
            transform: scaleX(-1);
          }
        }

        &[class^="logo"] {
          display: block;
          min-height: 10pt;
          margin-top: 5pt;
          padding: 20pt 0;
          border: 1px solid $gray-300;
        }
      }
    }

    &__buttons {
      button {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 768px) {
    &__pnr {
      &-subtitle {
        
      }

      h3 {
        font-size: 1.75rem;
      }
      
      &-box {
        & + .emissions__pnr-box {
          &:last-child {
            border-top: 2px solid $gray-300;
            border-left: 0px;
          }
        }

        p {
          font-size: 10pt;
        }

        .align-items-baseline {
          p {
            font-size: 10pt;
          }
        }

        span {
          font-size: 8pt;
          &.ic-arrow-flight-details {
            font-size: 8pt;
          }
        }
      }

      &__buttons {
        button {
          font-size: 14px;
        }
      }
    }
  }
}