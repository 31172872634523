.baggage-success {
  .text-dark {
    color: $gray-300;
  }

  .highlights {
    font-size: 20px;
  }

  .summary {
    padding: {
      top: 20px;
      left: 20px;
      right: 20px;
    }
    margin: {
      right: 20px;
      left: 20px;
    }
    border: 1px solid $gray-200;
    box-shadow: 0 1px 3px 0 $gray-400;

    @media (min-width: $site-width-mobile) {
      padding: 0;
      margin: 0;
      border: none;
      box-shadow: none;
    }
  }
}
