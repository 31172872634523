.optin-subscribe-frame {

    margin: 10px;
    font-size: 16px;

    span.title {
        margin-bottom: 10px;
        font-size: 1.4em;
        display: block;
        text-align: center;
    }

    @media (max-width: 768px) {
      button {
        width: 100%;
      }
    }

    button {
        background-color: $default;
        color: $white;
        padding: 10px 17px;
        border: none;
        border-radius: 4px;
        font-weight: bold;
        margin-top: 10px;
    }

    .frame {
        border: 1px solid $gray-400;
        padding: 24px;
        text-align: left;
    }
    input[type=text] {
        padding: 10px;
        width: 100%;
        border: 1px solid #CCC;
    }
    h4 {
        text-align: center;
        color: $gray-400;
        font-size: 1em;
        line-height: 1em;
    }

    .terms {
        position: relative;
        margin-top: 12px;


        .form-check-label {
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding-left: 20px;
            input {
              display: none;
              ~ span {
                &:before {
                  content: " ";
                  position: relative;
                  float: left;
                  margin-top: 1px;
                  margin-right: 5px;
                  width: 16px;
                  height: 16px;
                  background-image: url(#{$svg-path}/icons/ui/ic-input-radio.svg);
                  background-repeat: no-repeat;
                  background-position: 0 bottom;
                }
              }
              &:checked {
                ~ span {
                  &:before {
                    background-position: -16px bottom;
                  }
                }
              }
              &[type="checkbox"] {
                ~ span {
                  &:before {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    background-image: url(#{$svg-path}/icons/ui/ic-input-checkbox.svg);
                  }
                }
              }
            }
            &.disabled {
              cursor: not-allowed;
              opacity: 0.3;
            }
          }
    }
}
