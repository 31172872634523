.retrocredit {
  .font-select {
    @include bms-font-size(3.8vw, 12px);
    font-weight: 600;
    cursor: pointer;
  }

  span.alt-select {
    &::-ms-expand {
      display: none;
    }

    &:after {
      background-image: url('/images/svg/icons/gray/ic-arrow-down.svg');
      position: absolute;
      content: " ";
      background-size: 100% 100%;
      width: 10%;
      height: 100%;
      margin-left: 5px;
    }
  }

  .modal-list {
    position: absolute;
    background: $white;
    border: 1px solid $secondary;
    border-radius: 0 0 3px 3px;
    width: 200px;
    opacity: 1;
    z-index: 2;

    overflow-y: hidden;
    max-height: 500px; /* approximate max height */

    transition: max-height .7s, opacity .7s linear;

    &.closed {
      max-height: 0px;
      opacity: 0;
    }

    li {
      cursor: pointer;
    }
  }
}
