.smls-ocean-icons {
    font-size: 10px;
}

.smls-ocean-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-ocean-icons.svg");
    background-repeat: no-repeat;
    background-size: 15em 42.4em;
}

.no-svg .smls-ocean-icons:before {
    background-image: url("../sprites/smls-ocean-icons.png");
}



  .smls-ocean-icons.ic-airplane-fa:before {
      background-position: 0em 0em;
      width: 5em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-calendar-check:before {
      background-position: 0em -1.6em;
      width: 2em;
      height: 2em;
  }




  .smls-ocean-icons.ic-calendar-solid:before {
      background-position: 0em -3.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-circle-check:before {
      background-position: 0em -5.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-clouds-fa:before {
      background-position: 0em -6.8em;
      width: 2.0300000000000002em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-easy-travel:before {
      background-position: 0em -8.4em;
      width: 15em;
      height: 15em;
  }




  .smls-ocean-icons.ic-gift:before {
      background-position: 0em -23.4em;
      width: 5.2em;
      height: 5.1em;
  }




  .smls-ocean-icons.ic-gift-solid:before {
      background-position: 0em -28.5em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-people:before {
      background-position: 0em -30.1em;
      width: 3.45em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-people-arrows:before {
      background-position: 0em -31.7em;
      width: 2.5em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-profile-solid:before {
      background-position: 0em -33.3em;
      width: 2em;
      height: 2em;
  }




  .smls-ocean-icons.ic-ticket:before {
      background-position: 0em -35.3em;
      width: 5.3em;
      height: 3.9em;
  }




  .smls-ocean-icons.ic-ticket-solid:before {
      background-position: 0em -39.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-ocean-icons.ic-ticket-solid-v2:before {
      background-position: 0em -40.8em;
      width: 1.6em;
      height: 1.6em;
  }





