// react-select
input#react-select-2-input {
    height: 36px;
}

.react-select__control,
.react-select__indicators {
    height: 50px;
}

// .select-label .react-select__input-container {
//     padding-top: 17px;
// }

.form-control-select > div:first-of-type {
    border-radius: 0px;
    box-shadow: none;
}

.react-select__value-container {
    font-size: 15px;
}

.form-select-custom > .label-form-control-reduced {
    position: absolute;
    z-index: 20;
    font-size: 10px;
    top: 3px;
    left: 30px;
}

// end react-select

.container-passengers {
    border: 1px solid $gray-500;
    .data {
        &__waiting {
            color: $gray-500;
        }
    }
    .passenger-titles {
        color: $gray-800 !important;
    }
    .edit-reserve {
        position: absolute;
        right: 30px;
        font-size: 15px;
        color: $primary !important;
        border-bottom: 1px solid $primary;
        cursor: pointer;
    }
    .edit-reserve:hover {
        opacity: 0.7;
    }
    .passenger-amount {
        .passenger-amount__img {
            width: 28px;
            height: 22px;
            margin-right: 7px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("../../../images/svg/icons/gray/ic-passengers.svg");
        }
    }

    .passenger-category__standard {
        color: $primary;
    }

    .passenger-category__club {
        color: $club;
    }
}
.container-back {
    div {
        cursor: pointer;
        .ic-arrow-left-long {
            width: 17px;
            height: 17px;
            margin-right: 7px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url("../../../images/svg/icons/orange/ic-arrow-left-long.svg");
        }
        span {
            color: $primary;
            font-size: 16px;
            margin-top: 2px;
        }
    }
}

.historyData-container {
    span {
        font-size: 14px;
    }
}

.form-select-container {
    div {
        .react-select__control {
            .react-select__value-container {
                .react-select__single-value {
                }
            }
        }
    }
}
.form-text-date-input-size-container {
    .input-size {
        height: 50px !important;
        cursor: auto;
    }
    .input-size-time {
        height: 47px;
    }
    .date-picker {
        .SingleDatePicker {
            div {
                .SingleDatePickerInput {
                    .DateInput {
                        .DateInput_input {
                            font-size: 15px;
                            font-family: $regularFontFamily;
                        }
                    }
                }
            }
        }
    }
}

.box-passenger-form {
    div {
        div {
            .form-passenger-input {
                .form-control-select {
                    .react-select__control {
                        .react-select__value-container {
                            overflow: initial !important;
                        }
                    }
                }
            }
        }
    }
}

/* .form-select-flightform-container{
    .form-control-select{
        .react-select__control{
            height: 50px !important;
            .react-select__value-container{
                height: 50px !important;
            }
        }
    }
} */

/* FlightForm Vuelos Cuenta Smiles  */

.gray-title {
    color: $gray-800 !important;
}

.account-flight {
    border-bottom: 1px solid $primary;
    color: $primary;
    cursor: pointer;
}
.account-flight:hover {
    opacity: 0.6;
}

.flights-available-container {
    justify-content: space-between;
    .flight-code-container {
        background-color: $primary;
        color: $white;
        padding: 5px 13px;
        font-weight: bold;
        margin: 5px 0;
        border-radius: 4px;
        span {
            color: $gray-800;
            strong {
                color: $white;
            }
        }
    }
}

.flight-date {
    input[type="radio"] {
        width: 20px;
        height: 20px;
        margin-left: 30px;
        accent-color: $primary;
    }
}
.form-group {
    .err-msg {
        color: $danger;
        font-size: 12px;
        line-height: 1.5 !important;
        margin-top: 6px;
    }
}

.btn-modify-flights-passengers {
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;
}

.btn-add-flights-passengers {
    background-color: $primary;
    color: $white;
    border: none;
}

.btn-add-flights-passengers:hover {
    background-color: darken($primary, 15%);
}

.btn-modify-flights-passengers:hover {
    background-color: $primary;
    color: $white;
    border: none;
}

.modal-container-for-flights-from-account {
    .modal-header {
        border-bottom: none !important;
    }
    .modal-footer {
        border-top: none !important;
    }
}

.btn-cancel-container {
    .btn-cancel {
        background-color: transparent;
        border: none;
        color: $primary;
    }
    .btn-cancel:hover {
        opacity: 0.7;
    }
}

.flight-passangers-selector {
    cursor: pointer;
    color: $primary;
    text-decoration: underline;
}

/* ********************************* */

@media only screen and (max-width: 575px) {
    .container-passengers {
        .passenger-titles {
            &__resume {
                margin-top: 40px;
            }
        }
    }
    .form-text-date-input-size-container {
        .input-container {
            input {
                height: 50px !important;
            }
        }
    }
    .flights-available-container {
        justify-content: center;
        .flight-code-container {
            width: 100%;
            margin-bottom: 15px;
            text-align: center;
        }
    }
}
