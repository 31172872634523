.modal-favorite-passenger {
  text-align: center;

  &::before {
    vertical-align: middle;
    content: " ";
    height: 100%;
    display: inline-block;
  }

  .modal-dialog {
    width: 92%;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
  }

  .modal-dialog {
    max-width: 604px;

    .modal-content {
      border-radius: 0;
      width: 100%;
      z-index: 1100;

      .modal-header {
        border-radius: 0;
        text-align: center;
        border-bottom: none;
        color: $white;
        font-size: 22px;
        padding-bottom: 0;
        padding-left: 8px;
        padding-right: 8px;
        background-color: $default;
        height: 76px;
        .modal-title,
        .titleModal {
          margin: 10px auto;
          line-height: 22px;
          color: $white;
        }
        .closeModal,
        .close {
          position: absolute;
          top: 25px;
          right: 20px;
          color: $white;
          font-size: 20px;
          cursor: pointer;
          opacity: 1;
        }
      }

      .modal-body {
        width: 420px;
        margin: auto;
        padding: 40px 0 0 0;
        .form-group {
          min-height: 80px;
          .form-group {
            min-height: auto;
          }
          .form-check-label {
            margin-right: 20px;
          }
          input.form-control,
          select.form-control {
            height: 50px;
            border-radius: 0 !important;
            &.error {
              border-color: $danger;
            }
            &[readonly] {
              background-color: $white;
              background-image: url(#{$svg-path}/icons/orange/ic-edit.svg);
              background-position: right center;
              background-position-x: 99%;
              background-repeat: no-repeat;
              color: $gray-400;
              cursor: pointer;
            }
            &[disabled] {
              background-color: $white;
            }
          }
          small {
            font-size: 14px;
            color: $danger;
            margin-top: 10px;
            display: block;
          }
        }
      }

      .modal-footer {
        width: 420px;
        margin: auto auto 60px auto;
        border: 0;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        button,
        a {
          width: 180px;
          height: 40px;
          border: 0;
          text-align: center;
          font-size: 16px;
          color: $white;
          &.confirm {
            background-color: $default;
          }
          &.cancel {
            background-color: $gray-600;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .modal-dialog {
      .modal-content {
        .modal-header {
          text-align: left;
          font-size: 18px;
          .modal-title,
          .titleModal {
            text-align: left;
            margin: 10px;
          }
        }
        .modal-body {
          width: 100%;
          padding: 40px 20px 0 20px;
          .form-group {
            input {
              font-size: 14px;
            }
            label {
              font-size: 14px;
            }
          }
        }
        .modal-footer {
          width: 100%;
          margin: 0;
          display: flex;
          flex-direction: column-reverse;
          button,
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

.modal-favorite-passenger-delete {
  text-align: center;

  &::before {
    vertical-align: middle;
    content: " ";
    height: 100%;
    display: inline-block;
  }

  .modal-dialog {
    width: 92%;
    vertical-align: middle;
    display: inline-block;
    text-align: left;

    .modal-content {
      border-radius: 0;
      width: 100%;
      z-index: 1100;

      .modal-header {
        border: none;
        .modal-title,
        .titleModal {
          font-size: 22px;
          color: $default;
          text-align: center;
          display: block;
          width: 100%;
          margin-top: 20px;
        }
        .closeModal,
        .close {
          color: $default;
          font-size: 22px;
          cursor: pointer;
        }
      }

      .modal-body {
        text-align: center;
        font-family: $ligthFontFamily;
        font-size: 15px;
        b {
          font-family: $boldFontFamily;
        }
      }

      .modal-footer {
        border: none;
        margin: auto auto 40px auto;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        button,
        a {
          width: 160px;
          height: 40px;
          border: 0;
          text-align: center;
          font-size: 16px;
          color: $white;
          &.confirm {
            background-color: $default;
          }
          &.cancel {
            background-color: $gray-600;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .modal-dialog {
      .modal-content {
        .modal-footer {
          display: flex;
          flex-direction: column-reverse;
          button,
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
}
