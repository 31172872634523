.my-account {
  &__extract {
    background-color: $white;
    @include bms-base-box;
    position: relative;
    margin: auto;
    text-align: left;
    padding: 15pt 9pt;

    .box-header {
      &.extract {
        margin-bottom: 50px;
      }
    }

    &__action {
      border-bottom: 1px solid $gray-300;
      padding-top: 15pt;
      margin-bottom: 20pt;

      &__large {
        padding-bottom: 25pt;
      }

      &__small {
        padding-bottom: 5pt;
      }
    }

    &_vouchers {
      .box-header {
        margin-bottom: 20pt;
      }

      h2 {
        margin-bottom: 2pt;
      }

      .frm-default {
        margin-bottom: 25pt;
      }
    }

    .value {
      &_rescue {
        color: $red;
      }
      &_refund {
        color: $green;
      }
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .my-account {
    &__extract {
      border-bottom: 2px solid $default;

      &_vouchers {
        .frm-default {
          margin-top: 20pt;
        }
      }
    }
  }
}

// Large devices (Desktops, 768px and up)
@media (min-width: 768px) {
  .my-account {
    &__extract {
      padding: 20pt 20pt;

      &__action {
        border: 0;
        padding: 0 0 20pt;
      }

      &_vouchers {
        .box-header {
          margin-bottom: 30pt;
        }
        .navigation {
          margin-top: 30pt;
        }
      }
    }
  }
}
