.container {
  .my-account {
    &__club {
      background-color: $club;
      color: $white;
      text-align: center;
      font-size: 16px;
      padding: 10px;
      height: auto;
      width: 561px;
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }
    &__requests {
      @include bms-base-box;
      position: relative;
      margin: 0;
      text-align: left;
      padding: 15pt 9pt;
    }
  }
  .card-requests {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .requests-header {
      padding-left: 20px !important;
      padding-right: 20px !important;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      .ic-filelist-circle {
        background-color: $gray-800;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        min-width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        .ic-filelist-crop {
          height: 30px;
          width: 30px;
          min-width: 30px;
          .ic-filelist {
            font-size: 18px;
            height: 40px !important;
            width: 40px !important;
          }
        }
      }
      h2 {
        color: #5b5b5b;
        text-transform: uppercase;
        display: inline-block;
        margin: 10px 0 10px 20px;
        font-size: 20px !important;
        font-family: $regularFontFamily;
        font-weight: 700 !important;
      }
      p {
        color: $gray-700;
        font-family: $boldFontFamily;
        width: 87vw;
        font-size: 12px;
        font-weight: 600 !important;
        display: inline-block;
        margin-left: -40px;
        margin-top: 10px;
      }
    }
    .btn-volver {
      display: none !important;
    }
    hr {
      color: $gray-300;
      height: 1px;
      width: 97vw;
      margin-bottom: 40px;
    }
    table {
      margin-left: 10px;
      thead {
        font-weight: 800;
        background-color: #ececec;
      }
      tbody {
        font-weight: 400;
        font-size: 14px;
      }
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      th {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 800;
        font-family: $boldFontFamily;
        max-width: 153px;
      }
      td {
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle !important;
        &:first-child {
          text-align: center;
        }
      }
      th,
      td {
        border-right: 8px solid #ffffff;
        padding: 0.15rem 0.75rem;
        min-width: 155px !important;
        max-width: 185px !important;
        word-break: break-all;
        &:last-child {
          border-right-width: 0;
        }
      }
    }
    .change-page-requests {
      display: block;
      text-align: center;
      .btn {
        margin-left: auto;
        margin-right: auto;
        width: 40px;
      }
      .change {
        color: #333;
        background-color: #fff;
        border-color: #33333333;
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    .my-account {
      &__requests {
        width: 100% !important;
        max-width: 1150px;
        padding: 40px 50px;
        height: auto;
      }
    }
    .card-requests {
      padding-left: 30px !important;
      padding-right: 30px !important;

      hr {
        margin-top: 0;
      }

      .requests-header {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        .col-lg-12 {
          display: flex;
          flex-direction: column;
          h2 {
            color: #5b5b5b;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 0;
            margin-bottom: 2px;
          }
          p {
            color: $gray-700;
            font-family: $regularFontFamily;
            width: 82vw;
            font-size: 11pt;
            font-weight: 600 !important;
            max-width: 42rem;
            margin-left: 20px !important;
            margin-top: 0px;
          }
        }
      }
      .btn-volver {
        display: inline-block !important;
        position: absolute;
        min-width: 175px;
        height: 32px;
        border-radius: 3px;
        font-family: $regularFontFamily;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        right: 26px;
      }
      table {
        margin-left: 0px;
        th,
        td {
          min-width: 175px !important;
          max-width: 185px !important;
          padding-bottom: 5pt;
          padding-top: 5pt;
          font-size: 9.5pt;
          word-break: break-all;
        }
        td {
          &:first-child {
            text-align: left;
          }
        }
        th {
          &:last-child {
            padding-right: 0 !important;
            max-width: 215px !important;
            width: 212px !important;
          }
        }
      }
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
    .container{
        .my-account{
            &__club { 
                width: calc(100% - 8px);
                margin: 4px;
                margin-bottom: 10px;
                padding: 20px;
            }
        }
    }
}
