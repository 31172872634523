label {
    &:disabled {
        cursor: not-allowed;
    }
}
.search-flight {
    display: flex;
    .options-flight {
        padding: 2vw 3vw;

        .head-tabs {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding-bottom: 4vw;
            h2 {
                @include bms-font-size(5vw,31.5px);
            }
        }

        .header-flight {
            background: $primary;
            height: 50px;
            border-radius: $border-radius;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            .flight-info {
                @include bms-font-size(3vw,20px);
                color: $white;
                text-transform: uppercase;
                position: relative;
                padding-left: 40px;
                text-align: left;
                font-weight: 700;
                &:before {
                    content: '';
                    background-repeat: no-repeat;
                    background-size: 100%;
                    height: 22px;
                    width: 22px;
                    display: block;
                    position: absolute;
                    left: 10px;
                }
                &.back,
                &.going {
                  &:before {
                    top: -5px;
                    background-image: url(#{$svg-path}/icons/white/ic-departure.svg);
                  }
                }
            }
            .line-separator {
                width: 1px;
                height: 35px;
                background: $primary-light-100;
                display: block;
                position: relative;
                margin: 0 10px;
            }
            .flight-city {
                @include bms-font-size(3.5vw,20px,true);
                color: $white;
                text-align: right;
                &:not(small) {
                  font-weight: 700;
                }
                &:last-child {
                    text-align: left;
                }
                small {
                    display: none;
                    font-size: 14px;
                }
            }
            .arrow {
                width: 10%;
                &:before {
                    content: '';
                    background-image: url(#{$svg-path}/icons/white/ic-right-arrow-paging.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    display: block;
                    width: 100%;
                    height: 13px;
                }
            }
        }

        .carousel-search-flights {
            background: $gray-200;
            padding: 2vw;
            .calendar-day-options {
                padding: 0 3vw;
                margin-top: 15px;
                .calendar-day {
                    cursor: pointer;
                    background: $white;
                    border: 2px solid $white;
                    box-sizing: content-box;
                    margin: 0 5px;
                    position: relative;
                    font-weight: bold;
                    color: #656565;

                    .calendar-day-boxsize-full {
                      min-height: 88px;
                    }

                    &:focus {
                        outline: none;
                    }
                    &.selected {
                        border: 2px solid $primary;
                    }
                    &:hover {
                        border: 2px solid $primary;
                    }
                    &.disabled, &.block-click {
                        color: $gray-500;
                        cursor: initial;
                        pointer-events: none;
                        &:hover {
                            border-color: $white;
                        }
                    }
                    .calendar-day-name {
                        @include bms-font-size(3vw,13px);
                        display: block;
                        font-weight: 700;
                    }
                    .calendar-day-mounth-group {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        margin-bottom: 6px;
                        .calendar-day-date {
                            @include bms-font-size(5vw,16px);
                            line-height: 13px;
                            font-weight: 700;
                        }
                        .calendar-month-name {
                            @include bms-font-size(3vw,16px, true);
                            font-weight: 700;
                        }
                    }
                    .calendar-miles-group {
                      line-height: 1;

                      span.icon-not-available {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-block.svg);
                        display: inline-block;
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                        background-repeat: no-repeat;
                      }

                      span.icon-search {
                        content: '';
                        background-image: url(#{$svg-path}/icons/gray/ic-search.svg);
                        display: inline-block;
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                      }

                      .calendar-miles-label {
                        font-size: 67%;
                        margin-top: 2px;
                      }
                    }
                    .calendar-day-group-info {
                        margin: 10px 0;
                        display: none;
                        .calendar-day-icon {
                            &:before {
                                content: '';
                                display: block;
                                height: 22px;
                                width: 22px;
                                background-size: 100%;
                                margin: 0 auto;
                            }
                            &.icon-search {
                                &:before {
                                    background-image: url(#{$svg-path}/icons/gray/ic-search.svg);
                                }
                            }
                        }
                    }
                    .calendar-search-icon {
                      margin-bottom: 8px;
                      img {
                        opacity: 0.6;
                        margin: auto;
                      }
                    }
                }
                .slick-prev,
                .slick-next {
                    position: absolute;
                    display: block;
                    line-height: 0px;
                    font-size: 0px;
                    cursor: pointer;
                    background: transparent;
                    color: transparent;
                    top: 50%;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    padding: 0;
                    border: none;
                    outline: none;
                    &:before {
                        background-repeat: no-repeat;
                        display: block;
                        width: 16px;
                        height: 16px;
                    }
                }
                .slick-prev {
                    left: -5px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/orange/ic-arrow-left.svg);

                    }
                }
                .slick-prev.slick-disabled {
                  left: -5px;
                  &:before {
                    width: 16px;
                    height: 24px;
                    background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg);
                    background-repeat: no-repeat;
                  }
                }
                .slick-next {
                    right: -5px;
                    &:before {
                        content: '';
                        background-image: url(#{$svg-path}/icons/orange/ic-arrow-right.svg);
                    }
                }
                .slick-next.slick-disabled {
                  right: -5px;
                  &:before {
                    content: '';
                    width: 16px;
                    height: 24px;
                    background-image: url(#{$svg-path}/icons/gray/ic-arrow-right.svg);
                    background-repeat: no-repeat;
                  }
                }
            }
            .search-flights-caption {
                @include bms-font-size(3.5vw, 14px);
                margin: 10px 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 4vw;
                &:before {
                    content: '';
                    background-image: url(#{$svg-path}/icons/gray/ic-search.svg);
                    height: 15px;
                    width: 15px;
                    background-size: 100%;
                    margin-right: 5px;
                    position: relative;
                    bottom: 2px;
                }
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .search-flight {
        flex-direction: row-reverse;
        .options-flight {
            padding: 0 2vw 2vw 1vw;
            .head-tabs {
                padding-bottom: 40px;
            }
            .header-flight {
                height: 70px;
                padding-left: 15px;
                .flight-city {
                    width: 28%;
                    small {
                        display: block;
                    }
                }
                .line-separator {
                    height: 50px;
                    margin: 0;
                    left: 20px;
                }
                .arrow {
                    width: 190px;
                    &:before {
                      margin-left: 10%;
                      width: 80%;
                      height: 29px;
                      background-image: url(#{$svg-path}/icons/white/ic-arrow-right.svg);
                      background-position: right center;
                    }
                }
            }
            .carousel-search-flights {
                padding: 1vw;
                .calendar-day-options {
                    padding: 0 2vw;
                    .calendar-day {
                        margin: 0 8px;
                        padding: 8px 8px 3px;
                        .calendar-day-mounth-group {
                            flex-direction: row;
                            .calendar-day-date {
                                padding-right: 3px;
                            }
                        }
                    }
                    .slick-prev,
                    .slick-next {
                        &:before {
                            background-size: 100%;
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
                .search-flights-caption {
                    padding-left: 2.5vw;
                }
            }
        }
    }
}

// medium devices (tablet, 768px and up)
@media (min-width: 768px) {
    .search-flight {
        .options-flight {
            .header-flight {
                .flight-info {
                  padding-left: 50px;
                  &:before {
                      width: 32px;
                      height: 32px;
                      -webkit-transform: translate(0,10%);
                      -moz-transform: translate(0,10%);
                      transform: translate(0,10%);
                  }
                  &.going {
                    &:before {
                      top: 0;
                      background-image: url(#{$svg-path}/icons/white/ic-flight-going.svg);
                    }
                  }
                  &.back {
                    &:before {
                      top: 0;
                      background-image: url(#{$svg-path}/icons/white/ic-flight-back.svg);
                    }
                  }
                }
            }
        }
    }
}
