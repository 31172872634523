.frm-default.search-flight__form .range-short-control {
	position: relative;
	display: inline-block;

	input {
		@include bms-font-size(.8rem, 14px);

		height: 62px;
		width: 70px;
		padding-top: 2px;
		padding-right: 15px;
		border-radius: 4px;
		display: inline-block;
		background-position: 8px center;
		background-repeat: no-repeat;
		background-size: 30px;
		font-weight: bold;
		font-family: $boldFontFamily;
		text-align: right;
		color: $gray-700;
	}

	&.ic-adult input {
		background-image: url('/images/svg/icons/gray/ic-adult.svg');
		background-size: 62% auto;
		background-position: -2px center;
	}

	&.ic-child input {
		background-image: url('/images/svg/icons/gray/ic-child.svg');
	}

	&.ic-baby input {
		background-image: url('/images/svg/icons/gray/ic-baby.svg');
		background-size: 37% auto;
		background-position: 7px center;
	}

	button.control {
		position: absolute;
		width: 100%;
		height: 50%;
		left: 0;
		background: none;
		border: none;
		background: {
			size: 19px 19px;
			repeat: no-repeat;
		};
		transition: border 0.2s linear;

		&:focus {
			box-shadow: none;
		}

		&.increase, &.decrease {
			background-position-x: 78%;
			background-size: 15px auto;
		}

		&.increase {
			top: 0;
			background-image: url('/images/svg/icons/gray/ic-arrow-up.svg');
			background-position-y: 30%;
		}

		&.decrease {
			bottom: 0;
			background-image: url('/images/svg/icons/gray/ic-arrow-down.svg');
			background-position-y: 75%;
		}
	}
}
