.SmlsLoadingNewModal {
    padding: 25px;
    .SmlsLoadingNewModal__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            color: $primary;
            margin-bottom: 4px;
        }
    }
}
