.modal-passenger-current {
    text-align: center;
    

    &::before {
        vertical-align: middle;
        content: " ";
        height: 100%;
        display: inline-block;
    }

    .modal-dialog {
        width: 92%;
        vertical-align: middle;
        display: inline-block;
        text-align: left;
    }

    .modal-dialog {
       
        .modal-content {
            width: 100%;
            z-index: 1100;

            .modal-header {
                text-align: center;
                border-bottom: none;
                padding-top: 2rem;
                color: $default;
                font-size: 1.4rem;
                padding-bottom: 0;
                padding-left: 8px;
                padding-right: 8px;

                .closeModal {

                    button {
                        opacity: 1 !important;
                        height: 24px;
                        padding: 0;
                        width: 24px;
                        font-size: 15px;
                        border-radius: 100%;
                        margin-right: 10px;
                        margin-top: 10px;
                        color: $default;
                        display: block;
                        font-weight: normal;
                        position: absolute;
                        right: 0;
                        top: 0;
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                }

                .titleModal {
                    width: 90%;
                    height: auto;
                    display: inline-block;
                    background: transparent;
                    
                    span {
                        font-size: 24px;
                        font-family: $regularFontFamily;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        margin-bottom: 10px;
                        float: left;
                        text-align: left;
                        line-height: 24px;
                    }

                    p {
                        font-size: 12px;
                        float: left;
                        color: $gray-500;
                        text-align: left;
                    }
                }

                .categoryModal {
                    width: 50%;
                    height: 100px;

                    span {
                        width: 100%;
                        height: 100%;
                        border: 0;
                        display: block;

                        &.diamond {
                            background: url(../images/svg/categories/ic-card-diamond.svg) no-repeat center;
                            background-size: contain;
                            background-position: top right;
                        }

                        &.gold {
                            background: url(../images/svg/categories/ic-card-gold.svg) no-repeat center;
                            background-size: contain;
                            background-position: top right;
                        }

                        &.silver {
                            background: url(../images/svg/categories/ic-card-silver.svg) no-repeat center;
                            background-size: contain;
                            background-position: top right;
                        }

                        &.smiles {
                            background: url(../images/svg/categories/ic-card-smiles.svg) no-repeat center;
                            background-size: contain;
                            background-position: top right;
                        }
                    }
                }

            }

            .modal-body {
                position: relative;
                overflow-y: auto;
                max-height: 400px;
                padding: 8px;

                .alert {
                    font-family: $regularFontFamily;
                    font-size: 13px;
                }

                label {
                    font-size: 14px;
                    line-height: 16px;
                    font-family: $secondaryFontFamily;
                }

                .content {
                    
                    .flight-list {

                        .ic-airplane-two {
                            &::before {
                                margin: 0 5px 0 0;
                            }
                        }
                        
                        &__item {
                            padding: 10px 0 30px 0;
                            border-top: none;
                            border-bottom: 1px solid $gray-300;
                            position: relative;
                            margin-bottom: 5px;

                            .input-checkbox-wrapper {
        
                                input {
                                    left: -50px;
                                    top: 25%;
                                    position: absolute;
                                }
                            }
        
                            .flight-details {
                                color: $gray-500;
                                font-family: $regularFontFamily;
                                display: flex;
                                justify-content: space-between;
                                
                                &__company {
                                    padding: 3px 3px;
                                    height: 40px;
                                    width: 40px;
                                    border: 1px solid $gray-500;
                                    border-radius: 5px;
        
                                    .logo-airlines {
                                        width: 100%;
                                        height: 100%;
                                        padding: 0;
                                        margin: 0;
                                        background-size: 100% !important;
                                    }
                                }
        
                                &__travel {
                                    display: flex;
                                    width: 70%;
                                    flex-direction: row;
                                    flex-wrap: wrap;
        
                                    .travel__details-wrapper {
                                        display: flex;
                                        width: 100%;    
        
                                        .travel {
        
                                            &__airport {
                                                color: $black;
                                                font-size: 10px;
                                                line-height: 14px;
                                                font-weight: 100;
                                                font-family: $boldFontFamily;
                                            }
        
                                            &__location {
                                                font-size: 10px;
                                                line-height: 14px;
                                                font-weight: 100;
                                                color: $gray-800;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                width: 95%;
                                            }
        
                                            &__date-info {
                                                font-size: 10px;
                                                color: $gray-600;
                                            }
        
                                            &__origin {
                                                align-items: flex-end;
                                                margin-right: 0px;
                                                width: 35%;
                                                display: flex;
                                                flex-flow: column wrap;
                                                text-align: right;
                                                margin-left: 5px;
                                            }
        
                                            &__stops {
                                                text-transform: uppercase;
                                                font-size: 8px;
                                                line-height: 14px;
                                                color: $gray-700;
                                                font-weight: bold;
                                            }
        
                                            &__duration {
                                                font-size: 8px;
                                                line-height: 14px;
                                                color: $gray-700;
                                                font-weight: bold;
                                            }
        
                                            &__info {
                                                display: inherit;
                                                flex-direction: column;
                                                padding: 0 .9vw;
                                                text-align: center;
                                                margin: 0;

                                                .ic-cabine {
                                                    position: absolute;
                                                    top: 75%;
                                                    left: 30%;

                                                    &::before {
                                                        margin: 0 5px 3px 0;
                                                    }
                                                }
        
                                                .seta {
                                                    width: 100%;
                                                    margin: 0;
                            
                                                    &::after {
                                                        content: "";
                                                        width: 100%;
                                                        height: 14px;
                                                        display: block;
                                                        background-image: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
                                                        background-position: center right;
                                                        background-repeat: no-repeat;
                                                        margin-bottom: 1px;
                                                    }
                                                }
                                            }
        
                                            &__arrival {
                                                text-align: left;
                                                margin-left: 0px;
                                                width: 35%;
                                                display: flex;
                                                flex-flow: column wrap;
                                            }
                                        }
        
                                    }
                                }
        
                                &__about {
                                    color: $gray-700;
                                    font-size: 11px;
                                    text-align: left;
                                    margin: 0;
                                    padding: 0;
                                    
                                    li {
                                        line-height: normal;
                                        text-align: right;
                                        white-space: nowrap;

                                        &:first-child {
                                            font-size: 9px;
                                            text-transform: uppercase;
                                            
                                        }

                                        &:last-child {
                                            font-family: $boldFontFamily;
                                            font-size: 12px;
                                        }

                                        span {
                                            text-decoration: line-through;
                                        }
        
                                    }
                                }
                            }
                        }
                    }

                    button {
                        float: right;
                        margin: 10px auto 0;
                        display: block;
                        font-family: $regularFontFamily;
                        font-size: 14px;
                        border-radius: 3px;
                        width: 115px;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) { 

    .modal-passenger-current {
        text-align: center;
    
        &::before {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
    
        .modal-dialog {
            width: 682px !important;
            vertical-align: middle;
            display: inline-block;
            text-align: left;
            max-width: none;

            .modal-content {

                

                .modal-header {
                    padding: 1rem;
                    padding-bottom: 15px;

                    .titleModal {
                        width: calc(100% - 230px);
                        
                        span {
                            font-size: 28px;
                            letter-spacing: 0.5px;
                            margin-bottom: 10px;
                        }
    
                        p {
                            font-size: 14px;
                            float: left;
                        }
                    }

                    .categoryModal {
                        width: 150px;
                        height: 100px;
                    }
                }
                
                .modal-body {

                    padding: 15px;

                    .content {
                        width: 100%;
            
                        .flight-list {
                                
                            &__item {
                                padding: 10px 0;
                                border-top: 1px solid $gray-300;
                                
                                .flight-details {
            
                                    &__company {
                                        width: 112px;
                                        height: 72px !important;
                                    }

                                    &__company {
                                        padding: 3px 10px;
                                        width: 112px;
                                        height: 72px !important;
            
                                        .logo-airlines {
                                            background-size: 100% !important;
                                        }
                                    }
            
                                    &__travel {
                                        width: 400px;
            
                                        .mobile-travel {
                                            &__stops-and-duration {
                                                display: none;
                                            }
                                        }
            
                                        .travel__details-wrapper {
            
                                            .travel {
            
                                                &__info {
                                                    margin: 10px 0 0 0;

                                                    .seta {
                                                        margin: 0 5px;
                                                        width: 80px;
                                                    }

                                                    .ic-cabine {
                                                        left: 43%;
                                                    }
                                                }
            
                                                &__origin {
                                                    margin-right: 12px;
                                                    width: 40%;
                                                    margin-left: 0;
                                                }
            
                                                &__arrival {
                                                    margin-left: 12px;
                                                    width: 40%;
                                                }
            
                                                &__airport {
                                                    font-size: 14px;
                                                    line-height: 16px;
                                                }
            
                                                &__location {
                                                    font-size: 14px;
                                                    line-height: 18px;
                                                }
            
                                                &__date-info {
                                                    font-size: 12px;
                                                }
            
                                                &__stops {
                                                    font-size: 10px;
                                                }
            
                                                &__duration {
                                                    font-size: 10px;
                                                }
                                            }
                                        }
                                    }
            
                                    &__about {
                                        margin: 5px 0 0 0;
                                        max-width: 55%;
                                        font-size: 14px;
            
                                        li {
                                            line-height: 20px;
                                            text-align: right;
            
                                            &:first-child {
                                                font-size: 10px;
                                            }
            
                                            &:last-child {
                                                font-size: 18px;
                                                font-family: $boldFontFamily;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}