.home {
	.offer-banner {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;

		.offer-headline {
			padding: 10px;
			width: 100%;
			border-top-right-radius: inherit;
			border-top-left-radius: inherit;
			background: $default;

			h2 {
				color: $white;
				margin-bottom: 0px;
				font-size: 25px;
			}
		}

		&__content {
			padding: 20px;
			border-right: 2px solid $default;
			border-left: 2px solid $default;
			border-bottom: 2px solid $default;
			border-bottom-left-radius: inherit;
			border-bottom-right-radius: inherit;

			.offer-card {
				min-height: 340px;
				padding: 0px !important;
				margin: 0px !important;
		
				&, p,h2 {
					color: $white;
				}
		
				.content {
					text-align: center;
					background-repeat: no-repeat;
					background-position: center;
					border-radius: 10px;
					margin: 0px !important;
				}
				.btn-anexo {
					width: 59px;
					height: 59px;
					background-color: $primary;
					border-radius: 50px;
					position: relative;
		
					p {
						padding-top: 12px;
					margin-left: -2px;
					}
		
					.smls-white-icons {
						font-size: 22px;
					}
		
					@media (max-width: $site-width-mobile) {
						width: 59px;
						height: 59px;
						background-color: $primary;
						border-radius: 50px;
						position: relative;
					}
				}
				.border-line {
						color: $primary;
						@media (max-width: $site-width-mobile) {
							display: block;
		
							.line {
									max-height: 20px;
									border-color: $primary;
							 }
							 .smls-white-icons {
									font-size: 20px;
									position: absolute;
							}
							.ic-airplane {
									color: $primary;
							}
		
							.border-line-content {
								float: right;
								text-align: left;
		
								.city {
									font-size: 13px;
								}
							}
						}
				}
				.card-content-first-ofertas {
					border-left: 2px solid $primary;
		
					.button-card-ofertas {
						background-color: $primary;
		
						p {
							text-align: center;
							width: 100%;
							line-height: 26px;
						}
					}
				}
				.box-millas {
						max-width: 120px;
						max-height: 120px;
						width: 120px;
						height: 120px;
						background-color: $primary;
						border-radius: 60px;
		
						p {
							text-align: center;
						}
		
						@media (max-width: $site-width-mobile) {
							position: absolute;
							width: 120px;
							height: 120px;
							right: 20px;
							bottom: 20px;
						}
				}
		
				.btn {
					min-width: unset;
		
					@media (min-width: $site-width-mobile) {
						width: 100%;
					}
				}
		
			}
		}

		@media screen and (max-width: $site-width-mobile) {
			margin-left: 15px;
			margin-right: 15px;
			padding: 0px;
			margin-bottom: 0px;

			h2 {
				color: $white;
				margin-bottom: 0px;
				font-weight: 700;
			}

			&__content {
				padding: 0px;
				border: 0px;
				margin-bottom: 40px;
				min-height: 620px;

				.content.px-3 {
					padding: 0px !important;
					
					img {
						border-bottom-left-radius: 16px;
						border-bottom-right-radius: 16px;
					}
				}
			}
		}
	}
}
