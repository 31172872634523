.tdDivBonusMsg {
  display: table-cell;
  min-height: 66px;
  vertical-align: middle;
}

.pPurple {
  color: $club;
}

.color-smiles-orange {
  color: $default;
}

.color-smiles {
  color: $primary;
}

.transferBonusPopupText {
  font-weight: 700;
  font-family: $boldFontFamily;
  font-size: 12px;
  margin-top: -6px;
}

.centered-modal {
  width: 100%;
  color: $gray-700 !important;
  text-align: center;
  font-weight: 700;
  font-family: $boldFontFamily;
  font-size: 20px;
  letter-spacing: 0;
}

.my-account {
  &__transfer-miles {
    padding: 15pt 23pt;
    position: absolute;
    @media (max-width: 991px) and ( min-width: 769px) {
      &.box.points {
        position: relative;
        height: 400px;
      }
    }

    .transfer-miles-first-row {
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      padding-left: 12px;
      border: 1px solid $gray-400;
      position: relative;
      margin-bottom: 20px;
      justify-content: space-between;
      align-items: center;

      .input-border {
        border: 1px solid $gray-400;
        display: block;
        overflow: hidden;
        background-color: $white;
        width: 134px;
        padding: 10px;
        height: 50px;
        input {
          border: none;
          color: $gray-700;
        }
        ::placeholder {
          color: $gray-400;
        }
      }

      & h5 {
        height: 50px;
        line-height: 50px;
      }

      .final-block {
        background: $gray-300;
        width: 30%;
        max-width: 30%;
        height: 100px;
        align-self: flex-end;
        padding: 10px;
        display: table;

        p {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
      }
    }

    .gift-miles-first-row {
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      border: 1px solid $gray-400;
      position: relative;
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 10px 10px 0px 0px;
      background-color: $gray-200;
      max-width: 100%;

      .gift-inputs {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-evenly;
        width: 100%;
        gap: 1rem;
        .dni-input-gift-wrapper{
          display: flex;
          flex-direction: column;
          width: 55%;
        }
      }

      .input-border {
        border: 1px solid $gray-400;
        display: block;
        overflow: hidden;
        background-color: $white;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 0px 1px 1px $gray-500;
        width: 100%;
        input {
          border: none;
          color: $gray-700;
          width: 100%;
          ::placeholder {
            color: $gray-200;
          }
        }
        @media (max-width: 428px) {
          #nroSmiles::-webkit-input-placeholder {
            font-size: 0.7rem;
          }
        }
      }

      & h5 {
        height: 50px;
        line-height: 50px;
      }
    }

    .total_highlit {
      color: $default;
      font-size: 16px;
      min-width: 100%;
    }

    &.success {
      background-color: transparent;
      position: relative;
    }

    h5 {
      font-size: 14px;
      font-weight: bold;
      width: auto;
      margin-right: 10px;
    }

    .rule {
      h4 {
        text-align: left;
        width: 100%;
        border: none;
        margin-bottom: 20px;
        span {
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 13px;
            background-image: url(../../images/svg/icons/purple/ic-smiles-connector.svg);
            background-size: 20px;
            background-position-y: -7px;
            position: relative;
            top: 16%;
          }
          filter: grayscale(100%);
          color: $gray-700;
        }
      }

      .miles-amount, .points {
        min-width: 270px;
        padding: 27px 10px;
        padding-left: 20px;

        p {
          font-size: 12px;
          margin-bottom: 0;
        }

        &::before {
          border-left: 40px solid $white;
          right: -40px;
        }

        &::after {
          border-left: 40px solid $white;
          right: -39px;
        }

        .input-border {
          #miles {
            width: 50%;
            background-color: transparent;

            &::placeholder {
              color: $gray-400;
            }
          }
          #dni {
            width: 100%;
            border: none;
          }
          #points {
            width: 65%;
            border: none;
            text-align: right;
            background-color: $white;
          }
        }
      }

      .club, .bonus, .diamond, .points {
        display: flex;
        flex-direction: column;
        padding-left: 90px;
        padding-right: 40px;
        padding-top: 50px;
        font-size: 12px;
        text-align: left;
        font-family: $ligthFontFamily;
        line-height: 14px;

        h5 {
          color: white;
          font-size: 14px;
          margin-bottom: 5px;
          margin-right: 5px !important;
        }

        .level {
          margin-top: auto;
        }

        .bonusRow {
          display: grid;
          grid-template-columns: 50% 1fr;

          h5 {
            margin-bottom: 0px;
          }
        }

        &::after {
          right: -25px;
          height: 140px;
          border-top: 75px solid transparent;
          border-bottom: 75px solid transparent;
          z-index: 2;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          right: -25px;
          height: 140px;
          border-top: 75px solid white;
          border-bottom: 75px solid white;
          border-left: 42px solid white;
          z-index: 0;
        }
      }

      .points {
        h5 {
          color: $gray-700;
        }
        padding-top: 27px;
        font-size: 1rem;
        color: $gray-900;
        &::before {
          top: -1px;
        }
      }

      .diamond {
        &::after{
            border-left: 42px solid $diamond;
        }
      }

      .club {
        &::after{
          border-left: 42px solid $club;
        }
      }

      .miles-info {
        display: flex;

        .miles-total {
          display: flex;
          flex-direction: column;
          padding-left: 40px;
          padding-right: 0px;
          padding-top: 50px;
          padding-bottom: 20px;
          font-size: 12px;
          text-align: center;
          font-family: "NunitoLight",Arial,sans-serif;
          line-height: 14px;

          h5 {
            color: grey;
            font-size: 14px;
            margin-bottom: 5px;
            margin-right: 5px !important;
          }
        }
      }

      .bonus {
        .label-will-receive {
          margin-top: 3px;
          margin-bottom: 3px
        }
        &::after{
          border-left: 42px solid $default;
        }
      }

      .points {
        &::after{
          border-left: 42px solid $gray-200;
        }
      }

      .value-total {
        padding: 50px 0px 0 40px;
        border: 1px solid $default;
        border-left: transparent;

        &::before{
          border-left: 40px solid $default;
        }

        &::after{
          border-left: 40px solid $white;
        }
      }

      .base-wrapper {
        width: 100%;
        display: flex;
        margin-top: 39px;
        justify-content: space-between;

        .limit-bar {
          bottom: 12px;
          left: 0;

          .progress-level {
            width: 300px;
            height: 17px;
            border: 1px solid $gray-500;
            border-radius: 9px;
            overflow: hidden;

            .level {
              background-color: $gray-500;
              overflow: hidden;
              height: 100%;
              border-radius: 0px 8px 8px 0px;
            }
          }

          p {
            margin-bottom: 2px;
            display: inline-block;
          }
        }
      }
    }

    .banner-purple {
      margin-bottom: 10px;

      .btn-close {
        z-index: 9;
      }
    }

    .resume-wrapper {
      border: 1px solid $default;
      margin: auto;
      width: 1080px;
      display: flex;
      justify-content: space-between;
      padding: 24px;
      background-color: white;

      .first-name {
        color: $gray-800 !important;
      }

      h4 {
        margin: 0px 25px 13px 0px;
        width: 100%;
      }

      h6 {
        color: $gray-700;
        .color-club {
          color: $club !important;
        }
      }

      .row {
        width: 100%;
      }

      .resume {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        flex-shrink: 0;

        .color-smiles {
          color: $default !important;
        }

        .color-diamond {
          color: $diamond !important;
        }
      }
    }
  }
}

// ***********
// success
// ***********

.success-transfer {
  .checkout-success-header {
    max-width: 100%;
    margin-bottom: -70px;
    padding-top: 20px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
      padding-bottom: 0px;
    }

    img {
      width: 100%;
      position: absolute;
      height: 350px;
      object-fit: contain;
    }
  }

  .success-transfer-container {
    border: 1px solid $default;
    padding: 25px;
    margin-top: 82px;
    margin-bottom: 100px;
    width: 1080px;

    .first-row {
      h4 {
        margin: 0px !important;
      }

      .gift-miles {
        flex: content
      }

      .bonus-gift {
        text-align: center;
      }

      .col-3:not(:first-child) {
        padding-left: 25px
      }

      .box-transfer-data {
        height: 90px;
        border: 1px solid $gray-400;
        padding: 5px 10px 5px 15px;
        margin-bottom: 20px;

        .first-row {
          border-bottom: 1px solid $gray-400;
          .valor {
            text-align: right
          }
        }
      }
    }

    .button-row {
      .buttons {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        a {
          margin: 10px;
        }

        a button {
          width: 130px;
          height: auto;
        }
      }
    }

    .default-color-border-right {
        border-right: 1px solid $default;
        height: 90px;
    }

    .default-color-border-right-expand {
      border-right: 1px solid $default;
      height: 140px;
    }
  }
}

.miles-transfer-success {
  .club-smiles {
    &__success-transfer {
      .checkout-success-header {
        margin-bottom: 0 ;
      }

      .resume-wrapper {
        border: 1px solid $gray-500;
        margin: 57px auto 0;
        width: 1080px;
        display: flex;
        justify-content: space-between;
        padding: 14px;
        text-align: left;
        height: 226px;

        .col-md-5 {
          color: $default;
          padding: 30px;
          height: 100%;

          h4 {
              margin: 15px 25px 2px 0px;
              font-size: 26px;
              line-height: 27px;
          }

          p {
            padding-right: 60px;
            font-size: 20px;
            line-height: 27px;
          }
        }

        .resume {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          width: 100%;

          .row {
            height: 50%;
          }

          .col-md-4 {
            justify-content: center;
            display: flex;
            flex-direction: column;

            p {
              margin-bottom: 0;
              color: $club !important;
            }

            span {
              color: $club;
            }
          }

          h6 {
            color: $gray-700;
            margin-bottom: 1px;
          }
        }
      }
    }
  }

  .club-smiles-box-success {
    .wrapper {
      .resumen {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 14px;
        line-height: 19px;

        .resummen-table {
          font-size: 14px;
          margin: 20px 0px;
          span.value {
            display: block;
          }
        }

        .buttons {
          margin-top: 20px;
          button {
            margin: 0 23px 0 0;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    &.footer {
      margin-top: 70px;
      font-size: 20px;
      line-height: 27px;
      padding: 10px 40px;
      font-weight: bold;
      border: none;
    }
  }
}

// Small devices (Mobile and tablet, up to 768px)
@media (max-width: 768px) {
  .my-account {
    &__transfer-miles {
      position: relative;
      height: 100%;

      h5 {
        margin: 0;
        height: auto;
        line-height: unset;
        margin-top: 0px;
        margin-bottom: 10px;
      }

      .transfer-miles-first-row {
        flex-direction: column;
        border-color: $gray-200;
        background-color: $gray-100;
        width: 100%;
        padding: 0;

        .input-border {
          width: 212px;
          margin: 5px auto;
          &:nth-child(3) {
            margin-bottom: 25px;
          }
        }

        .final-block {
          width: 100%;
          max-width: unset;
          margin-top: 15px;
          padding: 20px 10%;
          height: auto;
          text-align: center;
        }
      }

      .gift-miles-first-row {
        padding-right: 12px;

        .gift-inputs {
          flex-direction: column;
          .dni-input-gift-wrapper{
            display: flex;
            flex-direction: column;
            // width: 55%;
            width: 100% !important;
          }
        }

        .input-border {
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .total_highlit {
        font-size: 14px;
      }

      .banner-purple {
        text-align: center;

        .col-md-1 {
          .btn-close {
            top: -140px;
            z-index: 9;
          }
        }

        .col-md-7 {
          p {
            margin-top: 30px;
            text-align: center;
          }
        }

        .btn-compra {
          margin-bottom: 20px;
        }
      }

      .rule {
        margin-top: 14px;
        padding-bottom: 43px;
        margin-bottom: 27px;

        .flex-wrapper {
          width: 100%;
        }

        .base-wrapper {
          flex-direction: column;
          margin: 0;
          text-align: left;
          background-color: $gray-100;
          padding: 0 7% 40px;

          h5 {
            font-size: 16px;
            margin-bottom: 15px;
            margin-top: 15px;
          }

          .limit-bar {
            order: unset;
            margin: 0px 10px 20px;
            position: relative;
            display: block;

            .progress-level {
              width: 100%;
            }
          }
        }

        .club, .bonus, .diamond, .points {
          height: auto;
          text-align: center;
          align-items: center;
          padding-left: 0px;
          padding-right: 0px;

          h5 {
            margin-bottom: 0px;
            font-size: 14px;
          }

          p {
            display: inline;
            font-size: 14px;
            margin-bottom: 0;
          }

          &::after, &::before {
            display: none;
          }

          .label-will-receive {
            margin-top: 13px;
            margin-bottom: 0px;
          }

          .level {
            margin-top: 25px;
            margin-bottom: 0px;
          }

          .bonusRow {
            display: block;
            margin-top: 5px;
          }
        }

        .points {
          border-left: none;
        }

        .miles-info {
            display: contents;

            .club, .bonus, .diamond {
              padding-top: 10px;
              padding-bottom: 10px;
            }

            .miles-total {
              display: flex;
              padding-left: 0px;
              padding-right: 0px;
              padding-top: 20px;
              padding-bottom: 20px;
              font-size: 12px;
              text-align: center;
              font-family: "NunitoLight",Arial,sans-serif;
              line-height: 14px;

              h5 {
                color: grey;
                font-size: 14px;
                margin-bottom: 5px;
                margin-right: 0px !important;
              }
            }
          }

        .miles-amount, .points {
          padding: 40px 10%;
          width: 100%;
          min-width: unset;
          background-color: $gray-100;

          h5 {
            margin-bottom: 17px;
          }

          p {
            font-size: 13px;
            margin-bottom: 13px
          }

          .input-border {
            width: auto;
            text-align: right;
            #miles {
              width: 100px;
              &::placeholder {
                color: $gray-400;
              }
            }
          }
        }

        .value-total {
          display: flex;
          flex-direction: row;
          border: none;
          height: auto;

          text-align: center;
          background-color: $gray-100;
          padding: 17px 10%;
          width: auto;
          flex-wrap: wrap;

          &::before{
            display: none;
          }

          &::after{
            display: none;
          }

          span {
            flex: 1;
            font-weight: bold;
            &.label {
              text-align: left;
            }
            &.total {
              text-align: right;
              flex-basis: 100%;
            }
          }
        }
      }

      .btn {
        order: 3;
        margin: 0 auto;
        width: auto;
        padding: 6px 16px;
      }
      .gift-dni-button{
        width: 100%;        
      }

      .resume-wrapper {
        border: none;
        margin: auto;
        width: 100%;
        flex-direction: row;
        font-size: 18px;
        height: auto;
        padding: 0;

        .col-md-5 {
          height: auto;
          text-align: center;

          h4 {
            display: inline;
            margin: 0;
          }

          p {
            font-size: 22px;
            display: inline;
            font-weight: bold;
            line-height: 30px;
            padding: 0;
          }
        }

        .resume {
          border: 1px solid $gray-400;
          width: auto;
          padding: 20px 15px;

          .row {
            height: auto;
            flex-direction: column;
          }

          .col-md-4 {
            margin-bottom: 17px;

            h6, p {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .miles, .diamond {
          margin: 10px 0;
        }

        h4 {
          border-top: none;
          margin: 0 auto 20px;
          text-align: center;
          font-size: 22px;
          line-height: 30px;
        }
      }
      .recaptcha-payment-gift{
        iframe{
            transform: scale(0.8);
            transform-origin: 0 0;
            width: 100%;
            height: 100%;
        }
      }
    }
  }

  // ***********
  // success
  // ***********

  .success-transfer {
    .checkout-success-header {
      padding-top: 62px;
      margin-bottom: 0;

      img {
        position: relative;
        height: auto;
      }
    }

    .success-transfer-container {
      width: 90%;
      margin-top: 0;
      border-color: $gray-400;
      padding: 15px;

      .col-3 {
        width: 100%;
        flex: 100%;
        max-width: 100%;
        border: none;
        padding: 0 !important;

        h6 {
          color: $gray-700;
          margin-top: 10px;
        }
      }

      .col-6  {
        width: 100%;
        flex: 100%;
        max-width: 100%;

        &.box-transfer-data {
          border: none;
          padding: 0;
          margin: 10px 0;

          .first-row {
            border: none;

            h6 {
              color: $gray-700 !important;
            }

            .valor {
              text-align: left;
              margin: 14px 0;

              h6 {
                margin: 0;
              }
            }
          }
        }

        h6 {
          color: $gray-700;
        }
      }

      .first-row {
        flex-direction: column;

        .bonus-gift {
          text-align: left;
        }
      }

      .button-row .buttons {
        flex-direction: column;
        text-align: center;

        button {
          margin: 12px 0;
          width: 112px;
        }

        a {
          width: 100%;
        }
      }
    }
  }

  .miles-transfer-success {
    .club-smiles {
      &__success-transfer {
        .my-account__transfer-miles {
          padding-top: 0 ;
          padding-bottom: 0;
        }
      }
    }

    .club-smiles-box-success {
      width:95% !important;
      max-width: 95% !important;
      background-color: transparent;
      border: none;

      .wrapper {
        border: 1px solid $gray-400;
        margin-top: 10px;

        .resumen {
          margin: 30px 20px;

          .resummen-table {
            flex-direction: column;

            span {
              text-align: left;
              padding: 0;
              margin: 10px 0;
            }

            .bonus-miles {
              padding:0;
            }

            .total-miles {
              text-align: left;
            }

            .value {
              font-size: 20px;
            }
          }

          .buttons {
            flex-direction: column;
            height: auto !important;
            text-align: center;

            button, button.btn-club {
              width: 112px;
              margin:10px auto !important;
              font-size: 12px;
              padding: 10px 0 !important;
            }
          }
        }
      }
    }
  }
//styles for gift recaptcha


}

