.search-flight {
  .rate-boarding {
    padding: 2vw 3vw;
    .line-separator {
      width: 100%;
      height: 2px;
      background: $gray-300;
      display: block;
      position: relative;
    }

    .rate-boarding-header {
      h5 {
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
    .choose-miles-money {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .choose-mile,
      .choose-money {
        .form-check {
          border: 1px solid $primary;
          padding: 0 4vw;
          border-radius: 4px;
          .form-check-label {
            span {
              color: $primary;
              font-weight: bold;
            }
          }
        }
        > span {
          @include bms-font-size(2.5vw, 12px);
          color: $gray-700;
          text-transform: uppercase;
        }
      }
      .choose-or {
        position: relative;
        top: -15px;
        &:before {
          content: "";
          display: block;
          height: 20px;
          width: 1px;
          background-color: $gray-700;
          position: absolute;
          left: 50%;
          top: -15px;
        }
        &:after {
          content: "";
          display: block;
          height: 20px;
          width: 1px;
          background-color: $gray-700;
          position: absolute;
          left: 50%;
          bottom: -15px;
        }
      }
    }
  }
}
