$break-small: 500px;
.club-smiles-box-img-DNI,
.club-smiles-box-success {
  background-color: $lightBackground;
  border: 1px solid $grayBorder;
  width: 1080px;
  margin-bottom: 40px;
  max-width: 100%;

  @media screen and (max-width: $break-small) {
    height: 300px;
    margin-bottom: 20px;
  }
}

.name-plan {
  font-size: 16px;
  color: $gray-700;
}

.purple-logo {
  width: 61px;
  height: 30px;
  margin: 0 10px 0 0;
  display: inline-block;

  label {
    font-family: $boldFontFamily;
    color: $club;
    font-size: 1.7rem;
    letter-spacing: 0px;
    padding: 0;
    width: 100%;
    text-align: center;
    line-height: 1rem;
    margin: 0;
  }

  small {
    background-image: url(../images/svg/logos/smiles-purple.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 10px;
    text-align: center;
    background-position: center;
    margin-top: 0px;
    position: relative;
    display: block;

    &::after {
      content: "";
      position: absolute;
      height: 9px;
      border-bottom: 2px solid $club;
      top: -4px;
      width: 20%;
      right: 75%;
      margin-left: 1px;
    }
    &::before {
      content: "";
      position: absolute;
      height: 9px;
      border-bottom: 2px solid $club;
      top: -4px;
      width: 20%;
      left: 75%;
      margin-left: 3px;
    }
  }
}

$default-border-radius: 20px;
.has-indication-code {
  max-width: 80%;

  @media screen and (max-width: 768px) {
    max-width: 95%;
  }

  > .club-smiles-box-img-DNI {
    background-color: white;
    border-radius: 20px;
    border: 1px solid $club;

    &.club-25000 {
      border: 1px solid $diamond;
    }

    .club-smiles-box-success-image {
      border-top-left-radius: $default-border-radius - 3px;
      border-bottom-left-radius: $default-border-radius - 3px;
      @media(max-width: 767px) {
      border-bottom-left-radius: 0px;
        border-top-right-radius: $default-border-radius - 3px;
      }
    }

    input {
      border-color: $gray-400;
    }
    #indicationCode {
      color: $default;
    }

    @media (min-width: 768px) {
      .col-md-12 {
          max-width: 70%;
      }
    }

    .DNI-club-smiles, .label-mobile-dni {
      width: 85%;
    }

    .forms-info {
      padding: 0 3%;
      margin: 15px 0;

      .dni-form {
        gap: 10px;
        @media screen and (max-width: 420px) {
          margin: 0 30px;
        }  
        .dni-form-Tittle{
          color: $club;
          font-size: 24px;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
        .form-group-dni{
          width: 100%;
          @media screen and (max-width: 767px) {
            width: 300px;
            margin: 5px 10px;
          }
          @media screen and (max-width: 420px) {
            width: 100%;
          } 
        }
      }

      .position-relative {
        margin: 5px 0;

        @media screen and (max-width: 767px) {
          margin: 5px 10px;
        }
      }
      .disabledInput {
        input {
          pointer-events: none;
          background: $gray-200;
        }
      }
    }

    .plan-1000 {
      font-size: 35px;
      line-height: 40px;
    }

    .your-plan-text {
      color: white;
      font-size: 1.4rem;
      margin-bottom: 25px;
    }

    .indication-code-label {
      margin-top: 20px;
    }
    .error{
      color:#e95555;
      margin-bottom: 10px;
    }
    .button-dniContinue{
      align-items: center;
      background-color: $club;
      border-radius: 9px;
      border: none;
      color: #FFF;
      display: flex;
      font-family: NunitoBold, sans-serif !important;
      font-size: 18px;
      justify-content: center;
      max-width: 300px;
      padding: 1rem 0;
      width: 100%;
      &:hover{
        opacity: .9;
      }
      @media screen and (max-width: 567px) {
        font-size: 16px;
      }    
      @media screen and (max-width: 420px) {
        max-width: 100%;
      }  
    }
  }
}

.confirm-payment {
  margin-bottom: 20px;
  .recaptcha-payment {
    margin-top: 10px;
    span {
      color: red;
    }
    > div {
      @media(max-width: 736px) {
        position: relative;
        left: 170px;
      }
      @media(max-width: 731px) {
        position: relative;
        left: 190px;
      }
      @media(max-width: 667px) {
        position: relative;
        left: 140px;
      }
      @media(max-width: 640px) {
        position: relative;
        left: 130px;
      }
      @media(max-width: 568px) {
        position: relative;
        left: 100px;
      }
      @media(max-width: 414px) {
        position: relative;
        left: 32px;
      }
      @media(max-width: 375px) {
        position: relative;
        left: 17px;
      }
      @media(max-width: 320px) {
        transform: scale(0.9);
        position: relative;
        left: -10px;
      }
    }
  }
}

.label-mobile-dni {
  width: 60%;
  margin: auto;
  display: block;
  text-align: left;
  margin-bottom: 7px;

  @media screen and (max-width: $break-small) {
    width: 90%;
    display: block;
    margin-top: 20px;
    margin-bottom: 11px;
  }
}
.label-mobile-edit-dni {
  margin-top: 10px;
  display: block;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (max-width: $break-small) {
    display: block;
    margin-top: 17px;
    width: 100%;
    text-align: center;
  }
}

.club-smiles-box-img-DNI-image,
.club-smiles-box-success-image {
  background-color: $club;
  width: 370px;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  &.club-25000 {
    background-color: $diamond;
  }

  @media screen and (max-width: $break-small) {
    height: 130px;
  }
}

.plan-1000 {
  color: $white;
  font-family: $boldFontFamily;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  &.step {
    color: $purple
  }
}

.success-family-club {
    display: grid;
}

.dni-step-subtitle {
  text-align: left;
  font-size: 20px;
}

.DNI-club-smiles {
  height: 60px;
  padding-left: 23px;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 60%;
  max-width: 85vw;
  margin: 0px auto;
  line-height: 60px;
  vertical-align: middle;
  border-color: $default;
  margin-bottom: 30px;

  @media screen and (max-width: $break-small) {
    width: 90%;
    max-width: 90vw;
  }
}

.haciendo-clic-en-con {
  color: $gray-700;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  width: 265px;
  text-align: left;
}

.btn-confirm {
  background-color: $default;
  border-radius: 3px;
  margin-bottom: 22px;
  width: 265px;
  height: 40px;
}

.confirm-container {
  .row {
    height: 100%;
  }
  text-align: center;
  align-items: center;
}

.club-smiles-box-img-DNI-container,
.club-smiles-box-success-container {
  display: flex;
  justify-content: center;

  .footer {
    background-color: $club;
    color: white;

    p {
      margin: 8px;
    }
  }

  .no-transfer {
    .btn {
      min-width: 180px;
    }
  }
}

.dniMsgError {
  background-color: $lightBackground;
  border: 1px solid $orangeBorder;
  padding: 25px;
  height: 72px;
  width: 972px;

  margin: 0px auto;
  margin-bottom: 25px;
  max-width: 100%;
  justify-content: flex-start;
  display: flex;

  span {
    color: $gray-700;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }
}

.checkout-success-header {
  max-width: 100%;
  margin-bottom: 50px;
}

/* Success */

.club-smiles-box-success {
  height: auto;

  .club-smiles-box-success-image {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 49px 0;

    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }

    .plan-1000 {
      text-align: center;
    }

    .logo-wrapper {
      position: relative;

      .logo-trace {
        border-bottom: 4px solid white;
        width: 80%;
        //border-radius: 10px;
        height: 0;
        position: absolute;
        top: 30px;
        left: 10%;
        z-index: 1;
      }

      .logo-smiles {
        z-index: 2;
        position: relative;
        display: block;
        width: 120px;
        height: 70px;
        background-image: url(../images/svg/logos/smiles-white.svg);
        background-position: center center;
        background-size: 100px;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: -1000px;
        margin: 0 auto;
        background-color: $club;

        &.club-25000 {
          background-color: $diamond;
        }
      }
    }
  }

  h3 {
    color: $club;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
  }

  .wrapper {
    display: flex;
    flex-direction: row;

    .resummen-table {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      font-size: 0.8em;
      margin: 20px auto;

      .sub-miles {
        color: $club;
        text-align: left;
        white-space: nowrap;
      }

      .bonus-miles {
        color: $default;
        padding: 0 20px;
        text-align: left;
        white-space: nowrap;
      }

      .total-miles {
        color: $club;
        padding-left: 20px;
        text-align: left;
        white-space: nowrap;
      }

      .add,
      .equal {
        text-align: center;
        width: 50px;
        font-size: 1.5em;
        color: $club;
      }
    }

    a {
      background-color: $club;
      width: 188px;
      height: 53px;
      border: none;
      font-size: 20px;
      color: white;
      float: right;
      margin-top: 20px;
      padding-top: 10px;
      text-align: center;
      &:hover {
        text-decoration: none;
      }
      button {
        background-color: transparent;
        border: 0;
      }
    }

    .field {
      margin: 15px 0;
    }

    .account-data {
      text-align: left;
      margin: 30px 0 30px 30px;
      padding-right: 30px;
      border-right: 1px solid $grayBorder;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;

      font-weight: bold;

      .label {
        display: block;
        clear: both;
      }
    }

    .resumen {
      text-align: left;
      margin: 30px;
      flex-grow: 2;
      flex-basis: 0;

      font-weight: bold;

      .buttons {
        height: 55px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .btn {
          height: 100%;
          margin: 5px;
          padding: 13px 30px;
        }

        .btn-club {
          color: white;
          padding: 13px 60px;
          font-size: 20px;
        }
      }
    }
  }
}

.purple-box-container {
  display: flex;
  justify-content: center;
  width: 1060px;
  padding: 0px 10px 0px 10px;
}

.purple-box {
  background: $club;
  height: 72px;
  color: white;
  padding: 15px 20px;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .purple-box-container {
    display: flex;
    justify-content: center;
    height: auto;
    max-width: 100%;
  }

  .purple-box {
    height: auto;
  }

  .checkout-success-header {
    margin-bottom: 0px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .club-smiles-box-success {
    .wrapper {
      flex-direction: column;

      .resummen-table {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-around;
        text-align: center;

        .total-miles {
          flex-basis: 100%;
          padding: 20px 0;
          text-align: center;
        }
      }

      .account-data {
        display: block;
        border: none;
        border-bottom: 1px solid $grayBorder;
        margin: 20px 20px 0 20px;
        padding: 0 0 20px 0;
      }

      .resumen {
        .no-transfer {
          .btn {
            padding: 0px;
            min-width: 130px;
          }
        }
      }

      a {
        float: none;
        clear: both;
        display: block;
        margin: 20px auto;
      }
    }
  }
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.club-smiles-box-alert {
  background-color: $lightBackground;
  border: 1px solid $orangeBorder;
  margin-bottom: 20px;
  //height: 72px;
  padding: 20px;
}

.size-responsive {
  @media(max-width: 384px) {
    label {
      font-size: 0.7rem;
    }
  }
}

button.upgrade-plan {
  border-radius: 5px;
}

.club-title {
  color: $club;
}

.club-suspended-container {
  @media screen and (max-width: 768px) {
    display: none !important;
  }

  div.h5.text-body {
    width: 50%;

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
}

/* Club Plans */

.accession {
  background-color: $gray-100;
  width: 100%;
  height: 200px;
  display: inline-block;
  margin-top: 30px;
  position: relative;

  &.most-sold {
    &::before {
      content: '';
      background-image: url(#{$image-path}/club-smiles/most-sold-club-plan.png);
      background-size: contain;
      position: absolute;
      z-index: 1;
      top: -24px;
      left: -24px;
      width: 55px;
      height: 55px;
    }
  }

  .club {
    height: 200px;
    margin: 0;
    width: 30%;
    background-color: $club;
    border: 1px solid $club;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    label {
      font-size: 30px;
      color: $white;
      margin-top: 15px;
      margin-bottom: 0;
      font-family: $boldFontFamily;
    }

    span {
      background-image: url(#{$svg-path}/logos/smiles-white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 60px;
      height: 25px;
      position: relative;
      display: inline-block;
      margin-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        height: 9px;
        border-bottom: 3px solid $white;
        top: -5px;
        width: 40px;
        left: 100%;
        margin-left: 3px;
      }

      &::after {
        content: "";
        position: absolute;
        height: 9px;
        border-bottom: 3px solid $white;
        top: -5px;
        width: 40px;
        right: 100%;
        margin-right: 3px;
      }
    }
  }

  .info {
    width: 70%;
    float: right;
    padding: 10px;
    display: flex;
    height: 200px;
    border: 1px solid #CBCBCB;
    border-left: none;

    p {
      &:nth-child(2) {
        text-align: center;
      }
    }

    a {
      background-color: $club;
      border: none;
      float: right;
      color: $white;
      text-align: center;
      margin: 10px 0 0 0;
      text-decoration: none;

      &:hover {
        color: $white;
      }
    }

    .club-first-block {
      width: 26%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 5px;

      &.border-plus {
        border-right: 2px dashed $club;

        &::before {
          content: '';
          height: 6px;
          background-color: $club;
          position: absolute;
          right: -16px;
          transform: rotate(90deg);
          box-shadow: 0px 0px 0px 10px $lightBackground;
          width: 30px;
          border-radius: 10px;
          border: 2px solid $club;
        }

        &::after {
          content: '';
          height: 6px;
          background-color: $club;
          position: absolute;
          right: -16px;
          width: 30px;
          border-radius: 10px;
          border: 2px solid $club;
        }
      }
    }

    .info-third-block {
      width: 42%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 30px;
      flex-direction: column;

      .info-title {
        width: 270px;
        padding-left: 5px;
      }

      .info-third-block-months {
        display: flex;

        .info-third-month {
          color: $primary;
          font-family: $boldFontFamily;
          font-size: 14px;
          line-height: 1.4;
          text-align: center;
          border-right: 1px solid #ccc;
          border-bottom: none !important;
          padding: 0 8px;

          &:last-child {
            border-right: none !important;
          }

          span {
            font-size: 24px;
            line-height: 1.2;
            display: block;
          }
        }
      }
    }

    .info-fourth-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 30%;

      .info-link {
        width: 180px;
        font-size: 18px;
        font-family: $boldFontFamily;
        padding: 10px;
        display: inline;
        height: auto;
        line-height: 1.4;
        position: relative;

        span {
          display: block;
        }
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 13px;
      text-align: left;
      line-height: 25px;
      font-family: $boldFontFamily;

      &:first-child {
        padding-bottom: 10px;
        border-bottom: 1px dashed $club;
      }

      &.info-title {
        font-size: 26px;
        width: 180px;
        line-height: 1.2;
        border: 0 !important;
      }
    }

    .txtPurple {
      color: $club !important;
    }

    .txtOrange {
      color: $primary !important;
    }

    .txtRegular {
      font-family: $regularFontFamily;
    }
  }
}

/*
  patches .accession for the "invite-friends" screen
  to avoid changing other instances
*/
.member-get-member-container .invite-friends-screen {
  $purple: #6d3c9d;
  $yellow: #fef101;
  $gray: #e8e1f0;

  $width: 1179px;

  /* plus after element */
  %plus-after {
    content: '+';
    position: absolute;
    right: 0;
    top: 50%;
    padding: 10px 10px;
    border-radius: 100%;
    font-size: 54px;
    line-height: .6em;
    transform: translateX(50%) translateY(-50%);
  }

  /* Colors */
  .accession {
    .info-first-block::after {
      @extend %plus-after;
      color: $yellow;
      background: $purple;
    }
  }

  .accession.grayish {
    .info-first-block::after {
      @extend %plus-after;
      color: $purple;
      background: $gray;
    }
  }

  /* Other */
  .accession {
    .info {
      width: 100%;
      border: none;

      .info-first-block {
        position: relative;
        margin-top: -10px;

        .info-title {
          text-align: center;
          margin-top: 0;

          &::after,
          &.txtPurple::after {
            content: unset;
          }

          &.txtMillas {
            font-size: 16px;
          }
        }

        .club {
          width: 100%;
          height: unset;
          background-color: unset;
          border: none;
        }
      }

      .info-third-block {
        margin-top: 0;

        .info-third-block-months {
          & > p {
            line-height: 1em;
          }

          .info-third-month span:first-of-type {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      .info-fourth-block {
        margin-top: 0;

        a {
          width: 190px;
          background-color: unset;
          padding: 10px;
          font-family: $regularFontFamily;
        }
      }
    }
  }

  @media screen and (max-width: $width) {
    .accession {
      height: auto;
      background-color: $purple;

      &.grayish {
        background-color: $gray;
      }

      .info {
        height: auto;

        .info-first-block .info-title {
          width: unset
        }

        .info-third-block {
          padding-left: 0;
          width: unset;
          margin-bottom: 10px;
        }

        .info-fourth-block {
          width: unset;

          a {
            display: block;

            .txtRegular {
              font-size: 26px;
            }

            .txtSubscribe, .txtMonth {
              display: block;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .accession {
    .info {
      .club-first-block {
        width: 100%;
        position: relative;

        &.border-plus {
          padding: 0;
          border-right: none;
          border-bottom: 2px dashed $club;

          &::before {
            top: 83px;
            right: 50%;
            margin-right: -15px;
          }
          &::after {
            right: 50%;
            top: 83px;
            z-index: 4;
            box-shadow: none;
            margin-right: -15px;
          }
        }
      }
    }
  }
}
