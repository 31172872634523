.service-name-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 0px;

    .service-name {
        font-size: 16px;
        color: $black;
        text-transform: uppercase;
        font-weight: 900;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 10px;
    }
    .service-name:hover {
        color: $primary;
    }
}

.service-name-details {
    font-size: 16px;
    color: $black;
    text-transform: uppercase;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 10px;
}

.check-service-name-details {
    height: 27px;
    width: 27px;
    margin-top: 10px;
    margin-left: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../images/svg/icons/orange/ic-check-bg-orange.svg");
}

.airport-service-image {
    width: 250px;
    height: 160px;
    border-radius: 26px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* ServiceDetails */
.services-list {
    color: $black;
}
.my-account__extract {
    .other-options {
        color: $black;
    }
    .other-options:hover {
        opacity: 0.5;
    }
}

.title-vip-jarvis {
    align-items: center;
    .logo-jarvis {
        background-image: url("../../images/svg/logos/jarvis-logo.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 120px;
        height: 90px;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 575px) {
    .airport-service-image {
        width: 100%;
    }
    .airport-name {
        padding: 15px;
        text-align: center;
    }
    .service-name-container {
        justify-content: center;
        padding: 6px !important;

        .service-name {
            margin-top: 0px;
            padding: 14px;
        }
    }
    .row-details-container {
        justify-content: center;
    }
    .title-vip-jarvis {
        .logo-jarvis {
            width: 100%;
            margin-left: 0px;
        }
    }
}

.textfadeInBottom,
.textfadeInBottom span {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
}

.textfadeInBottom span {
    display: inline-block;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        height: auto;
    }
}

.cardServiceRow {
    background-color: white;
    margin-top: 10px;
    border: 2px solid $default;
    border-radius: 25px;
    align-items: center;
    &:hover{
        box-shadow: 1px 1px 5px 5px #ccc;
    }
}

/* ServiceCard */
