.container {
  .form-multiple-checkout__container {
    width: 100%;
    background-color: $white;
    border-radius: 20px;
    border: 1px solid $club;

    .form-multiple-checkout__left {
      height: 100%;
      background-color: $club;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 50px 0;
      border-top-left-radius: 17px;
      border-bottom-left-radius: 17px;

      @media only screen and (max-width: 767px) {
        border-bottom-left-radius: 0;
        border-top-right-radius: 17px;
      }

      .plan-1000 {
        color: $white;
        font-family: "NunitoBold", "Arial Bold", sans-serif;
        font-size: 35px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        @media only screen and (max-width: 500px) {
          font-size: 35px !important;
        }
      }

      .logo-wrapper {
        position: relative;

        .logo-smiles {
          z-index: 2;
          position: relative;
          display: block;
          width: 120px;
          height: 70px;
          background-image: url(../images/svg/logos/smiles-white.svg);
          background-position: center center;
          background-size: 100px;
          background-repeat: no-repeat;
          overflow: hidden;
          text-indent: -1000px;
          margin: 0 auto;
          background-color: $club;
        }

        .logo-trace {
          border-bottom: 4px solid $white;
          width: 80%;
          height: 0;
          position: absolute;
          top: 30px;
          left: 10%;
          z-index: 1;
        }
      }
    }

    .form-multiple-checkout__right {
      height: auto;
      padding: 1.5rem;

      .form__container {
        padding: 1.5rem;

        label {
          font-weight: bold;
          color: $club;
          margin-bottom: .5rem;
        }
        .form__container_error-text{
          margin-bottom: 1.5rem;
          span{
            color: #e95555;
          }
        }

        .form-group {
          .form {
            margin-bottom: 0.5rem;

            .input__container {
              display: flex;

              .btn-delete {
                background-color: transparent;
                color: $gray-700;
                border: none;
                font-size: 0.75rem;
                font-family: "Nunito", "Arial", sans-serif;
                padding: 0;
                position: absolute;
                right: 15px;
                top: 5px;
              }
            }

            .form-control {
              border-radius: 5px;
              height: 50px;
              line-height: 20px;
              font-size: 15px;
              font-weight: bold;
              @media only screen and (max-width: 991px) {
                margin-bottom: 0.5rem;
              }
            }

            .message__container {
              height: 100%;
              max-height: 50px;
              padding: 0.5rem;
              font-size: 0.8rem;
              @media only screen and (max-width: 767px) {
                max-height: 100%;
                max-height: 50px;
              }

              .popover {
                display: flex;
                right: -215px;
                width: 150px;
                margin: auto;
                height: 50px;
                padding: 2px;
                font-size: 0.6rem;
                border-color: $red;
                border-radius: 0;
                text-align: center;
                align-items: center;
                z-index: 1;
                line-height: 10px;
                @media only screen and (max-width: 767px) {
                  margin-left: 12rem;
                }
                @media only screen and (max-width: 500px) {
                  margin-left: 9rem;
                }
                @media only screen and (max-width: 375px) {
                  margin-left: 7.5rem;
                }
                span {
                  line-height: 11px;
                  @media only screen and (max-width: 500px) {
                    font-size: 0.6rem !important;
                  }
                }
              }

              .popover::before {
                position: absolute;
                display: block;
                content: "";
                border-color: transparent;
                border-style: solid;
                border-width: 0.5rem 0.5rem 0.5rem 0;
                border-right-color: $red;
                top: 12px;
                left: -9px;
              }

              .popover::after {
                position: absolute;
                display: block;
                content: "";
                border-color: transparent;
                border-style: solid;
                border-width: 0.5rem 0.5rem 0.5rem 0;
                border-right-color: $white;
                top: 12px;
                left: -8px;
              }

              .message__inner {
                display: flex;
                flex-direction: row;
                margin: 0 0 0 0;
                align-items: center;
                @media only screen and (max-width: 769px) {
                  margin: 1rem 0 0 0;
                }
                .error-icon {
                  display: inline-block;
                  background: url(#{$svg-path}/icons/red/ic-error-adhesion.svg)
                    no-repeat center;
                  width: 30px;
                  height: 30px;
                  background-size: 30px;
                  position: relative;
                  top: 3px;
                  @media only screen and (max-width: 991px) {
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -13px;
                  }
                }
                .alert-icon {
                  display: inline-block;
                  background: url(#{$svg-path}/icons/orange/ic-alert-adhesion.svg)
                    no-repeat center;
                  width: 30px;
                  height: 30px;
                  background-size: 30px;
                  position: relative;
                  top: 3px;
                  @media only screen and (max-width: 991px) {
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -13px;
                  }
                }
                .success-icon {
                  display: inline-block;
                  background: url(#{$svg-path}/icons/green/ic-success-adhesion.svg)
                    no-repeat center;
                  width: 25px;
                  height: 25px;
                  background-size: 30px;
                  position: relative;
                  top: 5px;
                  @media only screen and (max-width: 991px) {
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -11px;
                  }
                }
                .red-text {
                  display: inline-block;
                  font-size: 14px;
                  font-weight: bold;
                  color: $danger;
                  line-height: 15px;
                  padding-left: 0.5rem;
                  padding-right: 1rem;
                  width: 100%;
                  max-width: 140px;
                  position: relative;
                  top: 3px;
                  @media only screen and (max-width: 991px) {
                    font-size: 11px;
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -13px;
                  }
                  @media only screen and (max-width: 500px) {
                    max-width: 120px;
                  }
                  @media only screen and (max-width: 375px) {
                    max-width: 110px;
                    font-size: 10px !important;
                  }
                }

                .orange-text {
                  display: inline-block;
                  font-size: 14px;
                  font-weight: bold;
                  color: $orange;
                  line-height: 15px;
                  padding-left: 0.5rem;
                  padding-right: 1rem;
                  width: 100%;
                  max-width: 140px;
                  position: relative;
                  top: 3px;
                  @media only screen and (max-width: 991px) {
                    font-size: 11px;
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -13px;
                  }
                  @media only screen and (max-width: 375px) {
                    font-size: 10px !important;
                    max-width: 110px;
                  }
                }

                .green-text {
                  display: inline-block;
                  font-size: 14px;
                  font-weight: bold;
                  color: $success;
                  line-height: 25px;
                  padding-left: 0.7rem;
                  position: relative;
                  top: 5px;
                  @media only screen and (max-width: 991px) {
                    font-size: 11px;
                    top: 0px;
                  }
                  @media only screen and (max-width: 769px) {
                    top: -11px;
                  }
                  @media only screen and (max-width: 375px) {
                    font-size: 10px !important;
                  }
                }
              }
            }
          }
        }
        .error{
          color: #e95555;
        }
        .form__container__validate-button button {
          background-color: transparent;
          border-radius: 5px;
          border: 1px solid $club;
          color: $club;
          font-weight: bold;
          height: 50px;
          max-width: 410px;
          width: 45%;
          &:hover {
            background-color: $club;
            border: none;
            color: #fff;
          }
        }
      }
    }
  }

  .terms-conditions__container {
    display: flex;
    height: 50px;
    padding-top: 12px;
    @media only screen and (max-width: 991px) {
      justify-content: center;
    }
    @media only screen and (max-width: 769px) {
      margin-bottom: 1.5rem;
    }
  }
  .button-continue {
    background-color: $club;
    height: 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 410px;
  }
}
