.airportSelect-container{
    border: 1px solid $gray-400;
    border-radius: 3px;
    display: flex;
    margin-top: 20px;
    min-height: 150px;
    .jarvis-airport-plane-smile-img{
        background-color:$primary;
        width: 150px;
        img{
            width: 90px;
        }
    }
    .jarvis-airport-inputs-container{
    /*     display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        flex: 4; */
        flex:4;
    
        .jarvis-label {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 16px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                &:checked {
                    ~ {
                        .checkmark {
                            background-color: $primary;
                            &:after {
                                display: block;
                            }
                        }
                    }
                }
            }
            &:hover {
                input {
                    ~ {
                        .checkmark {
                            background-color: $gray-500;
                        }
                    }
                }
            }
            .checkmark {
                &:after {
                    left: 10px;
                    top: 4px;
                    width: 5px;
                    height: 15px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 1px solid $gray-500;
            border-radius: 50%;
            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }
        
    }
}

@media only screen and (max-width: 575px){
    .airportSelect-container{
        .jarvis-airport-plane-smile-img{
            img{
                width: 40px;
            }
        }
        .jarvis-label{
            margin: 25px;
        }
    }

}