/*Resultado Adhesión Simple Inicio*/
.container-fluid {
    .top-banner {
        @media only screen and (max-width: 1152px) {
            margin-top: 2rem;
        }
        @media only screen and (max-width: 991px) {
            margin-top: revert;
        }
        @media only screen and (max-width: 768px) {
            margin-top: 3.5rem;
        }
        .image-banner {
            max-width: 100% !important;
            height: auto !important;
        }
    }
}
.success-reactive-simple__container {
    padding: 1.5rem 1.5rem;
    margin: 0px;
    .container {
        width: 1279px !important;
        max-width: 100% !important;
        .success-reactive-simple__title {
            margin-top: 2rem;
            h4 {
                color: $club;
                margin-bottom: 1rem;
            }
        }
        .resume-wrapper {
            padding: 0px;
            border: 1px solid $gray-500;
            width: 100% !important;
            display: flex;
            margin-bottom: 7.5rem;
            @media only screen and (max-width: 991px) {
                flex-direction: column;
            }
            .resume-wrapper__left {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                .resume-wrapper__left--inner-top {
                    background-color: $club;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 90px 50px;
                    @media only screen and (max-width: 1287px) {
                        padding: 82px 50px;
                    }
                    @media only screen and (max-width: 1112px) {
                        padding: 95px 50px;
                    }
                    @media only screen and (max-width: 1041px) {
                        padding: 107px 50px;
                    }
                    @media only screen and (max-width: 1038px) {
                        padding: 92px 50px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 75px 50px;
                    }
                    .resume-wrapper__icon-check {
                        width: 55px;
                        height: 55px;
                        background-size: 55px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                    }
                    h4 {
                        margin: 0px 0px 20px 10px;
                        color: $white;
                        text-align: center;
                        .smile-word {
                            transform: rotate(90deg);
                            display: inline-block;
                            width: 21px;
                            position: relative;
                            top: 9px;
                            left: 1px;
                        }
                    }
                    .user-cancel-subtitle {
                        margin: 0px;
                    }
                }
                .resume-wrapper__left--inner-bottom {
                    background-color: $white;
                    padding: 24px 20px;
                    display: flex;
                    justify-content: center;
                    .resume-wrapper__button-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        gap: 10px;
                        align-items: center;
                        width: 100%;
                        max-width: 315px;
                        a {
                            .btn {
                                margin-top: 0px;
                                padding: 8px 20px;
                                width: 200px;
                                height: 35px;
                                @media only screen and (max-width: 425px) {
                                    width: 130px;
                                }
                            }
                            .home-button {
                                background-color: $club;
                            }
                        }
                    }
                }
            }
            .resume-bottom{
                background-color: $gray-200;
            }
            .wrapper-padding{
                padding: 2rem 2rem 2rem 2rem;
                .subtitle-bottom{
                    color: $gray-700;
                }
                div{
                    div{
                        p{
                            font-family: $boldFontFamily;
                        }
                    }
                }
                .resume-wrapper__right {
                    background-color: $white;
    
                    .resume-wrapper__inner-top{
                        .resume-wrapper__inner-bottom{
                            h6{
                                color: $club;
                            }
                        }
                    }
    
                    .resume-subscription__title {
                        display: inline-block;
                        font-weight: 600;
                        color: $club;
                        margin-bottom: 0.25rem;
                    }
                    .resume-subscription__paragraph {
                        display: inline-block;
                    }
    
                    .subscription-detail__left {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        color: $club;
                        font-weight: 600;
                        @media only screen and (max-width: 550px) {
                            font-size: 16px;
                        }
                        @media only screen and (max-width: 500px) {
                            font-size: 13px;
                        }
                    }
                    .subscription-detail__middle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                        color: $primary;
                        font-weight: 600;
                        @media only screen and (max-width: 550px) {
                            font-size: 16px;
                        }
                        @media only screen and (max-width: 500px) {
                            font-size: 13px;
                        }
                        .subscription-detail__middle--plus-sing {
                            display: inline-block;
                            align-items: center;
                            font-size: 18px;
                            color: $club;
                            font-weight: 600;
                            padding-right: 2.5rem;
                            padding-left: 2.5rem;
                            @media only screen and (max-width: 550px) {
                                font-size: 16px;
                                padding-right: 1.5rem;
                                padding-left: 1.5rem;
                            }
                            @media only screen and (max-width: 500px) {
                                font-size: 13px;
                                padding-right: 1rem;
                                padding-left: 1rem;
                            }
                        }
                        .subscription-detail__middle--equal-sing {
                            display: inline-block;
                            font-size: 18px;
                            color: $club;
                            font-weight: 600;
                            padding-left: 2.5rem;
                            padding-right: 2.5rem;
                            @media only screen and (max-width: 550px) {
                                font-size: 16px;
                                padding-right: 1.5rem;
                                padding-left: 1.5rem;
                            }
                            @media only screen and (max-width: 500px) {
                                font-size: 13px;
                                padding-right: 1rem;
                                padding-left: 1rem;
                            }
                        }
                    }
                    .subscription-detail__right {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        color: $club;
                        font-weight: 600;
                        @media only screen and (max-width: 550px) {
                            font-size: 16px;
                        }
                        @media only screen and (max-width: 500px) {
                            font-size: 13px;
                        }
                    }
                }
            }
            .subscription-detail-payment__container {
                background-color: $gray-200;
                width: 100%;
                padding: 2rem 2rem 1rem 2rem;
                .subscription-detail-payment__list--left {
                    padding: 0;
                    margin: 0rem 0.5rem 1rem 0rem;
                    @media only screen and (max-width: 575px) {
                        margin: 0rem 0rem 1rem 0rem;
                    }
                    .list-title {
                        font-weight: 600;
                        display: inline-block;
                        margin-bottom: 0.8rem;
                    }
                    li {
                        span {
                            display: block !important;
                        }
                    }
                }
                .subscription-detail-payment__list--right {
                    padding: 0;
                    margin: 0rem 0rem 1rem 0.5rem;
                    @media only screen and (max-width: 575px) {
                        margin: 0rem 0rem 1rem 0rem;
                    }
                    .list-title {
                        font-weight: 600;
                        display: inline-block;
                        margin-bottom: 0.5rem;
                    }
                    .list-icon-user {
                        margin-right: 10px;
                        display: inline-block;
                        width: 25px;
                        height: 22px;
                        background-size: 25px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                        position: relative;
                        top: 1px;
                    }
                    .list-icon-tdc {
                        margin-right: 10px;
                        display: inline-block;
                        width: 25px;
                        height: 17px;
                        background-size: 25px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url(#{$svg-path}/icons/gray/ic-credit-card.svg);
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
    }
}
/*Resultado Adhesión Simple Inicio*/
// UserCancel
.cancel-flight-code-wrapper {
    margin: 5em;
    text-align: center;

    .flight-code {
        display: inline;
    }
}
.cancellation-reasons-wrapper {
    padding: 0px;
    border: 1px solid #bcbec0;
    border-radius: 20px 20px 0px 0px;
    width: 100% !important;
}
.cancellation-reasons-wrapper-ET {
    padding: 0px;
    border: 1px solid #bcbec0;
    border-radius: 20px 20px 0px 0px;
    width: 1279px !important;
    max-width: 100% !important;
    text-align: center;
    margin: auto;
}
.cancelation-reasons-title {
    padding: 20px;
}
.cancelation-reasons-box {
    background-color: $gray-200;
    border-top: 1px dashed #bcbec0;
    height: 300px;
    border-bottom: 1px solid #bcbec0;

    .cancelation-reasons-content {
        background-color: $white;
        border: 1px solid #bcbec0;
        border-radius: 15px;
        width: 55%;
        margin: 40px auto;

        @media only screen and (max-width: 575px){
            width: 85%;
        }

        .cancellation-reasons-content-title-send {
            display: block;
            padding: 40px;
            text-align: center;
            h3 {
                text-align: center;
                color: $gray-700;
                font-weight: 400;
                font-family: $regularFontFamily;
            }
        }

        .cancellation-reasons-content-title {
            display: block;
            padding: 25px 0px 0px;
            text-align: left;
            margin-left: 20px;
        }

        .inputs-wrapper {
            div {
                margin: 20px 0px;
                text-align: start;
                label span b {
                    margin-left: 20px;
                }
            }
        }
    }
}
.cancel-flight-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 150px;
    .cancel-flight-button {
        margin-top: 30px;
        border-radius: 5px;
        padding: 0px 35px;
    }
}
.easy-travel-wrapper {
    background-color: $easy-travel !important;
    .user-cancel-subtitle {
        margin: 0px;
    }
}

.new-progress-bar-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-size: 30px;
    padding: 1em;
    margin-bottom: 0.25em;
    vertical-align: top;
    transition: 0.3s color, 0.3s border, 0.3s transform, 0.3s opacity;

    .new-progress-bar-inside {
        background-color: white;
        padding: 98px 15%;
        border: 1px solid #c7c7c7;
        border-radius: 16px;
        .new-progress-bar-cancel {
            width: 25vw;
            height: 0.66em;
            border-radius: 0.1em;
            background: linear-gradient(
                -60deg,
                rgba(135, 135, 135, 0.15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(135, 135, 135, 0.15) 50%,
                rgba(135, 135, 135, 0.15) 75%,
                #8b8b8b00 75%,
                transparent
            );
            background-size: 1em 2em;
            background-position: 0 0;
            animation: progress-bar-animation 0.8s infinite linear;
            box-shadow: 0px 0px 6px $primary, inset 0px 0px 3px $primary;
            @media only screen and (max-width: 550px) {
                width: 50vw;
            }
        }
        .new-progress-bar-easyTravel {
            width: 25vw;
            height: 0.66em;
            border-radius: 0.1em;
            background: linear-gradient(
                -60deg,
                rgba(135, 135, 135, 0.15) 25%,
                transparent 25%,
                transparent 50%,
                rgba(135, 135, 135, 0.15) 50%,
                rgba(135, 135, 135, 0.15) 75%,
                #8b8b8b00 75%,
                transparent
            );
            background-size: 1em 2em;
            background-position: 0 0;
            animation: progress-bar-animation 0.8s infinite linear;
            box-shadow: 0px 0px 6px $easy-travel, inset 0px 0px 3px $easy-travel;
            @media only screen and (max-width: 550px) {
                width: 50vw;
            }
        }
    }
}
@keyframes progress-bar-animation {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -2em 0;
    }
}
