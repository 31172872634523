
.counter-passenger-container {
    .passenger-description{
        font-size: .7em;
    }
    .jarvis-passenger-type{
        h6{
            color: $gray-600;
        }
    }
    .passenger-quantity{
        font-size: .7em;
        text-align: right;
    }
    
    .jarvisV2-counter-btn{
        div{
            .amount{
               font-size: .8em;
               line-height: 1.8;
               border: 1px solid $gray-600;
               padding:4px 9px
            }
        }
        .btn-less{
            border-radius: 4px 0px 0px 4px;
            background-color: $gray-600;
            color: white;
            border: none;
            padding: 2px 10px;
        }
        .btn-less:hover{
            opacity: .6;
        }
        .btn-plus{
            border-radius: 0px 4px 4px 0px;
            background-color: $gray-600;
            color: white;
            border: none;
            padding: 2px 10px;
        }
        .btn-plus:hover{
            opacity: .6;
        }
    }
    
    .pricing-jarvis-v2{
        .price{
            font-size: .75em;
            font-family: $boldFontFamily;
            text-align: right;
            margin-right: 20px !important;
            &__standard{
                color: $primary;
            }
            &__club{
                color: $club;
            }
            &__diamante{
                color: $diamond;
            }
    
        }
    }
}

@media only screen and (max-width: 575px){
    .counter-passenger-container {
        justify-content: center;
        text-align: center;
        .pricing-jarvis-v2{
            margin-top: 10px;
            .price{
                font-size: 1.1em;
                text-align: center;
                margin-right: 0px !important;
            }
        }
    }
}