.modal-calendar {
  .header-flight {
    background: $primary;
    height: 50px;
    border-radius: $border-radius;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    
    .flight-info {
      @include bms-font-size(3vw, 14px);
      color: $white;
      text-transform: uppercase;
      position: relative;
      // padding-left: 40px;
      text-align: left;
      font-weight: 700;
      display: flex;
      margin-left: 10px;
      margin-top: 3px;
      &:before {
        content: "";
        background-repeat: no-repeat;
        background-size: 100%;
        height: 22px;
        width: 22px;
        display: block;
        left: 10px;
        margin-right: 4px;
        margin-left: 4px;
      }
      &.back,
      &.going {
        &:before {
          top: -5px;
          background-image: url(#{$svg-path}/icons/white/ic-flight-going.svg);
        }
      }
    }
    .line-separator {
      width: 1px;
      height: 35px;
      background: $primary-light-100;
      display: block;
      position: relative;
      margin: 0 10px;
    }
    .flight-city {
      @include bms-font-size(3.5vw, 16px, true);
      color: $white;
      text-align: right;
      &:not(small) {
        font-weight: 400;
      }
      &:last-child {
        text-align: left;
      }
      small {
        display: none;
        font-size: 12px;
      }
    }
    .arrow {
      width: 10%;
      &:before {
        content: "";
        background-image: url(#{$svg-path}/icons/white/ic-right-arrow-paging.svg);
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        width: 100%;
        height: 13px;
      }
    }
  }
}
@media (min-width: 768px) {
  .modal-calendar {
    .header-flight {
      @media (max-width: 980px) {
        height: 70px;
      }

      @media (min-width: 1400px) {
        height: 70px;
      }
      height: 60px;
      padding-left: 15px;
      .flight-info {
        @include bms-font-size(3vw, 16px);
        color: $white;
        text-transform: uppercase;
        position: relative;
        // padding-left: 40px;
        text-align: left;
        font-weight: 700;
        font-size: 18px;
        display: block;
        &:before {
          content: "";
          background-repeat: no-repeat;
          background-size: 100%;
          height: 22px;
          width: 22px;
          display: block;
          left: 10px;
        }
        &.going {
          justify-content: center;
          align-items: center;
          text-align: center;
          &:before {
            top: 0;
            width: 26px;
            background-image: url(#{$svg-path}/icons/white/ic-flight-going.svg);
          }
        }
        &.back {
          &:before {
            top: 0;
            width: 28px;
            background-image: url(#{$svg-path}/icons/white/ic-flight-back.svg);
          }
        }
      }
      .flight-city {
        width: 28%;
        small {
          display: block;
          font-size: 12px;
        }
      }
      .line-separator {
        height: 50px;
        margin: 0;
        left: 10px;
      }
      .arrow {
        width: 45px;
        margin-left: 10px;
        margin-right: 10px;
        &:before {
          margin-left: 15%;
          width: 80%;
          height: 29px;
          background-image: url(#{$svg-path}/icons/white/ic-arrow-right.svg);
          background-position: right center;
        }
      }
    }
  }
}
