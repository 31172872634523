.checkout {

    &__emission-options {
        
        .box-subtitle {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .title {
            width: 100%;
            text-align: center;
        }
        .title-center {
            display: block !important;
        }
        .options {
            display: block;
            width: 100%;

            .option {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                font-family: $regularFontFamily;
                font-size: 12px;
                border: 1px solid $default;
                border-radius: 4px;
                padding: 3px;
                height: 209px;
                width: 100%;
                text-align: center;
                margin: 10px 0;

                &.ticket {
                    background: $white;

                    .content {
                        background-image: url(#{$svg-path}/icons/orange/ic-ticket.svg);
                        background-repeat: no-repeat;
                        background-position: center 40px;
                        background-size: 97px;

                        p {
                            font-size: 14px;
                            line-height: 17px;
                            margin: 0;
                            color: $default;

                            &.title {
                                font-size: 20px;
                                line-height: 30px;
                                color: $default;
                                padding: 120px 0 0 0;
                            }
                        }
                    }

                    &.active {
                        background-image: url(#{$svg-path}/icons/white/ic-check.svg);
                        background-repeat: no-repeat;
                        background-position: 20px 20px;
                        background-size: 30px;
                        color: $white;
                        background-color: $default;

                        .content {
                            background-image: url(#{$svg-path}/icons/white/ic-ticket.svg);

                            p {
                                color: $white;

                                &.title {
                                    color: $white;
                                }
                            }
                        }
                    }


                }

                &.easytravel {
                    background: $white;
                    border-color: $easy-travel;

                    .content {
                        background-image: url(#{$svg-path}/icons/ocean/ic-easy-travel.svg);
                        background-repeat: no-repeat;
                        background-position: center 40px;
                        background-size: 55px;
                        text-align: center;

                        p {
                            font-size: 12px;
                            line-height: 15px;
                            margin: 0;
                            color: $gray-600;
                            max-width: 267px;
                            display: inline-block;

                            &.title {
                                font-size: 20px;
                                line-height: 30px;
                                color: $easy-travel;
                                padding: 100px 0 0 0;
                                width: 100%;
                            }
                        }
                    }

                    &.active {
                        background-image: url(#{$svg-path}/icons/white/ic-check.svg);
                        background-repeat: no-repeat;
                        background-position: 20px 20px;
                        background-size: 30px;
                        color: $white;
                        background-color: $easy-travel;

                        .content {
                            background-image: url(#{$svg-path}/icons/white/ic-easy-travel.svg);

                            p {
                                color: $white;

                                &.title {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    &::before{
                        content: 'o';
                        display: inline-block;
                        position: absolute;
                        box-sizing: border-box;
                        color: $gray-700;
                        border: 4px solid $white;
                        right: 45%;
                        top: 95%;
                        background-color: #ececec;
                        width: 44px;
                        height: 44px;
                        padding-top: 1px;
                        border-radius: 50%;
                        font-family: Arial,Helvetica,sans-serif;
                        font-size: 20px;
                        padding-left: 1px;
                        z-index: 1;
                    }
                }
            }
        }
    }

    .box {
        &.easy-travel {
            width: 100%;
            margin-bottom: 20px;
            border-bottom: 2px solid $gray-300;
            padding: 0 0 30px 0;
            display: inline-block;

            .no-miles {
                text-align: center;
                p {
                    font-size: 14px;
                    margin: 0;
                }
                label {
                    font-size: 19px;
                    font-family: $boldFontFamily;
                    color: $default;
                    line-height: 22px;
                    margin: 10px auto;
                }
            }

            .info {
                padding: 30px 10px 30px 10px;

                .title {
                    background-image: url(#{$svg-path}/icons/ocean/ic-easy-travel.svg);
                    background-repeat: no-repeat;
                    background-size: 55px;
                    background-position: center 0;
                    padding-left: 0;
                    padding-top: 70px;
                    float: left;
                    display: block;

                    p {
                        float: left;
                        width: 100%;
                        text-align: center;
                        color: $gray-600;
                        font-size: 14px;
                        font-family: $regularFontFamily;
                        margin: 0;

                        &:first-child {
                            font-family: $boldFontFamily;
                            font-size: 16px;
                            margin-bottom: 5px;
                        }
                    }
                }

                .content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    float: left;
                    width: 100%;
                    margin: 20px 0;

                    div {
                        margin: 15px 60px;
                        font-size: 14px;
                        text-align: center;
                        background-repeat: no-repeat;
                        background-position: center 0;
                        padding-top: 50px;

                        &.passaje {
                            width: 161px;
                            background-image: url(#{$svg-path}/icons/ocean/ic-ticket.svg);

                        }

                        &.calendar {
                            width: 190px;
                            background-image: url(#{$svg-path}/icons/ocean/ic-calendar-check.svg);
                            background-size: 40px;
                        }

                        &.present {
                            width: 145px;
                            background-image: url(#{$svg-path}/icons/ocean/ic-gift.svg);
                            background-size: 40px;
                        }
                    }
                }

                .bonus {
                    width: 100%;
                    float: left;
                    margin-bottom: 20px;
                    &__content {
                        min-height: 150px;
                        border: 1px dashed $easy-travel;
                        width: 100%;
                        margin: auto;
                        text-align: center;
                        label, span, small {
                            display: block;

                        }
                        small {
                            font-size: 14px;
                            margin-top: 15px;
                        }
                        label {
                            font-family: $boldFontFamily;
                            font-size: 20px;
                            color: $easy-travel;
                        }
                        span {
                            font-family: $boldFontFamily;
                            font-size: 60px;
                            line-height: 60px;
                            margin-top: 10px;
                            color: $easy-travel;
                        }
                        &__club {
                            height: 210px;
                            &__logo {
                                width: 90px;
                                height: 60px;
                                background-color: $club;
                                border-radius: 5px;
                                position: relative;
                                float: left;
                                margin: 20px 0 0 20px;
                                label {
                                    color: $white;
                                    font-size: 30px;
                                    font-family: $boldFontFamily;
                                    letter-spacing: -1px;
                                    float: left;
                                    clear: both;
                                    margin: 8px 20px 0 17px;
                                    line-height: 30px;
                                }
                                small {
                                    background-image: url(../images/svg/logos/smiles-white.svg);
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    width: 34px;
                                    height: 18px;
                                    position: relative;
                                    float: left;
                                    clear: both;
                                    margin: -3px 0 0 28px;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        height: 9px;
                                        border-bottom: 2px solid $white;
                                        top: -2px;
                                        width: 17px;
                                        right: 100%;
                                        margin-right: 3px;
                                    }

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        height: 9px;
                                        border-bottom: 2px solid $white;
                                        top: -2px;
                                        width: 17px;
                                        left: 100%;
                                        margin-left: 3px;
                                    }
                                }
                                &::before {
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                    z-index: 3;
                                    left: 0;
                                    top: 80%;
                                    border-width: 10px;
                                    border-left-color: $club;
                                }
                            }
                            span {
                                font-family: $regularFontFamily;
                                font-size: 40px;
                                text-align: right;
                                float: left;
                                width: 50%;
                                text-decoration: line-through;
                            }
                            label {
                                &.value-club {
                                    color: $gray-600;
                                    width: 100%;
                                    font-size: 60px;
                                    float: left;
                                    line-height: 40px;
                                    margin: 20px 0 10px 0;
                                }
                                &:last-child {
                                    width: 100%;
                                    float: left;
                                    color: $gray-600;
                                }
                            }
                        }
                    }
                }

                .club-offer {
                    width: 100%;
                    float: left;
                    margin-bottom: 20px;
                    margin-top: 30px;
                    &__content {
                        padding: 30px 20px;
                        margin: auto;
                        width: 100%;
                        height: auto;
                        border: 1px solid $club;
                        &__header {
                            width: 100%;
                            height: 120px;
                            &__logo {
                                width: 120px;
                                display: block;
                                margin: auto;
                                label {
                                    color: $club;
                                    font-size: 60px;
                                    line-height: 60px;
                                    font-family: $boldFontFamily;
                                    letter-spacing: -1px;
                                    width: 100%;
                                    margin-bottom: 0;
                                    text-align: center;
                                }
                                small {
                                    background-image: url(../images/svg/logos/smiles-purple.svg);
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    width: 100%;
                                    height: 24px;
                                    position: relative;
                                    display: inline-block;
                                    margin-bottom: 20px;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        height: 9px;
                                        border-bottom: 3px solid $club;
                                        top: -2px;
                                        width: 36px;
                                        right: 76%;
                                        margin-right: 3px;
                                    }

                                    &::before {
                                        content: "";
                                        position: absolute;
                                        height: 9px;
                                        border-bottom: 3px solid $club;
                                        top: -2px;
                                        width: 36px;
                                        left: 76%;
                                        margin-left: 3px;
                                    }
                                }
                            }
                            span {
                                display: block;
                                color: $club;
                                width: 100%;
                                float: right;
                                margin: 0 0 10px 0;
                                font-size: 18px;
                                text-align: center;
                            }
                        }
                        &__info {
                            line-height: 20px;
                            padding: 0 0px;
                            font-size: 14px;
                            text-align: center;
                        }
                        &__benefits {
                            color: $club;
                            width: 100%;
                            text-align: center;
                            font-size: 14px;
                            text-decoration: underline;
                            font-family: $boldFontFamily;
                        }
                        &__terms {
                            position: relative;
                            width: 100%;
                            margin: 10px 0px;
                            label {
                                margin: 0 0 5px 20px;
                                color: $club;
                                font-size: 14px;
                                font-family: $boldFontFamily;
                            }
                            p {
                                margin: 0 0 0 20px;
                                font-size: 14px;
                                font-family: $boldFontFamily;
                            }
                        }
                    }
                }

                .accept-rules-terms {
                  text-align: center;
                  @include bms-font-size(4.6vw, 15px);
                  .form-check-label {
                    margin: 0 0 10px 0;
                    input {
                      &[type="checkbox"] {
                        ~ span {
                          &:before {
                            position: relative;
                          }
                        }
                      }
                    }
                  }
                }

                .terms {
                    float: left;
                    width: 100%;
                    text-align: center;
                    display: flex;
                    padding: 0 70px;
                    align-items: center;
                    justify-content: center;
                    label {
                        font-size: 14px;

                        input {
                            display: block;
                            margin-left: -10px;
                        }
                    }
                }
            }

            &.pay {
                border-radius: 5px 5px 0 0;
                border: 1px solid $easy-travel;
                height: 123px;
                width: 100%;
                background-image: url(#{$svg-path}/icons/ocean/ic-easy-travel.svg);
                background-repeat: no-repeat;
                background-position: 10px 12px;
                background-size: 55px;
                padding: 28px 0 0 0;

                h2 {
                    color: $easy-travel;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 29px;
                    text-align: left;
                    margin-left: 88px;
                }

                p {
                    color: $gray-700;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    text-align: center;
                    margin-top: 22px;
                }
            }
        }
    }

    .club-smiles {
        &__resumen {
            width: 100%;
            border: 1px solid $club;
            border-radius: 2px;
            padding: 5px 20px 0 20px;
            float: left;
            &__logo {
                width: 94px;
                display: block;
                margin: auto;

                label {
                    color: $club;
                    font-size: 46px;
                    line-height: 40px;
                    font-family: $boldFontFamily;
                    letter-spacing: -1px;
                    width: 100%;
                    margin-bottom: 0;
                    text-align: center;
                }
                small {
                    background-image: url(../images/svg/logos/smiles-purple.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                    height: 18px;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 2px solid $club;
                        top: -4px;
                        width: 26px;
                        right: 72%;
                        margin-right: 3px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 2px solid $club;
                        top: -4px;
                        width: 26px;
                        left: 72%;
                        margin-left: 3px;
                    }
                }
            }
            &__info {
                color: $club;
                font-size: 16px;
                float: left;
                margin: 0;
                width: 100%;
                text-align: center;
            }
            span {
                float: left;
                margin: 10px auto;
                width: 100%;
                text-align: center;
            }
        }
    }
    .easy-travel {

        &__resumen {
            width: 100%;
            border: 1px solid $default;
            border-radius: 2px;
            padding: 10px 20px;
            float: left;
            background-image: url(#{$svg-path}/icons/gray/ic-calendar-check.svg);
            background-repeat: no-repeat;
            background-position: center 10px;
            background-size: 30px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                float: left;
                font-size: 14px;
                padding: 40px 50px 10px 50px;
                margin: 0;
                text-align: center;
            }

            span {
                float: right;
                padding: 5px;
                border: 1px solid $gray-300;
                color: $default;
                font-size: 14px;
                border-radius: 3px;
            }
        }
    }

    @media (min-width: 768px) {
        &__emission-options {

            .title {
                width: 100%;
                text-align: center;
            }

            .options {
                display: flex;
                align-items: center;
                justify-content: center;

                .option {
                    height: 209px;
                    width: 400px;
                    margin: 30px 8px;

                    &:first-child {
                        &::before{
                            right: -30px;
                            top: 65%;
                            padding-left: 3px;
                        }
                    }
                }
            }
        }

        .box {
            &.easy-travel {
                width: 100%;
                margin-bottom: 20px;
                border-bottom: 2px solid $gray-300;
                padding: 0 0 30px 0;
                display: inline-block;

                .no-miles {
                    text-align: center;
                    p {
                        font-size: 14px;
                        margin: 0;
                    }
                    label {
                        font-size: 19px;
                        font-family: $boldFontFamily;
                        color: $default;
                        line-height: 22px;
                        margin: 10px auto;
                    }
                }

                .info {
                    padding: 50px 50px 0 50px;

                    .title {
                        background-position: 0 0;
                        padding-left: 70px;
                        padding-top: 0;
                        float: left;
                        display: block;

                        p {
                            float: left;
                            width: 100%;
                            text-align: left;
                        }
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: row;
                        float: left;
                        width: 100%;
                        margin: 80px 0;

                        div {
                            margin: 0 60px;
                        }
                    }

                    .bonus {
                        &__content {
                            width: 350px;
                        }
                    }

                    .club-offer {
                        &__content {
                            padding: 30px 40px;
                            width: 600px;
                            height: 340px;
                            &__header {
                                width: 100%;
                                height: 120px;
                                &__logo {
                                    width: 120px;
                                    float: left;
                                    label {
                                        color: $club;
                                        font-size: 60px;
                                        line-height: 60px;
                                        font-family: $boldFontFamily;
                                        letter-spacing: -1px;
                                        width: 100%;
                                        margin-bottom: 0;
                                        text-align: center;
                                    }
                                    small {
                                        width: 100%;
                                        height: 24px;
                                        margin-bottom: 20px;

                                        &::after {
                                            top: -2px;
                                            width: 36px;
                                            right: 76%;
                                            margin-right: 3px;
                                        }

                                        &::before {
                                            top: -2px;
                                            width: 36px;
                                            left: 76%;
                                            margin-left: 3px;
                                        }
                                    }
                                }
                                span {
                                    width: 340px;
                                    margin: 22px 20px 0 0;
                                }
                            }
                            &__info {
                                padding: 0 20px;
                            }
                            &__terms {
                                width: 400px;
                                margin: 10px 50px;
                            }
                        }
                    }

                    .terms {
                        float: left;
                        width: 100%;
                        text-align: center;
                        display: block;
                        padding: 0;

                        label {

                            input {
                                margin-left: -20px;
                            }
                        }
                    }
                }

                &.pay {
                    height: 143px;
                    width: 100%;
                    background-image: url(#{$svg-path}/icons/ocean/ic-easy-travel.svg);
                    background-repeat: no-repeat;
                    background-position: 40px center;
                    background-size: 81px;
                    padding: 31px 0 0 148px;

                    h2 {
                        font-size: 24px;
                        line-height: 29px;
                        margin-left: 0;
                    }

                    p {
                        font-size: 16px;
                        line-height: 19px;
                        text-align: left;
                        margin-top: 0;
                    }
                }
            }
        }

        .easy-travel {

            &__resumen {
                display: block;
                background-image: url(#{$svg-path}/icons/gray/ic-calendar-check.svg);
                background-position: 20px center;
                background-size: 30px;

                p {
                    float: left;
                    font-size: 14px;
                    padding: 10px 0 0 45px;
                    margin: 0;
                    text-align: left;
                }
            }
        }

        .club-smiles {
            &__resumen {
                &__logo {
                    float: left;
                }
                &__info {
                    text-align: left;
                    margin: 10px 0 0 40px;
                    width: 50%;
                }
                span {
                    text-align: left;
                    margin: 0px 0 0 40px;
                    width: 70%;
                }
            }
        }
    }
}
