.home {
  // override default padding
  &.container.one-column {
    padding-top: 20px;
  }
}
.faq {
  // override default padding
  &.container.one-column {
    padding-top: 20px;
  }
}

.go-to-top-float {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 20px;
  left: 20px;
  background: url("../../images/svg/icons/white/ic-arrow-up.svg") no-repeat center;
  background-size: 15px;
  z-index: 9000;
  background-color: $gray-900;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    left: unset;
    right: 20px;
  }
}
