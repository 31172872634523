.search-flight {
  &.not-found {
    display: block;
    width: 100%;
    height: 258px;
    margin-top: 20px;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    * {
      font-family: $boldFontFamily;
    }
    h2 {
      @include bms-font-size(4vw, 18px);
      color: $gray-700;
      margin: 20px auto 0 auto;
      padding: 0;
      &:before {
        content: "";
        display: block;
        position: relative;
        width: 100%;
        height: 90px;
        background-image: url(#{$svg-path}/misc/ic-binoculars-gray.svg);
        background-size: 85px;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    p {
      @include bms-font-size(3vw, 14px);
    }
  }
  &.booking-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid $gray-300;
    border-radius: $border-radius;
    width: 100%;
    min-height: 375px;
    background-image: url(#{$svg-path}/misc/ic-clouds-gray.svg);
    background-size: content;
    background-position: center center;
    background-repeat: no-repeat;
    .vertical-center {
      margin: auto;
    }
    h2 {
      @include bms-font-size(4vw, 16px);
      color: $gray-700;
      margin: 0;
    }
    p {
      @include bms-font-size(3vw, 14px);
    }
  }
  .congenere-message {
    padding: 20px 5px;
    border: 2px solid $primary;
    h3 {
      font-family: $regularFontFamily;
      font-weight: bolder;
      @include bms-font-size(6vw, 24px);
    }
    p {
      font-family: $boldFontFamily;
    }
  }
  .breadcrumb {
    margin: 0 0 30px;
  }

  .first-col-container {
    .container {
      width: 100% !important;
      .checkout {
        width: 100%;
      }
    }

    .checkout-sucess-g3, .checkout-sucess-congenere, .checkout-success-cancel {
      a.search-flights-caption {
        position: absolute;
        right: 200px;
        top: -45px;
        z-index: 1;
        &.easy-travel {
          top: 0px;
        }
      }
    }
  }
}

.flight-selection-timer {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 300px;
  height: 85px;
  border-radius: 3px;
  padding-left: 60px;
  background: $gray-800;
  color: $white;
  z-index: 100;
  background-image: url(#{$svg-path}/icons/white/ic-timer.svg);
  background-size: 40px;
  background-position: 10px center;
  background-repeat: no-repeat;
  p {
    margin: 20px auto 0 auto;
    @include bms-font-size(3.5vw, 14px);
  }
  .countdown {
    font-family: $boldFontFamily;
    @include bms-font-size(3.5vw, 18px);
  }
  .btn-dark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: none;
    font-family: $boldFontFamily;
    font-size: 14px;
    line-height: 14px;
    padding: 3px;
    font-weight: bold;
    &:active {
      outline: none;
    }
  }
}

.fix-header {
  position: fixed;
  top: 0;
  width: 94%;
  z-index: 10;
  &.mini-calendar {
    .box-selected-day {
      position: relative;
      display: block;
      height: 40px;
      margin-left: 5px;
      margin-right: 10px;
      background-color: $white;
      border-radius: $border-radius;
      @include bms-font-size(2.5vw, 10px);
      text-transform: uppercase;
      font-family: $boldFontFamily;
      padding: 2px;
      cursor: pointer;
      > div {
        line-height: 13px;
      }
      .day {
        @include bms-font-size(3.5vw, 18px);
      }
      .dropdown-calendar {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -7px;
        top: 14px;
        background-color: $gray-600;
        border-radius: $border-radius;
        background-image: url(#{$svg-path}/icons/white/ic-arrow-down.svg);
        background-size: 80%;
        background-position: center center;
        background-repeat: no-repeat;
        border: 1px solid $white;
      }
    }
    &.open-calendar {
      .box-selected-day {
        .dropdown-calendar {
          transform: rotate(180deg);
        }
      }
    }
    &.close-calendar {
      .dropdown-calendar {
        transform: rotate(0deg);
      }
    }
    .arrow {
      width: 20px !important;
    }
    .flight-city {
      .city {
        display: block;
        padding: 0;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .flight-info {
    padding-left: 10px !important;
    &:before {
      display: none !important;
    }
  }
}
.fix-calendar {
  position: fixed;
  width: 94%;
  z-index: 10;
  .carousel-search-flights,
  .group-header {
    display: none;
  }
}
.fix-booking {
  width: 365px;
}

.box-selected-day {
  display: none;
}

@media (min-width: 768px) {
  .fix-header {
    width: 810px;
    .flight-info {
      padding-left: 50px !important;
      &:before {
        display: block !important;
      }
    }
    &.mini-calendar {
      .arrow {
        width: 100px !important;
      }
      .flight-city {
        .city {
          display: initial;
          width: auto;
        }
      }
      .box-selected-day {
        margin-left: 0;
        width: 60px;
        height: 50px;
        > div {
          line-height: 16px;
        }
        .dropdown-calendar {
          right: -7px;
          top: 17px;
        }
      }
    }
  }
  .fix-calendar {
    width: 810px;
  }
}
