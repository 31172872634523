.card-resume {

    & + & {
        margin-top: 20px;
    }

    // desktop version
    .checkout__installment__resumen {
        @include media-large-maxw {display: none}
    }

    // mobile version
    .two-cards-form__box.complete-data.resume {
        @include media-large {display: none}
    }

}