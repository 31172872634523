.container {
    .luggage-simple__container {
        margin-top: 3rem;
        margin-bottom: 3rem;
        padding: 1.5rem;

        @media only screen and (max-width: 768px) {
            padding: 3rem 1.5rem 1.5rem 1.5rem;
        }

        .club-adhesion-simple__title {
            padding: 0 0 0.5rem 0;

            h4 {
                font-size: 28px;
                font-family: $regularFontFamily;
                float: left;
                font-weight: bold;
            }
        }

        .order-in-progress__container {
            padding: 0px;
            border: 1px solid $gray-500;
            display: flex;
            flex-direction: row;

            .order-in-progress__left {
                background-color: $primary;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 10px;
                height: 195px;

                .inprogress {
                    background: url(#{$svg-path}/icons/white/ic-gear.svg) no-repeat center;
                    width: 100%;
                    height: 60px;
                    margin-bottom: 10px;
                    display: block;
                    background-size: 60px;
                }

                h2 {
                    color: $white;
                    text-align: center;
                    margin-bottom: 0px;
                }
            }

            .order-in-progress__right {
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 15px 25px;
                height: 195px;
                text-align: center;

                p {
                    margin-bottom: 10px;
                }

                a {
                    border: 0px solid;
                    background-color: $primary;
                    margin-top: 0px;
                    float: right;
                    padding: 8px 45px;
                    height: 35px;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            .order-in-progress__container {
                flex-direction: column;
            }
        }
    }
}