.success-container{
    p{
        font-size: 18px;
    }
    span{
        font-size: 18px;
        text-align: center; 
    }
    .miles{
        &__color{
            color: $primary;
        }
    }
    .img-success{
        width: 100%;
        height: 60vh;
        background-image: url(../images/jarvis-success/jarvis-success-desktop.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .btn-jarvis-goto{
        height: 33px;
        width: 175px;
    }
    .btn-jarvis-goto-jarvis{
        background-color: $white;
        border: 1px solid $black;
        color: $black;
    }
    .btn-jarvis-goto-jarvis:hover{
        background-color: $gray-300;
    }
}

@media only screen and (max-width: 575px){
    .success-container{
        .img-success{
            height: 40vh;
        }
    }
}