.modal-club-smiles-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(29, 29, 29, 0.36000001430511475);
  z-index: 99;

  justify-content: center;
  align-items: center;
}
.modal-club-smiles-modal {
  background-color: #ffffff;
  width: 571px;
  min-height: 323px;
  max-width: 95%;
  max-height: 100%;
  .title {
    margin-top: 80px;
    margin-bottom: 35px;
    display: block;
    /* margin: 0 auto; */
    color: #ff5a00;
    text-align: center;
    font-size: 27px;
    font-weight: 700;
    line-height: 32px;
  }
  .content {
    color: #7c7c7c;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    width: 467px;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .align-self-end {
    margin: 27px 37px;
  }
}
