.box-user-signout {
    .box-logged {
        @include bms-font-size (14px, 14px);
        @include bms-line-height(18px, 18px);
        position: relative;
        width: 100%;
        height: 43px;
        background-color: $white;
        border-radius: 0;
        padding: 8px 0 0 43px;
        cursor: pointer;
        border-bottom: 1px solid $gray-400;
        strong, span {
            display: block;
        }
        strong {
            font-family: $boldFontFamily;
        }
        span {
            @include bms-font-size (11px, 11px);
            @include bms-line-height(15px, 15px);
            font-family: $ligthFontFamily;
            font-weight: 800;
        }
        button {
            @include bms-font-size (32px, 32px);
            @include bms-line-height(32px, 32px);
            font-family: $secondaryFontFamily;
            position: absolute;
            right: 10px;
            top: 5px;
            border: none;
            background-color: transparent;
            color: $primary;
        }
        &.category-diamante,
        &.category-ouro,
        &.category-prata,
        &.category-smiles {
            background-repeat: no-repeat;
            background-position: 10px center;
        }

        &.category-diamante {
            background-image: url(#{$svg-path}/categories/ic-category-diamond.svg);
            .user-info {
                color: $diamond;
            }
        }

        &.category-ouro {
            background-image: url(#{$svg-path}/categories/ic-category-gold.svg);
            .user-info {
                color: $gold;
            }
        }

        &.category-prata {
            background-image: url(#{$svg-path}/categories/ic-category-silver.svg);
            .user-info {
                color: $silver;
            }
        }

        &.category-smiles {
            background-image: url(#{$svg-path}/categories/ic-category-smiles.svg);
            .user-info {
                color: $smiles;
            }
        }

        .member-id-dyna {
          display: none;
        }
    }

    .box-logout {
        display: none;
    }

    .box-logged-mobile {
        position: fixed;
        width: 100%;
        border-radius: 0;
        border: 0;
        top: 128px !important;
        left: 0 !important;
        padding: 0;
        height: calc(100% - 123px);
        background: transparent;
        display: block;
        margin: -5px 0 0 0;
        cursor: pointer;
        &.disable {
            display: none;
        }

        .opacity {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            background: $black;
            opacity: 0.4;
        }

        .content {
            position: relative;
            overflow-y: auto;
            height: 100%;
            background: $white;
            width: 270px;
            z-index: 20;
            float: left;
            display: inline-block;
            font-size: 14px;

            ul {
                list-style: none;
                margin-left: 10px;
                float: left;
                padding: 0;
                clear: both;

                li {
                    color: $default;
                    line-height: 14px;
                    margin-bottom: 0;
                    margin-top: 15px;
                    font-weight: bold;

                    a {
                        color: $gray-600;
                        font-weight: 100;
                        line-height: 14px;
                        text-decoration: none;
                    }
                }
            }

            a.logout {
                color: $gray-700;
                padding: 20px 0 20px 10px;
                display: block;
                cursor: pointer;
                text-align: left;
                border-top: 1px solid $gray-300;
                float: left;
                clear: both;
                width: 100%;
            }
        }
    }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .box-user-signout {
        .box-logged {
            min-width: 170px;
            max-width: 275px;
            height: 50px;
            overflow: hidden;
            cursor: pointer;
            border-radius: $border-radius;
            .user-info {
              strong {
                display: block;
                white-space: nowrap;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
        }
        .box-logout {
            display: block;
            margin-left: 18px;
            width: 40px;
            max-width: 40px;
            button {
              width: 100%;
              padding-top: 40px;
              font-size: 10px;
              font-family: $boldFontFamily;
              color: $white;
              height: auto;
              overflow: hidden;
              background-color: transparent;
              text-align: left;
              border: none;
              background-image: url(#{$svg-path}/icons/white/ic-logout.svg);
              background-size: 30px;
              background-position: 3px 10px;
              background-repeat: no-repeat;
            }
        }

        .box-logged-mobile {
            display: none;
        }
    }
}
