// payment options fix
.checkout__installment__type-payment {
  @include media-large-maxw {
    padding: 20px 16px;
  }

  & > h2 {
    margin-bottom: 28px;
  }

  ul {
    height: auto;

    li {
      display: inline-block;
      float: none;
    }
  }

  @include media-large-maxw {
    ul {
      li {
        padding: 20px 0;
        width: 100%;
        & + li {
          border-top: 1px solid #f2f2f2;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.checkout__payment__methods {
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  margin-left: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0px;
    align-items: center;
  }
}

.two-cards-form {
  --gap-x1: 12px;
  --gap-x2: calc(var(--gap-x1) * 2);

  padding-top: var(--gap-x2);

  @include media-large {
    display: flex;
  }

  &__box {
    border: 1px solid #d8d8d8;
    padding: 20px 30px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-large-maxw {
      padding: 20px 16px;
      &:first-child {
        margin-bottom: 24px;
      }
    }

    @include media-large {
      width: calc(50% - var(--gap-x1));

      &:nth-child(odd) {
        margin-right: var(--gap-x2);
      }
    }

    h3 {
      font-family: $regularFontFamily;
      font-size: 20px;
      color: $gray-700;
      margin-bottom: 12px;

      @include media-large {
        font-size: 24px;
      }
    }

    p {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 6px;
    }

    .sm-debounce {
      display: inline-block;
      position: relative;

      &__input {
        position: relative;

        &.hidden {
          display: none;
        }

        span {
          position: absolute;
          left: 12px;
          top: 5px;
        }

        input {
          border: 1px solid #d6d6d6;
          border-radius: 2px;
          width: 202px;
          height: 30px;
          padding-left: 34px;
          background: none;

          &:disabled {
            -webkit-text-fill-color: #bdbdbd;
          }
        }

        span,
        input {
          font-family: $boldFontFamily;
          font-size: 14px;
          color: $gray-700;
        }

        &.disabled {
          span,
          input {
            color: #bdbdbd;
          }
        }
      }
    }

    .inner-card-wrapp {
      min-height: 56px;
      @include media-large {
        align-items: flex-end;
        display: flex;
      }

      .sm-card-icon {
        display: inline-block;
        position: relative;
        top: 1px;
        @include size(78px, 46px);
        @include icon-pos(before, $card-icon);
        margin-right: 10px;
        @include media-large {
          margin-right: 30px;
        }
        @include media-large-maxw {
          &::before {
            background-position: top;
          }
        }
        &.active {
          @include icon-pos(before, $card-icon--dark);
        }
      }
    }

    &.input-error {
      .sm-debounce__input {
        input {
          border-color: #eb4a4a;
        }
        input,
        span {
          color: #eb4a4a;
        }
      }
      .sm-card-icon {
        position: relative;
        @include min-width(384px) {
          top: -19px;
        }
      }
      .error-message {
        font-family: $ligthFontFamily;
        font-size: 10px;
        color: #eb4a4a;
        margin: 0;
      }
    }

    //FirstCard.js
    //completed card data
    &.complete-data {
      padding-bottom: 6px;

      .head {
        font-family: $boldFontFamily;
        font-size: 24px;
        color: $gray-700;
      }

      .sub-head {
        font-family: $regularFontFamily;
        font-size: 14px;
        font-weight: 400;
        color: $gray-700;
        margin-bottom: 10px;
      }

      .total {
        font-family: $regularFontFamily;
        font-size: 20px;
        font-weight: 400;
        color: #707070;
      }

      .payments {
        font-family: $regularFontFamily;
        font-size: 12px;
        font-weight: 400;
        color: #707070;
      }

      .change-payment-method {
        position: relative;
        display: inline-block;
        @include icon-pos(before, $pencil-icon, 12px, 12px, left, 0, top, 5px);
        text-decoration: underline !important;
        padding-left: 16px;
        font-family: $regularFontFamily;
        font-size: 14px;
        margin: 10px 0;
        color: $default !important;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.7;
        }

        @include media-large {
          margin: 0;
          @include pos-absolute(right, 18px, top, 18px);
        }
      }

      .card-payment-data {
        display: flex;
        flex-wrap: wrap;

        .card-img-holder {
          @include size(50px, 50px);
          padding: 2px;
          border: 1px solid $default;
          margin-right: 5px;

          & ~ .card-img-holder {
            margin-right: 20px;
          }

          img {
            @include size;
            object-fit: contain;
          }
        }

        p {
          font-family: $boldFontFamily;
          font-size: 34px;
          color: #d6d6d6;
          line-height: 42px;
          margin-bottom: 0;
          padding-top: 14px;

          @include media-large-maxw {
            font-size: 18px;
          }
        }
      }
    }

    // InputSecondCardForm.js
    &.second-card {
      .sm-debounce {
        p {
          color: #bdbdbd;
        }

        &__input {
          span,
          input {
            color: #bdbdbd;
          }
        }
      }
    }

    //--
    // when first card is completed
    // modifies the second card box
    // - - InputSecondCardForm.js
    //--
    &.complete-data ~ .second-card {
      justify-content: initial;

      h3 {
        font-weight: bold;
        margin-bottom: 41px;
      }

      .inner-card-wrapp {
        .sm-card-icon {
          @include icon-pos(before, $card-icon--dark);
        }
      }

      .sm-debounce {
        p {
          color: $gray-700;
        }

        &__input {
          span,
          input {
            color: $gray-700;
          }
        }
      }
    }
  }
}
