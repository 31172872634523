.switch-club-plan-promo {
  .rules-legal-terms {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding-top: 20px;
    margin: 0 0 40px;
    border-top: 1px solid $gray-700;

    hr {
      color: $default;
      margin-bottom: 0;
      border-color: $default;
      margin-top: 40px;
    }

    .container-show-full-content {
      position: relative;
      text-align: center;

      a:not([href]):not([tabindex]) {
        text-decoration: none;
      }

      .bt-full-content.open {
        display: inline-block;
      }

      .bt-full-content {
        text-decoration: none;
        padding: 10px 15px;
        border: 1px solid #f47920;
        border-radius: 3px;
        color: #f47920;
        font: 14px Arial, sans-serif;
        margin-top: 0px;
        display: none;
        position: absolute;
        z-index: 2;
        background: $white;
        cursor: pointer;
        width: 320px;
        left: calc( 50% - 160px);
        top: -18px;
      }
    }

    .terms{
      height: 100px;
      overflow-y: hidden;
      pointer-events: none;

      &:active {
        height: auto;
      }
    }
  }

  .align-self-center {
    margin: 0 auto;
  }

  .tems-wrapper {
    margin: 0 auto;
    text-align: left;

    h5 {
      color: $gray-700;
      font-size: 1em;
      font-family: $regularFontFamily;
      font-style: italic;
    }

    &.reactive {
      margin-top: 40px;
    }
  }

  h3 {
    color: $club;
    font-size: 1.2em;
    width: 100%;
    text-align: left;
    border-left: 3px solid $club;
    padding: 20px;
    margin: 20px 0;
  }

  padding-top: 0 !important;

  &__header {
    .banner {
      background-color: $gray-400;
      height: 400px;
    }

    .head-line {
      background-color: $club;
      color: $white;
      padding: 10px 0;
    }
  }

  .my-account__header-info {
    border: 1px solid $club;
    font-size: 14px;
    height: auto;
    margin: 20px auto;
    padding: 20px;

    box-shadow: none;

    .club-information {
        display: flex;

        &__not-logged {
          text-align: left;
          padding: 20px 0 20px 10%;

          p {
            margin: 0 0 5px;
            font-family: $regularFontFamily;
          }

          h4 {
            color: $club;
            font-size: 1.5em;
            margin-top: 10px;
          }

          button {
            font-family: $regularFontFamily;
            width: auto;
          }
        }

        &__info {
          padding: 20px 60px 20px 50px;
          float: right;
          text-align: left;
          border: none;

          span {
            display: block;
            font-size: 14px;
            margin-top: 2px;

            &.label {
              color: $club;
              margin-bottom: 5px;
            }
          }

          &__plan {
            margin: 0;
            padding: 10px 20px;
            display: block;
            clear: both;
            width: auto;
          }
        }

        border-right: 1px solid $club;
    }

    .club-information__state {
      padding: 7px 0px 7px 50px;
      text-align: left;
      height:100%;

      .club-information__info__status{
        margin: 4px 0;
        border: none;
        padding: 0;
        background-position: 2px 5px;
        width: 130px;
        height: 30px;

        &.suspended {
          color: $red;
          background-image: none;

          &::before {
            content: "!";
            margin-right: 5px;
            width: 24px;
            height: 24px;
            border: 2px solid $red;
            display: inline-block;
            border-radius: 50%;
          }
        }
      }
    }

    .btn {
      background-color: $club;
      color: $white;
      width: 200px;
      margin: 10px 0;
    }
  }

  .subtitle {
    margin: 40px auto 60px;
    font-size: 1.2em;
  }

  .club-options-tabbed {
    width: 100%;
    font-family: $boldFontFamily;
    margin: 20px auto;
    display:flex;
    flex-wrap: wrap;

    button.tab-button {
      background-color: $club;
      color: $white;
      border: 1px solid $white;
      border-bottom: none;
      border-radius: 0;
      display: inline-block;
      width: 18%;
      text-transform: uppercase;
      padding: 10px 0;
      height: 50px;
      font-size: 18px;

      &:first-of-type {
        border-radius: 10px 0px 0px 0px !important;
      }

      &:last-of-type {
        border-radius: 0px 10px 0px 0px;
        width: 28%;
      }

      &.small-size {
        width: 15%;
        &:last-of-type {
          width: 25%;
        }
      }

      &.equal-size {
        width: 20%;
        &.small-size {
          width: 16.6%;
        }
      }

      &.active {
        background-color: $club;
        color: $club;
        border: 1px solid $club;
        border-radius: 0px 10px 0px 0px;
        position: relative;
        font-size: 18px;
        height: 50px;

        &:not(.tab-button.active:first-of-type):not(.tab-button.active:last-of-type){
          border-radius: 0px;
        }

        .tab-button__inner {
          padding: 6px 15px 15px;
          background: $purple-200;
          color: $club;
          border-right: 2px solid $white;
          border-left: 2px solid $white;
          border-top: 2px solid $white;
          position: relative;
          border-radius: 10px 10px 0px 0px;

          span {
            text-transform: none;

            &::after {
              background-image: url(../../images/svg/icons/purple/ic-smiles-connector.svg);
            }
          }

          @media screen and (max-width: 768px) {
            position: relative;
            top: -2px;
          }
        }

        &.diamond {
          .tab-button__inner {
            background-color: $diamond !important;
            color: $white !important;

            span::after {
              background-image: url(../../images/svg/icons/white/ic-smiles-connector.svg);
            }
          }
        }
      }

      .tab-button__inner {
        span {
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 15px;
            background-image: url(../../images/svg/icons/white/ic-smiles-connector.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            background-position-y: -7px;
            position: relative;
            top: 16%;
          }

          &:last-of-type {
            &::after {
              content: none;
            }
          }
        }
      }

      &.diamond {
        .tab-button__inner {
          .new::after {
            content: "NUEVO";
            display: inline-block;
            width: max-content;
            height: auto;
            background: $default;
            padding: 2px 6px;
            background-image: none !important;
            font-size: 10px;
            position: relative;
            top: -10%;
            right: -3%;
          }
        }
      }

      .ic-arrow-down {
        display: none;
      }
    }

    section {
      flex: 1;
      order: 5;
    }

    section > div {
      float  : right;
      width  : 100%;
      border: 2px solid $club;
      display: inline;
      padding: 20px;
      border-radius: 0 0 30px 30px;
      background: $purple-100;
    }

    .sign-in-legals {
      display: flex;
      justify-content: flex-end;

      p {
        font-size: 10px;
        line-height: 130%;
        text-align: left;
        width: 80%;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      @media screen and (max-width: 768px) {
        padding-bottom: 40px;
        justify-content: center;

        p {
          text-align: center;
        }
      }
    }
  }

  .sign-in-frame {
    min-height: 300px;
    border: none;
    margin: 0px;
    display: grid;
    position: relative;
    grid-template-columns: 20% 80% 0%;
    background: $white;
    border-radius: 20px;
    box-shadow: 5px 5px 1px 0px rgba(155,155,155,0.3);

    &.bonus {
      grid-template-columns: 20% 50% 30% !important;
    }

    &.confirmation {
      grid-template-columns: 25% 75% 0%;

      &.bonus {
        grid-template-columns: 25% 55% 20%;
      }

      .miles-amount {
        span {
          font-weight: 700;
        }
      }

      .miles-bonus {
        span, p {
          font-weight: 700;
        }

        &--separator::before {
          right: 44%;
        }
      }

      .miles-list {
        &__promo {
          p {
            font-size: 10px !important;
          }
          span {
            font-weight: 700;
            font-size: 10px !important;
          }
        }
      }

      .button-container {
        padding: 10% 10% !important;
        background: $club;
        border-radius: 16px;

        button {
          font-size: 20px;
          align-items: center;
        }

        &.diamond {
          background: $diamond;
        }
      }

      @include media-large {
        .miles-per-month {
          .logo {
            small {
              &::before {
                left: 2%;
              }
              &::after {
                right: 4%;
              }
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        .miles-bonus--total {
          text-align: center;
          line-height: 120%;
        }
        .button-container {
            padding: 1% 25% !important;
        }
      }
    }

    button {
      &:disabled{
        background-color: $gray-300;
        color: $club;
        cursor: not-allowed;
      }

      margin: 2.1% 20px ;
      background-color: $default;
      border: 0px;
      border-radius: 12px;
      color: $white;
      padding: 10px 20px;

      span {
        font-family: $regularFontFamily;
        clear: both;
        display: block;
        font-size: 14px;
        line-height: 14px;
        margin-top: 4px;
      }
    }

    .text {
      color: $club;
      font-family: $boldFontFamily;
      font-size: 18px;

      span.bonus {
        color: $default;
      }
    }

    .miles-per-month {
      position: relative;

      .logo {
        width: 100%;
        background-color: $club;
        float: none;
        height: 50%;
        margin: 0;
        display: flex;
        padding: 17px 14px;
        border-radius: 20px 0px 0px 0px;
        flex-direction: column;
        justify-content: center;

        &.diamond {
          background-color: $diamond;
        }

        label {
          font-family: $boldFontFamily;
          color: $white;
          font-size: 1.8rem;
          letter-spacing: 2px;
          padding: 0;
          width: 100%;
          text-align: center;
          line-height: 1.7rem;
          margin-bottom: 10px !important;
        }

        small {
          @include base-logo();
          margin: 0;

          @include media-large {
            &::before {
              left: 10%;
            }
            &::after {
              right: 12%;
            }
          }
        }
      }

      .miles-amount {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        color: $white;
        height: 50%;
        background: $purple-300;
        border-radius: 0 0 0 20px;
        border-top: 1px dashed;

        p {
          font-size: 0.8rem;
          text-transform: uppercase;
          max-width: 30%;
          margin-left: 5px;
          line-height: 120%;
          text-align: left;
          margin-bottom: 0px;
        }

        .miles {
          font-size: 35px;
        }

        @media screen and (max-width: 768px) {
          border-radius: 0px !important;
          padding: 20px 10px;
        }
      }

      span.float-icon {
        background-image: url(../../images/svg/icons/white/ic-add.svg);

        @media screen and (max-width: 768px) {
          right: 45%;
          position: absolute;
          top: 91%;
        }
      }
    }

    .miles-bonus {
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $white !important;

      &--content {
        text-align: left;

        span {
          font-size: 35px;
          color: $default;
        }

        p {
          font-size: 1.8rem;
          color: $default;
        }
      }

      &--separator {
        display: flex;
        align-items: center;
        min-width: 100% !important;
        justify-content: flex-end;

        button {
          margin-right: 0px;
        }

        &::before {
          content: "";
          height: 2px;
          min-width: 32% !important;
          display: inline-block;
          position: absolute;
          z-index: 1;
          right: 42%;
          background: $default;
        }
      }

      &--total {
        font-size: 1.4rem;
        margin-top: 20px;
        color: $club;
      }

      @media screen and (max-width: 768px) {
        .miles-bonus{
          &--separator{
            margin: 20px 0px 10px;

            &::before {
              content: "";
              min-width: 25% !important;
              right: 65%;
            }

            &::after {
              content: "";
              display: inline-block;
              width: 25%;
              height: 2px;
              background: $default;
              position: absolute;
            }

            &--total {
              line-height: 130%;
            }

            button {
              margin: 0 auto;
            }
          }

          &--content {
            text-align: center !important;
            line-height: 200%;
            padding-top: 20px;

            p {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .miles-list {
      padding: 0px;
      margin: 20px;
      border-radius: 15px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: 75% 25%;
      position: relative;
      -webkit-box-shadow: inset 0px 0px 0px 3px $default;
      -moz-box-shadow: inset 0px 0px 0px 3px $default;
      box-shadow: inset 0px 0px 0px 3px $default;

      button {
        &.cross {
          position: absolute;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -28px;
          top: -17px;
        }
      }

      &__prices {
        padding: 5px;
        border-radius: 10px 10px 0px 0px;
        background: $club;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400 !important;
          font-size: 18px !important;
          color: $white;
          margin-bottom: 1px;
          padding-left: 5px;

          span {
            font-size: 18px;
            margin-left: 6px;
            color: $white;
            font-weight: 600 !important;
          }
        }
      }

      &__promo {
        grid-column-start: 1;
        grid-column-end: 3;
        font-size: 11px;
        padding: 15px 0px;
        background-color: $default;
        border-radius: 0px 0px 15px 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        span,p {
          font-size: 12.8px;
          color: $white;
        }

        p {
          padding-left: 10px;
          margin-bottom: 0px;
          margin-right: 5px;
        }

        span {
          padding: 7px 10px;
          background: $club;
          border-radius: 5px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: 20px 10px;
        background: $white;

        &__prices {
          padding: 30px 0px;

          p {
            margin-bottom: 10px !important;
          }

          p, span {
            font-size: 16px !important;
          }
        }

        &__promo {
          flex-direction: column;

          p {
            margin-bottom: 5px;
          }
        }

        button.cross {
          right: -3px !important;
        }
      }
    }

    span.float-icon {
      width: 35px;
      height: 35px;
      background-color: $default;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      display: inline-block !important;
      border-radius: 50%;
      position: absolute;
      right: -16px;
      top: 44%;
    }
  }

  @media (max-width: 330px) {
    .sign-in-frame {
      margin: 40px 10% 0px !important;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px !important;

    .subtitle {
      margin: 40px 10px 60px auto;
    }

    &.has-user-menu {
      margin-top: 62px !important;
    }

    &__header {
      img {
        width: 100%;
        height: auto;
      }

      .head-line {
        padding: 20px 0;
      }
    }

    .my-account__header-info {
      width: 100%;
      padding-top: 0;
      border: none;
      box-shadow: 0 1px 3px 0 $gray-400;

      .club-information {
        border:none;
        padding-top: 80px;

        &__logo {
          border-top: 2px solid $club;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100px;

          label {
            font-size: 3em;
            margin-top: -5px;
            color: $club;
          }

          small {
            background-size: 37%;
            background-position-x: center;
            margin-top: -13px;
            margin-left: 0;

            &::before {
              width: 27%;
              margin-left: 0px;
              border-bottom: 2px solid $club;
              top:2px;
              border-radius: 1px
            }

            &::after {
              width: 27%;
              margin-right: 0px;
              border-bottom: 2px solid $club    ;
              top:2px;
              border-radius: 1px
            }
          }
        }

        &__info {
          padding: 20px 0;

          span.label {
            font-size: 1.1em;
          }

          &__plan {
            width: 100%;
            font-size: 2em;
            text-align: center;
            padding: 28px 0;
            font-weight: bold;
            margin: 10px 0;
          }
        }

        &__state {
          padding: 20px 0;
          border-top: 2px solid $club;

          .club-information__info__status {
            margin:30px 0 20px;
          }

          span.label {
            color: $club;
          }

          button.btn {
            width: 60%;
            display: block;
            clear: both;
            margin: 30px auto 10px;
          }
        }
      }
    }

    .club-options-tabbed {
      max-width: 95%;

      .text-center {
        margin: auto;
      }

      section {
        order: unset;
      }

      & > button.tab-button {
        background-color: $club;
        color: $white;
        border: none;
        border-radius: 10px;

        width: 100% !important;
        text-transform: uppercase;
        margin-bottom: 2px;
        padding: 0 25px;
        text-align: left;

        &.active {
          background-color: $club;
          color: $white;
          border: none;
          border-bottom: none;
          border-radius: 10px 10px 0px 0px !important;

          &.diamond:last-of-type .tab-button__inner {
            padding: 0px !important;
            border: 0px !important;
            position: relative;
            top: 4% !important;
          }

          .ic-arrow-down {
            transform: rotate(180deg);
          }
        }

        &.diamond {
          background-color: $diamond !important;
          .tab-button__inner {
            background-color: $diamond !important;
          }
        }

        &:last-of-type,
        &:first-of-type {
          border-radius: 10px !important;
        }

        .ic-arrow-down {
          display: inline-block;
          float: right;
          transition-duration: 0.8s;
          transition-property: transform;
        }
      }

      & > section > div {
        overflow: hidden;
        margin : 2px 0 0 0;
        border: none;
        -webkit-transition: max-height 0.5s;
        -moz-transition: max-height 0.5s;
        -ms-transition: max-height 0.5s;
        -o-transition: max-height 0.5s;
        transition: max-height 0.5s;
        padding: 0;
      }

      .sign-in-container {
        margin-bottom: 10px !important;
      }
    }

    .sign-in-frame {
      flex-direction: column;
      margin: 20px 10% 20px;
      background-color: $white;
      position: relative;
      display: flex;

      .logo {
        width: 100%;
        border-radius: 20px 20px 0px 0px !important;

        small {
          width: 160px;
          margin: 0 auto;
        }
      }

      .text {
        padding: 20px 0;
        line-height: 20px;

        span {
          display: block;
          width: 100%;
        }
      }

      button {
        margin: 10px auto;
      }
    }
  }

  &.change-plan {
    .tab-button__inner {
      span {
        font-size: 15px;
      }
    }

    .miles-amount .miles {
      font-size: 1.9rem !important;
    }

    .miles-bonus--content {
      span {
        line-height: 120%;
        display: inline-block;
        margin-bottom: 10px;
      }
    }

    .monthly-plan span {
      font-size: 13px;
    }

    .annual-plan span {
      font-size: 12px;
    }

    .annual-plan-subscription p {
      left: -36%;
    }

    .miles-list {
      &__promo {
        p {
          font-size: 8px;
        }
        span {
          font-size: 8px;
        }
      }
    }

    @include media-large {
      .miles-per-month {
        .logo {
          small {
            &::before {
              left: 2%;
            }
            &::after {
              right: 4%;
            }
          }
        }
      }
    }
  }
}

.miles-change-plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $gray-200;
  border-radius: 0px 20px 20px 0px;

  div {
    display: flex;
    align-items: center;

    button {
      margin-left: 0px !important;
      margin-right: 5px !important;
      padding: 10px !important;
      background: $default !important;
      color: $white !important;
    }

    span {
      display: flex;
      font-size: 17px;
    }

    p {
      margin-bottom: 0px;
      margin-left: 5px;
      text-transform: uppercase;
      font-size: 8px;
    }

    &.monthly-plan {
      margin-bottom: 10px;

        span,p {
        color: $default;
      }
    }

    &.annual-plan {
      padding: 0px 6px;
      background: $club;
      border-radius: 16px;
      color: $white;

      &.diamond {
        background: $diamond;
      }
    }
  }

  .annual-plan-subscription {
    display: flex;
    flex-direction: column;
    position: relative;

    p {
      position: relative;
      left: -47%;
      text-transform: inherit !important;
      margin: 0px;

      &:not(.subscription) {
        color: $diamond;
      }

      &.subscription {
        padding: 5px 10px;
        font-size: 10px !important;
        background: $club;
        color: $white;
        display: flex;
        align-items: center;
        border-right: 5px dotted;
        border-left: 5px dotted;

        &.diamond {
          &::before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            background-image: url(../../images/svg/icons/white/ic-diamond-client.svg);
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 5px;
          }
        }
      }
    }

    &.diamond {
      .subscription {
        background: $diamond;
      }
    }
  }

  button {
    margin: 2.1% 20px;
    background-color: $default;
    border: 0px;
    border-radius: 12px;
    color: $white;
    margin-left: 0px !important;
    margin-right: 5px !important;
    padding: 10px !important;
  }

  @media screen and (max-width: 768px) {
    padding: 30px 0px;
    background: $gray-200;
    border-radius: 0px 0px 20px 20px;
  }
}
