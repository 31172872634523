/*Resumen de compra de vuelo Inicio*/
.resume-emision-gol__bottom{
    padding: 0px;
    border: 1px solid $gray-500;
    background-color: $gray-200;
    border-top: 0px;
    width: 100% !important;
    display: flex;
    @media only screen and (max-width: 768px) { 
        flex-direction: column;
    }
    .resume-emision-gol__left {
        background-color: $gray-200;
        padding: 20px;
        .list-left {
            margin: 0px;
            padding: 0px;
            text-align: left;
            li {
                width: 100%;
                margin-top: 0px;
                .title{
                    font-weight: 500;
                    color: $gray-900;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                span {
                    font-size: 14px !important;
                    color: $gray-700;
                }
                span:first-child {
                    font-weight: bold;
                    margin-right: 10px;
                }
                span:last-child {
                    width: 100%;
                }
                .resume-icon-user {
                    display: inline-block;
                    width: 25px;
                    height: 23px;
                    background-size: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                }

                .resume-icon-house {
                    display: inline-block;
                    width: 25px;
                    height: 19px;
                    background-size: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/gray/ic-house.svg);
                }

                .resume-icon-phone {
                    display: inline-block;
                    width: 25px;
                    height: 18px;
                    background-size: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/gray/ic-phone-checkout.svg);
                }

                .resume-icon-email {
                    display: inline-block;
                    width: 25px;
                    height: 14px;
                    background-size: 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/gray/ic-email.svg);
                }   
            }
        }
    }
    .resume-emision-gol__right {
        background-color: $gray-200;
        padding: 20px;
        
        .list-right {
            margin: 0px;
            padding: 0px;
            text-align: left;
            li {
                width: 100%;
                margin-top: 0px;
                display: flex;
                align-items: center;
                padding-bottom: 8px;
                .title{
                    font-weight: 500;
                    color: $gray-900;
                    display: inline-block;
                    margin-bottom: 10px;
                }
                span {
                    font-size: 14px !important;
                    color: $gray-700;
                }
                span:first-child {
                    font-weight: bold;
                    margin-right: 10px;
                }
                span:last-child {
                    width: 100%;
                }
                .resume-icon-coin {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-size: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(#{$svg-path}/icons/gray/ic-coin.svg);
                }
                .footer-line {
                    font-size: 14px;
                    width: 100% !important;
                    font-weight: 500 !important;
                }
            }
        }
    }     
}
/*Resumen de compra de vuelo Fin*/
