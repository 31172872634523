.container {
    width: 1279px !important;
    max-width: 100% !important;
    .adhesion-success__container {
        padding: 20px 10px 20px 10px;
        margin: 0px;
        @media only screen and (max-width: 768px) {
            padding: 3rem 10px 20px 10px;
        }
        .alert-card {
            background-color: $primary;
            padding: 1.5rem;
            height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media only screen and (max-width: 1200px) {
                height: 250px;
            }
            @media only screen and (max-width: 991px) {
                height: 225px;
            }
            @media only screen and (max-width: 855px) {
                height: 240px;
            }
            @media only screen and (max-width: 767px) {
                height: 200px;
            }
            .alert-icon {
                background: url(../images/svg/icons/white/ic-alert-error.svg) no-repeat center;
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
                display: block;
                background-size: 60px;
            }
        }
        .success-info__container {
            padding: 1.5rem;
            background-color: $gray-200;
            height: 220px;
            @media only screen and (max-width: 1200px) {
                height: 250px;
            }
            @media only screen and (max-width: 991px) {
                height: 225px;
            }
            @media only screen and (max-width: 855px) {
                height: 240px;
            }
            @media only screen and (max-width: 767px) {
                height: revert;
            }
            .succes-info--left {
                padding: 1rem 2.5rem;
                box-sizing: border-box;
                border-right: 2px solid $gray-500;
                @media only screen and (max-width: 767px) {
                    border-right: revert;
                    border-bottom: 2px solid $gray-500;
                    text-align: center;
                    padding: 2.5rem;
                }
                span {
                    font-weight: 800;
                    line-height: 20px;
                    display: block;
                    margin-bottom: 0.5rem !important;
                }
                a {
                    text-decoration: underline;
                }
            }   
            .succes-info--right {
                padding: 1rem 2.5rem 1rem 3.5rem;
                box-sizing: border-box;
                @media only screen and (max-width: 767px) {
                    padding: 2.5rem;
                    text-align: center;
                }
                span {
                    line-height: 20px;
                    display: block;
                    margin-bottom: 1rem !important;
                }
                .btn {
                    border-radius: 5px;
                }
            }
        }
        .resume-wrapper {
            padding: 0px;
            border: 1px solid $gray-500;
            width: 100% !important;
            display: flex;
            @media only screen and (max-width: 991px) {
                flex-direction: column;
            }
            .resume-wrapper__left {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                .resume-wrapper__left--inner-top {
                    background-color: $club;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 34px 20px;
                    @media screen and (min-width: 991px) and (max-width: 1060px) {
                        padding: 45px 20px;
                    }
                    @media screen and (min-width: 1061px) and (max-width: 1071px) {
                        padding: 40px 20px;
                    }
                    @media screen and (min-width: 1072px) and (max-width: 1076px) {
                        padding: 40px 20px;
                    }
                    @media screen and (min-width: 1077px) and (max-width: 1085px) {
                        padding: 46px 20px;
                    }
                    .resume-wrapper__icon-check {
                        width: 55px;
                        height: 55px;
                        background-size: 55px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                    }
                    h4 {
                        margin: 20px 0px 20px 10px;
                        color: $white;
                        text-align: center;
                    }
                }
                .resume-wrapper__left--inner-bottom {
                    background-color: $white;
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    @media screen and (min-width: 1086px) and (max-width: 1148px) {
                        padding: 32px 20px;
                    }
                    .resume-wrapper__button-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        max-width: 280px;
                        a { 
                            .btn {
                            margin-top: 0px;
                            float: right;
                            padding: 10px 20px;
                            width: 120px;
                            border-radius: 5px;
                            }
                            .club {
                                background-color: $club;
                            }
                        }
                    }
                }
            }
            .resume-wrapper__right {
                padding: 0px;
                background-color: $white;
                .resume-wrapper__inner-top {
                    background-color: $white;
                    padding: 10px 0px;
                    height: 202px;
                    @media screen and (min-width: 991px) and (max-width: 1060px) {
                        padding: 10px 0px;
                        height: 230px;
                    }
                    @media screen and (min-width: 1061px) and (max-width: 1071px) {
                        padding: 10px 0px;
                        height: 220px;
                    }
                    @media screen and (min-width: 1072px) and (max-width: 1076px) {
                        padding: 10px 0px;
                        height: 220px;
                    }
                    @media screen and (min-width: 1077px) and (max-width: 1085px) {
                        padding: 46px 0px;
                        height: 256px;
                    }
                    @media screen and (min-width: 1086px) and (max-width: 1148px) {
                        padding: 30px 0px;
                        height: 231px;
                    }
                    .resume-list-top {
                        margin: 0px !important;
                        padding: 0px !important;
                        .text-border {
                            border-bottom: solid 2px $gray-300;
                        }

                        .text__container {
                            padding: 1.5rem 1.5rem;
                            // @media screen and (max-width: 991px) {
                            //    text-align: center;
                            // }
                            .text-club--left {
                                font-size: 16px !important;
                                font-weight: 900;
                                color: $club;
                                display: inline-block;
                                margin-right: 0.5rem;
                            }
                            .text-club--right {
                                font-size: 16px !important;
                                font-weight: 900;
                                color: $club;
                                display: inline-block;
                                margin-left: 0.5rem;
                            }
                            .text--top {
                                font-weight: 900;
                                color: $gray-900;
                                display: inline-block;
                                text-align: left;
                                @media screen and (max-width: 991px) {
                                    margin-right: 0.5rem;
                                }
                            }
                            .text--bottom {
                                font-weight: 500;
                                color: $gray-900;
                                display: inline-block;
                                // @media screen and (max-width: 991px) {
                                //     margin-left: 0.5rem;
                                // }
                            }
                        }
                        li {
                            width: 100%;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            .no-club {
                                color: $primary;
                                font-weight: 900;
                            }
                            .diamond {
                                color: $diamond;
                                font-weight: 900;
                            }
                            .club {
                                color: $club;
                                font-weight: 900;
                            }
                            span {
                                font-size: 12px !important;
                                color: $gray-700;
                            }
                            @media only screen and (max-width: 1178px) {
                                span {
                                    font-size: 12px !important;
                                }
                            }
                        }
                        hr {
                            margin: 5px 0px !important;
                        }
                    }
                    &:last-child{
                        text-align: left;
                    }
                    @media only screen and (max-width: 991px) {
                        .resume-list-top {
                            padding: 0px 0px 0px 20px;
                            li {
                                margin-top: 0px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
                .resume-wrapper__inner-bottom {
                    background-color: $gray-200;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px;
                    @media only screen and (max-width: 500px) {
                        flex-direction: column;
                    }
                    .resume-list-bottom {
                        padding: 0px 0px 0px 0px;
                        margin: 0px;
                        width: 50%;
                        @media only screen and (max-width: 991px) {
                            padding: 0px 0px 0px 5px;
                            width: 100%;
                        }
                        li {
                            width: 100%;
                            margin-top: 0px;
                            .resume-title {   
                                font-weight: 900;
                                color: $gray-900;
                                display: inline-block;
                            }
                            span {
                                color: $gray-700;
                                font-size: 11.5px;
                            }
                            .resume-icon-user {
                                margin-right: 10px;
                                display: inline-block;
                                width: 25px;
                                height: 22px;
                                background-size: 25px;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url(#{$svg-path}/icons/gray/ic-user-credit-card.svg);
                            }
                            .resume-icon-tdc {
                                margin-right: 10px;
                                display: inline-block;
                                width: 25px;
                                height: 17px;
                                background-size: 25px;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url(#{$svg-path}/icons/gray/ic-credit-card.svg);
                            }
                        }
                    }
                }
            }
        }
        .resume__banner-bottom {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
        .resume__banner-right {
            margin-left: 1.5rem;
            @media only screen and (max-width: 991px) {
                margin-left: 0rem;
            }
        }
        .table-success-adhesion__container {
            border: solid 1px $gray-500;
            margin-top: 1.5rem;
            .table-success__title {
                padding: 1.5rem 1rem 1.5rem 3rem;
                font-weight: 600;
                color: $gray-900;
                @media only screen and (max-width: 624px) {
                    padding: 0.75rem;
                }
            }
            .table-responsive {
                .table-striped {
                    margin-bottom: 0rem !important;
                    .table-head {
                        background-color: $gray-adhesion-table;
                        .table-row-head{
                            .table-title {
                                padding: 0.75rem 0.75rem 0.75rem 3rem;
                                border-bottom: 2px solid $white;
                                @media only screen and (max-width: 624px) {
                                    padding: 0.75rem;
                                }
                            }
                            .table-title-first {
                                padding: 0.75rem 0.75rem 0.75rem 4.5rem;
                                border-bottom: 2px solid $white;
                                @media only screen and (max-width: 624px) {
                                    padding: 0.75rem;
                                }
                            }
                            .table-title-last {
                                padding: 0.75rem 0.75rem 0.75rem 5.5rem;
                                border-bottom: 2px solid $white;
                                @media only screen and (max-width: 624px) {
                                    padding: 0.75rem;
                                }
                            }
                        }
                        .table-success-head{
                            color: $gray-900;
                        }
                    }
                    .table-body {
                        .table-row-body {
                            .table-title-first {
                                padding: 0.75rem 0.75rem 0.75rem 4.5rem;
                                @media only screen and (max-width: 624px) {
                                    padding: 0.75rem;
                                }
                            }
                            .table-title {
                                padding: 0.75rem 0.75rem 0.75rem 3rem;
                                @media only screen and (max-width: 624px) {
                                    padding: 0.75rem;
                                }
                                .table-success-icon {
                                        display: inline-block;
                                        background: url(../images/svg/icons/green/ic-success-adhesion.svg) no-repeat center;
                                        width: 35px;
                                        height: 25px;
                                        background-size: 25px;
                                        @media only screen and (max-width: 425px) {
                                            width: 25px;
                                            height: 20px;
                                            margin-right: 0.5rem;
                                        }
                                }
                                .text-success-icon {
                                    display: inline-block;
                                    font-size: 14px;
                                    font-weight: bold;
                                    color: $success;
                                    padding-left: 0.5rem;
                                    position: relative;
                                    bottom: 7px;
                                    @media only screen and (max-width: 624px) {
                                        font-size: 11.5px !important;
                                        padding-left: 0rem;
                                    }
                                }
                                .table-error-icon {
                                    display: inline-block;
                                    background: url(../images/svg/icons/red/ic-error-credit-card-adhesion.svg) no-repeat center;
                                    width: 35px;
                                    height: 25px;
                                    background-size: 35px;
                                    @media only screen and (max-width: 624px) {
                                        width: 25px;
                                        height: 20px;
                                        background-size: 25px;
                                        margin-right: 0.5rem;
                                    }
                                    @media only screen and (max-width: 400px) {
                                        position: relative;
                                        bottom: 10px;
                                    }
                                    @media only screen and (max-width: 374px) {
                                        position: revert;
                                        bottom: revert;
                                    }
                                }
                                .text-error-icon {
                                    display: inline-block;
                                    font-size: 14px;
                                    font-weight: bold;
                                    color: $danger;
                                    padding-left: 0.5rem;
                                    position: relative;
                                    bottom: 8px;
                                    @media only screen and (max-width: 624px) {
                                        font-size: 11.5px !important;
                                        padding-left: 0rem;
                                    }
                                    @media only screen and (max-width: 400px) {
                                        font-size: 11.5px !important;
                                        padding-left: 0rem;
                                        width: 44px;
                                    }
                                }  
                            }
                        }
                    }
                }
            }
        }
        
    }
}

 
