.segments-flights-template {
  .group-header {
    display: flex;
    align-items: center;
    color: $white;
    background-color: $gray-700;
    padding: 3vw;
    span {
      &.info-flight-template {
        cursor: pointer;
        text-transform: uppercase;
        @include bms-font-size(4vw, 12px);
        text-align: left;
        small {
          display: block;
          color: $yellow;
        }
        i {
          font-style: normal;
          @include bms-font-size(4vw, 12px);
          border: 1px solid $white;
          border-radius: 20px;
          padding: 0px 6px;
          margin-left: 10px;
          margin-left: 5px;
        }
      }
    }
  }
  .selection-flights {
    padding: 1vw 0px;
    .group-info-flights {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 30px;
      padding: 3vw 1vw;
      &:nth-child(odd) {
        background-color: $white;
      }
      &:nth-child(even) {
        background-color: $gray-200;
        .collapse-flights {
          .card {
            background-color: transparent;
            border: none;
            .flights-timeline-tamplate {
              .checkin-details-depart,
              .checkin-details-stop,
              .checkin-details-return {
                .pin {
                  background: $gray-200 url(#{$svg-path}/icons/gray/ic-pin.svg)
                    no-repeat;
                  background-position: 0 50%;
                }
              }
              .checkin-details-flight {
                .icon-flight {
                  background: $gray-200
                    url(#{$svg-path}/icons/gray/ic-airplane.svg) no-repeat;
                  background-position: 0 50%;
                }
              }
            }
          }
        }
      }
      div.viaje-facil {
        display: block;
        width: 100%;
        text-align: center;

        span {
          display: inline-block;
          background: url(#{$svg-path}/icons/orange/logo-viaje-facil.svg)
            no-repeat -12px 0;
          padding: 20px 0 0 0;
          background-size: 110px;
          left: 14px;
          font-size: 13px;
          color: $gray-700;
          text-align: center;
          margin-top: 20px;
          width: auto;
        }
      }

      .company-thumb {
        display: flex;
        width: 65px;
        height: 65px;
        box-shadow: 0 0 0 1px $gray-300;
        background: $white;
        border-radius: $border-radius;
        padding: 1vw;
        flex-basis: 20%;
        div {
          width: 100%;
        }

        span.viaje-facil {
          display: none;
        }
      }
      .group-info-travel {
        flex-basis: 80%;
        .travel-details {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .travel-origin {
            text-align: right;
          }
          .travel-info {
            color: $gray-700;
            padding: 0 5px;
            .travel-stops {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: bold;
            }
            .travel-duration {
              @include bms-font-size(4vw, 12px);
              font-weight: bold;
            }
          }
          .travel-arrival {
            text-align: left;
          }
          .travel-origin,
          .travel-arrival {
            .travel-airport {
              @include bms-font-size(6vw, 20px);
              color: $gray-800;
              font-weight: bold;
            }
            .travel-location {
              color: $gray-800;
              @include bms-font-size(5vw, 14px);
              white-space: nowrap;
              width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .travel-date-info {
              color: $gray-800;
            }
            .travel-date-info {
              font-weight: bold;
              @include bms-font-size(4vw, 14px);
            }
          }
        }
        .info-cabin {
          margin: 2vw 0;
          font-weight: bold;
          color: $gray-800;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-right: 8px;
          }
        }
        .info-seat {
          margin: 2vw 0;
          color: $primary-light-200;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-style: normal;
            border: 1px solid $primary-light-200;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
        .info-detail {
          margin-bottom: 65px;
          margin-top: 20px;
          .btn {
            color: $gray-600;
            background-color: $gray-300;
            position: absolute;
            width: 94%;
            left: 3vw;
            border-radius: $border-radius;
            display: flex;
            align-items: center;
            justify-content: center;
            .info-detail-arrow-down {
              &:before {
                content: "";
                background-image: url(#{$svg-path}/icons/gray/ic-arrow-down.svg);
                display: block;
                background-size: 100%;
                height: 12px;
                width: 12px;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .collapse-flights {
        width: 100%;
        position: relative;
        .flights-timeline-tamplate {
          display: flex;
          flex-direction: column;
          > div {
            &:before {
              content: "";
              width: 1px;
              border-left: 1px dashed $gray-600;
              position: relative;
              left: 8px;
            }
            &:last-child {
              &:before {
                border: none;
              }
            }
          }
          .checkin-details-depart,
          .checkin-details-stop,
          .checkin-details-return {
            display: flex;
            .pin {
              background: $white url(#{$svg-path}/icons/gray/ic-pin.svg)
                no-repeat;
              width: 15px;
              height: 25px;
              position: relative;
              z-index: 1;
              background-position: 0 50%;
            }
          }
          .checkin-details-depart {
            .info-depart {
              display: block;
              width: 95%;
              text-align: left;
              padding-left: 10px;
              .info-depart-hour {
                display: block;
                float: right;
                position: relative;
                &:before {
                  content: "*";
                  position: absolute;
                  top: -6px;
                  right: -6px;
                  font-size: 11px;
                }
              }
              .info-depart-city {
                display: block;
                max-width: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
              }
            }
          }
          .checkin-details-flight {
            display: flex;
            .icon-flight {
              background: $white url(#{$svg-path}/icons/gray/ic-airplane.svg)
                no-repeat;
              width: 20px;
              height: 25px;
              position: relative;
              left: -2px;
              background-position: 0 50%;
            }
            .group-details-flight {
              margin-left: 5px;
              .info-company-thumb {
                width: 100%;
                height: auto;
                div {
                  max-width: 60px;
                  min-width: 45px;
                  margin: 0 auto;
                  height: 45px;
                }
              }
              .info-number-flight {
                text-align: left;
                display: block;
                margin-top: 10px;
                span {
                  display: block;
                }
              }
            }
          }
          .checkin-details-stop {
            .info-stop {
              display: block;
              width: 95%;
              text-align: left;
              padding-left: 10px;
              .info-stop-hour {
                display: block;
                float: right;
                text-align: right;
                position: relative;
                &:before {
                  content: "*";
                  position: absolute;
                  right: -6px;
                  top: -6px;
                  font-size: 11px;
                }
                span {
                  display: block;
                  &.stop {
                    border: 1px solid $gray-500;
                    border-radius: $border-radius;
                    text-align: center;
                    padding: 1px;
                    margin-top: 4px;
                    min-width: 75px;
                    max-width: 75px;
                    float: right;
                  }
                }
              }
              .info-stop-city {
                display: block;
                max-width: 85px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
              }
            }
          }
          .checkin-details-return {
            .info-return {
              display: block;
              width: 95%;
              text-align: left;
              padding-left: 10px;
              .info-return-hour {
                display: block;
                float: right;
                position: relative;
                &:before {
                  content: "*";
                  top: -6px;
                  right: -6px;
                  position: absolute;
                  font-size: 11px;
                }
              }
              .info-return-city {
                display: block;
                max-width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
              }
            }
          }
        }
      }
      .select-payment-group {
        margin-top: 5px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        .diamond-logo {
          background-color: $diamond;
          width: 100%;
          border-radius: 4px;
          padding: 2vw;
          margin-top: 10px;
          display: flex;
          color: $gray-500;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          img {
            width: 37%;
            margin: 7px 0 0;
          }
        }
        .miles-group {
          display: flex;
          flex-direction: row;
          .miles {
            .list-group {
              .list-group-item {
                .tooltip-club {
                  left: -10px;
                  &::before {
                    left: 20px;
                  }
                }
              }
            }
          }
          .miles-money {
            .list-group {
              .list-group-item {
                .tooltip-club {
                  left: -130px;
                  &::before {
                    left: 170px;
                  }
                }
              }
            }
          }
          .miles,
          .miles-money {
            .group-header {
              background-color: transparent;
              color: $gray-800;
              font-weight: bold;
              min-height: 55px;
              align-items: center;
              padding: 0;
              span {
                &.info-flight-template {
                  @include bms-font-size(3.5vw, 14px);
                  small {
                    display: block;
                    color: $primary-light-200;
                  }
                  i {
                    border: 1px solid $gray-700;
                    font-size: 12px;
                  }
                }
              }
            }
            .list-group {
              min-width: 145px;
              .list-group-item {
                padding: 0.55rem 0.8rem;
                &.diamond {
                  background-color: $diamond;
                  .form-check {
                    label {
                      span {
                        color: $white;
                        b {
                          color: $white;
                        }
                      }
                    }
                  }
                }
                &.club {
                  background-color: $club;
                  .form-check {
                    label {
                      span {
                        color: $white;
                        b {
                          color: $white;
                        }
                      }
                      small {
                        white-space: nowrap;
                        margin-left: -20px;
                        text-decoration: none;
                        line-height: 14px;
                      }
                    }
                  }
                }
                .tooltip-club {
                  background-color: $club;
                  border-radius: 6px;
                  width: 300px;
                  height: 134px;
                  position: absolute;
                  z-index: 2;
                  top: 38px;
                  &::before {
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    z-index: 3;
                    top: -16px;
                    border-width: 8px;
                    border-bottom-color: $club;
                  }

                  &.exclusive {
                    &::before {
                      border: solid transparent;
                      border-width: 8px;
                      border-top-color: $club;
                    }
                  }

                  .logo-club {
                    width: 100%;
                    height: 40px;
                    position: relative;
                    a {
                      position: absolute;
                      right: 10px;
                      color: $white;
                      font-size: 18px;
                    }
                    label {
                      font-size: 20px;
                      color: $white;
                      margin-top: 10px;
                      margin-bottom: 0;
                      line-height: 16px;
                      width: 100%;
                      font-family: $boldFontFamily;
                      padding: 0;
                    }
                    span {
                      background-image: url(../images/svg/logos/smiles-white.svg);
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      width: 22px;
                      height: 10px;
                      position: relative;
                      display: inline-block;
                      margin-bottom: 20px;
                      margin-top: -5px;
                      &::after {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 1px solid $white;
                        top: -7px;
                        width: 14px;
                        right: 70%;
                        margin-right: 3px;
                      }
                      &::before {
                        content: "";
                        position: absolute;
                        height: 9px;
                        border-bottom: 1px solid $white;
                        top: -7px;
                        width: 14px;
                        left: 70%;
                        margin-left: 3px;
                      }
                    }
                  }

                  b {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #fbdd04;
                    font-family: $boldFontFamily;
                  }
                  span {
                    font-size: 14px;
                    line-height: 14px;
                    color: $white;
                    width: 100%;
                    display: block;
                    padding: 0 30px;
                  }
                  label {
                    font-size: 12px;
                    line-height: 12px;
                    color: $white;
                    padding: 8px;
                    font-family: $ligthFontFamily;
                  }
                }
                &.reduced-miles {
                  background-color: $easy-travel;
                  * {
                    color: $white !important;
                  }

                  .form-check-label {
                    span {
                      &:last-child {
                        margin-left: -20px;
                        margin-top: -5px;
                      }
                    }
                    input[type="checkbox"] {
                      ~ span {
                        &:before {
                          background-image: url(#{$svg-path}/icons/ui/ic-input-checkbox-green.svg);
                        }
                      }
                    }
                  }
                }
                &.grayed {
                  background-color: $club;
                  * {
                    color: $white !important;
                  }

                  .form-check-label {
                    span {
                      &:last-child {
                        margin-left: -20px;
                        margin-top: -5px;
                      }
                    }
                    input[type="checkbox"] {
                      ~ span {
                        &:before {
                          background-image: url(#{$svg-path}/icons/ui/ic-input-checkbox-green.svg);
                        }
                      }
                    }
                  }
                }
                .form-check {
                  label {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    margin-top: 3px;
                    small {
                      display: block;
                      color: $white;
                      // text-decoration-line: line-through;
                      font-size: 12px;
                      font-weight: bold;
                    }
                    span {
                      color: $primary;
                      font-weight: bold;
                      @include bms-font-size(3.5vw, 14px);
                      b {
                        color: $gray-700;
                      }
                    }
                  }
                }
              }
            }
          }
          .miles {
            margin-right: 8px;
            .list-group {
              .list-group-item {
                border-radius: 4px;
                padding: 5px 8px;
                .form-check {
                  label {
                    small {
                      font-weight: normal;
                      line-height: 10px;
                    }
                  }
                }
                &.club {
                  padding: 9px 8px;
                }
                .tooltip-club {
                  left: 0;
                  &::before {
                    left: 10px;
                  }
                  &.exclusive {
                    top: -148px;
                    &::before {
                      top: 134px;
                    }
                  }
                }
              }
            }
          }
          .miles-money {
            .list-group .list-group-item {
              .form-check {
                label {
                  span::before {
                    top: 12px;
                  }
                }
              }
              .tooltip-club {
                &.exclusive {
                  top: -148px;
                  &::before {
                    top: 134px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .view-all {
      display: block;
      text-align: right;
      margin-top: 15px;
      p {
        @include bms-font-size(4vw, 14px);
        font-weight: bold;
        color: $gray-700;
      }
    }
  }
}

.calendar-data {
  position: relative;
  display: flex;
  flex-direction: row-reverse;

  margin-top: 10px;

  @media (max-width: 767px) {
    justify-content: center;
  }

  a.calendar-toggle {
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: bold;

    @media (max-width: 767px) {
      width: 95%;
    }

    padding: 9px;
    padding-right: 75px;
    border-radius: 3px;
    font-size: 14px;

    @media (max-width: 360px) {
      font-size: 12px;
    }
    @media (max-width: 320px) {
      font-size: 10px;
    }

    background: #5b5b5b;
    color: white;

    cursor: pointer;

    img {
      margin-right: 10px;
      width: 16px;
    }

    &:hover {
      color: white;
    }
  }

  span#floating-text {
    position: absolute;
    right: -5px;

    @media (max-width: 767px) {
      right: 25px;
    }

    top: 50%;
    transform: translateY(-50%);

    padding: 3px 8px;
    font-size: 12px;

    @media (max-width: 360px) {
      font-size: 10px;
    }

    background: #ff5a00;
    color: white;

    cursor: pointer;
  }
}

.segments-graybar {
  background-color: $gray-600;
  color: $white;
  text-transform: uppercase;
  padding: 10px 15px;
  font-family: $boldFontFamily;

  dl {
    margin-bottom: 0;

    dd {
      display: inline-block;
      text-align: left;
      font-size: 12px;
      margin-bottom: 0;

      &:first-child {
        width: 30%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:last-child {
        width: 40%;
      }
      @media (max-width: 520px) {
        &:first-child {
          width: 20%;
        }
        &:nth-child(2) {
          width: 30%;
        }
        &:last-child {
          width: 50%;
        }
      }

      i {
        padding: 2px 8px;
        border-radius: 20px;
        border: 1px solid $white;
        margin-left: 10px;
        font-style: normal;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .segments-flights-template {
    .group-header {
      padding: 1vw;
    }
    .selection-flights {
      .group-info-flights {
        padding: 10px;
        div.viaje-facil {
          display: none;
        }
        .company-thumb {
          padding: 20px 5px;
          width: 180px;
          height: 80px;
          flex-basis: 15%;
          order: 1;

          span.viaje-facil {
            margin: 80px 0 0;
            display: block;
            border-left: 2px solid $default;
            background: url(#{$svg-path}/icons/orange/logo-viaje-facil.svg)
              no-repeat 10px 0;
            padding: 22px 0 0 25px;
            background-size: 100%;
            position: absolute;
            left: 14px;
            font-size: 13px;
            color: $gray-700;
          }
        }
        .group-info-travel {
          flex-basis: 35%;
          order: 2;
          .info-detail {
            display: flex;
            justify-content: center;
            .btn {
              position: relative;
              left: 0;
              width: auto;
            }
          }
        }
        .collapse-flights {
          order: 4;
          margin-top: 20px;
        }
        .select-payment-group {
          flex-basis: 50%;
          order: 3;
          .diamond-logo {
            padding: 5px;
            margin-top: 0;
            margin-bottom: 15px;
          }
          .miles-group {
            .miles,
            .miles-money {
              .group-header {
                align-items: flex-start;
              }
            }
          }
        }
      }
    }
  }

  .segments-graybar {
    dl {
      display: flex;
      dd {
        &:first-child {
          width: 60%;
        }
        &:nth-child(2) {
          display: inline-block;
          width: 15%;
        }
        &:last-child {
          display: inline-block;
          width: 25%;
        }
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .segments-flights-template {
    .group-header {
      padding: 0.4vw;
    }
    .selection-flights {
      .group-info-flights {
        .group-info-travel {
          flex-basis: 45%;
          .info-detail {
            margin-bottom: 20px;
          }
          .info-cabin {
            margin-top: 10px;
          }
          .info-seat {
            margin: 1vw 0;
          }
        }
        .collapse-flights {
          .card-body {
            padding-bottom: 10px;
          }
          .card-text {
            font-size: 12px;
          }
          .flights-timeline-tamplate {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            &:before {
              content: "";
              width: 88%;
              border-top: 1px dashed $gray-600;
              height: 1px;
              position: absolute;
              left: 50px;
              top: 12px;
            }
            > div {
              &:before {
                display: none;
              }
            }
            .checkin-details-depart,
            .checkin-details-stop,
            .checkin-details-return {
              flex-direction: column;
              align-items: center;
            }
            .checkin-details-depart {
              .info-depart {
                display: flex;
                width: 100%;
                text-align: center;
                flex-direction: column;
                padding-left: 0;
                .info-depart-hour {
                  order: 2;
                  font-size: 14px;
                }
                .info-depart-city {
                  order: 1;
                }
              }
            }
            .checkin-details-flight {
              flex-direction: column;
              align-items: center;
              .group-details-flight {
                display: flex;
                flex-direction: column;
                align-items: center;
                .info-number-flight {
                  text-align: center;
                  font-size: 14px;
                  line-height: normal;
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
            .checkin-details-stop {
              .info-stop {
                display: flex;
                width: 100%;
                padding-left: 0;
                flex-direction: column;
                align-items: center;
                .info-stop-hour {
                  float: none;
                  order: 2;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font-size: 14px;
                  line-height: normal;
                  span {
                    font-size: 12px;
                    &.stop {
                      min-width: 60px;
                      float: none;
                    }
                  }
                }
                .info-stop-city {
                  order: 1;
                }
              }
            }
            .checkin-details-return {
              .info-return {
                display: flex;
                width: 100%;
                text-align: center;
                padding-left: 0;
                flex-direction: column;
                .info-return-hour {
                  order: 2;
                }
                .info-return-city {
                  order: 1;
                  max-width: auto;
                }
              }
            }
          }
        }
        .select-payment-group {
          flex-basis: 40%;
          .miles-group {
            .miles,
            .miles-money {
              .list-group {
                .list-group-item {
                  .tooltip-club {
                    left: -15px;
                    top: 38px;
                    &::before {
                      left: 28px;
                      top: -16px;
                    }
                    &.exclusive {
                      top: -142px;
                      &::before {
                        left: 28px;
                        top: 133px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: #bcbec0;
  border-radius: 4px;
  position: relative;
  background-color: white;
  margin-left: 0 !important;
  cursor: not-allowed; // Indicar que está deshabilitado
  &:after {
    content: "X";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #e8e8e8;
  }

  @media (max-width: 768px) {
    &:after {
      font-size: 12px;
    }
  }
}
