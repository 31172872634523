.print-ticket {
    padding: 50px 5px 0px 5px;

    &__box-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;

        .title {
             
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                width: 739px;
                margin: 5px 0;

                h4 {
                    font-size: 20px;
                    color: $gray-800;
                    font-family: $regularFontFamily;
                    margin: 0;
                    padding: 0;
                }

                a {
                    color: $gray-800;
                    font-size: 14px;
                    font-family: $boldFontFamily;
                }

                .smiles {
                    width: 68px;
                    height: 28px;
                    background-image: url(../images/svg/logos/smiles-orange.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                .hidden-print {
                    display: none;
                }
            
        }

        .panel {
            
            width: 739px;

            &-heading {
                width: 100%;
                height: 61px;
                background-color: $default;
                padding: 9px 18px;
                border-radius: 5px 5px 0 0;

                .flight-locator {
                    float: left;

                    &__label {
                        color: $black;
                        font-size: 12px;
                        text-align: left;
                        font-family: $boldFontFamily;
                        float: left;
                        clear: both;
                    }

                    &__number {
                        color: $white;
                        font-size: 17px;
                        text-align: left;
                        float: left;
                        clear: both;
                    }
                }
            }

            &-body {
                padding: 20px 15px;
                border: 1px solid $black;
                border-top: 0;

                .flight-list {

                    &__item {
                        border-bottom: 1px solid $gray-200;
                        padding-bottom: 12px;
                        margin-bottom: 20px;

                        &:last-child {
                            border-bottom: 0;
                        }

                        .flight-details {
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: space-between;

                            &__date {
                                position: relative;
                                top: -5px;
                                margin-right: 20px;
                                width: 100%;
                                text-align: left;
                                flex-flow: row nowrap;

                                .date {
                                    &__week-day {
                                        text-transform: uppercase;
                                        color: $gray-600;
                                        font-size: 10px;
                                        display: inline-block;
                                    }

                                    &__day {
                                        font-size: 14px;
                                        color: $gray-800;
                                        display: inline-block;
                                        line-height: 14px;
                                        font-family: $boldFontFamily;
                                    }

                                    &__month {
                                        font-size: 14px;
                                        color: $gray-800;
                                        text-transform: uppercase;
                                        display: inline-block;
                                        font-family: $boldFontFamily;
                                    }
                                }
                            }

                            &__company {
                                margin-bottom: 20px;

                                .company {
                                    &__thumb {
                                        box-sizing: border-box;
                                        padding: 0 15px;
                                        width: 81px;
                                        height: 55px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        box-shadow: 0 0 0 1px $gray-400;

                                        .logo-airlines {
                                            background-size: contain !important;
                                            background-repeat: no-repeat;
                                            width: 75px;
                                            height: 50px;
                                            margin: auto;
                                        }
                                    }
                                }
                            }

                            &__travel {
                                width: 63%;

                                .travel {
                                    &__info {
                                        color: #999;
                                        font-size: 12px;
                                        font-weight: bold;
                                        align-items: center;
                                        justify-content: center;
                                        display: none;

                                        &.hidden-desktop {
                                            display: block;
                                            margin-top: -25px;
                                            font-family: $boldFontFamily;
                                            margin-bottom: 8px;

                                            .travel {
                                                &__stops {
                                                    text-align: center;
                                                    width: 50%;
                                                    display: inline-block;
                                                }

                                                &__duration {
                                                    display: inline-block;
                                                    width: 45%;
                                                    text-align: left;
                                                }
                                            }

                                        }

                                        .seta {
                                            &::before {
                                                content: "";
                                                width: 100%;
                                                min-width: 90px;
                                                height: 14px;
                                                display: block;
                                                background-image: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
                                                background-position: center right;
                                                background-repeat: no-repeat;
                                                margin-bottom: 1px;
                                            }
                                        }
                                    }

                                    &__details-wrapper {
                                        display: flex;
                                        width: 100%;
                                        max-width: auto;
                                        margin: auto;
                                    }

                                    &__origin {
                                        text-align: right;
                                        margin-right: 12px;
                                        width: 40%;
                                    }

                                    &__arrival {
                                        text-align: left;
                                        margin-left: 12px;
                                        width: 40%;
                                    }

                                    &__airport {
                                        color: $gray-800;
                                        font-family: $boldFontFamily;
                                        font-size: 20px;
                                        display: block;
                                        line-height: 18px;
                                    }

                                    &__location {
                                        font-size: 14px;
                                        line-height: 20px;
                                        display: block;
                                        color: $gray-800;
                                    }

                                    &__date-info {
                                        font-size: 12px;
                                        display: block;
                                    }

                                    &__stops {
                                        text-transform: uppercase;
                                    }

                                    
                                }
                            }

                            &__about {
                                color: #999;
                                font-size: 12px;    
                                text-align: left;
                                margin: 0;
                                max-width: 55%;
                                padding: 0;

                                li {
                                    margin-bottom: 5px;
                                }
                            }

                            &__extra-content {
                                margin: 20px 0 10px;
                                padding-top: 10px;

                                .flight-timeline {
                                    &__wrapper {
                                        .flight-timeline {
                                            display: flex;
                                            justify-content: space-between;
                                            flex-flow: row wrap;
                                            border-top: none;
                                            color: $gray-500;
                                            margin-top: none;
                                            margin-left: 10px;
                                            width: 100%;

                                            .timeline {
                                                &__box {
                                                    text-align: left;
                                                    width: 100%;
                                                    margin-left: 14px;
                                                    position: relative;
                                                    top: -12px;
                                                    left: -22px;

                                                    .travel {
                                                        &__origin {
                                                            display: flex;
                                                            margin-top: 0;
                                                            flex-direction: row;
                                                            justify-content: space-between;
                                                            position: relative;
                                                            top: -18px;
                                                            margin-left: 22px;
                                                        }
    
                                                        &__airport {
                                                            color: $gray-700;;
                                                            font-size: 16px;
                                                            text-transform: uppercase;

                                                            .ic-pin {
                                                                background: $white;
                                                                padding: 0 8px 0 6px;
                                                            }
                                                        }
    
                                                        &__location {
                                                            align-self: auto;
                                                            max-width: 90px !important;
                                                            text-align: center;
                                                            font-size: 12px;
                                                        }
    
                                                        &__hour {
                                                            text-align: center;
                                                            font-size: 14px;
                                                        }

                                                        &__duration {
                                                            float: right;
                                                            width: 100px;

                                                            .duration__period {
                                                                width: auto;
                                                                float: none;
                                                                font-size: 12px;
                                                                margin-bottom: 3px;
                                                                text-align: center;
                                                            } 

                                                            .hidden-desktop {
                                                                margin: 0 2px 0 0;
                                                                width: auto !important;
                                                                display: inline-block !important;
                                                                clear: none !important;
                                                                align-self: baseline;
                                                            }
                                                        }

                                                        &__duration {
                                                            font-size: 14px;

                                                            &__period {
                                                                width: auto;
                                                                float: none;
                                                                font-size: 12px;
                                                                margin-bottom: 3px;
                                                                text-align: center;
                                                            }

                                                            .hidden-desktop {
                                                                display: inline-block;
                                                                font-size: 10px;
                                                                color: $gray-700;
                                                                border: 1px solid $gray-800;
                                                                border-radius: 4px;
                                                                text-transform: uppercase;
                                                                padding: 2px 6px;
                                                            }
                                                        }

                                                        &__label {
                                                            &.hidden-phone {
                                                                display: none;
                                                            }
                                                        }
                                                    }
                                                }

                                                &__airport-origin, &__airport-arrival {
                                                    background-color: $white;
                                                    height: 30px;
                                                }

                                                &__airport {
                                                    height: 30px;
                                                }

                                                &__flight {
                                                    position: relative;
                                                    top: -12px;
                                                    text-align: left;
                                                    width: 100%;
                                                    margin-left: 20px;
                                                    border-left: 1px dashed;
                                                    padding-top: 30px;

                                                    .ic-airplane {
                                                        border: 5px solid $white;
                                                        border-bottom: 0;
                                                        border-top: 0;
                                                        background-color: $white;
                                                        margin-left: -15px;
                                                        

                                                        &::before {
                                                            width: 21px;
                                                            height: 19px;
                                                        }
                                                    }

                                                    &-info {
                                                        display: flex;
                                                        flex-flow: column wrap;
                                                        font-size: 12px;
                                                        color: #999;
                                                        margin-top: 0px;
                                                        margin-left: 20px;
                                                        position: relative;
                                                        top: -22px;

                                                        .flight {
                                                            

                                                            &__company {
                                                                width: 55px;
                                                                margin-bottom: 9px;
                                                                margin-top: -5px !important;

                                                                .logo-airlines {
                                                                    background-size: contain !important;
                                                                    background-repeat: no-repeat;
                                                                    width: 55px;
                                                                    height: 30px;
                                                                    margin: auto;
                                                                }
                                                            }

                                                            &__code {
                                                                font-size: 14px;
                                                                color: #777;
                                                                margin-top: -10px;
                                                                text-transform: uppercase;
                                                            }

                                                            &__cabine {
                                                                font-size: 12px;
                                                                color: #999;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .flight-table {
                                    position: relative;
                                    padding-right: 5px;

                                    .table-responsive {
                                        overflow-x: auto;
                                        
                                        .table {
                                            font-size: 13px;
                                            color: $gray-700;
                                            thead {
                                                tr {
                                                    border-top: none;
                                                    th {
                                                        border: 0;
                                                        text-align: center;
                                                        font-family: $boldFontFamily;
                                                        padding: 0.75rem 0px;
                                                        &:first-child {
                                                            text-align: left;
                                                        }
                                                    }
                                                }
                                            }
                                            tbody {
                                                tr {
                                                    border-top: 1px solid $gray-300;
                                                    td {
                                                        border: 0;
                                                        text-align: center;
                                                        padding: 0.75rem 0px;
                                                        font-size: 12px;
                                                        line-height: 20px;
                                                        &:first-child {
                                                            text-align: left;
                                                        }
                                                        &:last-child {
                                                            text-align: right;
                                                        }
                                                    }
                                                    &:last-child {
                                                        border-bottom: 1px solid $gray-300;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &::after {
                                        box-shadow: inset -27px 0 23px -13px $white;
                                        position: absolute;
                                        width: 34px;
                                        height: 100%;
                                        top: 0;
                                        right: -1px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) { 
        padding: 0;

        &__box-wrapper {
            .title { 
                margin: 15px;

                .hidden-print {
                    display: block;
                    cursor: pointer;
                    span {
                        margin-right: 5px;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .panel {
                &-body {
                    .flight-list {
                            &__item {
                    
                            .flight-details {
                                justify-content: space-between;

                                &__date {
                                    display: block;
                                    width: auto;
                                    text-align: center;

                                    .date {
                                        &__week-day {
                                            font-size: 10px;
                                            display: block;
                                        }
    
                                        &__day {
                                            font-size: 28px;
                                            line-height: 28px;
                                            display: block;
                                        }
    
                                        &__month {
                                            font-size: 14px;
                                            display: block;
                                        }
                                    }
                                }

                                &__company {
                                    margin-bottom: 0;

                                    .company {
                                        &__thumb {
                                            width: 100px;
                                        }
                                    }
                                }

                                &__travel {
                                    width: auto;

                                    .travel {
                                        &__info {
                                            display: block;
                                            text-align: center;

                                            &.hidden-desktop {
                                                display: none;
                                            }
                                        }

                                        &__details-wrapper {
                                            width: 100%;
                                            max-width: 300px;
                                            margin: 0 20px;
                                        }
                                    }
                                }

                                &__extra-content {
                                    margin: 20px 0 10px;
                                    padding-top: 10px;
    
                                    .flight-timeline {
                                        margin-left: 0;
                                        margin-top: 40px;

                                        &__wrapper {
                                            .flight-timeline {
                                                width: auto;
                                                border-top: 1px dashed $gray-500;
                                                
                                                .timeline {
                                                    &__box {
                                                        text-align: center;
                                                        width: auto;
                                                        margin-left: 0;
                                                        left: 0;

                                                        .ic-pin {
                                                            background: $white;
                                                            padding: 0 6px 0 6px;
                                                        }

                                                        &__airport-origin, &__airport-arrival {
                                                            background-color: $white;
                                                            height: auto;
                                                        }
                                                        
                                                        .travel {
                                                            &__origin {
                                                                display: flex;
                                                                flex-direction: column;
                                                                margin-top: 10px;
                                                                top: 0;
                                                                margin-left: 0;
                                                            }

                                                            &__duration {
                                                                font-size: 14px;
                                                                float: none;

                                                                .duration__period {
                                                                    width: 100%;
                                                                    float: left;
                                                                    font-size: 12px;
                                                                    margin-bottom: 3px;
                                                                    text-align: center;
                                                                }

                                                                .hidden-desktop {
                                                                    display: none !important;
                                                                }
                                                            }

                                                            &__label {
                                                                font-size: 10px;
                                                                color: $gray-700;
                                                                border: 1px solid $gray-800;
                                                                border-radius: 4px;
                                                                text-transform: uppercase;
                                                                padding: 2px 6px;
                                                                align-self: center;

                                                                &.hidden-phone {
                                                                    display: inline-block;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &__flight {
                                                        position: relative;
                                                        top: -45px; 
                                                        text-align: center;
                                                        width: auto;
                                                        margin-left: 14px;
                                                        border-left: none;
                                                        padding-top: none;

                                                        &__location {
                                                            align-self: auto;
                                                        }

                                                        .ic-airplane {
                                                            margin-left: 0;
                                                        }

                                                        &-info {
                                                            align-items: center;
                                                            position: relative;
                                                            top: 0;
                                                            margin-left: 0;
                                                            margin-top: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .flight-table {
                                        .table-responsive {
                                            .table {
                                                thead {
                                                    tr {
                                                        th {
                                                            padding: 0.75rem;
                                                        }
                                                    }
                                                }
                                                tbody {
                                                    tr {
                                                        td {
                                                            padding: 0.75rem;
                                                            font-size: 14px;
                                                            line-height: 14px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media print {
        padding: 0;

        &__box-wrapper {
            .title { 
                margin: -30px 0 10px 0;

                .hidden-print {
                    display: none;
                }
            }

            .panel {
                &-heading {
                    background-color: $white !important;
                    border-bottom: 1px solid $default;
                    padding: 9px 0;
                    .flight-locator {
                        float: left;
    
                        &__label {
                            color: $black;
                        }
    
                        &__number {
                            color: $black;
                        }
                    }
                }
                &-body {
                    border: none;
                    padding: 20px 0;
                    .flight-list {
                            &__item {
                    
                            .flight-details {
                                justify-content: space-between;

                                &__date {
                                    display: block;
                                    width: auto;
                                    text-align: center;

                                    .date {
                                        &__week-day {
                                            font-size: 10px;
                                            display: block;
                                        }
    
                                        &__day {
                                            font-size: 28px;
                                            line-height: 28px;
                                            display: block;
                                        }
    
                                        &__month {
                                            font-size: 14px;
                                            display: block;
                                        }
                                    }
                                }

                                &__company {
                                    margin-bottom: 0;

                                    .company {
                                        &__thumb {
                                            width: 100px;
                                        }
                                    }
                                }

                                &__travel {
                                    width: auto;

                                    .travel {
                                        &__info {
                                            display: block;
                                            text-align: center;

                                            &.hidden-desktop {
                                                display: none;
                                            }

                                            .seta{
                                                &::before {
                                                    content: url(../images/svg/icons/gray/ic-arrow-flight-details.svg);
                                                }
                                            }
                                        }

                                        &__details-wrapper {
                                            width: 100%;
                                            max-width: 300px;
                                            margin: 0 20px;
                                        }
                                    }
                                }

                                &__extra-content {
                                    margin: 20px 0 10px;
                                    padding-top: 10px;
    
                                    .flight-timeline {
                                        margin-left: 0;
                                        margin-top: 40px;

                                        &__wrapper {
                                            .flight-timeline {
                                                width: auto;
                                                border-top: 1px dashed $gray-500;
                                                
                                                .timeline {
                                                    &__box {
                                                        text-align: center;
                                                        width: auto;
                                                        margin-left: 0;
                                                        left: 0;

                                                        .ic-pin {
                                                            background: $white;
                                                            padding: 0 6px 0 6px;
                                                        }

                                                        &__airport-origin, &__airport-arrival {
                                                            background-color: $white;
                                                            height: auto;
                                                        }
                                                        
                                                        .travel {
                                                            &__origin {
                                                                display: flex;
                                                                flex-direction: column;
                                                                margin-top: 10px;
                                                                top: 0;
                                                                margin-left: 0;
                                                            }

                                                            &__duration {
                                                                font-size: 14px;
                                                                float: none;

                                                                .duration__period {
                                                                    width: 100%;
                                                                    float: left;
                                                                    font-size: 12px;
                                                                    margin-bottom: 3px;
                                                                    text-align: center;
                                                                }

                                                                .hidden-desktop {
                                                                    display: none !important;
                                                                }
                                                            }

                                                            &__label {
                                                                font-size: 10px;
                                                                color: $gray-700;
                                                                border: 1px solid $gray-800;
                                                                border-radius: 4px;
                                                                text-transform: uppercase;
                                                                padding: 2px 6px;
                                                                align-self: center;

                                                                &.hidden-phone {
                                                                    display: inline-block;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &__flight {
                                                        position: relative;
                                                        top: -45px; 
                                                        text-align: center;
                                                        width: auto;
                                                        margin-left: 14px;
                                                        border-left: none;
                                                        padding-top: none;

                                                        &__location {
                                                            align-self: auto;
                                                        }

                                                        .ic-airplane {
                                                            margin-left: 0;
                                                        }

                                                        &-info {
                                                            align-items: center;
                                                            position: relative;
                                                            top: 0;
                                                            margin-left: 0;
                                                            margin-top: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .flight-table {
                                        .table-responsive {
                                            .table {
                                                thead {
                                                    tr {
                                                        th {
                                                            padding: 0.75rem;
                                                        }
                                                    }
                                                }
                                                tbody {
                                                    tr {
                                                        td {
                                                            padding: 0.75rem;
                                                            font-size: 14px;
                                                            line-height: 14px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

