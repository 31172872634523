@import './luggage-success';

.luggage-container {
  text-align: initial !important;

  .text-gold {
    color: $gold-light;
  }

  .btn-less {
    background-color: $gray-200;
  }

  .btn-more {
    background-color: $gray-300;
  }

  .volver-dash {
    align-items: center;
    .btn-volver {
      width: 100%;
    }
  }

  .localizador-dash {
    p {
      font-size: 16px;
    }
  }

  .dashboard-luggage {
    border: 2px solid $gray-500;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $gray-300;

    .reglas-equipaje {
      text-align: center;
      color: $primary;
      font-size: 1.5em;
      padding-top: 25px;
      padding-bottom: 20px;
      border-bottom: 1px dashed $gray-500;
      background-color: $white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .los-clientes {
      border: 2px solid $gray-300;
      border-bottom: none;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      h3 {
        text-align: center;
        font-size: 1.3em;
        padding: 10px 30px 10px 30px;
        margin-top: 10px;
        color: $gray-700;
        margin-bottom: 20px;
      }
    }

    .categorias-dash {
      border: 2px solid $gray-300;
      margin-top: -2px;
      padding: 5px 10px 25px 10px;
      .categoria-item {
        h2 {
          text-align: center;
          margin-top: 10px;
        }


        .icn-categoria-silver {
          background-image: url(/images/svg/icons/gray/ic-luggage-silver.svg);
          filter:brightness(2.5);
          display: block;
          width: 100%;
          height: 100px;
          background-position: center center;
          background-size: 80px;
          background-repeat: no-repeat;
          h2{
            text-transform: lowercase;
          }

        }

        .icn-categoria-gold {
          background-image: url(/images/svg/icons/gold/ic-luggage-gold.svg);
          filter:brightness(2.5);
          display: block;
          width: 100%;
          height: 100px;
          background-position: center center;
          background-size: 140px;
          background-repeat: no-repeat;

        }

        .icn-categoria-diamond {
          background-image: url(/images/svg/icons/white/ic-luggage-diamond.svg);
          display: block;
          width: 100%;
          height: 100px;
          background-position: center center;
          background-size: 180px;
          background-repeat: no-repeat;
        }
      }

      .categoria-silver {
        border-right: 2px solid $gray-300;
        background-color: $silver;
        padding: 40px 20px;
        border-radius: 15px 0px 0px 15px;

        h2 {
          color: $white;
          margin-bottom: 10px;
        }


        p {
          color: $white;
          font-weight: initial;
          font-size: 15px;
          text-align: center;
          padding: 0px 30px 0px 30px;
          margin-bottom: 0px;
        }
      }

      .categoria-gold {
        border-right: 2px solid $gray-300;
        background-color: $gold;
        padding: 40px 20px;

        h2 {
          color: $white;
          margin-bottom: 10px;
        }


        p {
          color: $white;
          font-weight: initial;
          font-size: 15px;
          text-align: center;
          padding: 0px 30px 0px 30px;
          margin-bottom: 0px;
        }
      }

      .categoria-diamond {
        background-color: $diamond;
        padding: 40px 20px;
        border-radius: 0px 15px 15px 0px;
        h2 {
          color: $white;
          margin-bottom: 10px;
        }

        p {
          color: $white;
          font-weight: initial;
          font-size: 15px;
          text-align: center;
          padding: 0px 30px 0px 30px;
          margin-bottom: 0px;
        }
      }
    }

    .atencion {
      div{
        .icon-atencion{
          width: 30px;
        }
      }
      p:first-child {
        margin-top: 20px;
      }

      p {
        padding: 0px 35px 0px 35px;
        font-size: 14px;
        color: $gray-700;
      }
    }

    .linha-passagem-dash {
      width: 83.33333%;
      height: 1px;
      background-color: $gray-300;
      margin: 0 auto;
    }

    .passagem-dash {
      margin-top: 20px;

      .passagem-box {
        margin-top: 5px;
        border: 1px solid $gray-300;
        border-radius: 5px;

        .ida-header {
          background-color: $gray-300;
          height: 65px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          margin-top: -1px;
          margin-left: -1px;
          padding-top: 5px;

          .data-viagem {
            height: 65px;

            p {
              margin-top: 10px;
              font-weight: bold;
              font-size: 12px;
              color: $gray-800;
            }

            strong {
              font-weight: bold;
            }

            .icn-airplane {
              background-image: url(/images/svg/icons/gray/ic-airplane-two.svg);
              width: 30px;
              height: 100%;
              margin-top: 15px;
              margin-left: 10px;
              background-repeat: no-repeat;
              background-size: 30px;
            }

            .back {
              transform: scaleX(-1);
            }
          }

          .destino-viagem {
            height: 65px;
            display: flex;
            justify-content: center;

            div {
              .arrow-destino img {
                width: 60px;
                margin-top: 10px;
              }

              h1 {
                color: $gray-700;
                font-size: 26px;
                margin-top: 5px;
                margin-bottom: 0px;
              }
              p {
                font-size: 12px;
              }
            }
          }
        }

        .ida-body {
          .row-passageiro {
            input {
              color: $gray-700;
              width: 18.5em;
            }
          }

          .icon-passageiro img {
            margin-top: 40px;
            margin-left: 15px;
            height: 25px;
          }

          .name-passenger {
            p {
              font-size: 16px;
              margin-bottom: 0px;
              font-weight: bold;
              margin-top: 40px;
              width: 100%;
              color: $gray-800;
              word-break: break-word;
              padding-left: 20px;
            }
          }

          .details-passenger {
            margin-top: 3px;

            img {
              width: 40px;
            }
          }

          .details-passenger-text {
            margin-top: 3px;

            p {
              font-size: 14px;
              border: 1px solid black;
              border-radius: 3px;
              width: 120px;
              padding: 2px 0px 2px 0px;
              text-align: center;
            }
          }
          .categoria-silver {
            padding-top: 2px;
            h2 {
              color: $silver;
              margin-bottom: 10px;
            }

            p {
              border: 1px solid $silver;
              color: $silver;
              font-size: 14px;
              border-radius: 3px;
              width: 120px;
              padding: 2px 0px 2px 0px;
              text-align: center;
            }
          }

          .categoria-gold {
            padding-top: 2px;
            h2 {
              color: $gold;
              margin-bottom: 10px;
            }

            p {
              border: 1px solid $gold;
              color: $gold;
              font-size: 14px;
              border-radius: 3px;
              width: 120px;
              padding: 2px 0px 2px 0px;
              text-align: center;
            }
          }

          .categoria-diamond {
            padding-top: 2px;
            h2 {
              color: $diamond;
              margin-bottom: 10px;
            }

            p {
              border: 1px solid $diamond;
              color: $diamond;
              font-size: 14px;
              border-radius: 3px;
              width: 120px;
              padding: 2px 0px 2px 0px;
              text-align: center;
            }
          }
          .suitcase {
            img {
              width: 25px;
              margin-top: 50px;
              margin-left: -10px;
            }
          }

          .tres {
            margin-left: -5px;

            span {
              color: $primary;
              font-size: 70px;
              margin-left: -5px;
            }
          }

          .piezas {
            p {
              font-size: 12px;
              margin-top: 20px;
              margin-left: 10px;
              max-width: 109px;
              font-weight: bold;

              strong {
                color: $primary;
                text-transform: uppercase;
              }
            }
          }

          .qtde-piezas {
            p {
              font-size: 11.5px;
              margin-top: 20px;
              margin-bottom: 5px;
              font-weight: bold;
            }
            .add-piezas {
              height: 48px;
              display: flex;
              justify-content: center;
              padding-right: 5px;
              max-width: 200px;

              input {
                width: 48px;
                height: 100%;
                border: 1px solid $gray-300;
                text-align: center;
                cursor: pointer;
              }

              button {
                width: 48px;
                height: 100%;
                border: none;
                color: $gray-700;
                border-radius: 5px;
                cursor: pointer;
              }
            }
          }

          .linha-passagem-hr {
            display: none;
          }
        }

        .row-detail-passenger-2 {
          .question-sign {
            background-color: $gray-500;
            width: 18px;
            height: 18px;
            text-align: center;
            border-radius: 100%;
            color: white;
            margin-left: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
          }

          p {
            margin-top: 10px;
            margin-bottom: 0px;
          }

          input {
            border: 1px solid $gray-300;
            font-size: 14px;
            padding: 5px 0px 5px 10px;
            width: 220px;
            border-radius: 5px;
            height: 30px;
            padding-right: 34px;
          }

          button {
            width: 30px;
            height: 25px;
            margin-top: 2.5px;
            background-color: $gray-500;
            border: none;
            border-radius: 5px;
            margin-left: -32px;
            background-image: url(../images/svg/icons/white/ic-arrow-dash.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }

      .row-passenger-w-header {
        .pieza-extra {
          p {
            font-size: 11px;
            color: $gray-700;
            margin-left: 20px;
            font-weight: bold;

            strong {
              color: $orange;
              font-weight: bold;
            }

            .question-sign {
              background-color: $gray-500;
              width: 18px;
              height: 18px;
              text-align: center;
              border-radius: 100%;
              color: white;
              margin-left: 7px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }

      .row-passenger-n-header {
        .pieza-extra {
          p {
            font-size: 12px;
            color: $gray-700;
            margin-left: 20px;
            margin-top: 45px;
            font-weight: bold;

            strong {
              color: $orange;
              font-weight: bold;
            }

            .question-sign {
              background-color: $gray-500;
              width: 18px;
              height: 18px;
              text-align: center;
              border-radius: 100%;
              color: white;
              margin-left: 2px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }

      .passenger-2 {
        min-height: 130px;
        border: 1px solid $gray-300;
        border-radius: 5px;
        margin-top: 10px;

        .icon-passageiro img {
          margin-top: 40px;
          margin-left: 15px;
        }

        .name-passenger {
          p {
            font-size: 16px;
            margin-bottom: 0px;
            margin-top: 25px;
            font-weight: bold;
          }
        }

        .row-detail-passenger-2 {
          .question-sign {
            background-color: $gray-500;
            width: 18px;
            height: 18px;
            text-align: center;
            border-radius: 100%;
            color: white;
            margin-left: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
          }

          p {
            margin-top: 10px;
            margin-bottom: 0px;
          }

          input {
            border: 1px solid $gray-300;
            font-size: 14px;
            padding: 5px 0px 5px 10px;
            width: 220px;
            border-radius: 5px;
            height: 30px;
            padding-right: 34px;
          }

          button {
            width: 30px;
            height: 25px;
            margin-top: 2.5px;
            background-color: $gray-500;
            border: none;
            border-radius: 5px;
            margin-left: -32px;
            background-image: url(../images/svg/icons/white/ic-arrow-dash.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .details-passenger {
          margin-top: 3px;

          img {
            width: 40px;
          }
        }

        .details-passenger-text {
          margin-top: 3px;

          p {
            font-size: 14px;
            border: 1px solid black;
            border-radius: 3px;
            width: 120px;
            padding: 2px 0px 2px 0px;
            text-align: center;
          }
        }

        .suitcase {
          img {
            width: 25px;
            margin-top: 30px;
          }
        }

        .tres {
          span {
            font-size: 50px;
            color: $orange;
            margin-left: 5px;
          }
        }

        .piezas {
          p {
            font-size: 12px;
            margin-top: 20px;
            margin-left: 10px;
            max-width: 109px;
            font-weight: bold;

            strong {
              color: $orange;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
        }

        .qtde-piezas {
          p {
            font-size: 11px;
            margin-top: 20px;
            margin-left: 18px;
            margin-bottom: 5px;
            font-weight: bold;
          }
          .add-piezas {
            height: 48px;
            display: flex;
            justify-content: center;

            .col-3 {
              margin-left: 10px;
            }
            input {
              width: 48px;
              height: 100%;
              border: 1px solid $gray-300;
              text-align: center;
              cursor: pointer;
            }

            button {
              width: 48px;
              height: 100%;
              border: none;
              color: $gray-700;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }

        .linha-passagem-hr {
          display: none;
        }
      }
    }
    .confirmar-dash {
      margin-top: 20px;
      border-top: 1px solid $gray-300;

      .confirmar-box {
        margin-top: 20px;
        border-radius: 15px;
        float: left;

        .cantidad {
          p {
            font-size: 18px;
            color: $gray-700;
          }

          p:first-child {
            color: $gray-800;
            font-weight: bold;
          }
          .si-las {
            font-size: 10px;
            color: $gray-600;
          }
          .no-pieces {
            margin-top: 60px;
          }
          .has-pieces {
            margin-top: 40px;
          }
        }
        .confirmar-buttons {
          text-align: right;
          float: right;

          .confirmar-inputs {
            margin-bottom: 15px;
          }
          .money-miles-selected {
            border: 2px solid $primary;
            color: $primary;
          }

          input {
            border: 1px solid $gray-700;
            border-radius: 5px;
            color: $gray-700;
            text-align: center;
            width: 130px;
            font-weight: bold;
            margin-left: 30px;
            display: block;
            cursor: pointer;
          }

          .btn-confirmar {
            border-radius: 5px;
            width: 130px;
            height: 45px;
            margin-top: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 992px) {
    .volver-dash {
      .btn-volver {
        display: none;
      }
    }

    .btn-volver {
      font-size: 20px;
    }

    .equipaje-title {
      h1 {
        font-size: 24px;
        margin-left: 15px;
      }
    }

    .localizador-dash {
      p {
        font-size: 14px;
        margin-left: 15px;
      }
    }

    .dashboard-luggage {

      .reglas-equipaje {
        font-size: 18px;
      }

      .categorias-dash {
        .categoria-silver {
          border-right: none;
          border-bottom: 2px solid $gray-300;
          padding-bottom: 20px;
          border-radius: 0px;
        }

        .categoria-gold {
          border-right: none;
          border-bottom: 2px solid $gray-300;
          padding-bottom: 20px;
        }
        .categoria-diamond {
          border-radius: 0px;
        }
      }
      .atencion {
        margin-left: 25px;
        margin-right: 25px;
        div{
          .icon-atencion{
            width: 30px;
            height: 25px;
          }
        }
      }

      .los-clientes {
  
        h3 {
          font-size: 1em;
        }
      }

      .passagem-dash {
        .passagem-box {
          .ida-header {
            padding: 5px 5px 0px 15px;

            .destino-viagem {
              div {
                h1 {
                  font-size: 18px;
                  text-align: center;
                }

                p {
                  font-size: 12px;
                  text-align: center;
                }

                .arrow-destino {
                  img {
                    width: 40px;
                  }
                }
              }
            }
          }

          .ida-body {
            .linha-passagem-hr {
              display: flex;

              .linha-passagem-hr-border {
                height: 1px;
                background-color: $gray-300;
              }
            }

            .row-passageiro {
              input {
                width: 16em;
              }
            }
          }
        }

        .passenger-2 {
          padding-bottom: 30px;
        }

        .pieza-extra {
          p {
            margin: 0 auto;
            margin-top: 25px !important;
            margin-left: auto !important;
            margin-bottom: 10px;
            text-align: center;
            width: 165px;
          }
        }

        .piezas-row {
          display: flex;
          justify-content: center;
          padding-bottom: 10px;

          .tres {
            span {
              margin-left: -15px !important;
              font-size: 70px;
              color: $primary;
            }
          }

          .piezas {
            p {
              max-width: none !important;
              font-size: 12px;
              margin-left: 0px !important;
              text-align: center;
              font-weight: bold;
            }
          }

          .qtde-piezas {
            p {
              margin-top: 0px !important;
              margin-left: 0px !important;
              text-align: center;
            }
          }
        }
      }
      .confirmar-dash {
        .confirmar-box {
          .confirmar-buttons {
            text-align: initial !important;
            .inputs-wrapper-mobile{
              display: flex;
              justify-content: center;
            }
            input {
              margin-left: 0px;

            }
            .btn-confirmar {
              width: 100%;
            }
          }
        }
      }
    }

    .los-clientes-row {
      padding: 0px 20px 0px 20px;
    }

    .row-passageiro {
      padding-left: 15px;
    }
  }
}
