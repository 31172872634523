.mpcardform-container{
    font-family: $regularFontFamily;

    .firstRow{
        /* gap: 6px;
        flex-wrap: nowrap; */
    }
}


@media only screen and (max-width: 991px) {
    .mpcardform-container{

        .firstRow{
           /*  gap: 0px;
            flex-wrap: wrap; */
        }
    }
}