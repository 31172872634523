.frm-default .calender-control {
	$width: 70px;
	$height: 62px;

	position: relative;
	min-width: $width;
	max-width: $width;

	.SingleDatePickerInput {
		border: none;
	}

	.DateRangePickerInput {
		border: none;
	}

	span {
		z-index: 1;
	}

	.control-container {
		top: 0;
		width: 100%;
		height: $height;
		position: absolute;
		pointer-events: none;
		border: 1px solid $gray-700;
		border-radius: 4px;
		background-color: $white;

    &.cc-clickable {
      pointer-events: all;
      cursor: pointer;
    }

    &.selected {
      background: rgba(255, 90, 0, 0.2);
    }

		span {
			color: $gray-700;
			display: block;
			width: 100%;
			line-height: 1.2em;
			text-align: center;
		}

		.weekday {
			font-size: 8px;
			width: 60%;
			margin-left: 20%;
			padding-top: 3px;
			border-bottom: 1px solid $gray-700;
		}

		.day {
			@include bms-font-size(1.2rem, 21px);

			padding-top: 1px;
			font-weight: bold;
		}

		.month {
			width: 100%;
			font-size: 8px;
			position: relative;
      z-index: 0;

			&:after {
				content: ' ';
				position: absolute;
				width: 100%;
				height: 10px;
				top: 85%;
				left: 0;
				background: {
					image: url('/images/svg/icons/gray/ic-arrow-down.svg');
					size: 14px;
					position: center;
					repeat: no-repeat;
				};
			}
		}
	}

	.input-container,
	.SingleDatePickerInput .DateInput {
		height: $height;
		min-width: $width;
		max-width: $width;
		margin: 0;
	}

  .input-container input ,
	.SingleDatePickerInput .DateInput .DateInput_input {
		height: $height;
		cursor: pointer;
		width: 100%;

		@media(min-width: $site-width-mobile) {
			font-size: 1px;
		}

		&[disabled] {
			cursor: unset;
		}
	}

	.input-container input ,
	.DateRangePickerInput .DateInput .DateInput_input {
		height: $height;
		cursor: pointer;
		width: 100%;

		@media(min-width: $site-width-mobile) {
			font-size: 1px;
		}

		&[disabled] {
			cursor: unset;
		}
	}
}

.range-input-calendar-info {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(112, 112, 112, 0.5);
  padding: 10px;
}

.d-desktop {
  .input-container,
  .DateRangePickerInput .DateInput {
    display: none;
    margin: 0;
  }
}
