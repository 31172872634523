.smls-black-icons {
    font-size: 10px;
}

.smls-black-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-black-icons.svg");
    background-repeat: no-repeat;
    background-size: 3.95em 21.2em;
}

.no-svg .smls-black-icons:before {
    background-image: url("../sprites/smls-black-icons.png");
}



  .smls-black-icons.ic-anticipation:before {
      background-position: 0em 0em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-box-checked:before {
      background-position: 0em -1.6em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-cabin:before {
      background-position: 0em -3.2em;
      width: 1.5em;
      height: 1.6em;
  }




  .smls-black-icons.ic-departure-plus-solid:before {
      background-position: 0em -4.8em;
      width: 2.98em;
      height: 1.6em;
  }




  .smls-black-icons.ic-departure-solid:before {
      background-position: 0em -6.4em;
      width: 2.9em;
      height: 1.6em;
  }




  .smls-black-icons.ic-expiration:before {
      background-position: 0em -8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-luggage-diamond:before {
      background-position: 0em -9.6em;
      width: 3.95em;
      height: 1.6em;
  }




  .smls-black-icons.ic-profile-solid:before {
      background-position: 0em -11.2em;
      width: 2em;
      height: 2em;
  }




  .smls-black-icons.ic-renewal-cycle:before {
      background-position: 0em -13.2em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-seat-solid:before {
      background-position: 0em -14.8em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-star-solid:before {
      background-position: 0em -16.4em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-suitcase-solid:before {
      background-position: 0em -18em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-black-icons.ic-ticket-solid:before {
      background-position: 0em -19.6em;
      width: 1.6em;
      height: 1.6em;
  }





