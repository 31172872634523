.search_flight {
  position: relative;

  &.alternate .search-flight__form {
    background-color: $gray-200;
  }

  .col label.form-check-label {
    $size: 25px;
    padding-left: 30px;
    line-height: $size;

    span::before {
      top: 0;
      width: $size;
      height: $size;
      background-size: cover;
    }

    input:checked ~ span::before {
      background-position: -$size bottom;
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    & > .form-container {
      max-width: unset;
      width: 700px;
    }

    .control-group {
      justify-content: space-between;
      & > div {
        max-width: unset;
      }
      & > div:nth-child(1) {
        flex-basis: 72%;
      }
      & > div:nth-child(2) {
        flex-basis: 28%;
      }
    }
  }

  label,
  .is-label {
    color: $gray-700;
  }

  label.form-check-label {
    padding-left: 20px;
    position: relative;
  }

  .frm-default label {
    font-weight: unset;
  }

  .calendar-label {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 0;
    min-width: 70px;
    &.floated {
      position: absolute;
      top: -20px;
    }
  }

  .search-flight__form {
    background-color: $white;
  }

  .search-flight__form {
    margin-top: 0;
    border-radius: 16px;

    .form-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      h2 {
        color: #ffffff;
        margin-bottom: 0px;
        width: 90%;
        padding: 10px;
        border-top-left-radius: 16px;
      }

      &__icon {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-top-right-radius: 16px;
        background: rgba(0,0,0,0.3);

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          display: inline-block;
          background-image: url("../../../images/svg/icons/white/ic-search.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      @media screen and (max-width: 768px) {
        h2 {
          width: 80%;
          padding-left: 20px;
        }

        &__icon {
          width: 20%;
        }
      }
    }

    .form-content {
      padding: 10px 20px 5px 20px;
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      background-color: $gray-200;

      #airport-origin,
      #airport-destination {
        background: $white;
        border: 1px solid $gray-700;

        &::placeholder {
          font-size: 15px;
        }
      }

      .btn {
        &.search {
          padding: 0px !important;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 0px;
          height: 50px;
          border-radius: 10px;
          margin-left: 10px;

          &::after {
            content: "";
            position: absolute;
            height: 75%;
            width: 2px;
            background: $gray-500;
            left: -8px;
          }

          p {
            margin-bottom: 0px;
            display: flex;
            width: 70%;
            justify-content: center;
            font-size: 20px;
          }

          .search-btn__icon {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 30%;
            background: rgba(0,0,0,0.3);
            height: 100%;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              display: inline-block;
              background-image: url("../../../images/svg/icons/white/ic-search.svg");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;

              -moz-transform: scaleX(-1);
              -o-transform: scaleX(-1);
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }
        }
      }

      @media screen and (max-width: $site-width-mobile) {
        padding-bottom: 0px;
        padding-top: 30px;
        margin-bottom: 40px;

        .actions {
          position: absolute;
          bottom: -28px;
          display: flex !important;
          justify-content: center;
          width: 100%;

          .search {
            max-width: 180px !important;
            margin-left: 0px;

            &::after {
              content: none;
            }
          }
        }
      }
    }
  }

  form.frm-default.search-flight__form .airport-row {
    @media (min-width: $site-width-mobile) {
      .form-group {
        margin-bottom: 5px;
      }
    }
  }

  form.frm-default.search-flight__form .airport-row .airport-icon{
    height: 25px;
    width: 25px;
    background: url('/images/svg/icons/gray/ic-departure.svg');
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px 10px;
    border: none;
  }
  form.frm-default.search-flight__form .airport-row .airport-icon:hover{
    background: url('/images/svg/icons/orange/ic-swap.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-control {
    border-color: $gray-700;
    &:focus {
      border-color: $gray-900;
    }
  }

  .disabled-input {
    opacity: 0.4;
  }

  .d-mobile {
    @media (min-width: $site-width-mobile) {
      display: none;
    }
  }

  .d-desktop {
    @media (max-width: $site-width-mobile) {
      display: none;
    }
  }

  .bottom-label {
    padding-right: 5px;
    @media (min-width: $site-width-mobile) {
      position: absolute;
    }

    p {
      text-align: left;
      font-size: 8px;
      line-height: 11px;
      font-weight: 600;
      margin-top: 3px;
      color: $gray-700;
    }
  }

  button{
    &.is-link {
      background: none;
      border: none;
      font-weight: bold;
    }

    &.return-button {
      position: absolute;
      color: $secondary;
      font-size: 14px;
      z-index: 1;
      left: 0;
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        padding-right: 10px;
        right: 0;
        left: unset;
      }
    }

    &.close-button {
      position: absolute;
      color: $primary;
      left: -6px;
      top: -31px;
      font-size: 21px;
      z-index: 1;
    }

    &.btn.search-btn-active {
      border-color: $primary !important;
    }

    &.search-btn {
      color: $primary;
      background-color: $white;
      border-color: $primary;
    }

    &.search-btn-active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  }

  .btn-stack-row {
    &.row {
      .smls-orange-icons,
      .smls-white-icons {
        font-size: 13px;
      }
      .search-buttons-row .smls-orange-icons,
      .search-buttons-row .smls-white-icons {
        font-size: 11px;
        margin-inline-start: -10px;
      }
    }
  }

  .search-buttons-row {
    &.row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      margin: 10px 0px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        border: 1px solid $default;
        border-radius: 10px;

        .btn-text {
          margin-inline-end: -8px;
        }
      }

      .ic-back-forth-arrows {
        margin-right: 7px;
      }
    }

    @media (min-width: $site-width-mobile) {
      .form-group {
        margin-bottom: 5px;
      }
      & > div:first-child {
        padding-right: 10px;
      }
      & > div:nth-child(2) {
        padding-left: 10px;
      }
      & > div:nth-child(3) {
        padding-left: 10px;
      }
    }

    .multi-segment-btn {
      display: flex;
      grid-gap: 10px;
      font-size: 12px;

      > div {
        display: flex;
        gap: 5px;
      }

      .close-btn {
        padding-top: 8px;
        font-size: 17px;
        margin-inline-end: -8px;
      }
    }

    span.smls-orange-icons.ic-globe {
      margin-right: 5px;
    }

    @media screen and (min-width: $site-width-mobile) {
      &.mb {
        display: none;
      }
    }

    @media screen and (max-width: $site-width-mobile) {
      display: none !important;

      &.mb {
        display: flex !important;

        .flex-btns {
          display: flex;
          width: 100%;
          gap: 10px;
        }
      }
    }
  }

  .dropdown.sort-result.btn-group {
    width: 189px;
    height: 40px;
    display: flex;
    padding: 10px;
    justify-content: center;

    font-weight: 700;
    font-size: 13px;

    border: 1px solid $grayBorder;
    border-radius: 10px;
    background: $white;
    cursor: pointer;
  }

  a.cabin-dropdown.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul.classes-list {
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 4px;
  }

  .classes-list__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 4px;
      &.smls-orange-icons.ic-executive-class {
        position: relative;
        top: -2px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;

    .search-flight__form {
      margin-top: 15px !important;
    }

    .classes-list__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .dropdown-menu.show {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-1.5px, 47px, 0px) !important;
    width: 101.5%;
    padding-top: 0px;

    .dropdown-item {
      padding: 10px 17px;
    }

    button.dropdown-item {
      color: $gray-700;
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .search-buttons-row.row {
      display: block;
      .btn {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-bottom: 10px;
        width: 100%;
      }
    }

    .dropdown.sort-result.btn-group {
      width: 100%;
      float: none;
      font-weight: 700;
      font-size: 15px;
      padding: 10px;
      border: 1px solid $grayBorder;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    .dropdown-menu.show {
      position: absolute;
      will-change: transform;
      top: 0px;
      left: 0px;
      transform: translate3d(-1.5px, 47px, 0px) !important;
      width: 101% !important;
      padding-top: 0px;
    }

    .calendar-label {
      &.floated {
        position: relative;
        top: 0;
      }
    }
  }

  .btn-plus {
    @include bms-font-size(0.8rem,
      15px);
    background: none;
    border: none;
    padding: 0;

    &:before {
      content: '+';
      color: $primary;
      font-weight: bold;
      padding-right: 5px;
    }
  }

  .control-group {
    label {
      font-weight: bold;
      text-align: left;
      width: 100%;
    }

    .form-group {
      text-align: left;
      @media (min-width: $site-width-mobile) {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    button {
      width: 100%;
    }
  }

  %calendarColumn {
    flex: 1;
    max-width: 83px;
  }

  .calendar-column {
    @extend %calendarColumn;
    .calender-control {
      float: right;
    }
    .calendar-label {
      right: 0;
      left: -17px;
    }
  }

  .row.fluid-row {
    justify-content: center;
    @media (min-width: $site-width-mobile) {
      &.multi-segment {
        justify-content: flex-end;
      }
    }

    & > [class*="col"] {
      flex: 1;
      &.mobile-menu {
        max-width: 120px;
      }
    }
  }

  .calendar-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .calendarTravel > div {
      left: 52%;
    }
    .calendarReturn > div {
      left: 8%;
    }
  }
}
