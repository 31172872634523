.jarvis-servicePrice-container{
    flex-wrap: nowrap;
    .first-service{
        padding:15px 12px 5px 15px;
        border-radius: 10px 0 0 10px;
        background-color: $club;
        .img-club-smiles-container{
            flex-wrap: nowrap;
            img{
                width: 24px;
            }
            span{
                position: absolute;
                top: -7px;
                left: 13px;
                background-color:$white;
                color:$club;
                font-size:.6em;
                border-radius:30px;
                padding:1px 6px;
                font-family: $boldFontFamily;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.598);
            }
        }
        h4{
            font-size: 1.2em
        }
        p {
            font-size:.6em;
            margin-top:-10px;
            margin-bottom:0px
        }
    }
    .second-service{
        padding:15px 15px 5px 9px;
        background-color: $primary;
        border-radius: 0px 10px 10px 0px;
        h4{
            margin-top:12px;
            margin-bottom:0px;
            font-size: 1.2em
        }
        p {
            font-size:.6em;
            margin-top:-10px;
            margin-bottom:0px
        }
    }

}

@media only screen and (max-width: 575px){

}