.checkout-more-options {
    display: flex !important;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;

    &__itens {
        display: flex !important;
        align-items: center;
        flex-flow: row wrap;
        width: 480px;
        margin: 0;

        div {
            border: 1px solid #ececec;
            border-radius: 1px;
            padding: 10px 20px;
            margin: 9px 0 9px;
            display: flex !important;
            align-items: center;
            width: 100% !important;
            justify-content: space-around;

            span {
                width: 116px;
                height: 85px;
            }

            .baggage {
                background: url(#{$svg-path}/icons/gray/ic-luggage-silver.svg) no-repeat center;
                background-size: 60%;
            }

            .seats {
                background: url(#{$svg-path}/icons/gray/ic-seats.svg) no-repeat center;
            }

            a {
                border-radius: 3px;
                padding: 8px 22px;
                text-shadow: none;
                box-shadow: none;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                background-image: none;
                border: 1px solid rgba(0,0,0,0);
                transition: all ease .5s;
                font-size: 14px;
                min-height: 60px;
                display: flex !important;
                line-height: 20px;
                align-items: center;
                width: 120px;
                white-space: normal;
            }

            .btn-theme-dark {
                background-color: $gray-600;
                color: $white;
            }
        }
    }

    @media (min-width: 768px) { 
        &__itens { 
            div {
                width: 50% !important;
                justify-content: center;
                flex-flow: column wrap;
                min-height: 304px;

                a {
                    width: 136px;
                    min-height: 70px;
                }
            }
        }
    }
}