.default-loading {
  display: table;
  width: 100%;
  height: 90%;
  min-height: 40px;
  vertical-align: middle;
  background-image: url(#{$image-path}/animation/loading.gif);
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
}

.loading-airplane {
  display: table;
  width: 100%;
  height: 100px;
  vertical-align: middle;
  background-image: url(#{$image-path}/animation/loading-airplane.gif);
  background-size: 300px;
  background-position: top center;
  background-repeat: no-repeat;
}

.loading-airlines {
  display: table;
  width: 100%;
  height: 100px;
  vertical-align: middle;
  background-image: url(#{$image-path}/animation/loading-airlines.gif);
  background-size: contain;
  background-position: center -5px;
  background-repeat: no-repeat;
}

#loading-airlines-cms-gif {
	height: 45px;
}
