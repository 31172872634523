.smls-green-icons {
    font-size: 10px;
}

.smls-green-icons:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("icons/sprites/smls-green-icons.svg");
    background-repeat: no-repeat;
    background-size: 2.5em 4.1em;
}

.no-svg .smls-green-icons:before {
    background-image: url("../sprites/smls-green-icons.png");
}



  .smls-green-icons.ic-circle-check:before {
      background-position: 0em 0em;
      width: 1.6em;
      height: 1.6em;
  }




  .smls-green-icons.ic-success-adhesion:before {
      background-position: 0em -1.6em;
      width: 2.5em;
      height: 2.5em;
  }





