/*Resultado emision Congenere vuelos de millas inicio*/

    .easy-travel-success__container {
            width: 100%;
            padding: 20px 10px;
            margin: 0px;
            .resume-emision-gol {
                padding: 0px;
                border: 1px solid $gray-500;
                width: 100% !important;
                display: flex;
                @media only screen and (max-width: 991px) {
                    flex-direction: column;
                }
                .resume-emision-gol__left {
                        height: 237px;
                        background-color: $easy-travel;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        .viaje-facil-logo {
                            width: 60px;
                            height: 60px;
                            position: absolute;
                            top: 12px;
                            left: 12px;
                            background: url(#{$svg-path}/icons/white/ic-easy-travel.svg) no-repeat;
                            background-size: 60px;
                        }
                        .resume-emision-gol__icon-check {
                            width: 55px;
                            height: 55px;
                            background-size: 55px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-image: url(#{$svg-path}/icons/white/ic-check-success.svg);
                        }
                      
                        .easy-travel-message-container {
                            width: 100%;
                            max-width: 306px;
                            text-align: left;
                            h2 {
                                margin: 20px 0px 10px 0px;
                                color: $white;
                            }
                            p {
                                color: $white;
                                margin-bottom: 3px;
                                font-size: 14px;
                                font-weight:  600;
                            }
                        }
                        @media only screen and (max-width: 768px) { 
                            h4 {
                                margin: 20px 0px 20px 10px;
                                color: $white;
                                text-align: center;
                                font-size: 24px;
                            }
                        }
                }
                .resume-emision-gol__right {
                    padding: 0px;
                    background-color: $white;
                    hr {
                        margin: 0px 20px;
                    }
                    .resume-emision-gol__inner-top {
                        height: 105px;
                        background-color: $white;
                        padding: 20px;
                        .resume-list-top {
                            margin: 0px !important;
                            padding: 0px !important;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            li {
                                width: 100%;
                                margin-top: 0px;
                                margin-bottom: 0px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                .resume-title-02 {
                                    font-weight: bold;
                                    color: $gray-900;
                                }
                                .title {
                                    font-weight: 800;
                                    color: $easy-travel;
                                    font-size: 16px;
                                }
                                span {
                                    text-align: center;
                                    color: $gray-700;
                                    font-size: 14px;
                                    font-weight: 800;
                                }
            
                            }
                
                        }
                    }
                    .resume-emision-gol__inner-bottom {
                        background-color: $white;
                        padding: 5px 10px 30px 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        span {
                            text-align: center;
                            margin-bottom: 3px;
                        }
                        .resume-emision-gol__button-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            max-width: 280px;
                            a {
                                .btn {
                                margin-top: 0px;
                                border-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .resume-emision-gol__banner-bottom {
                margin-top: 1.5rem;
            }
    }

.tooltip-info {
    border: solid 2px $primary;
    border-radius: 5px;
    width: 115px;
    padding: 10px;
    position: absolute;
    left: 315px;
    top: 20px;
    background-color: $white;
    z-index: 1;
    p {
        font-size: 14px;
        margin-bottom: 0px;
        text-align: center;
        .club-text {
            color: $club;
            font-weight: 900;
        }
        .easy-text {
            color: $easy-travel;
            font-weight: 900;
        }
    }
    .arrow {
        width: 15px;
        height: 15px;
        background-color: $white;
        border-top: solid 0px $primary;
        border-left: solid 2px $primary;
        border-bottom: solid 2px $primary;
        border-right: solid 0px $primary;
        position: absolute;
        left: -9px;
        top: 25px;  
        transform: rotate(45deg);      
    }
}

@media only screen and (max-width: 1200px) {
    .tooltip-info {
        left: 292px;
    }
}

@media only screen and (max-width: 1024px) {
    .tooltip-info {
        left: 232px;
    }
}

@media only screen and (max-width: 991px) {
    .tooltip-info {
        left: 600px;
    }
}

@media only screen and (max-width: 768px) {
    .tooltip-info {
        left: 480px;
    }
}

@media only screen and (max-width: 425px) {
    .tooltip-info {
        width: 90px;
        left: 290px;
        p {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 375px) {
    .tooltip-info {
        width: 90px;
        left: 245px;
        p {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 320px) {
    .tooltip-info {
        width: 90px;
        left: 215px;
        p {
            font-size: 12px;
        }
    }
}

/*Resultado emision Congenere vuelos de millas fin*/