.seats {
    .title {
        width: 100%;
        padding-bottom: 10px;
        h1 {
            font-size: 23px;
            text-align: left;
            float: left;
            padding-top: 5px;
        }

        i {
            background: url(/images/svg/icons/orange/ic-title-seats.svg) no-repeat;
            background-size: 100%;
            display: inline-block;
            height: 38px;
            width: 70px;
            float: left;
            margin-right: 10px;
            margin-left: 5px;
        }
    }

    .flight-tab {
        
        .flight {
            padding: 7px;
            float: left;    
            text-align: center;
            height: auto;
            background-color: $gray-300;
            width: calc(50% - 10px);
            margin: 0 5px;

            &:last-child {
                width: calc(50% - 5px);
                margin: 0 5px 0 0;
            }

            p {
                span {
                    width: 100%;
                    display: inline-block
                }
                float: left;
                font-size: 13px;
                text-align: left;
                color: $gray-800;
            }

            &.current {
                background-color: $default;

                p {
                    color: $white;
                }
            }
        }
    }

    .flight-detail {
        margin: 0 5px;
        padding: 5px 0 10px 0;
        background-color: $gray-300;
        
        .flight {
            background-color: $default;
            display: inline-block;
            vertical-align: top;
            position: relative;
            color: $white;
            padding: 10px 20px;
            margin: 2px 0;
            float: left;
            width: 100%;

            h3 {
                color: $white;
                font-size: 18px;
                float: left;
                margin: 0;
            }

            label {
                float: left;
                margin: 0;
                clear: both;
                font-size: 14px;

                span {
                    display: inline-block;

                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .content {
        background-color: $gray-300;
        height: auto;
        position: relative;
        margin: 0 5px;
        width: calc(100% - 10px);

        .client-details {
            width: 100%;
            float: left;
            padding: 0 5px 5px 5px;
        
            .client-info {
                background: $gray-100;
                width: 100%;
                border-left: 2px solid $gray-100;
                border-bottom: 1px solid $gray-500;
                margin-bottom: 5px;
                position: relative;
                height: 38px;

                label {
                    width: 155px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: $gray-500;
                    font-size: 12px;
                    float: left;
                    margin-top: 8px;
                    text-align: left;
                    text-transform: uppercase;
                }

                span {
                    font-size: 25px;
                    color: $default;
                    float: right;
                    padding-right: 8px;
                }

                i.icon-user {
                    width: 18px;
                    height: 18px;
                    background: url(/images/svg/categories/ic-category-silver.svg) no-repeat;
                    background-size: contain;
                    vertical-align: middle;
                    margin: 9px 15px;
                    float: left;
                }

                &.selected {
                    border-left: 2px solid $default;
                    
                    label {
                        color: $default;
                    }
                    
                    i.icon-user {
                        background: url(/images/svg/categories/ic-category-smiles.svg) no-repeat;
                        width: 18px;
                        height: 18px;
                        background-size: contain;
                        vertical-align: middle;
                        margin: 9px 15px;
                        float: left;
                    }
                }

                i.icon-badge-diamond {
                    background: url(/images/svg/icons/white/ic-badge-diamond.svg) no-repeat;
                    width: 23px;
                    height: 23px;
                    float: left;
                    margin: 7px;
                }
            }
            
            .resumn {
                background: $white;
                width: 100%;
                padding: 0;
                position: relative;
                box-sizing: border-box;
                margin: 0 0 20px;
                height: auto;

                b {
                    font-size: 20px;
                    color: $default;
                    float: left;
                    padding: 10px;
                    
                }

                small {
                    font-size: 12px;
                    float: left;
                    clear: both;
                    margin: 0 0 10px 10px;
                }

                button {
                    padding: 15px 20px;
                    border-radius: 4px;
                    font-size: 16px;
                    height: 50px;
                    margin: 10px 0 10px -20px;
                }
            }

            .exclusive-client {
                padding: 10px 0;
                width: 100%;
                margin-top: 15px;
                position: relative;

                label {    
                    color: $white;
                    font-size: 14px;
                    padding: 0 20px 0 60px;
                    margin-bottom: 5px;
                    text-align: left;
                    width: 100%;
                    line-height: 16px;
                }

                &.diamond {
                    background: url(/images/svg/icons/white/ic-diamond-client.svg) no-repeat;
                    background-color: $diamond;
                    background-position: 12px center;

                    &::before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid $diamond;
                        position: absolute;
                        top: -5px;
                        left: 50%;
                    }
                }

                &.gold {
                    background: url(/images/svg/icons/white/ic-gold-client.svg) no-repeat;
                    background-color: $gold;
                    background-position: 12px center;

                    &::before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid $gold;
                        position: absolute;
                        top: -5px;
                        left: 50%;
                    }
                }
            }
        }

        .airplane {
            background: url(/images/svg/backgrounds/airplane-plant.svg) no-repeat;
            background-size: 105%;
            background-position-x: -10px;
            background-position-y: -20px;
            background-color: #cecece;
            width: 100%;
            height: auto;
            padding-bottom: 10px;
            display: inline-block;
            float: left;

            .airplane-seats {
                width: 280px;
                float: none;
                margin: 50px auto 30px;
                font: 12px 'GothamRoundedMedium', arial, sans-serif;
                color: #7c7c7c;

                div {
                    height: 28px;
                    width: 28px;
                    float: left;
                    margin: 10px 5px 0 6px;
                    border: 1px solid;
                    
                    label {
                        margin: 8px 4px;
                        font-weight: bold;
                        font-size: 10px;
                    }

                    &.reserved {
                        border-color: $gray-200;
                        background-color: $gray-300;
                        color: $gray-500;
                        cursor: not-allowed;
                        label {
                            cursor: not-allowed;
                        }
                    }

                    &.blocked {
                        border-color: $gray-200;
                        background: url(/images/svg/backgrounds/ic-bg-legend-seat-block.svg) no-repeat;
                        color: $gray-700;
                        cursor: not-allowed;
                        label {
                            cursor: not-allowed;
                        }
                    }
                    
                    &.space {
                        border-color: #cecece;
                        background-color: #cecece;
                        color: #cecece;
                        cursor: default;
                        label {
                            cursor: default;
                        }
                    }

                    &.chairConfort {
                        border: solid 1px #66cccc;
                        background-color: #dbeaea;
                        color: #66cccc;
                        cursor: pointer;
                        label {
                            cursor: pointer;
                        }
                    }
                    
                    &.chair {
                        border-color: $gray-200;
                        background-color: $white;
                        cursor: pointer;
                        label {
                            cursor: pointer;
                        }
                    }

                    &.chairExit {
                        border-color: $default;
                        background-color: $white;
                        cursor: pointer;
                        label {
                            cursor: pointer;
                        }
                    }

                    &.selected {
                        background-color: $default;
                        border-color: $default;
                        color: $white;
                        cursor: not-allowed;
                        label {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        .legends {
            width: 100%;
            float: left;
            margin: 0 0 0 10px;
            padding-top: 10px;
            padding-bottom: 10px;

            .legend {
                width: calc(100% - 20px);
                float: left;
                background: $gray-200;
                padding: 15px 15px 10px 15px;
                border-bottom: 1px solid $gray-400;
                clear: both;
                margin-bottom: 10px;

                b {
                    float: left;
                    font-size: 15px;
                    color: $default;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }

                label {
                    float: left;
                    clear: both;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    position: relative;
                    font-size: 10px;

                    &#chairConfort {
                        cursor: pointer;
                    }

                    &#chairExit {
                        cursor: pointer;
                    }

                    i {
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        vertical-align: middle;
                        border-width: 1px;
                        border-style: solid;
                        margin-right: 10px;

                        &.chair {
                            border-color: $gray-300;
                        }

                        &.chairBusy {
                            border-color: $gray-300;
                            background-color: $gray-300;
                        }

                        &.chairChosen {
                            border-color: $default;
                            background-color: $default;
                        }

                        &.chairConfort {
                            cursor: pointer;
                            border-color: #90d8d8;
                        }

                        &.chairExit {
                            cursor: pointer;
                            border-color: $default;
                        }
                    }
                }
            }
        }

        .sponsored-categories {
            clear: both;
            background: $white;
            padding: 20px 10px 10px 10px;
            margin-top: 15px;
            position: relative;
            width: calc(100% - 20px);
            float: left;

            &::before {
                content: '';
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $white;
                position: absolute;
                top: -5px;
                left: 50%;
            }

            .gold {
                background: url(/images/svg/icons/white/ic-gold-client.svg) no-repeat;
                background-color: $gold;
                background-size: 20px;
                background-position: 5px center;
            }

            .diamond {
                background: url(/images/svg/icons/white/ic-diamond-client.svg) no-repeat;
                background-color: $diamond;
                background-size: 20px;
                background-position: 5px center;
            }

            div {
                float: left;
                color: $white;
                padding: 5px 10px 5px 30px;
                display: inline-block;
            }

            p {
                float: left;
                text-align: left;
                font-size: 14px;
                margin: 10px 0 20px 0;
                line-height: 16x;
            }
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        .title {
            padding-bottom: 15px;

            h1 {
                font-size: 25px;
                padding-top: 5px;
            }
    
            i {
                height: 60px;
                width: 110px;
                margin-right: 20px;
                margin-left: 0;
                background-size: 100%;
            }
        }

        .flight-tab {
        
            .flight {
                border-bottom: 2px solid $gray-400;
                height: 42px;
                background-color: $gray-200;
                width: 50%;
                margin: 0;

                &:last-child {
                    width: 50%;
                    margin: 0;
                }

                p {
                    span {
                        width: auto;
                        display: inline;
                    }
                }

                &.current {
                    background-color: $gray-400;
                    border-bottom-color: $gray-400;
                    border-left: 1px solid $gray-400;
                    border-top: 2px solid $gray-200;

                    p {
                        color: $gray-800;
                    }
                }
            }
        }

        .flight-detail {
            margin: 0;
            
            .flight {
                margin: 0 2px;
                width: auto;
                
                small {
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid $gray-300;
                    display: none;
                    position: absolute;
                    bottom: 0;
                    right: 10px;
                }

                &.selected {
                    small {
                        display: block;
                    }
                }
            }
        }

        .content {
            margin: auto;
            width: auto;
            height: 1460px;

            .client-details {
                width: 372px;
                margin: auto;
                padding: 0 0 0 20px;

                .client-info {
                    width: 340px;

                    label {
                        width: 185px;
                    }
                }

                .resumn {
                    width: 340px;
                    padding: 10px;

                    button {
                        margin: 10px;
                    }
                }

                .exclusive-client {
                    width: 342px;
                }
            }

            .airplane {
                width: 596px;
                height: 1510px;
                margin-bottom: 0;
                background-size: 105%;
                background-position-x: -10px;
                background-position-y: -20px;
                background-color: $white;

                .airplane-seats {
                    width: 300px;
                    margin-left: 163px;
                    margin-top: 50px;
                }
            }

            .legends {
                width: auto;
                padding-top: 0;
                padding-bottom: 0;

                .legend {
                    width: 280px;
                }

                .sponsored-categories { 
                    width: 280px;                   
                }
            }
        }
    }
}

.tooltip-exit{

    .tooltip-inner {
        background-color: $default;
        border: 0px solid;
        border-radius: 3px;
        min-width: 220px;
        text-align: left;
        color: white;
    }

    .arrow::after, .bs-tooltip-bottom .arrow::after {
        width: 0;
        height: 0;
        border-left-color: $default;
        border-right-color: $default;
    }
}

.tooltip-confort{

    .tooltip-inner {
        background-color: #90d8d8;
        border: 0px solid;
        border-radius: 3px;
        min-width: 220px;
        text-align: left;
        color: white;
    }

    .arrow::after, .bs-tooltip-bottom .arrow::after {
        width: 0;
        height: 0;
        border-left-color:  #90d8d8;
        border-right-color:  #90d8d8;
    }

    .arrow::before, .bs-tooltip-bottom .arrow::before {
        width: 0;
        height: 0;
        border-left-color:  #90d8d8;
        border-right-color:  #90d8d8;
    }
}
