.home {
  .smiles-bankslide {
    display: flex;
    margin: 40px 0px;
    width: 100%;
    height: 250px;

    background-size: cover;
    background-color: $gray-200;

    border-radius: 16px;
    border: 2px solid $default;

    h2 {
      font-weight: 700;
      color: $default;
      line-height: 1.4;
      font-size: 20px;
      text-align: center;
      background: $white;

      width: 100%;
      height: max-content;
      padding: 20px;
      margin-bottom: 20px;

      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      border-bottom: 2px solid $default;
    }

    .smiles-bankslide-link-wrapper {
      display: inline-block;
      text-align: center;

      a {
        font-weight: 600;
        color: $white;
        text-decoration: none;
        font-size: 16px;
        letter-spacing: .5px;
        line-height: 1.4;
        background: $default;
        padding: 6px 10px;
        border-radius: 10px;

        &:hover, &:focus {
          color: #f2f2f2;
          background: darken($default , 3%);
          cursor: pointer;
        }
      }
    }

    .slick-slider {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      .slick-list {
        width: 90%;

        .slick-track {
          background: transparent;
        }
      }

      .slick-arrow {
        display: block;
        height: 20px;
        width: 20px;
        border: none;
        font-size: 0;
        background-position: center;
        padding: 20px 15px;
        background-repeat: no-repeat;
        background-color: $default !important;

        &.slick-prev {
          background: url('../../images/svg/icons/white/ic-arrow-left-sm.svg') no-repeat;
          background-position: center;
        }

        &.slick-next {
          background: url("../../images/svg/icons/white/ic-arrow-right-sm.svg") no-repeat;
          background-position: center;
        }
      }
    }

    .slick-slide > div {
      padding: 0 5px;
    }

    .paginator {
      position: absolute;
      bottom: 10px;
      left: 50%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50px;
      margin-left: -25px;
      padding-top: 10px;
    }
  }

  .smiles-home-bankslide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .smiles-banks-slide {
    height: 40px;
    background: transparent;
    margin-top: 4px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

}

@media (max-width: 991px) {
  .home {
    .smiles-bankslide-link-wrapper {
      a {
        font-size: 12px;
      }
    }

    .smiles-bankslide-wrapper {
      padding: 0 20px;
    }

    .smiles-bankslide {
      .slick-prev {
        left: 62.3%;
      }

      .slick-next {
        left: 68%;
      }
    }
  }
}

@media (max-width: 767px) {
.home {
    .smiles-bankslide {
      margin: 20px auto 40px auto;
      flex-direction: column;
      height: 250px !important;
      background: $gray-200;
      width: 90%;

      h2 {
        font-size: 16px;
        font-weight: 700;
      }

      .smiles-bankslide-wrapper {
        padding: 0px;
      }

        .slick-prev {
          left:50%;
        }

        .slick-next {
            left: 50%;
            margin-left: 10px;
            &:hover {
                margin: 0 0 -12px 11px;
            }
        }

        .smiles-bankslide-link-wrapper {
            padding: 20px 20px 25px;
            width: 100%;
            a {
                font-size: 18px;
            }
        }

        .smiles-home-bankslide-wrapper {
            padding: 28px 20px 5px;
            width: 100%;
        }

        .paginator {
            position: relative;
            bottom: 0;
            left: 0;
            margin: 0;
        }
    }
  }
 }

 @media (max-width: 567px) {
    .home {
        .smiles-bankslide {
            height: 200px;

            .smiles-bankslide-link-wrapper {
                a {
                    font-size: 14px;
                }
            }

            .smiles-home-bankslide-wrapper {
                padding: 35px 10px 6px;
            }
        }
    }
 }
