@mixin radiusLeft {
  border-radius: 3px 0px 0px 3px;
  -moz-border-radius: 3px 0px 0px 3px;
  -webkit-border-radius: 3px 0px 0px 3px;
}

@mixin radius {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
}

@media (max-width: 768px) {
  .miles-request-warning-desktop {
    display: none;
  }
  .miles-request-warning-mobile {
    display: block;
    p {
      margin: 0px;
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  .miles-request-warning-desktop {
    display: block;
  }
  .miles-request-warning-mobile {
    display: none !important;
  }
}



.miles-request-warning-delta{
  display: flex;

  strong {
    font-weight: 600;
  }

  .space {
      width: 180px;
      margin: 12px;
  }

  .warning-delta {
      display: flex;
      padding: 0px;

      -webkit-box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      -moz-box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      box-shadow: 2px 1px 5px 0px rgba(204,202,204,1);
      @include radius();
      margin: 15px 0px;

      .ico {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F95923;
          width: 70px;
          height: 70px;
          @include radiusLeft();
      }

      .ico img {
          width: 40px;
          height: 40px;
      }

      .text {
          padding: 10px 15px;
          p:first-child {
              line-height: 20px;
          }
          p {
              font-size: 12px !important;
              color: #000000 !important;
              margin: 0px;
              padding: 0px;
              line-height: 15px;
              font-weight: 100 !important;
          }
      }
  }
}
