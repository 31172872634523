.my-account {
    &__favorite-passenger {
        background-color: $white;
        @include bms-base-box;
        position: relative;
        margin: auto;
        text-align: left;
        padding: 20pt 20pt;
        &__header {
            width: 100%;
            display: flex;
            .info {
                width: 260px;
                label {
                    font-size: 18px;
                    color: $default;
                    small {     
                        font-size: 14px;
                        color: $gray-600;
                    }
                }
                p {
                    font-size: 14px;
                }
            }
            .actions {
                text-align: right;
                width: 540px;
                button, a {
                    color: $white;
                    border: none;
                    background-color: $default;
                    font-size: 16px;
                    padding: 5px 30px;
                    &:disabled {
                      opacity: .4;
                    }
                }
                .alert {
                    width: 800px;
                    text-align: left;
                    background-color: $gray-200;
                    border: 1px solid $gray-600;
                    color: $gray-600;
                    padding: 9px 20px 9px 50px;
                    font-size: 14px;
                    margin: 20px 0 -10px -260px;
                    background-image: url(#{$svg-path}/icons/gray/ic-alert-info.svg);
                    background-repeat: no-repeat;
                    background-position: 20px;
                    font-family: $ligthFontFamily;
                    b {
                        font-family: $boldFontFamily;
                    }
                }
                .search {
                    margin-top: 36px;
                    input {
                        width: 300px;
                        height: 50px;
                        margin-left: 20px;
                        background-image: url(#{$svg-path}/icons/orange/ic-search.svg);
                        background-repeat: no-repeat;
                        background-position: right center;
                        border: 1px solid $gray-400;
                        padding: 20px;
                        &::placeholder {
                            color: $white;
                        }
                    }
                }
            }
        }
        &__no-passenger {
            text-align: center;
            padding: 80px 0 120px 0;
            label {
                display: block;
            }
            button, a {
                display: block;
                color: $white;
                border: none;
                background-color: $default;
                font-size: 16px;
                padding: 5px 30px;
                margin: auto;
            }
        }
        &__list {
            width: 100%;
            margin-top: 20px;
            table {
                width: 100%;
                thead {
                    tr {
                        th {
                            padding: 20px 0;
                            color: $default;
                            font-size: 14px;
                            &:last-child {
                                text-align: right;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        border-top: 1px solid $gray-300;
                        td {
                            padding: 20px 0;
                            font-size: 14px;
                            &:last-child {
                                text-align: right;
                            }
                            b {
                                display: none;
                            }
                            a {
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                background-size: contain;
                                cursor: pointer;
                                overflow: hidden;
                                &.delete {
                                    background-image: url(#{$svg-path}/icons/gray/ic-delete.svg);    
                                }
                                &.edit {
                                    background-image: url(#{$svg-path}/icons/gray/ic-edit.svg);    
                                }
                            }
                        }
                    }
                }
                tfoot {
                    tr {
                        td {
                            text-align: center;
                            vertical-align: middle;
                            div {
                                padding-top: 40px;
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                a {
                                    cursor: pointer;
                                    &.back {
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg); 
                                        width: 13px;
                                        height: 13px;
                                        margin-top: 8px;
                                    }
                                    &.next {
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        background-image: url(#{$svg-path}/icons/gray/ic-arrow-right.svg); 
                                        width: 13px;
                                        height: 13px;
                                        margin-top: 8px;
                                    }
                                    &.number {
                                        background-color: $gray-200;
                                        border-radius: 5px;
                                        text-align: center;
                                        font-size: 12px;
                                        padding: 5px 10px;
                                        margin: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .justify-content-center {
                .pagination {
                    margin-top: 16px;
                    li {
                        a {
                            background-color: $gray-200 !important;
                            margin: auto 3px;
                        }
                        &.active {
                            a {
                                background-color: $default !important;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        &__favorite-passenger {
            padding: 10pt;
            &__header {
                display: block;
                .info {
                    width: 100%;
                    label {
                        width: 100%;
                        small {
                            float: right;
                        }
                    }
                }
                .actions {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    button {
                        width: 100%;
                        padding: 9px 30px;
                        order: 2;
                    }
                    .search {
                        order: 3;
                        margin-top: 16px;
                        label {
                            display: none;
                        }
                        input {
                            width: 100%;
                            margin: 0;
                            height: 30px;
                            &::placeholder {
                                color: $gray-400;
                            }
                        }
                    }
                    .alert {
                        order: 0;
                        width: 100%;
                        margin: 10px 0;
                        background-position: 10px 10px;
                        padding: 9px 20px 9px 40px;

                    }
                }
            }
            &__list {
                width: 100%;
                margin-top: 20px;
                table {
                    width: 100%;
                    thead {
                        display: none;
                    }
                    tbody {
                        tr {
                            display: flex;
                            flex-direction: column;
                            border-top: 3px solid $default;
                            td {
                                padding: 10px 0;
                                font-size: 14px;
                                border-bottom: 1px dotted $gray-300;
                                text-align: right;
                                font-family: $ligthFontFamily;
                                &:last-child {
                                    border-bottom: none;
                                }
                                b {
                                    display: inherit;
                                    float: left;
                                    font-family: $regularFontFamily;
                                }
                                a {
                                    width: 20px;
                                    height: 20px;
                                    display: inline-block;
                                    background-size: contain;
                                    cursor: pointer;
                                    &.delete {
                                        background-image: url(#{$svg-path}/icons/orange/ic-delete.svg); 
                                        width: 80px;
                                        background-repeat: no-repeat;
                                        background-position: right;
                                        text-align: left;
                                        &::before {
                                            content: "Eliminar";
                                            text-decoration: underline;
                                            color: $default;
                                        }   
                                    }
                                    &.edit {
                                        background-image: url(#{$svg-path}/icons/orange/ic-edit.svg);    
                                        float: left;
                                        width: 70px;
                                        background-repeat: no-repeat;
                                        background-position: right;
                                        text-align: left;
                                        &::before {
                                            content: "Editar";
                                            text-decoration: underline;
                                            color: $default;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    tfoot {
                        tr {
                            td {
                                text-align: center;
                                vertical-align: middle;
                                div {
                                    padding-top: 40px;
                                    display: flex;
                                    width: 100%;
                                    justify-content: center;
                                    a {
                                        cursor: pointer;
                                        &.back {
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            background-image: url(#{$svg-path}/icons/gray/ic-arrow-left.svg); 
                                            width: 13px;
                                            height: 13px;
                                            margin-top: 8px;
                                        }
                                        &.next {
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            background-image: url(#{$svg-path}/icons/gray/ic-arrow-right.svg); 
                                            width: 13px;
                                            height: 13px;
                                            margin-top: 8px;
                                        }
                                        &.number {
                                            background-color: $gray-200;
                                            border-radius: 5px;
                                            text-align: center;
                                            font-size: 12px;
                                            padding: 5px 10px;
                                            margin: 0 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}