.modal-password {
  text-align: center;

  &::before {
    vertical-align: middle;
    content: " ";
    height: 100%;
    display: inline-block;
  }

  .modal-dialog {
    width: 90%;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
  }

  .modal-form {
    width: 90%;

    .form {
      button {
        white-space: normal;
        height: auto;
        line-height: 20px;
        padding: 10px;
      }
    }
  }

  .modal-dialog,
  .modal-form {
    .modal-content {
      width: 100%;
      z-index: 1100;

      .modal-header {
        text-align: center;
        border-bottom: none;
        padding-top: 2.3rem;
        color: $default;
        font-size: 1.4rem;
        padding-bottom: 15px;

        .closeModal {
          button {
            opacity: 1 !important;
            height: 24px;
            padding: 0;
            width: 24px;
            font-size: 15px;
            border-radius: 100%;
            margin-right: 10px;
            margin-top: 10px;
            color: $default;
            display: block;
            font-weight: normal;
            position: absolute;
            right: 0;
            top: 0;
            background: transparent;
            border: none;
            outline: none;
          }
        }

        .titleModal {
          width: calc(100% - 10px);
          height: auto;
          display: inline-block;
          background: transparent;
          line-height: 20px;

          span {
            font-size: 19px;
            font-family: $regularFontFamily;
            font-weight: bold;
            letter-spacing: 0.5px;
          }
        }
      }

      .modal-body {
        position: relative;
        overflow-y: auto;
        max-height: 400px;
        padding: 15px;

        .alert {
          font-family: $regularFontFamily;
          font-size: 13px;
        }

        label {
          font-size: 14px;
          line-height: 16px;
          font-family: $secondaryFontFamily;
        }

        .form {
          padding: 10px;

          .elementForm {
            width: 100%;
            height: auto;
            display: table;
            margin: 0 0 10px 0;

            input {
              height: 50px;
              width: 98%;
              margin-bottom: 10px;
              padding-left: 12px;
              font-size: 14px;
              border-radius: 3px;
              border: 1px solid $gray-600;
              color: $gray-700;
              font-family: $secondaryFontFamily;
            }

            input:read-only {
              color: $gray-500;
            }

            span {
              font-size: 11px;
            }

            label {
              margin-bottom: 0;
              font-size: 14px;
            }

            &.success-field {
              label {
                color: $green;
              }

              input {
                color: $green;
                border-color: $green;
              }
            }

            &.error-field {
              label {
                color: $red;
              }

              input {
                color: $red;
                border-color: $red;
              }
            }
          }

          button {
            width: 80%;
            float: none;
            margin: 10px auto 0;
            display: block;
            font-family: $regularFontFamily;
            font-size: 14px;
          }
        }

        .recoverPasswordChose {
          width: 100%;
          display: table;
          margin: 0 0 10px 0;
          text-align: center;

          button {
            max-width: 60%;
            white-space: normal;
            height: auto;
            border-radius: 3px;
            padding: 8px 22px;
            text-shadow: none;
            box-shadow: none;
            font-family: $regularFontFamily;
            font-size: 14px;
            margin: 0 0 10px 0;
          }
        }

        &.change-password {
          min-height: 490px;
          max-height: 520px;
          .form {
            .alert {
              &.disable {
                display: none;
              }
            }
            .elementForm {
              text-align: center !important;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-password {
    text-align: center;

    &::before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }

    .modal-dialog {
      width: 370px !important;
      vertical-align: middle;
      display: inline-block;
      text-align: left;
    }

    .modal-form {
      width: 450px !important;
    }
  }
}
