@mixin setHr($position, $width, $height, $display, $bgColor, $top, $left) {
  content: '';
  position: $position;
  width: $width;
  height: $height;
  display: $display;
  background-color: $bgColor;
  left: $left;
  top: $top;
}

.smiles-club-success {
  .subtitle {
    margin: 10px auto 60px;
  }
}

.switch-club-plan-success {
  margin: 0 auto;
  padding-top: 0px;

  .checkout-success-header {
    .subtitle {
      font-family: $boldFontFamily;
    }
  }

  .resum-table {
    border: 2px dashed $default;
    margin: 30px auto;
    width: 820px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .club-info {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
      color: $club;
      font-size: 1.5em;
      font-family: $boldFontFamily;
      margin-left: 10px;
    }

    .date-value  {
      color: $default;
      font-size: 1.2em;
      font-family: $boldFontFamily;
    }

    ._success-date {
      font-family: $boldFontFamily;

    }

    ._total-miles {
      color: $club;
      font-family: $boldFontFamily;

    }
    ._bonus_miles {
      color: $default;
      font-family: $boldFontFamily;

    }
    .v-line-division {
      width: 2px;
      border-left: 1px solid $gray-300;
    }

    .miles-value {
      font-size: 1.2em;
    }

    button {
      width: 150px;
      margin: 20px 10px;
      background-color: $club;
      color: $white;
    }

    .bonus-resum {
      .table {
        margin: 0;
      }

      .table > div {
        display: flex;
        flex-direction: row;
        border: 1px solid $gray-300;
        margin: 10px 10px 0 10px;
        padding: 5px 10px;
        justify-content: space-between;

        div {
          padding: 0 ;
          text-align: right;
          display: flex;
          flex-direction: column;
          &:last-child {
            border: none;
          }
        }
      }

      button {
        background-color: $default;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    padding-top: 63px !important;

    .subtitle {
      margin: 30px 10px;
      font-size: 1em;
    }

    .resum-table {
      border: 1px solid $gray-300;
      margin: 0 20px 20px;
      padding: 10px;
      flex-direction: column;
      width: auto;
    }

    .club-info {
      border-bottom: 1px solid $gray-300;
      display: flex;
      flex-direction: column;

      .club-color {
        color: $club;
        font-size: 1.5em;
        font-family: $boldFontFamily;
      }

      button {
        width: 60%;
        margin: 20px auto;
        background-color: $club;
        color: $white;
      }
    }

    .bonus-resum .table >  div {
      border: none !important;
      flex-wrap: wrap;

      div {
        border:none !important;
        text-align: center !important;
        padding: 0 !important;
      }
    }

    ._success-date, ._total-miles, ._bonus_miles {
      display: flex;
      flex-direction: column;
      font-family: $boldFontFamily;
      margin: 10px auto;
    }

    ._success-date {
      color: $gray-700;
      width: 100%;
    }

    ._total-miles {
      color: $default;
    }

    .v-line-division {
      display: none;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      border-top: 1px solid $gray-300;
      color: $white;
      padding: 10px 0;

      button {
        color: $white;
        background-color: $default;
        width: 60%;
        margin: 10px auto;
      }
    }
  }
}
