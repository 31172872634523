// travel decorative line
.border-line {
	max-width: 70%;

	&.color-primary {
		.line {
			border-color: $primary;
		}
	}

	.border-line-content {
		float: left;
		text-align: right;
		width: 52%;

		.city {
			font-size: 13px;
		}
	}

	.line {
			float: left;
			height: 70px;
			border-left: 2px solid white;
			border-bottom: 2px solid white;
			border-bottom-left-radius: 30px;
	}

	.smls-white-icons {
		font-size: 15px;
		margin-bottom: -50%;
	}

	@media (min-width: $site-width-mobile) {
		display: block;
		max-width: 100%;
	}
}

.airline-border {
	$offset-y: 30px;
	$offset-x: 25px;

	$width-large: 90%;
	$width-small: 40%;

	position: relative;

	&::before {
		content: ' ';
		position: absolute;
		border-radius: 0 0 20px;
		border-width: 0 2px 2px 0;
		border-color: $primary;
		border-style: solid;
		bottom: $offset-y;
		right: $offset-x;
		height: 40px;
		width: 40%;

		@media (max-width: $site-width-mobile) { display: none; }
	}

	&.border-white::before { border-color: $white; }

	&.fill::before { width: $width-large; }

	&.left::before {
		border-radius: 0 0 0 20px;
		border-width: 0 0 2px 2px;
		right: unset;
		left: $offset-x;
	}

	.airline-item {
		margin-bottom: 0;
		position: absolute;
		padding-right: 10px;
		bottom: calc(#{$offset-y} - 10px);
		right: calc(#{$width-small} + #{$offset-x});
		font-size: 13px;

		.ic-airplane {
			font-size: 13px;
		}

		@media (max-width: $site-width-mobile) { display: none; }
	}

	&.offset {
		&::before, .airline-item {
			margin-bottom: 20px;
		}
	}

	&.fill .airline-item {
		right: calc(#{$width-large} + #{$offset-x})
	}
	&.left .airline-item {
		right: unset;
		left: calc(#{$width-small} + #{$offset-x})
	}
	&.left.fill .airline-item {
		right: unset;
		left: calc(#{$width-large} + #{$offset-x})
	}
}

.ic-airplane.reverse::before {
	transform: scale(-1,1);
}
