.canje-button {
    width: none;
    margin: 0px 0px 0px 10px;
    cursor: pointer;
    line-height: 18px;
    font-size: 16px;
    font-family: $boldFontFamily;
    height: 40px;
    color: $white;
    background-color: $primary;
    border-color: $primary;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 2px;
    transition: $btn-transition;
}

.canje-button:hover {
    background-color: $primary-light-100;
    border-color: $primary-light-100;
    text-align: none;
}

.canje-text-politicy {
    font-weight: 500;
    color: $primary;
}