.tooltip-installments {
    left: 3% !important;

    .tooltip-inner {
        max-width: 94%;    

        .understand-installments {
            width: 100%;
            
            h4 {
                font-size: 20px;
                color: $gray-600;
            }

            p {
                color: $default;
            }

            table {
                width: 100%;
                tr {
                    height: 60px;
                    border-bottom: 1px solid $gray-300;

                    th {
                        font-size: 20px;
                        color: $default;
                        padding: 16px 5px;
                    }

                    td {
                        font-size: 14px;
                        color: $default;
                        padding: 16px 5px;

                        &:first-child {
                            white-space: nowrap;
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }

                tfoot {
                    //display: table-footer-group;
                    display: none;
                    vertical-align: middle;
                    border-color: inherit;
                    
                    tr {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    // Large devices (Desktop, 768px and up)
    @media (min-width: 768px) {
        left: 0% !important;

        .tooltip-inner {   
            max-width: 330px !important;
            
            .understand-installments {
                width: 300px;
                
                table {

                    tfoot {
                        display: none;

                    }

                    tr {
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}