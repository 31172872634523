.color-club {
  color: $club !important;
}

.valor {
  strong: {
    color: black;
  }
}

.mt-6 {
  margin-top: 5rem !important;
}

.checkout {
  &.club-smiles {
    .refund {
      padding-bottom: 40px;
    }

    @media screen and (min-width: 768px) {
      width: auto;
    }

    @media screen and (max-width: 992px) {
      .box {
        .payment {
          > div {
            min-height: auto;

            .flex-payment {
              margin: auto;
            }
          }
        }
      }
    }

    @media screen and (min-width: 993px) {
      .box.regularization-payment {
        .payment {
          > div {
            border-bottom: none;
            border-right: 2px solid $gray-300;
            &:before {
              top: 45%;
              right: -22px;
              bottom: unset;
              left: unset;
              transform: none;
            }
          }
        }
      }
    }
  }

  .club-smiles {
    &__dni {
      width: calc(100% + 20px);
      height: 280px;
      border: none;
      background-color: $gray-100;
      margin: 0 0 0 -10px;

      .club {
        width: 100%;
        height: 166px;
        float: left;
        background-color: $club;
        text-align: center;

        label {
          font-size: 50px;
          color: $white;
          margin-top: 15px;
          width: 100%;
          font-family: $boldFontFamily;
        }

        span {
          background-image: url(../images/svg/logos/smiles-white.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 98px;
          height: 38px;
          position: relative;
          display: inline-block;
          margin-bottom: 20px;

          &::after {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 5px solid #fff;
            top: -5px;
            width: 65px;
            right: 100%;
            margin-right: 3px;
          }

          &::before {
            content: "";
            position: absolute;
            height: 9px;
            border-bottom: 5px solid #fff;
            top: -5px;
            width: 65px;
            left: 100%;
            margin-left: 3px;
          }
        }
      }

      .dni {
        float: left;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 50px;
        margin-top: 10px;

        input {
          width: 90%;
          height: 50px;
          border: 1px solid red;
          border-radius: 0;
          padding: 20px;
          color: $gray-700;
        }

        a {
          font-size: 15px;
          text-decoration: underline;
          color: $gray-700;
          margin-top: 14px;
          display: none;
          cursor: pointer;
        }

        label {
          display: inline-block;
          font-size: 14px;
          width: 90%;
          text-align: left;
          color: $gray-700;
        }
      }
    }

    &__alert {
      background-color: $club;
      width: calc(100% + 20px);
      padding: 15px 20px;
      color: $white;
      font-size: 16px;
      text-align: left;
      margin: 0 0 30px -10px;
      float: none;
    }
  }

  @media (min-width: 768px) {
    .club-smiles {
      &__dni {
        width: 972px;
        height: 170px;
        border: 2px solid $gray-400;
        margin: auto;

        .club {
          width: 370px;
          height: 166px;
        }

        .dni {
          width: 590px;
          height: 100px;
          margin-top: 50px;

          input {
            width: 372px;
            height: 60px;
          }

          a {
            display: inline-block;
          }

          label {
            display: none;
          }
        }
      }

      &__alert {
        width: 100%;
        padding: 15px 20px;
        font-size: 16px;
        text-align: left;
        margin: 40px 0 30px 0;
      }
    }
  }
}
