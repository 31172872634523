.container{
    .card-ticket {
        padding-left: 20px !important;
        padding-right: 20px !important;
        .requests-header {
            padding-left: 20px !important;
            padding-right: 20px !important;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            .ic-filelist-circle{
                background-color: $gray-800;
                border-radius: 50%;
                height: 48px;
                width: 48px;
                min-width: 48px;
                display:flex;
                align-items: center;
                justify-content: center;
                margin-top: 0px;
                .ic-filelist-crop{
                    height: 30px;
                    width: 30px;
                    min-width: 30px;
                    .ic-filelist{
                        font-size: 18px;
                        height: 40px !important;
                        width: 40px !important;
                    }
                }
            }
            h2{
                color: #5B5B5B;
                text-transform: uppercase;
                display: inline-block;
                margin: 10px 0 10px 20px;
                font-size: 20px !important;
                font-family: $regularFontFamily;
                font-weight: 700 !important;
            }
            p{
                color: $gray-700;
                font-family: $regularFontFamily;
                width: 87vw;
                font-size: 12px !important;
                font-weight: 600 !important;
                display: inline-block;
                margin-left: -40px;
                margin-top: 10px;
            }
        }
        .btn-upload{
            display: none !important;
        }
        hr{
            color: $gray-300;
            height: 1px;
            width: 97vw;
            margin-bottom: 40px;
        }
        .ticket-allinfo{
            width: calc( 97vw - 40px);
            .ticket-status{
                display: flex;
                flex-direction: column;
                .title-status{
                    font-weight: 700;
                    font-size: 14px;
                    font-family: $boldFontFamily;
                    line-height: 17px;
                    text-transform: uppercase;
                }
                .body-status{
                    font-weight: 700;
                    font-size: 12px;
                    font-family: $boldFontFamily;
                    line-height: 17px;
                    color: $primary;
                    margin: 0px 0 20px 0;
                }
            }
            .ticket-info{
                display: flex;
                flex-wrap: wrap;
            }
                .ticket-type{
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    margin-right: 2.5rem;
                    .title-type{
                        font-weight: 700;
                        font-size: 14px;
                        font-family: $boldFontFamily;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                    .body-type{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        text-transform: uppercase;
                        margin: 0px 0 20px 0;
                    }
                    .body-desc{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        margin: 0px 0 20px 0;
                    }
                }
            .ticket-desc{
                display: flex;
                flex-direction: column;
                .title-desc{
                    font-weight: 700;
                    font-size: 14px;
                    font-family: $boldFontFamily;
                    line-height: 17px;
                    text-transform: uppercase;
                }
                .body-desc{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            .upload-doc{
                display: flex;
                flex-direction: column;
                .title-upload{
                    font-weight: 700;
                    font-size: 14px;
                    font-family: $boldFontFamily;
                    color: $primary;
                    line-height: 17px;
                }
                .body-upload{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        .btn-row{
            display: flex;
            justify-content: flex-end;
            margin: 1rem 0;
            &.space{
                justify-content: space-between !important;
                margin: 20px 0;
            }
            .btn-upload{
                display: inline-block !important;
                min-width: 100px;
                height: 32px;
                border-radius: $border-radius;
                font-family: $regularFontFamily;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                margin: 20px 0;
            }
        }
        .comment-type{
            display: flex;
            flex-direction: column;
            width: auto;
            .title-comment{
                font-weight: 700;
                font-size: 14px;
                font-family: $boldFontFamily;
                line-height: 17px;
                text-transform: uppercase;
            }
            textarea{
                border-radius: $border-radius;
                margin-top: 30px;
            }
        }
        table {
            margin-left: 10px;
            thead{
                font-weight: 800;
                background-color: #ECECEC;
            }
            tbody{
                font-weight: 400;
                font-size: 14px;
            }
            tr:nth-child(even) {
                background-color: #f2f2f2
            }

            th{
                white-space: nowrap;
                font-size: 16px;
                font-weight: 800;
                font-family: $boldFontFamily;
                max-width: 153px;
            }
            td{
                font-size: 14px;
                font-weight: 400;
                vertical-align: middle !important;
                &:first-child {
                    text-align: center;
                }
            }
            th, td {
                border-right: 8px solid #ffffff;
                padding: .15rem .75rem !important;
                min-width: 155px !important;
                max-width: 185px !important;
                word-break: break-all;
                &:last-child {
                    border-right-width: 0;
                }
            }
        }
        .change-page-requests{
            display: block;
            text-align: center;
            .btn{
                margin-left: auto;
                margin-right: auto;
                width: 40px;
            }
            .change{
                color: #333;
                background-color: #fff;
                border-color: #33333333;
            }
        }
    }
}

@media (min-width: 992px) {
    .container{
        .card-ticket {
            padding-left: 30px !important;
            padding-right: 30px !important;
            .requests-header{
                display: flex;
                flex-direction: row;
                overflow: hidden;
                .col-lg-12{
                    display: flex;
                    flex-direction: column;
                    h2{
                        color: #5B5B5B;
                        text-transform: uppercase !important;
                        font-family: $regularFontFamily;
                        font-size: 16px !important;
                        font-weight: 300;
                        line-height: 24px;
                    }
                    p{
                        color: $gray-700;
                        font-family: $regularFontFamily;
                        width: 82vw;
                        font-size: 12px !important;
                        font-weight: 600 !important;
                        max-width: 36rem;
                        margin-left: 20px !important;
                        margin-top: 0px;
                    }
                }
            }
            .ticket-allinfo{
                width: 100vw;
                .ticket-status{
                    display: flex;
                    flex-direction: column;
                    .title-status{
                        font-weight: 700;
                        font-size: 14px;
                        font-family: $boldFontFamily;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                    .body-status{
                        font-weight: 700;
                        font-size: 12px;
                        font-family: $boldFontFamily;
                        line-height: 17px;
                        color: $primary;
                        margin: 0px 0 20px 0;
                    }
                }
                    .ticket-type{
                        display: flex;
                        flex-direction: column;
                        width: auto;
                        margin-right: 2.5rem;
                        .title-type{
                            font-weight: 700;
                            font-size: 14px;
                            font-family: $boldFontFamily;
                            line-height: 17px;
                            text-transform: uppercase;
                        }
                        .body-type{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            text-transform: uppercase;
                            margin: 0px 0 20px 0;
                        }
                        .body-desc{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            margin: 0px 0 20px 0;
                        }
                    }
                .ticket-desc{
                    display: flex;
                    flex-direction: column;
                    .title-desc{
                        font-weight: 700;
                        font-size: 14px;
                        font-family: $boldFontFamily;
                        line-height: 17px;
                        text-transform: uppercase;
                    }
                    .body-desc{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
                .upload-doc{
                    display: flex;
                    flex-direction: column;
                    .title-upload{
                        font-weight: 700;
                        font-size: 14px;
                        font-family: $boldFontFamily;
                        color: $primary;
                        line-height: 17px;
                    }
                    .body-upload{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
            .btn-row{
                display: flex;
                justify-content: flex-end;
                margin: 1rem 0;
                &.space{
                    justify-content: space-between !important;
                    margin: 60px 0;
                }
                &.file {
                  justify-content: space-between;
                  flex-direction: row-reverse;
                  align-items: center;
                  span {
                    font-weight: bold;
                    font-size: 12px;
                  }
                }
                .btn-upload{
                    display: inline-block !important;
                    min-width: 175px;
                    height: 32px;
                    border-radius:$border-radius;
                    font-family: $regularFontFamily;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;
                }
            }
            .comment-type{
                display: flex;
                flex-direction: column;
                width: auto;
                .title-comment{
                    font-weight: 700;
                    font-size: 14px;
                    font-family: $boldFontFamily;
                    line-height: 17px;
                    text-transform: uppercase;
                }
                textarea{
                    border-radius: $border-radius;
                    margin-top: 30px;
                }
            }
            table{
                margin-left: 0px;
                th, td{
                    min-width: 175px !important;
                    max-width: 185px !important;
                    word-break: break-all;
                }
                th{
                    &:last-child{
                        padding-right: 0 !important;
                        max-width: 215px !important;
                        width: 212px !important;
                    }
                }
            }
        }
    }
}
